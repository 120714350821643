import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { injectIntl } from 'react-intl';

function InsightBarChart(props) {
  return (
    <Bar
      data={{
        labels: props.data.map(({ date }) => date),
        datasets: [
          {
            label: props.label,
            data: props.data.map(({ value }) => value),
            backgroundColor: '#24506B',
          },
        ],
      }}
      options={{
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              offset: true,
              type: 'time',
              time: {
                unit: 'day',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            title: (tooltipItem, data) => {
              const { index } = tooltipItem[0];
              const date = data.labels[index];
              return props.intl.formatDate(date);
            },
          },
        },
      }}
    />
  );
}

InsightBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  label: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatDate: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(InsightBarChart);
