import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { VelocityTransitionGroup } from 'velocity-react';

class PanelList extends React.Component {
  handlePanelSelect = (id) => {
    this.props.onSelectPanel(this.props.selectedPanel === id ? '' : id);
  };

  render() {
    const { selectedPanel, transitionDuration, disableTransition, children } =
      this.props;

    const className = cx('panel-list', this.props.className, {
      'panel-list--active': Boolean(selectedPanel),
    });

    const panels = React.Children.map(children, (child, i) => {
      if (!child) {
        return undefined;
      }

      return React.cloneElement(child, {
        key: child.props.id ? child.props.id : `panel-list-${i}`,
        expanded: selectedPanel === child.props.id,
        onClick: this.handlePanelSelect,
      });
    });

    return (
      <div className={className}>
        {disableTransition ? (
          panels
        ) : (
          <VelocityTransitionGroup
            enter={{
              animation: 'slideDown',
              duration: transitionDuration,
            }}
            leave={{
              animation: 'slideUp',
              duration: transitionDuration,
            }}
          >
            {panels}
          </VelocityTransitionGroup>
        )}
      </div>
    );
  }
}

PanelList.propTypes = {
  className: PropTypes.string,
  selectedPanel: PropTypes.string,
  disableTransition: PropTypes.bool,
  transitionDuration: PropTypes.number,
  onSelectPanel: PropTypes.func.isRequired,
  children: PropTypes.any,
};

PanelList.defaultProps = {
  className: '',
  selectedPanel: '',
  disableTransition: false,
  transitionDuration: 300,
  children: [],
};

export default PanelList;
