import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Octicon from 'react-octicon';
import intersection from 'lodash/intersection';

import { compactObject } from 'source/utils';
import ButtonWithConfirmation from 'source/components/common/buttonWithConfirmation';
import Icon from 'source/components/common/icon';

const insightsPropsByType = {
  video: ['likes', 'comments', 'shared', 'views', 'playTime', 'watchTime'],
};

function TikTokInsightsHeadline({ onRemove, item }) {
  const { type, data: { videoUrl, publishedAt, reportedAt } = {} } = item;

  let title = 'Video';
  if (type === 'video' && videoUrl) {
    const [, videoId = ''] = videoUrl.split('video/');
    title = videoId.split('?')[0];
  }

  const publishedDate = publishedAt && moment(publishedAt).format('YYYY/MM/DD');
  const reportedDate = reportedAt && moment(reportedAt).format('YYYY/MM/DD');

  const insightsProps = insightsPropsByType[type];
  const completed = intersection(
    insightsProps,
    Object.keys(compactObject(item.data)),
  );
  const progress = `${completed.length}/${insightsProps.length}`;

  return (
    <div className="row">
      <div className="col d-flex align-center ">
        <Icon name="tiktok" className="small-icon mr-2 tiktok-icon" />
        <div className="insight-name">{title}</div>
      </div>
      <div className="col d-flex align-center">
        <Octicon name="calendar" className="text-primary mr-2" />
        <div className="date">{publishedDate}</div>
      </div>
      <div className="col d-flex align-center">
        <Octicon name="calendar" className="text-primary mr-2" />
        <div className="date">{reportedDate}</div>
      </div>
      <div className="col d-flex align-center justify-content-center">
        <Octicon name="eye" className="text-primary mr-2" />
        <div>{progress}</div>
      </div>
      <div className="col d-flex justify-content-end">
        <ButtonWithConfirmation
          button={<Octicon name="trashcan" className="text-danger" />}
          onConfirm={onRemove}
        >
          <p>Are you sure you want to remove this {type}?</p>
        </ButtonWithConfirmation>
      </div>
    </div>
  );
}

TikTokInsightsHeadline.propTypes = {
  onRemove: PropTypes.func.isRequired,
  item: PropTypes.shape({
    type: PropTypes.oneOf(['video']),
    data: PropTypes.shape({
      videoUrl: PropTypes.string,
      publishedAt: PropTypes.string,
      reportedAt: PropTypes.string,
    }).isRequired,
  }),
};

export default TikTokInsightsHeadline;
