import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm, Field } from 'redux-form';
import cx from 'classnames';
import get from 'lodash/get';

import Alert from 'source/components/common/alert';
import Checkbox from 'source/scenes/components/reduxFormAdapters/checkbox';
import { socialMediaSharingPlatforms } from 'source/reducers/campaign/shared';

import selector from './selectors';
import { withSubmissionHandler } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';

const enhance = compose(
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
  reduxForm({
    touchOnBlur: false,
    touchOnChange: true,
    enableReinitialize: true,
  }),
);

function SocialMediaSharingForm({ error, invalid, submitting, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Alert message={get(error, 'message')} />

      <h5 className="pb-3">Required Social Media posts:</h5>

      <p className="text-muted">
        Mark the platforms on which the influencer is required to share their
        post (if they have an account).
      </p>

      <div className="row flex-wrap pl-5">
        {socialMediaSharingPlatforms.map(({ platform, label }) => (
          <Field
            className="col-4 pb-2"
            key={platform}
            component={Checkbox}
            type="checkbox"
            name={platform}
            label={label}
          />
        ))}
      </div>

      <div className="row justify-content-end pr-3">
        <button
          type="submit"
          className={cx('btn', {
            'btn-primary': !error,
            'btn-danger': !!error,
          })}
          disabled={invalid || submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
}

SocialMediaSharingForm.propTypes = {
  error: PropTypes.object,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

SocialMediaSharingForm.defaultProps = {
  error: undefined,
  invalid: false,
  submitting: false,
};

export default enhance(SocialMediaSharingForm);
