import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { pure, withProps, compose } from 'recompose';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import uniqueId from 'lodash/uniqueId';
import identity from 'lodash/identity';

import {
  isValidURL,
  isRequiredIfOtherMissing,
  isRequiredIfOtherPresent,
} from 'source/utils/validators';
import Markdown from 'source/components/common/markdown';
import Badge from 'source/components/common/badge';
import { LabeledInput } from 'source/scenes/components/labeledInputs';
import Checkbox from 'source/scenes/components/reduxFormAdapters/checkbox';
import CollectionBuilderInput from 'source/scenes/components/reduxFormAdapters/collectionBuilderInput';

import TextTask from './textTask';
import LinkTask from './linkTask';
import ImageTask from './imageTask';

// Helpers

const tempId = () => uniqueId('temp_');

const clientLabelValidator = composeValidators(
  isRequiredIfOtherPresent('clientAccess'),
)('Custom label');

const validationByTaskType = {
  text: combineValidators({
    headline: isRequired('Headline'),
    clientLabel: clientLabelValidator,
  }),
  link: combineValidators({
    headline: isRequired('Headline'),
    body: isValidURL('Link'),
    clientLabel: clientLabelValidator,
  }),
  code: combineValidators({
    headline: isRequired('Headline'),
    body: isRequired('Code'),
    clientLabel: clientLabelValidator,
  }),
  image: combineValidators({
    headline: isRequired('Headline'),
    file: isRequiredIfOtherMissing('body')('File'),
    body: composeValidators(
      isRequiredIfOtherMissing('file'),
      isValidURL,
    )('File URL'),
    clientLabel: clientLabelValidator,
  }),
};

// Components

/**
 * Form used for adding / editing the task itself
 */
function TaskForm({
  isUpdate,
  onClose,
  handleSubmit,
  taskType,
  customFields: CustomFields,
  clientAccess,
  headline,
  changeFormFieldValue,
}) {
  const onClientAccessChange = (event) => {
    if (event.target.checked) {
      changeFormFieldValue('clientLabel', headline);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="card-text text-muted">
          {isUpdate ? 'Update' : 'Create a new'} <b>{taskType}</b> task
        </div>
        <Field name="id" component="input" type="hidden" />
        <Field name="type" component="input" type="hidden" />
        <Field name="list" component="input" type="hidden" />
        <Field
          name="headline"
          component={LabeledInput}
          type="text"
          placeholder="Task headline..."
        />
        <CustomFields />
        <Field
          name="individual"
          component={Checkbox}
          label="Per influencer individualized task"
        />
        <Field
          name="clientAccess"
          component={Checkbox}
          label="Content provided by Client"
          onChange={onClientAccessChange}
        />
        {clientAccess && (
          <Field
            className="ml-3"
            name="clientLabel"
            component={LabeledInput}
            type="text"
            label="Custom label for Business Manager"
          />
        )}
        <div className="row justify-content-end pr-3">
          <div className="btn-group">
            <button className="btn btn-primary" onClick={handleSubmit}>
              {isUpdate ? 'Update' : 'Add'}
            </button>
            <button className="btn btn-secondary" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

TaskForm.propTypes = {
  isUpdate: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  taskType: PropTypes.string.isRequired,
  customFields: PropTypes.func.isRequired,
  changeFormFieldValue: PropTypes.func.isRequired,
  headline: PropTypes.string.isRequired,
  clientAccess: PropTypes.bool,
};

/**
 * Factory for producing task forms
 */
const taskForm = ({ form, validate, ...props }) =>
  compose(
    connect(
      (state) => {
        const formPropSelector = formValueSelector(form);

        return {
          clientAccess: formPropSelector(state, 'clientAccess'),
          headline: formPropSelector(state, 'headline'),
        };
      },
      (dispatch) => ({
        changeFormFieldValue: (field, value) =>
          dispatch(change(form, field, value)),
      }),
    ),
    reduxForm({
      form,
      validate,
      onSubmit: identity,
    }),
    withProps(props),
  )(TaskForm);

/**
 * Component used to render each task in the list
 */
function TaskListItem({
  className,
  item: { headline, individual, proxy, clientAccess },
}) {
  return (
    <span className={className}>
      <Markdown markdown={headline} typeName="span" noParagraphs />
      {individual && (
        <Badge type="info" className="ml-1">
          individual
        </Badge>
      )}
      {proxy && (
        <Badge type="info" className="ml-1">
          proxy
        </Badge>
      )}
      {clientAccess && (
        <Badge type="info" className="ml-1">
          client access
        </Badge>
      )}
    </span>
  );
}

TaskListItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    individual: PropTypes.bool,
    clientAccess: PropTypes.bool,
    proxy: PropTypes.bool,
  }),
};

const TaskBuilder = compose(
  pure,
  withProps(({ tasksForm: form, taskList }) => ({
    // Initial values when adding a new task
    getNewItemInitialValues: (formId) => ({
      type: formId,
      id: tempId(),
      list: taskList,
    }),
    // Configuration of forms for each task type
    itemForms: [
      {
        id: 'text',
        label: 'Text',
        component: taskForm({
          form,
          validate: validationByTaskType.text,
          taskList,
          taskType: 'text',
          customFields: TextTask,
        }),
      },
      {
        id: 'link',
        label: 'Link',
        component: taskForm({
          form,
          validate: validationByTaskType.link,
          taskList,
          taskType: 'link',
          customFields: LinkTask,
        }),
      },
      {
        id: 'image',
        label: 'Image',
        component: taskForm({
          form,
          validate: validationByTaskType.image,
          taskList,
          taskType: 'image',
          customFields: ImageTask,
        }),
      },
      {
        id: 'code',
        label: 'Code',
        component: taskForm({
          form,
          validate: validationByTaskType.code,
          taskList,
          taskType: 'code',
          customFields: TextTask,
        }),
      },
    ],
  })),
  withProps({
    addBtnLabel: 'Add Task',
    noContentMessage: 'No tasks',
    listItemComponent: TaskListItem,
    getItemId: ({ id }) => id,
    getItemFormId: ({ type }) => type,
  }),
)(CollectionBuilderInput);

function TaskBuilderField(props) {
  return <Field {...props} component={TaskBuilder} />;
}

TaskBuilderField.propTypes = {
  tasksForm: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  taskList: PropTypes.string.isRequired,
};

export default TaskBuilderField;
