import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'source/components/common/icon';

function InternalNote({ notes }) {
  if (!notes) {
    return null;
  }

  return (
    <section className="container-fluid">
      <div className="row">
        <h6>
          <strong>Internal Note</strong>
          <span>
            <Icon name="note" className="small-icon mx-2" />
          </span>
        </h6>
      </div>

      <div className="row">
        <div className="col-6 pl-0 notes-wrapper">
          <p className="mb-5 text-muted">{notes}</p>
        </div>
      </div>
    </section>
  );
}

InternalNote.propTypes = {
  notes: PropTypes.string,
};

InternalNote.defaultProps = {
  notes: '',
};

export default InternalNote;
