import React from 'react';
import ReactDom from 'react-dom';
import domready from 'domready';

// this is where apple touch icon is being loaded
import 'source/images/apple-touch.png';

import App from './app';

const renderApp = (App) => {
  ReactDom.render(<App />, document.getElementById('root-container'));
};

domready(renderApp(App));
