/* eslint-disable react/style-prop-object */

import React from 'react';
import { FormattedNumber } from 'react-intl';
import { createSelector, createStructuredSelector } from 'reselect';
import { isDirty } from 'redux-form';
import get from 'lodash/get';

const getCampaign = (state, props) => props.campaign;
const getFormDirty = (state, props) => isDirty(props.form)(state);

const getInitialValues = createSelector(getCampaign, (campaign) => ({
  ...get(campaign, 'contract', {}),
}));

const getHeadline = createSelector(getFormDirty, (dirty) => {
  let headline = 'Campaign Contract';
  if (dirty) {
    headline += '*';
  }

  return headline;
});

const getSubHeadline = createSelector(getCampaign, (campaign = {}) => {
  const contract = get(campaign, 'contract', {});
  const currency = get(campaign, 'settings.currency');

  let subHeadline = '';
  if (contract.budget) {
    subHeadline = (
      <>
        Budget:{' '}
        <FormattedNumber
          value={contract.budget}
          style="currency"
          currency={currency}
        />
      </>
    );
  }

  return subHeadline;
});

export default createStructuredSelector({
  initialValues: getInitialValues,
  headline: getHeadline,
  subHeadline: getSubHeadline,
});
