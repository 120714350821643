import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import range from 'lodash/range';
import { withHandlers, defaultProps, compose } from 'recompose';

import { Icon } from 'react-fa';

// Helpers

const preventDefault = (e) => {
  e.preventDefault();
};

// HoCs

const withUpdatePointHandlers = withHandlers({
  onAddPoint:
    ({ points, maxPoints, onSetPoint }) =>
    (e) => {
      e.preventDefault();

      if (points + 1 <= maxPoints) {
        onSetPoint(points + 1);
      }
    },

  onRemovePoint:
    ({ points, onSetPoint }) =>
    (e) => {
      e.preventDefault();

      if (points - 1 >= 0) {
        onSetPoint(points - 1);
      }
    },
  onSetPoint:
    ({ maxPoints, onSetPoint }) =>
    (e, points) => {
      e.preventDefault();
      if (points >= 0 && points <= maxPoints) {
        onSetPoint(points);
      }
    },
});

// Components

function Counter({ points, maxPoints }) {
  return (
    <div className="points__counter">
      {points} / {maxPoints}
    </div>
  );
}

Counter.propTypes = {
  points: PropTypes.number.isRequired,
  maxPoints: PropTypes.number.isRequired,
};

function Points({
  className,
  disabled,
  points,
  maxPoints,
  showCounter,
  onAddPoint,
  onRemovePoint,
  onSetPoint,
}) {
  return (
    <div className={cx(className, 'points', { disabled })}>
      <button
        className={cx(
          'btn',
          'btn-outline-secondary',
          'points__btn',
          'points__btn--remove',
          { disabled },
        )}
        onClick={!disabled ? onRemovePoint : preventDefault}
      >
        <Icon name="minus" />
      </button>
      <div className="points__point-list">
        {range(maxPoints).map((i) => (
          <div
            key={`point-${i}`}
            className={cx('points__point', {
              active: i < points,
              disabled,
            })}
            onClick={!disabled ? (e) => onSetPoint(e, i + 1) : preventDefault}
          />
        ))}
      </div>
      <button
        className={cx(
          'btn',
          'btn-outline-secondary',
          'points__btn',
          'points__btn--remove',
          { disabled },
        )}
        onClick={!disabled ? onAddPoint : preventDefault}
      >
        <Icon name="plus" />
      </button>
      {showCounter ? <Counter points={points} maxPoints={maxPoints} /> : null}
    </div>
  );
}

Points.propTypes = {
  className: PropTypes.string,
  points: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  showCounter: PropTypes.bool,
  maxPoints: PropTypes.number.isRequired,
  /**
   * Callback to add points. Passed the current points value. Won't be
   * called if `points > maxPoints`
   */
  onAddPoint: PropTypes.func.isRequired,
  /**
   * Callback to remove points. Passed the current points value. Won't be
   * called if `points < 0`
   */
  onRemovePoint: PropTypes.func.isRequired,
  onSetPoint: PropTypes.func.isRequired,
};

export default compose(
  defaultProps({
    className: '',
    disabled: false,
    showCounter: false,
  }),
  withUpdatePointHandlers,
)(Points);
