import { combineValidators, composeValidators, isRequired } from 'revalidate';

import { isRequiredIfOtherMissing, isValidURL } from 'source/utils/validators';

export default combineValidators({
  file: isRequiredIfOtherMissing('url')('File'),
  url: composeValidators(
    isRequiredIfOtherMissing('file'),
    isValidURL,
  )('File URL'),
  filename: isRequired('File name'),
});
