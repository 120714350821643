import React, { Component } from 'react';
import PropTypes from 'prop-types';
import outsideClick from 'react-click-outside';

class ConfirmationAlert extends Component {
  // eslint-disable-next-line
  handleClickOutside = () => {
    this.props.closePortal();
  };

  confirmAlert = (e) => {
    e.stopPropagation();
    this.props.closePortal();
    this.props.onConfirm();
  };

  closeAlert = (e) => {
    e.stopPropagation();
    this.props.closePortal();
  };

  render() {
    return (
      <div
        className="confirmation-alert showConfirmationAlert visible"
        style={{ display: 'block', marginTop: '-170px' }}
      >
        {this.props.children}
        <div className="ca-button-container">
          <button
            onClick={this.closeAlert}
            className="btn btn-secondary"
            tabIndex={-1}
          >
            {this.props.noButtonLabel}
          </button>
          <button
            onClick={this.confirmAlert}
            className="btn btn-danger"
            tabIndex={-2}
          >
            {this.props.yesButtonLabel}
          </button>
        </div>
      </div>
    );
  }
}

ConfirmationAlert.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  closePortal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  yesButtonLabel: PropTypes.string,
  noButtonLabel: PropTypes.string,
};

ConfirmationAlert.defaultProps = {
  yesButtonLabel: 'Yes',
  noButtonLabel: 'No',
};

export default outsideClick(ConfirmationAlert);
