import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function TodoDetail({ title, children, noColon, className }) {
  return (
    <div className={cx('row', className)}>
      <div className="col-md-2">
        {title}
        {noColon ? '' : ':'}
      </div>
      <div className="col-md-10">{children}</div>
    </div>
  );
}

TodoDetail.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node,
  noColon: PropTypes.bool,
  className: PropTypes.string,
};

TodoDetail.defaultProps = {
  children: undefined,
  noColon: false,
  className: '',
};

export default TodoDetail;
