import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import get from 'lodash/get';
import { FormattedNumber } from 'react-intl';

import Icon from 'source/components/common/icon';
import ExternalLink from 'source/scenes/components/externalLink';
import ChannelStatus from 'source/components/common/channelStatus';
import ChannelAvatar from 'source/components/common/channelAvatar';

// Helpers
const toPercent = (value) =>
  typeof value === 'number' ? (
    <FormattedNumber
      {...{ style: 'percent' }}
      maximumFractionDigits={2}
      value={value}
    />
  ) : (
    ''
  );
const toNumber = (value) =>
  Number.isInteger(value) ? <FormattedNumber value={value} /> : '';

function KeyMetricsEntry({ className, label, bold, children }) {
  return (
    <div className={cx('key-metrics-entry', className)}>
      <span className="key-metrics-entry-label">{label}</span>
      <div
        className={cx('key-metrics-entry-value', {
          bold,
        })}
      >
        {children}
      </div>
    </div>
  );
}

KeyMetricsEntry.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  bold: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
KeyMetricsEntry.defaultProps = {
  className: '',
  bold: false,
};

function PinterestKeyMetrics({ className, keyMetrics }) {
  const metricsValidAt = get(keyMetrics, 'updatedAt');
  const followers = get(keyMetrics, 'followers');
  const pins = get(keyMetrics, 'pins');
  const impressionMedian = get(keyMetrics, 'impressionMedian');
  const engagementMedian = get(keyMetrics, 'engagementMedian');
  const engagementRatio = get(keyMetrics, 'engagementRatio');
  const pinClickMedian = get(keyMetrics, 'pinClickMedian');
  const pinClickRatio = get(keyMetrics, 'pinClickRatio');
  const saveMedian = get(keyMetrics, 'saveMedian');
  const saveRatio = get(keyMetrics, 'saveRatio');

  const lastAutomaticUpdate = metricsValidAt
    ? `Last automatic update on ${moment(metricsValidAt).format(
        'L HH:mm ZZ',
      )} by Pinterest API`
    : '';

  return (
    <div className={cx('key-metrics-container', className)}>
      <KeyMetricsEntry label="Followers" bold>
        {toNumber(followers)}
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Pins" bold>
        {toNumber(pins)}
      </KeyMetricsEntry>
      <div className={cx('key-metrics-entry text-muted')}>
        Insights for last 30 days for last 10 idea pins:
      </div>
      <KeyMetricsEntry label="Impressions Median" bold>
        {toNumber(impressionMedian)}
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Engagement Median" bold>
        {toNumber(engagementMedian)}
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Engagement Rate" bold>
        {toPercent(engagementRatio)}
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Pin Click Median" bold>
        {toNumber(pinClickMedian)}
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Pin Click Rate" bold>
        {toPercent(pinClickRatio)}
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Save Median" bold>
        {toNumber(saveMedian)}
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Save Rate" bold>
        {toPercent(saveRatio)}
      </KeyMetricsEntry>
      <span className="last-updated-label">{lastAutomaticUpdate}</span>
    </div>
  );
}

PinterestKeyMetrics.propTypes = {
  keyMetrics: PropTypes.object,
  className: PropTypes.string,
};
PinterestKeyMetrics.defaultProps = {
  keyMetrics: {},
  className: '',
};

function ChannelInfo({ className, channel }) {
  return (
    <div className={className}>
      <ChannelAvatar
        className="application-channel-avatar-pinterest"
        url={channel.avatarUrl}
      />
      <div className="channel-info-entry">
        <span className="channel-info-entry-label">Internal ID</span>
        <div className="channel-info-entry-value">
          <ExternalLink
            className="truncate bold"
            href={`/channels/${channel.id}#metrics`}
          >
            {channel.id}
          </ExternalLink>
        </div>
      </div>
      <div className="channel-info-entry">
        <span className="channel-info-entry-label">Channel URL</span>
        <div className="channel-info-entry-value">
          <ExternalLink className="truncate bold" href={channel.data.url}>
            {channel.data.url}
          </ExternalLink>
        </div>
      </div>
      <div className="channel-info-entry">
        <span className="channel-info-entry-label">Channel Type</span>
        <div className="channel-info-entry-value">Pinterest</div>
      </div>
      <div className="channel-info-entry">
        <span className="channel-info-entry-label">Status</span>
        <div className="channel-info-entry-value">
          <ChannelStatus
            ready={channel.ready}
            readyState={channel.readyState}
          />
        </div>
      </div>
      <div className="channel-info-entry">
        <span className="channel-info-entry-label">
          Internal Note
          <Icon name="note" className="small-icon ml-2" />
        </span>
        <div className="channel-info-entry-value">{channel.notes}</div>
      </div>
      <span className="last-updated-label">
        Last update on {moment(channel.updatedAt).format('L HH:mm ZZ')}
      </span>
    </div>
  );
}

ChannelInfo.propTypes = {
  className: PropTypes.string,
  channel: PropTypes.object.isRequired,
};
ChannelInfo.defaultProps = {
  className: '',
};

function ChannelDetails({ application }) {
  return (
    <div className="channel-details">
      <ChannelInfo
        className="channel-details-info"
        channel={application.channel}
      />
      <PinterestKeyMetrics
        className="channel-details-key-metrics"
        channelName={application.channel.name}
        keyMetrics={application.channel.keyMetrics}
      />
    </div>
  );
}

ChannelDetails.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    channel: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      data: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      notes: PropTypes.string,
      keyMetrics: PropTypes.object,
      kpi: PropTypes.object,
    }).isRequired,
  }).isRequired,
  insights: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.object,
    data: PropTypes.object,
  }).isRequired,
};

export default ChannelDetails;
