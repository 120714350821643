import * as Sentry from '@sentry/browser';
import {
  asyncActionCreator,
  keyedAsyncActionCreator,
} from '@blogfoster/redux-async-utils';

import { actionAuthRequest } from 'source/utils/axios';

export const actionTypes = {
  FETCH_MANY: 'data/applications/FETCH_MANY',
  FETCH: 'data/applications/FETCH',
  UPDATE: 'data/applications/UPDATE',
  CREATE: 'data/applications/CREATE',
  CLEAR: 'data/applications/CLEAR',
  DELETE: 'data/applications/DELETE',
};

export const applicationFetchMany = (name) =>
  asyncActionCreator(
    actionTypes.FETCH_MANY,
    ({ page = null, filters = [], fields = [] } = {}) =>
      (dispatch, getState) => {
        const params = {};

        if (filters && filters.length > 0) {
          params.filters = JSON.stringify(filters);
        }

        if (fields && fields.length > 0) {
          params.fields = JSON.stringify(fields);
        }

        if (page && page > 0) {
          params.page = page;
        }

        Sentry.addBreadcrumb({
          category: 'action',
          message: actionTypes.FETCH_MANY,
          level: Sentry.Severity.Debug,
          data: {
            params,
          },
        });

        return actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
        }).get('/v2/applications', { params });
      },
    { name },
  );

export const applicationFetch = (name) =>
  keyedAsyncActionCreator(
    actionTypes.FETCH,
    (applicationId, { filters = [], fields = [] } = {}) =>
      (dispatch, getState) => {
        const params = {};

        if (filters && filters.length > 0) {
          params.filters = JSON.stringify(filters);
        }

        if (fields && fields.length > 0) {
          params.fields = JSON.stringify(fields);
        }

        return actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
        }).get(`/v2/applications/${applicationId}`, { params });
      },
    { name },
  );

export const applicationUpdate = (name) =>
  keyedAsyncActionCreator(
    actionTypes.UPDATE,
    (applicationId, payload = {}) =>
      (dispatch, getState) =>
        actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
        }).patch(`/v2/applications/${applicationId}`, payload),
    { name },
  );

export const applicationUpdateContentPreviewMedia = (name) =>
  keyedAsyncActionCreator(
    actionTypes.UPDATE,
    (applicationId, contentPreviewVersion, payload = {}) =>
      (dispatch, getState) =>
        actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
        }).put(
          `/v2/applications/${applicationId}/content-previews/${contentPreviewVersion}/media`,
          payload,
        ),
    { name },
  );

export const applicationUpdateContentPreviewAccess = (name) =>
  keyedAsyncActionCreator(
    actionTypes.UPDATE,
    (applicationId, contentPreviewVersion, payload = {}) =>
      (dispatch, getState) =>
        actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
        }).put(
          `/v2/applications/${applicationId}/content-previews/${contentPreviewVersion}/access`,
          payload,
        ),
    { name },
  );

export const applicationUpdateContentPreviewReview = (name) =>
  keyedAsyncActionCreator(
    actionTypes.UPDATE,
    (applicationId, contentPreviewVersion, payload = {}) =>
      (dispatch, getState) =>
        actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
        }).put(
          `/v2/applications/${applicationId}/content-previews/${contentPreviewVersion}/kam`,
          payload,
        ),
    { name },
  );

export const applicationCreate = (name) =>
  asyncActionCreator(
    actionTypes.CREATE,
    (payload) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
      }).post('/v2/applications', payload),
    { name },
  );

export const applicationDelete = (name) =>
  keyedAsyncActionCreator(
    actionTypes.DELETE,
    (id, payload) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
      }).delete(
        `/v2/applications/${id}`,
        payload
          ? {
              data: payload,
            }
          : undefined,
      ),
    { name },
  );

/**
 * Clear store
 */
export const applicationsClear = (name) => (ids) => ({
  type: actionTypes.CLEAR,
  payload: ids,
  name,
});

// Export generic versions of these action creators

export const listApplications = applicationFetchMany('default');
export const loadApplication = applicationFetch('default');
export const updateApplication = applicationUpdate('default');
export const createApplication = applicationCreate('default');
export const clearApplications = applicationsClear('default');
export const deleteApplication = applicationDelete('default');
