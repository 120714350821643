/* == Helpers == */

const getInitials = (first, last) => `${first[0]}${last[0]}`;

/**
 * Retuns the intials (two capital letters) for a given user. This
 * tries to use the `firstname` + `lastname` first, then uses `email`
 * and defaults to 'n/a'.
 */
export const toInitials = ({ firstname, lastname, email, type }) => {
  let initials;

  if (type === 'system') {
    initials = '🤖';
  } else if (firstname && lastname) {
    initials = getInitials(firstname, lastname);
    // If we only have a firstname, use the first two letters as the initials
  } else if (firstname) {
    initials = firstname.split('').slice(0, 2).join('');
    // We assume the email contains first and last name divided by a period
  } else if (email && /[^@]+\.[^@]+@.*/.test(email)) {
    initials = getInitials(...email.split('@')[0].split('.'));
  } else {
    initials = 'n/a';
  }

  return initials.toUpperCase();
};

const mapToCharCodes = (str) => {
  const codes = [];
  for (let i = 0; i < str.length; i += 1) {
    codes.push(str.charCodeAt(i));
  }
  return codes;
};

const sum = (numbers) => numbers.reduce((sum, n) => sum + n, 0);

/**
 * Returns a color code for the given string `str`.
 */
export const toColor = (str) => {
  const colors = [
    '#a4243b',
    '#36c18e',
    '#75358f',
    '#0081af',
    '#f76032',
    '#3e3c5c',
  ];
  const hash = sum(mapToCharCodes(str)) % colors.length;

  return colors[hash];
};

export const upperCaseFirst = (str) => `${str[0].toUpperCase()}${str.slice(1)}`;
