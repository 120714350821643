import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Octicon from 'react-octicon';
import moment from 'moment';
import { compose, setPropTypes, withHandlers } from 'recompose';

import Feature from 'source/components/common/feature';
import Pagination from 'source/components/common/pagination';
import LoginButton from 'source/components/common/loginButton';

const enhanceUserTableRow = compose(
  setPropTypes({
    onLoginClick: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }),
  withHandlers({
    onLoginClick:
      ({ user, onLoginClick }) =>
      (e) => {
        e.preventDefault();
        onLoginClick(user.id);
      },
  }),
);

function _UserTableRow({ user, onLoginClick }) {
  return (
    <tr>
      <td>
        <strong>{user.id}</strong>
      </td>
      <td>
        <Link className="cell-link" to={`/users/${user.id}`}>
          {user.firstname}
        </Link>
      </td>
      <td>
        <a href={`mailto:${user.email}`} style={{ paddingTop: '2px' }}>
          <Octicon name="mail" />
        </a>
        <span className="pl-1">{user.email}</span>
      </td>
      <td>{user.phone}</td>
      <td>{moment(user.createdAt).format('YYYY-MM-DD HH:mm')}</td>
      <td>{moment(user.lastLogin).format('YYYY-MM-DD HH:mm')}</td>
      <Feature feature="loginAsInfluencer">
        <td>
          <LoginButton
            accessToken={user.accessToken}
            onLoginClick={onLoginClick}
          />
        </td>
      </Feature>
    </tr>
  );
}

_UserTableRow.propTypes = {
  user: PropTypes.object.isRequired,
  onLoginClick: PropTypes.func.isRequired,
};

const UserTableRow = enhanceUserTableRow(_UserTableRow);

function UsersTable({ users, pages, page, onSelectPage, onLoginClick }) {
  return (
    <div>
      <div className="table-responsive-lg">
        <table className="table table-striped table-links">
          <thead>
            <tr>
              <th className="col-id">#</th>
              <th className="col-id">Name</th>
              <th className="col-uri">Email</th>
              <th className="col-id">Phone</th>
              <th className="min-width-8-5">Account Created</th>
              <th className="min-width-8-5">Last Login</th>
              <Feature feature="loginAsInfluencer">
                <th className="col-id">Manage</th>
              </Feature>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <UserTableRow
                key={user.id}
                user={user}
                onLoginClick={onLoginClick}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="row justify-content-center pt-3">
        <Pagination pages={pages} activePage={page} onSelect={onSelectPage} />
      </div>
    </div>
  );
}

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  pages: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  onSelectPage: PropTypes.any.isRequired,
  onLoginClick: PropTypes.func.isRequired,
};

export default UsersTable;
