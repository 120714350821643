export const getCampaignEditPath = (campaign) => {
  if (campaign.type === 'website' && campaign.pricing === 'budget') {
    return `/campaigns/website-budget/${campaign.id}/edit`;
  }

  if (campaign.type === 'website' && campaign.pricing === 'bucket') {
    return `/campaigns/website-bucket/${campaign.id}/edit`;
  }

  return `/campaigns/${campaign.type}/${campaign.id}/edit`;
};
