import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { UserProfile, UserProfileHeadline } from 'source/scenes/widgets';

import MissionStatus from './missionStatus';
import ApplicationInfo from './applicationInfo';
import ApplicationProductShipment from './applicationProductShipment';
import Conversation from './conversation';
import InfluencerRating from './influencerRating';
import IndividualTasks from './individualTasks';
import MissionSection from './missionSection';

function Mission({
  forms,
  onSubmitConversation,
  onSubmitStatus,
  onSubmitKamNote,
  onSubmitProductShipment,
  onSubmitRating,
  ...props
}) {
  const getPanelSettings = () => {
    const settings = JSON.parse(localStorage.getItem('missionPanels') || '{}');

    if (isEmpty(settings)) {
      return {
        productShipment: false,
        notes: false,
        channelInfo: false,
        userProfile: false,
        individualTasks: false,
        rating: false,
      };
    }

    return settings;
  };

  const [sectionsOpen, setSectionsOpen] = React.useState(() =>
    getPanelSettings(),
  );

  const createToggler = (sectionName) => () => {
    const newSettings = {
      ...sectionsOpen,
      [sectionName]: !sectionsOpen[sectionName],
    };

    setSectionsOpen(newSettings);
    localStorage.setItem('missionPanels', JSON.stringify(newSettings));
  };

  return (
    <div className="row mission">
      <div className="col-lg-9 order-2 order-lg-1">
        <Conversation
          {...props}
          forms={forms}
          onSubmit={onSubmitConversation}
        />
      </div>

      <div className="col-lg-3 order-1 order-lg-2 pb-3 pt-3">
        <MissionStatus
          {...props}
          id="status"
          form={forms.status}
          onSubmit={onSubmitStatus}
          className="mb-2"
        />

        {props?.campaign?.productShipment?.enabled && (
          <MissionSection
            className="mb-1"
            label="Product Shipping"
            open={sectionsOpen.productShipment}
            onToggle={createToggler('productShipment')}
          >
            <ApplicationProductShipment
              {...props}
              id="productShipment"
              form={forms.productShipment}
              onSubmit={onSubmitProductShipment}
            />
          </MissionSection>
        )}

        <MissionSection
          className="mb-1"
          label="Notes"
          open={sectionsOpen.notes}
          onToggle={createToggler('notes')}
        >
          <ApplicationInfo
            {...props}
            id="notes"
            form={forms.notes}
            onSubmitKamNote={onSubmitKamNote}
          />
        </MissionSection>

        <MissionSection
          className="mb-1"
          label="Channel Info"
          open={sectionsOpen.channelInfo}
          onToggle={createToggler('channelInfo')}
        >
          <props.channelLinkComponent channel={props?.application?.channel} />
        </MissionSection>

        <MissionSection
          className="mb-1"
          label="Individual Tasks"
          open={sectionsOpen.individualTasks}
          onToggle={createToggler('individualTasks')}
        >
          <IndividualTasks application={props?.application} />
        </MissionSection>

        <MissionSection
          className="mb-1"
          label={<UserProfileHeadline user={props.application.user} />}
          open={sectionsOpen.userProfile}
          onToggle={createToggler('userProfile')}
          bold={false}
        >
          <UserProfile
            showHeadline={false}
            user={props.application.user}
            container={false}
            className="pt-2 mb-2"
          />
        </MissionSection>

        <MissionSection
          label="Rate Influencer"
          open={sectionsOpen.rating}
          onToggle={createToggler('rating')}
        >
          <InfluencerRating
            {...props}
            id="collaborationRatings"
            form={forms.collaborationRatings}
            onSubmit={onSubmitRating}
            showHeadline={false}
          />
        </MissionSection>
      </div>
    </div>
  );
}

Mission.propTypes = {
  forms: PropTypes.shape({
    status: PropTypes.object,
    notes: PropTypes.object,
    productShipment: PropTypes.object,
    collaborationRatings: PropTypes.object,
  }).isRequired,
  campaign: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  onSubmitConversation: PropTypes.func.isRequired,
  onSubmitStatus: PropTypes.func.isRequired,
  onSubmitRating: PropTypes.func.isRequired,
  onSubmitKamNote: PropTypes.func.isRequired,
  onSubmitProductShipment: PropTypes.func.isRequired,
};

export default Mission;
