import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import findIndex from 'lodash/findIndex';
import { compose, setPropTypes, withHandlers } from 'recompose';

import { remove, push } from 'source/utils/imCollection';
import { applicationStatuses } from 'source/constants';
import { CollapsiblePanel } from 'source/components/common';
import InfoCard, { InfoCardContainer } from 'source/components/common/infoCard';

import Application from './application';
import Headline from './headline';

/* CampaignApplicationsList */
function ApplicationsListEmpty({ filters, className }) {
  const message = reject(filters, isEmpty).length
    ? 'No applications found by filter.'
    : 'Once there are any applications for this campaign, you will see them here.';

  return (
    <InfoCardContainer className={className}>
      <InfoCard iconName="info" title="No applications so far.">
        {message}
      </InfoCard>
    </InfoCardContainer>
  );
}

ApplicationsListEmpty.propTypes = {
  filters: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

const enhanceApplicationsList = compose(
  setPropTypes({
    onExpandApplication: PropTypes.func.isRequired,
  }),
  withHandlers({
    onExpandApplication:
      ({ onExpandApplication }) =>
      (id, expanded) =>
        onExpandApplication({ id, expanded }),
  }),
);

function _ApplicationsList({
  applications,
  campaign,
  forms,
  selectApplication,
  selectedApplications,
  expanded,
  insights,
  onExpandApplication,
  publishingDates,
  ...props
}) {
  return (
    <div>
      {applications.map((application) => {
        const { id } = application;
        const selected = findIndex(selectedApplications, { id }) !== -1;
        const headline = (
          <Headline
            selected={selected}
            onSelect={selectApplication}
            application={application}
          />
        );

        return (
          <CollapsiblePanel
            key={id}
            id={id}
            headline={headline}
            expanded={expanded[id] || false}
            onClick={onExpandApplication}
            className="mt-3"
          >
            <Application
              {...props}
              campaignId={campaign.id}
              application={application}
              form={forms[id] || {}}
              insights={insights[id]}
              publishingDates={publishingDates[id]}
            />
          </CollapsiblePanel>
        );
      })}
    </div>
  );
}

_ApplicationsList.propTypes = {
  forms: PropTypes.object.isRequired,
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  selectApplication: PropTypes.func.isRequired,
  selectedApplications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  insights: PropTypes.object.isRequired,
  expanded: PropTypes.object.isRequired,
  onExpandApplication: PropTypes.func.isRequired,
  publishingDates: PropTypes.object.isRequired,
};

const ApplicationsList = enhanceApplicationsList(_ApplicationsList);

class CampaignApplicationsList extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      selected: [],
    };
  }

  onApplicationSelect = (id) => {
    const index = findIndex(this.state.selected, { id });
    if (index !== -1) {
      this.setState({ selected: remove(this.state.selected, { id }) });
    } else {
      this.setState({ selected: push(this.state.selected, { id }) });
    }
  };

  onReviewMultiple = () => {
    this.onUpdateMultiple({ status: applicationStatuses.review });
  };

  onSelectMultiple = () => {
    this.onUpdateMultiple({ status: applicationStatuses.selected });
  };

  onRejectMultiple = () => {
    this.onUpdateMultiple({ status: applicationStatuses.rejected });
  };

  onUpdateMultiple = (update) => {
    const {
      campaign: { id },
      updateApplications,
    } = this.props;
    const { selected } = this.state;

    const applications = selected.map((application) => ({
      ...application,
      ...update,
    }));

    return updateApplications(id, applications).then(() =>
      this.setState({ selected: [] }),
    );
  };

  renderMultipleSelectionPanel() {
    const { selected } = this.state;

    if (selected.length <= 0) {
      return null;
    }

    return (
      <div className="text-center mb-1">
        <span className="mr-1">
          update {selected.length} application{selected.length > 1 && 's'}
        </span>
        <button className="btn btn-info mr-1" onClick={this.onSelectMultiple}>
          select
        </button>
        <button className="btn btn-info mr-1" onClick={this.onReviewMultiple}>
          review
        </button>
        <button className="btn btn-danger" onClick={this.onRejectMultiple}>
          reject
        </button>
      </div>
    );
  }

  render() {
    const { applications, filters, ...props } = this.props;
    const { selected } = this.state;

    return (
      <div className="applications-list row">
        <div className="col">
          {this.renderMultipleSelectionPanel()}
          {isEmpty(applications) ? (
            <ApplicationsListEmpty filters={filters} className="pt-3" />
          ) : (
            <ApplicationsList
              {...props}
              applications={applications}
              selectApplication={this.onApplicationSelect}
              selectedApplications={selected}
            />
          )}
        </div>
      </div>
    );
  }
}

CampaignApplicationsList.propTypes = {
  applications: PropTypes.array.isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  filters: PropTypes.any,
  updateApplications: PropTypes.func.isRequired,
};

CampaignApplicationsList.defaultProps = {
  filters: undefined,
};

export default CampaignApplicationsList;
