import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Event, toColor } from '../components';

const { Avatar, ContentContainer, EventHeadline, EventEdits, EventBodyButton } =
  Event;

export default ({ getReviewUrl, buttonLabel }) => {
  function TikTokReviewEventView({
    createdAt,
    issuer,
    title,
    content,
    initials,
    campaign,
    references,
  }) {
    if (issuer.type !== 'user') {
      return null;
    }

    return (
      <div className={cx('row', `${issuer.type}-action`)}>
        {/* Image */}
        <Avatar
          name={initials}
          color={issuer.type === 'user' ? toColor(initials) : null}
        />
        {/* Message */}
        <ContentContainer>
          <EventHeadline title={title} createdAt={createdAt} />

          <EventBodyButton
            caption={buttonLabel}
            url={getReviewUrl({
              campaign,
              references,
              version: content.version,
            })}
          />

          <EventEdits text={issuer.type === 'user' ? content.caption : null} />
        </ContentContainer>
      </div>
    );
  }

  TikTokReviewEventView.propTypes = {
    createdAt: PropTypes.instanceOf(Date).isRequired,
    issuer: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      type: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.shape({
      version: PropTypes.number.isRequired,
      caption: PropTypes.string,
      media: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    initials: PropTypes.string.isRequired,
    campaign: PropTypes.object.isRequired,
    references: PropTypes.shape({
      campaignId: PropTypes.string.isRequired,
      applicationId: PropTypes.string.isRequired,
    }).isRequired,
  };

  return TikTokReviewEventView;
};
