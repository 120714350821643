import { isFeatureAvailable } from 'source/utils/features';
import { createSetupContainer } from './components';

/* == form sections == */
const formSections = ({ appUser }) => [
  {
    id: 'create-campaign',
    headline: 'Create a new campaign',
    forms: [
      {
        id: 'init',
        withProps: {
          payloadConstants: {
            type: 'tiktok',
            pricing: 'budget',
          },
        },
      },
    ],
  },
  {
    id: 'details',
    headline: 'Define campaign details',
    forms: [
      'settings',
      {
        id: 'contract',
        withProps: { reachKPI: 'followers', unitOfParticipation: 'posts' },
      },
      isFeatureAvailable('campaignBonuses')(appUser) ? 'bonuses' : null,
      'categories',
    ],
  },
  {
    id: 'mission',
    headline: 'Define the mission',
    forms: ['briefing', 'timeline', 'productShipment'],
  },
  {
    id: 'tasks',
    headline: 'Define tasks for influencers',
    forms: ['preTasks', 'tasks', 'postTasks'],
  },
  {
    id: 'extras',
    headline: 'Extras',
    forms: ['attachments'],
  },
];

/* == connected, container == */
export default createSetupContainer({
  formSections,
  label: 'TikTok',
});
