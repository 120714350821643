import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

function CategoriesFilterType({ input }) {
  const { value, onChange } = input;

  const onFilterChange = (type) => (e) => {
    e.preventDefault();

    onChange(type);
  };

  return (
    <div className="btn-group mb-2 w-100" role="group">
      <div className="btn-group w-50">
        <button
          type="button"
          className={cx({
            btn: true,
            'w-100': true,
            'btn-outline-primary': value !== 'and',
            'btn-primary': value === 'and',
          })}
          onClick={onFilterChange('and')}
        >
          And
        </button>
      </div>
      <div className="btn-group w-50">
        <button
          type="button"
          className={cx({
            btn: true,
            'w-100': true,
            'btn-outline-primary': value !== 'or',
            'btn-primary': value === 'or',
          })}
          onClick={onFilterChange('or')}
        >
          Or
        </button>
      </div>
    </div>
  );
}

CategoriesFilterType.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
};

export default CategoriesFilterType;
