import { createSelector, createStructuredSelector } from 'reselect';
import { isDirty } from 'redux-form';
import get from 'lodash/get';

import { getCampaignManager } from 'source/utils/acl';

const getCampaign = (state, props) => props.campaign;
const getFormDirty = (state, props) => isDirty(props.form)(state);
const getDefaultValues = () => ({ currency: 'EUR' });
const getPrivateNetworkId = (state, props) =>
  get(props.campaign, 'settings.privateNetworkId');
const getPrivateNetworks = (state, props) => {
  if (props.privateNetworks.data) {
    props.privateNetworks.data.sort((a, b) => a.name.localeCompare(b.name));
  }
  return props.privateNetworks;
};
const getClients = (state, props) => {
  if (props.clients.data) {
    props.clients.data.sort((a, b) => a.name.localeCompare(b.name));
  }
  return props.clients;
};

const getPrivateNetworkFomPayload = createSelector(
  getCampaign,
  getPrivateNetworks,
  ({ settings = {}, privateNetworks = [] } = {}) =>
    privateNetworks.find((pn) => pn.id === settings.privateNetworkId),
);

const getIsPrivateNetworkPresentInPayload = createSelector(
  getPrivateNetworkFomPayload,
  Boolean,
);

const getInitialValues = createSelector(
  getDefaultValues,
  getCampaign,
  (defaultValues, campaign) => {
    const settings = get(campaign, 'settings', {});

    if (settings.managerName) {
      settings.managerName = settings.managerName.split('@')[0];
    }

    return {
      ...defaultValues,
      ...settings,
    };
  },
);

const getHeadline = createSelector(getFormDirty, (dirty) => {
  let headline = 'Campaign Settings';
  if (dirty) {
    headline += '*';
  }

  return headline;
});

const getSubHeadline = createSelector(getCampaign, (campaign = {}) => {
  const settings = get(campaign, 'settings', {});

  let subHeadline = '';
  if (settings.managerName) {
    const manager = getCampaignManager({ username: settings.managerName });

    subHeadline += `managed by ${manager?.name || settings.managerName}`;
  }

  if (settings.currency) {
    subHeadline += ` currency ${settings.currency}`;
  }

  return subHeadline;
});

export default createStructuredSelector({
  initialValues: getInitialValues,
  headline: getHeadline,
  subHeadline: getSubHeadline,
  clients: getClients,
  privateNetworks: getPrivateNetworks,
  privateNetworkId: getPrivateNetworkId,
  isPrivateNetworkPresentInPayload: getIsPrivateNetworkPresentInPayload,
});
