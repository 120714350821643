import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';

import {
  withSubmissionValidation,
  withNextPanelAfterSubmission,
} from '../../enhancers';

const toExternal = (values) =>
  Object.keys(values)
    .filter((platform) => values[platform]) // only keep positive ones
    .map((platform) => ({ type: platform }));

const submitSettings =
  ({ onUpdateCampaign }) =>
  (values, __, props) =>
    onUpdateCampaign(props.campaign.id, {
      socialMediaSharing: toExternal(values),
    });

export const withSubmissionHandler = compose(
  setPropTypes({
    campaign: PropTypes.shape({ id: PropTypes.string.isRequired }),
    onUpdateCampaign: PropTypes.func.isRequired,
  }),
  withHandlers({ onSubmit: submitSettings }),
  withSubmissionValidation,
  withNextPanelAfterSubmission,
);
