import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cx from 'classnames';

import Markdown from 'source/components/common/markdown';

function Buttons({ className, onSaveClick, onCancelClick }) {
  return (
    <div className={className}>
      <button
        type="button"
        className="btn btn-outline-secondary"
        onClick={onCancelClick}
      >
        Cancel
      </button>
      <button
        type="button"
        className="btn btn-danger ml-2"
        onClick={onSaveClick}
      >
        Save
      </button>
    </div>
  );
}

Buttons.propTypes = {
  className: PropTypes.string,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

Buttons.defaultProps = {
  className: '',
};

const MarkdownTextAreaEditable = React.memo(
  ({
    className,
    defaultMode,
    value: defaultValue,
    onSave,
    onChange,
    rows,
    title,
    buttonsPosition,
    lastEditLabel,
  }) => {
    const [editMode, setEditMode] = React.useState(defaultMode === 'edit');
    const [value, setValue] = React.useState(defaultValue);
    const [originalValue, setOriginalValue] = React.useState(defaultValue);

    const toggleEditMode = () => setEditMode(!editMode);

    const onTextChange = (e) => {
      setValue(e.target.value);
      onChange(e);
    };

    const cancelEdit = (e) => {
      setValue(originalValue);
      toggleEditMode();
      // this simulates onChange when Cancel action is triggered
      // and new value needs to be discarded
      e.target.value = originalValue;
      onChange(e);
    };

    const saveValue = () => {
      setOriginalValue(value);
      toggleEditMode();
      onSave(value);
    };

    const onEditClick = (e) => {
      e.preventDefault();

      setOriginalValue(value);
      toggleEditMode();
    };

    return (
      <div className={className}>
        <div className={cx('d-flex', { 'justify-content-between': editMode })}>
          <span className="text-muted">{title}</span>
          {!editMode && (
            <span className="text-muted ml-2">
              <a href="#" onClick={onEditClick}>
                Edit
              </a>
            </span>
          )}
          {editMode && buttonsPosition === 'top' && (
            <Buttons
              editMode={editMode}
              onSaveClick={saveValue}
              onCancelClick={cancelEdit}
            />
          )}
        </div>
        {lastEditLabel && <small className="text-muted">{lastEditLabel}</small>}
        {editMode ? (
          <>
            <textarea
              className="form-control mt-1 mb-2"
              rows={rows}
              value={value}
              onChange={onTextChange}
            />

            {editMode && buttonsPosition === 'bottom' && (
              <Buttons
                className="d-flex justify-content-end"
                editMode={editMode}
                onSaveClick={saveValue}
                onCancelClick={cancelEdit}
              />
            )}
          </>
        ) : (
          <div onClick={toggleEditMode}>
            <Markdown markdown={value} />
          </div>
        )}
      </div>
    );
  },
);

MarkdownTextAreaEditable.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  defaultMode: PropTypes.oneOf(['preview', 'edit']),
  buttonsPosition: PropTypes.oneOf(['top', 'bottom']),
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastEditLabel: PropTypes.string,
};

MarkdownTextAreaEditable.defaultProps = {
  value: '',
  title: '',
  className: '',
  rows: '10',
  defaultMode: 'preview',
  buttonsPosition: 'top',
  onChange: noop,
  lastEditLabel: '',
};

export default MarkdownTextAreaEditable;
