import { createSetupContainer } from './components';

/* == form sections == */
const formSections = () => [
  {
    id: 'create-campaign',
    headline: 'Create a new campaign',
    forms: [
      {
        id: 'init',
        withProps: {
          payloadConstants: {
            type: 'website',
            pricing: 'bucket',
          },
        },
      },
    ],
  },
  {
    id: 'details',
    headline: 'Define campaign settings',
    forms: ['settings', 'budget'],
  },
  {
    id: 'mission',
    headline: 'Define the mission',
    forms: ['briefing', 'socialMediaSharing', 'timeline', 'productShipment'],
  },
  {
    id: 'influencers',
    headline: 'Define influencer requirements',
    forms: ['clientsAndReach', 'countries', 'categories'],
  },
  {
    id: 'tasks',
    headline: 'Create tasks for influencers',
    forms: ['preTasks', 'tasks', 'postTasks'],
  },
  {
    id: 'extras',
    headline: 'Extras',
    forms: ['attachments'],
  },
];

/* == connected container == */
export default createSetupContainer({
  formSections,
  label: 'Website (Bucket Pricing)',
});
