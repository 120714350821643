import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Icon from 'source/components/common/icon';
import ExternalLink from 'source/scenes/components/externalLink';

function ChannelLink({ channel: { name, data, platform } }) {
  return (
    <div className="d-flex align-items-center channel-icon">
      <Icon name={platform} className="small-icon mr-2" />
      <p className="form-control-plaintext truncate">
        <ExternalLink href={get(data, 'url')}>{name}</ExternalLink>
      </p>
    </div>
  );
}

ChannelLink.propTypes = {
  channel: PropTypes.shape({
    platform: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    data: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ChannelLink;
