import React from 'react';
import PropTypes from 'prop-types';

import { idType } from 'source/utils/propTypes';

import TableApplicationsDisplay from './tableApplicationsDisplay';
import {
  CampaignsStatusCell,
  CampaignTodosStatusCell,
  CampaignActions,
} from './rowComponents';

function WebsiteCampaignV1TableRow({ campaign }) {
  return (
    <tr>
      <td className="col-todos">
        <CampaignTodosStatusCell
          status={campaign.status}
          todos={campaign.summary.todos.total}
        />
      </td>
      <td className="col-status">
        <CampaignsStatusCell label={campaign.status} />
      </td>
      <td className="col-name truncate">{campaign.name}</td>
      <td className="col-counter col-live">
        <TableApplicationsDisplay
          type="secondary"
          applications={campaign.applications.live}
          sumUp
          noTag
        />
      </td>
      <td className="col-counter col-mission">
        <TableApplicationsDisplay
          type="secondary"
          applications={campaign.applications.mission}
          sumUp
          noTag
        />
      </td>
      <td className="col-counter col-missing">
        <TableApplicationsDisplay
          type="danger"
          applications={campaign.applications.missing}
        />
      </td>
      <td className="col-counter col-review">
        <TableApplicationsDisplay
          type="secondary"
          applications={campaign.applications.review}
        />
      </td>
      <td className="col-counter col-target">
        <TableApplicationsDisplay
          type="secondary"
          applications={campaign.applications.total}
        />
      </td>
      <td className="col-details">
        <CampaignActions campaign={campaign} />
      </td>
    </tr>
  );
}

WebsiteCampaignV1TableRow.propTypes = {
  campaign: PropTypes.shape({
    id: idType.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    summary: PropTypes.shape({
      todos: PropTypes.shape({
        total: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    applications: PropTypes.shape({
      live: PropTypes.any.isRequired,
      mission: PropTypes.any.isRequired,
      missing: PropTypes.any.isRequired,
      review: PropTypes.any.isRequired,
      total: PropTypes.any.isRequired,
    }).isRequired,
  }).isRequired,
};

export default WebsiteCampaignV1TableRow;
