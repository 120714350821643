import moment from 'moment';
import every from 'lodash/every';
import find from 'lodash/find';
import { createSelector } from 'reselect';
import includes from 'lodash/includes';

import { getPages as getPaginationPages } from 'source/components/common/pagination';

const getLinks = (state) => state.data.links;
const getUsers = (state) => state.data.users;
const getCampaigns = (state) => state.data.campaigns;
const getFilters = (state) => state.filters.filters;
const getFilterTypes = (state) => state.filters.types;
const getPerPage = (state) => state.table.perPage;
const getPage = (state) => state.table.page;
const getSelectedLinkId = (state) => state.linkDetails.selectedLink;

const normalize = (value) => ({ value });

export const normalizeUser = (user) => {
  if (!user) {
    return normalize('');
  }

  const userData = [`[${user.id}]`];
  if (user.firstname) {
    userData.push(user.firstname);
  }
  if (user.email) {
    userData.push(`<${user.email}>`);
  }
  return { id: user.id, value: userData.join(' '), orig: user };
};

const normalizeCampaign = (campaign) => {
  if (!campaign) {
    return normalize('');
  }

  return {
    id: campaign.id,
    value: campaign.name,
    orig: campaign,
  };
};

const normalizeDate = (date) =>
  normalize(moment(date).format('YYYY-MM-DD HH:mm'));

export const normalizeLink = (link) => {
  if (!link) {
    return null;
  }

  return {
    id: link.id,
    url: normalize(link.url),
    targetUrl: normalize(link.targetUrl),
    user: normalizeUser(link.user),
    campaign: normalizeCampaign(link.campaign),
    createdAt: normalizeDate(link.createdAt),
  };
};

export const getNormalizedUsers = createSelector([getUsers], (users) =>
  users.map(normalizeUser),
);

export const getNormalizedLinks = createSelector([getLinks], (links) =>
  links.map(normalizeLink),
);

export const getNormalizedCampaigns = createSelector(
  [getCampaigns],
  (campaigns) => campaigns.map(normalizeCampaign),
);

/* return set of filterTypes - filters */
export const getAvailableFiltersTypes = createSelector(
  [getFilters, getFilterTypes],
  (filters, filterTypes) => {
    const taken = filters.map((filter) => filter.filter);
    return filterTypes.filter(
      (filterType) => !includes(taken, filterType.value),
    );
  },
);

export const getDisableAdd = createSelector(
  [getAvailableFiltersTypes],
  (filterTypes) => filterTypes.length <= 0,
);

const getFilterTypeForFilter = (filterTypes, filter) =>
  find(filterTypes, { value: filter });

export const getFiltersWithOptions = createSelector(
  [getFilters, getFilterTypes, getAvailableFiltersTypes],
  (filters, filterTypes, availableFilterTypes) =>
    filters.map((filter) => {
      const options = [
        getFilterTypeForFilter(filterTypes, filter.filter),
        ...availableFilterTypes,
      ];

      return {
        ...filter,
        options,
      };
    }),
);

export const getFilteredLinks = createSelector(
  [getFilters, getNormalizedLinks],
  (filters, links) => {
    if (!filters.length) {
      return links;
    }

    return links.filter((link) =>
      every(filters, (filter) => link[filter.filter].value.match(filter.regex)),
    );
  },
);

export const getPages = createSelector(
  [getPerPage, getFilteredLinks],
  (perPage, links) => getPaginationPages(links.length, perPage),
);

export const getFilteredAndSlicedLinks = createSelector(
  [getPage, getPerPage, getFilteredLinks],
  (page, perPage, links) => links.slice(page * perPage, (page + 1) * perPage),
);

export const getSelectedLink = createSelector(
  [getSelectedLinkId, getLinks],
  (linkId, links) => find(links, { id: linkId }),
);
