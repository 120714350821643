import { createSelector, createStructuredSelector } from 'reselect';
import { isDirty } from 'redux-form';
import get from 'lodash/get';

const getIabCategories = (state) => state.iabCategories;
const getCampaign = (state, props) => props.campaign;
const getFormDirty = (state, props) => isDirty(props.form)(state);

const getInitialValues = createSelector(getCampaign, (campaign) => ({
  categories: {
    whitelist: get(campaign, 'categories.whitelist', []),
  },
}));

const getHeadline = createSelector(getFormDirty, (dirty) => {
  let headline = 'Categories';
  if (dirty) {
    headline += '*';
  }

  return headline;
});

const getSubHeadline = createSelector(getCampaign, (campaign = {}) => {
  const categories = get(campaign, 'categories.whitelist', []);

  return `${categories.length} selected categories`;
});

export default createStructuredSelector({
  initialValues: getInitialValues,
  iabCategories: getIabCategories,
  headline: getHeadline,
  subHeadline: getSubHeadline,
});
