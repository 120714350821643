import { asyncActionCreator } from '@blogfoster/redux-async-utils';

import api from 'source/actions/campaigns/api';

export const actionTypes = {
  CREATE: 'data/cave/CREATE',
};

/**
 * Upload Files to Aurum/S3
 *
 * @typedef ApiUploadResponse Array<Object<{ key: String, url: String, filename: String }>>
 *
 * @param {Array<Object<{ key: String, file: File, [filename: String] }>>} files - file definitions
 * @resolves {ApiUploadResponse} uploaded files
 */
export const filesUpload = (name) =>
  asyncActionCreator(
    actionTypes.CREATE,
    (files) => (dispatch) => dispatch(api.postCave({ files })),
    { name },
  );

// Export general versions of these actions creators, might be useful

export const uploadFiles = filesUpload('default');
