import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { getCurrencySymbolForString } from 'source/utils';

/**
 * Returns the currency of the campaign as a symbol if possible.
 *
 * @param {{ settings?: { currency?: string } }} campaign
 * @param {{ prefix?: string, symbol?: boolean }} options
 * @returns {string|undefined}
 */
export const getCampaignCurrency = (
  campaign,
  { prefix = '', symbol = false } = {},
) => {
  let currency = get(campaign, 'settings.currency');
  if (symbol) {
    currency = getCurrencySymbolForString(currency);
  }

  return isNil(currency) ? '' : `${prefix}${currency}`;
};
