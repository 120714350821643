import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { FormControlFeedback } from 'source/components/common/forms';
import MarkdownTextareaSide from 'source/components/common/markdownTextAreaSide';

/**
 * Redux-form compatible wrapper for `MarkdownTextAreaSide`.
 *
 * @example
 *
 * import MarkdownTextareaSide from 'reduxFormAdapters/markdownTextAreaSide';
 *
 * <Field
 *   name="body"
 *   component={MarkdownTextareaSide}
 *   placeholder="write some markdown ..."
 * />
 */
function WrappedMarkdownTextareaSide({
  // redux-form props
  input,
  input: { name },
  meta: { touched, error, form },

  // custom props
  className,
  label,
  ...inputProps
}) {
  const errorMsg = touched && error ? error : null;

  return (
    <div className={cx('form-group', className)}>
      {label && <label htmlFor={`${form}-${name}`}>{label}</label>}
      <MarkdownTextareaSide
        {...input}
        id={`${form}-${name}`}
        {...inputProps}
        className={cx({ 'is-invalid': touched && error })}
      />
      <FormControlFeedback message={errorMsg} style={{ display: 'inherit' }} />
    </div>
  );
}

WrappedMarkdownTextareaSide.propTypes = {
  // redux-form props
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    form: PropTypes.string.isRequired,
  }).isRequired,

  // custom props
  className: PropTypes.string,
  label: PropTypes.string,
};

WrappedMarkdownTextareaSide.defaultProps = {
  className: '',
  label: '',
};

export default WrappedMarkdownTextareaSide;
