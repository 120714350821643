import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import { CampaignMissions } from '../components';
import {
  EventDefault,
  EventPayment,
  EventApplicationCreated,
  EventLink,
  EventTaskEdit,
} from '../components/sharedEvents';
import createTikTokReviewEventView from './createTikTokReviewEventView';
import ChannelLink from '../components/channelLink';
import { withApplications, withLoadingGuard, withErrorGuard } from '../common';
import { campaignMissions } from '../selectors';
import actions from '../actions';
import {
  getContentPreviewReviewUrl,
  getAfterPublicationUploadsReviewUrl,
} from '../utils';

const withConfig = withProps({
  eventComponents: {
    application_created: EventApplicationCreated,
    application_content_preview_submitted: createTikTokReviewEventView({
      getReviewUrl: getContentPreviewReviewUrl,
      buttonLabel: 'Review Post',
    }),
    application_content_publication_changed: EventLink,
    application_after_publication_uploads_changed: createTikTokReviewEventView({
      getReviewUrl: getAfterPublicationUploadsReviewUrl,
      buttonLabel: 'Review Insights',
    }),
    application_payment_changed: EventPayment,
    application_task_overrides_changed: EventTaskEdit,
    campaign_tasks_changed: EventTaskEdit,
    default: EventDefault,
  },
  getHeading: (application) => application.user.firstname,
  getSubheading: (application) =>
    `(${application.channel.name}, followers: ${application.match.reach})`,
  channelLinkComponent: ChannelLink,
});

const mapStateToProps = campaignMissions;
const mapDispatchToProps = actions;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApplications(),
  withConfig,
  withLoadingGuard,
  withErrorGuard,
)(CampaignMissions);
