import { createSelector, createStructuredSelector } from 'reselect';

import { getPages as getPaginationPages } from 'source/components/common/pagination';

const getListState = (state) => state.scenes.users.listState;
const getDetailsState = (state) => state.scenes.users.detailsState;
const getUser = (state) => state.scenes.users.data.user;
const getUsers = (state) => state.scenes.users.data.users;
const getFilters = (state) => state.scenes.users.filters;
const getUserForms = (state) => state.scenes.users.forms;

const getPage = (state) => state.scenes.users.paging.page;
const getPerPage = (state) => state.scenes.users.paging.pageSize;
const getPagingCount = (state) => state.scenes.users.paging.count;
const getPagingTotal = (state) => state.scenes.users.paging.total;

const getUserDetailsForm = createSelector(
  getUserForms,
  getUser,
  (forms, user) => ({
    ...forms.userDetails,
    email: forms.userDetails.pristine ? user.email : forms.userDetails.email,
  }),
);

const getGroupsTablePages = createSelector(
  getPerPage,
  getPagingCount,
  (perPage, count) => getPaginationPages(count, perPage),
);

const getCounter = createSelector(
  getPagingCount,
  getPagingTotal,
  (count, total) => ({ count, total }),
);

export const userDetailsSelector = createStructuredSelector({
  state: getDetailsState,
  user: getUser,
  form: getUserDetailsForm,
});

export const usersListSelector = createStructuredSelector({
  state: getListState,
  users: getUsers,
  filters: getFilters,
  page: getPage,
  pages: getGroupsTablePages,
  counter: getCounter,
});
