import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Fields } from 'redux-form';
import { compose } from 'recompose';
import get from 'lodash/get';

import Alert from 'source/components/common/alert';

import UploadsList from './components/uploadsList';
import Attachment from './components/attachment';
import selector from './selectors';
import validate from './validation';
import { withSubmissionHandler } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';

const enhance = compose(
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
  reduxForm({
    validate,
    touchOnBlur: false,
    touchOnChange: true,
    enableReinitialize: true,
  }),
);

class AttachmentsForm extends React.PureComponent {
  handleFileChange = (file) => {
    const { change } = this.props;

    change('filename', get(file, 'name', ''));
  };

  render() {
    const { pristine, invalid, uploads, error, handleSubmit } = this.props;

    return (
      <div>
        <Alert message={get(error, 'message')} />
        <UploadsList uploads={uploads} />
        <form onSubmit={handleSubmit}>
          <h5 className="text-muted pt-3">Add a new Upload to this Campaign</h5>

          <Fields
            names={['file', 'url', 'filename']}
            component={Attachment}
            onFileChange={this.handleFileChange}
          />

          <div className="row justify-content-end pr-3">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={pristine || invalid}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

AttachmentsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  uploads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired,
    }),
  ),
  change: PropTypes.func.isRequired, // redux-form injected function
  error: PropTypes.object,
};

AttachmentsForm.defaultProps = {
  uploads: [],
  error: undefined,
};

export default enhance(AttachmentsForm);
