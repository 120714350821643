import React from 'react';
import PropTypes from 'prop-types';
import withOnClickOutside from 'react-click-outside';
import { withState, withHandlers, compose } from 'recompose';

import Checkbox from 'source/scenes/components/checkbox';
import SettingsBar from 'source/components/common/settingsBar';

import CampaignGoals from './campaignGoals';
import { OverviewPanelList } from '../overviewPanelList';
import ListApplicationsReportButton from '../listApplicationsReportButton';

// Helpers

const byStatusShape = PropTypes.shape({
  new: PropTypes.any.isRequired,
  shortlisted: PropTypes.any.isRequired,
  review: PropTypes.any.isRequired,
  selected: PropTypes.any.isRequired,
  approved: PropTypes.any.isRequired,
  rejected: PropTypes.any.isRequired,
});

// HoCs

const withPanelState = withState('selectedPanel', 'setSelectedPanel', '');

const withStatusUpdateHandlers = withHandlers({
  markRejected:
    ({ onUpdateApplicationStatus }) =>
    (id) =>
      onUpdateApplicationStatus(id, 'rejected'),

  markDeleted:
    ({ onDeleteApplication }) =>
    (id, notificationEmailPayload) =>
      onDeleteApplication(id, notificationEmailPayload),

  markNew:
    ({ onUpdateApplicationStatus }) =>
    (id) =>
      onUpdateApplicationStatus(id, 'new'),

  markShortlisted:
    ({ onUpdateApplicationStatus }) =>
    (id) =>
      onUpdateApplicationStatus(id, 'shortlisted'),

  markReview:
    ({ onUpdateApplicationStatus }) =>
    (id) =>
      onUpdateApplicationStatus(id, 'review'),

  markSelected:
    ({ onUpdateApplicationStatus }) =>
    (id) =>
      onUpdateApplicationStatus(id, 'selected'),
});

const withOutsideClickReset = compose(withOnClickOutside, (Component) => {
  class WithClickOutsideHandler extends React.Component {
    // eslint-disable-next-line
    handleClickOutside() {
      const { campaign } = this.props;

      if (document.getElementById('delete-application-modal')) {
        return;
      }

      this.props.setSelectedPanel('');
      this.props.syncApplicationWithLocation(campaign);
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  WithClickOutsideHandler.propTypes = {
    campaign: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    setSelectedPanel: PropTypes.func.isRequired,
    syncApplicationWithLocation: PropTypes.func.isRequired,
  };

  return WithClickOutsideHandler;
});

// Components

function ApplicationsOverview({
  overviewFilter: { showRejected },
  onShowRejectedClick,
  campaign,
  accessToken,
  ...props
}) {
  return (
    <section className="application-review col">
      <SettingsBar className="pb-3">
        <ListApplicationsReportButton
          campaignId={campaign.id}
          accessToken={accessToken}
        >
          Applications Report
        </ListApplicationsReportButton>
        <Checkbox
          id="showRejected"
          className="ml-3"
          label="Show rejected"
          checked={showRejected}
          disabled={props.loading}
          onClick={onShowRejectedClick}
          managed
        />
      </SettingsBar>
      <CampaignGoals campaign={campaign} />
      <OverviewPanelList
        {...props}
        campaign={campaign}
        showRejected={showRejected}
        accessToken={accessToken}
      />
    </section>
  );
}

ApplicationsOverview.propTypes = {
  applicationsByStatus: byStatusShape.isRequired,
  metricsByStatus: byStatusShape.isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  accessToken: PropTypes.string.isRequired,
  markNew: PropTypes.func.isRequired,
  markReview: PropTypes.func.isRequired,
  markShortlisted: PropTypes.func.isRequired,
  markSelected: PropTypes.func.isRequired,
  markRejected: PropTypes.func.isRequired,
  markDeleted: PropTypes.func.isRequired,
  selectedPanel: PropTypes.string.isRequired,
  overviewFilter: PropTypes.shape({
    showRejected: PropTypes.bool.isRequired,
  }).isRequired,
  setSelectedPanel: PropTypes.func.isRequired,
  onUpdateApplicationStatus: PropTypes.func.isRequired,
  onShowRejectedClick: PropTypes.func.isRequired,
};

export default compose(
  withPanelState,
  withStatusUpdateHandlers,
  withOutsideClickReset,
)(ApplicationsOverview);
