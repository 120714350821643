import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import {
  withApplications,
  withLoadingGuard,
  withErrorGuard,
} from 'source/scenes/campaignDetail/missions/common';
import { getCampaign } from 'source/data/selectors';
import {
  getApplicationsOnMission,
  getApplicationsRequestState,
} from 'source/scenes/campaignDetail/missions/selectors';
import actions from 'source/scenes/campaignDetail/missions/actions';
import SocialView from './components/viewSocial';

const mapStateToProps = createStructuredSelector({
  applicationsRequestState: getApplicationsRequestState,
  applications: getApplicationsOnMission,
  campaign: getCampaign,
  user: (state) => state.app.user,
});
const mapDispatchToProps = actions;

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApplications({ defaultFields: ['id', 'mission', 'status'] }),
  withLoadingGuard,
  withErrorGuard,
);

export default enhance(SocialView);
