import development from './development';
import stage from './stage';
import production from './production';

const env = process.env.TARGET_ENV || 'development';

const configs = {
  development,
  stage,
  production,
};

export default configs[env];
