import React from 'react';
import PropTypes from 'prop-types';

import { idType } from 'source/utils/propTypes';

import {
  CampaignsStatusCell,
  CampaignTodosStatusCell,
  CampaignActions,
} from './rowComponents';

function CampaignV2TableRow({ campaign }) {
  return (
    <tr>
      <td className="col-todos">
        <CampaignTodosStatusCell
          status={campaign.status}
          todos={campaign.summary.todos.total}
        />
      </td>
      <td className="col-status">
        <CampaignsStatusCell label={campaign.status} />
      </td>
      <td className="col-name truncate">{campaign.name}</td>
      <td className="col-counter col-live">
        <span>{campaign.summary.reach.live}</span>
      </td>
      <td className="col-counter col-mission">
        <span>{campaign.summary.reach.mission}</span>
      </td>
      <td className="col-counter col-missing">
        {campaign.summary.reach.missing}
      </td>
      <td className="col-counter col-review">
        {campaign.summary.reach.review}
      </td>
      <td className="col-counter col-target">
        <span>{campaign.summary.reach.target}</span>
      </td>
      <td className="col-details">
        <CampaignActions campaign={campaign} />
      </td>
    </tr>
  );
}

CampaignV2TableRow.propTypes = {
  campaign: PropTypes.shape({
    id: idType.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    summary: PropTypes.shape({
      todos: PropTypes.shape({
        total: PropTypes.number.isRequired,
      }).isRequired,
      reach: PropTypes.shape({
        live: PropTypes.number.isRequired,
        mission: PropTypes.number.isRequired,
        missing: PropTypes.number.isRequired,
        review: PropTypes.number.isRequired,
        target: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default CampaignV2TableRow;
