import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * Redux-form compatible wrapper for `checkbox` elements.
 *
 * @example
 *
 * <Field
 *   name="firstname"
 *   component={Checkbox}
 *   label="Firstname:"
 *   placeholder="Your first name"
 * />
 */
function Checkbox({
  // redux-form props
  input,
  input: { name, value = false },
  meta: { touched, error, form },

  // custom props
  className,
  label,
  onClear,
  ...inputProps
}) {
  const id = `${form}-${name}`;

  return (
    <div className={cx('form-check', className)}>
      <input
        {...input}
        checked={!!value}
        id={id}
        className={cx('form-check-input', { 'is-invalid': touched && error })}
        type="checkbox"
        {...inputProps}
      />
      <label htmlFor={id} className="form-check-label">
        {label}
      </label>
      {touched && error && <small className="invalid-feedback">{error}</small>}
    </div>
  );
}

Checkbox.propTypes = {
  // redux-form props
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    form: PropTypes.string.isRequired,
  }).isRequired,

  // custom props
  className: PropTypes.string,
  label: PropTypes.string,
  onClear: PropTypes.func,
};

Checkbox.defaultProps = {
  className: '',
  label: null,
  onClear: null,
};

export default Checkbox;
