import React from 'react';
import { Field } from 'redux-form';

import MarkdownTextarea from 'source/scenes/components/reduxFormAdapters/markdownTextarea';

function TextTask() {
  return (
    <Field
      name="body"
      component={MarkdownTextarea}
      placeholder="write some markdown ..."
    />
  );
}

export default TextTask;
