import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Badge from 'source/components/common/badge';
import Markdown from 'source/components/common/markdown';

const IndividualTasks = React.memo(({ application }) => {
  const { tasks, id, type, campaignId } = application;
  const individualTasks = tasks.filter((task) => task.individual);

  if (individualTasks.length === 0) {
    return <div className="mt-2 mb-2 text-muted">No Individual Tasks</div>;
  }

  return (
    <div className="individual-tasks mt-2 mb-2">
      {individualTasks.map((task) => (
        <p key={task.id}>
          <Link to={`/campaigns/${type}/${campaignId}/applications/${id}`}>
            <div className="text-muted">{task.type}</div>
            <div>
              {task.headline}
              {!task.updated && (
                <Badge pill type="warning" className="ml-2">
                  unedited
                </Badge>
              )}
            </div>
            {task.body ? (
              <div className="font-weight-bold">
                <Markdown markdown={task.body} />
              </div>
            ) : (
              '—'
            )}
          </Link>
        </p>
      ))}
    </div>
  );
});

IndividualTasks.propTypes = {
  application: PropTypes.shape({
    tasks: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string,
    type: PropTypes.string,
    campaignId: PropTypes.string,
  }),
};

IndividualTasks.defaultProps = {
  application: {
    tasks: [],
  },
};

export default IndividualTasks;
