import React from 'react';
import PropTypes from 'prop-types';

export default class Collapsible extends React.PureComponent {
  static propTypes = {
    initialShow: PropTypes.bool,
    children: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialShow: false,
  };

  constructor(props) {
    super(props);

    this.state = { show: props.initialShow };
  }

  onToggle = () => {
    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  };

  handleKeyDown = (e) => {
    if (e.key === 'Escape' && this.state.show === true) {
      this.onToggle();
    }
  };

  render() {
    const { children } = this.props;
    const { show } = this.state;

    return children({
      onToggle: this.onToggle,
      onKeyDown: this.handleKeyDown,
      show,
    });
  }
}
