import { asyncActionReducer, asyncStates } from '@blogfoster/redux-async-utils';

import {
  namespacedReducer,
  nestedReducer,
  combineReducersFlat,
} from 'source/utils/redux';
import { actionTypes as invoiceActionTypes } from 'source/data/invoices/actions';
import { getPagingFromHeaders } from 'source/components/common/pagination';

import { namespace, actionTypes } from './actions';

export const getInitialState = () => ({
  state: '',
  error: null,

  invoices: [],

  paging: {
    page: 0,
    pageSize: 30,
    count: 0,
    total: 0,
  },

  filters: {
    period: '',
    userId: '',
    invoiceId: '',
  },
});

const filtersReducer = (state = {}, action) => {
  if (action.type === actionTypes.FILTER_RESET) {
    return {
      period: '',
      userId: '',
      invoiceId: '',
    };
  }
  if (action.type !== actionTypes.FILTER_CHANGED) {
    return state;
  }

  const { filter, value } = action.payload;

  return {
    ...state,
    [filter]: value,
  };
};

const invoicesDataReducer = asyncActionReducer(invoiceActionTypes.FETCH_MANY, {
  [asyncStates.pending]: (state) => ({
    ...state,
    state: 'loading',
    error: null,
  }),
  [asyncStates.success]: (state, { payload }) => ({
    ...state,
    state: 'loaded',
    error: null,
    invoices: payload.data,
    paging: getPagingFromHeaders(payload.headers),
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    state: 'failure',
    error,
  }),
});

const resetState = (state = {}, action) => {
  if (action.type !== actionTypes.RESET_SCENE) {
    return state;
  }

  return {
    ...state,
    state: '',
    error: null,
    invoices: [],
  };
};

const initialState = getInitialState();

export default combineReducersFlat(
  [
    namespacedReducer(namespace)(invoicesDataReducer),
    resetState,
    nestedReducer('filters')(filtersReducer),
  ],
  initialState,
);
