import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import { compose } from 'redux';
import moment from 'moment';

// Private helper function to not convert empty values.
const convertDefined = (convert) => (value) => {
  if (isUndefined(value) || isNull(value) || value === '') {
    return value;
  }

  return convert(value);
};

/**
 * Parser functions that are compatible with redux-form `Field` components. Use
 * these functions to have redux-form store parsed values in the state so you
 * don't have to convert them later on in your `onSubmit` handler.
 */

export const parseToInt = convertDefined((value) => parseInt(value, 10));

export const parseToFloat = convertDefined(parseFloat);

export const toMomentDate = convertDefined((value) => moment(value));

export const toStartOf = (unit) =>
  convertDefined((value) => value.startOf(unit));

export const toEndOf = (unit) => convertDefined((value) => value.endOf(unit));

export const toISOString = convertDefined((value) => value.toISOString());
export const toStartOfDay = compose(
  toISOString,
  toStartOf('day'),
  toMomentDate,
);

export const toEndOfDay = compose(toISOString, toEndOf('day'), toMomentDate);

const toFixedNumber = (value, digits) => Number(value.toFixed(digits));

export const floatStrToPercent = convertDefined((value) => {
  const float = parseFloat(value);
  const percent = float / 100;
  /**
   * Make sure we return rounded floats
   * (e.g. 0.0699999999999 instead of 0.07)
   */
  return toFixedNumber(percent, 4);
});

/**
 * Formatter functions that are compatible with redux-form `Field` components.
 * Use these functions to have redux-form format values from the redux-form
 * state to strings that should be displayed in your inputs.
 */

export const percentToFloatStr = convertDefined((value) => {
  const float = value * 100;
  /**
   * Make sure we return rounded floats
   * (e.g. 10.699999999999 instead of 10.7)
   */
  return toFixedNumber(float, 2);
});

export const normalizePercent = (value, previousValue) => {
  if (isNaN(value)) {
    return previousValue;
  }

  if (!value) {
    return value;
  }

  if (value < 0.0001 || value > 1) {
    return previousValue;
  }

  return value;
};
