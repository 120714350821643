import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'recompose';
import cx from 'classnames';
import get from 'lodash/get';

import Alert from 'source/components/common/alert';
import Input from 'source/scenes/components/reduxFormAdapters/input';

import validate from './validation';
import selector from './selectors';
import { withSubmissionHandler } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';

const enhance = compose(
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
  reduxForm({
    validate,
    touchOnBlur: false,
    touchOnChange: true,
    enableReinitialize: true,
  }),
);

function BudgetForm({ handleSubmit, invalid, submitting, error }) {
  return (
    <form onSubmit={handleSubmit}>
      <Alert message={get(error, 'message')} />

      {/* advertiser budget */}
      <Field
        name="budget"
        component={Input}
        label="Advertiser budget*:"
        type="number"
      />

      <div className="row justify-content-end pr-3">
        <button
          type="submit"
          className={cx('btn', {
            'btn-primary': !error,
            'btn-danger': !!error,
          })}
          disabled={invalid || submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
}

BudgetForm.propTypes = {
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  error: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

BudgetForm.defaultProps = {
  submitting: false,
  invalid: false,
  error: undefined,
};

export default enhance(BudgetForm);
