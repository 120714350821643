import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import withResetScene from 'source/components/commonEnhancers/withResetScene';
import Alert from 'source/components/common/alert';

import CampaignProgress from '../progress';
import FormSections from '../forms/formSections';
import {
  withOnMount,
  withOnNextSection,
  withCategoriesData,
  withNewCampaignIndicator,
} from './enhancers';

const enhance = compose(
  withCategoriesData,
  withNewCampaignIndicator,
  withResetScene,
  withOnMount,
  withOnNextSection,
);

function CampaignSetup({ label, sections, error, ...props }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h2>{label} Campaign Settings</h2>
          <p className="text-muted">
            This view helps you to setup or edit a campaign. Click on a section
            to expand it and fill out all necessary data fields. A progress
            indicator on the right side helps you to publish the campaign or see
            if there is still necessary data to be filled out before.
          </p>
        </div>
      </div>

      {/* generic view err */}
      <div className="row justify-content-center">
        <Alert message={error} />
      </div>

      <div className="row">
        <div className="col-md-9">
          <FormSections sections={sections} {...props} />
        </div>

        <div className="col-md-3">
          <CampaignProgress {...props} />
        </div>
      </div>
    </div>
  );
}

CampaignSetup.propTypes = {
  label: PropTypes.string.isRequired,
  sections: PropTypes.any.isRequired,
  error: PropTypes.string,
};

CampaignSetup.defaultProps = {
  error: undefined,
};

export default enhance(CampaignSetup);
