import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  Avatar,
  ContentContainer,
  EventHeadline,
  EventEdits,
  EventBody,
} from '../event';
import { toColor } from '../helpers';

function EventDefault({
  createdAt,
  issuer,
  title,
  content,
  initials,
  children,
}) {
  return (
    <div className={cx('row', `${issuer.type}-action`)}>
      {/* Image */}
      <Avatar
        className={cx({
          'bf-avatar--user': issuer.type === 'user',
          'bf-avatar--office': issuer.type === 'office',
          'bf-avatar--system': issuer.type === 'system',
          'bf-avatar--client': issuer.type === 'client',
        })}
        name={issuer.type === 'client' ? '👑' : initials}
        color={issuer.type === 'user' ? toColor(initials) : null}
      />

      {/* Message */}
      <ContentContainer>
        <EventHeadline title={title} createdAt={createdAt} />
        <EventBody content={content} />
        {children}
        <EventEdits text={issuer.type === 'user' ? content : null} />
      </ContentContainer>
    </div>
  );
}

EventDefault.propTypes = {
  createdAt: PropTypes.instanceOf(Date).isRequired,
  issuer: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  initials: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default EventDefault;
