/* eslint-disable react/style-prop-object */

import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { FormattedNumber } from 'react-intl';

import Pagination from 'source/components/common/pagination';

const paymentRowBuilder = (payment) => (
  <tr key={payment.id}>
    <td>{payment.userId}</td>
    <td>
      <FormattedNumber
        value={payment.amount}
        style="currency"
        currency={payment.currency}
      />
    </td>
    <td>{payment.currency}</td>
    <td>{payment.description}</td>
    <td>{payment.approvedBy}</td>
    <td>{payment.createdAt}</td>
  </tr>
);

function PaymentsTable({ payments, pages, page, onSelectPage }) {
  return (
    <div>
      <div className="table-responsive-md">
        <table className="table table-striped">
          <thead>
            <tr>
              <th nowrap="true">User Id</th>
              <th>Amount</th>
              <th>Currency</th>
              <th>Description</th>
              <th>Approved By</th>
              <th nowrap="true">Created At</th>
            </tr>
          </thead>
          <tbody>{payments.map(paymentRowBuilder)}</tbody>
        </table>
      </div>
      <div className="row justify-content-center pt-3">
        <Pagination pages={pages} activePage={page} onSelect={onSelectPage} />
      </div>
    </div>
  );
}

PaymentsTable.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      approvedBy: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    }),
  ),
  pages: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  onSelectPage: PropTypes.any.isRequired,
};

PaymentsTable.defaultProps = {
  payments: [],
};

export default pure(PaymentsTable);
