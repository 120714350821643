import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { compose, withHandlers, setPropTypes } from 'recompose';
import find from 'lodash/find';
import pick from 'lodash/pick';

import { withSubmissionValidation } from '../../enhancers';

const submitAttachments =
  ({ onUploadFiles, onAttachFilesToCampaign }) =>
  (values, dispatch, props) => {
    const {
      campaign: { id: campaignId },
      form,
    } = props;
    const { file, filename, url } = values;

    const extractFiles = () => {
      const files = [];
      if (file) {
        files.push({ key: 'attachment', file, filename });
      }

      return files;
    };

    const submitFiles = onUploadFiles;

    const toAttachmentsPayload = (uploads) => {
      const upload = find(uploads, { key: 'attachment' });

      // shouldn't happen ...
      if (file && !upload) {
        throw new Error("we couldn't upload the file");
      }

      // if there was an upload previously, we take the url and the filename
      // from the upload response, otherwise from the user input
      return [upload ? pick(upload, ['url', 'filename']) : { url, filename }];
    };

    const attachFiles = (uploads) =>
      onAttachFilesToCampaign(campaignId, uploads);

    const clearForm = (arg) => {
      dispatch(reset(form));

      return arg;
    };

    return Promise.resolve()
      .then(extractFiles)
      .then(submitFiles)
      .then(toAttachmentsPayload)
      .then(attachFiles)
      .then(clearForm);
  };

export const withSubmissionHandler = compose(
  setPropTypes({
    campaign: PropTypes.shape({ id: PropTypes.string.isRequired }),
    form: PropTypes.string.isRequired,
    onUploadFiles: PropTypes.func.isRequired,
    onAttachFilesToCampaign: PropTypes.func.isRequired,
  }),
  withHandlers({ onSubmit: submitAttachments }),
  withSubmissionValidation,
);
