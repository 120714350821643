const buildUnfinishedTasksMessage = (heading, unfinishedTasks) => {
  const lines = ['\n---\n', `${heading}\n`];
  unfinishedTasks.forEach((task) => {
    let body = task.body || '';
    if (
      task.body &&
      (task.type === 'code' || task.type === 'link' || task.type === 'image')
    ) {
      body = `\`\`\`\n${body}\n\`\`\``;
    }

    lines.push(`- ${task.headline}`);
    lines.push(task.body ? `${body}\n` : '');
  });
  const output = lines.join('\n');

  return output;
};

export default buildUnfinishedTasksMessage;
