import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import remark from 'remark';
import html from 'remark-html';
import domPurify from 'dompurify';
import config from 'config';

import MarkdownTextAreaTabs from 'source/components/common/markdownTextAreaTabs';
import { getCurrencySymbolForString } from 'source/utils';
import { getCampaignManagerByEmail } from 'source/utils/acl';

Modal.setAppElement('#root-container');

const getDefaultContent = ({
  firstName,
  price,
  pitch,
  campaignId,
  campaignName,
  managerEmail,
  currency,
  creatorAppUrl,
}) => `Hallo ${firstName},

Leider ist deine Preisvorstellung von **${price}${getCurrencySymbolForString(
  currency,
)}** für die Kooperation _${campaignName}_ über dem, was wir normalerweise für vergleichbare Insights bezahlen.
Meinst du, du könntest uns hier noch entgegenkommen? Was ich dir anbieten kann sind *XXX€*. Ich würde mich über eine Zusammenarbeit bei dieser Kampagne sehr freuen.

Wenn dir dieser Betrag zusagt, kannst du dich gerne noch mal bewerben. Dieser Link führt direkt zu der Kampagne [${campaignName}](${creatorAppUrl}/sponsored-posts/campaigns/${campaignId})

Um dir die erneute Bewerbung leichter zu machen, kannst du den eingereichten Pitch aus dieser Mail kopieren und wieder verwendet.

${pitch
  .split('\n')
  .filter((line) => Boolean(line))
  .map((line) => `> ${line}`)
  .join('\n')}

Trage bitte auch den vereinbarten Preis in das Gegenangebot Feld.

Viele Grüße,
${getCampaignManagerByEmail(managerEmail)?.name || managerEmail}`;

function DeleteApplicationModal({ onClose, onConfirm, application, ...props }) {
  const [subject, setSubject] = React.useState('Bewerbung abgelehnt');
  const [content, setContent] = React.useState(
    getDefaultContent({
      firstName: application.user.firstname,
      price: application.match.payment.totals.price,
      pitch: application.pitch,
      campaignId: application.campaign.id,
      campaignName: application.campaign.name,
      managerEmail: application.campaign.settings.managerName,
      currency: application.campaign.settings.currency,
      creatorAppUrl: config.userApp.url,
    }),
  );

  const handleConfirm = (e) => {
    remark()
      .use(html)
      .process(content, (err, file) => {
        onConfirm(e, {
          subject,
          content: domPurify.sanitize(String(file)),
        });
      });
  };

  return (
    <Modal
      {...props}
      id="delete-application-modal"
      onRequestClose={onClose}
      contentLabel="Application Deletion Modal"
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1100,
        },
        content: {
          top: 150,
          width: '40rem',
          right: 'auto',
          left: 'auto',
        },
      }}
    >
      <div className="delete-application-modal-content">
        <h2 className="delete-application-modal-title">
          Delete application (allow to reapply)
        </h2>
        <p className="text-muted">
          Send an email to the Influencer to let them know they can reapply
        </p>
        <div className="delete-application-modal-controls">
          <div className="delete-application-modal-subject-control">
            <label htmlFor="subject-input">Subject</label>
            <input
              id="subject-input"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <MarkdownTextAreaTabs
            id="feedback"
            className="delete-application-modal-markdown"
            rows="12"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <p className="text-muted">
            This mail is also sent to Influencerteam@eqolot.com
          </p>
          <div className="delete-application-modal-button-container">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
            <button className="btn btn-danger" onClick={handleConfirm}>
              Delete Application and send Email
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

DeleteApplicationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
};
DeleteApplicationModal.defaultProps = {};

export default DeleteApplicationModal;
