import React from 'react';
import PropTypes from 'prop-types';

import InsightsReviewManager from './insightsReviewManager';
import Headline from '../../missions/components/missionHeadline';
import { getReviewSubHeading } from '../helpers';

function InsightsReviewView({
  application,
  campaign,
  onInsightsReviewUpdate,
  onPublicationAnalyticsUpdate,
  selectedPanel,
  togglePanel,
  collapseAllPanels,
  publicationAnalyticsFormValue,
}) {
  return (
    <div className="container">
      <div className="row application-review-container">
        <Headline
          application={application}
          heading={application.user.firstname}
          subHeading={getReviewSubHeading(application)}
        />
        <div className="col">
          <InsightsReviewManager
            application={application}
            campaign={campaign}
            onInsightsReviewUpdate={onInsightsReviewUpdate}
            onPublicationAnalyticsUpdate={onPublicationAnalyticsUpdate}
            selectedPanel={selectedPanel}
            togglePanel={togglePanel}
            collapseAllPanels={collapseAllPanels}
            publicationAnalyticsFormValue={publicationAnalyticsFormValue}
          />
        </div>
      </div>
    </div>
  );
}

InsightsReviewView.propTypes = {
  application: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  onInsightsReviewUpdate: PropTypes.func.isRequired,
  onPublicationAnalyticsUpdate: PropTypes.func.isRequired,
  selectedPanel: PropTypes.number,
  togglePanel: PropTypes.func.isRequired,
  collapseAllPanels: PropTypes.func.isRequired,
  publicationAnalyticsFormValue: PropTypes.arrayOf(PropTypes.object),
};

export default InsightsReviewView;
