import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const statusProps = {
  confirmed: {
    title: 'Selection Confirmed',
    verb: 'Confirmed',
  },
};

function ClientInfluencerSelectionToolTip({
  value: clientInfluencerSelection,
  showDifferenceWarning,
  ...props
}) {
  const { title, verb } = statusProps[clientInfluencerSelection.status];

  const subtitle = `${verb} on ${moment(
    clientInfluencerSelection.updatedAt,
  ).format('DD.MM.YYYY, HH:mm')} by ${clientInfluencerSelection.updatedBy}`;

  return (
    <div className="client-influencer-selection-tooltip" {...props}>
      <h2 className={`title title-${clientInfluencerSelection.status}`}>
        {title}
      </h2>
      <h5 className="subtitle">{subtitle}</h5>
      {showDifferenceWarning && (
        <h5 className="warning">
          The selection is more than 5% different from the budget or reach
          target!
        </h5>
      )}
    </div>
  );
}

ClientInfluencerSelectionToolTip.propTypes = {
  value: PropTypes.object.isRequired,
  showDifferenceWarning: PropTypes.bool,
};

ClientInfluencerSelectionToolTip.defaultProps = {
  showDifferenceWarning: false,
};

export default ClientInfluencerSelectionToolTip;
