import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import compact from 'lodash/compact';
import noop from 'lodash/noop';

/**
 * Navigation Component
 *
 * @usage
 *    <Nav onClick={(navId) => this.setState({ activeNavId: navId })} activeNavId={this.state.activeNavId}>
 *      <Nav.Item navId="1">
 *        <Nav.Link>Navigation Item 1</Nav.Link>
 *      </NavItem>
 *      <Nav.Item navId="2">
 *        <Nav.Link>Navigation Item 1</Nav.Link>
 *      </Nav.Item>
 *    </Nav>
 */
function Nav({ className, children, onClick, activeNavId }) {
  const extendedChildren = compact(React.Children.toArray(children)).map(
    (child) =>
      React.cloneElement(child, {
        activeNavId,
        onClick,
      }),
  );

  return <ul className={cx('nav', className)}>{extendedChildren}</ul>;
}

Nav.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  activeNavId: PropTypes.string,
};

Nav.defaultProps = {
  className: '',
  children: '',
  onClick: null,
  activeNavId: null,
};

class NavItem extends React.PureComponent {
  handleClick = (e) => {
    const { navId, onClick } = this.props;

    // NOTE:
    //    Only bubble up event if no event handler was given.
    if (!onClick) {
      return null;
    }

    e.preventDefault();
    e.stopPropagation();

    return onClick(navId);
  };

  render() {
    const { navId, activeNavId, className, children } = this.props;
    const active = navId !== null && activeNavId === navId;

    let child = React.Children.toArray(children)[0] || null;
    if (child) {
      child = React.cloneElement(child, {
        active,
        onClick: this.handleClick,
      });
    }

    return <li className={cx('nav-item', className)}>{child}</li>;
  }
}

NavItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  navId: PropTypes.string,

  /* == managed == */
  activeNavId: PropTypes.string,
  onClick: PropTypes.func,
};

NavItem.defaultProps = {
  className: '',
  children: '',
  navId: null,

  /* == managed == */
  activeNavId: null,
  onClick: null,
};

function NavLink({ className, children, active, href, onClick }) {
  return (
    <a
      className={cx('nav-link', className, { active })}
      href={href}
      onClick={onClick}
    >
      {children}
    </a>
  );
}

NavLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,

  /* == managed == */
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

NavLink.defaultProps = {
  className: '',
  children: null,
  href: '#',

  /* == managed == */
  active: false,
  onClick: noop,
};

Nav.Item = NavItem;
Nav.Link = NavLink;
export default Nav;
