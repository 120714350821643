import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

function EventContentCounterOffer({ price, currency }) {
  return (
    <div className="card-body message counter-offer">
      Counteroffer:{' '}
      <span className="price">
        <FormattedNumber value={price} {...{ style: 'currency', currency }} />
      </span>
    </div>
  );
}

EventContentCounterOffer.propTypes = {
  price: PropTypes.number.isRequired,
  currency: PropTypes.oneOf(['EUR', 'GBP']).isRequired,
};

export default EventContentCounterOffer;
