import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Feature from 'source/components/common/feature';
import { Event, toColor } from '..';

const { Avatar, ContentContainer, EventHeadline, EventEdits, EventBody } =
  Event;

function EventPayment({ createdAt, issuer, title, content, initials }) {
  return (
    <div className={cx('row', `${issuer.type}-action`)}>
      {/* Image */}
      <Avatar
        name={initials}
        color={issuer.type === 'user' ? toColor(initials) : null}
      />

      {/* Message */}
      <ContentContainer>
        <EventHeadline title={title} createdAt={createdAt} />
        <Feature
          feature="allowCrediting"
          otherwise={
            <EventBody content="You don't have permissions to credit influencers." />
          }
        >
          <EventBody content={content} />
        </Feature>
        <EventEdits text={issuer.type === 'user' ? content : null} />
      </ContentContainer>
    </div>
  );
}

EventPayment.propTypes = {
  createdAt: PropTypes.instanceOf(Date).isRequired,
  issuer: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
};

export default EventPayment;
