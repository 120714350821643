import {
  combineValidators,
  composeValidators,
  isRequired,
  isOneOf,
} from 'revalidate';

export default combineValidators({
  name: isRequired('Campaign name'),
  language: composeValidators(
    isRequired,
    isOneOf(['de', 'en'])({ message: 'Unsupported language' }),
  )('Language'),
});
