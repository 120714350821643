import {
  compose,
  lifecycle,
  withProps,
  branch,
  withHandlers,
  renderComponent,
} from 'recompose';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import keys from 'lodash/keys';
import flatten from 'lodash/flatten';
import * as Sentry from '@sentry/browser';

import ProgressBar from 'source/components/common/progressBar';
import { withPage } from 'source/scenes/components/page';
import { dedent } from 'source/utils';
import { getCampaign } from 'source/data/selectors';

import {
  listApplications,
  updateApplicationStatus,
  deleteApplication,
  syncApplicationWithLocation,
  changeOverviewFilter,
  resetScene,
} from './actions';

import ApplicationsOverview from './applicationOverview';

// @sean TODO Don't reach into global selectors and use some kind of
//       selector namespace or cursor concept
import * as selectors from '../../../selectors';

// Helpers

const getApplicationIds = (applicationsByStatus) =>
  flatten(
    keys(applicationsByStatus).map((status) => applicationsByStatus[status]),
  ).map((application) => application.id);

const statuses = [
  'rejected',
  'new',
  'shortlisted',
  'review',
  'selected',
  'approved',
];

const metricsByStatusReduction = (reduction) => (applicationsByStatus) =>
  statuses.reduce((memo, status) => {
    const applications = applicationsByStatus[status];
    memo[status] = applications.reduce(...reduction);
    return memo;
  }, {});

// HoCs

const withOnShowRejectedClick = withHandlers({
  onShowRejectedClick: (props) => (filterName, value) => {
    const {
      fields,
      campaign,
      onLoadApplications,
      onChangeOverviewFilter,
      rejectedApplicationsLoaded,
      overviewFilter: { showRejected },
    } = props;

    const filters = [{ campaignId: campaign.id }, { status: 'rejected' }];
    onChangeOverviewFilter(filterName, value);

    if (!showRejected && !rejectedApplicationsLoaded) {
      onLoadApplications({ filters, fields });
    }
  },
});

const withApplicationsFetch = lifecycle({
  componentDidMount() {
    const { campaign, fields, onLoadApplications } = this.props;

    const filters = [
      { campaignId: campaign.id },
      { status: { not: 'rejected' } },
    ];
    onLoadApplications({ filters, fields });
  },
});

const withUnmountCleanup = lifecycle({
  componentWillUnmount() {
    const { applicationsByStatus, onResetScene } = this.props;

    Sentry.addBreadcrumb({
      category: 'componentLifecycle',
      message: 'componentWillUnmount applicationsOverview',
      level: Sentry.Severity.Debug,
    });
    onResetScene({
      applicationIds: getApplicationIds(applicationsByStatus),
    });
  },
});

const withProgressBar = branch(
  (props) => !props.loaded,
  renderComponent(ProgressBar.Mega),
);

const loaded = createSelector(
  selectors.getApplicationsSceneState,
  (applicationsSceneState) => applicationsSceneState.loaded,
);

const loading = createSelector(
  selectors.getApplicationsSceneState,
  (applicationsSceneState) => applicationsSceneState.loading,
);

const rejectedApplicationsLoaded = createSelector(
  selectors.getApplicationsSceneState,
  (applicationsSceneState) => applicationsSceneState.loadedRejected,
);

export default function createOverviewContainer({
  additionalFields = [],
  metricsReduction,
  getPanelGoals,
  panelBody,
}) {
  return compose(
    connect(
      createStructuredSelector({
        campaign: getCampaign,
        accessToken: selectors.getAccessToken,
        applicationsByStatus: selectors.getApplicationsByStatus,
        applications: selectors.getApplications,
        metricsByStatus: createSelector(
          selectors.getApplicationsByStatus,
          metricsByStatusReduction(metricsReduction),
        ),
        overviewFilter: selectors.getOverviewFilter,
        loaded,
        loading,
        rejectedApplicationsLoaded,
      }),
      {
        onLoadApplications: listApplications,
        onUpdateApplicationStatus: updateApplicationStatus,
        onDeleteApplication: deleteApplication,
        onChangeOverviewFilter: changeOverviewFilter,
        onResetScene: resetScene,
        syncApplicationWithLocation,
      },
    ),
    withUnmountCleanup,
    withProps({
      isFluid: true,
      className: 'applications',
      heading: 'Applications',
      description: dedent(
        `Check user applications for a particular campaign.
         Review and manage their application status.`,
      ),
      fields: [
        // default fields
        'assignment',
        'campaignId',
        'campaign',
        'groups',
        'privateNetworks',
        'id',
        'insightsAnalysis',
        'match',
        'mission',
        'pitch',
        'pitchEdits',
        'status',
        'clientApplicationReview',
        'tasks',
        'user',
        'productShipment',
        'kamNotes',
        'channels.id',
        'channels.name',
        'channels.ready',
        'channels.readyState',
        'channels.platform',
        'channels.insights',
        'channels.pricePerPostV2',
        'channels.hidden',
        'createdAt',
        'events',
        // campaign type specific fields
        ...additionalFields,
      ],
      panelBody,
      getPanelGoals,
    }),
    withPage,
    withApplicationsFetch,
    withOnShowRejectedClick,
    withProgressBar,
  )(ApplicationsOverview);
}
