import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function Bar({ className, children }) {
  return <div className={cx('bar', className)}>{children}</div>;
}

Bar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Bar.defaultProps = {
  className: '',
  children: '',
};

function Item({ className, ...props }) {
  return <span className={cx('bar__item', className)} {...props} />;
}

Item.propTypes = {
  className: PropTypes.string,
};

Item.defaultProps = {
  className: '',
};

Bar.Item = Item;

export default Bar;
