import React from 'react';
import PropTypes from 'prop-types';

import ExternalLink from 'source/scenes/components/externalLink';

// magical regular expression to extract the YouTube video Id from a YouTube
// URL, see: https://stackoverflow.com/a/14701040/2363945
const getVideoId = (url) => {
  // eslint-disable-next-line no-useless-escape
  const regexp = /^.*(?:youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
  const match = regexp.exec(url);

  return match ? match[1] : undefined;
};

/**
 * Renders the official embedded YouTube player for a given YouTube video URL or
 * a link to it if the given URL couldn't be parsed correctly.
 */
function YouTubeVideo(props) {
  const videoId = getVideoId(props.url);

  if (videoId) {
    return (
      <div className="bf-youtube-player">
        <iframe
          title={`YouTube Video for ${props.url}`}
          src={`https://www.youtube.com/embed/${videoId}?rel=0`}
          frameBorder="0"
          width={props.width}
          height={props.height}
        />
      </div>
    );
  }

  return <ExternalLink href={props.url}>{props.url}</ExternalLink>;
}

YouTubeVideo.propTypes = {
  /**
   * full URL that leads to a YouTube video like `https://www.youtube.com/watch?v=zB4I68XVPzQ`
   */
  url: PropTypes.string.isRequired,
  /**
   * The width of the video player
   */
  width: PropTypes.string,
  /**
   * The height of the video player
   */
  height: PropTypes.string,
};

YouTubeVideo.defaultProps = {
  width: undefined,
  height: undefined,
};

export default YouTubeVideo;
