import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import moment from 'moment';
import { FormattedRelativeTime } from 'react-intl';
import config from 'config';

import PropTypes from 'source/scenes/utils/propTypes';
import ButtonWithConfirmation from 'source/components/common/buttonWithConfirmation';
import Icon from 'source/components/common/icon';
import { SCREENSHOTS_DESCRIPTIONS } from '../constants';

function Status({ screenshot, platform }) {
  if (isEmpty(screenshot)) {
    return <strong className="text-danger">Missing</strong>;
  }

  const today = moment().startOf('day');
  const updatedAtDate = moment(screenshot.updatedAt).startOf('day');
  const uploadDaysAfter = moment(today).diff(updatedAtDate, 'days');

  const { insightsTtl, insightsIntermediateTtl } = config.insights[platform];

  return (
    <strong
      className={cx({
        'text-danger': uploadDaysAfter > insightsTtl,
      })}
    >
      {uploadDaysAfter > insightsIntermediateTtl && '⚠️ '}
      <FormattedRelativeTime
        value={-uploadDaysAfter}
        unit="day"
        numeric="auto"
      />
    </strong>
  );
}

Status.propTypes = {
  screenshot: PropTypes.screenshot,
  platform: PropTypes.platform.isRequired,
};

class ScreenshotPanel extends React.Component {
  state = {
    isZoomed: false,
  };

  handleZoom = () => {
    this.setState({ isZoomed: !this.state.isZoomed });
  };

  deleted = false;

  deleteHandler = (name) => () => {
    this.deleted = true;
    this.props.onDelete(name);
  };

  renderDeleteButton = () => {
    const { screenshot, name } = this.props;

    const deleteIcon = (
      <Icon
        name="delete"
        title="Click to delete screenshot"
        disabled={isEmpty(screenshot)}
      />
    );

    if (isEmpty(screenshot) || this.deleted) {
      return deleteIcon;
    }

    return (
      <ButtonWithConfirmation
        onConfirm={this.deleteHandler(name)}
        button={deleteIcon}
      >
        <h4>Are you sure you want to delete this screenshot?</h4>
      </ButtonWithConfirmation>
    );
  };

  renderImage = () => {
    const { screenshot, placeholderImg } = this.props;

    const isMissing = isEmpty(screenshot);

    if (isMissing) {
      return (
        <div className="screenshot-placeholder">
          <img src={placeholderImg} alt="No screenshot provided" disabled />
        </div>
      );
    }

    const metricsScreenshot = (
      <div className="screenshot-container">
        <span className="screenshot-title">{screenshot.filename}</span>
        <img
          src={screenshot.url}
          alt="Metrics Screenshot"
          onClick={this.handleZoom}
        />
      </div>
    );

    return (
      <>
        {metricsScreenshot}
        {this.state.isZoomed && (
          <div className="metrics-container" onClick={this.handleZoom}>
            {metricsScreenshot}
          </div>
        )}
      </>
    );
  };

  render() {
    const { screenshot, name, platform } = this.props;

    const description = get(SCREENSHOTS_DESCRIPTIONS, `${platform}.${name}`);

    return (
      <div className={cx('screenshot-panel', platform)}>
        <span>{description}</span>
        <div className="status-bar">
          <Status screenshot={screenshot} platform={platform} />
          {this.renderDeleteButton()}
        </div>
        {this.renderImage()}
      </div>
    );
  }
}

ScreenshotPanel.propTypes = {
  screenshot: PropTypes.screenshot,
  placeholderImg: PropTypes.string.isRequired,
  name: PropTypes.screenshotName.isRequired,
  platform: PropTypes.platform.isRequired,
  onDelete: PropTypes.func,
};

ScreenshotPanel.defaultProps = {
  onDelete: noop,
};

export default ScreenshotPanel;
