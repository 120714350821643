import React from 'react';
import noop from 'lodash/noop';
import { Field } from 'redux-form';

import Input from 'source/scenes/components/reduxFormAdapters/input';
import Checkbox from 'source/scenes/components/reduxFormAdapters/checkbox';

function LinkTask() {
  return (
    <div>
      <Field
        name="body"
        component={Input}
        type="url"
        placeholder="http://wwww.example.com"
      />
      <Field name="proxy" label="Create proxy link" component={Checkbox} />
    </div>
  );
}

export default LinkTask;

LinkTask.defaultProps = {
  onProxyChange: noop,
};
