import cloneDeep from 'lodash/cloneDeep';
import sharedImport from './shared';

const shared = cloneDeep(sharedImport);
// add Tony to the product group, but only on staging
shared.accessGroups.product.push('tony.jose');

const config = {
  api: {
    url: 'https://aurum.staging.eqolot.com',
    timeout: 90000,
  },

  app: {
    url: 'https://cc.staging.eqolot.com',
  },

  userApp: {
    url: 'https://app.staging.eqolot.com',
  },

  sentry: {
    dsn: 'https://952c9113ed0843e386b914e67b6862fb@o30840.ingest.sentry.io/86666',
  },

  allowedSSODomains: ['blogfoster.com', 'eqolot.com'],
  primaryDomain: 'eqolot.com',

  managers: shared.managers,
  allUsers: shared.allUsers,
  paymentApprovers: [
    ...shared.accessGroups.paymentApprovers,
    ...shared.accessGroups.product,
    ...shared.accessGroups.developers,
  ],

  insights: {
    instagram: {
      insightsTtl: 30,
      insightsIntermediateTtl: 23,
    },
    tiktok: {
      insightsTtl: 30,
      insightsIntermediateTtl: 23,
    },
  },

  features: {
    loginAsInfluencer: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        ...shared.accessGroups.marketing,
        ...shared.accessGroups.operations,
        ...shared.accessGroups.management,
        ...shared.accessGroups.accounting,
        'nicole.stoermann',
        'isabelle.sonderyd',
        'dilara.abaci',
        'biftu.abduljelil',
        'arina.mezhenina',
        'melanie.lucas',
        'lara.gundacker',
        'christina.tinter',
        'annabelle.fritz',
        'caroline.albrecht',
        'maja.kuzmin',
        'mathias.avalos-thiel',
        'anja.schreiber',
        'isabelle.sonderyd',
        'semin.lee',
        'shirin.safar',
      ],
    },

    showCreateGroup: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        'franziska.falk',
        'nils.tornow',
      ],
    },

    pricingMultiplier: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        ...shared.accessGroups.management,
        ...shared.accessGroups.campaignManagers,
        'biftu.abduljelil',
        'arina.mezhenina',
        'christina.tinter',
        'maja.kuzmin',
        'katharina.philipps.extern',
        'caroline.albrecht',
        'nils.tornow',
      ],
    },

    invoices: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        ...shared.accessGroups.management,
        ...shared.accessGroups.accounting,
      ],
    },

    payments: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        ...shared.accessGroups.management,
        ...shared.accessGroups.accounting,
        ...shared.accessGroups.operations,
        ...shared.accessGroups.paymentApprovers,
        'melanie.lucas',
        'annabelle.fritz',
        'caroline.albrecht',
      ],
    },

    campaignBonuses: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        ...shared.accessGroups.management,
        ...shared.accessGroups.campaignManagers,
        'juliane.dobberow',
        'nicole.stoermann',
        'biftu.abduljelil',
        'arina.mezhenina',
        'melanie.lucas',
        'lara.gundacker',
        'christina.tinter',
        'annabelle.fritz',
        'maja.kuzmin',
        'katharina.philipps.extern',
        'caroline.albrecht',
        'nils.tornow',
        'mathias.avalos-thiel',
        'anja.schreiber',
        'isabelle.sonderyd',
        'kevin.dombrowski',
      ],
    },

    allowCrediting: {
      allowed: [
        ...shared.accessGroups.product,
        ...shared.accessGroups.developers,
        ...shared.accessGroups.campaignManagers,
        ...shared.accessGroups.management,
        'ayla.albayrak',
        'caroline.albrecht',
        'nils.tornow',
      ],
    },

    campaignSelectPrivateNetwork: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        ...shared.accessGroups.campaignManagers,
        'biftu.abduljelil',
        'arina.mezhenina',
        'melanie.lucas',
        'christina.tinter',
        'annabelle.fritz',
        'maja.kuzmin',
        'katharina.philipps.extern',
        'caroline.albrecht',
        'nils.tornow',
        'semin.lee',
      ],
    },
  },
};

export default config;
