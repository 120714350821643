import {
  userFetch,
  userFetchManyV1,
  userLogIn,
  userPatch,
} from 'source/data/users/actions';

export const namespace = 'scenes/users';

export const actionTypes = {
  RESET_LIST_SCENE: `${namespace}/RESET_LIST_SCENE`,
  RESET_DETAILS_SCENE: `${namespace}/RESET_DETAILS_SCENE`,
  USERS_LIST_FILTER_CHANGES: `${namespace}/USERS_LIST_FILTER_CHANGES`,
  FORM_CHANGES: `${namespace}/FORM_CHANGES`,
  FORM_ERRORS: `${namespace}/FORM_ERRORS`,
};

const fetchUser = userFetch(namespace);
const fetchUsers = userFetchManyV1(namespace);
const loginAsUser = userLogIn(namespace);

export const loadUser = (userId) => (dispatch) => {
  const fields = [
    'id',
    'email',
    'salutation',
    'firstname',
    'lastname',
    'yearOfBirth',
    'language',
    'createdAt',
    'lastLogin',
    'phone',
    'streetName',
    'streetNumber',
    'city',
    'country',
    'postalCode',
    'phone',
    'settings.promotionalEmails',
    'settings.recommendedCampaignEmails',
    'groups',
    'privateNetworks',
  ];

  return dispatch(fetchUser(userId, { fields }));
};

const loadUsers =
  (page = 0) =>
  (dispatch, getState) => {
    const term = getState().scenes.users.filters.term.trim();

    return dispatch(fetchUsers({ page, search: term }));
  };

const loginUser = (userId) => (dispatch) => dispatch(loginAsUser(userId));

const changeSearchFilter = (term) => ({
  type: actionTypes.USERS_LIST_FILTER_CHANGES,
  payload: { term },
});

const onFormChange = (form, field, value) => ({
  type: actionTypes.FORM_CHANGES,
  payload: { form, field, value },
});

const onFormErrors = (form, errors) => ({
  type: actionTypes.FORM_ERRORS,
  payload: { form, errors },
});

const onFormSubmit = (form) => (dispatch, getState) => {
  const {
    scenes: {
      users: {
        data: { user },
        forms: { [form]: formData },
      },
    },
  } = getState();

  const payload = { email: formData.email };
  const patchUser = userPatch(namespace, { form });

  return dispatch(patchUser(user.id, payload));
};

const resetDetailsScene = () => ({ type: actionTypes.RESET_DETAILS_SCENE });
const resetListScene = () => ({ type: actionTypes.RESET_LIST_SCENE });

export const usersListActions = {
  loadUsers,
  loginUser,
  changeSearchFilter,
  onResetScene: resetListScene,
};

export const userDetailsActions = {
  loadUser,
  loginUser,
  onFormChange,
  onFormErrors,
  onFormSubmit,
  onResetScene: resetDetailsScene,
};
