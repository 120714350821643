import PropTypes from 'prop-types';
import React from 'react';

export function ErrorText({ message, stack, code }) {
  if (!message) {
    return null;
  }
  return (
    <div className="card-body">
      <p className="card-text">
        [{code}] {message}
      </p>
      <p className="card-text">
        <small className="text-muted">{stack}</small>
      </p>
    </div>
  );
}

ErrorText.propTypes = {
  message: PropTypes.node,
  stack: PropTypes.node,
  code: PropTypes.node,
};
