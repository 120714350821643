import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { Input } from 'source/scenes/components/reduxFormAdapters';

import CountriesSelect from './countriesSelect';

class CountriesTableRow extends React.PureComponent {
  handleRemove = () => {
    const { index, handleRemove } = this.props;
    handleRemove(index);
  };

  render() {
    const { country, options } = this.props;
    return (
      <tr key={country}>
        <td className="col-reach">
          <div className="form-group">
            <Field
              className="form-control"
              name="country"
              type="string"
              component={CountriesSelect}
              options={options}
            />
          </div>
        </td>
        <td>
          <Field
            name="visitsShare"
            type="number"
            component={Input}
            className="small-input text-right"
            max="100"
            min="0"
          />
        </td>
        <td className="col-details">
          <button
            type="button"
            className="btn btn-danger pull-right"
            onClick={this.handleRemove}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  }
}

CountriesTableRow.propTypes = {
  country: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default CountriesTableRow;
