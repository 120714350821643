import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import cx from 'classnames';

import { findCountry } from 'source/scenes/widgets/userProfile';

const OTHER_PRODUCT = 'otherProduct';

function Buttons({ className, onSaveClick, onCancelClick, disableSave }) {
  return (
    <div className={className}>
      <button
        type="button"
        className="btn btn-outline-secondary"
        onClick={onCancelClick}
      >
        Cancel
      </button>
      <button
        type="button"
        className="btn btn-danger ml-2"
        disabled={disableSave}
        onClick={onSaveClick}
      >
        Save
      </button>
    </div>
  );
}

Buttons.propTypes = {
  className: PropTypes.string,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  disableSave: PropTypes.bool,
};

Buttons.defaultProps = {
  className: '',
  disableSave: false,
};

function Address({ className, address }) {
  return (
    <div className={className}>
      <span className="text-muted">Delivery address</span>
      <div className="full-name">
        {address.firstname} {address.lastname}
      </div>
      <div className="address-line-1">
        {address.street} {address.streetNumber}
      </div>
      <div className="address-line-2">
        {address.postalCode} {address.city}, {findCountry(address.country)}
      </div>
    </div>
  );
}

Address.propTypes = {
  className: PropTypes.string,
  address: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    street: PropTypes.string,
    streetNumber: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
};

const ProductShipment = React.memo(
  ({
    productShipment,
    onSave,
    onChange,
    title,
    defaultMode,
    buttonsPosition,
    className,
  }) => {
    if (isEmpty(productShipment) || !productShipment?.enabled) {
      return null;
    }
    const {
      address,
      product,
      productChoices = [],
      allowOtherChoice = false,
    } = productShipment;

    const [selectedProduct, setSelectedProduct] = React.useState(
      productChoices.includes(product) ? product : OTHER_PRODUCT,
    );
    const [otherProduct, setOtherProduct] = React.useState(
      !productChoices.includes(product) ? product : '',
    );
    const [originalValue, setOriginalValue] = React.useState(product);
    const [editMode, setEditMode] = React.useState(defaultMode === 'edit');

    const toggleEditMode = () => setEditMode(!editMode);
    const onSelectChange = (e) => {
      setSelectedProduct(e.target.value);
      onChange(e);
    };
    const onOtherProductChange = (e) => {
      setOtherProduct(e.target.value);
      onChange(e);
    };

    const onEditClick = (e) => {
      e.preventDefault();

      setOriginalValue(product);
      toggleEditMode();
    };

    const cancelEdit = (e) => {
      setOriginalValue(originalValue);
      toggleEditMode();
      // this simulates onChange when Cancel action is triggered
      // and new value needs to be discarded
      e.target.value = originalValue;
      onSelectChange(e);
    };

    const saveValue = () => {
      const newProduct =
        selectedProduct === OTHER_PRODUCT ? otherProduct : selectedProduct;
      onSave(newProduct).then(() => {
        setOriginalValue(newProduct);
        toggleEditMode();
      });
    };

    return (
      <div className={className}>
        <div className="mb-2">
          <div
            className={cx('d-flex', { 'justify-content-between': editMode })}
          >
            <span className="text-muted">{title}</span>
            {!editMode && (
              <span className="text-muted ml-2">
                <a href="#" onClick={onEditClick}>
                  Edit
                </a>
              </span>
            )}
            {editMode && buttonsPosition === 'top' && (
              <Buttons
                editMode={editMode}
                onSaveClick={saveValue}
                onCancelClick={cancelEdit}
                disableSave={selectedProduct === OTHER_PRODUCT && !otherProduct}
              />
            )}
          </div>
          {editMode ? (
            <div className="mt-1 mb-2">
              <select
                className="custom-select mb-2"
                value={selectedProduct}
                onChange={onSelectChange}
              >
                {productChoices.map((product) => (
                  <option key={product} value={product}>
                    {product}
                  </option>
                ))}
                {allowOtherChoice && (
                  <option value="otherProduct">Other (free text option)</option>
                )}
              </select>
              {selectedProduct === OTHER_PRODUCT && (
                <input
                  className="form-control mb-2"
                  type="text"
                  value={otherProduct}
                  onChange={onOtherProductChange}
                />
              )}
              {buttonsPosition === 'bottom' && (
                <Buttons
                  className="d-flex justify-content-end"
                  editMode={editMode}
                  onSaveClick={saveValue}
                  onCancelClick={cancelEdit}
                  disableSave={
                    selectedProduct === OTHER_PRODUCT && !otherProduct
                  }
                />
              )}
            </div>
          ) : (
            <span>{product}</span>
          )}
        </div>
        {!isEmpty(address) && <Address address={address} />}
      </div>
    );
  },
);

ProductShipment.propTypes = {
  productShipment: PropTypes.shape({
    product: PropTypes.string,
    address: PropTypes.object,
    productChoices: PropTypes.arrayOf(PropTypes.string),
    allowOtherChoice: PropTypes.bool,
    enabled: PropTypes.bool,
  }),
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  title: PropTypes.string,
  defaultMode: PropTypes.oneOf(['preview', 'edit']),
  buttonsPosition: PropTypes.oneOf(['top', 'bottom']),
  className: PropTypes.string,
};

ProductShipment.defaultProps = {
  defaultMode: 'preview',
  buttonsPosition: 'top',
  onChange: noop,
  className: '',
};

export default ProductShipment;
