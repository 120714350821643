import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';
import { defaultMemoize as memoize } from 'reselect';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withProps, pure, compose } from 'recompose';

import Alert from 'source/components/common/alert';
import { asLabeledInput } from 'source/scenes/components/labeledInputs';
import PointsInput from 'source/scenes/components/reduxFormAdapters/pointsInput';
import CollectionBuilderInput from 'source/scenes/components/reduxFormAdapters/collectionBuilderInput';
// eslint-disable-next-line import/no-named-as-default
import DependentFields from 'source/scenes/components/reduxFormAdapters/dependentFields';

// Helpers

const getCategoryId = (category) => category.code;

const wizardInitialValues = {
  parent: '',
  code: '',
  name: '',
  minWeight: 0,
};

/**
 * Dynamically create sub-form used when adding a new category
 */
const getWizardForm = memoize((FormComponent, form, categories) =>
  withProps({
    form: `${form}/categorySelectionWizard`,
    categories,
  })(FormComponent),
);

/**
 * No point memoizing since we're calling it twice per render
 * To memoize, would need to pass memoized functions to the component
 */
const getCategoryOptions = (categories = []) =>
  categories.map((category) => ({
    label: category.name,
    value: category.code,
  }));

const getRootCategories = memoize((categories) =>
  categories.filter((category) => !category.parent),
);

const getSubCategories = memoize((categories, code) =>
  code ? categories.filter((category) => category.parent === code) : [],
);

// HoCs

const withFormSelector = withProps(({ form }) => ({
  getFormValues: formValueSelector(form),
}));

// Components

/**
 * Extend default <select> to accept options as props
 */
function Select({ options, ...props }) {
  return (
    <select {...props}>
      <option disabled label="–––" value="" />
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

// Wrap input components with error handling

const LabeledSelect = asLabeledInput(Select);
const LabeledPointsInput = asLabeledInput(PointsInput, {
  isReduxFormInput: true,
});

/**
 * Component responsible for rendering the sub-form when adding a new category
 */
function CategorySelectionWizard({
  categories,
  selectedRootCategory,
  isUpdate,
  onClose,
  handleSubmit,
}) {
  return (
    <div className="card">
      <div className="card-body">
        <DependentFields
          disableOnMount={
            // If we are adding a new item, we want to make sure only the first
            // input is enabled, otherwise all should be
            !isUpdate
          }
          initialValues={wizardInitialValues}
          inputs={[
            {
              component: LabeledSelect,
              name: 'parent',
              label: 'Select a category group',
              options: getCategoryOptions(getRootCategories(categories)),
            },
            {
              component: LabeledSelect,
              name: 'code',
              options: getCategoryOptions(
                getSubCategories(categories, selectedRootCategory),
              ),
              label: 'Select the category',
            },
            {
              component: LabeledPointsInput,
              name: 'minWeight',
              label: 'Define the minimum category weight',
              maxPoints: 13,
              showCounter: true,
            },
          ]}
        />

        <div className="row justify-content-end pr-3">
          <div className="btn-group">
            <button className="btn btn-primary" onClick={handleSubmit}>
              {isUpdate ? 'Update' : 'Add'}
            </button>
            <button className="btn btn-outline-secondary" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

CategorySelectionWizard.propTypes = {
  categories: PropTypes.array.isRequired,
  selectedRootCategory: PropTypes.string,
  isUpdate: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const CategorySelectionWizardEnhanced = compose(
  withFormSelector,
  connect((state, { getFormValues }) => ({
    selectedRootCategory: getFormValues(state, 'parent'),
  })),
  reduxForm({
    validate: ({ parent, code, minWeight }) => {
      const errors = {};

      if (!parent) {
        errors.parent = 'You must select a root category';
      }

      if (!code) {
        errors.code = 'You must select a sub-category';
      }

      // The points field already prevents selection of values > max
      if (!minWeight || minWeight === 0) {
        errors.minWeight =
          'You must specify a minimum point value for the category';
      }

      return errors;
    },
    initialValues: wizardInitialValues,
    onSubmit: ({ parent, code, minWeight }, dispatch, { categories }) => ({
      parent,
      code,
      minWeight,
      name: find(categories, { code }).name,
    }),
    // Only validate on submit
    touchOnBlur: false,
  }),
)(CategorySelectionWizard);

function CategoryListHeader({ className }) {
  return (
    <div className={className}>
      <div>Category</div>
      <div>Minimum Weight</div>
    </div>
  );
}

CategoryListHeader.propTypes = {
  className: PropTypes.string,
};

function CategoryListItem({ className, item }) {
  return (
    <div className={className}>
      <div>{item.name}</div>
      <div>{item.minWeight}</div>
    </div>
  );
}

CategoryListItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    minWeight: PropTypes.number.isRequired,
  }).isRequired,
};

/**
 * UI implementing selection
 */
function CategorySelection({
  form,
  error,
  categories,
  handleSubmit,
  invalid,
  pristine,
}) {
  if (!categories || !categories.length) {
    console.error('No categories provided to category selection form');
    return null;
  }

  const WizardForm = getWizardForm(
    CategorySelectionWizardEnhanced,
    form,
    categories,
  );

  return (
    <div>
      <div className="mb-3 text-muted">
        Here you may specify categories for the campaign. If specified, only
        users with channels whose categorization meets at least one of the given
        criteria will be able to apply to this campaign.
      </div>
      <form className="category-selection-form" onSubmit={handleSubmit}>
        <Alert message={get(error, 'message')} />
        <Field
          component={CollectionBuilderInput}
          name="categories.whitelist"
          draggable={false}
          addBtnLabel="Add Category"
          noContentMessage="All categories may apply"
          itemForms={WizardForm}
          getItemId={getCategoryId}
          listHeaderComponent={CategoryListHeader}
          listItemComponent={CategoryListItem}
        />
        <div className="row justify-content-end pr-3 pt-3">
          <button
            className="btn btn-primary"
            type="submit"
            disabled={pristine || invalid}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

CategorySelection.propTypes = {
  /** Redux form name */
  form: PropTypes.string.isRequired,
  /**
   * Redux form initial props. This is actually required, but will be
   * consumed by the Redux Form wrapper
   */
  // eslint-disable-next-line react/no-unused-prop-types
  initialValues: PropTypes.shape({
    categories: PropTypes.shape({
      whitelist: PropTypes.array.isRequired,
    }).isRequired,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      parent: PropTypes.string,
    }),
  ).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  onSubmit: PropTypes.func.isRequired,

  // Internal
  /** @ignore */
  handleSubmit: PropTypes.func.isRequired,
  /** @ignore */
  error: PropTypes.object,
  /** @ignore */
  pristine: PropTypes.bool.isRequired,
  /** @ignore */
  invalid: PropTypes.bool.isRequired,
};

export default compose(
  pure,
  withFormSelector,
  reduxForm({
    // Only validate when submit is pressed
    touchOnBlur: false,
    // Allows reloading initial values from server without
    // unmounting the form
    enableReinitialize: true,
  }),
)(CategorySelection);
