import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import Markdown from 'source/components/common/markdown';
import MarkdownTextAreaEditable from 'source/components/common/markdownTextAreaEditable';

const Notes = React.memo(
  ({
    label,
    value,
    updatedBy,
    updatedAt,
    onSave,
    onChange,
    className,
    buttonsPosition,
    editable,
  }) => {
    const lastEditLabel = React.useMemo(() => {
      if (!updatedBy || !updatedAt) {
        return null;
      }

      return `by ${updatedBy} on ${moment
        .utc(updatedAt)
        .format('YYYY-MM-DD HH:mm')}`;
    }, [updatedAt, updatedBy]);

    if (editable) {
      return (
        <MarkdownTextAreaEditable
          title={label}
          buttonsPosition={buttonsPosition}
          className={className}
          onSave={onSave}
          onChange={onChange}
          value={value}
          rows={6}
          lastEditLabel={lastEditLabel}
        />
      );
    }

    return (
      <div>
        <div>
          <span className="text-muted">{label}</span>
        </div>
        {lastEditLabel && <small className="text-muted">{lastEditLabel}</small>}
        <Markdown markdown={value} />
      </div>
    );
  },
);

Notes.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  buttonsPosition: PropTypes.oneOf(['top', 'bottom']),
  updatedBy: PropTypes.string,
  updatedAt: PropTypes.string,
  editable: PropTypes.bool,
};

Notes.defaultProps = {
  onChange: noop,
  label: '',
  value: '',
  className: '',
  updatedBy: '',
  updatedAt: '',
  editable: true,
};

export default Notes;
