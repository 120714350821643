import { combineValidators, isRequired } from 'revalidate';
import { isRequiredIfOtherMissing } from 'source/utils/validators';

export default combineValidators({
  name: isRequired('Public name'),
  mission: isRequired('Mission'),
  goal: isRequired('Goal'),
  companyLogo: isRequiredIfOtherMissing('companyLogoUrl')('Company logo'),
  previewImage: isRequiredIfOtherMissing('previewImageUrl')('Preview image'),
});
