import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function ContentContainer({ withOffset, children }) {
  const colClassName = cx('col-md-9', { 'offset-md-2': withOffset });

  return (
    <div className={colClassName}>
      <div className="card mb-2">{children}</div>
    </div>
  );
}

ContentContainer.propTypes = {
  withOffset: PropTypes.bool,
  children: PropTypes.node,
};

ContentContainer.defaultProps = {
  withOffset: false,
  children: undefined,
};

export default ContentContainer;
