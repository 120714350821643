import React from 'react';
import PropTypes from 'prop-types';
import { withProps, withState, withHandlers, compose } from 'recompose';
import clamp from 'lodash/clamp';
import omit from 'lodash/omit';

/**
 * Ensures that a given integer `n` has no more than `precision` decimal places
 */
const ensurePrecision = (precision, n) =>
  parseFloat(Math.round(n * 10 ** precision) / 10 ** precision);

/**
 * Take the input step and determine the desired precision where precision
 * simply refers to the number of decimal digits
 */
const withPrecision = withProps(({ step = 1 }) => {
  const decimal = `${step}`.split('.')[1];
  return {
    precision: decimal === undefined ? 0 : decimal.length,
  };
});

const withInputRef = withState('inputRef', 'setInputRef');

const withInputHandler = withHandlers({
  onKeyPress:
    ({ inputRef, onKeyPress }) =>
    (e) => {
      if (e.key === 'Enter') {
        // Instead of having the browser display a weird generic message, we just
        // snap to the nearest valid value
        e.preventDefault();
        inputRef.blur();
      }

      if (typeof onKeyPress === 'function') {
        onKeyPress(e);
      }
    },

  /**
   * We check the min, max, and precision onBlur and update the input
   * to match those constraints
   */
  onBlur:
    ({
      min = -Infinity,
      max = Infinity,
      step = 1,
      value,
      precision,
      inputRef,
      onBlur,
    }) =>
    (e) => {
      if (inputRef && value !== '' && value !== undefined) {
        const valueNext = clamp(parseFloat(value), min, max);
        // If clamp was applied or if precision too large
        if (value !== valueNext || valueNext % step) {
          const changeEvent = new Event('change');
          inputRef.value = `${ensurePrecision(precision, valueNext)}`;
          inputRef.dispatchEvent(changeEvent);
        }
      }

      if (typeof onBlur === 'function') {
        onBlur(e);
      }
    },
});

/**
 * Wrapper around `<input type="number" />`
 *
 * Mostly adds ensuring of `min`, `max`, and `step` when editing
 * the field manually. `step` is intepreted as a specification of precision.
 * So a step of 0.1 means input should have a precision of 1, 0.01 a precision
 * of 2, and so on
 *
 * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number
 * for docs on props
 */
function NumberInput({ className = '', setInputRef, ...props }) {
  return (
    <input
      type="number"
      ref={setInputRef}
      className={`number-input ${className}`}
      {...omit(props, ['precision', 'inputRef'])}
    />
  );
}

NumberInput.propTypes = {
  /** @ignore */
  className: PropTypes.string,
  /** @ignore */
  setInputRef: PropTypes.func.isRequired,
};

export default compose(
  withPrecision,
  withInputRef,
  withInputHandler,
)(NumberInput);
