import React from 'react';
import PropTypes from 'prop-types';
import PrismCode from 'react-prism';
import Prism from 'prismjs';

import('prismjs/themes/prism.css');

// Extend the HTML language to have bf extensions inside a tag value
const bfExtensions = {
  'bf-analytics': /analytics.blogfoster.com\/count.js/,
  'bf-link': /link.blogfoster.com/,
  'bf-nofollow': /nofollow/,
};

Prism.languages['bf-html'] = Prism.languages.extend('html', {});
Prism.languages['bf-html'].tag.inside['attr-value'].inside = {
  ...Prism.languages['bf-html'].tag.inside['attr-value'].inside,
  ...bfExtensions,
};

function HTMLRenderer({ showCode, html, safeHtml, setRenderedHTMLRef }) {
  if (showCode) {
    return (
      <PrismCode component="pre" className="language-bf-html html-renderer">
        {html}
      </PrismCode>
    );
  }
  return (
    <div
      id="importantdiv"
      className="html-renderer-rendered"
      ref={setRenderedHTMLRef}
      dangerouslySetInnerHTML={{ __html: safeHtml }}
    />
  );
}

HTMLRenderer.propTypes = {
  showCode: PropTypes.bool.isRequired,
  html: PropTypes.string.isRequired,
  safeHtml: PropTypes.string.isRequired,
  setRenderedHTMLRef: PropTypes.func.isRequired,
};

export default HTMLRenderer;
