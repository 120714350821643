import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose, withProps } from 'recompose';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import capitalize from 'lodash/capitalize';
import pick from 'lodash/pick';
import map from 'lodash/map';

import { ProgressBar } from 'source/components/common';
import { formatReadingTime, compactObject, sum } from 'source/utils';

// only render the data point if it is available
const renderData = (data, key, renderer) => {
  const value = get(data, key);

  if (isNil(value)) {
    return `${capitalize(key)} insights not available`;
  }

  return renderer(value);
};

// render table rows for top5 listing
const renderTop5Rows =
  ({ intl, key }) =>
  (top5Devices) =>
    top5Devices.map((dev) => (
      <tr key={dev[key]}>
        <th scope="row" className="truncate" style={{ maxWidth: '12.5rem' }}>
          {dev[key]}:
        </th>
        <td className="text-right">
          {intl.formatNumber(dev.perc, {
            style: 'percent',
            maximumFractionDigits: 2,
          })}
        </td>
      </tr>
    ));

function ApplicationInsightsPiwik({ intl, insights }) {
  if (!insights || isEmpty(insights) || insights.state === 'loading') {
    return <ProgressBar.Mega style={{ minHeight: '15rem' }} />;
  }

  if (insights.state === 'error') {
    return (
      <div className="pl-3 pt-3 pb-3">
        Could not load insights data - {insights.error.slice(0, 110)} ...
      </div>
    );
  }

  const { insights: data } = insights;

  if (data.notFound) {
    return <div className="pl-3 pt-3 pb-3">No Insights Data Available ...</div>;
  }

  const pairs = [
    { label: 'Page Views', attr: 'pageviews', formatter: intl.formatNumber },
    { label: 'Visits', attr: 'visits', formatter: intl.formatNumber },
    {
      label: 'Actions per Visit',
      attr: 'actionsPerVisit',
      formatter: (val) => intl.formatNumber(val, { maximumFractionDigits: 2 }),
    },
    {
      label: 'Unique Visitors',
      attr: 'uniqueVisits',
      formatter: intl.formatNumber,
    },
    {
      label: 'Visit Duration',
      attr: 'visitDuration',
      formatter: formatReadingTime,
    },
    {
      label: 'Bounce Rate',
      attr: 'bounceRate',
      formatter: (val) =>
        intl.formatNumber(val, {
          style: 'percent',
          maximumFractionDigits: 2,
        }),
    },
  ];

  return (
    <div>
      <p className="text-muted">
        listing inights data related to this application for the last 30 days
      </p>

      <div className="row">
        {/* common channel metrics */}
        <div className="col-md-6">
          <h5 className="pb-2">Channel Metrics</h5>
          <table className="table table-hover table-sm ml-2">
            <tbody>
              {pairs.map(({ label, attr, formatter }) => (
                <tr key={attr}>
                  <th scope="row">{label}:</th>
                  <td className="text-right">
                    {renderData(data, attr, formatter)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* devices */}
        <div className="col-md-6">
          <h5 className="pb-2">Devices</h5>
          <table className="table table-hover table-sm ml-2">
            <tbody>
              {renderData(
                data,
                'top5Devices',
                renderTop5Rows({ intl, key: 'device' }),
              )}
            </tbody>
          </table>
        </div>

        {/* countries */}
        <div className="col-md-6">
          <h5 className="pb-2">Country Traffic</h5>
          <table className="table table-hover table-sm ml-2">
            <tbody>
              {renderData(
                data,
                'top5Countries',
                renderTop5Rows({ intl, key: 'country' }),
              )}
            </tbody>
          </table>
        </div>

        {/* referrals */}
        <div className="col-md-6">
          <h5 className="pb-2">Referrals</h5>
          <table className="table table-hover table-sm ml-2">
            <tbody>
              {renderData(
                data,
                'top5Referrals',
                renderTop5Rows({ intl, key: 'referrer' }),
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

ApplicationInsightsPiwik.propTypes = {
  intl: PropTypes.object.isRequired,
  insights: PropTypes.object,
};

const top5 = (col, sortKey) => {
  if (!col || !col.length) {
    return null;
  }

  const sumValue = sum(map(col, sortKey));

  return col
    .sort((a, b) => b[sortKey] - a[sortKey])
    .slice(0, 5)
    .map((item) => ({
      ...item,
      sum: sumValue,
      perc: item[sortKey] / sumValue,
    }));
};

const transformInsights = (data) => {
  const nextInsights = compactObject({
    ...pick(data, [
      'pageviews',
      'visits',
      'uniqueVisits',
      'actionsPerVisit',
      'visitDuration',
      'bounceRate',
      'median',
    ]),
    top5Devices: top5(data.devices, 'visits'),
    top5Countries: top5(data.countries, 'visits'),
    top5Referrals: top5(data.referrals, 'visits'),
  });

  // Minor optimization:
  //    If no data was returned from the server we render one
  //    message that tells that there is no data at all.
  return !isEmpty(nextInsights) ? nextInsights : { notFound: true };
};

const enhance = compose(
  injectIntl,
  withProps(({ insights }) => ({
    insights: insights && { insights: transformInsights(insights.insights) },
  })),
);

export default enhance(ApplicationInsightsPiwik);
