import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'recompose';
import get from 'lodash/get';

import Alert from 'source/components/common/alert';
import { Select, Input } from 'source/scenes/components/reduxFormAdapters';
import { campaignManagers } from 'source/utils/acl';
import Feature from 'source/components/common/feature';

import selector from './selectors';
import validate from './validation';
import { withSubmissionHandler } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';

const enhance = compose(
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
  reduxForm({
    validate,
    touchOnBlur: false,
    touchOnChange: true,
    enableReinitialize: true,
  }),
);

function SettingsForm({
  handleSubmit,
  invalid,
  submitting,
  error,
  clients,
  privateNetworks,
  privateNetworkId,
  isPrivateNetworkPresentInPayload,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Alert message={get(error, 'message')} />
      <Field name="managerName" component={Select} label="Managers Name:">
        <option value="">--- no manager selected ---</option>
        {campaignManagers.map((manager) => (
          <option key={manager.username} value={manager.username}>
            {manager.name}
          </option>
        ))}
      </Field>
      <Field name="currency" component={Select} label="Campaign Currency:">
        <option value="EUR">Euro</option>
        <option value="GBP">British Pound</option>
      </Field>

      <Field name="clientId" component={Select} label="Client:">
        {clients.loading && <option value="">Loading...</option>}
        {clients.loaded && <option value="">Not Selected</option>}
        {clients.loaded &&
          clients.data.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
      </Field>

      <Feature feature="campaignSelectPrivateNetwork">
        <Field
          name="privateNetworkId"
          component={Select}
          label="Private Network:"
        >
          {privateNetworks.loading && <option value="">Loading...</option>}
          {privateNetworks.loaded && <option value="">Not Selected</option>}
          {privateNetworks.loaded &&
            privateNetworks.data.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          {privateNetworkId &&
            privateNetworks.loaded &&
            !isPrivateNetworkPresentInPayload && (
              <option key={privateNetworkId} value={privateNetworkId} disabled>
                {privateNetworkId}
              </option>
            )}
        </Field>
      </Feature>

      <Field
        name="accountingNumber"
        component={Input}
        type="string"
        label="Accounting Number (Kostenstelle):"
      />

      <div className="row justify-content-end pr-3">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={invalid || submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
}

SettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.object,
  clients: PropTypes.shape({
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  privateNetworks: PropTypes.shape({
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  privateNetworkId: PropTypes.string,
  isPrivateNetworkPresentInPayload: PropTypes.bool.isRequired,
};

SettingsForm.defaultProps = {
  error: undefined,
};

export default enhance(SettingsForm);
