import { campaignFetch, clearCampaigns } from 'source/data/campaigns/actions';

export const namespace = 'campaignDetail/root';

export const actionTypes = {
  SET_ACTIVE_CAMPAIGN: `${namespace}/SET_ACTIVE_CAMPAIGN`,
  RESET_SCENE: `${namespace}/RESET_SCENE`,
};

// Action creators

export const fetchCampaign = campaignFetch(namespace);

export const setActiveCampaign = (campaignId) => ({
  type: actionTypes.SET_ACTIVE_CAMPAIGN,
  payload: campaignId,
});

export const resetScene = (campaignId) => (dispatch) => {
  if (campaignId) {
    dispatch(clearCampaigns([campaignId]));
  }

  return dispatch({
    type: actionTypes.RESET_SCENE,
  });
};
