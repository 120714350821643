import { createSelector, createStructuredSelector } from 'reselect';
import { isDirty } from 'redux-form';
import get from 'lodash/get';

const getCampaign = (state, props) => props.campaign;
const getFormDirty = (state, props) => isDirty(props.form)(state);

const getUploads = createSelector(getCampaign, (campaign) =>
  get(campaign, 'uploads', []),
);

const getHeadline = createSelector(getFormDirty, (dirty) => {
  let headline = 'Attachments';
  if (dirty) {
    headline += '*';
  }

  return headline;
});

const getSubHeadline = createSelector(getCampaign, (campaign = {}) => {
  const uploads = get(campaign, 'uploads', []);

  let subHeadline = '';
  if (uploads && uploads.length > 0) {
    subHeadline += `${uploads.length} upload(s)`;
  }

  return subHeadline;
});

export default createStructuredSelector({
  uploads: getUploads,
  headline: getHeadline,
  subHeadline: getSubHeadline,
});
