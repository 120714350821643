import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import FileDrop from 'source/components/common/fileDrop';

/**
 * Redux-form compatible wrapper for `FileDrop` elements.
 *
 * @example
 *
 * <Field
 *   name="someImage"
 *   component={FileDrop}
 *   label="Some image:"
 *   placeholder="Choose or drag a file here..."
 *   accept="image/*"
 * />
 */

class WrappedFileDrop extends React.PureComponent {
  handleChange = (id, file) => {
    // The underlying `FileDrop` components allows to clear the input but
    // replaces the value with an empty object. To clear the value in the
    // redux-form state we need to change it to be an empty string.
    const nextFile = isEmpty(file) ? '' : file;

    // call the input onChange handler
    this.props.input.onChange(nextFile);

    // call the custom onChange handler
    if (this.props.onChange) {
      this.props.onChange(nextFile);
    }
  };

  render() {
    const {
      // redux-form props
      input: { name, value },
      meta: { form, touched, error },

      // custom props
      ...props
    } = this.props;

    // NOTE @alexspri
    //    Some value transformations to conform with the `FileDrop` api.
    const file = !value ? {} : value;
    const errorMessage = touched && error ? error : '';

    return (
      <FileDrop
        {...props}
        id={`${form}-${name}`}
        file={file}
        error={errorMessage}
        onChange={this.handleChange}
      />
    );
  }
}

WrappedFileDrop.propTypes = {
  // redux-form props
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    form: PropTypes.string.isRequired,
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
};

WrappedFileDrop.defaultProps = {
  onChange: null,
};

export default WrappedFileDrop;
