import { createSelector, createStructuredSelector } from 'reselect';
import { isDirty } from 'redux-form';
import get from 'lodash/get';

const getCampaign = (state, props) => props.campaign;
const getFormDirty = (state, props) => isDirty(props.form)(state);

const toInternal = (socialMediaSharing = []) =>
  socialMediaSharing.reduce(
    (acc, sharing) => ({
      ...acc,
      [sharing.type]: true,
    }),
    {},
  );

const getInitialValues = createSelector(getCampaign, (campaign) => ({
  ...toInternal(get(campaign, 'socialMediaSharing', [])),
}));

const getHeadline = createSelector(getFormDirty, (dirty) => {
  let headline = 'Social Media Sharing';
  if (dirty) {
    headline += '*';
  }

  return headline;
});

const getSubHeadline = createSelector(
  getCampaign,
  (campaign = {}) =>
    `on ${get(campaign, 'socialMediaSharing', []).length} channel(s)`,
);

export default createStructuredSelector({
  initialValues: getInitialValues,
  headline: getHeadline,
  subHeadline: getSubHeadline,
});
