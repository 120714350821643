import get from 'lodash/get';

import { createOverviewContainer, PinterestPanelBody } from './components';

// panel header
const metricsReduction = [
  (acc, application) => ({
    impressionMedianTotal:
      acc.impressionMedianTotal + get(application, 'match.reach', 0),
    advertiserCostTotal:
      acc.advertiserCostTotal +
      get(application, 'match.payment.totals.advertiserPrice', 0),
    influencerBaseCostsTotal:
      acc.influencerBaseCostsTotal + get(application, 'match.payment.price', 0),
    influencerTotalCostsTotal:
      acc.influencerTotalCostsTotal +
      get(application, 'match.payment.totals.price', 0),
  }),
  {
    impressionMedianTotal: 0,
    advertiserCostTotal: 0,
    influencerBaseCostsTotal: 0,
    influencerTotalCostsTotal: 0,
  },
];

const getPanelGoals = ({ campaign: { settings }, metrics }) => [
  {
    label: 'Followers',
  },
  {
    label: 'Channels',
    empty: true,
  },
  {
    label: 'Applied',
  },
  {
    label: 'Client Review',
  },
  {
    label: 'Infl. Base Price',
    value: metrics.influencerBaseCostsTotal,
    formatting: {
      style: 'currency',
      currency: settings.currency,
    },
  },
  {
    label: 'Inf. Total Price',
    value: metrics.influencerTotalCostsTotal,
    formatting: {
      style: 'currency',
      currency: settings.currency,
    },
  },
  {
    label: 'Advert. Price',
    value: metrics.advertiserCostTotal,
    formatting: {
      style: 'currency',
      currency: settings.currency,
    },
  },
  {
    label: 'Impressions',
    toolTip: 'Median impressions for last 10 pins',
    value: metrics.impressionMedianTotal,
  },
];

export default createOverviewContainer({
  additionalFields: [
    'channel.id',
    'channel.data',
    'channel.name',
    'channel.platform',
    'channel.keyMetrics',
    'channel.notes',
    'channel.updatedAt',
    'channel.ready',
    'channel.readyState',
    'channel.avatarUrl',
  ],
  metricsReduction,
  getPanelGoals,
  panelBody: PinterestPanelBody,
});
