import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import intersection from 'lodash/intersection';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Video, Image, Transformation } from 'cloudinary-react';
import Octicon from 'react-octicon';
import fileSaver from 'file-saver';

import ButtonWithConfirmation from 'source/components/common/buttonWithConfirmation';

const killEvent = (e) => {
  e?.preventDefault();
  e?.stopPropagation();
};

const SortableItem = SortableElement(
  ({
    itemIndex,
    badgeIndex,
    mediaItem: { url, filename, type, assetId },
    onIndexClick,
    onDeleteClick,
  }) => {
    const handleDeleteClick = (e) => {
      killEvent(e);
      onDeleteClick(itemIndex);
    };
    const handleIndexClick = (e) => {
      killEvent(e);
      onIndexClick(url);
    };

    return (
      <div className="content-item">
        {type === 'image' && !assetId && <img alt={filename} src={url} />}
        {type === 'image' && assetId && (
          <Image cloudName="eqolot" publicId={assetId}>
            <Transformation transformation={['influencer-asset']} />
          </Image>
        )}
        {type === 'video' && assetId && (
          <Video secure cloudName="eqolot" publicId={assetId} format="mp4">
            <Transformation fetchFormat="auto" />
          </Video>
        )}
        {type === 'video' && !assetId && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video src={url}>
            <source src={url} type="video/mp4" />
          </video>
        )}
        <div className="content-item__index" onClick={handleIndexClick}>
          <span>{badgeIndex}</span>
        </div>
        <ButtonWithConfirmation
          onConfirm={handleDeleteClick}
          button={
            <div className="content-item__delete">
              <Octicon name="trashcan" />
            </div>
          }
        >
          <div>Are you sure you want to delete this content piece?</div>
          It will be gone forever, from the Creator App, Control Center and
          Business Manager.
        </ButtonWithConfirmation>
        <div
          className="content-item__download"
          onClick={() => fileSaver.saveAs(url, filename)}
        >
          <Octicon name="cloud-download" />
        </div>
      </div>
    );
  },
);

const SortableList = SortableContainer(
  ({ content, onIndexClick, onDeleteClick }) => (
    <div className="content-preview-reorder__container px-3">
      {content.media.map((mediaItem, index) => (
        <SortableItem
          key={mediaItem.url}
          index={index}
          itemIndex={index}
          badgeIndex={index + 1}
          mediaItem={mediaItem}
          onIndexClick={onIndexClick}
          onDeleteClick={onDeleteClick}
        />
      ))}
    </div>
  ),
);

function ContentReorder({
  applicationId,
  type,
  content,
  version,
  disableReorder,
  onChange,
  onIndexClick,
}) {
  const supportedTypes = ['instagram', 'tiktok', 'pinterest'];

  if (!supportedTypes.includes(type)) {
    return null;
  }
  const onDeleteClick = (index) => {
    const media = [...content.media];
    media.splice(index, 1);
    onChange(applicationId, version, media);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const media = arrayMove(content.media, oldIndex, newIndex);
    onChange(applicationId, version, media);
  };

  const getHelperContainer = React.useCallback(
    () =>
      document.querySelector('.content-preview-reorder__container') ||
      document.body,
    [],
  );

  const shouldCancelStart = React.useCallback(
    (e) => {
      if (disableReorder) {
        return true;
      }
      const elementClasses = e.target.classList || [];
      const parentClasses = e.target.parentElement.classList || [];
      const classes = [...elementClasses, ...parentClasses];

      const escapeClasses = ['index', 'delete', 'download'].map(
        (className) => `content-item__${className}`,
      );

      return intersection(classes, escapeClasses).length > 0;
    },
    [disableReorder],
  );

  return (
    <div
      className={cx('content-preview-reorder', {
        'disable-reorder': disableReorder,
      })}
    >
      <SortableList
        axis="x"
        helperClass="active"
        helperContainer={getHelperContainer}
        content={content}
        onSortEnd={onSortEnd}
        shouldCancelStart={shouldCancelStart}
        onIndexClick={onIndexClick}
        onDeleteClick={onDeleteClick}
      />
      <div className="reorder-text px-3 pt-2">
        Reorder the thumbnails with drag and drop. The same order is shown in
        the Creator App and in Business Manager.
      </div>
    </div>
  );
}

ContentReorder.propTypes = {
  applicationId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disableReorder: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onIndexClick: PropTypes.func.isRequired,
  version: PropTypes.number.isRequired,
  content: PropTypes.PropTypes.shape({
    title: PropTypes.string,
    articleWordsCount: PropTypes.number,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['image', 'video']).isRequired,
        filename: PropTypes.string.isRequired,
      }),
    ),
  }),
};

export default ContentReorder;
