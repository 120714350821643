/* eslint-disable react/style-prop-object */

import React from 'react';
import { FormattedNumber } from 'react-intl';
import { createSelector, createStructuredSelector } from 'reselect';
import { isDirty } from 'redux-form';
import get from 'lodash/get';

const getCampaign = (state, props) => props.campaign;
const getFormDirty = (state, props) => isDirty(props.form)(state);

const getInitialValues = createSelector(getCampaign, (campaign) => ({
  // defaults
  fixed: 0,
  variablePercentage: 0,
  // actual data values
  ...get(campaign, 'bonuses', {}),
}));

const getHeadline = createSelector(getFormDirty, (dirty) => {
  let headline = 'Bonuses';
  if (dirty) {
    headline += '*';
  }

  return headline;
});

const getSubHeadline = createSelector(getCampaign, (campaign = {}) => {
  const bonuses = get(campaign, 'bonuses', {});
  const currency = get(campaign, 'settings.currency');

  return (
    <>
      {bonuses.fixed && (
        <FormattedNumber
          value={bonuses.fixed}
          style="currency"
          currency={currency}
        />
      )}
      {bonuses.variablePercentage ? (
        <>
          {' + '}
          <FormattedNumber
            value={bonuses.variablePercentage}
            style="percent"
            maximumFractionDigits={2}
          />
        </>
      ) : null}
    </>
  );
});

export default createStructuredSelector({
  initialValues: getInitialValues,
  headline: getHeadline,
  subHeadline: getSubHeadline,
});
