import { combineReducers } from 'redux';

import applications, {
  getInitialState as applicationsInitialState,
} from './applications';

const reducer = combineReducers({
  applications,
});

export const getInitialState = () => ({
  applications: applicationsInitialState(),
});

export default reducer;
