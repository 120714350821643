import { withProps } from 'recompose';
import {
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
} from 'revalidate';

import { isGreaterThan, isLessThan } from 'source/utils/validators';

const createValidate = (reachLabel = 'Reach') =>
  combineValidators({
    budget: composeValidators(
      isRequired,
      isNumeric,
      isGreaterThan(0),
    )('Advertiser budget'),
    reach: composeValidators(
      isRequired,
      isNumeric,
      isGreaterThan(0),
    )(reachLabel),
    minPosts: composeValidators(
      isRequired,
      isNumeric,
      isGreaterThan(0),
      isLessThan('maxPosts'),
    )('Min. Posts'),
    maxPosts: composeValidators(
      isNumeric,
      isGreaterThan(0),
      isGreaterThan('minPosts'),
    )('Max. Posts'),
  });

// Used because the redux-form's `validate` function doesn't have access to
// props but we need to have error messages based on the campaign type. The
// solution is to use a HoC to dynamically create the `validate` function with
// the right error message.
const withValidate = withProps(({ reachKPI }) => ({
  validate: createValidate(reachKPI),
}));

export default withValidate;
