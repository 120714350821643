import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FieldArray, Field } from 'redux-form';
import { parseToInt } from 'source/utils/reduxForm';

class TagsInput extends React.PureComponent {
  state = { value: '' };

  renderTag = ({ fields, index, input }) => {
    const onRemoveClick = () => {
      fields.remove(index);
    };

    return (
      <span
        className={cx('tag', {
          'badge-secondary': !this.props.readOnly,
          'badge-disabled': this.props.readOnly,
        })}
      >
        {input.value}
        {!this.props.readOnly && (
          <span className="remove ml-1" onClick={onRemoveClick}>
            ×
          </span>
        )}
      </span>
    );
  };

  renderTags = ({ fields }) => {
    const onChange = (e) => {
      const { value: rawValue } = e.target;
      const value = rawValue.replace(/[^0-9,]/g, '');

      if (value === ',') {
        return '';
      }
      if (value && value[value.length - 1] === ',') {
        fields.push(parseToInt(value.slice(0, -1)));
        return this.setState({ value: '' });
      }

      return this.setState({ value });
    };

    const onKeyDown = (e) => {
      // on backspace click
      if (e.keyCode === 8 && !this.state.value) {
        fields.pop();
      }
    };

    return (
      <>
        {fields.map((field, index) => (
          <Field
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            fields={fields}
            name={field}
            index={index}
            parse={parseToInt}
            component={this.props.renderTag || this.renderTag}
            readOnly={this.props.readOnly}
          />
        ))}
        {!this.props.readOnly && (
          <input
            id={this.props.id}
            type="text"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={this.state.value}
            readOnly={this.props.readOnly}
          />
        )}
      </>
    );
  };

  render() {
    const { className, name, wrap } = this.props;

    return (
      <div
        className={cx('tags-input', className, {
          'tags-input-nowrap': !wrap,
        })}
      >
        <FieldArray
          name={name}
          component={this.renderTags}
          inputValue={this.state.value}
        />
      </div>
    );
  }
}

TagsInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  renderTag: PropTypes.func,
  readOnly: PropTypes.bool,
  wrap: PropTypes.bool,
};
TagsInput.defaultProps = {
  className: '',
  renderTag: null,
  readOnly: false,
  wrap: true,
};

export default TagsInput;
