import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Alert from 'source/components/common/alert';
import ProgressBar from 'source/components/common/progressBar';

import Navigation from '../navigation';
import {
  withCampaignFetch,
  withCampaignLoading,
  withCampaignNavigationSummaryFetch,
  withCampaignNavigationSummaryReloading,
  withResetScene,
} from './enhancers';

const enhance = compose(
  withCampaignFetch,
  withCampaignLoading,
  withCampaignNavigationSummaryFetch,
  withCampaignNavigationSummaryReloading,
  withResetScene,
);

/**
 * Campaign Detail Controller
 */
class CampaignDetailRoot extends React.PureComponent {
  getCampaignId() {
    if (!this.props.match.params.id || this.props.match.params.id === 'new') {
      return null;
    }

    return this.props.match.params.id;
  }

  renderChildren() {
    const {
      campaignFetchState: { error },
      campaign,
      children,
    } = this.props;

    const campaignId = this.getCampaignId();

    // show error if we couldn't load the campaign
    if (campaignId && error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }

      return <Alert mega message={error.message || error} />;
    }

    // wait while we have a configured `campaignId`, but no `campaign` yet
    if (campaignId && !campaign) {
      return <ProgressBar.Mega />;
    }

    // otherwise render the children directly, and pass down the campaignId
    return Children.map(children, (child) =>
      cloneElement(child, { campaignId }),
    );
  }

  render() {
    const {
      children,
      location: { pathname },
      ...props
    } = this.props;

    const campaignId = this.getCampaignId();
    const campaignType = pathname.split('campaigns/')[1].split('/')[0];

    return (
      <div>
        <Navigation
          {...props}
          campaignId={campaignId}
          campaignType={campaignType}
        />
        <div className="pt-5">{this.renderChildren()}</div>
      </div>
    );
  }
}

CampaignDetailRoot.propTypes = {
  campaignFetchState: PropTypes.shape({
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.object,
  }).isRequired,
  campaign: PropTypes.object,
  children: PropTypes.node,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

CampaignDetailRoot.defaultProps = {
  campaign: undefined,
  children: undefined,
};

export default enhance(CampaignDetailRoot);
