import PropTypes from 'prop-types';
import {
  compose,
  setPropTypes,
  withHandlers,
  withStateHandlers,
  mapProps,
} from 'recompose';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import pick from 'lodash/pick';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import { contingents } from 'source/utils/reachOptions';
import { remove, findAndReplace } from 'source/utils/imCollection';

import {
  withSubmissionValidation,
  withNextPanelAfterSubmission,
} from '../../enhancers';

/**
 * transform form values to api payload format of contingents
 */
const submitContingents =
  ({ onUpdateCampaign }) =>
  (
    /** values */
    { channels = [] } = {},
    __,
    props,
  ) =>
    onUpdateCampaign(props.campaign.id, {
      // map channels data to response format
      contingents: channels.map((c) =>
        pick(c, ['id', 'channel', 'numberOfPostings', 'bonus']),
      ),
    });

/**
 * High-Order-Component which adds behaviour to submit contingents to our
 * api correctly from the given form data, once the "save" button is hit.
 *
 * Also once the data was correctly submitted, this will fire an action
 * to switch to the next panel.
 */
export const withSubmissionHandler = compose(
  setPropTypes({
    campaign: PropTypes.shape({ id: PropTypes.string.isRequired }),
    onUpdateCampaign: PropTypes.func.isRequired,
    onResetContingents: PropTypes.func.isRequired,
  }),
  withHandlers({ onSubmit: submitContingents }),
  withSubmissionValidation,
  withNextPanelAfterSubmission,
);

const allAvalilableContingents = contingents.filter((option) => !option.ignore);

/**
 * Based on the given "taken" channels, returns all still available channel
 * options.
 *
 * @param {{ id: number }[]} channels
 */
const getContingentsOptions = (channels) =>
  allAvalilableContingents.filter(
    (option) => !find(channels, { id: option.id }),
  );

const getInitialState = () => ({
  channels: [],
  options: allAvalilableContingents,
  state: 'initial',
  errors: {},
  error: null,
});

const getChannelsState = (campaign = {}) => {
  const channels = get(campaign, 'contingents', [])
    // inject some properties of the "defaultContingents" configuration
    // which are not directly submitted from the server
    .map((contingent, index) => {
      const defaultContingent = find(contingents, { id: contingent.id });

      if (!defaultContingent) {
        console.warn(
          `received an invalid reach option for ${campaign.id}:${index}`,
        );
        return null;
      }

      return {
        ...contingent,
        ...pick(defaultContingent, ['label']),
      };
    })
    .filter((contingent) => contingent); // if we received an invalid reach option we must filter it out now

  // filter all options that are not yet taken
  const options = getContingentsOptions(channels);

  return { channels, options };
};

export const withManagedChannels = compose(
  setPropTypes({
    campaign: PropTypes.shape({
      contingents: PropTypes.arrayOf(
        PropTypes.shape({
          reach: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
          }).isRequired,
          price: PropTypes.number,
          channel: PropTypes.string.isRequired,
          numberOfPostings: PropTypes.number.isRequired,
          bonus: PropTypes.number,
        }),
      ),
    }),
  }),
  withStateHandlers(
    /* initial state */
    ({ campaign }) => ({
      ...getInitialState(),
      ...getChannelsState(campaign),
    }),
    /* state handlers */
    {
      onAddChannel:
        ({ channels, options }) =>
        () => {
          // don't add another channel of we dont have more options
          if (options.length <= 0) {
            return undefined;
          }

          const option = options[0];
          const nextOptions = options.slice(1);

          const nextChannel = {
            ...option,
            channel: 'blog',
            numberOfPostings: 1,
            bonus: 0,
          };
          const nextChannels = [...channels, nextChannel];

          return {
            channels: nextChannels,
            options: nextOptions,
            state: 'updated',
          };
        },
      onRemoveChannel:
        ({ channels }) =>
        (row) => {
          // NOTE we need to add the options that is now free again
          const nextChannels = remove(channels, row);
          const nextOptions = getContingentsOptions(nextChannels);

          return {
            channels: nextChannels,
            options: nextOptions,
            state: 'updated',
          };
        },
      onRowChange:
        ({ channels }) =>
        (row, field, value) => {
          let updatedRow = { ...row };

          // a single configuration changed
          if (field === 'numberOfPostings' || field === 'bonus') {
            updatedRow[field] = parseInt(value, 10);
          } else if (field === 'reach') {
            // the entire reach/bucket changed
            updatedRow = {
              ...row,
              ...pick(value, [
                'price',
                'advertiserPrice',
                'reach',
                'label',
                'id',
              ]),
            };
          }

          const index = findIndex(channels, { id: row.id });
          const nextChannels = findAndReplace(channels, updatedRow, { index });

          // if reach is changed, we need to change the id and options
          const overrides = {};
          if (field === 'reach') {
            overrides.options = getContingentsOptions(nextChannels);
          }

          return {
            channels: nextChannels,
            ...overrides,
            state: 'updated',
          };
        },
      onResetContingents: () => (campaign) => ({
        ...getInitialState(),
        ...getChannelsState(campaign),
      }),
      onSubmissionError: () => (error) => {
        if (error instanceof SubmissionError) {
          error = error.errors._error;
        }

        return { error };
      },
    },
  ),
  mapProps(({ channels, options, state, form, ...props }) => ({
    ...props,
    formName: form,
    form: { channels, options, state },
  })),
);
