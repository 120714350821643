import React from 'react';
import PropTypes from 'prop-types';
import { Fields } from 'redux-form';
import {
  withHandlers,
  withStateHandlers,
  defaultProps,
  compose,
} from 'recompose';

// HoCs

const withDisabledState = compose(
  defaultProps({
    disableOnMount: true,
  }),
  withStateHandlers(
    ({ inputs, disableOnMount }) => ({
      disabledState: inputs.reduce((acc, input, i) => {
        acc[input.name] = { disabled: i !== 0 && disableOnMount };
        return acc;
      }, {}),
    }),
    {
      onDisableInput:
        ({ disabledState }) =>
        (name) => ({
          disabledState: {
            ...disabledState,
            [name]: { disabled: true },
          },
        }),

      onEnableInput:
        ({ disabledState }) =>
        (name) => ({
          disabledState: {
            ...disabledState,
            [name]: { disabled: false },
          },
        }),
    },
  ),
);

/**
 * Provides a wrapper for Redux Form's provided onChange handlers such
 * that the onChange for dependent fields is also called
 */
const withChangeHandler = withHandlers({
  // Shitty API design by Redux form, but the fields are spread into the props
  // at the root level
  getChangeHandler:
    ({ initialValues, onDisableInput, onEnableInput, inputs, ...props }) =>
    (index) =>
    (...args) => {
      // When one field changes, we want to make sure the next is enabled
      // Will always be either length === 1 or empty
      const enabledInputs = inputs.slice(index + 1, index + 2);
      const disabledInputs = inputs.slice(index + 2);
      const clearedInputs = inputs.slice(index + 1);
      // Grab Redux form metadata for the changed field
      const ownField = props[inputs[index].name];

      enabledInputs.forEach(({ name }) => onEnableInput(name));

      disabledInputs.forEach(({ name }) => onDisableInput(name));

      clearedInputs.forEach(({ name }) => {
        const field = props[name];
        // Reset and disable dependent fields
        field.input.onChange(initialValues[name]);
      });

      ownField.input.onChange(...args);
    },
});

// Components

/**
 * Redux Form Fields element which takes a list of inputs and renders
 * them such that each input depends on the previous. Meaning you have
 * to provide input in order. Inputs are disabled and reset accordingly
 * to prevent out of order input
 */
function DependentFields({
  inputs,
  initialValues,
  disabledState,
  getChangeHandler,
  ...props
}) {
  return (
    <div className="dependent-fields">
      {inputs.map(({ component: Input, ...inputProps }, i) => {
        const { name } = inputProps;
        const inputDisabledState = disabledState[name];

        return (
          <Input
            {...inputDisabledState}
            {...inputProps}
            input={{
              ...props[name].input,
              onChange: getChangeHandler(i),
            }}
            meta={props[name].meta}
            key={name}
          />
        );
      })}
    </div>
  );
}

DependentFields.propTypes = {
  /**
   * Specification of inputs which are dependent on one another.
   * Each should specify at least a `name` and `component`. The component
   * should be compatible with the Readux Form Field API. All other
   * keys will be passed to the component as properties
   */
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
    }),
  ).isRequired,
  initialValues: PropTypes.object,

  // Internal
  /** @ignore */
  disabledState: PropTypes.object.isRequired,
  /** @ignore */
  getChangeHandler: PropTypes.func.isRequired,
};

const DependentFieldsEnhanced = compose(
  defaultProps({
    initialValues: {},
  }),
  withDisabledState,
  withChangeHandler,
)(DependentFields);

function DependentFieldsWrapped(props) {
  return (
    <Fields
      {...props}
      names={props.inputs.map(({ name }) => name)}
      component={DependentFieldsEnhanced}
    />
  );
}

DependentFieldsWrapped.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

// Export for styleguide discovery
export { DependentFields };

export default DependentFieldsWrapped;
