import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import breaks from 'remark-breaks';

function Markdown({
  markdown,
  className,
  noParagraphs,
  typeName,
  noBreaks,
  plugins,
}) {
  const renderers = {};

  if (typeName) {
    const Element = typeName;
    // eslint-disable-next-line react/prop-types
    renderers.root = ({ children, className }) => (
      <Element className={className}>{children}</Element>
    );
  }

  if (noParagraphs) {
    // eslint-disable-next-line react/prop-types
    renderers.paragraph = ({ children }) => <span>{children}</span>;
  }

  if (noBreaks) {
    renderers.break = () => null;
  }

  return (
    <ReactMarkdown
      plugins={[breaks, gfm, ...plugins]}
      renderers={renderers}
      linkTarget="_blank"
      className={cx('markdown', className)}
    >
      {markdown}
    </ReactMarkdown>
  );
}

Markdown.propTypes = {
  typeName: PropTypes.string,
  markdown: PropTypes.string,
  noBreaks: PropTypes.bool,
  noParagraphs: PropTypes.bool,
  plugins: PropTypes.array,
  className: PropTypes.string,
};

Markdown.defaultProps = {
  typeName: null,
  markdown: '',
  noBreaks: false,
  noParagraphs: false,
  plugins: [],
  className: '',
};

export default Markdown;
