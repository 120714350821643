import { connect } from 'react-redux';

import { compose, lifecycle, withHandlers, withPropsOnChange } from 'recompose';
import mixpanel from 'source/utils/mixpanel';
import ChannelSearchMain from './components/channelSearchMain';
import { payloadToEventProperties } from './utils';

import selector from './selectors';
import actions from './actions';

const enhance = compose(
  connect(selector, actions),
  withPropsOnChange(
    ['channels', 'channelAsync', 'categories', 'categoriesAsync'],
    ({ channels, channelAsync, categories, categoriesAsync }) => ({
      results: channels ? channels.data : null,
      counters: channels ? channels.meta.counters : null,
      loading: channelAsync.loading || categoriesAsync.loading,
      categories: categories ? categories.categories : [],
      categoriesByCode: categories ? categories.categoriesByCode : {},
    }),
  ),
  lifecycle({
    componentDidMount() {
      const { onLoadCategories } = this.props;

      mixpanel.track('View channel search');

      return onLoadCategories();
    },
    componentWillUnmount() {
      const { onResetScene } = this.props;
      return onResetScene();
    },
  }),
  withHandlers({
    handleSubmit:
      ({ channelSorting, onLoadChannels }) =>
      (payload) => {
        const eventProps = payloadToEventProperties(payload);

        mixpanel.track('Apply channel filter', eventProps);

        return onLoadChannels(payload, 0, channelSorting);
      },
    onSelectPage:
      ({ channelFilterValues, channelSorting, onLoadChannels }) =>
      (page) =>
        onLoadChannels(channelFilterValues, page, channelSorting),
    onChangeSort:
      ({
        channelFilterValues,
        channelPaging,
        onLoadChannels,
        onUpdateSorting,
      }) =>
      (sort) => {
        // update internal "sort" state
        onUpdateSorting(sort);
        // track Mixpanel event
        const eventProps = sort;
        mixpanel.track('Sort channel results', eventProps);
        // reload data
        return onLoadChannels(channelFilterValues, channelPaging.page, sort);
      },
    onReportDownload:
      ({ channelFilterValues: payload }) =>
      () => {
        const eventProps = payloadToEventProperties(payload);

        mixpanel.track('Download channel report', eventProps);
      },
    onChannelFilterReset: () => () => {
      mixpanel.track('Clear channel filter');
    },
  }),
);

export const ChannelSearch = enhance(ChannelSearchMain);
