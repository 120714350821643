import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import set from 'lodash/set';
import validator from 'validator';
import { Link } from 'react-router-dom';
import ChannelStatus from 'source/components/common/channelStatus';
import Icon from 'source/components/common/icon';
import ButtonWithConfirmation from 'source/components/common/buttonWithConfirmation';
import { reduxForm, Field, propTypes } from 'redux-form';

import QualityToggle from './qualityToggle';

function ChannelTypeDetails({ channel }) {
  const {
    platform,
    data: { propertyId },
  } = channel;

  const channels = {
    website: 'Website',
    ga: 'Google Analytics',
    instagram: 'Instagram',
    twitter: 'Twitter',
    youtube: 'Youtube',
    pinterest: 'Pinterest',
    tiktok: 'TikTok',
  };
  return (
    <>
      <div className="d-flex align-items-center ml-2">
        <Icon name={platform} className="small-icon " />
        <span className="ml-2">{channels[platform]}</span>
      </div>
      {channel.platform === 'ga' && (
        <span className="ml-4 text-muted">Property: {propertyId}</span>
      )}
    </>
  );
}

ChannelTypeDetails.propTypes = {
  channel: PropTypes.object.isRequired,
};

class ChannelDetailsForm extends React.PureComponent {
  handleUndoMigration = () => {
    const { onDelete } = this.props;

    return onDelete({ revertMigration: true });
  };

  renderField = ({
    className,
    showPercentage,
    input,
    label,
    smallLabel,
    meta: { error },
    ...rest
  }) => {
    const labelElement = smallLabel ? 'small' : 'label';

    if (input.value === '') {
      this.props.clearFields(false, false, input.name);
    }

    return (
      <>
        {React.createElement(
          labelElement,
          { htmlFor: input.name, className: 'text-muted truncate' },
          label,
        )}
        <div
          className={cx('position-relative', {
            percentage: showPercentage && input.value >= 0,
          })}
        >
          <input
            id={input.name}
            className={cx(className, { 'is-invalid': error })}
            {...input}
            {...rest}
          />
          <small className="invalid-feedback">{error}</small>
        </div>
      </>
    );
  };

  render() {
    const { pristine, requestState, channel, handleSubmit } = this.props;
    const isPiwikWebsite = channel.platform === 'website';

    return (
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              {/* Channel URL */}

              <div className="form-group mb-3">
                <Field
                  className="form-control"
                  name="data.url"
                  type="url"
                  label="Channel URL"
                  component={this.renderField}
                  readOnly={isPiwikWebsite}
                />
              </div>

              <div className="container no-padding">
                <div className="row">
                  <div className="col-lg">
                    <div className="form-group mb-3 channel-icon">
                      <span className="text-muted">Channel Type</span>
                      <ChannelTypeDetails channel={channel} />
                    </div>
                    <div className="form-group mb-3">
                      <span className="text-muted">User</span>
                      <Link
                        to={`/users/${channel.userId}`}
                        className="ml-2 d-block"
                      >
                        {`${channel.userName}[${channel.userId}]`}
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg">
                    {channel.referenceChannelId && (
                      <div className="form-group mb-3">
                        <span className="text-muted">Migrated from</span>
                        <Link
                          to={`/channels/${channel.referenceChannelId}`}
                          className="ml-2 d-block"
                        >
                          {channel.referenceChannelWebsiteId}
                        </Link>

                        <div className="mt-4">
                          <ButtonWithConfirmation
                            button={
                              <button
                                type="button"
                                className="btn btn-outline-danger"
                              >
                                Undo GA Migration
                              </button>
                            }
                            onConfirm={this.handleUndoMigration}
                          >
                            <p>
                              This will reactivate the old Piwik channel and
                              delete the new GA channel. If there are GA
                              applications, they will be deleted and the
                              influencer can re-apply. Are you sure you want to
                              continue?{' '}
                            </p>
                          </ButtonWithConfirmation>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-md-12">
              <div className="container no-padding">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group mb-3 channel-icon">
                      <span className="text-muted">Status</span>
                      <ChannelStatus
                        className="mt-3 ml-2"
                        ready={channel.ready}
                        readyState={channel.readyState}
                      />
                    </div>
                  </div>

                  <div className="col-lg-8">
                    {channel.platform === 'ga' && (
                      <QualityToggle disabled={requestState.submitting} />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-12">
                    {/* Internal Note */}
                    <span className="text-muted">Internal Note</span>
                    <Field
                      className="form-control note"
                      type="text"
                      label="Internal Note"
                      component="textarea"
                      rows="5"
                      name="notes"
                      placeholder={`Share your experience on "${channel.url}" with your colleagues...`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Submit section */}

          <div className="row mt-3">
            <div className="col">
              <div className="d-flex justify-content-end align-items-center">
                <input
                  type="submit"
                  className={cx('btn btn-primary', {
                    'btn-primary': !requestState.submitted,
                    'btn-success': requestState.submitted && pristine,
                  })}
                  value="Save"
                  disabled={pristine || requestState.submitting}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

ChannelDetailsForm.propTypes = {
  ...propTypes,
  requestState: PropTypes.shape({
    submitting: PropTypes.bool,
    submitted: PropTypes.bool,
  }),
  channel: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};

  const url = get(values, 'data.url', '');
  if (!url) {
    set(errors, 'data.url', 'Channel URL is required.');
  } else if (!validator.isURL(url)) {
    set(errors, 'data.url', 'Channel URL is not valid.');
  }

  return errors;
};

export default reduxForm({
  validate,
  enableReinitialize: true,
  form: 'channels/channelDetailsForm',
})(ChannelDetailsForm);
