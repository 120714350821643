import actionTypes from 'source/constants/actionTypes';
import { requestFlow, actionAuthRequest } from 'source/utils/axios';
import { entityCollectionSkipUpdate } from 'source/utils';

export const load = () => (dispatch, getState) => {
  const {
    iabCategories: { state, lastFetch },
  } = getState();

  if (entityCollectionSkipUpdate(state, lastFetch)) {
    return Promise.resolve();
  }

  const onBefore = () => dispatch({ type: actionTypes.IAB_CATEGORIES_LOADING });
  const onSuccess = (resp) =>
    dispatch({ type: actionTypes.IAB_CATEGORIES_LOADED, payload: resp.data });
  const onError = (resp) =>
    dispatch({ type: actionTypes.IAB_CATEGORIES_LOAD_FAILED, payload: resp });

  const request = () =>
    actionAuthRequest(dispatch, getState).get('/v2/categories');

  return requestFlow(request, { onBefore, onSuccess, onError });
};
