/**
 * Simple wrapper to make the old applications view work with the new campaign
 * detail root. Mostly a copy paste of the old applications view container
 */

import { connect } from 'react-redux';

import { getCampaign } from 'source/data/selectors';

import {
  applicationFilterChange,
  applicationFormChange,
  expandApplication,
  applicationSubmit,
  loadApplicationInsights,
  updateApplications,
  loadPublishingDates,
  loadApplications,
} from 'source/actions/campaigns/applications';

import { campaignApplications } from 'source/selectors/campaign/applications';

import CampaignApplications from 'source/components/campaigns/applications';

export default connect(
  (state, props) => ({
    ...campaignApplications(state),
    campaign: getCampaign(state, props),
  }),
  {
    loadApplicationInsights,
    onFilterChange: applicationFilterChange,
    onChange: applicationFormChange,
    onSubmit: applicationSubmit,
    onLoadApplications: loadApplications,
    updateApplications,
    onExpandApplication: expandApplication,
    loadPublishingDates,
  },
)(CampaignApplications);
