import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';

import { insertBy } from 'source/utils/imCollection';

class ChannelsTableRow extends React.PureComponent {
  handleChange = (e) => {
    const { row, options, onChange } = this.props;

    e.preventDefault();
    e.stopPropagation();

    let { value } = e.target;
    if (e.target.id === 'reach') {
      const reach = find(options, { id: Number(e.target.value) });
      value = reach;
    } else if (e.target.id === 'numberOfPostings') {
      value = Number(e.target.value.slice(0, 3));
      if (Number.isNaN(value) || value < 0) {
        value = 0;
      }
    } else if (e.target.id === 'bonus') {
      value = Number(e.target.value.slice(0, 4));
      if (Number.isNaN(value) || value < 0) {
        value = 0;
      }
    }

    return onChange(row, e.target.id, value);
  };

  handleDelete = (e) => {
    const { row, onRemove } = this.props;

    e.preventDefault();
    e.stopPropagation();

    return onRemove(row);
  };

  render() {
    const { row, options } = this.props;

    // NOTE: `options` := contains the contingents that are not inserted in a row
    //       `row`     := is the contigent for the current row
    //       The select input should contain all available contigents + the own
    //       contingent. The contingents will also be sorted by `id`.

    // NOTE: Our previous assumption was that `options` is sorted by `id`. Now
    //       they are sorted by `reach.min` and it happens when they are exported
    //       in `utils/reachOptions.js`.
    const rowOptions = insertBy(options, row, 'reach.min');

    // NOTE @alexspri
    //  we need to add some inline styles so the table borders look correct
    const inlineEditButtonStyles = { marginBottom: '-0.5rem' };
    const inlineReachSelectStyles = { marginBottom: '-0.24rem' };

    return (
      <tr className="form-group">
        <td className="col-bucket">{row.id}</td>
        <td className="col-channel">{capitalize(row.channel)}</td>
        <td className="col-reach">
          <select
            id="reach"
            className="custom-select"
            value={row.id}
            onChange={this.handleChange}
            style={inlineReachSelectStyles}
          >
            {rowOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            ))}
          </select>
        </td>
        <td className="col-postings">
          <input
            id="numberOfPostings"
            type="number"
            className="form-control form-control-sm small-input text-right pr-1"
            value={row.numberOfPostings}
            onChange={this.handleChange}
          />
        </td>
        <td className="col-bonus">
          <input
            id="bonus"
            type="number"
            className="form-control form-control-sm small-input text-right pr-1"
            value={row.bonus}
            onChange={this.handleChange}
          />
        </td>
        <td className="col-costs">
          <span>
            {row.numberOfPostings * (row.price + Number(row.bonus || 0))}
          </span>
          <span className="ml-1">€</span>
        </td>
        <td className="col-details">
          <button
            className="btn btn-danger btn-sm pull-right"
            onClick={this.handleDelete}
            style={inlineEditButtonStyles}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  }
}

ChannelsTableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    channel: PropTypes.string.isRequired,
    numberOfPostings: PropTypes.number.isRequired,
    bonus: PropTypes.number,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ChannelsTableRow;
