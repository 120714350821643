import { createConstants } from 'source/utils';

const actionTypes = createConstants(
  // global events
  'GLOBAL_GENERIC_REQUEST_ERROR',
  'GLOBAL_SERVER_REQUEST_ERROR',
  'GLOBAL_ERROR_CLEAR',
  'GLOBAL_RESET_STORE',
  // app
  'APP_STATE_LOADING',
  'APP_STATE_LOADED',
  'APP_STATE_LOADING_FAILED',
  // account
  'ACCOUNT_AUTH_URL_REQUESTING',
  'ACCOUNT_AUTH_URL_REQUESTED',
  'ACCOUNT_AUTH_URL_REQUEST_FAILED',
  'ACCOUNT_ACCESS_TOKEN_FETCHED',
  // links
  'LINKS_FILTER_CHANGE',
  'LINKS_FILTER_ADD',
  'LINKS_FILTER_REMOVE',
  'LINKS_PAGE_SELECTED',
  'LINK_DETAILS_LOADED',
  'LINKS_LINK_ADD',
  'LINKS_ADD_UPDATE',
  'LINKS_ADD_VALIDATION',
  'LINKS_ADD_SUBMITTING',
  'LINKS_ADD_SUBMITTED',
  'LINKS_ADD_SUBMITION_FAILED',
  'LINKS_ADD_CANCEL',
  'LINK_STATE_LOADING',
  'LINK_STATE_LOADED',
  'LINK_STATE_FAILED',
  'LINKS_ALERT_CLOSE',
  'LINK_DETAILS_LOADING',
  'LINK_DETAILS_EDIT',
  'LINK_DETAILS_EDIT_FORM',
  'LINK_DETAILS_EDIT_CANCEL',
  'LINK_DETAILS_FORM_VALIDATION',
  'LINK_DETAILS_FROM_SUBMITTED',
  'LINK_DETAILS_RESET',
  'LINKS_DETAILS_SUBMITTING',
  'LINKS_DETAILS_SUBMITTED',

  // campaign form
  'CAMPAIGN_SETUP_FORM_EXPAND',
  'CAMPAIGN_SETUP_FORM_CHANGE',
  'CAMPAIGN_SETUP_FORM_TABLE_CHANGE',
  'CAMPAIGN_SETUP_FORM_SUBMIT',
  'CAMPAIGN_SETUP_FORM_ERRORS',
  'CAMPAIGN_SETUP_DRAFT_CREATING',
  'CAMPAIGN_SETUP_DRAFT_CREATED',
  'CAMPAIGN_SETUP_DRAFT_CREATING_FAILED',
  // upload images
  'CAMPAIGN_IMAGES_UPLOADING',
  'CAMPAIGN_IMAGES_UPLOADED',
  'CAMPAIGN_IMAGES_UPLOADING_FAILED',
  // upload files
  'CAMPAIGN_FILES_UPLOADING',
  'CAMPAIGN_FILES_UPLOADED',
  'CAMPAIGN_FILES_UPLOADING_FAILED',
  // campaign uploads
  'CAMPAIGN_UPLOADS_SUBMITTING',
  'CAMPAIGN_UPLOADS_SUBMITTED',
  'CAMPAIGN_PATCHING',
  'CAMPAIGN_PATCHED',
  'CAMPAIGN_PATCHING_FAILED',
  'CAMPAIGN_SETUP_CONTINGENT_CHANNEL_ADDED',
  'CAMPAIGN_SETUP_CONTINGENT_CHANNEL_REMOVED',
  'CAMPAIGN_SETUP_COUNTRY_ADDED',
  'CAMPAIGN_SETUP_COUNTRY_REMOVED',
  'CAMPAIGN_SETUP_COUNTRY_EDITED',
  'CAMPAIGN_SETUP_DERIVE_CATEGORIES',
  'CAMPAIGN_SETUP_TASKS_NEW_TASK_ADDED',
  'CAMPAIGN_SETUP_TASKS_TASK_ADDED',
  'CAMPAIGN_SETUP_TASKS_TASK_REMOVED',
  'CAMPAIGN_SETUP_TASKS_TASK_CANCELLED',
  'CAMPAIGN_SETUP_TASK_CHANGED',
  'CAMPAIGN_SETUP_TASKS_TASK_CLICKED',
  'CAMPAIGN_SETUP_TASKS_TASK_MOVED',
  'CAMPAIGN_SERVER_ERROR',
  'CAMPAIGN_PUBLISHING',
  'CAMPAIGN_PUBLISHED',
  'CAMPAIGN_PUBLISHING_FAILED',
  'CAMPAIGN_SETUP_ALERT_CLOSE',
  'CAMPAIGN_SETUP_ALERT_FORM_CHANGE',
  'CAMPAIGN_SETUP_ALERT_FORM_CLOSE',
  'CAMPAIGN_SETUP_RESET',
  'CAMPAIGN_ARCHIVING',
  'CAMPAIGN_ARCHIVED_SUCCESFULLY',
  'CAMPAIGN_ARCHIVING_FAILED',
  'CAMPAIGN_DELETING',
  'CAMPAIGN_DELETED_SUCCESFULLY',
  'CAMPAIGN_DELETING_FAILED',
  // campaign reporting
  'CAMPAIGN_REPORTING_LOADING',
  'CAMPAIGN_REPORTING_LOADED',
  'CAMPAIGN_REPORTING_LOADING_FAILED',
  // campaign activation
  'CAMPAIGN_ACTIVATION_REQUEST',
  'CAMPAIGN_ACTIVATION_FAILURE',
  'CAMPAIGN_ACTIVATION_SUCCESS',
  // iab categories
  'IAB_CATEGORIES_LOADING',
  'IAB_CATEGORIES_LOADED',
  'IAB_CATEGORIES_LOAD_FAILED',
  // general
  'NOOP',
);

export default actionTypes;
