import React from 'react';
import PropTypes from 'prop-types';

import LoginButton from 'source/components/common/loginButton';
import Feature from 'source/components/common/feature';

function UserLogin({ user, onLogin }) {
  return (
    <div className="d-flex">
      <div>
        <h2>{user.firstname}</h2>
      </div>
      <Feature feature="loginAsInfluencer">
        <div className="ml-auto pt-2">
          <LoginButton
            accessToken={user.accessToken}
            name={user.firstname}
            onLoginClick={onLogin}
          />
        </div>
      </Feature>
    </div>
  );
}

UserLogin.propTypes = {
  user: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    accessToken: PropTypes.string,
  }),
  onLogin: PropTypes.func.isRequired,
};

export default UserLogin;
