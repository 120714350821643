import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

function EventBodyButton({ caption, url }) {
  return (
    <div className="card-body message pb-3">
      <Link className="btn btn-primary" to={url}>
        {caption}
      </Link>
    </div>
  );
}

EventBodyButton.propTypes = {
  caption: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default EventBodyButton;
