import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Markdown from 'source/components/common/markdown';
import { EventDefault } from './index';

const eventTaskEdit = ({ payload, ...props }) => {
  const { task } = payload;
  const headline = `${task.headline} / ${task.clientLabel}`;
  const content = task.body || '*empty content*';

  return (
    <EventDefault {...props}>
      <div className="card-body message">
        {headline}
        <div
          className={cx({
            'font-weight-bold': task.body,
            'text-muted': !task.body,
          })}
        >
          <Markdown markdown={content} />
        </div>
      </div>
    </EventDefault>
  );
};

eventTaskEdit.propTypes = {
  payload: PropTypes.shape({
    task: PropTypes.shape({
      headline: PropTypes.string,
      clientLabel: PropTypes.string,
      body: PropTypes.string,
    }),
  }).isRequired,
};

export default eventTaskEdit;
