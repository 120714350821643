import { keyedAsyncActionCreator } from '@blogfoster/redux-async-utils';

import { actionAuthRequest } from 'source/utils/axios';

export const actionTypes = {
  FETCH: 'data/reports/campaignReport/FETCH',
  CLEAR: 'data/reports/campaignReport/CLEAR',
};

/**
 * GET /v2/reports/campaigns/{campaignId}
 */
export const campaignReportFetch = (name) =>
  keyedAsyncActionCreator(
    actionTypes.FETCH,
    (campaignId, { filters = [] } = {}) =>
      (dispatch, getState) => {
        const params = {};
        if (filters && filters.length > 0) {
          params.filters = JSON.stringify(filters);
        }

        return actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
        }).get(`/v2/reports/campaigns/${campaignId}`, { params });
      },
    { name },
  );

export const campaignReportClear = (name) => (campaignId) => ({
  name,
  type: actionTypes.CLEAR,
  payload: campaignId,
});
