import moment from 'moment';
import get from 'lodash/get';

import { actionAuthRequest } from 'source/utils/axios';

/* == api helper == */

const api = {
  /**
   * GET /v2/applications
   */
  listApplications: (campaignId) => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState)
      .get('/v2/applications', {
        params: {
          filters: JSON.stringify([{ campaignId }]),
          fields: JSON.stringify([
            'id',
            'contingentId',
            'campaignId',
            'assignment',
            'channel',
            'channelId',
            'insightsAnalysis',
            'match',
            'mission',
            'pitch',
            'pricing',
            'socialMediaSharing',
            'status',
            'type',
            'tasks',
            'user',
            'groups',
            'userId',
            'version',
          ]),
        },
      })
      .then((resp) => resp.data),

  /**
   * PATCH /v2/applications
   */
  patchApplication:
    ({ applicationId, payload }, options) =>
    (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, options)
        .patch(`/v2/applications/${applicationId}`, payload)
        .then((resp) => resp.data),

  /**
   * GET /v2/applications/{id}?fields=["insights", "channel.performance"]
   */
  getApplicationInsightsAggregation:
    ({ applicationId }, options) =>
    (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, options)
        .get(`/v2/applications/${applicationId}`, {
          params: {
            fields: JSON.stringify([
              'insights',
              'channel.performance',
              'channel.metrics',
            ]),
          },
        })
        .then((resp) => get(resp, 'data', {})),

  /**
   * load publishing dates
   *
   * GET /v2/publishing-dates
   */
  getPublishingDates:
    ({ campaignId }, options) =>
    (dispatch, getState) => {
      const params = {
        filters: JSON.stringify([
          { campaignId },
          // NOTE @alexspri
          //  Load all publishing dates for this campaign and do client side
          //  filtering for vip date assignment. but also allow to show the
          //  publishing date in the panel
          {
            date: {
              greater: moment().startOf('day').toISOString(),
            },
          },
        ]),
      };

      return actionAuthRequest(dispatch, getState, options)
        .get('/v2/publishing-dates', { params })
        .then((resp) => resp.data);
    },
};

export default api;
