import {
  createValidator,
  combineValidators,
  composeValidators,
  isRequired,
} from 'revalidate';
import moment from 'moment';

import {
  createArrayValidator,
  isNotEmpty,
  isGreaterThan,
} from 'source/utils/validators';

const isBeforeApplicationEnd = createValidator(
  (message) => (value, allValues) => {
    if (!value || !allValues.applicationEnd) {
      return undefined;
    }

    if (moment(value).isAfter(moment(allValues.applicationEnd))) {
      return message;
    }

    return undefined;
  },
  (field) => `${field} must be before Application end`,
);

export default combineValidators({
  applicationStart: composeValidators(
    isRequired,
    isBeforeApplicationEnd,
  )('Application start'),
  publishingDates: createArrayValidator(
    // array validation
    composeValidators(isRequired, isNotEmpty)('Publishing Dates'),
    // item validation
    combineValidators({
      date: isRequired('Date'),
      // redux-form removes the value of a checkbox Field if it is not checked.
      // vip: isRequired('VIP'),
      posts: composeValidators(isRequired, isGreaterThan(0))('Posts'),
    }),
  ),
});
