import { loadStateReducer } from '@blogfoster/redux-async-utils';
import { combineReducers } from 'redux';
import { actionTypes } from './actions';

export const selectors = {
  getVipDatesStatus: (state) => state.vipDates,
  getAdditionalTermsStatus: (state) => state.additionalTerms,
  getInsightsAsync: (state) => state.insights,
  getPublishingDatesAsync: (state) => state.publishingDates,
};

export default combineReducers({
  insights: loadStateReducer(actionTypes.INSIGHTS_FETCH),
  publishingDates: loadStateReducer(actionTypes.PUBLISHING_DATES_FETCH),
});
