import noop from 'lodash/noop';
import axios from 'axios';

import config from 'config';

import {
  genericRequestErrorHappened,
  serverErrorRequestErrorHappened,
  unauthorizedRequestHappend,
} from 'source/actions/error';

export const getAxios = () => axios;

const create = (baseURL, headers = {}, options = {}) => {
  const _headers = { ...headers, 'Content-Type': 'application/json' };

  const instance = axios.create({
    baseURL,
    timeout: config.api.timeout,
    headers: _headers,
    ...options,
  });

  const methods = {
    auth(token) {
      instance.defaults.headers.Authorization = `Bearer ${token}`;

      return instance;
    },
  };

  return { ...instance, ...methods };
};

const initRequest = (
  request,
  dispatch,
  { handleUnauthorizedRequest = true, dispatchErrors = true } = {},
) => {
  request.interceptors.response.use(
    // valid response
    (response) => response,
    // error response
    (error) => {
      if (!error.response) {
        if (dispatchErrors) {
          dispatch(genericRequestErrorHappened(error));
          error.dispatched = true;
        }

        return Promise.reject(error);
      }

      const { response } = error;

      if (handleUnauthorizedRequest && response.status === 401) {
        dispatch(unauthorizedRequestHappend(response));
        response.dispatched = true;
        return Promise.reject(response);
      }

      if (response.status >= 500) {
        dispatch(serverErrorRequestErrorHappened(response));
        response.dispatched = true;
      }

      if (!response.dispatched && dispatchErrors) {
        dispatch(genericRequestErrorHappened(response.data));
        response.dispatched = true;
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        ...response.data,
        dispatched: response.dispatched,
      });
    },
  );

  return request;
};

export const actionRequest = (dispatch, requestOpts = {}) => {
  const request = create(
    config.api.url,
    requestOpts.headers,
    requestOpts.options,
  );

  return initRequest(request, dispatch, requestOpts);
};

export const actionAuthRequest = (dispatch, getState, requestOpts) => {
  const {
    account: { accessToken },
  } = getState();

  return actionRequest(dispatch, requestOpts).auth(accessToken);
};

export const requestFlow = (
  request,
  { onBefore = noop, onSuccess = noop, onError = noop } = {},
) =>
  Promise.resolve().then(onBefore).then(request).then(onSuccess).catch(onError);

/**
 * Creates a mock for
 */
export const authRequestMock =
  ({
    payload = {},
    headers = {},
    statusCode = 200,
    error = false,
    timeout = 100,
    sideEffect = noop,
  } = {}) =>
  (/* dispatch, getState */) => {
    const response = (data) => ({
      statusCode,
      data,
      headers,
    });

    const getPayload = typeof payload === 'function' ? payload : () => payload;

    const request = () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          sideEffect();
          if (error) {
            reject(response(getPayload()));
          } else {
            resolve(response(getPayload()));
          }
        }, timeout);
      });

    return {
      get: request,
      post: request,
      delete: request,
      patch: request,
    };
  };
