import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Alert } from 'source/components/common';
import { getReviewPaymentState } from '../selectors';
import actions from '../actions';

const enhance = compose(connect(getReviewPaymentState, actions));

class ReviewPayment extends React.PureComponent {
  componentDidMount() {
    const { onValidate, payment } = this.props;

    onValidate(payment);
  }

  handleEdit = (e) => {
    const { onEdit } = this.props;
    e.preventDefault();

    return onEdit();
  };

  handleSubmit = (e) => {
    const { onApprove, paymentPayload } = this.props;
    e.preventDefault();

    return onApprove(paymentPayload);
  };

  render() {
    const { payment, alert, onAlertClose, state } = this.props;
    const stateLoading = state === 'loading';

    return (
      <div className="container">
        <Alert {...alert} onClose={onAlertClose} />

        <div className="row">
          <div className="col">
            <h2>Create new payment</h2>
          </div>
        </div>
        <hr />

        <form>
          <div className="form-group row">
            <label htmlFor="approved-by" className="col-sm-2 col-form-label">
              Approved by:
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="approved-by"
                value={payment.approvedBy.value}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="beneficiary" className="col-sm-2 col-form-label">
              Beneficiary:
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="beneficiary"
                value={payment.userId.value}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="amount" className="col-sm-2 col-form-label">
              Amount:
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="amount"
                value={payment.amount.value}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="currency" className="col-sm-2 col-form-label">
              Currency:
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="currency"
                value={payment.currency.value}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="description" className="col-sm-2 col-form-label">
              Description:
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="description"
                value={payment.description.value}
              />
            </div>
          </div>
          {payment.campaignId.id && (
            <div className="form-group row">
              <label htmlFor="campaign" className="col-sm-2 col-form-label">
                Campaign:
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="campaign"
                  value={payment.campaignId.value}
                />
              </div>
            </div>
          )}
          {payment.applicationId.id && (
            <div className="form-group row">
              <label htmlFor="application" className="col-sm-2 col-form-label">
                Application:
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="application"
                  value={payment.applicationId.value}
                />
              </div>
            </div>
          )}

          <div className="row justify-content-end pr-3">
            <button
              type="submit"
              className="btn btn-outline-primary"
              onClick={this.handleEdit}
            >
              Back to edit
            </button>
            <button
              type="button"
              className="btn btn-primary ml-1"
              onClick={this.handleSubmit}
              disabled={stateLoading}
            >
              Release payment
            </button>
          </div>
        </form>
      </div>
    );
  }
}

ReviewPayment.propTypes = {
  state: PropTypes.string.isRequired,
  alert: PropTypes.object,
  onApprove: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  payment: PropTypes.shape({
    approvedBy: PropTypes.object,
    userId: PropTypes.object,
    amount: PropTypes.object,
    currency: PropTypes.object,
    description: PropTypes.object,
    campaignId: PropTypes.object,
    applicationId: PropTypes.object,
  }).isRequired,
  paymentPayload: PropTypes.shape({
    approvedBy: PropTypes.string,
    userId: PropTypes.number,
    amount: PropTypes.string,
    currency: PropTypes.string,
    description: PropTypes.string,
    campaign: PropTypes.string,
    application: PropTypes.string,
  }).isRequired,
  onAlertClose: PropTypes.func.isRequired,
};

export default enhance(ReviewPayment);
