import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, FormSection } from 'redux-form';
import cx from 'classnames';

import Alert from 'source/components/common/alert';

import PublishingDate from './publishingDate';

class PublishingDatesContent extends React.PureComponent {
  handleAddDate = () => this.props.fields.push({ vip: false, posts: 5 });

  handleRemoveDate = (index) => this.props.fields.remove(index);

  render() {
    const {
      fields,
      meta: { error },
    } = this.props;

    return (
      <div>
        <table className="table table-hover publishing-dates-table">
          <thead>
            <tr className={cx({ 'no-dates': fields.length === 0 })}>
              <th />
              <th>Date</th>
              <th>VIP</th>
              <th>Quantity</th>
              <th className="clearfix">
                <button
                  type="button"
                  className="btn btn-primary float-right"
                  onClick={this.handleAddDate}
                >
                  Add Date
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {fields.map((publishingDate, index) => (
              <FormSection key={publishingDate} name={publishingDate}>
                <PublishingDate id={index} onRemove={this.handleRemoveDate} />
              </FormSection>
            ))}
          </tbody>
        </table>
        <div className="form-group has-danger">
          <Alert type="warning" message={error} />
        </div>
      </div>
    );
  }
}

PublishingDatesContent.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

function PublishingDates({ name, ...props }) {
  return (
    <FieldArray name={name} component={PublishingDatesContent} props={props} />
  );
}

PublishingDates.propTypes = {
  name: PropTypes.string.isRequired,
};

export default PublishingDates;
