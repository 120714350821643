import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import cx from 'classnames';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

/**
 * Redux-form compatible wrapper for the `Toggle` component from 'react-toggle'.
 *
 * @example
 *
 * <Field
 *   name="someBoolean"
 *   component={Toggle}
 *   onChange={props.someOnChangeHandler}
 * />
 */
function ReduxFormToggle({
  input: { name, value, onChange, onFocus, onBlur },
  meta: { error },
  className,
  ...toggleProps
}) {
  return (
    <div className={cx('blogfoster-toggle', className)}>
      <Toggle
        name={name}
        checked={value || false}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        {...toggleProps}
      />
      {error ? <div className="error">{error}</div> : null}
    </div>
  );
}

ReduxFormToggle.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

ReduxFormToggle.defaultProps = {
  className: '',
  disabled: false,
};

export default pure(ReduxFormToggle);
