export const actionTypes = {
  CHECKBOX_SET: 'checkbox/SET',
};

export const getChecked = (state) => state;

export const checkboxActionCreator =
  (name, extension = {}) =>
  (value) => ({
    ...extension,
    type: actionTypes.CHECKBOX_SET,
    payload: value,
    name,
  });

export const checkboxReducer =
  (name, initialValue = false) =>
  (state = initialValue, action) =>
    action.type === actionTypes.CHECKBOX_SET && action.name === name
      ? action.payload
      : state;
