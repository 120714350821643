import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import get from 'lodash/get';

import { getCampaignsById } from 'source/data/selectors';
import { forSlice } from 'source/utils/selectors';

import { fetchCampaign, setActiveCampaign, resetScene } from './actions';
import { selectors as fromState } from './reducer';
import DetailRoot from '../root';

// Selectors

const containerSelector = (slice, overrides = {}) => {
  const getActiveCampaignIdGlobal = forSlice(
    slice,
    fromState.getActiveCampaignId,
  );

  const getFetchStateGlobal = forSlice(slice, fromState.getCampaignFetchState);

  const getCampaign = createSelector(
    getCampaignsById,
    getActiveCampaignIdGlobal,
    (campaignsById, activeCampaignId) => campaignsById[activeCampaignId],
  );

  const getCounters = createSelector(getCampaign, (campaign = {}) => {
    const applicationsSummary = get(campaign, 'summary.applications', {
      total: 0,
      mission: 0,
    });
    const todosSummary = get(campaign, 'summary.todos', {
      total: 0,
    });

    return {
      applications: applicationsSummary.total,
      missions: applicationsSummary.mission,
      todos: todosSummary.total,
    };
  });

  const getCampaignName = (state, props) => {
    const campaign = getCampaign(state, props) || {};
    return campaign.name || null;
  };

  return createStructuredSelector({
    campaign: getCampaign,
    campaignFetchState: getFetchStateGlobal,
    campaignName: getCampaignName,
    counters: getCounters,
    ...overrides,
  });
};

// Container

export default function createRootContainer({
  slice,
  selectors = {},
  actions = {},
} = {}) {
  return connect(containerSelector(slice, selectors), {
    onGetCampaign: fetchCampaign,
    onSetActiveCampaign: setActiveCampaign,
    onResetScene: resetScene,
    ...actions,
  })(DetailRoot);
}
