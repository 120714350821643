import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Input, FileDrop } from 'source/scenes/components/reduxFormAdapters';

// Rendered inside a `Fields` component because they depend on each other.
function Attachment({ file, url, filename, onFileChange }) {
  return (
    <div>
      <FileDrop
        {...file}
        label="Upload a file ..."
        placeholder="Choose or drop a file..."
        disabled={Boolean(url.input.value)}
        onChange={onFileChange}
      />

      <Input
        {...url}
        label="... or provide an image url."
        placeholder="image url..."
        type="url"
        disabled={!isEmpty(file.input.value)}
      />

      <Input
        {...filename}
        label="Filename:"
        placeholder="filename is mandatory ..."
      />
    </div>
  );
}

Attachment.propTypes = {
  file: PropTypes.object.isRequired,
  url: PropTypes.object.isRequired,
  filename: PropTypes.object.isRequired,
  onFileChange: PropTypes.func.isRequired,
};

export default Attachment;
