import { asyncStates } from '@blogfoster/redux-async-utils';
import omit from 'lodash/omit';
import get from 'lodash/get';

const getDataDefault = (payload) => get(payload, 'data');

export const dataMergeHandler = ({ getData = getDataDefault } = {}) => ({
  [asyncStates.success]: (state, { payload }) => ({
    ...state,
    ...getData(payload),
  }),
});

export const dataReplaceHandler = ({ getData = getDataDefault } = {}) => ({
  [asyncStates.success]: (state, { payload }) => getData(payload),
});

export const dataCreateHandler = ({
  key = 'id',
  getData = getDataDefault,
} = {}) => ({
  [asyncStates.success]: (state, { payload }) => ({
    ...state,
    [payload.data[key]]: getData(payload),
  }),
});

export const dataReplaceManyHandler = ({
  key = 'id',
  getData = getDataDefault,
} = {}) => ({
  [asyncStates.success]: (state, { payload }) =>
    getData(payload).reduce(
      (state, data) => ({
        ...state,
        [data[key]]: data,
      }),
      { ...state },
    ),
});

export const dataMergeManyHandler = ({
  key = 'id',
  getData = getDataDefault,
} = {}) => ({
  [asyncStates.success]: (state, { payload }) =>
    getData(payload).reduce(
      (state, data) => ({
        ...state,
        [data[key]]: {
          ...get(state, data[key]),
          ...data,
        },
      }),
      { ...state },
    ),
});

export const dataDeleteHandler = () => ({
  [asyncStates.success]: (state, { key }) => omit(state, [key]),
});
