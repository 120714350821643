import moment from 'moment';
import pick from 'lodash/pick';
import { formValueSelector } from 'redux-form';
import { createSelector, createStructuredSelector } from 'reselect';
import { getPages as getPaginationPages } from 'source/components/common/pagination';
import { redux as collapsiblePanelRedux } from 'source/components/common/collapsiblePanel';

const channelFormValueSelector = formValueSelector('channels/channelDetails');
const getState = (state) => state.scenes.channels.state;
const getUser = (state) => state.app.user;
const getRequestState = (state) =>
  pick(state.scenes.channels, ['submitting', 'submitted']);
const getRequestInsightsState = (state) =>
  pick(state.scenes.channels, ['insightsSubmitting', 'insightsSubmitted']);
const getError = (state) => state.scenes.channels.error;
const getAlert = (state) => state.scenes.channels.alert;
const getChannels = (state) => state.scenes.channels.channels;
const getPerPage = (state) => state.scenes.channels.paging.pageSize;
const getPage = (state) => state.scenes.channels.paging.page;
const getPagingCount = (state) => state.scenes.channels.paging.count;
const getPagingTotal = (state) => state.scenes.channels.paging.total;
const getFilters = (state) => state.scenes.channels.filters;
export const getChannelState = (state) => state.scenes.channels.channel;
const getChannelFormInitialValues = (state) =>
  state.scenes.channels.channelFormInitialValues;
const getReferenceChannel = (state) =>
  state.scenes.channels.channel.referenceChannel;

const getChannelFormValues = (state) => ({
  kpi: channelFormValueSelector(state, 'kpi'),
  keyMetrics: channelFormValueSelector(state, 'keyMetrics'),
  data: channelFormValueSelector(state, 'data'),
  qualityReview: channelFormValueSelector(state, 'qualityReview'),
  notes: channelFormValueSelector(state, 'notes'),
});

const getChannel = createSelector(
  getChannelState,
  getReferenceChannel,
  (channel, referenceChannel) => ({
    id: channel.id,
    platform: channel.platform,
    websiteId: channel.data.id,
    userId: channel.user.id,
    userName: channel.user.firstname,
    url: channel.data.url,
    name: channel.name || channel.data.accountName || channel.data.name,
    qualityReview: channel.qualityReview,
    ready: channel.ready,
    readyState: channel.readyState,
    referenceChannelId: referenceChannel && referenceChannel.id,
    referenceChannelWebsiteId: referenceChannel && referenceChannel.data.id,
    extension: channel.extension,
    kpi: channel.kpi,
    keyMetrics: channel.keyMetrics,
    keyMetricsCache: channel.keyMetricsCache,
    screenshots: channel.screenshots,
    notes: channel.notes,
    data: channel.data,
    updatedAt: channel.updatedAt,
    avatarUrl: channel.avatarUrl,
  }),
);

const getPanels = (state) => state.scenes.channels.panels;
const getSelectedPanel = createSelector(getPanels, (panels) =>
  collapsiblePanelRedux.selectors.getExpanded(panels),
);

const getChannelsTableElement = (channel) => ({
  id: channel.id,
  websiteId: channel.data.id,
  platform: channel.platform,
  url: channel.data.url,
  user: channel.user,
  qualityReview: channel.qualityReview,
  name: channel.name || channel.data.accountName || channel.data.name,
  ready: channel.ready,
  readyState: channel.readyState,
  createdAt: moment(channel.createdAt).format('YYYY-MM-DD HH:mm'),
});

const getChannelsTableElements = createSelector(getChannels, (channels) =>
  channels.map(getChannelsTableElement),
);

const getChannelsTablePages = createSelector(
  getPerPage,
  getPagingCount,
  (perPage, count) => getPaginationPages(count, perPage),
);

const getCounter = createSelector(
  getPagingCount,
  getPagingTotal,
  (count, total) => ({ count, total }),
);

export const getChannelsState = createStructuredSelector({
  state: getState,
  channels: getChannelsTableElements,
  page: getPage,
  pages: getChannelsTablePages,
  counter: getCounter,
  filters: getFilters,
  alert: getAlert,
});

export const getChannelDetailsState = createStructuredSelector({
  user: getUser,
  state: getState,
  channel: getChannel,
  requestState: getRequestState,
  requestInsightsState: getRequestInsightsState,
  initialValues: getChannelFormInitialValues,
  formValues: getChannelFormValues,
  alert: getAlert,
  selectedPanel: getSelectedPanel,
  error: getError,
});
