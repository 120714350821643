import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Toggle } from 'source/scenes/components/reduxFormAdapters';

class QualityToggle extends React.PureComponent {
  renderLabel = (qualityReview) => {
    const label = qualityReview
      ? 'Fully compliant: can apply to campaigns if status is "ready"'
      : '⚠️ Not compliant: can\'t apply to campaigns even if status is "ready"';

    return <span className="ml-2">{label}</span>;
  };

  renderToggle = (props) => (
    <>
      <Toggle {...props} />
      {this.renderLabel(props.input.value)}
    </>
  );

  render() {
    return (
      <div className="form-group">
        <span className="text-muted">Quality Review</span>
        <div className="d-flex mt-1">
          <Field
            className="align-self-start"
            name="qualityReview"
            component={this.renderToggle}
          />
        </div>
      </div>
    );
  }
}

QualityToggle.propTypes = {
  disabled: PropTypes.bool,
};

QualityToggle.defaultProps = {
  disabled: false,
};

export default QualityToggle;
