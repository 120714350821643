import React from 'react';
import PropTypes from 'prop-types';
import { withProps, compose } from 'recompose';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';

import Forms from './index';

// Helpers

const getFormConfig = (form) => {
  const formData = Forms[form];

  if (!formData) {
    throw new Error(`cannot render form ${form}`);
  }

  return formData;
};

// HoCs

const exists = (val) => !isNil(val) && val !== '';

/**
 * Helper to compute final configuration of forms, taking into account
 * both the common config, and custom config specified at container creation
 */
const withSectionsConfig = withProps(({ sections }) => ({
  sections: sections.map(({ forms = [], ...rest }) => ({
    ...rest,
    forms: forms.filter(exists).map((customFormConfig) => {
      // Normalize custom config
      customFormConfig = isString(customFormConfig)
        ? { id: customFormConfig }
        : customFormConfig;

      const defaultFormConfig = getFormConfig(customFormConfig.id);

      // Normalize prop getters
      const getDefaultProps = isFunction(defaultFormConfig.withProps)
        ? defaultFormConfig.withProps
        : () => defaultFormConfig.withProps || {};
      const getCustomProps = isFunction(customFormConfig.withProps)
        ? customFormConfig.withProps
        : () => customFormConfig.withProps || {};

      return {
        ...defaultFormConfig,
        ...customFormConfig,
        withProps: (props) => ({
          ...getDefaultProps(props),
          ...getCustomProps(props),
        }),
      };
    }),
  })),
}));

// Components

/**
 * Wrapper for assembling multiple forms within different sections.
 *
 * @example
 *
 * const formSections = [
 *   {
 *     id: 'create-campaign',
 *     headline: 'Create a new campaign',
 *     forms: [
 *       {
 *         id: 'init',
 *         Component: (<form></form>),
 *         withProps: props => ({ form: 'xxx', disabled: !props.newCampaign }),
 *       }
 *     ],
 *   }
 * ];
 */
function FormSections({ sections, ...sectionProps }) {
  let index = 0;

  return (
    <div>
      {sections.map(({ id, headline, forms = [] }) => (
        <section key={id} className="form-section pb-3">
          {headline ? (
            <h5 className="text-lg-center py-1 pt-3 pb-3">{headline}</h5>
          ) : null}

          {forms.map(({ id, component: Component, withProps }) => {
            const componentProps = withProps(sectionProps);
            index += 1;

            return (
              <Component
                key={id}
                id={id}
                index={index}
                {...sectionProps}
                {...componentProps}
              />
            );
          })}
        </section>
      ))}
    </div>
  );
}

const formShape = PropTypes.shape({
  id: PropTypes.string,
  component: PropTypes.any, // suppresses react warning
  withProps: PropTypes.func,
});

FormSections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      headline: PropTypes.node,
      forms: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, formShape]),
      ),
    }),
  ),
};

FormSections.defaultProps = {
  sections: [],
};

export default compose(withSectionsConfig)(FormSections);
