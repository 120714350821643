import React from 'react';
import PropTypes from 'prop-types';

import PreviewReviewManager from './previewReviewManager';
import Headline from '../../missions/components/missionHeadline';
import { getReviewSubHeading } from '../helpers';

function ContentPreviewReviewView({
  campaign,
  application,
  selectedContentPreview,
  onCheckTask,
  onUpdateContentPreviewReview,
  onUpdateContentPreviewMedia,
  onUpdateContentPreviewAccess,
}) {
  return (
    <div className="container">
      <div className="row application-review-container">
        <Headline
          application={application}
          heading={application.user.firstname}
          subHeading={getReviewSubHeading(application)}
        />
        <div className="col">
          <PreviewReviewManager
            selectedContentPreview={selectedContentPreview}
            application={application}
            campaign={campaign}
            onCheckTask={onCheckTask}
            onUpdateContentPreviewReview={onUpdateContentPreviewReview}
            onUpdateContentPreviewMedia={onUpdateContentPreviewMedia}
            onUpdateContentPreviewAccess={onUpdateContentPreviewAccess}
          />
        </div>
      </div>
    </div>
  );
}

ContentPreviewReviewView.propTypes = {
  selectedContentPreview: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  onCheckTask: PropTypes.func.isRequired,
  onUpdateContentPreviewReview: PropTypes.func.isRequired,
  onUpdateContentPreviewMedia: PropTypes.func.isRequired,
  onUpdateContentPreviewAccess: PropTypes.func.isRequired,
};

export default ContentPreviewReviewView;
