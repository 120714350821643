import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Field } from 'redux-form';

import CategoriesFilter from './categoriesFilter';
import CategoriesFilterType from './categoriesFilterType';

const toNumber = (value) =>
  isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);

function ChannelFilter({ handleSubmit, reset, categories, onReset }) {
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div>
        <div className="mb-4">
          <h3>User</h3>
          <div className="form-group">
            <Field
              className="form-control"
              name="user.language"
              component="select"
            >
              <option value="">All languages</option>
              <option value="de">German</option>
              <option value="en">English</option>
            </Field>
          </div>
          <div className="form-group">
            <Field
              className="form-control"
              name="user.country"
              component="select"
            >
              <option value="">All countries</option>
              <option value="de">Germany</option>
              <option value="gb">United Kingdom</option>
              <option value="at">Austria</option>
              <option value="ch">Switzerland</option>
              <option value="ie">Ireland</option>
              <option value="us">United States</option>
              <option value="fr">France</option>
              <option value="it">Italy</option>
              <option value="es">Spain</option>
              <option value="in">India</option>
              <option value="nl">Netherlands</option>
              <option value="pl">Poland</option>
              <option value="au">Australia</option>
              <option value="pt">Portugal</option>
              <option value="ca">Canada</option>
              <option value="ee">Estonia</option>
              <option value="se">Sweden</option>
              <option value="gr">Greece</option>
            </Field>
          </div>
        </div>
        <div className="mb-4">
          <h3>Channel</h3>
          <div className="form-group">
            <Field className="form-control" name="platform" component="select">
              <option value="blogs">All Blogs</option>
              <option value="website">Piwik Blogs</option>
              <option value="ga">Google Blogs</option>
              <option value="instagram">Instagram</option>
              <option value="youtube">Youtube</option>
              <option value="tiktok">TikTok</option>
              <option value="pinterest">Pinterest</option>
            </Field>
          </div>
          <div className="form-group">
            <Field
              id="reachMin"
              className="form-control"
              name="reachMin"
              component="input"
              min="0"
              type="number"
              placeholder="Min. reach"
              normalize={toNumber}
            />
          </div>
          <div className="form-group">
            <Field
              id="reachMax"
              className="form-control"
              name="reachMax"
              component="input"
              min="0"
              type="number"
              placeholder="Max. reach"
              normalize={toNumber}
            />
          </div>
        </div>
        <div className="mb-4">
          <h3>Categories</h3>
          <div className="form-group">
            <Field
              name="categoriesFilterType"
              component={CategoriesFilterType}
            />

            <FieldArray
              name="categories"
              component={CategoriesFilter}
              categories={categories}
            />
          </div>
        </div>
      </div>
      <button className="btn btn-block btn-primary" type="submit">
        Apply filter
      </button>
      <button
        className="btn btn-block btn-outline-primary"
        type="button"
        onClick={() => {
          reset();
          onReset();
        }}
      >
        Clear filter
      </button>
    </form>
  );
}

ChannelFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
};

export default ChannelFilter;
