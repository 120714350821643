import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'source/components/common/icon';

function MissionSection({ className, bold, label, open, onToggle, children }) {
  return (
    <div className={className}>
      <div
        className={cx('mission-section-header', {
          'mission-section-header-open': open,
          'mission-section-header-closed': !open,
        })}
        onClick={onToggle}
      >
        <span
          className={cx('mission-section-header-label', {
            'mission-section-header-label-bold': bold,
          })}
        >
          {label}
        </span>
        <Icon
          name={open ? 'chevron-up' : 'chevron-down'}
          className="small-icon"
        />
      </div>
      <div className="ml-1">{open && children}</div>
    </div>
  );
}

MissionSection.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  open: PropTypes.bool,
  bold: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

MissionSection.defaultProps = {
  className: '',
  open: false,
  bold: true,
};

export default MissionSection;
