import { combineReducers } from 'redux';
import * as Sentry from '@sentry/browser';
import { asyncActionReducer } from '@blogfoster/redux-async-utils';

import { combineReducersFlat, keyValueStoreReducer } from 'source/utils/redux';

import { actionTypes } from './actions';
import {
  dataMergeManyHandler,
  dataMergeHandler,
  dataCreateHandler,
  dataDeleteHandler,
} from '../dataHandlers';

/* == selectors == */
export const getApplicationsById = (state) => state.byId;
export const getApplication = (state, { applicationId }) =>
  state.byId[applicationId];

const applicationFetchManyReducer = asyncActionReducer(
  actionTypes.FETCH_MANY,
  dataMergeManyHandler(),
);

const applicationFetchReducer = asyncActionReducer(
  actionTypes.FETCH,
  dataMergeHandler(),
);

const applicationUpdateReducer = asyncActionReducer(
  actionTypes.UPDATE,
  dataMergeHandler(),
);

const applicationDeleteReducer = asyncActionReducer(
  actionTypes.DELETE,
  dataDeleteHandler(),
);

const applicationCreateReducer = asyncActionReducer(
  actionTypes.CREATE,
  dataCreateHandler(),
);

const applicationClearReducer = (state, action) => {
  if (action.type === actionTypes.CLEAR) {
    Sentry.addBreadcrumb({
      category: 'reducer',
      message: actionTypes.CLEAR,
      level: Sentry.Severity.Debug,
      data: {
        payload: action.payload,
      },
    });

    return action.payload.reduce(
      (state, applicationId) => {
        state[applicationId] = undefined;
        return state;
      },
      { ...state },
    );
  }
  return state;
};

const applicationsByIdReducer = combineReducersFlat(
  [
    applicationFetchManyReducer,
    applicationCreateReducer,
    applicationClearReducer,
    applicationDeleteReducer,
    keyValueStoreReducer(
      combineReducersFlat([applicationFetchReducer, applicationUpdateReducer]),
    ),
  ],
  {},
);

export default combineReducers({
  byId: applicationsByIdReducer,
});
