import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function SettingsBar({ className, children }) {
  return (
    <div
      className={cx(
        'row',
        'justify-content-end',
        'align-items-center',
        'pr-3',
        className,
      )}
    >
      {children}
    </div>
  );
}

SettingsBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

SettingsBar.defaultProps = {
  className: '',
  children: null,
};

export default SettingsBar;
