import { createConstants } from 'source/utils';

const actionTypes = createConstants(
  // expand todo collapsible panel
  'CAMPAIGNS_TODOS_EXPAND_TODO',
  // loading todos
  'CAMPAIGNS_TODOS_LOADING',
  'CAMPAIGNS_TODOS_LOADED',
  // fire actions from the todo tab
  'CAMPAIGNS_TODOS_ACTION_UPDATING',
  'CAMPAIGNS_TODOS_ACTION_UPDATED',
);

export default actionTypes;
