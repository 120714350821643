import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, SubmissionError } from 'redux-form';
import { isURL } from 'validator';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { withProps, withHandlers, compose, setPropTypes } from 'recompose';

import Badge from 'source/components/common/badge';
import { LabeledTextArea } from 'source/scenes/components/labeledInputs';

// Helpers

/**
 * To patch application tasks we need to send the _full_ list of tasks,
 * although we have only edited a subset. Here we merge the update tasks into
 * the full list by using a temporary _index field to determine their position.
 */
const mergeTasks = (tasks, individualTasks) =>
  individualTasks.reduce((tasks, updatedTask) => {
    tasks.splice(updatedTask._index, 1, omit(updatedTask, ['_index']));
    return tasks;
  }, tasks);

// Components

function ValueInput({ input: { value } }) {
  return <span>{value}</span>;
}

ValueInput.propTypes = {
  input: PropTypes.object.isRequired,
};

function UpdatedStatus({ input: { value } }) {
  return (
    <Badge pill type={value ? 'success' : 'warning'}>
      {value ? 'ready' : 'unedited'}
    </Badge>
  );
}

UpdatedStatus.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.bool,
  }).isRequired,
};

const withOnChangeUpdate = withHandlers({
  onChange:
    ({ change, name }) =>
    () => {
      // If we've edited a task at least once, mark it is "updated" in the form
      change(`${name}.updated`, true);
    },
});

const TaskEditRow = compose(withOnChangeUpdate)(
  ({ name, disabled, onChange }) => (
    <tr>
      <td>
        <Field name={`${name}.updated`} component={UpdatedStatus} />
      </td>
      <td>
        <Field name={`${name}.type`} component={ValueInput} />
      </td>
      <td>
        <Field
          name={`${name}.headline`}
          disabled={disabled}
          component={LabeledTextArea}
          onChange={onChange}
        />
      </td>
      <td>
        <Field
          name={`${name}.body`}
          disabled={disabled}
          component={LabeledTextArea}
          onChange={onChange}
        />
      </td>
    </tr>
  ),
);

function TaskListBody({ fields, anyTouched, ...props }) {
  return (
    <tbody>
      {fields.map((name) => (
        <TaskEditRow {...props} key={name} name={name} />
      ))}

      {props.meta.error && anyTouched ? (
        <tr className="text-danger">
          <td>{props.meta.error}</td>
        </tr>
      ) : null}
    </tbody>
  );
}

TaskListBody.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  anyTouched: PropTypes.bool.isRequired,
};

const withSubmitCallback = compose(
  setPropTypes({
    application: PropTypes.shape({
      id: PropTypes.string.isRequired,
      tasks: PropTypes.array.isRequired,
    }).isRequired,
    onUpdateApplication: PropTypes.func.isRequired,
  }),
  withProps(({ onUpdateApplication, application }) => ({
    onSubmit: ({ individualTasks }) =>
      onUpdateApplication(application.id, {
        tasks: mergeTasks(application.tasks, individualTasks),
      }).then((result) => {
        if (result.statusCode >= 400) {
          throw new SubmissionError({
            individualTasks: {
              _error: `Error updating tasks: ${result.data}`,
            },
          });
        }
      }),
  })),
);

function IndividualTasksForm({
  form,
  disabled,
  anyTouched,
  handleSubmit,
  onSubmit,
  submitting,
  submitSucceeded,
  change,
}) {
  return (
    <form id={form} onSubmit={handleSubmit(onSubmit)}>
      <table className="table table-sm individual-tasks-table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Type</th>
            <th>Headline</th>
            <th>Body</th>
          </tr>
        </thead>
        <FieldArray
          name="individualTasks"
          disabled={disabled}
          change={change}
          anyTouched={anyTouched}
          component={TaskListBody}
        />
      </table>
      {!disabled ? (
        <div className="row justify-content-end pr-3">
          <button
            type="submit"
            disabled={submitting}
            className={cx('btn', 'btn-primary', {
              'btn-success': submitSucceeded && !submitting,
            })}
          >
            {submitting ? 'Saving' : 'Save'}
          </button>
        </div>
      ) : null}
    </form>
  );
}

IndividualTasksForm.propTypes = {
  form: PropTypes.string.isRequired,
  anyTouched: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
};

export default compose(
  setPropTypes({
    application: PropTypes.shape({
      tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  }),
  connect((state, { application: { tasks } }) => ({
    initialValues: {
      individualTasks: tasks
        .map((task, i) => ({
          ...task,
          // For individual tasks, explicitly set an "updated" property
          // to indicate whether they have been customized. This will be
          // propagated to the server as well on PATCH
          updated: task.updated === undefined ? false : task.updated,
          // Record the original index in the tasks list for when we merge
          // the customized tasks back into the original list. This will be
          // removed before PATCH
          _index: i,
        }))
        .filter((task) => task.individual),
    },
  })),
  reduxForm({
    form: 'applicationReview/applicationPanel/individualTasks',
    touchOnBlur: false,
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate: (values) => {
      const error = {};
      // This fixes redux-form and/or react-redux bug
      // when initialValues are not propagated to validate function
      // https://github.com/redux-form/redux-form/issues/4344
      if (isEmpty(values)) {
        return error;
      }
      error.individualTasks = values.individualTasks.map((task) => {
        const error = {};

        if (!task.headline) {
          error.headline = 'Headline is required.';
        }
        if (!task.body && !['link', 'text'].includes(task.type)) {
          error.body = 'Body text is required.';
        }
        if (
          (task.type === 'image' || (task.type === 'link' && task.body)) &&
          !isURL(task.body)
        ) {
          error.body = 'Body must be a valid URL.';
        }

        return error;
      });

      return error;
    },
  }),
  withSubmitCallback,
)(IndividualTasksForm);
