import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers, withProps } from 'recompose';
import { assertSuccessStatusCode } from 'source/utils/reduxAsyncUtils';
import { getCampaignEditPath } from 'source/scenes/campaigns/components/utils';
import propsSelector from './propsSelectors';

const onCloseCampaignSetup =
  ({ push }) =>
  () =>
    push('/campaigns');
const onRedirectCampaignSetup =
  ({ push }) =>
  ({ data: campaign }) =>
    push(getCampaignEditPath(campaign));

export const withOnCloseCampaign = compose(
  setPropTypes({
    push: PropTypes.func.isRequired,
  }),
  withHandlers({
    onCloseCampaign: onCloseCampaignSetup,
    onRedirectCampaign: onRedirectCampaignSetup,
  }),
);

const progressAction =
  (action, options = { redirect: false }) =>
  ({ campaign, onCloseCampaign, onRedirectCampaign, onError }) =>
  (payload) => {
    if (!campaign || !campaign.id) {
      return null;
    }

    const onAfterAction = options.redirect
      ? onRedirectCampaign
      : onCloseCampaign;

    return Promise.resolve()
      .then(() => action(campaign.id, payload))
      .then(assertSuccessStatusCode)
      .then(onAfterAction)
      .catch(onError);
  };

export const withProgressActions = compose(
  setPropTypes({
    campaign: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    onPublishCampaign: PropTypes.func.isRequired,
    onDeleteCampaign: PropTypes.func.isRequired,
    onArchiveCampaign: PropTypes.func.isRequired,
    onDoneCampaign: PropTypes.func.isRequired,
    onCloseCampaign: PropTypes.func.isRequired,
    onDuplicateCampaign: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  }),
  withHandlers((props) => ({
    onPublishCampaign: progressAction(props.onPublishCampaign),
    onDeleteCampaign: progressAction(props.onDeleteCampaign),
    onArchiveCampaign: progressAction(props.onArchiveCampaign),
    onDoneCampaign: progressAction(props.onDoneCampaign),
    onDuplicateCampaign: progressAction(props.onDuplicateCampaign, {
      redirect: true,
    }),
  })),
);

export const withPropsSelectorApplied = withProps(propsSelector);
