import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';

import { assertSuccessStatusCode } from 'source/utils/reduxAsyncUtils';

import TodoModel, { TodoShape } from '../../../todoModel';

/**
 * common todo action handler, which mainly patches the application
 */
const updateApplicationFromTodoAction =
  ({ todo, onUpdateApplication, onToggleTodo, onLoadCampaign }) =>
  (payload) => {
    const todoId = TodoModel.id(todo);
    const applicationId = TodoModel.applicationId(todo);
    const campaignId = TodoModel.campaignId(todo);

    // patch application with the given payload
    const updateApplication = () => onUpdateApplication(applicationId, payload);

    // de-collapse the panel after action finished
    const decollapse = () => onToggleTodo(todoId, false);

    // reload todos
    const reloadTodos = () => onLoadCampaign(campaignId, { fields: ['todos'] });

    return Promise.resolve()
      .then(updateApplication)
      .then(assertSuccessStatusCode)
      .then(decollapse)
      .then(reloadTodos)
      .then(assertSuccessStatusCode);
  };

export const withActionSubmit = compose(
  setPropTypes({
    todo: TodoShape.isRequired,
    onUpdateApplication: PropTypes.func.isRequired,
    onToggleTodo: PropTypes.func.isRequired,
    onLoadCampaign: PropTypes.func.isRequired,
  }),
  withHandlers({
    onSubmit: updateApplicationFromTodoAction,
  }),
);
