import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  withProps,
  setPropTypes,
  withHandlers,
} from 'recompose';
import flatMap from 'lodash/flatMap';
import compact from 'lodash/compact';

export const withNewCampaignIndicator = withProps(({ campaign }) => ({
  newCampaign: !campaign || !campaign.id,
}));

export const withCategoriesData = withProps(({ iabCategories }) => ({
  iabCategories: iabCategories.state === 'loaded' ? iabCategories.data : [],
}));

export const withOnMount = compose(
  setPropTypes({
    newCampaign: PropTypes.bool.isRequired,
    onSelectPanel: PropTypes.func.isRequired,
    onLoadIABCategories: PropTypes.func.isRequired,
  }),
  lifecycle({
    componentDidMount() {
      const {
        newCampaign,
        onSelectPanel,
        onLoadIABCategories,
        onLoadClients,
        onLoadPrivateNetworks,
      } = this.props;

      const panel = newCampaign ? 'init' : 'settings';

      onSelectPanel(panel);
      onLoadIABCategories();
      onLoadClients();
      onLoadPrivateNetworks();
    },
  }),
);

export const withOnNextSection = compose(
  setPropTypes({
    sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onSelectPanel: PropTypes.func.isRequired,
  }),
  withHandlers({
    onNextSection: ({ sections, onSelectPanel }) => {
      const formOrder = flatMap(sections, (section) =>
        compact(section.forms).map((form) =>
          typeof form === 'string' ? form : form.id,
        ),
      );

      return (form) => onSelectPanel(formOrder[formOrder.indexOf(form) + 1]);
    },
  }),
);
