import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { pure } from 'recompose';
import cx from 'classnames';

import { Feature } from 'source/components/common';
import Collapsible from 'source/components/common/collapsible';

function Navigation({ user }) {
  return (
    <Collapsible>
      {({ onToggle, onKeyDown, show }) => (
        <nav
          className="navbar navbar-expand-sm navbar-dark fixed-top bg-primary"
          onKeyDown={onKeyDown}
        >
          <div className="container">
            <NavLink to="/" className="navbar-brand">
              <img
                className="eqolot-logo-navbar"
                src="/images/eqolot-logo-navbar.png"
                alt=""
              />
            </NavLink>

            <button
              onClick={onToggle}
              className="navbar-toggler ml-auto"
              type="button"
              data-toggle="collapse"
              aria-expanded={show}
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div className={cx('collapse', 'navbar-collapse', { show })}>
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <NavLink
                    to="/users"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Influencers
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/channels"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Channels
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/campaigns"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Campaigns
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/links"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Links
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/groups"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Groups
                  </NavLink>
                </li>

                <Feature feature="invoices">
                  <li className="nav-item">
                    <NavLink
                      to="/invoices"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Invoices
                    </NavLink>
                  </li>
                </Feature>

                <Feature feature="payments">
                  <li className="nav-item">
                    <NavLink
                      to="/payments"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Payments
                    </NavLink>
                  </li>
                </Feature>
              </ul>

              <div className="form-inline">
                <img src={user.picture} alt="avatar" className="avatar" />
              </div>
            </div>
          </div>
        </nav>
      )}
    </Collapsible>
  );
}

Navigation.propTypes = {
  user: PropTypes.shape({ picture: PropTypes.string.isRequired }).isRequired,
};

export default pure(Navigation);
