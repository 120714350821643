import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Toggle } from 'source/scenes/components/reduxFormAdapters';
import Icon from 'source/components/common/icon';

const statusToTitle = {
  accepted: 'Client Accepted',
  rejected: 'Client Rejected',
  undecided: 'Client Undecided',
};

function ClientContentReviewForm({
  title,
  description,
  clientContentReview,
  initialValues: { allowClient },
  disabled,
}) {
  const status = statusToTitle[clientContentReview.status];
  const reviewedBy = `by ${clientContentReview.updatedBy}`;
  const reviewedOn = `Reviewed on ${moment(
    clientContentReview.updatedAt,
  ).format('DD.MM.YYYY, HH:mm')}`;

  return (
    <div className="form-group write-review">
      <h3 className="mt-5">{title}</h3>
      <p className="text-muted">{description}</p>
      <div className="row">
        <div className="col-5 d-flex">
          <Field
            disabled={disabled}
            className="align-self-start"
            name="allowClient"
            component={(props) => (
              <>
                <Toggle {...props} />
                <span className="ml-2">Open for Client Review</span>
              </>
            )}
          />
        </div>
        {allowClient && clientContentReview.status === 'pending' && (
          <div className="col-7 client-content-preview">
            <h2 className="title">Waiting for client review</h2>
          </div>
        )}
        {clientContentReview.updatedAt && (
          <div className="col-7 client-content-preview">
            <h5 className="subtitle mb-0">{reviewedOn}</h5>
            <h5 className="subtitle">{reviewedBy}</h5>
            <div className="d-flex">
              {clientContentReview.status === 'accepted' && (
                <Icon
                  className="small-icon mr-2"
                  name="thumbsup"
                  stroke="#75e3ac"
                />
              )}
              {clientContentReview.status === 'rejected' && (
                <Icon
                  className="small-icon mr-2"
                  name="thumbsdown"
                  stroke="#f45c25"
                />
              )}
              {clientContentReview.status === 'undecided' && (
                <Icon
                  className="small-icon mr-2"
                  name="minus"
                  stroke="#00ace7"
                />
              )}
              <h2 className={`title title-${clientContentReview.status}`}>
                {status}
              </h2>
            </div>
            {clientContentReview.note && (
              <div className="note">
                <Icon name="note" className="small-icon mr-2" />
                {clientContentReview.note}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

ClientContentReviewForm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  clientContentReview: PropTypes.shape({
    status: PropTypes.oneOf(['pending', 'accepted', 'rejected', 'undecided']),
    note: PropTypes.string,
    updatedAt: PropTypes.string,
    updatedBy: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  initialValues: PropTypes.shape({
    allowClient: PropTypes.bool.isRequired,
  }),
};

ClientContentReviewForm.defaultProps = {
  clientContentReview: {},
  disabled: false,
};

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ClientContentReviewForm);
