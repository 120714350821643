import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import {
  lifecycle,
  branch,
  renderComponent,
  compose,
  setPropTypes,
} from 'recompose';
import moment from 'moment';

import ChannelAvatar from 'source/components/common/channelAvatar';
import ProgressBar from 'source/components/common/progressBar';
import InternalNote from 'source/scenes/campaignDetail/applications/components/common/internalNote';

// Helpers

function Error() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      Error loading insights.
    </div>
  );
}

function Loading() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ProgressBar duration={2000} />
    </div>
  );
}

// HoCs

const withApplicationInsightsFetch = compose(
  setPropTypes({
    campaign: PropTypes.object.isRequired,
    onLoadInsights: PropTypes.func.isRequired,
  }),
  lifecycle({
    componentDidMount() {
      const { campaign, insights, application, onLoadInsights } = this.props;

      if (!insights.loaded) {
        onLoadInsights(application.id, campaign);
      }
    },
  }),
);

const withErrorGuard = branch(
  ({ insights }) => insights.error,
  renderComponent(Error),
);

const withLoadingGuard = branch(
  ({ insights }) => !insights.loaded,
  renderComponent(Loading),
);

// Components

function ApplicationInsights({
  insights: {
    data: { date, subscriberCount, viewCount, videoCount, commentCount },
  },
  application: {
    channel: { notes, avatarUrl },
  },
}) {
  return (
    <>
      <InternalNote notes={notes} />

      <div className="row">
        <div className="col-md-8">
          <ChannelAvatar
            url={avatarUrl}
            className="application-channel-avatar-youtube"
          />
          <table className="table table-sm">
            <tbody>
              <tr>
                <th>Date</th>
                <td>{moment(date).format('YYYY-MM-DD')}</td>
              </tr>
              <tr>
                <th>Subscribers</th>
                <td>
                  <FormattedNumber value={subscriberCount} />
                </td>
              </tr>
              <tr>
                <th>View Count</th>
                <td>
                  <FormattedNumber value={viewCount} />
                </td>
              </tr>
              <tr>
                <th>Video Count</th>
                <td>
                  <FormattedNumber value={videoCount} />
                </td>
              </tr>
              <tr>
                <th>Comment Count</th>
                <td>
                  <FormattedNumber value={commentCount} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

ApplicationInsights.propTypes = {
  insights: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.object,
    data: PropTypes.shape({
      date: PropTypes.string.isRequired,
      subscriberCount: PropTypes.number.isRequired,
      viewCount: PropTypes.number.isRequired,
      videoCount: PropTypes.number.isRequired,
      commentCount: PropTypes.number.isRequired,
    }),
  }),
  application: PropTypes.shape({
    channel: PropTypes.shape({
      notes: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
  }),
};

ApplicationInsights.defaultProps = {
  insights: {},
};

export default compose(
  withApplicationInsightsFetch,
  withErrorGuard,
  withLoadingGuard,
)(ApplicationInsights);
