import PropTypes from 'prop-types';
import React from 'react';

import cx from 'classnames';
import { isPending } from 'source/utils/status';

function ChannelStatus({ className, ready, readyState = {} }) {
  const states = {
    READY: {
      message: 'ready',
      color: 'green',
    },
    NOT_READY: {
      message: 'not ready',
      color: 'red',
    },
    PENDING: {
      message: 'pending',
      color: 'orange',
    },
  };

  let state;

  if (ready) {
    state = states.READY;
  } else if (isPending(readyState)) {
    state = states.PENDING;
  } else {
    state = states.NOT_READY;
  }

  return (
    <div className={cx(className, 'status')}>
      <div className={cx('status-indicator', state.color)} />
      <div className="status-text ml-2">{state.message}</div>
    </div>
  );
}

ChannelStatus.propTypes = {
  className: PropTypes.string,
  ready: PropTypes.bool.isRequired,
  readyState: PropTypes.shape({
    hasCachedData: PropTypes.bool,
  }),
};

export default ChannelStatus;
