import { withProps, compose } from 'recompose';
import pick from 'lodash/pick';

import { ApplicationDetailList } from '../common/detailPanelList';
import ApplicationDetailBody, {
  withApplicationDetailContainer,
} from '../common/applicationDetail';

import SendToMission from '../common/sendToMission';
import Profile from '../common/profile';
import YouTubeInsights from './insights';

// Helpers

const isSelectedOrApproved = (status) =>
  status === 'selected' || status === 'approved';

// Config

const getPanelMetrics = ({ application }) => {
  const {
    createdAt,
    channel,
    channels,
    clientApplicationReview,
    user: { firstname },
    match: {
      reach,
      payment: {
        counterOffer,
        price: influencerBasePrice,
        totals: {
          price: influencerTotalPrice,
          advertiserPrice: advertiserTotalPrice,
          // in case of counter offer application, this holds
          // originally proposed influencer price..
          priceOriginal: influencerOriginalPrice,
        },
        currency,
      },
    },
  } = application;

  return [
    {
      label: 'First Name',
      value: firstname,
      type: 'metric',
      appearance: {
        bold: true,
      },
      formatting: {
        style: 'text',
      },
    },
    {
      label: 'Channel',
      value: channel,
      type: 'channel',
    },
    {
      label: 'Channels',
      value: channels,
      type: 'channels',
    },
    {
      label: 'Applied',
      value: createdAt,
      type: 'metric',
      appearance: {
        alignment: 'right',
      },
      formatting: {
        style: 'date',
      },
    },
    {
      label: 'Client Review',
      value: clientApplicationReview,
      type: 'review',
    },
    {
      label: 'Infl. Base Price',
      value: influencerBasePrice,
      type: 'metric',
      appearance: {
        alignment: 'right',
      },
      formatting: {
        style: 'currency',
        currency,
      },
    },
    {
      label: 'Infl. Total Price',
      value: counterOffer
        ? { influencerTotalPrice, influencerOriginalPrice }
        : influencerTotalPrice,
      type: counterOffer ? 'counterOffer' : 'metric',
      appearance: { alignment: 'right' },
      formatting: { style: 'currency', currency },
    },
    {
      label: 'Advertiser Price',
      value: advertiserTotalPrice,
      type: 'metric',
      appearance: { alignment: 'right' },
      formatting: { style: 'currency', currency },
    },
    {
      label: 'Subscribers',
      value: reach,
      type: 'metric',
      appearance: {
        alignment: 'right',
      },
    },
  ];
};

const getTabs = () => [
  {
    id: 'pitch',
    label: 'Pitch and Profile',
    hideTitle: true,
    component: Profile,
    withProps: (props) => {
      const {
        application: {
          id,
          user,
          groups,
          privateNetworks,
          pitch,
          pitchEdits,
          productShipment: applicationProductShipment = {},
          kamNotes,
        },
        onUpdateApplication,
        campaign: { productShipment: campaignProductShipment = {} },
      } = props;
      return {
        pitch,
        pitchEdits,
        applicationId: id,
        productShipment: {
          ...applicationProductShipment,
          ...campaignProductShipment,
        },
        onUpdateApplication,
        kamNotes,
        user: { ...user, groups, privateNetworks },
      };
    },
  },
  {
    id: 'insights',
    label: 'Insights',
    withChannelLink: true,
    component: YouTubeInsights,
    withProps: (props) =>
      pick(props, ['campaign', 'application', 'insights', 'onLoadInsights']),
  },
  {
    id: 'send-to-mission',
    label: 'Individual Tasks and Publishing Dates',
    component: SendToMission,
    withProps: (props) => ({
      ...pick(props, [
        'application',
        'publishingDates',
        'onLoadPublishingDates',
        'onUpdateApplication',
        'onLoadApplication',
      ]),
      onSendToMission: props.onClose,
    }),
  },
];

// Components

const YouTubeBody = compose(
  withApplicationDetailContainer(),
  withProps(({ application: { status } }) => ({
    getTabs,
    initialTab: isSelectedOrApproved(status) ? 'send-to-mission' : undefined,
  })),
)(ApplicationDetailBody);

export default compose(
  withProps({
    getPanelMetrics,
    panelBody: YouTubeBody,
  }),
)(ApplicationDetailList);
