import de from './de';
import en from './en';

const messagesByLocale = {
  de,
  en,
};
const getMessage = (locale, messageId) => messagesByLocale[locale][messageId];

export default getMessage;
