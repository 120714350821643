import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'recompose';
import cx from 'classnames';
import get from 'lodash/get';

import Alert from 'source/components/common/alert';
import Input from 'source/scenes/components/reduxFormAdapters/input';
import {
  parseToFloat,
  floatStrToPercent,
  percentToFloatStr,
} from 'source/utils/reduxForm';

import validate from './validation';
import selector from './selectors';
import { withSubmissionHandler } from './enhancers';
import { getCampaignCurrency } from '../utils';
import { withCollapsiblePanel } from '../../enhancers';

const enhance = compose(
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
  reduxForm({
    validate,
    touchOnBlur: false,
    touchOnChange: true,
    enableReinitialize: true,
  }),
);

function BonusesForm({ handleSubmit, invalid, submitting, error, campaign }) {
  return (
    <form onSubmit={handleSubmit}>
      <Alert message={get(error, 'message')} />

      <Field
        name="fixed"
        component={Input}
        label={`Fixed Bonus${getCampaignCurrency(campaign, {
          prefix: ' in ',
        })}*:`}
        helpText="Fixed bonus gets paid by the advertiser additionally to the normal campaign costs. eg. influencer needs to buy a product"
        type="number"
        parse={parseToFloat}
      />

      <Field
        name="variablePercentage"
        component={Input}
        label="Variable Bonus in Percent*:"
        helpText="Max. 30%. Variable bonus gets paid by blogfoster, it decreases our margin. Should be used for engaging influencers to apply during the runtime of a campaign."
        type="number"
        step="0.1"
        min={0}
        parse={floatStrToPercent}
        format={percentToFloatStr}
      />
      <div className="text-muted" />

      <div className="row justify-content-end pr-3">
        <button
          type="submit"
          className={cx('btn', {
            'btn-primary': !error,
            'btn-danger': !!error,
          })}
          disabled={invalid || submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
}

BonusesForm.propTypes = {
  campaign: PropTypes.object,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  error: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

BonusesForm.defaultProps = {
  campaign: undefined,
  submitting: false,
  invalid: false,
  error: undefined,
};

export default enhance(BonusesForm);
