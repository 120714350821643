import { loadStateReducer } from '@blogfoster/redux-async-utils';
import { combineReducers } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { selectFromStatePath, withStatePath } from 'source/utils/selectors';
import { namespacedReducer, keyValueStoreReducer } from 'source/utils/redux';
import {
  campaignFetch,
  actionTypes as campaignActionTypes,
} from 'source/data/campaigns/actions';
import {
  applicationUpdate,
  actionTypes as applicationActionTypes,
} from 'source/data/applications/actions';
import { getCampaign } from 'source/data/selectors';
import { redux } from 'source/components/common/collapsiblePanel';

import { TodosView } from './components';

/* == actions == */
const namespace = 'scenes/campaignDetail/todos';
const actions = {
  onLoadCampaign: campaignFetch(namespace),
  onToggleTodo: redux.toggleActionCreator(namespace),
  onUpdateApplication: applicationUpdate(namespace),
};

/* == reducer == */
export const reducer = namespacedReducer(namespace)(
  combineReducers({
    campaignAsync: loadStateReducer(campaignActionTypes.FETCH, {}),
    expanded: redux.reducers.toggle(namespace),
    todosActionAsync: keyValueStoreReducer(
      loadStateReducer(applicationActionTypes.UPDATE, {}),
      { key: 'payload.id' },
    ),
  }),
);

/* == selector == */
const selector = createStructuredSelector({
  campaign: getCampaign,
  campaignAsync: selectFromStatePath('campaignAsync'),
  expanded: selectFromStatePath('expanded', {}, redux.selectors.getExpanded),
  todosActionAsync: selectFromStatePath('todosActionAsync'),
});

/* == container == */
const statePath = 'scenes.campaignDetail.todos';
const enhance = compose(connect(withStatePath(statePath)(selector), actions));

export default enhance(TodosView);
