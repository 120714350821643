import React from 'react';
import PropTypes from 'prop-types';

/**
 * A preconfigured anchor tag that always opens in a new tab/page and forces
 * the browser to request the target URL without a Referrer header. You can use
 * this component exactly like you would use the HTML element `<a>` but be aware
 * that you can not customize the `target` and `rel` attributes.
 */
function ExternalLink({ children, ...anchorProps }) {
  return (
    <a {...anchorProps} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

ExternalLink.propTypes = {
  children: PropTypes.node,
};

ExternalLink.defaultProps = {
  children: null,
};

export default ExternalLink;
