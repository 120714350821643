import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Badge from 'source/components/common/badge';

import MissionStatusBadge from './missionStatusBadge';

function Headline({
  application: {
    mission: { previewDate, publishDate },
  },
  application,
  heading,
  subHeading,
}) {
  return (
    <div className="application-headline">
      <MissionStatusBadge application={application} />
      <span className="ml-3">
        <span>{heading}</span>
        {subHeading && (
          <span className="ml-3">
            <small>{subHeading}</small>
          </span>
        )}
        {previewDate && publishDate && (
          <span>
            &nbsp;
            <Badge type="warning" className="font-weight-normal">
              Preview: {moment.utc(previewDate).format('ddd DD/MM/YYYY')}
            </Badge>
            &nbsp;
            <Badge type="success" className="font-weight-normal">
              Publish: {moment.utc(publishDate).format('ddd DD/MM/YYYY')}
            </Badge>
          </span>
        )}
      </span>
    </div>
  );
}

Headline.propTypes = {
  application: PropTypes.shape({
    mission: PropTypes.shape({
      previewDate: PropTypes.string,
      publishDate: PropTypes.string,
    }).isRequired,
  }).isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.node,
};

Headline.defaultProps = {
  subHeading: undefined,
};

export default Headline;
