import shared from './shared';

const config = {
  api: {
    url: process.env.AURUM_URL,
    timeout: 20000,
  },

  app: {
    url: process.env.SELF_APP_URL,
  },

  userApp: {
    url: process.env.CREATOR_APP_URL,
  },

  sentry: {
    disabled: true,
  },

  allowedSSODomains: ['blogfoster.com', 'eqolot.com'],
  primaryDomain: 'eqolot.com',

  managers: shared.managers,
  allUsers: shared.allUsers,
  paymentApprovers: [
    ...shared.accessGroups.paymentApprovers,
    ...shared.accessGroups.developers,
  ],

  features: {
    loginAsInfluencer: {},
    showCreateGroup: {},
    pricingMultiplier: {},
    invoices: {},
    payments: {},
    campaignBonuses: {},
    allowCrediting: {},
    campaignSelectPrivateNetwork: {},
  },

  insights: {
    instagram: {
      insightsTtl: 30,
      insightsIntermediateTtl: 23,
    },
    tiktok: {
      insightsTtl: 30,
      insightsIntermediateTtl: 23,
    },
  },
};

export default config;
