import merge from 'lodash/merge';
import { actionAuthRequest } from 'source/utils/axios';

/* == api helper == */

const api = {
  /**
   * upload files
   *
   * @param {Array<Object<{ key: String, file: File, [filename: String] }>>} options.files - file definitions
   *
   * POST /v1/cave
   */
  postCave:
    ({ files }, options = {}) =>
    (dispatch, getState) => {
      if (!files || !files.length) {
        return Promise.resolve([]);
      }

      const payload = files.reduce((formData, { key, file, filename }) => {
        formData.append(key, file, filename);

        return formData;
      }, new FormData());

      // NOTE  files uploads might take a little longer
      const requestOpts = merge(
        {},
        {
          options: {
            timeout: 5 * 60000,
          },
        },
        options,
      );

      return actionAuthRequest(dispatch, getState, requestOpts)
        .post('/v1/cave', payload)
        .then((resp) => resp.data);
    },
};

export default api;
