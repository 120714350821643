import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withProps, compose } from 'recompose';

import {
  loadApplicationInsights,
  loadApplicationPublishingDates,
  updateToggleState,
} from './actions';

import {
  updateApplication,
  loadApplication,
} from '../applicationsOverview/actions';

import {
  getPanelInsightsAsync,
  getPanelPublishingDatesAsync,
} from '../../../selectors';

const withApplicationId = withProps(({ application }) => ({
  applicationId: application.id,
}));

export default ({ selectors = {}, actions = {} } = {}) =>
  compose(
    withApplicationId,
    connect(
      createStructuredSelector({
        insights: getPanelInsightsAsync,
        publishingDates: getPanelPublishingDatesAsync,
        ...selectors,
      }),
      {
        onLoadInsights: loadApplicationInsights,
        onLoadPublishingDates: loadApplicationPublishingDates,
        onUpdateApplication: updateApplication,
        onLoadApplication: loadApplication,
        onToggleChange: updateToggleState,
        ...actions,
      },
    ),
  );
