import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

import Pagination from 'source/components/common/pagination';

import WebsiteCampaignV1TableRow from './websiteCampaignV1TableRow';
import DefaultTableRow from './campaignV2TableRow';

const campaignRowFactory = (campaign) => {
  const { type, pricing } = campaign;

  let CampaignRowComponent = DefaultTableRow;

  if (type === 'website' && pricing === 'bucket') {
    CampaignRowComponent = WebsiteCampaignV1TableRow;
  }

  return <CampaignRowComponent key={campaign.id} campaign={campaign} />;
};

function CampaignsTable({ campaigns, pages, page, onSelectPage }) {
  return (
    <div>
      <div className="table-responsive-lg">
        <table className="table table-striped table-campaigns">
          <thead>
            <tr>
              <th className="col-todos" />
              <th className="col-status">Status</th>
              <th className="col-name">Name</th>
              <th className="col-counter col-live">Live</th>
              <th className="col-counter col-mission">Mission</th>
              <th className="col-counter col-missing">Missing</th>
              <th className="col-counter col-review">In Review</th>
              <th className="col-counter col-target">Target</th>
              <th className="col-details" />
            </tr>
          </thead>
          <tbody>{campaigns.map(campaignRowFactory)}</tbody>
        </table>
      </div>
      <div className="row justify-content-center pt-3">
        <Pagination pages={pages} activePage={page} onSelect={onSelectPage} />
      </div>
    </div>
  );
}

CampaignsTable.propTypes = {
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      pricing: PropTypes.string.isRequired,
    }),
  ),
  pages: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  onSelectPage: PropTypes.any.isRequired,
};

CampaignsTable.defaultProps = {
  campaigns: [],
};

export default pure(CampaignsTable);
