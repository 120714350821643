import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import withResetScene from 'source/components/commonEnhancers/withResetScene';
import { ProgressBar, Alert } from 'source/components/common';
import { allUsers } from 'source/utils/acl';

import actions from '../actions';
import { getPaymentsState } from '../selectors';
import PaymentsTable from './table';

const enhance = compose(connect(getPaymentsState, actions), withResetScene);

class Payments extends React.PureComponent {
  componentDidMount() {
    const { onMount } = this.props;

    return onMount();
  }

  componentWillUnmount() {
    this.props.onAlertClose();
  }

  handleSearchUserIdChange = (e) =>
    this.props.onFilterChange('userId', e.target.value);

  handleSearchApprovedByChange = (e) =>
    this.props.onFilterChange('approvedBy', e.target.value);

  handleSubmitSearch = (e) => {
    e.preventDefault();
    this.props.onMount();
  };

  handleFilterReset = () => {
    this.props.onFilterReset();
    this.props.onMount();
  };

  renderFilterOperations() {
    const {
      filters: { approvedBy },
    } = this.props;

    return (
      <select
        id="manager"
        className="form-control custom-select"
        value={approvedBy}
        onChange={this.handleSearchApprovedByChange}
      >
        <option value="">All senders</option>
        {allUsers.map((user) => (
          <option key={user.username} value={user.username}>
            {user.name}
          </option>
        ))}
      </select>
    );
  }

  renderPaymentsTable() {
    const { payments, page, pages, counter, onSelectPage } = this.props;

    return (
      <div className="col">
        <h3 className="pb-3">
          Payments
          <span>
            ({counter.count}/{counter.total})
          </span>
        </h3>
        <PaymentsTable
          payments={payments}
          page={page}
          pages={pages}
          onSelectPage={onSelectPage}
        />
      </div>
    );
  }

  render() {
    const {
      state,
      alert,
      onAlertClose,
      filters: { userId },
    } = this.props;

    const stateLoaded = state === 'loaded';

    return (
      <div className="container">
        <Alert {...alert} onClose={onAlertClose} />

        <div className="row">
          <div className="col">
            <h2>Manual Payments</h2>
            <p className="text-muted">Create and search manual payments.</p>
          </div>
        </div>

        <div className="row justify-content-end pr-3">
          <Link to="/payments/new" className="btn btn-outline-primary ">
            New Payment
          </Link>
        </div>

        <hr />

        <h3>Search for payments</h3>
        <form className="col" onSubmit={this.handleSubmitSearch}>
          <div className="row pt-3">
            <p className="text-muted">User Id:</p>
            <input
              className="form-control"
              type="number"
              placeholder="User Id"
              value={userId}
              onChange={this.handleSearchUserIdChange}
            />
          </div>
          <div className="row pt-3">
            <p className="text-muted">Approved by:</p>
            {this.renderFilterOperations({ className: 'pl-3' })}
          </div>
          <div className="row justify-content-end pt-3">
            <button className="btn btn-primary" type="submit">
              Search
            </button>
            <button
              className="btn btn-secondary ml-1"
              type="button"
              onClick={this.handleFilterReset}
            >
              Reset
            </button>
          </div>
        </form>

        <div className="row pt-5">
          {stateLoaded ? this.renderPaymentsTable() : <ProgressBar.Mega />}
        </div>
      </div>
    );
  }
}

Payments.propTypes = {
  alert: PropTypes.object,
  state: PropTypes.string.isRequired,
  payments: PropTypes.array.isRequired,
  page: PropTypes.any.isRequired,
  pages: PropTypes.any.isRequired,
  filters: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    approvedBy: PropTypes.string.isRequired,
  }).isRequired,
  counter: PropTypes.shape({
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  onMount: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
  onAlertClose: PropTypes.func.isRequired,
};

export default enhance(Payments);
