import AttachmentsForm from './attachments';
import BriefingForm from './briefing';
import BudgetForm from './budget';
import BonusesForm from './bonuses';
import CategoriesForm from './categories';
import ClientsAndReach from './clientsAndReach';
import ContractForm from './contract';
import CountriesForm from './countries';
import InitForm from './init';
import SettingsForm from './settings';
import SocialMediaSharing from './socialMediaSharing';
import TasksForm from './tasks';
import TimelineForm from './timeline';
import ProductShipmentForm from './productShipment';

const Forms = {
  init: {
    id: 'init',
    component: InitForm,
    withProps: (props) => ({
      form: 'campaignDetail/setup/init',
      disableLanguageSelect: !props.newCampaign,
    }),
  },
  contract: {
    id: 'contract',
    component: ContractForm,
    withProps: (props) => ({
      form: 'campaignDetail/setup/contract',
      disabled: props.newCampaign,
    }),
  },
  countries: {
    id: 'countries',
    component: CountriesForm,
    withProps: (props) => ({
      form: 'campaignDetail/setup/countries',
      disabled: props.newCampaign,
    }),
  },
  clientsAndReach: {
    id: 'clientsAndReach',
    component: ClientsAndReach,
    withProps: (props) => ({
      form: 'campaignDetail/setup/clientsAndReach',
      disabled: props.newCampaign,
    }),
  },
  settings: {
    id: 'settings',
    component: SettingsForm,
    withProps: (props) => ({
      form: 'campaignDetail/setup/settings',
      disabled: props.newCampaign,
    }),
  },
  categories: {
    id: 'categories',
    component: CategoriesForm,
    withProps: (props) => ({
      form: 'campaignDetail/setup/categories',
      disabled: props.newCampaign,
    }),
  },
  briefing: {
    id: 'briefing',
    component: BriefingForm,
    withProps: (props) => ({
      form: 'campaignDetail/setup/briefing',
      disabled: props.newCampaign,
    }),
  },
  budget: {
    id: 'budget',
    component: BudgetForm,
    withProps: (props) => ({
      form: 'campaignDetail/setup/budget',
      disabled: props.newCampaign,
    }),
  },
  bonuses: {
    id: 'bonuses',
    component: BonusesForm,
    withProps: (props) => ({
      form: 'campaignDetail/setup/bonuses',
      disabled: props.newCampaign,
    }),
  },
  socialMediaSharing: {
    id: 'socialMediaSharing',
    component: SocialMediaSharing,
    withProps: (props) => ({
      form: 'campaignDetail/setup/socialMediaSharing',
      disabled: props.newCampaign,
    }),
  },
  timeline: {
    id: 'timeline',
    component: TimelineForm,
    withProps: (props) => ({
      form: 'campaignDetail/setup/timeline',
      disabled: props.newCampaign,
    }),
  },
  productShipment: {
    id: 'productShipment',
    component: ProductShipmentForm,
    withProps: (props) => ({
      form: 'campaignDetail/setup/productShipment',
      disabled: props.newCampaign,
    }),
  },
  preTasks: {
    id: 'preTasks',
    component: TasksForm,
    withProps: (props) => ({
      list: 'preTasks',
      form: 'campaignDetail/setup/preTasks',
      disabled: props.newCampaign,
    }),
  },
  tasks: {
    id: 'tasks',
    component: TasksForm,
    withProps: (props) => ({
      list: 'tasks',
      form: 'campaignDetail/setup/tasks',
      disabled: props.newCampaign,
    }),
  },
  postTasks: {
    id: 'postTasks',
    component: TasksForm,
    withProps: (props) => ({
      list: 'postTasks',
      form: 'campaignDetail/setup/postTasks',
      disabled: props.newCampaign,
    }),
  },
  attachments: {
    id: 'attachments',
    component: AttachmentsForm,
    withProps: (props) => ({
      form: 'campaignDetail/setup/attachments',
      disabled: props.newCampaign,
    }),
  },
};

export default Forms;
