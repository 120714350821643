import React from 'react';
import PropTypes from 'prop-types';
import { Video, Image, Transformation } from 'cloudinary-react';

import ExternalLink from 'source/scenes/components/externalLink';

function EventMediaBody({ content: { media }, mediaRefs }) {
  return (
    <div className="action-media-content">
      {media.map(({ type, url, filename, assetId }, index) => {
        let mediaItem;

        if (type === 'video') {
          if (assetId) {
            mediaItem = (
              <Video
                controls
                secure
                cloudName="eqolot"
                publicId={assetId}
                format="mp4"
                controlsList="nodownload"
              >
                <Transformation fetchFormat="auto" />
              </Video>
            );
          } else {
            mediaItem = (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video key={url} controls controlsList="nodownload">
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            );
          }
        } else {
          mediaItem = (
            <ExternalLink href={url} key={url}>
              {!assetId && (
                <img src={url} alt={`action-media-content-${index}`} />
              )}
              {assetId && (
                <Image cloudName="eqolot" publicId={assetId}>
                  <Transformation transformation={['influencer-asset']} />
                </Image>
              )}
            </ExternalLink>
          );
        }

        return (
          <div
            key={url}
            className="action-media-content-item"
            ref={mediaRefs[url]}
          >
            {filename && (
              <span className="action-media-content-title">
                <div className="index">{index + 1}</div>
                {filename}
              </span>
            )}
            {mediaItem}
          </div>
        );
      })}
    </div>
  );
}

EventMediaBody.propTypes = {
  content: PropTypes.shape({
    media: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        filename: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  mediaRefs: PropTypes.objectOf(
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLElement) }),
  ).isRequired,
};

export default EventMediaBody;
