import { createSelector, createStructuredSelector } from 'reselect';
import { isDirty } from 'redux-form';
import get from 'lodash/get';

const getCampaign = (state, props) => props.campaign;
const getFormDirty = (state, props) => isDirty(props.form)(state);

const getCountries = createSelector(getCampaign, (campaign = {}) => {
  const countries = get(campaign, 'countries', []);
  const preparedCountries = countries.map((country) => ({
    ...country,
    visitsShare: country.visitsShare * 100,
  }));
  return { countries: preparedCountries };
});

const getHeadline = createSelector(getFormDirty, (dirty) => {
  let headline = 'Countries';
  if (dirty) {
    headline += '*';
  }

  return headline;
});

const getSubHeadline = createSelector(
  getCampaign,
  (campaign = {}) => `${get(campaign, 'countries', []).length} countries(s)`,
);

export default createStructuredSelector({
  initialValues: getCountries,
  headline: getHeadline,
  subHeadline: getSubHeadline,
});
