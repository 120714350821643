import omit from 'lodash/omit';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import merge from 'lodash/merge';

/**
 * generate an incremental next id-number for the given collection
 */
export const nextItemId = (collection) => {
  const lastItem = collection[collection.length - 1];

  // if there is now item in the list start with 0
  if (!lastItem) {
    return 0;
  }

  // if the last item in the list is a number just increase the counter
  const lastId = Number(lastItem.id);
  if (lastId >= 0) {
    return lastItem.id + 1;
  }

  // We might have non-numbers in the list, so we need to scan
  // the entire list for possible ids, so we can calculate the
  // ==> `max(ids) + 1`.
  const ids = collection.map((item) => {
    const parsedId = Number(item.id);

    // set non-numbers to 0
    return parsedId >= 0 ? parsedId : 0;
  });

  return Math.max(...ids) + 1;
};

/**
 * update the `field` with the given `value` of the `item`
 */
export const updateItem = (item, field, value) => ({ ...item, [field]: value });

/**
 * add another item to the collection (to the end)
 */
export const push = (collection, item) => [...collection, item];

/**
 * add another item to the collection (to the front)
 */
export const unshift = (collection, item) => [item, ...collection];

export const insertBy = (collection, item, attr) => {
  const itemIndex = findIndex(
    collection,
    (collectionItem) => get(collectionItem, attr) > get(item, attr),
  );

  if (itemIndex === 0) {
    return unshift(collection, item);
  }
  if (itemIndex === -1) {
    return push(collection, item);
  }

  return [
    ...collection.slice(0, itemIndex),
    item,
    ...collection.slice(itemIndex),
  ];
};

/**
 * replace the given `item` within the given `collection`
 */
export const findAndReplace = (collection, item, { index } = {}) => {
  const itemIndex = index >= 0 ? index : findIndex(collection, { id: item.id });
  if (itemIndex === -1) {
    console.warn('could not find item', item.id, 'in collection', collection);
    return collection;
  }

  return [
    ...collection.slice(0, itemIndex),
    item,
    ...collection.slice(itemIndex + 1),
  ];
};

/**
 * merge in update of the given `item` into the `collections` existing item
 */
export const findAndMerge = (collection, item, { index } = {}) => {
  const itemIndex = index >= 0 ? index : findIndex(collection, { id: item.id });
  if (itemIndex === -1) {
    console.warn(`could not find item ${item.id} in collection ${collection}`);
    return collection;
  }

  return [
    ...collection.slice(0, itemIndex),
    merge({}, collection[itemIndex], item),
    ...collection.slice(itemIndex + 1),
  ];
};

/**
 * remove the given item from the collection
 */
export const remove = (collection, item, { index } = {}) => {
  const itemIndex = index >= 0 ? index : findIndex(collection, { id: item.id });
  if (itemIndex === -1) {
    return collection;
  }

  return [
    ...collection.slice(0, itemIndex),
    ...collection.slice(itemIndex + 1),
  ];
};

/**
 * remove an item at the given index
 */
export const removeAtIndex = (list, index) => {
  if (index < 0 || list.length - 1 < index) {
    return list;
  }

  return [...list.slice(0, index), ...list.slice(index + 1)];
};

/**
 * replace an item at the given index
 */
export const replaceAtIndex = (list, index, item) => {
  if (index < 0 || list.length - 1 < index) {
    return list;
  }

  return [...list.slice(0, index), item, ...list.slice(index + 1)];
};

/**
 * swap two items within an array
 */
export const swapByIndex = (collection, firstIndex, secondIndex) => {
  const collectionCopy = [...collection];

  const tmp = collection[firstIndex];
  collectionCopy[firstIndex] = collection[secondIndex];
  collectionCopy[secondIndex] = tmp;

  return collectionCopy;
};

/**
 * swap the given two items within a collection
 */

export const swap = (collection, first, second) => {
  const firstIndex = findIndex(collection, { id: first.id });
  const secondIndex = findIndex(collection, { id: second.id });

  return swapByIndex(collection, firstIndex, secondIndex);
};

/**
 * move one item within a collection one index up (-1)
 */
export const moveUp = (collection, item) => {
  const itemIndex = findIndex(collection, { id: item.id });
  const neighbor = collection[itemIndex - 1];

  if (!neighbor) {
    return collection;
  }

  return swap(collection, item, neighbor);
};

/**
 * move one item within a collection one index up (+1)
 */
export const moveDown = (collection, item) => {
  const itemIndex = findIndex(collection, { id: item.id });
  const neighbor = collection[itemIndex + 1];

  if (!neighbor) {
    return collection;
  }

  return swap(collection, neighbor, item);
};

export const without = omit;
