import React from 'react';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import noop from 'lodash/noop';

import 'react-dates/lib/css/_datepicker.css';

/**
 * Wrapper around `SingleDatePicker`, that manages "focus" state, but also
 * accepts it from the out-side, so it can still be managed through redux / redux-form.
 */
class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { focused: false };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { focused } = this.props;

    if (focused !== null) {
      this.setState({ focused });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ focused: nextFocused }) {
    const { focused: oldFocused } = this.props;

    if (nextFocused !== null && oldFocused !== nextFocused) {
      this.setState({ focused: nextFocused });
    }
  }

  handleDateChange = (date) => {
    this.handleFocusChange({ focused: false });
    this.props.onChange(date);
  };

  handleFocusChange = (e) => {
    const { focused } = e;

    // NOTE:
    //    Some strange behaviour with the `SingleDatePicker`, if focus state is
    //    already in the provided state we don't need to re-propagate this
    //    change. Also if the focused `value === null` we don't propagate that.
    if (focused === this.state.focused || focused === null) {
      return;
    }

    // if focused is not managed from the out-side, we do it form the inside
    if (this.props.focused === null) {
      this.setState({ focused });
    }

    this.props.onFocus(focused);
  };

  render() {
    // extract the `props.focused` as we only handle focused through the state
    const {
      id,
      value,
      onChange,
      onFocus,
      focused: propsFocused,
      ...props
    } = this.props;

    const { focused } = this.state;

    const singleDatePickerProps = {
      id,
      date: value,
      focused,
      onDateChange: this.handleDateChange,
      onFocusChange: this.handleFocusChange,
      ...props,
    };

    return <SingleDatePicker {...singleDatePickerProps} />;
  }
}

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.object,
  focused: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  numberOfMonths: PropTypes.number,
  showClearDate: PropTypes.bool,
  showDefaultInputIcon: PropTypes.bool,
};

DatePicker.defaultProps = {
  value: null,
  focused: null,
  onChange: noop,
  onFocus: noop,
  placeholder: 'Date',
  numberOfMonths: 1,
  showClearDate: true,
  showDefaultInputIcon: true,
};

export default DatePicker;
