import { createReducer } from 'source/utils';
import actionTypes from 'source/constants/actionTypes';

export const getInitialState = (accessToken) => ({
  accessToken,
});

const actionHandlers = {
  [actionTypes.ACCOUNT_ACCESS_TOKEN_FETCHED]: (state, accessToken) => ({
    ...state,
    accessToken,
  }),
};

export default createReducer(getInitialState(), actionHandlers);
