import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import withClickOutside from 'react-click-outside';
import cn from 'classnames';

import { STATUS_LABELS } from 'source/scenes/groups/constants';

const statuses = ['N/A', ...Object.keys(STATUS_LABELS)];

class PopupItem extends React.PureComponent {
  getStatusLabel = (status) => get(STATUS_LABELS, `${status}`, 'N/A');

  handleOnClick = (e) => {
    e.preventDefault();
    const { onClick, status } = this.props;
    const newStatus = status === 'N/A' ? null : status;

    onClick(newStatus);
  };

  render() {
    const { userStatus, status } = this.props;

    const isCurrent =
      (!userStatus && status === 'N/A') || userStatus === status;

    return (
      <a
        href="#"
        onClick={this.handleOnClick}
        className={cn('group-list-status', {
          current: status === userStatus,
        })}
      >
        <span
          className={`group-status-indicator ${status}`}
          style={{ marginRight: 7 }}
        />
        <span>{this.getStatusLabel(status)}</span>
        {isCurrent && <span className="checked">✓</span>}
      </a>
    );
  }
}

PopupItem.propTypes = {
  userStatus: PropTypes.string,
  status: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

class UserStatusPopup extends React.PureComponent {
  // eslint-disable-next-line
  handleClickOutside = () => {
    this.props.onClose();
  };

  render() {
    const { user, onStatusSelected } = this.props;

    return (
      <div className="select-group-status-popup">
        {statuses.map((status) => (
          <PopupItem
            key={status}
            status={status}
            userId={user.id}
            userStatus={user.status}
            onClick={onStatusSelected}
          />
        ))}
      </div>
    );
  }
}

UserStatusPopup.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onStatusSelected: PropTypes.func.isRequired,
};

export default withClickOutside(UserStatusPopup);
