import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { ProgressBar, Alert } from 'source/components/common';
import { Checkbox } from 'source/components/common/forms';
import { dedent } from 'source/utils';

const formatDate = (date, format = 'YYYY-MM-DD', defaultStr = 'n/a') => {
  if (!date) {
    return defaultStr;
  }

  return moment.utc(date).format(format);
};

class PublishingDates extends Component {
  handleDateChange = (e) => {
    const nextValue = e.target.value;
    const {
      application: { id },
      onChange,
    } = this.props;

    onChange(id, 'publishingDateId', nextValue);
  };

  renderAssigned() {
    const { application, publishingDates } = this.props;

    const isAssigned = !isEmpty(application.assignment);
    const hasAccepted = get(application, 'mission.status') === 'confirmed';

    if (hasAccepted || !isAssigned) {
      return null;
    }

    const date = get(publishingDates, 'publishingDates', []).find(
      ({ id }) => id === application.assignment.publishingDateId,
    );
    let dateStr = '';
    if (date && date.date) {
      dateStr = ` [on ${formatDate(date.date)}]`;
    }

    return (
      <Alert
        message={`Application was assigned to a VIP date.${dateStr}`}
        type="warning"
      />
    );
  }

  renderAccepted() {
    const { application } = this.props;

    const hasAccepted = get(application, 'mission.status') === 'confirmed';

    if (!hasAccepted) {
      return null;
    }

    const date = formatDate(get(application, 'mission.publishDate'));
    const dateStr = ` [on ${date}]`;

    return (
      <Alert
        message={`User has accepted the mission.${dateStr}`}
        type="success"
      />
    );
  }

  renderVIPCheckbox() {
    const vip = get(this.props.form, 'forms.vip', false);

    /**
     * NOTE @alexspri
     *    Form changes are automatically handled by the parent.
     */
    return (
      <Checkbox
        id="vip"
        className="pb-3"
        inputClassName="checkbox-big"
        checked={vip}
        label={<span className="pl-3">VIP</span>}
      />
    );
  }

  renderDates() {
    const { form, publishingDates } = this.props;

    const { publishingDateId } = form.forms;
    const vip = get(form, 'forms.vip', false);

    const loadingState = get(publishingDates, 'state');
    const dates = get(publishingDates, 'publishingDates', []);

    if (!vip) {
      return null;
    }

    if (loadingState === 'loading') {
      return <ProgressBar mega />;
    }

    const availableDates = dates.filter(
      ({ vip, assigned }) => vip && !assigned,
    );

    if (isEmpty(availableDates)) {
      const warningMessange = dedent(`
          Could not find any unassigned VIP publishing dates for this campaign.
          Please make sure you have configured enough VIP dates and have not
          accidentely assigned someone else to a VIP date.`);

      return (
        <Alert message={warningMessange} type="warning" className="mb-0" />
      );
    }

    return (
      <div>
        <p>Select the date you want to assign this application to:</p>
        {availableDates.map(({ id, date }) => {
          const isChecked = id === publishingDateId;
          const displayDate = moment.utc(date).format('ddd DD/MM/YYYY');

          return (
            <label
              className="radio-label-group pb-1"
              htmlFor={`publishingDateId:${id}`}
              key={id}
            >
              <input
                type="radio"
                className="radio-big"
                id={`publishingDateId:${id}`}
                value={id}
                checked={isChecked}
                onChange={this.handleDateChange}
              />
              <span>{displayDate}</span>
            </label>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col">
          <h5>Would you like to mark this as a VIP application?</h5>
          <p className="text-muted">
            VIP applications are assigned a single publish date on which the
            user may post their content. Otherwise, users may select from any
            available publish date.
          </p>
          {this.renderAssigned()}
          {this.renderAccepted()}
          {this.renderVIPCheckbox()}
          {this.renderDates()}
        </div>
      </div>
    );
  }
}

PublishingDates.propTypes = {
  application: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  publishingDates: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

PublishingDates.defaultProps = {
  publishingDates: null,
};

export default PublishingDates;
