import PropTypes from 'prop-types';
import React from 'react';
import range from 'lodash/range';
import noop from 'lodash/noop';
import cx from 'classnames';
import { animateScroll as scroll } from 'react-scroll';

function PaginationLink({ onSelect, item, children }) {
  const handleClick = (e) => {
    e.preventDefault();
    return onSelect(item);
  };

  return (
    <a className="page-link" href="#" onClick={handleClick}>
      {children}
    </a>
  );
}

PaginationLink.propTypes = {
  onSelect: PropTypes.func.isRequired,
  item: PropTypes.number.isRequired,
  children: PropTypes.any,
};

function PaginationItem({ onSelect, item, children, active, className }) {
  return (
    <li className={cx('page-item', className, { active })}>
      <PaginationLink onSelect={onSelect} item={item}>
        {children}
      </PaginationLink>
    </li>
  );
}

PaginationItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  item: PropTypes.number.isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
};

PaginationItem.defaultProps = {
  active: false,
};

export const getPages = (itemCount, perPage) => Math.ceil(itemCount / perPage);
export const getPagination = ({ count, pageSize, page }) => ({
  activePage: page,
  pages: getPages(count, pageSize),
});

function Pagination({ className, pages, activePage, onSelect, disableScroll }) {
  const onSelectWithOptionalScroll = (item) => {
    onSelect(item);
    if (!disableScroll) {
      scroll.scrollToTop({ duration: 1 });
    }
  };
  const MAX_PAGES = 10;

  if (activePage < 0) {
    return null;
  }

  let nextPage = activePage + 1;
  let prevPage = activePage - 1;

  // Wrap around to next item if we reached limit.
  if (nextPage > pages - 1) {
    nextPage = 0;
  }

  // Wrap around to previous item if we reached the first.
  if (prevPage < 0) {
    prevPage = pages - 1;
  }

  const renderPager = () => {
    const fromPage = Math.max(activePage - MAX_PAGES / 2, 0);
    const toPage = Math.min(fromPage + MAX_PAGES, pages);

    return range(fromPage, toPage).map((page) => (
      <PaginationItem
        className="pagination-page"
        key={page}
        item={page}
        active={page === activePage}
        onSelect={onSelectWithOptionalScroll}
      >
        {page + 1}
      </PaginationItem>
    ));
  };

  return (
    <nav>
      <ul className={cx('pagination', className)}>
        <PaginationItem onSelect={onSelect} item={prevPage}>
          <span aria-hidden="true">Previous</span>
          <span className="sr-only">Previous</span>
        </PaginationItem>
        {renderPager()}
        <PaginationItem onSelect={onSelect} item={nextPage}>
          <span aria-hidden="true">Next</span>
          <span className="sr-only">Next</span>
        </PaginationItem>
      </ul>
    </nav>
  );
}

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  disableScroll: PropTypes.bool,
};

Pagination.defaultProps = {
  onSelect: noop,
};

export default Pagination;

export const getPagingFromHeaders = (headers = {}) => ({
  page: Number(headers['x-page'] || '0'),
  pageSize: Number(headers['x-page-size'] || '0'),
  count: Number(headers['x-count'] || '0'),
  total: Number(headers['x-total-count'] || '0'),
});
