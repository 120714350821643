import {
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
} from 'revalidate';

import { isGreaterThan } from 'source/utils/validators';

export default combineValidators({
  budget: composeValidators(
    isRequired,
    isNumeric,
    isGreaterThan(0),
  )('Advertiser budget'),
});
