import get from 'lodash/get';
import identity from 'lodash/identity';

/**
 * Select a property from a namespaced part of the state
 *
 * @example
 *   import { createStructuredSelector } form 'reselect';
 *
 *   const getActiveCampaignId = selectFromStatePath('activeCampaignId');
 *
 *   export default createStructuredSelector({
 *     campaignId: getActiveCampaignId
 *   });
 */
export const selectFromStatePath =
  (key, defaults, subSelector = identity) =>
  (state, props, name) =>
    subSelector(get(get(state, name, {}), key, defaults));

/**
 * Wrapper for the mapDispatchToProps function when connecting with redux,
 * such that the corresponding selector receives the namespace as 3rd argument.
 *
 * @example
 *   const { connect } from 'react-redux';
 *
 *   const Container form './container';
 *   const selector from './selector';
 *
 *   export default connect(withStatePath('my.nested.state.location')(selector));
 */
export const withStatePath = (name) => (selector) => (state, ownProps) =>
  selector(state, ownProps, name);

/**
 * selector to get the statePath
 */
export const getStatePath = (state, props, name) => name;

/**
 * HoF which take an object path and a selector, and yields a new selector
 * which treats the state slice corresponding to that path as the state root
 */
export const forSlice = (slicePath, selector) => (state, props) => {
  const slice = get(state, slicePath);

  if (slice === undefined) {
    throw new Error(`forSlice: No state slice at ${slicePath}`);
  }

  return selector(slice, props);
};
