import React from 'react';
import PropTypes from 'prop-types';

function Avatar({ name, color }) {
  const style = {};
  if (color) {
    style.backgroundColor = color;
  }

  return (
    <div className="col-md-1 image-col">
      <div className="card mb-2">
        <div className="card-body image-card-block" style={style}>
          {name}
        </div>
      </div>
    </div>
  );
}

Avatar.propTypes = {
  name: PropTypes.node.isRequired,
  color: PropTypes.string,
};

Avatar.defaultProps = {
  color: null,
};
export default Avatar;
