import React from 'react';
import PropTypes from 'prop-types';
import qsStringify from 'qs/lib/stringify';

import Config from 'config';
import DownloadButton from 'source/components/common/downloadButton';

function ListApplicationsReportButton({
  accessToken,
  campaignId,
  applicationStatus,
  ...props
}) {
  const baseUrl = `${Config.api.url}/v2/reports/applications.xlsx`;
  const filters = [{ campaignId }];

  if (applicationStatus) {
    filters.push({ status: applicationStatus });
  }

  const query = {
    filters: JSON.stringify(filters),
    access_token: accessToken,
  };
  const href = `${baseUrl}?${qsStringify(query)}`;

  return <DownloadButton href={href} {...props} />;
}

ListApplicationsReportButton.propTypes = {
  accessToken: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  applicationStatus: PropTypes.string,
};

ListApplicationsReportButton.defaultProps = {
  applicationStatus: undefined,
};

export default ListApplicationsReportButton;
