import React from 'react';
import PropTypes from 'prop-types';
import { PortalWithState } from 'react-portal';
import ConfirmationAlertWrapper from './confirmationAlertWrapper';

function ButtonWithConfirmation({
  onConfirm,
  button,
  children,
  yesButtonLabel,
  noButtonLabel,
}) {
  return (
    <PortalWithState closeOnOutsideClick closeOnEsc>
      {({ openPortal, closePortal, portal }) => {
        const onClick = (e) => {
          e.stopPropagation();
          openPortal(e);
        };
        return (
          <>
            {React.cloneElement(button, { onClick })}
            {portal(
              <ConfirmationAlertWrapper
                onConfirm={onConfirm}
                yesButtonLabel={yesButtonLabel}
                noButtonLabel={noButtonLabel}
                closePortal={closePortal}
              >
                <div>
                  <div className="ca-icon ca-warning pulseWarning">
                    <span className="ca-body pulseWarningIns" />
                    <span className="ca-dot pulseWarningIns" />
                  </div>
                  {children}
                </div>
              </ConfirmationAlertWrapper>,
            )}
          </>
        );
      }}
    </PortalWithState>
  );
}

ButtonWithConfirmation.propTypes = {
  button: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  yesButtonLabel: PropTypes.string,
  noButtonLabel: PropTypes.string,
};

ButtonWithConfirmation.defaultProps = {
  yesButtonLabel: 'Yes',
  noButtonLabel: 'No',
};

export default ButtonWithConfirmation;
