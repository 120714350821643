/**
 * Global-aware selectors for the ApplicationReview scene
 */

import merge from 'lodash/merge';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { createAsyncSelector } from '@blogfoster/redux-async-utils';

import { selectors as fromPanel } from './components/common/applicationDetail/reducer';

import { selectors as fromMainScene } from './components/common/applicationsOverview/reducer';

// Helper selector to get the root state for this scene
const getScene = (state) => get(state, 'scenes.campaignDetail.applications');
export const getCampaignId = (state, props) => props.campaignId;
export const getAccessToken = (state) => state.account.accessToken;

export const getApplicationsSceneState = (state) =>
  fromMainScene.getApplicationsSceneState(getScene(state));

const getApplicationPanel = (state, props) =>
  fromMainScene.getApplicationPanel(getScene(state), props);

export const getPanelInsightsAsync = createAsyncSelector(
  {
    insights: createSelector(getApplicationPanel, (panelScene) =>
      fromPanel.getInsightsAsync(panelScene),
    ),
  },
  (state) => {
    const kpi = get(state, 'insights.channel.kpi', {});
    const insights = get(state, 'insights.insights', {});
    const performance = get(state, 'insights.channel.performance', {});
    return { kpi, ...insights, ...performance };
  },
);

export const getPanelPublishingDatesAsync = createSelector(
  getApplicationPanel,
  (panelScene) => fromPanel.getPublishingDatesAsync(panelScene),
);

export const getApplications = createSelector(
  getApplicationsSceneState,
  (applicationsSceneState) => applicationsSceneState.data,
);

export const getApplicationsByStatus = createSelector(
  getApplications,
  (applications) =>
    merge(
      {
        new: [],
        shortlisted: [],
        review: [],
        selected: [],
        approved: [],
        rejected: [],
      },
      groupBy(applications, (application) => application.status),
    ),
);

export const getOverviewFilter = (state) => getScene(state).overviewFilter;
