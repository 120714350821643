import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';

import { DatePicker } from 'source/components/common';

/**
 * Redux-form compatible date picker component. The component uses
 * `SingleDatePicker` from "react-dates". All date inputs are stored in ISO
 * string format in the redux-form state.
 *
 * @example
 *
 * <Field
 *   name="birthday"
 *   component={DateInput}
 *   label="Birthday:"
 * />
 */
function DateInput(props) {
  const { input, meta, label, className, ...otherProps } = props;
  const { touched, error } = meta;

  // make sure an empty value is treated correctly, if given to moment, this
  // would otherwise create a "now" date object
  const value = input.value !== '' ? moment(input.value) : null;
  const id = `${meta.form}/${input.name}`;

  const datePickerProps = {
    id,
    value,
    focused: meta.active,
    onChange: input.onChange,
    onFocus: (focused) =>
      focused ? input.onFocus(focused) : input.onBlur(value),
    ...otherProps,
  };

  return (
    <div className={cx('form-group', className)}>
      {label && <label htmlFor={id}>{label}</label>}
      <div>
        <DatePicker {...datePickerProps} />
      </div>
      {touched && error && (
        <small className="invalid-feedback" style={{ display: 'inherit' }}>
          {error}
        </small>
      )}
    </div>
  );
}

DateInput.propTypes = {
  // Some of the important props provided by redux-form. Complete list:
  // http://redux-form.com/6.7.0/docs/api/Field.md
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    form: PropTypes.string.isRequired,
    active: PropTypes.bool,
  }).isRequired,
  // Custom props
  label: PropTypes.string,
  className: PropTypes.string,
};

DateInput.defaultProps = {
  label: null,
};

export default DateInput;
