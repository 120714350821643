import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import moment from 'moment';
import cx from 'classnames';
import config from 'config';
import withResetScene from 'source/components/commonEnhancers/withResetScene';
import { isPending } from 'source/utils/status';
import { isAnyScreenshotMissing } from 'source/utils/insights';
import { ProgressBar, CollapsiblePanel, Alert } from 'source/components/common';
import Icon from 'source/components/common/icon';
import ChannelAvatar from 'source/components/common/channelAvatar';

import ChannelDetailsForm from './channelDetailsForm';
import InstagramInsights from './instagramInsights';
import TikTokInsights from './tiktokInsights';
import PinterestInsights from './pinterestInsights';
import actions from '../actions';
import { getChannelDetailsState } from '../selectors';

const enhance = compose(
  connect(getChannelDetailsState, actions),
  withResetScene,
);

const panels = {
  details: 'details',
  metrics: 'metrics',
};

const states = {
  READY: {
    message: 'ready',
    color: 'green',
  },
  NOT_READY: {
    message: 'not ready',
    color: 'red',
  },
  PENDING: {
    message: 'pending',
    color: 'orange',
  },
};

const metricsConfigByPlatform = {
  instagram: {
    metricsKey: 'keyMetrics',
  },
  pinterest: {
    metricsKey: 'keyMetrics',
  },
  tiktok: {
    metricsKey: 'kpi',
  },
};

class ChannelDetails extends React.PureComponent {
  getLastUpdateTimestamp() {
    const { channel } = this.props;

    if (!channel.updatedAt) {
      return '';
    }

    const formatted = moment(channel.updatedAt).format('YYYY-MM-DD HH:mm:ss');
    return `Last Control Center update on ${formatted}`;
  }

  channelDetailsHeading = (
    {
      url,
      id,
      userId,
      ready,
      readyState = {},
      qualityReview,
      platform,
      extension = {},
    },
    { notes },
  ) => {
    let state;

    if (ready) {
      state = states.READY;
    } else if (isPending(readyState)) {
      state = states.PENDING;
    } else {
      state = states.NOT_READY;
    }

    const qualityReviewStatus = qualityReview ? 'quality review' : '';

    const noteIcon = isEmpty(notes) ? null : (
      <Icon name="note" className="small-icon" />
    );

    const isInstagram = platform === 'instagram';
    const hasApiOrigins = !!extension.apiVersion;

    return (
      <>
        <span className="mx-2">{url}</span>
        <span className="mx-2">id: {id}</span>
        <span className="mx-2">user: {userId}</span>

        <span
          className={cx('mx-2 badge', {
            'badge-success': ready,
            'badge-warning': isPending(readyState),
            'badge-danger': !ready && !isPending(readyState),
          })}
        >
          {state.message}
        </span>

        <span className="mx-2 badge badge-warning">{qualityReviewStatus}</span>

        <span className="mx-2">{noteIcon}</span>
        {isInstagram && hasApiOrigins && (
          <span className="text-warning">API channel</span>
        )}
      </>
    );
  };

  kpiChannelDetailsHeading = ({ platform, screenshots, keyMetrics = {} }) => {
    const insightsConfig = get(config, `insights.${platform}`, {});
    const { insightsTtl, insightsIntermediateTtl } = insightsConfig;

    const metricsValidAt = keyMetrics.metricsValidAt || keyMetrics.updatedAt;

    // Uploaded Date
    const oldestScreenshotUploadDate = screenshots
      ? moment.utc(screenshots.oldestUpdatedAt).format('ddd DD/MM/YYYY')
      : '';

    const today = moment().startOf('day');
    const updatedAtDate = screenshots
      ? moment(screenshots.updatedAt).startOf('day')
      : '';
    const uploadDaysAfter = moment(today).diff(updatedAtDate, 'days');

    // Collected Date
    const collectedDate = metricsValidAt
      ? moment.utc(metricsValidAt).format('ddd DD/MM/YYYY')
      : '';

    const collected = metricsValidAt
      ? moment(metricsValidAt).startOf('day')
      : '';
    const collectedAfter = moment(today).diff(collected, 'days');

    if (platform === 'pinterest') {
      return (
        <>
          <span className="mx-2 badge badge-success">API</span>
          <span className="badge badge-info">
            {metricsValidAt
              ? `Report date: ${collectedDate}`
              : 'Collected date is not available'}
          </span>
        </>
      );
    }

    return (
      <>
        {/* Uploaded Date */}
        <span
          className={cx('mx-2 badge', {
            'badge-secondary': uploadDaysAfter < insightsIntermediateTtl + 1,
            'badge-warning': uploadDaysAfter >= insightsIntermediateTtl + 1,
            'badge-danger':
              uploadDaysAfter > insightsTtl ||
              !screenshots ||
              isAnyScreenshotMissing(screenshots, platform),
          })}
        >
          {screenshots
            ? `Uploaded: ${oldestScreenshotUploadDate}`
            : 'No screenshots available'}
        </span>

        {/* Collected Date */}
        <span
          className={cx('mx-2 badge', {
            'badge-secondary':
              collectedAfter <= uploadDaysAfter ||
              !collectedAfter ||
              !screenshots,
            'badge-danger': collectedAfter > uploadDaysAfter,
          })}
        >
          {metricsValidAt
            ? `Report date: ${collectedDate}`
            : 'Collected date is not available'}
        </span>
      </>
    );
  };

  render() {
    const {
      channel,
      onFormSubmit,
      onFormInsightsSubmit,
      onFormKeyMetricsSubmit,
      onDeleteChannel,
      onDeleteChannelScreenshot,
      onRefreshMetrics,
      onPanelClick,
      requestState,
      requestInsightsState,
      initialValues,
      formValues,
      selectedPanel,
      user,
    } = this.props;

    const metricsConfig = metricsConfigByPlatform[channel.platform];
    const hasMetrics = !!metricsConfig;

    return (
      <div>
        <div className="row">
          <div className="col">
            <h2>{channel.name}</h2>
          </div>
        </div>

        <div className="row mt-4 mb-3">
          <div className="col">
            <span className="text-muted">{this.getLastUpdateTimestamp()}</span>
          </div>
        </div>

        <CollapsiblePanel
          id={panels.details}
          headline="Channel Settings"
          subHeadline={this.channelDetailsHeading(channel, initialValues)}
          disableToggle={!hasMetrics}
          onClick={onPanelClick}
          expanded={selectedPanel === panels.details}
          headerClassName="card-header-channel"
          panelIcon={<ChannelAvatar url={channel.avatarUrl} />}
        >
          <ChannelDetailsForm
            channel={channel}
            initialValues={initialValues}
            formValues={formValues}
            requestState={requestState}
            onSubmit={onFormSubmit}
            onDelete={onDeleteChannel}
          />
        </CollapsiblePanel>

        {hasMetrics && (
          <CollapsiblePanel
            className="mt-3"
            bodyClassName="channel-metrics"
            id={panels.metrics}
            headline="Metrics"
            subHeadline={this.kpiChannelDetailsHeading(channel)}
            onClick={onPanelClick}
            expanded={selectedPanel === panels.metrics}
          >
            <>
              {channel.platform === 'instagram' && (
                <InstagramInsights
                  channel={channel}
                  initialValues={initialValues.keyMetrics}
                  formValues={formValues.keyMetrics}
                  requestInsightsState={requestInsightsState}
                  onSubmit={onFormKeyMetricsSubmit}
                  onDeleteScreenshot={onDeleteChannelScreenshot}
                  onRefreshMetrics={onRefreshMetrics}
                  user={user}
                />
              )}
              {channel.platform === 'tiktok' && (
                <TikTokInsights
                  channel={channel}
                  initialValues={initialValues.kpi}
                  formValues={formValues.kpi}
                  requestInsightsState={requestInsightsState}
                  onSubmit={onFormInsightsSubmit}
                  onDeleteScreenshot={onDeleteChannelScreenshot}
                />
              )}
              {channel.platform === 'pinterest' && (
                <PinterestInsights {...channel.keyMetrics} />
              )}
            </>
          </CollapsiblePanel>
        )}
      </div>
    );
  }
}

ChannelDetails.propTypes = {
  channel: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onFormInsightsSubmit: PropTypes.func.isRequired,
  onFormKeyMetricsSubmit: PropTypes.func.isRequired,
  onDeleteChannel: PropTypes.func.isRequired,
  onDeleteChannelScreenshot: PropTypes.func.isRequired,
  onRefreshMetrics: PropTypes.func.isRequired,
  onPanelClick: PropTypes.func.isRequired,
  requestState: PropTypes.object.isRequired,
  requestInsightsState: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  selectedPanel: PropTypes.string,
  user: PropTypes.object.isRequired,
};

class ChannelDetailsRoot extends React.Component {
  componentDidMount() {
    const {
      onLoadChannel,
      match: {
        params: { id },
      },
      location,
      error,
    } = this.props;

    return onLoadChannel(id).then(() => {
      if (error) {
        return;
      }
      const [, panelId] = location.hash.split('#');
      this.onPanelClick(panelId || panels.details, true);
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const {
      onLoadChannel,
      match: {
        params: { id: nextId },
      },
    } = nextProps;

    if (id !== nextId) {
      onLoadChannel(nextId);
    }
  }

  onPanelClick = (id, toggleFlag) => {
    this.props.onPanelClick(id, toggleFlag, {
      location: this.props.location,
      history: this.props.history,
    });
  };

  renderWebsiteDetails() {
    const {
      channel,
      onChannelFormSubmit,
      onChannelInsightsFormSubmit,
      onChannelKeyMetricsFormSubmit,
      onDeleteChannel,
      onDeleteChannelScreenshot,
      onRefreshMetrics,
      requestState,
      requestInsightsState,
      initialValues,
      formValues,
      selectedPanel,
      user,
    } = this.props;

    return (
      <ChannelDetails
        channel={channel}
        requestState={requestState}
        requestInsightsState={requestInsightsState}
        formValues={formValues}
        initialValues={initialValues}
        selectedPanel={selectedPanel}
        onFormSubmit={onChannelFormSubmit}
        onFormInsightsSubmit={onChannelInsightsFormSubmit}
        onFormKeyMetricsSubmit={onChannelKeyMetricsFormSubmit}
        onDeleteChannel={onDeleteChannel}
        onDeleteChannelScreenshot={onDeleteChannelScreenshot}
        onRefreshMetrics={onRefreshMetrics}
        onPanelClick={this.onPanelClick}
        user={user}
      />
    );
  }

  render() {
    const { state, alert, onAlertClose } = this.props;
    const stateLoaded = state === 'loaded' || state === 'failed';

    return (
      <div className="container">
        <Alert {...alert} onClose={onAlertClose} />
        {stateLoaded ? this.renderWebsiteDetails() : <ProgressBar.Mega />}
      </div>
    );
  }
}

ChannelDetailsRoot.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    shouldFetchData: PropTypes.bool,
    hash: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
  error: PropTypes.object,
  alert: PropTypes.object,
  state: PropTypes.oneOf(['loading', 'loaded', 'failure', '']).isRequired,
  channel: PropTypes.object.isRequired,
  requestState: PropTypes.object.isRequired,
  requestInsightsState: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  onLoadChannel: PropTypes.func.isRequired,
  onDeleteChannel: PropTypes.func.isRequired,
  onDeleteChannelScreenshot: PropTypes.func.isRequired,
  onRefreshMetrics: PropTypes.func.isRequired,
  onChannelFormSubmit: PropTypes.func.isRequired,
  onChannelInsightsFormSubmit: PropTypes.func.isRequired,
  onChannelKeyMetricsFormSubmit: PropTypes.func.isRequired,
  onAlertClose: PropTypes.func.isRequired,
  onPanelClick: PropTypes.func.isRequired,
  selectedPanel: PropTypes.string,
  user: PropTypes.object.isRequired,
};

export default enhance(ChannelDetailsRoot);
