import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import Octicon from 'react-octicon';

import ExternalLink from 'source/scenes/components/externalLink';

function DownloadButton({
  href,
  disabled,
  children,
  size,
  className,
  btnType,
  customIcon,
  iconName,
  title,
  onClick,
}) {
  return (
    <ExternalLink
      className={cx('btn', `btn-${btnType}`, 'btn-download', className, {
        disabled,
        'cursor-not-allowed': disabled,
        'btn-sm': size === 'sm',
        'btn-lg': size === 'lg',
      })}
      role="button"
      href={href}
      title={title}
      download
      aria-disabled={disabled}
      onClick={onClick}
    >
      {children}
      {!customIcon && (
        <Octicon name={iconName} className={cx({ 'no-margin': !children })} />
      )}
    </ExternalLink>
  );
}

DownloadButton.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  btnType: PropTypes.oneOf(['outline-primary', 'primary']),
  className: PropTypes.string,
  customIcon: PropTypes.bool,
  iconName: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

DownloadButton.defaultProps = {
  children: null,
  disabled: false,
  size: null,
  btnType: 'outline-primary',
  className: '',
  customIcon: false,
  iconName: 'cloud-download',
  title: undefined,
};

export default DownloadButton;
