import { combineReducers } from 'redux';
import { asyncActionReducer } from '@blogfoster/redux-async-utils';
import get from 'lodash/get';
import compact from 'lodash/compact';

import { combineReducersFlat, keyValueStoreReducer } from 'source/utils/redux';

import { actionTypes } from './actions';
import {
  dataMergeManyHandler,
  dataMergeHandler,
  dataCreateHandler,
  dataDeleteHandler,
} from '../dataHandlers';

/* == selectors == */
export const getCampaignsById = (state) => get(state, 'byId', {});
export const getCampaign = (state, { campaignId }) => state.byId[campaignId];

/* == default reducers == */
const campaignFetchManyReducer = asyncActionReducer(
  actionTypes.FETCH_MANY,
  dataMergeManyHandler(),
);

const campaignFetchReducer = asyncActionReducer(
  actionTypes.FETCH,
  dataMergeHandler(),
);

const campaignUpdateReducer = asyncActionReducer(
  actionTypes.UPDATE,
  dataMergeHandler(),
);

const campaignCreateReducer = asyncActionReducer(
  actionTypes.CREATE,
  dataCreateHandler(),
);

const campaignDeleteReducer = asyncActionReducer(
  actionTypes.DELETE,
  dataDeleteHandler(),
);

const campaignClearReducer = (state, action) =>
  action.type === actionTypes.CLEAR
    ? compact(action.payload).reduce((nextState, campaignId) => {
        nextState[campaignId] = undefined;
        return nextState;
      }, state)
    : state;

const campaignsByIdReducer = combineReducersFlat(
  [
    campaignClearReducer,
    campaignFetchManyReducer,
    campaignCreateReducer,
    campaignDeleteReducer,
    keyValueStoreReducer(
      combineReducersFlat([campaignFetchReducer, campaignUpdateReducer]),
    ),
  ],
  {},
);

export default combineReducers({
  byId: campaignsByIdReducer,
});
