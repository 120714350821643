import { combineReducers } from 'redux';

import applications from './applications/reducer';
import campaigns from './campaigns/reducer';
import cave from './cave/reducer';
import payments from './payments/reducer';
import reports from './reports/reducer';

export default combineReducers({
  applications,
  campaigns,
  cave,
  payments,
  reports,
});
