import isFinite from 'lodash/isFinite';
import isNil from 'lodash/isNil';

const isFloat = (value) =>
  typeof value === 'number' ||
  (typeof value === 'string' && isFinite(parseFloat(value)));

export default function validate(form) {
  const errors = {};

  // `fixed`
  if (isNil(form.fixed)) {
    errors.fixed = 'missing "Fixed Bonus"';
  }

  if (!isFloat(form.fixed)) {
    errors.fixed = '"Fixed Bonus" is not valid number';
  }

  if (form.fixed < 0) {
    errors.fixed = '"Fixed Bonus" must be greater than or equal to 0%';
  }

  // `variablePercentage`
  if (isNil(form.variablePercentage)) {
    errors.variablePercentage = 'missing "Variable Bonus Percentage"';
  }

  if (!isFloat(form.variablePercentage)) {
    errors.variablePercentage =
      '"Variable Bonus Percentage" is not valid number';
  }

  if (form.variablePercentage < 0) {
    errors.variablePercentage =
      '"Variable Bonus Percentage" must be greater than or equal to 0%';
  }

  return errors;
}
