import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import first from 'lodash/first';
import isString from 'lodash/isString';
import { withProps, withState, compose } from 'recompose';

import ExternalLink from 'source/scenes/components/externalLink';
import Nav from 'source/scenes/components/navigation';
import Tabs from 'source/scenes/components/tabs';

// Helpers

const visibleFor =
  (props) =>
  ({ isVisible }) =>
    isVisible === undefined || isVisible(props);

const emptyObject = () => ({});

// HoCs

const withTabs = withProps(({ getTabs, ...props }) => ({
  tabs: getTabs(props),
}));

const withTabState = withState(
  'selectedTab',
  'onSelectTab',
  ({ initialTab, tabs }) => initialTab || get(first(tabs), 'id') || '',
);

// Components

function ApplicationTabPane({ title, children, className, tabId }) {
  return (
    <section
      className={cx(
        className,
        'application-info-tab',
        `application-info-tab--${tabId}`,
      )}
    >
      {isString(title) ? <h1 className="mr-3">{title}</h1> : title}
      <div>{children}</div>
    </section>
  );
}

ApplicationTabPane.propTypes = {
  className: PropTypes.string,
  tabId: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.any,
};

ApplicationTabPane.defaultProps = {
  className: '',
  tabId: '',
  children: null,
};

function DetailBody({ tabs, selectedTab, onSelectTab, ...props }) {
  return (
    <div className="application-detail__body">
      <Nav
        onClick={onSelectTab}
        className="sub-navigation"
        activeNavId={selectedTab}
      >
        {tabs.filter(visibleFor(props)).map(({ id, label }) => (
          <Nav.Item key={id} navId={id}>
            <Nav.Link>{label}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tabs activeTabId={selectedTab}>
        {tabs
          .filter(visibleFor(props))
          .map(
            ({
              id,
              label,
              hideTitle,
              withChannelLink = false,
              component: Component = 'span',
              withProps = emptyObject,
            }) => {
              let title = hideTitle ? undefined : label;

              if (withChannelLink) {
                const {
                  application: { channel },
                } = props;

                title = (
                  <div className="d-flex align-items-center mb-3">
                    <h1 className="mr-3">{label}</h1>
                    <ExternalLink className="mr-3" href={channel.data.url}>
                      {channel.data.url}
                    </ExternalLink>
                    <ExternalLink href={`/channels/${channel.id}`}>
                      Channel Details
                    </ExternalLink>
                  </div>
                );
              }

              return (
                <Tabs.Pane key={id} tabId={id}>
                  <ApplicationTabPane title={title}>
                    <Component {...withProps(props)} />
                  </ApplicationTabPane>
                </Tabs.Pane>
              );
            },
          )}
      </Tabs>
    </div>
  );
}

DetailBody.propTypes = {
  /**
   * Tab with focus when the component is first mounted. If `undefined`, will just
   * default to first tab
   */
  initialTab: PropTypes.string,
  getTabs: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,

  // Internal

  /** @ignore */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      withChannelLink: PropTypes.bool,
      component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      props: PropTypes.object,
    }),
  ).isRequired,
  /** @ignore */
  selectedTab: PropTypes.string.isRequired,
  /** @ignore */
  onSelectTab: PropTypes.func.isRequired,
};

export default compose(withTabs, withTabState)(DetailBody);

export { default as withApplicationDetailContainer } from './withApplicationDetailContainer';
