import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function TitledPage({
  isFluid,
  className,
  heading,
  subHeading,
  description,
  children,
}) {
  return (
    <main
      className={cx(
        'page',
        { container: !isFluid, 'container-fluid': isFluid },
        className,
      )}
    >
      <div className={cx({ row: !isFluid })}>
        <header className="page__header">
          <h1 className="page__heading">{heading}</h1>
          <h2 className="page__sub-heading">{subHeading}</h2>
          <p className="text-muted">{description}</p>
        </header>
        <hr />
      </div>
      <div className={cx({ row: !isFluid })}>{children}</div>
    </main>
  );
}

TitledPage.propTypes = {
  isFluid: PropTypes.bool,
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any,
};

TitledPage.defaultProps = {
  isFluid: false,
  className: '',
  subHeading: '',
  description: '',
  children: [],
};

export const withPage = (Component) => {
  function Paged({
    isFluid,
    className,
    heading,
    subHeading,
    description,
    children,
    ...rest
  }) {
    return (
      <TitledPage
        className={className}
        heading={heading}
        subHeading={subHeading}
        description={description}
        isFluid={isFluid}
      >
        <Component {...rest}>{children}</Component>
      </TitledPage>
    );
  }

  Paged.propTypes = TitledPage.propTypes;

  return Paged;
};

export default TitledPage;
