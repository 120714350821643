import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  pure,
  withHandlers,
  withProps,
  defaultProps,
  compose,
} from 'recompose';
import noop from 'lodash/noop';
import { arrayMove, arrayUnshift, arrayRemove, arraySplice } from 'redux-form';

import CollectionBuilder, {
  propTypes as collectionBuilderPropTypes,
} from 'source/scenes/components/collectionBuilder';

// HoCs

const withArrayUpdaters = withHandlers({
  onAddItem:
    ({ meta: { form }, input: { name }, onArrayUnshift }) =>
    (item) =>
      onArrayUnshift(form, name, item),

  onRemoveItem:
    ({ meta: { form }, input: { name }, onArrayRemove }) =>
    (index) =>
      onArrayRemove(form, name, index),

  onUpdateItem:
    ({ meta: { form }, input: { name }, onArraySplice }) =>
    (index, item) =>
      onArraySplice(form, name, index, 1, item),

  onReorderItem:
    ({ meta: { form }, input: { name }, onArrayMove }) =>
    (fromIndex, toIndex) =>
      onArrayMove(form, name, fromIndex, toIndex),
});

// Maps props to the shape of the CollectionBuilder API
const withPropsProxy = withProps(({ input, getItemId, getItemFormId }) => {
  if (!Array.isArray(input.value)) {
    console.error('Field value must be an array');
    return { items: [] };
  }

  return {
    items: input.value.map((item) => ({
      formId: getItemFormId(item),
      data: item,
      getId: getItemId,
    })),
  };
});

// Components

/**
 * Implements CollectionBuilder as a Redux Form compatible field
 */
function CollectionBuilderInput(props) {
  return <CollectionBuilder {...props} />;
}

CollectionBuilderInput.propTypes = {
  className: PropTypes.string,
  /** Label shown on "Add" button */
  addBtnLabel: PropTypes.string,
  draggable: PropTypes.bool,
  /**
   * Forms used to add or edit items in a collection
   *
   * See CollectionBuilder for details
   *
   * Note that if the item forms are implemented via Redux Form,
   * their onSubmit callback must yield the submitted values to work
   * correctly
   */
  itemForms: collectionBuilderPropTypes.itemForms,
  /**
   * Unique id for item in CollectionBuilder
   */
  getItemId: PropTypes.func,
  /**
   * If multiple item forms specified, this is needed to
   * determine the proper form to use for a item
   */
  getItemFormId: PropTypes.func,
  /**
   * Form initial values when adding a new item
   */
  getNewItemInitialValues: PropTypes.func,
  /**
   * Component used to render items in list
   *
   * See CollectionBuilder for details
   */
  listItemComponent: PropTypes.func,

  // Internal
  /** @ignore */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
  }).isRequired,
};

const CollectionBuilderInputEnhanced = compose(
  pure,
  defaultProps({
    className: '',
    draggable: true,
    addBtnLabel: 'Add',
    getNewItemInitialValues: noop,
    getItemId: (item) => item.id,
    getItemFormId: noop,
    listItemComponent: undefined,
  }),
  connect(null, {
    onArrayMove: arrayMove,
    onArrayUnshift: arrayUnshift,
    onArrayRemove: arrayRemove,
    onArraySplice: arraySplice,
  }),
  withPropsProxy,
  withArrayUpdaters,
)(CollectionBuilderInput);

export default CollectionBuilderInputEnhanced;
