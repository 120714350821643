import { combineReducers, compose } from 'redux';
import { createSelector } from 'reselect';
import pick from 'lodash/pick';
import { loadStateReducer } from '@blogfoster/redux-async-utils';

import { resetableReducer, namespacedReducer } from 'source/utils/redux';
import { actionTypes as campaignActionTypes } from 'source/data/campaigns/actions';

import { namespace, actionTypes } from './actions';

// Selectors

/**
 * NOTE @alexspri
 *    We use a "cached" selector here, as the `pick` function
 *    otherwise always creates a new "object" and this ruins
 *    the pure "shouldComponentUpdate" behaviour.
 *
 * NOTE @sean
 *    Pick out only the loading state
 *
 * TODO @sean
 *    Modify loadStateReducer to optionally do this
 */
const getCampaignFetchState = createSelector(
  // get campaign fetch state
  (state) => state.campaignFetchState,
  // as returned result just pick the necessary props
  (fetchState) => pick(fetchState, ['loaded', 'loading', 'error']),
);

export const selectors = {
  getActiveCampaignId: (state) => state.activeCampaignId,
  getCampaignFetchState,
  getApplicationIds: (state) => state.applicationIds,
};

// Reducers

const campaignFetchState = namespacedReducer(namespace)(
  loadStateReducer(campaignActionTypes.FETCH),
);

const activeCampaignId = (state = null, action) => {
  if (action.type !== actionTypes.SET_ACTIVE_CAMPAIGN) {
    return state;
  }

  return action.payload;
};

export default compose(resetableReducer(actionTypes.RESET_SCENE))(
  combineReducers({
    campaignFetchState,
    activeCampaignId,
  }),
);
