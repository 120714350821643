import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, fieldInputPropTypes, Field } from 'redux-form';

import MarkdownTextAreaTabs from 'source/components/common/markdownTextAreaTabs';

function ReduxFormMarkdownTextArea(props) {
  return (
    <MarkdownTextAreaTabs
      className="form-control mh-24"
      readOnly={!!props.readOnly}
      rows={15}
      {...props.input}
    />
  );
}
ReduxFormMarkdownTextArea.propTypes = {
  ...fieldInputPropTypes.input,
};

const FeedbackForm = React.memo(({ title, description, readOnly }) => (
  <div className="form-group write-review">
    <h3 className="mt-5">{title}</h3>
    <p className="text-muted">{description}</p>
    <div className="pt-1 pb-1">
      <Field
        name="feedback"
        component={ReduxFormMarkdownTextArea}
        readOnly={readOnly}
      />
    </div>
  </div>
));

FeedbackForm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(FeedbackForm);
