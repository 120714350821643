import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { updateItem, findAndReplace } from 'source/utils/imCollection';
import { taskValidator } from 'source/utils/validators';
import Badge from 'source/components/common/badge';

class IndividualTask extends Component {
  constructor() {
    super();
    this.state = {
      taskErrors: {},
    };
  }

  handleTaskControlClick = (e) => {
    const {
      taskIndex,
      application: { id },
      formData: { editingTask, tasks },
      onChange,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    const value = editingTask === taskIndex ? null : taskIndex;

    const task = tasks[taskIndex];
    const valid = taskValidator(task);

    this.setState({
      taskErrors: {
        ...this.state.taskErrors,
        [taskIndex]: valid,
      },
    });

    if (Object.keys(valid).length) {
      return null;
    }

    return onChange(id, 'editingTask', value);
  };

  handleTaskChange = (e) => {
    const {
      task,
      taskIndex,
      application: { id },
      formData: { modifiedTasks = {}, tasks },
      onChange,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    const updatedTask = updateItem(task, e.target.id, e.target.value);
    const updatedTasks = findAndReplace(tasks, updatedTask);

    onChange(id, 'tasks', updatedTasks);
    return onChange(id, 'modifiedTasks', {
      ...modifiedTasks,
      [taskIndex]: true,
    });
  };

  renderStatus() {
    const {
      task,
      taskIndex,
      formData: { modifiedTasks = {} },
    } = this.props;

    const isModified = !!modifiedTasks[taskIndex];
    const isUpdated = !!task.updated;

    /* eslint-disable no-nested-ternary */
    const taskStatus = isModified
      ? 'modified'
      : isUpdated
      ? 'updated'
      : 'pending';
    const badgeType = isModified ? 'warning' : isUpdated ? 'success' : 'danger';
    /* eslint-enable no-nested-ternary */

    return (
      <Badge type={badgeType} pill>
        {taskStatus}
      </Badge>
    );
  }

  renderStaticHeadline() {
    const { task } = this.props;

    return (
      <div className="form-group">
        <textarea
          id="headline"
          className="form-control form-control-sm cursor-not-allowed"
          rows="1"
          value={task.headline}
          onChange={this.handleTaskChange}
          disabled
        />
      </div>
    );
  }

  renderEditingHeadline() {
    const { task, taskIndex } = this.props;
    const { taskErrors } = this.state;

    const error = taskErrors[taskIndex] && taskErrors[taskIndex].headline;

    return (
      <div className="form-group">
        <textarea
          id="headline"
          className={cx('form-control', { 'is-invalid': error })}
          rows="5"
          value={task.headline}
          onChange={this.handleTaskChange}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }

  renderHeadline() {
    const {
      taskIndex,
      formData: { editingTask },
    } = this.props;

    return editingTask === taskIndex
      ? this.renderEditingHeadline()
      : this.renderStaticHeadline();
  }

  renderStaticBody() {
    const { task } = this.props;

    return (
      <div className="form-group">
        <textarea
          id="body"
          className="form-control form-control-sm cursor-not-allowed"
          rows="1"
          value={task.body || ''}
          onChange={this.handleTaskChange}
          disabled
        />
      </div>
    );
  }

  renderEditingBody() {
    const { task, taskIndex } = this.props;
    const { taskErrors } = this.state;

    const error = taskErrors[taskIndex] && taskErrors[taskIndex].body;

    return (
      <div className="form-group">
        <textarea
          id="body"
          className={cx('form-control', { 'is-invlaid': error })}
          rows="5"
          value={task.body || ''}
          onChange={this.handleTaskChange}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }

  renderBody() {
    const {
      taskIndex,
      formData: { editingTask },
    } = this.props;

    return editingTask === taskIndex
      ? this.renderEditingBody()
      : this.renderStaticBody();
  }

  renderControls() {
    const {
      taskIndex,
      formData: { editingTask },
    } = this.props;
    const controlButtonText = editingTask === taskIndex ? 'next' : 'edit';

    return (
      <button
        type="submit"
        className="btn btn-outline-secondary btn-sm pull-right"
        onClick={this.handleTaskControlClick}
      >
        {controlButtonText}
      </button>
    );
  }

  render() {
    const { task } = this.props;

    return (
      <tr>
        <td className="task-status">{this.renderStatus()}</td>
        <td className="task-type">{task.type}</td>
        <td className="task-headline">{this.renderHeadline()}</td>
        <td className="task-body">{this.renderBody()}</td>
        <td className="task-controls">{this.renderControls()}</td>
      </tr>
    );
  }
}

IndividualTask.propTypes = {
  task: PropTypes.shape({
    list: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    body: PropTypes.string,
    updated: PropTypes.bool,
    type: PropTypes.oneOf(['text', 'link', 'image', 'code']),
  }).isRequired,
  taskIndex: PropTypes.number.isRequired,
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tasks: PropTypes.array.isRequired,
  }).isRequired,
  formData: PropTypes.shape({
    editingTask: PropTypes.number,
    tasks: PropTypes.array,
    modifiedTasks: PropTypes.object,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IndividualTask;
