import React from 'react';
import PropTypes from 'prop-types';

class ChannelsTableSummary extends React.PureComponent {
  render() {
    const {
      form: { channels },
    } = this.props;

    if (channels.length === 0) {
      return null;
    }

    let totalReach = channels.reduce(
      (total, { reach }) => total + (reach.max || reach.min),
      0,
    );
    totalReach = Math.round(totalReach / 1000);

    const totalPostings = channels.reduce(
      (total, channel) => total + Number(channel.numberOfPostings),
      0,
    );
    const totalBonus = channels.reduce(
      (total, channel) =>
        total + Number(channel.bonus) * Number(channel.numberOfPostings),
      0,
    );

    const totalCosts = channels.reduce(
      (total, channel) =>
        total + channel.price * Number(channel.numberOfPostings),
      0,
    );

    return (
      <tr>
        <td className="col-bucket" />
        <td className="col-channel" />
        <td className="col-reach">
          <strong>
            {totalReach}
            <span>K / Month</span>
          </strong>
        </td>
        <td className="col-postings">
          <strong>{totalPostings}</strong>
        </td>
        <td className="col-bonus">
          <strong>
            {totalBonus}
            <span className="ml-1">€</span>
          </strong>
        </td>
        <td className="col-costs">
          <strong>
            {totalCosts + totalBonus}
            <span className="ml-1">€</span>
          </strong>
        </td>
        <td className="col-details" />
      </tr>
    );
  }
}

ChannelsTableSummary.propTypes = {
  form: PropTypes.shape({
    channels: PropTypes.arrayOf(
      PropTypes.shape({
        bonus: PropTypes.number.isRequired,
        reach: PropTypes.shape({
          min: PropTypes.number,
          max: PropTypes.number,
        }),
        price: PropTypes.number.isRequired,
        numberOfPostings: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default ChannelsTableSummary;
