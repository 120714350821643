import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { combineReducers } from 'redux';
import { createStructuredSelector, createSelector } from 'reselect';
import querystring from 'qs';
import moment from 'moment';
import { loadStateReducer } from '@blogfoster/redux-async-utils';
import { compose, setPropTypes, lifecycle } from 'recompose';

import config from 'config';
import { namespacedReducer, resetableReducer } from 'source/utils/redux';
import {
  campaignReportFetch,
  campaignReportClear,
  actionTypes as reportActionTypes,
} from 'source/data/reports/actions';
import { applicationFetch } from 'source/data/applications/actions';
import { getCampaignReport, getCampaign } from 'source/data/selectors';

import { CampaignReporting } from './components';

/* == actions == */

const namespace = 'scenes/campaignDetail/reporting/websiteBucket';
const loadApplicationRefferrers = applicationFetch(namespace);
const loadCampaignReporting = campaignReportFetch(namespace);
const clearCampaignReporting = campaignReportClear(namespace);

const actions = {
  onLoadCampaignReporting(campaignId, { startDate, endDate } = {}) {
    const filters = [
      { start: startDate.toJSON() },
      { end: endDate.toJSON() },
      { enhancedArticleReporting: true },
    ];

    return loadCampaignReporting(campaignId, { filters });
  },
  onLoadApplicationReferrers(applicationId, { startDate, endDate } = {}) {
    const filters = [];
    const referrer = `contentReferrers?${querystring.stringify({
      start: startDate,
      end: endDate,
    })}`;
    const fields = ['id', referrer];

    return loadApplicationRefferrers(applicationId, { filters, fields });
  },

  onClearCampaignReporting: (campaignId) => (dispatch) => {
    dispatch(clearCampaignReporting(campaignId));
    dispatch({ name: namespace, type: `${namespace}/RESET_SCENE` });
  },
};

/* == reducer == */

export const reducer = namespacedReducer(namespace)(
  resetableReducer(`${namespace}/RESET_SCENE`)(
    combineReducers({
      reportAsync: loadStateReducer(reportActionTypes.FETCH),
    }),
  ),
);

/* == selectors== */

const getAccessToken = (state) => state.account.accessToken;
const getReportAsync = (state) =>
  state.scenes.campaignDetail.reporting.reportAsync;

const getTransformedCampaignReports = createSelector(
  getCampaignReport,
  (report) => {
    if (!report) {
      return report;
    }

    const setTotalsToNullIfReportIsInvalid = (report) => {
      // We assume for now that whenever we receive '0' as any performance value for an article, the article report is invalid
      const { pageviews, uniqueVisitors, duration } = report.totals;
      const isInvalid = [pageviews, uniqueVisitors, duration].some(
        (value) => value === 0,
      );

      if (isInvalid) {
        return {
          ...report,
          totals: {
            ...report.totals,
            pageviews: null,
            visits: null,
            uniqueVisitors: null,
            cpr: null,
            duration: null,
          },
        };
      }
      return report;
    };

    report = setTotalsToNullIfReportIsInvalid(report);
    report.byPost = report.byPost.map(setTotalsToNullIfReportIsInvalid);

    return report;
  },
);

const getInitialReportingDateRange = createSelector(
  getCampaign,
  (campaign) => ({
    startDate: moment(campaign.timeline.publishStart).subtract(10, 'days'),
    endDate: moment(campaign.timeline.publishEnd).add(90, 'days'),
    enhancedArticleReporting: false,
  }),
);

const getReportingUrls = createSelector(
  getAccessToken,
  getCampaign,
  getCampaignReport,
  getInitialReportingDateRange,
  (accessToken, campaign, report, initialValues) => {
    // We try to compute the download URL's based on the currently loaded
    // reporting data. If none was loaded, we take the initial date range.
    const start = (report && report.start) || initialValues.startDate.toJSON();
    const end = (report && report.end) || initialValues.endDate.toJSON();
    const filters = [{ start }, { end }, { enhancedArticleReporting: true }];

    const getQueryByReportType = (reportType) =>
      querystring.stringify({
        access_token: accessToken,
        filters: JSON.stringify([...filters, { reportBy: reportType }]),
      });

    const getUrlByReportType = (reportType) =>
      `${config.api.url}/v2/reports/campaigns/${
        campaign.id
      }.xlsx?${getQueryByReportType(reportType)}`;

    const getCampaignReport = (reportType) =>
      `${config.api.url}/v2/reports/campaigns/${
        campaign.id
      }/${reportType}.xlsx?${querystring.stringify({
        access_token: accessToken,
        filters: JSON.stringify([{ start }, { end }]),
      })}`;

    return {
      byPost: getUrlByReportType('post'),
      byDate: getUrlByReportType('date'),
      byLink: getUrlByReportType('link'),
      byPostMetrics: getCampaignReport('post-metrics'),
    };
  },
);

const selector = createStructuredSelector({
  accessToken: getAccessToken,
  reportAsync: getReportAsync,
  report: getTransformedCampaignReports,
  campaign: getCampaign,
  initialReportingDateRange: getInitialReportingDateRange,
  reportingUrls: getReportingUrls,
});

/* == container component */

const withLifecyle = compose(
  setPropTypes({
    campaign: PropTypes.shape({
      id: PropTypes.string.isRequired,
      timeline: PropTypes.shape({}).isRequired,
    }).isRequired,
    initialReportingDateRange: PropTypes.shape({
      // moment date objects
      startDate: PropTypes.object.isRequired,
      endDate: PropTypes.object.isRequired,
    }).isRequired,
    onLoadCampaignReporting: PropTypes.func.isRequired,
  }),
  lifecycle({
    // load reporting initially once the component mounts
    componentDidMount() {
      const { campaign, initialReportingDateRange, onLoadCampaignReporting } =
        this.props;

      onLoadCampaignReporting(campaign.id, initialReportingDateRange);
    },

    // cleanup the state once the component unmounts
    componentWillUnmount() {
      const {
        campaign: { id: campaignId },
        onClearCampaignReporting,
      } = this.props;

      return onClearCampaignReporting(campaignId);
    },
  }),
);

const enhance = compose(connect(selector, actions), withLifecyle);

export default enhance(CampaignReporting);
