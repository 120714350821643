import { push } from 'connected-react-router';

import actionTypes from 'source/constants/actionTypes';
import { auth } from 'source/utils/auth';

export const handleGenericRequestError = (error) => ({
  type: actionTypes.GLOBAL_GENERIC_REQUEST_ERROR,
  payload: error,
});

export const handleServerRequestError = (resp, message) => ({
  type: actionTypes.GLOBAL_SERVER_REQUEST_ERROR,
  payload: { resp, message },
});

export const genericRequestErrorHappened = (error) => (dispatch) => {
  dispatch(handleGenericRequestError(error));
  dispatch(push('/we-are-having-problems'));
};

export const unauthorizedRequestHappend = (resp) => (dispatch) => {
  auth.reset();

  dispatch(handleServerRequestError(resp.data, 'Request unauthorized'));
  dispatch(push('/signin'));
};

export const serverErrorRequestErrorHappened = (resp) => (dispatch) => {
  dispatch(handleServerRequestError(resp.data, 'Request server error'));
  dispatch(push('/we-are-having-problems'));
};
