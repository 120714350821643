import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import withResetScene from 'source/components/commonEnhancers/withResetScene';
import actions from '../actions';
import { getGroupDetailsState } from '../selectors';
import GroupDetailsWrapper from './groupDetailsWrapper';

const enhance = compose(connect(getGroupDetailsState, actions), withResetScene);

class GroupDetailsRoot extends Component {
  componentDidMount() {
    this.loadGroup();
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname: prevPathname },
    } = prevProps;
    const {
      location: { pathname },
    } = this.props;

    if (prevPathname !== pathname) {
      this.loadGroup();
    }
  }

  componentWillUnmount() {
    this.props.onAlertClose();
  }

  loadGroup = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.props.onLoadGroup(id);
  };

  render() {
    const { state, group, onAlertClose, alert, groupName } = this.props;

    return (
      <div>
        <GroupDetailsWrapper
          alert={alert}
          state={state}
          group={group}
          onAlertClose={onAlertClose}
          groupName={groupName}
        >
          {this.props.children}
        </GroupDetailsWrapper>
      </div>
    );
  }
}

GroupDetailsRoot.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  state: PropTypes.string.isRequired,
  group: PropTypes.object.isRequired,
  groupName: PropTypes.string.isRequired,
  onLoadGroup: PropTypes.func.isRequired,
  alert: PropTypes.object,
  onAlertClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};

export default enhance(GroupDetailsRoot);
