import * as Sentry from '@sentry/browser';

import { actionAuthRequest, requestFlow } from 'source/utils/axios';
import actionTypes from 'source/constants/actionTypes';
import mixpanel from 'source/utils/mixpanel';
import config from 'config';

export const loadAppState = () => (dispatch, getState) => {
  const onBefore = () => {
    dispatch({ type: actionTypes.APP_STATE_LOADING });
  };

  const onSuccess = (resp) => {
    dispatch({ type: actionTypes.APP_STATE_LOADED, payload: resp.data });
    if (!config.sentry.disabled) {
      Sentry.setUser({
        email: resp.data.email,
      });
    }
    // Associate all future events sent from the library with the unique
    // Google Id.
    mixpanel.identify(resp.data.sub);
  };

  const onError = (resp) => {
    dispatch({ type: actionTypes.APP_STATE_LOADING_FAILED, payload: resp });
  };

  const request = () =>
    actionAuthRequest(dispatch, getState).get('/v1/users/me');

  return requestFlow(request, { onBefore, onSuccess, onError });
};
