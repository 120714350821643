import { push } from 'connected-react-router';
import traverse from 'traverse';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import get from 'lodash/get';
import {
  channelsFetchMany,
  channelFetch,
  channelPatch,
  channelDelete,
  channelUpdateKpi,
  channelUpdateKeyMetrics,
  channelRefreshKeyMetrics,
} from 'source/data/channels/actions';
import { redux as collapsiblePanelRedux } from 'source/components/common/collapsiblePanel';
import { getChannelState } from './selectors';

export const namespace = 'scenes/channels';

const fetchChannelsAction = channelsFetchMany(namespace);
const fetchChannelAction = channelFetch(namespace);
const patchChannelAction = channelPatch(namespace);
const deleteChannelAction = channelDelete(namespace);
const updateChannelKpiAction = channelUpdateKpi(namespace);
const updateChannelKeyMetricsAction = channelUpdateKeyMetrics(namespace);

export const actionTypes = {
  RESET_SCENE: `${namespace}/RESET_SCENE`,
  FILTER_CHANGED: `${namespace}/FILTER_CHANGED`,
  CHANNEL_FORM_CHANGED: `${namespace}/CHANNEL_FORM_CHANGED`,
  CHANNEL_ALERT_CHANGED: `${namespace}/CHANNEL_ALERT_CHANGED`,
};

const loadChannelDetails = (channelId) => (dispatch) => {
  const fields = [
    'id',
    'name',
    'platform',
    'extension',
    'referenceChannel.id',
    'referenceChannel.data.id',
    'qualityReview',
    'readyState',
    'ready',
    'data',
    'kpi',
    'keyMetrics',
    'keyMetricsCache',
    'user.id',
    'user.firstname',
    'screenshots',
    'notes',
    'updatedAt',
    'avatarUrl',
  ];

  return dispatch(fetchChannelAction(channelId, { fields }));
};

const loadChannels =
  (page = 0) =>
  (dispatch, getState) => {
    const {
      scenes: {
        channels: { filters: formData = {} },
      },
    } = getState();

    const filters = [];
    const fields = [
      'id',
      'user.id',
      'user.firstname',
      'name',
      'platform',
      'qualityReview',
      'data',
      'ready',
      'readyState',
      'createdAt',
      'avatarUrl',
    ];
    const sort = { createdAt: -1 };

    if (formData.term && formData.term.length > 0) {
      filters.push(
        ...[
          { or: { name: { match: formData.term } } },
          { or: { 'data.url': { match: formData.term } } },
          { or: { 'data.name': { match: formData.term } } },
          { or: { 'data.accountName': { match: formData.term } } },
        ],
      );
      const websiteId = parseInt(formData.term, 10);
      if (!isNaN(websiteId)) {
        filters.push({ or: { websiteId: parseInt(formData.term, 10) } });
      }
    }

    return dispatch(fetchChannelsAction({ page, fields, filters, sort }));
  };

const changeFilter = (term) => (dispatch) =>
  dispatch({
    type: actionTypes.FILTER_CHANGED,
    payload: { filter: 'term', value: term },
  });

const patchChannelDetails = (values) => (dispatch, getState) => {
  const patch = pick(values, ['qualityReview', 'data', 'notes']);

  // invert qualityReview in payload before patching
  if (typeof patch.qualityReview === 'boolean') {
    patch.qualityReview = !patch.qualityReview;
  }

  const {
    scenes: {
      channels: { channel },
    },
  } = getState();

  return dispatch(patchChannelAction(channel.id, patch));
};

const patchChannelInsights = (kpi) => (dispatch, getState) => {
  // strip kpi object from empty-string value fields

  // eslint-disable-next-line prefer-arrow-callback
  traverse(kpi).forEach(function (node) {
    if (this.isLeaf && !node) {
      this.remove();
    }
  });

  const {
    scenes: {
      channels: { channel },
    },
  } = getState();

  return dispatch(updateChannelKpiAction(channel.id, kpi));
};

const patchChannelKeyMetrics = (payload) => (dispatch, getState) => {
  const { channel } = getState().scenes.channels;
  // strip keyMetrics from readonly fields
  const payloadWithoutReadOnly = omit(payload, [
    'followersHistory',
    'postImpressionMedian',
    'postImpressionRatio',
    'storyImpressionMedian',
    'storyImpressionRatio',
    'viewsPerVideoMedian',
    'topCountry',
  ]);

  // strip keyMetrics from empty-string value fields
  // eslint-disable-next-line prefer-arrow-callback
  traverse(payloadWithoutReadOnly).forEach(function (node) {
    if (this.isLeaf && !node) {
      this.remove();
    }
  });

  return dispatch(
    updateChannelKeyMetricsAction(channel.id, payloadWithoutReadOnly),
  );
};

const refreshChannelKeyMetrics = () => (dispatch, getState) => {
  const { channel } = getState().scenes.channels;

  return dispatch(channelRefreshKeyMetrics(channel.id));
};

const deleteChannel = (params) => (dispatch, getState) => {
  const {
    scenes: {
      channels: { channel },
    },
  } = getState();

  return dispatch(deleteChannelAction(channel.id, params)).then(() =>
    dispatch(push('/channels')),
  );
};

const deleteChannelScreenshot = (screenshotName) => (dispatch, getState) => {
  const currentChannel = getChannelState(getState());

  if (!get(currentChannel, `screenshots.${screenshotName}`)) {
    return null;
  }

  return dispatch(
    patchChannelAction(currentChannel.id, {
      screenshots: {
        [screenshotName]: null,
      },
    }),
  );
};

const selectPanel = collapsiblePanelRedux.toggleActionCreator(namespace);

const resetScene = () => ({ type: actionTypes.RESET_SCENE });

const closeAlert = () => (dispatch) =>
  dispatch({ type: actionTypes.CHANNEL_ALERT_CHANGED, alert: null });

const actions = {
  onMount: loadChannels,
  onLoadChannel: loadChannelDetails,
  onDeleteChannel: deleteChannel,
  onSelectPage: loadChannels,
  onFilterChange: changeFilter,
  onResetScene: resetScene,
  onChannelFormSubmit: patchChannelDetails,
  onChannelInsightsFormSubmit: patchChannelInsights,
  onChannelKeyMetricsFormSubmit: patchChannelKeyMetrics,
  onDeleteChannelScreenshot: deleteChannelScreenshot,
  onRefreshMetrics: refreshChannelKeyMetrics,
  onAlertClose: closeAlert,
  onPanelClick: selectPanel,
};

export default actions;
