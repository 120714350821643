import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { ProgressBar, SearchBar } from 'source/components/common';
import { usersListActions } from 'source/scenes/users/actions';
import { usersListSelector } from 'source/scenes/users/selectors';
import UsersTable from 'source/scenes/users/list/usersTable';
import withResetScene from 'source/components/commonEnhancers/withResetScene';

const enhance = compose(
  connect(usersListSelector, usersListActions),
  withResetScene,
);

class UsersList extends React.Component {
  componentDidMount() {
    this.props.loadUsers();
  }

  handleSearch = () => this.props.loadUsers(0);

  render() {
    const { users, filters, state, counter, page, pages } = this.props;

    return (
      <div className="container">
        <div className="row pt-3">
          <SearchBar
            className="col"
            query={filters.term}
            placeholder="Filter users by id, firstname or email"
            onInputChange={this.props.changeSearchFilter}
            onSubmit={this.handleSearch}
          />
        </div>

        <div className="row pt-5">
          {state !== 'loaded' ? (
            <ProgressBar.Mega />
          ) : (
            <div className="col">
              <h3 className="pb-3">
                Users
                <span>
                  ({counter.count}/{counter.total})
                </span>
              </h3>
              <UsersTable
                users={users}
                pages={pages}
                page={page}
                onSelectPage={this.props.loadUsers}
                onLoginClick={this.props.loginUser}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

UsersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  state: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  counter: PropTypes.shape({
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  filters: PropTypes.shape({
    term: PropTypes.string,
  }),
  loadUsers: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  changeSearchFilter: PropTypes.func.isRequired,
};

export default enhance(UsersList);
