import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'recompose';
import get from 'lodash/get';

import { Input, Select } from 'source/scenes/components/reduxFormAdapters';
import Alert from 'source/components/common/alert';

import selector from './selectors';
import validate from './validation';
import { withSubmissionHandler } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';

const enhance = compose(
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
  reduxForm({
    validate,
    touchOnBlur: false,
    touchOnChange: true,
    enableReinitialize: true,
  }),
);

/**
 * Renders the 1st form to enter all information necessary to setup
 * an initial campaign.
 */
function InitForm({
  handleSubmit,
  submitting,
  invalid,
  error,
  disableLanguageSelect,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Alert message={get(error, 'message')} />
      <Field
        name="name"
        component={Input}
        label="Name your campaign*:"
        placeholder="Campaign name"
      />
      <Field
        name="language"
        disabled={disableLanguageSelect}
        component={Select}
        label="Campaign Language:"
      >
        <option value="de">Deutsch</option>
        <option value="en">English</option>
      </Field>

      <div className="row justify-content-end pr-3">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={invalid || submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
}

InitForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.object,
  disableLanguageSelect: PropTypes.bool.isRequired,
};

InitForm.defaultProps = {
  error: undefined,
};

export default enhance(InitForm);
