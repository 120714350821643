import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import omit from 'lodash/omit';
import kebabCase from 'lodash/kebabCase';
import { FormattedNumber } from 'react-intl';

import Bar from 'source/scenes/components/bar';
import LabeledMetric from 'source/scenes/components/labeledMetric';

const getCampaignGoals = ({
  campaign: {
    type,
    settings,
    bonuses,
    contract: { budget, reach, pricingMultiplier = {} },
  },
}) => [
  {
    label: 'Client Review',
  },
  {
    label: 'Multiplier',
    value: pricingMultiplier.active ? pricingMultiplier.coefficient : 0,
  },
  {
    label: 'Bonus',
    value: bonuses.fixed,
    percentage: bonuses.variablePercentage,
    formatting: {
      style: 'currency',
      currency: settings.currency,
    },
  },
  {
    label: 'Budget',
    value: budget,
    formatting: {
      style: 'currency',
      currency: settings.currency,
    },
  },
  {
    label: {
      instagram: 'Followers',
      tiktok: 'Followers',
      pinterest: 'Impressions',
      youtube: 'Subscribers',
      website: 'Page Views',
    }[type],
    value: reach,
  },
  {
    label: 'CPR',
    skip: type !== 'website',
  },
];

function UnformattedValue({ value }) {
  return <span>{value}</span>;
}
UnformattedValue.propTypes = { value: PropTypes.any.isRequired };

const renderCampaignGoals = ({ goals }) =>
  goals.map(({ value, percentage, label, formatting = {}, skip }) => {
    if (skip) {
      return null;
    }
    const Label = formatting.component || FormattedNumber;
    const labelProps = omit(formatting, ['component']);
    const variablePercentage = percentage
      ? ` + ${percentage * 100}%`
      : undefined;

    return (
      <Bar.Item
        key={label}
        className={cx(
          'campaign-goals__metrics',
          `campaign-goals__metrics__${kebabCase(label)}`,
        )}
      >
        {value || percentage ? (
          <LabeledMetric
            className="align-right"
            value={
              <span>
                <Label {...labelProps} value={value} />
                {variablePercentage}
              </span>
            }
            label={label}
          />
        ) : null}
      </Bar.Item>
    );
  });

function CampaignGoals({ campaign }) {
  const {
    type,
    contract: { maxPosts },
    timeline: { applicationStart, publishStart, publishEnd },
  } = campaign;

  const goals = getCampaignGoals({ campaign });

  return (
    <section className="campaign-goals">
      <Bar className="campaign-goals">
        <Bar.Item className="campaign-goals__label no-border">
          <div className="ml-3">Targets</div>
        </Bar.Item>
        <Bar.Item className={`campaign-goals__count ${type} no-border`}>
          <div className="count mr-3">{maxPosts}</div>
        </Bar.Item>
        {type === 'instagram' && (
          <>
            <Bar.Item className="campaign-goals__empty instagram no-border" />
            <Bar.Item className="campaign-goals__empty instagram no-border" />
            <Bar.Item className="campaign-goals__empty instagram no-border" />
            <Bar.Item className="campaign-goals__empty instagram no-border" />
          </>
        )}
        {type === 'tiktok' && (
          <>
            <Bar.Item className="campaign-goals__empty tiktok no-border" />
            <Bar.Item className="campaign-goals__empty tiktok no-border" />
          </>
        )}
        {type === 'pinterest' && (
          <Bar.Item className="campaign-goals__empty pinterest no-border" />
        )}
        <Bar.Item className={`campaign-goals__dates ${type}`}>
          <span className="labeled-metric d-inline-block">
            <div className="labeled-metric__metric align-right">
              <span className="gray">
                Start of Applications:{' '}
                <span className="white">
                  {moment(applicationStart).format('YYYY-MM-DD')}
                </span>
              </span>
            </div>
            <div className="labeled-metric__metric">
              <span className="gray">
                Posting:{' '}
                <span className="white">
                  {moment(publishStart).format('YYYY-MM-DD')}
                </span>{' '}
                to{' '}
                <span className="white">
                  {moment(publishEnd).format('YYYY-MM-DD')}
                </span>
              </span>
            </div>
          </span>
        </Bar.Item>
        {renderCampaignGoals({ goals, type })}
        <Bar.Item className="campaign-goals__accept" />
      </Bar>
    </section>
  );
}

CampaignGoals.propTypes = {
  campaign: PropTypes.shape({
    type: PropTypes.string.isRequired,
    contract: PropTypes.shape({
      maxPosts: PropTypes.number,
    }).isRequired,
    timeline: PropTypes.shape({
      applicationStart: PropTypes.string.isRequired,
      publishStart: PropTypes.string.isRequired,
      publishEnd: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CampaignGoals;
