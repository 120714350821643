import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withState, withHandlers } from 'recompose';
import { Link } from 'react-router-dom';
import { getFormValues, change } from 'redux-form';
import Octicon from 'react-octicon';
import { FormattedNumber } from 'react-intl';
import moment from 'moment';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import cx from 'classnames';

import getMessage from 'source/i18n/getMessage';
import WarningButton from 'source/scenes/components/warningButton';
import CopyButton from 'source/components/common/copyButton';

import ContentPreview from './contentPreview';
import ContentReorder from './contentReorder';
import TaskListForm from './taskListForm';
import FeedbackForm from '../components/feedbackForm';
import ClientContentReviewForm from '../components/clientContentReviewForm';

import buildUnfinishedTasksMessage from './buildUnfinishedTasksMessage';
import { getMissionRoute } from '../helpers';

const TASK_LIST_FORM_NAME = 'taskListForm';
const FEEDBACK_FORM_NAME = 'feedbackForm';
const CLIENT_REVIEW_FORM_NAME = 'clientReviewForm';

const feedbackPrefixLineBuilders = {
  de: (updatedAt) => {
    const date = moment(updatedAt).locale('de').format('LLL');
    return `Am ${date}:`;
  },
  en: (updatedAt) => {
    const date = moment(updatedAt).locale('en').format('LLL');
    return `On ${date}:`;
  },
};

const getPrefixLineBuilder = (locale) =>
  feedbackPrefixLineBuilders[locale] || feedbackPrefixLineBuilders.en;

const getDraftFeedbackUpdateMessage = (contentPreview, error) => {
  const kamUpdatedAt = get(contentPreview, 'kam.updatedAt');
  const kamUpdatedBy = get(contentPreview, 'kam.updatedBy');

  const data = get(error, 'data', {});
  const status = get(error, 'data.status', '');

  if (!error) {
    if (!kamUpdatedAt) {
      return null;
    }

    return `Last saved ${moment(kamUpdatedAt).fromNow()} by ${kamUpdatedBy}`;
  }

  const message =
    status === 'submitted'
      ? `There is a newer draft, saved`
      : `${upperFirst(status)}`;

  return `${message} ${moment(data.updatedAt).fromNow()} by ${
    data.updatedBy
  }. ${
    status === 'submitted'
      ? 'You can overwrite it or refresh the page to get it here.'
      : ''
  }`;
};

const getReviewStatusUpdateMessage = (error) => {
  if (!error) {
    return null;
  }

  const data = get(error, 'data', {});
  const status = get(error, 'data.status', '');

  const message =
    status === 'submitted'
      ? `There is a newer draft, saved`
      : `${upperFirst(status)}`;

  return `${message} ${moment(data.updatedAt).fromNow()} by ${
    data.updatedBy
  }. You can overwrite it or refresh the page to get it here.`;
};

const debounceNotDirty = (func) => (values, dispatch, props) => {
  if (props.dirty) {
    func(values);
  }
};

const enhance = compose(
  withState('showCode', 'setShowCode', false),
  withState('copyTarget', 'setCopyTarget'),
  withState('verificationStatusChanged', 'setVerificationStatusChanged'),
  withState('expanded', 'setExpanded', false),
  withHandlers({
    onToggleExpanded:
      ({ setExpanded, expanded }) =>
      () =>
        setExpanded(!expanded),
    onCodeView:
      ({ setShowCode }) =>
      () =>
        setShowCode(true),
    onHtmlView:
      ({ setShowCode }) =>
      () =>
        setShowCode(false),
    onVerificationStatusChanged: ({ setVerificationStatusChanged }) =>
      setVerificationStatusChanged,
    onClipboardTargetRef: ({ setCopyTarget }) => setCopyTarget,
    getCopyTarget:
      ({ copyTarget }) =>
      () =>
        copyTarget,
  }),
  connect(
    (state) => ({
      taskListFormValues: getFormValues(TASK_LIST_FORM_NAME)(state),
      feedbackFormValues: getFormValues(FEEDBACK_FORM_NAME)(state),
    }),
    (dispatch) => ({
      changeFormField: (formName, field, value) =>
        dispatch(change(formName, field, value)),
    }),
  ),
);

class PreviewReviewManager extends React.Component {
  constructor(props) {
    super(props);

    this.timeStamp = new Date();
    this.previewContainerRef = React.createRef();
    this.state = { mediaRefs: {}, draftFeedbackUpdateError: null };
    this.state = { mediaRefs: {}, reviewStatusUpdateError: null };
  }

  static getDerivedStateFromProps(nextProps) {
    const media = get(nextProps, 'selectedContentPreview.content.media');
    if (!media) {
      return null;
    }
    return {
      mediaRefs: media.reduce((refs, item) => {
        refs[item.url] = React.createRef();
        return refs;
      }, {}),
    };
  }

  setVerificationStatus = (status, options = {}) => {
    const {
      feedbackFormValues,
      onVerificationStatusChanged,
      selectedContentPreview: {
        kam: { updatedAt = this.timeStamp },
      },
    } = this.props;

    onVerificationStatusChanged('pending');

    return this.updateVerification({
      status,
      note: feedbackFormValues.feedback,
      updatedAt: options.overwrite ? new Date() : updatedAt,
    }).then((response) => {
      if (response.status && response.status === 200) {
        onVerificationStatusChanged('success');
        this.setState({
          ...this.state,
          reviewStatusUpdateError: null,
          draftFeedbackUpdateError: null,
        });
        return null;
      }
      if (response.statusCode === 409) {
        this.setState({
          ...this.state,
          reviewStatusUpdateError: response,
          draftFeedbackUpdateError: null,
        });
        if (response.data.status === 'submitted') {
          onVerificationStatusChanged();
          return response;
        }
        onVerificationStatusChanged('success');
        return { ...response, abort: true };
      }

      onVerificationStatusChanged('error');
      return null;
    });
  };

  getLastFeedbackPrefixLine = () => {
    const {
      application,
      selectedContentPreview,
      campaign: { language },
    } = this.props;

    if (selectedContentPreview.version > 1) {
      const prevContentPreview = application.contentPreviews.find(
        (cp) => cp.version === selectedContentPreview.version - 1,
      );
      const { updatedAt } = get(prevContentPreview, 'kam', {});
      const prefixLineBuilder = getPrefixLineBuilder(language);

      return prefixLineBuilder(updatedAt);
    }
    return '';
  };

  getPreviousVersionFeedback = () => {
    const { application, selectedContentPreview } = this.props;

    if (selectedContentPreview.version > 1) {
      const prefixLine = this.getLastFeedbackPrefixLine();
      const prevContentPreview = application.contentPreviews.find(
        (cp) => cp.version === selectedContentPreview.version - 1,
      );
      const note = prevContentPreview?.kam?.note;

      if (note) {
        const feedback = `\n\n---\n${prefixLine}\n\n${note}`;

        return feedback;
      }
    }

    return '';
  };

  saveFeedbackDraft = (options = {}) => {
    const {
      feedbackFormValues: { feedback },
      onVerificationStatusChanged,
      selectedContentPreview: {
        kam: { updatedAt = this.timeStamp, status },
      },
    } = this.props;

    return this.updateVerification({
      note: feedback,
      status,
      updatedAt: options.overwrite ? new Date() : updatedAt,
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ ...this.state, draftFeedbackUpdateError: null });
      } else if (response.statusCode === 409) {
        this.setState({
          ...this.state,
          draftFeedbackUpdateError: response,
          reviewStatusUpdateError: null,
        });
        if (response.data.status !== 'submitted') {
          onVerificationStatusChanged('success');
          return { ...response, abort: true };
        }

        return response;
      }

      return null;
    });
  };

  reject = (options) => this.setVerificationStatus('rejected', options);

  accept = (options) => this.setVerificationStatus('accepted', options);

  checkTask = debounceNotDirty((values) => {
    const { application } = this.props;
    this.props.onCheckTask(application.id, values.tasksCheckList);
  });

  updateContentPreviewAccess = debounceNotDirty((values) => {
    const { application, selectedContentPreview } = this.props;

    this.props.onUpdateContentPreviewAccess(
      application.id,
      selectedContentPreview.version,
      values.allowClient,
    );
  });

  updateVerification = (payload) => {
    const {
      application,
      selectedContentPreview: { version },
    } = this.props;

    return this.props.onUpdateContentPreviewReview(
      application.id,
      version,
      payload,
    );
  };

  copyUnfinishedTasks = () => {
    const {
      campaign,
      feedbackFormValues,
      taskListFormValues,
      changeFormField,
      selectedContentPreview,
    } = this.props;
    const heading = getMessage(campaign.language, 'unfinishedTasks.heading');
    const unfinishedTasks = taskListFormValues.tasksCheckList
      .filter((task) => task.list === 'tasks') // we only care about posting tasks
      .filter((task) => !task.completed);
    const unfinishedTasksMessage = buildUnfinishedTasksMessage(
      heading,
      unfinishedTasks,
    );
    let { feedback } = feedbackFormValues;

    if (selectedContentPreview.version > 1) {
      const prefixLine = this.getLastFeedbackPrefixLine();
      const prefixLineIndex = feedback.indexOf(`\n\n---\n${prefixLine}`);
      const newFeedback = feedback.substr(0, prefixLineIndex);
      const restOfFeedback = feedback.substring(prefixLineIndex);
      feedback = `${newFeedback}${unfinishedTasksMessage}${restOfFeedback}`;
    } else {
      feedback += unfinishedTasksMessage;
    }

    changeFormField(FEEDBACK_FORM_NAME, 'feedback', feedback);
  };

  scrollToPreview = (id) => {
    const marginGap = 15;
    const element = this.state.mediaRefs[id]?.current;
    const container = this.previewContainerRef.current;
    if (element && container) {
      container.scrollTop = element.offsetTop - marginGap;
    }
  };

  render() {
    const {
      application,
      campaign,
      showCode,
      onCodeView,
      onHtmlView,
      onClipboardTargetRef,
      getCopyTarget,
      taskListFormValues,
      verificationStatusChanged,
      onToggleExpanded,
      onUpdateContentPreviewMedia,
      selectedContentPreview,
      expanded,
    } = this.props;

    /**
     * TODO @alexspri
     *    We want to disable the "copy unfinished tasks" button if all tasks
     *    are checked. The `TaskListForm` internally only renders "posting"
     *    tasks, so we here also then filter for posting tasks.
     */
    const allPostingTasksApproved = taskListFormValues.tasksCheckList
      .filter((task) => task.list === 'tasks')
      .every((task) => task.completed);

    const publicationStatus = application?.publication?.status;
    const disableContentReorder =
      publicationStatus && publicationStatus !== 'pending';

    const draftFeedbackUpdateMessage = getDraftFeedbackUpdateMessage(
      selectedContentPreview,
      this.state.draftFeedbackUpdateError,
    );

    const reviewUpdateMessage = getReviewStatusUpdateMessage(
      this.state.reviewStatusUpdateError,
    );

    const isReadOnly =
      selectedContentPreview.kam.status !== 'submitted' ||
      !!verificationStatusChanged;

    return (
      <div className="row pt-3 pb-3 content-preview">
        <div
          className={`col-md-${expanded ? '12' : '6'} html-preview`}
          ref={this.previewContainerRef}
        >
          <div className="html-preview-header p-3">
            <button
              className="btn btn-outline-primary expand-button float-right"
              onClick={onToggleExpanded}
            >
              <Octicon name={expanded ? 'triangle-left' : 'triangle-right'} />
            </button>
            <div>
              Title:
              <br />
              {selectedContentPreview.content.title || '(no title submitted)'}
            </div>
          </div>
          <ContentReorder
            type={application.type}
            disableReorder={disableContentReorder}
            applicationId={application.id}
            content={selectedContentPreview.content}
            version={selectedContentPreview.version}
            onChange={onUpdateContentPreviewMedia}
            onIndexClick={this.scrollToPreview}
          />
          <ContentPreview
            setRenderedHTMLRef={onClipboardTargetRef}
            showCode={showCode}
            type={application.type}
            content={selectedContentPreview.content}
            mediaRefs={this.state.mediaRefs}
          />
        </div>

        {!expanded && (
          <div className="col-md-6 pt-3 pb-3 management">
            <div className="form-group controls">
              {campaign.type === 'website' ? (
                <span>
                  {showCode ? (
                    <button className="btn btn-primary" onClick={onHtmlView}>
                      Show Content
                    </button>
                  ) : (
                    <span>
                      <button className="btn btn-primary" onClick={onCodeView}>
                        Show Code
                      </button>
                      <CopyButton
                        className="big-copy-btn"
                        title="Copy link"
                        getTarget={getCopyTarget}
                      />
                    </span>
                  )}
                </span>
              ) : null}

              <div className="float-right">
                {campaign.type === 'website' ? (
                  <div className="word-counter">
                    <strong>
                      <FormattedNumber
                        value={selectedContentPreview.content.articleWordsCount}
                      />
                    </strong>{' '}
                    Words
                  </div>
                ) : null}
                <Link
                  className="btn btn-outline-secondary"
                  to={getMissionRoute({ campaign, application })}
                  role="button"
                >
                  Close Review
                </Link>
              </div>
            </div>

            <div className="form-group tasks-management">
              <h3 className="mt-5">Step 1: Check posting tasks</h3>
              <p className="text-muted">
                Unfinished tasks are shown to the influencer in the User App
                after reject.
              </p>
              <div className="pt-1 pb-1">
                <TaskListForm
                  form={TASK_LIST_FORM_NAME}
                  initialValues={{ tasksCheckList: application.tasksCheckList }}
                  onChange={this.checkTask}
                />
                <div className="clearfix">
                  <button
                    className="btn btn-primary float-right"
                    onClick={this.copyUnfinishedTasks}
                    disabled={allPostingTasksApproved}
                  >
                    Copy Unfinished Tasks
                  </button>
                </div>
              </div>
            </div>

            <ClientContentReviewForm
              title="Step 2: Client Review (Optional)"
              description="Allow client to review the content in Business Manager interface. Their Accept/Reject decision and Note are only visible here and not shared with Influencer."
              clientContentReview={selectedContentPreview.client || {}}
              initialValues={{
                allowClient: selectedContentPreview.access.allowClient,
              }}
              onChange={this.updateContentPreviewAccess}
              form={CLIENT_REVIEW_FORM_NAME}
            />

            <FeedbackForm
              title="Step 3: Accept or Reject"
              description="Review notes are shown to the influencer in the User App after rejection."
              initialValues={{
                feedback:
                  selectedContentPreview.kam.note ||
                  this.getPreviousVersionFeedback() ||
                  '',
              }}
              form={FEEDBACK_FORM_NAME}
              readOnly={isReadOnly}
            />

            <div className="form-group">
              <p className="text-muted text-right">
                Did you copy unfinished tasks?
              </p>
              <div className="d-flex align-items-start">
                <WarningButton
                  className="draft-feedback-button"
                  initialClassName="btn-outline-secondary"
                  doneClassName="btn-outline-secondary"
                  error={this.state.draftFeedbackUpdateError}
                  onClick={this.saveFeedbackDraft}
                  onForceClick={() =>
                    this.saveFeedbackDraft({ overwrite: true })
                  }
                  disabled={isReadOnly}
                  warning="Wait"
                  danger="Overwrite"
                >
                  Save Draft
                </WarningButton>
                <div
                  className={cx('draft-feedback-info flex-1 mx-3', {
                    'text-muted':
                      !this.state.draftFeedbackUpdateError &&
                      !this.state.reviewStatusUpdateError,
                    'text-danger':
                      this.state.reviewStatusUpdateError ||
                      this.state.draftFeedbackUpdateError,
                  })}
                >
                  {(
                    reviewUpdateMessage ||
                    draftFeedbackUpdateMessage ||
                    ''
                  ).replace('in a', 'a')}
                </div>
                <div className="d-flex">
                  <WarningButton
                    withConfirm
                    withDone
                    className="mr-3"
                    onClick={this.accept}
                    onForceClick={() => this.accept({ overwrite: true })}
                    error={this.state.reviewStatusUpdateError}
                    disabled={isReadOnly}
                    initialClassName="btn-success"
                    danger="Overwrite"
                  >
                    Accept Review
                  </WarningButton>
                  <WarningButton
                    withConfirm
                    withDone
                    onClick={this.reject}
                    onForceClick={() => this.reject({ overwrite: true })}
                    error={this.state.reviewStatusUpdateError}
                    disabled={isReadOnly}
                    initialClassName="btn-danger"
                    danger="Overwrite"
                  >
                    Reject Review
                  </WarningButton>
                </div>
              </div>

              {verificationStatusChanged === 'error' && (
                <p className="pt-3 text-danger text-right">
                  ✖ Something went wrong
                </p>
              )}
            </div>

            <div className="form-group">
              <Link
                className="btn btn-outline-secondary float-right"
                to={getMissionRoute({ campaign, application })}
                role="button"
              >
                Close Review
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PreviewReviewManager.propTypes = {
  // props
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    publication: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }).isRequired,
    tasksCheckList: PropTypes.arrayOf(
      PropTypes.shape({
        completed: PropTypes.bool,
        list: PropTypes.oneOf(['preTasks', 'tasks', 'postTasks']),
      }),
    ).isRequired,
    contentPreviews: PropTypes.arrayOf(
      PropTypes.shape({
        version: PropTypes.number.isRequired,
        kam: PropTypes.shape({
          note: PropTypes.string,
          updatedAt: PropTypes.string,
          updatedBy: PropTypes.string,
        }).isRequired,
      }),
    ),
  }).isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    pricing: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  selectedContentPreview: PropTypes.shape({
    version: PropTypes.number.isRequired,
    content: PropTypes.shape({
      title: PropTypes.string,
      articleWordsCount: PropTypes.number,
      media: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          type: PropTypes.oneOf(['image', 'video']).isRequired,
          filename: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    kam: PropTypes.shape({
      status: PropTypes.oneOf(['pending', 'submitted', 'accepted', 'rejected']),
      updatedBy: PropTypes.string,
      updatedAt: PropTypes.string,
      note: PropTypes.string,
    }).isRequired,
    client: PropTypes.shape({}),
    access: PropTypes.shape({
      allowClient: PropTypes.bool.isRequired,
    }).isRequired,
  }),
  taskListFormValues: PropTypes.shape({
    tasksCheckList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        list: PropTypes.oneOf(['preTasks', 'tasks', 'postTasks']),
        completed: PropTypes.bool,
      }),
    ).isRequired,
  }),
  feedbackFormValues: PropTypes.shape({
    feedback: PropTypes.string.isRequired,
  }),
  // state
  showCode: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  verificationStatusChanged: PropTypes.string,
  // handlers
  onCodeView: PropTypes.func.isRequired,
  onToggleExpanded: PropTypes.func.isRequired,
  onHtmlView: PropTypes.func.isRequired,
  onClipboardTargetRef: PropTypes.func.isRequired,
  getCopyTarget: PropTypes.func.isRequired,
  onCheckTask: PropTypes.func.isRequired,
  onUpdateContentPreviewAccess: PropTypes.func.isRequired,
  onUpdateContentPreviewReview: PropTypes.func.isRequired,
  onUpdateContentPreviewMedia: PropTypes.func.isRequired,
  onVerificationStatusChanged: PropTypes.func.isRequired,
  // redux bound actions
  changeFormField: PropTypes.func.isRequired,
};

PreviewReviewManager.defaultProps = {
  taskListFormValues: { tasksCheckList: [] },
  feedbackFormValues: { feedback: '' },
  verificationStatusChanged: undefined,
};

export default enhance(PreviewReviewManager);
