import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';

import {
  withSubmissionValidation,
  withNextPanelAfterSubmission,
} from '../../enhancers';

const submitCategories =
  ({ onUpdateCampaign }) =>
  (values, __, props) => {
    const {
      campaign: { id: campaignId },
    } = props;

    const toPayload = () =>
      // If we have an empty whitelist, just "null" the category key
      values.categories.whitelist.length === 0 ? { categories: {} } : values;

    const patchCampaign = (payload) => onUpdateCampaign(campaignId, payload);

    return Promise.resolve().then(toPayload).then(patchCampaign);
  };

export const withSubmissionHandler = compose(
  setPropTypes({
    campaign: PropTypes.shape({ id: PropTypes.string.isRequired }),
    onUpdateCampaign: PropTypes.func.isRequired,
  }),
  withHandlers({ onSubmit: submitCategories }),
  withSubmissionValidation,
  withNextPanelAfterSubmission,
);
