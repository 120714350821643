const safeMixpanel = {
  identify(userIdentifier) {
    if (window.mixpanel) {
      window.mixpanel.identify(userIdentifier);
    }
    if (process.env.NODE_ENV === 'development') {
      /* eslint-disable no-console */
      console.group('Mixpanel identifier');
      console.log(userIdentifier);
      console.groupEnd('Mixpanel identifier');
      /* eslint-enable no-console */
    }
  },
  track(eventName, eventProperties = {}) {
    const eventPropertiesCopy = { ...eventProperties };
    if (window.mixpanel) {
      window.mixpanel.track(eventName, eventPropertiesCopy);
    }
    if (process.env.NODE_ENV === 'development') {
      /* eslint-disable no-console */
      console.group('Mixpanel event');
      console.log(eventName, eventProperties);
      console.groupEnd('Mixpanel event');
      /* eslint-enable no-console */
    }
  },
};

export default safeMixpanel;
