import { createReducer } from 'source/utils';
import actionTypes from 'source/constants/actionTypes';

export const getInitialState = () => ({
  error: {},
});

const actionHandlers = {
  [actionTypes.GLOBAL_GENERIC_REQUEST_ERROR]: (state, error) => ({
    ...state,
    error,
  }),

  [actionTypes.GLOBAL_SERVER_REQUEST_ERROR]: (state, { message, resp }) => ({
    ...state,
    error: {
      message,
      code: resp.statusCode,
      stack: resp.stack,
    },
  }),

  [actionTypes.GLOBAL_ERROR_CLEAR]: (state) => ({
    ...state,
    error: {},
  }),
};

export default createReducer(getInitialState(), actionHandlers);
