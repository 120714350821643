import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { setPropTypes, setStatic, compose } from 'recompose';

import NumberInput from './numberInput';

const withPropTypes = setPropTypes({
  className: PropTypes.string,
  formControlClassName: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    form: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.node,
});

const withDefaultProps = setStatic('defaultProps', {
  className: '',
  formControlClassName: '',
  label: '',
});

/**
 * HoC which takes an input component and wraps it with a Redux Form
 * compatible API which uses its input to attach error messages to
 * the input
 *
 * @param isReduxFormInput Whether the base component has a Redux Form compatible API
 */
export const asLabeledInput = (Input, { isReduxFormInput = false } = {}) =>
  compose(
    withPropTypes,
    withDefaultProps,
  )(
    ({
      className,
      formControlClassName,
      input,
      input: { name },
      meta: { touched, error, form },
      label,
      children,
      ...props
    }) => (
      <div className={cx('form-group', className)}>
        {label && <label htmlFor={`${form}-${name}`}>{label}</label>}
        <Input
          {...props}
          {...(isReduxFormInput ? { input } : input)}
          id={`${form}-${name}`}
          className={cx('form-control', formControlClassName, {
            'custom-select': Input === 'select',
            'is-invalid': touched && error,
          })}
        >
          {children}
        </Input>
        {touched && error && (
          <small className="invalid-feedback">{error}</small>
        )}
      </div>
    ),
  );

export const LabeledInput = asLabeledInput('input');
export const LabeledTextArea = asLabeledInput('textarea');
export const LabeledSelect = asLabeledInput('select');
export const LabeledNumberInput = asLabeledInput(NumberInput);
