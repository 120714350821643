import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { ProgressBar, CollapsiblePanel } from 'source/components/common';
import { UserProfile, UserProfileHeadline } from 'source/scenes/widgets';
import { userDetailsActions } from 'source/scenes/users/actions';
import { userDetailsSelector } from 'source/scenes/users/selectors';
import UserLogin from 'source/scenes/users/details/userLogin';
import UserDetailsForm from 'source/scenes/users/details/userDetailsForm';
import withResetScene from 'source/components/commonEnhancers/withResetScene';

const enhance = compose(
  connect(userDetailsSelector, userDetailsActions),
  withResetScene,
);

class UserDetails extends Component {
  componentDidMount() {
    const {
      loadUser,
      match: {
        params: { id },
      },
    } = this.props;

    return loadUser(id);
  }

  handleLogin = () => {
    this.props.loginUser(this.props.match.params.id);
  };

  render() {
    const { state, user, form, onFormChange, onFormErrors, onFormSubmit } =
      this.props;

    if (state !== 'loaded') {
      return <ProgressBar.Mega />;
    }

    const profileHeadline = (
      <>
        <span>Profile</span>
        <UserProfileHeadline highlightLanguage user={user} className="pl-2" />
      </>
    );

    return (
      <div className="container user-details">
        <div className="row">
          <div className="col">
            <UserLogin user={user} onLogin={this.handleLogin} />
          </div>
        </div>
        <hr />

        <CollapsiblePanel
          id="details"
          index="1"
          headline="User Settings"
          expanded
        >
          <div className="container">
            <UserDetailsForm
              id="userDetails"
              form={form}
              onChange={onFormChange}
              onErrors={onFormErrors}
              onSubmit={onFormSubmit}
            />
          </div>
        </CollapsiblePanel>
        <CollapsiblePanel
          id="profile"
          index="2"
          headline={profileHeadline}
          className="mt-3"
          expanded
        >
          <UserProfile user={user} />
        </CollapsiblePanel>
      </div>
    );
  }
}

UserDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  state: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onFormErrors: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
};

export default enhance(UserDetails);
