import React from 'react';
import { Fields, Field } from 'redux-form';
import noop from 'lodash/noop';

import PropTypes from 'source/scenes/utils/propTypes';
import { DateInput, Input } from 'source/scenes/components/reduxFormAdapters';
import { toEndOfDay } from 'source/utils/reduxForm';

class PublishingDateContent extends React.PureComponent {
  handleVipCheck = (e) => {
    if (e.target.checked) {
      // Set the "posts" field to 1 if the "vip" checkbox is checked.
      this.props.posts.input.onChange(1);
    }
  };

  handleRemove = (e) => this.props.onRemove(this.props.id, e);

  render() {
    const { vip, posts, id } = this.props;

    // NOTE: We use a separate connected `Field` component for "date" and "vip"
    //       because otherwise the parsing and formatting doesn't work. We
    //       don't rely on the value of "date" for the other fields but we we
    //       need the "vip" value to disable "posts".
    //       Since a new render is triggered for the whole component when
    //       anything changes, this currently works but we should look into how
    //       we can make parsers and formatters work without using `Fields`.
    return (
      <tr>
        <td>{id + 1}</td>
        <td className="col-date">
          <div className="publishing-date-picker">
            <Field name="date" component={DateInput} parse={toEndOfDay} />
          </div>
        </td>
        <td>
          <Field
            name="vip"
            component={Input}
            type="checkbox"
            onChange={this.handleVipCheck}
          />
        </td>
        <td>
          <Input
            {...posts}
            type="number"
            disabled={vip.input.value}
            min="1"
            className="small-input text-sm-right"
          />
        </td>
        <td className="text-sm-right">
          <button
            type="button"
            className="btn btn-danger"
            onClick={this.handleRemove}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  }
}

PublishingDateContent.propTypes = {
  vip: PropTypes.reduxFormInput.isRequired,
  posts: PropTypes.reduxFormInput.isRequired,
  id: PropTypes.number.isRequired,
  onRemove: PropTypes.func,
};

PublishingDateContent.defaultProps = {
  onRemove: noop,
};

function PublishingDate(props) {
  return (
    <Fields
      names={['vip', 'posts']}
      component={PublishingDateContent}
      props={props}
    />
  );
}

export default PublishingDate;
