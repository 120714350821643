import { createReducer } from 'source/utils';
import actionTypes from 'source/constants/actionTypes';

export const getInitialState = () => ({
  stateLoaded: false,
  user: null,
});

const actionHandlers = {
  [actionTypes.APP_STATE_LOADING]: (state) => ({ ...state }),

  [actionTypes.APP_STATE_LOADED]: (state, user) => ({
    ...state,
    user,
    stateLoaded: true,
  }),

  [actionTypes.APP_STATE_LOADING_FAILED]: (state) => ({
    ...state,
    stateLoaded: false,
  }),
};

export default createReducer(getInitialState(), actionHandlers);
