import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import withResetScene from 'source/components/commonEnhancers/withResetScene';
import { ProgressBar, Feature, Alert } from 'source/components/common';

import GroupsTable from './groupsTable';
import actions from '../actions';

import { getGroupsState } from '../selectors';

const enhance = compose(connect(getGroupsState, actions), withResetScene);

class GroupsList extends Component {
  componentDidMount() {
    const { onMount } = this.props;
    return onMount();
  }

  componentWillUnmount() {
    this.props.onAlertClose();
  }

  handleSelectPage = (page) => {
    this.props.onSelectPage(page);
  };

  renderGroupsTable = () => {
    const { groups, pages, page } = this.props;

    return (
      <GroupsTable
        groups={groups}
        pages={pages}
        page={page}
        onSelectPage={this.handleSelectPage}
      />
    );
  };

  render() {
    const { state, alert, onAlertClose } = this.props;
    const stateLoaded = state === 'loaded';

    return (
      <div className="container">
        <Alert {...alert} onClose={onAlertClose} />

        <Feature feature="showCreateGroup">
          <div className="row justify-content-end pr-3">
            <Link to="/groups/new" className="btn btn-outline-primary ">
              Add Group
            </Link>
          </div>
        </Feature>

        <div className="row">
          <div className="col">
            <h3 className="pb-4">Groups</h3>
            {stateLoaded ? this.renderGroupsTable() : <ProgressBar.Mega />}
          </div>
        </div>
      </div>
    );
  }
}

GroupsList.propTypes = {
  state: PropTypes.string.isRequired,
  groups: PropTypes.any.isRequired,
  onMount: PropTypes.func.isRequired,
  pages: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  onAlertClose: PropTypes.func.isRequired,
  alert: PropTypes.object,
};

export default enhance(GroupsList);
