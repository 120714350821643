import React from 'react';
import PropTypes from 'prop-types';
import ExternalLink from 'source/scenes/components/externalLink';
import { EventDefault } from './index';

function EventLink({ content, ...props }) {
  return (
    <EventDefault {...props}>
      <div className="card-body message pb-4">
        <ExternalLink href={content}>{content}</ExternalLink>
      </div>
    </EventDefault>
  );
}

EventLink.propTypes = {
  content: PropTypes.string,
};

export default EventLink;
