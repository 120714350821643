import { createSelector, createStructuredSelector } from 'reselect';
import moment from 'moment';
import get from 'lodash/get';
import pick from 'lodash/pick';
import find from 'lodash/find';
import last from 'lodash/last';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';

import {
  compactObject,
  dedent,
  trim,
  getCurrencySymbolForString,
} from 'source/utils';
import { missionsFilter } from 'source/selectors/campaign/shared';
import { getApplicationsById, getCampaign } from 'source/data/selectors';
import { redux as collapsiblePanelRedux } from 'source/components/common/collapsiblePanel';
import { TASK_TYPES } from 'source/scenes/campaignDetail/constants';

export const getMissionsState = (state) => state.scenes.campaignDetail.missions;
const getMissions = (state) => getMissionsState(state).missions;
const getListForms = (state) => getMissionsState(state).listForms;
const getShowRefused = (state) =>
  get(getListForms(state), 'missionsOptions.showRefused', true);

const allowedEventTypes = [
  'application_created',
  'application_status_changed',
  'application_mission_changed',
  'application_communication_changed',
  'application_delivery_address_changed', // @deprecated
  'application_shipment_changed', // @deprecated
  'application_product_shipment_changed',
  'application_content_preview_submitted',
  'application_content_preview_updated',
  'application_content_preview_deleted',
  'application_article_changed', // @deprecated
  'application_content_publication_changed',
  'application_article_url_changed', // @deprecated
  'application_publication_changed',
  'application_payment_changed',
  'application_assignment_change',
  'application_after_publication_uploads_changed',
  'application_after_publication_uploads_verification_changed',
  'application_social_media_sharing_changed',
  'application_social_media_sharing_verification_changed',
  'application_finished_task_lists_changed',
  'application_counter_offer_changed',
  'application_task_overrides_changed',
  'campaign_tasks_changed',
];

const isAllowedEvent = (allowed, event) => allowed.includes(event.type);

const getApplicationIds = createSelector(
  getMissionsState,
  (state) => state.applicationIds,
);

const getApplications = createSelector(
  getApplicationIds,
  getApplicationsById,
  (ids, applicationsById) => ids.map((id) => applicationsById[id]),
);

const isOnMission = (application) => ['approved'].includes(application.status);
const isVisible = (application, showRefused) =>
  application.mission.status === 'pending' ||
  application.mission.status === 'confirmed' ||
  (application.mission.status === 'refused' && showRefused);

const getApplicationsCounter = createSelector(
  [getApplications, getShowRefused],
  (applications, showRefused) =>
    applications
      .filter(isOnMission)
      .map((application) => ({ visible: isVisible(application, showRefused) }))
      .reduce(
        (counter, { visible }) => ({
          count: counter.count + (visible ? 1 : 0),
          total: counter.total + 1,
        }),
        { count: 0, total: 0 },
      ),
);

export const getApplicationsRequestState = createSelector(
  getMissionsState,
  (state) =>
    pick(state.applicationsRequestState, ['loading', 'loaded', 'error']),
);

const getEventsRequestState = createSelector(getMissionsState, (state) =>
  pick(state.eventsRequestState, ['loading', 'loaded', 'error']),
);

const getExpanded = createSelector([getMissionsState], (state) =>
  collapsiblePanelRedux.selectors.getExpanded(state),
);

const NotAvailable = 'n/a';

const taskChangedTitle = (event) => {
  const {
    payload: { task },
  } = event;
  return `updated ${task.type} task by ${task.updatedBy}`;
};

// Extend the events with a title that will be rendered in the Conversation UI.
const titleBuilders = {
  application_created: 'submitted application',
  application_status_changed: 'changed application status',
  application_finished_task_lists_changed: (event) => {
    const finishedTaskLists = get(event.payload, 'finishedTaskLists');

    if (isEmpty(finishedTaskLists)) {
      return NotAvailable;
    }

    const firstFinishedList = first(finishedTaskLists);
    return `finished all ${TASK_TYPES[firstFinishedList].toLowerCase()}`;
  },
  application_mission_changed: (event) =>
    `${get(event.payload, 'mission.status', NotAvailable)} application mission`,
  application_communication_changed: 'communicated with influencer via email',
  application_delivery_address_changed: 'submitted address', // @deprecated
  application_shipment_changed: 'we sent out the package to the influencer', // @deprecated
  application_product_shipment_changed: (event) => {
    const status = get(event, 'payload.productShipment.status');
    if (event.issuer.type === 'user') {
      return 'product shipping';
    }
    if (status === 'sent') {
      return 'we sent out the package to the influencer';
    }

    return 'product change';
  },
  application_content_preview_submitted: 'submitted post preview',
  application_content_preview_updated: (event) =>
    `${get(event.payload, 'contentReview.status', NotAvailable)} article`,
  application_content_preview_deleted: () => 'deleted content piece',
  application_article_changed: 'submitted article', // @deprecated
  application_content_review_changed: (event) =>
    `${get(event.payload, 'contentReview.status', NotAvailable)} article`, // @deprecated
  application_content_publication_changed: 'submitted post url',
  application_article_url_changed: 'submitted article url', // @deprecated
  application_publication_changed: (event) =>
    `${get(event.payload, 'publication.status', NotAvailable)} article url`,
  application_payment_changed: (event) =>
    `application payment ${get(event.payload, 'payment.status', NotAvailable)}`,
  application_assignment_change: 'vip influencer assigned with publishing date',
  application_social_media_sharing_changed: (action) => {
    const withUrls = action.payload.socialMediaSharing.filter(
      (s) => s.url,
    ).length;
    const total = action.payload.socialMediaSharing.length;

    return `shared on ${withUrls} of ${total} social media channels`;
  },
  application_social_media_sharing_verification_changed: (action) =>
    `${get(
      action.payload,
      'socialMediaSharingVerification.status',
      NotAvailable,
    )} social media sharings`,
  application_after_publication_uploads_changed:
    'uploaded publication insights',
  application_after_publication_uploads_verification_changed: (event) =>
    `${get(
      event.payload,
      'afterPublicationUploadsVerification.status',
      NotAvailable,
    )} publication insights`,
  application_counter_offer_changed: 'changed counteroffer',
  application_task_overrides_changed: taskChangedTitle,
  campaign_tasks_changed: taskChangedTitle,
};

const contentBuilders = {
  application_created: (event) => event.payload.pitch,
  application_status_changed: (event) => event.payload.status,
  application_mission_changed: (event) =>
    get(event.payload, 'mission.feedback'),
  application_communication_changed: '',
  application_delivery_address_changed: (event) => {
    // @deprecated
    const { deliveryAddress: address } = event.payload;

    return dedent(
      `
      ${address.firstname} ${address.lastname}
      ${address.street} ${address.streetNumber}
      ${address.postalCode} ${address.city}
    `,
      { blockMode: true },
    );
  },
  application_shipment_changed: '', // @deprecated
  application_product_shipment_changed: (event) => {
    const productShipment = get(event, 'payload.productShipment', {});
    const { address = {}, product, status } = productShipment;

    if (event.issuer.type === 'office') {
      if (status === 'sent') {
        return '';
      }

      return `Product changed to: ${product}`;
    }

    const productLine = `product: ${product}\n`;

    return dedent(
      `
      ${product ? productLine : ''}
      ${address.firstname} ${address.lastname}
      ${address.street} ${address.streetNumber}
      ${address.postalCode} ${address.city}
    `,
      { blockMode: true },
    );
  },
  application_content_preview_submitted: (event) =>
    event.payload.contentPreview,
  application_content_preview_updated: (event) =>
    get(event.payload, 'contentReview.note'),
  application_content_preview_deleted: (event) =>
    get(event.payload, 'contentPreview.content.media', [])
      .map((item) => item.filename)
      .join(', '),
  application_article_changed: (action) => action.payload.article, // @deprecated
  application_content_review_changed: (event) =>
    get(event.payload, 'contentReview.note'), // @deprecated
  application_content_publication_changed: (event) =>
    event.payload.contentPublication.url,
  application_article_url_changed: (action) => action.payload.articleUrl, // @deprecated
  application_publication_changed: (event) =>
    get(event.payload, 'publication.feedback'),
  application_payment_changed: (event) => {
    const status = get(event.payload, 'payment.status');
    if (status !== 'ready') {
      return '';
    }

    const billingCompleted = get(
      event.payload,
      'billingInformation.completed',
      false,
    );

    if (!billingCompleted) {
      return trim(`Crediting is not possible right now.
          The influencer has not yet provided the necessary billing information.
          Please contact the influencer to provide the information.`);
    }

    const totalAmount = get(event.payload, 'payment.totals.price');
    const price = get(event.payload, 'payment.price', 0);

    const variableBonus = get(event.payload, 'payment.bonuses.variable', 0);
    const variableBonusMessage = variableBonus
      ? `, variable bonus: ${variableBonus}`
      : '';
    const fixedBonus = get(event.payload, 'payment.bonuses.fixed', 0);
    const fixedBonusMessage = fixedBonus ? `, fixed bonus: ${fixedBonus}` : '';
    const bucketBonus = get(event.payload, 'payment.bonuses.bucket', 0);
    const bucketBonusMessage = bucketBonus
      ? `, bucket bonus: ${bucketBonus}`
      : '';

    const currency = get(event.payload, 'payment.currency');
    const currencySymbol = getCurrencySymbolForString(currency);

    return trim(`Would you like to credit ${totalAmount} (price: ${price}
                 ${variableBonusMessage}${fixedBonusMessage}${bucketBonusMessage})
                 ${currencySymbol} to the influencer?`);
  },
  application_assignment_change: '',
  application_social_media_sharing_changed: (action) =>
    action.payload.socialMediaSharing
      .map(({ type, url }) => {
        const messageBody = url || 'the user does not have such a channel';
        return `- ${type}: ${messageBody}`;
      })
      .join('\n'),
  application_social_media_sharing_verification_changed: (action) =>
    get(action.payload, 'socialMediaSharingVerification.feedback'),
  application_after_publication_uploads_changed: (event) =>
    event.payload.afterPublicationUploads,
  application_after_publication_uploads_verification_changed: (action) =>
    get(action.payload, 'afterPublicationUploadsVerification.feedback'),
};

const getContentPublishedReactions = (acceptId, rejectId) => () =>
  [
    {
      id: acceptId,
      reaction: 'accept',
      method: 'patch_application',
      payload: (form) => ({
        publication: compactObject({
          status: 'accepted',
          feedback: form.feedback,
        }),
      }),
    },
    {
      id: rejectId,
      reaction: 'reject',
      method: 'patch_application',
      payload: (form) => ({
        publication: compactObject({
          status: 'rejected',
          feedback: form.feedback,
        }),
      }),
    },
  ];

const reactionsBuilder = {
  application_content_publication_changed: getContentPublishedReactions(
    'accept_application_content_publication',
    'reject_application_content_publication',
  ),
  // @deprecated
  application_article_url_changed: getContentPublishedReactions(
    'accept_application_article_publication',
    'reject_application_article_publication',
  ),
  application_payment_changed: (event, index, events) => {
    // 1. Allow to submit a payment only for `payment_changed` actions
    //    where the payment status switched to "ready".
    if (get(event, 'payload.payment.status') !== 'ready') {
      return null;
    }

    const billingCompleted = get(
      event.payload,
      'billingInformation.completed',
      false,
    );

    if (!billingCompleted) {
      return null;
    }

    // 2. If the payment was already paid (we have a follow up action)
    //    then there should be no reaction for this payed action.
    const followingEvents = events.slice(index + 1);
    const query = {
      type: 'application_payment_changed',
      payload: { payment: { status: 'credited' } },
    };
    const paidEvent = find(followingEvents, query);

    if (paidEvent) {
      return null;
    }

    return [
      {
        id: 'create_application_payment',
        reaction: 'Credit influencer',
        method: 'post_payment',
        payload: () => {},
      },
    ];
  },
  application_social_media_sharing_changed: () => [
    {
      id: 'accept_application_social_media_sharing_changed',
      reaction: 'accept',
      method: 'patch_application',
      payload: (form) => ({
        socialMediaSharingVerification: compactObject({
          status: 'accepted',
          feedback: form.feedback,
        }),
      }),
    },
    {
      id: 'reject_application_social_media_sharing_changed',
      reaction: 'reject',
      method: 'patch_application',
      payload: (form) => ({
        socialMediaSharingVerification: compactObject({
          status: 'rejected',
          feedback: form.feedback,
        }),
      }),
    },
  ],
};

export const getReactionPayload = (reaction, { form }) =>
  reaction.payload(form);

const getResult = (builder, event, index, events) => {
  if (!builder) {
    return undefined;
  }

  if (typeof builder === 'function') {
    return builder(event, index, events);
  }

  if (typeof builder === 'string' || builder instanceof Array) {
    return builder;
  }

  return undefined;
};

const build =
  ({ builders, defaultResult = null }) =>
  (event, index, events) => {
    const result = getResult(builders[event.type], event, index, events);
    if (!result) {
      return getResult(defaultResult, event, index, events);
    }

    return result;
  };

const buildTitle = build({
  builders: titleBuilders,
  defaultResult: (event) => event.type,
});
const buildContent = build({ builders: contentBuilders });
const buildReactions = build({ builders: reactionsBuilder });

const transformEvent = (event, index, events) =>
  event
    ? compactObject({
        // well-known event fields
        id: event.id,
        createdAt: moment(event.createdAt).toDate(),
        type: event.type,

        // additional computed message fields
        issuer: pick(event.issuer, ['email', 'firstname', 'lastname', 'type']),
        title: buildTitle(event, index, events),
        content: buildContent(event, index, events),
        reactions: buildReactions(event, index, events),

        references: event.references,
        payload: event.payload,
      })
    : undefined;

const normalizeActions = (events = []) =>
  events
    .filter((event) => isAllowedEvent(allowedEventTypes, event))
    .map(transformEvent);

/**
 * This Selector returns all possible reactions an office-user can take
 * based on the actions that happened already with this application.
 */
const getPossibleReactions = (action) =>
  get(action, 'reactions', []).map((reaction) => ({
    label: reaction.reaction,
    id: reaction.id,
  }));

export const getApplicationsOnMission = createSelector(
  [getApplications, getShowRefused],
  (applications, showRefused) =>
    applications.filter(missionsFilter({ showRefused })),
);

const missionsWithNormalizedEvents = createSelector(
  [getApplicationsById, getMissions],
  (applicationsById, missions) =>
    Object.keys(missions).reduce((memo, missionId) => {
      const rawEvents = get(applicationsById, [missionId, 'events'], []);
      const events = normalizeActions(rawEvents);

      let lastEvent;

      const paymentEvent = find(events, {
        type: 'application_payment_changed',
      });

      // If there is a payment ready event, 'fast-track' it as last event to not
      // prevent crediting when for example insights are reordered after payment
      // is ready
      if (get(paymentEvent, 'payload.payment.status') === 'ready') {
        lastEvent = paymentEvent;
      } else {
        lastEvent = last(events);
      }

      return {
        ...memo,
        [missionId]: {
          ...missions[missionId],
          lastEvent,
          events,
          possibleReactions: getPossibleReactions(lastEvent),
        },
      };
    }, {}),
);

export const campaignMissions = createStructuredSelector({
  campaign: getCampaign,
  missions: missionsWithNormalizedEvents,
  listForms: getListForms,
  expanded: getExpanded,
  counter: getApplicationsCounter,
  applicationsRequestState: getApplicationsRequestState,
  eventsRequestState: getEventsRequestState,
  applications: getApplicationsOnMission,
});

export const campaignMissionEvents = createSelector(
  [getCampaign, missionsWithNormalizedEvents],
  (campaign, missions) => ({
    campaign,
    missions,
  }),
);
