import { createSelector, createStructuredSelector } from 'reselect';
import { isDirty } from 'redux-form';
import pick from 'lodash/pick';

const getCampaign = (state, props) => props.campaign;
const getFormDirty = (state, props) => isDirty(props.form)(state);
const getDefaultValues = () => ({ language: 'de' });

const getNameAndLanguage = createSelector(getCampaign, (campaign) =>
  pick(campaign, ['name', 'language']),
);

const getInitialValues = createSelector(
  getDefaultValues,
  getNameAndLanguage,
  (defaultValues, init) => ({ ...defaultValues, ...init }),
);

const getHeadline = createSelector(getFormDirty, (dirty) => {
  let headline = 'Campaign Name';
  if (dirty) {
    headline += '*';
  }

  return headline;
});

const getSubHeadline = createSelector(getCampaign, (campaign = {}) => {
  let subHeadline = '';
  if (campaign.name) {
    subHeadline += campaign.name;
  }

  if (campaign.language) {
    subHeadline += ` (in ${campaign.language})`;
  }

  return subHeadline;
});

export default createStructuredSelector({
  initialValues: getInitialValues,
  headline: getHeadline,
  subHeadline: getSubHeadline,
});
