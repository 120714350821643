import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { push } from 'connected-react-router';

import { forSlice } from 'source/utils/selectors';
import { getCampaign } from 'source/data/selectors';
import { load as loadIABCategories } from 'source/actions/iabCategories';

import { selectors as fromState } from './reducer';

import {
  createCampaign,
  updateCampaign,
  uploadFiles,
  attachFilesToCampaign,
  publishCampaign,
  archiveCampaign,
  doneCampaign,
  duplicateCampaign,
  deleteCampaign,
  selectPanel,
  resetScene,
  setError,
  fetchClients,
  fetchPrivateNetworks,
} from './actions';

import CampaignSetup from '../setup';

// Slightly hacky selector to reuse global IAB fetch logic
const getIabCategories = (state) => state.iabCategories;

const containerSelector = (slice, overrides = {}) => {
  const getSelectedPanelGlobal = forSlice(slice, fromState.getSelectedPanel);
  const getErrorGlobal = forSlice(slice, fromState.getError);
  const getClientsGlobal = forSlice(slice, fromState.getClients);
  const getPrivateNetworksGlobal = forSlice(
    slice,
    fromState.getPrivateNetworks,
  );

  return createStructuredSelector({
    appUser: (state) => state.app.user,
    selectedPanel: getSelectedPanelGlobal,
    campaign: getCampaign,
    iabCategories: getIabCategories,
    error: getErrorGlobal,
    clients: getClientsGlobal,
    privateNetworks: getPrivateNetworksGlobal,
    ...overrides,
  });
};

export default function createSetupContainer({
  slice = 'scenes.campaignDetail.setup',
  formSections,
  label,
}) {
  if (!formSections || typeof formSections !== 'function') {
    throw new Error('missing "formSections"');
  }

  if (!label || typeof label !== 'string') {
    throw new Error('missing "label"');
  }

  return compose(
    connect(containerSelector(slice), {
      push,
      onResetScene: resetScene,
      onSelectPanel: selectPanel,
      onLoadIABCategories: loadIABCategories,
      onError: setError,
      onCreateCampaign: createCampaign,
      onUpdateCampaign: updateCampaign,
      onUploadFiles: uploadFiles,
      onAttachFilesToCampaign: attachFilesToCampaign,
      onPublishCampaign: publishCampaign,
      onArchiveCampaign: archiveCampaign,
      onDeleteCampaign: deleteCampaign,
      onDoneCampaign: doneCampaign,
      onDuplicateCampaign: duplicateCampaign,
      onLoadClients: fetchClients,
      onLoadPrivateNetworks: fetchPrivateNetworks,
    }),
    withProps((props) => ({
      label,
      sections: formSections(props),
    })),
  )(CampaignSetup);
}
