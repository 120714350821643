import { asyncActionReducer, asyncStates } from '@blogfoster/redux-async-utils';

import {
  namespacedReducer,
  nestedReducer,
  combineReducersFlat,
} from 'source/utils/redux';

import { actionTypes as paymentActionTypes } from 'source/data/payments/actions';
import { actionTypes as userActionTypes } from 'source/data/users/actions';
import { actionTypes as applicationActionTypes } from 'source/data/applications/actions';
import { actionTypes as campaignActionTypes } from 'source/data/campaigns/actions';
import { getPagingFromHeaders } from 'source/components/common/pagination';

import { namespace, actionTypes } from './actions';

const getInitialPaymentForm = () => ({
  approvedBy: {
    value: '',
  },
  userId: {
    value: '',
  },
  amount: {
    value: '0',
  },
  currency: {
    value: 'EUR',
  },
  description: {
    value: '',
  },
  campaignId: {
    value: '',
  },
  applicationId: {
    value: '',
  },
});

const getInitialPaymentFormState = () => ({
  form: getInitialPaymentForm(),
  errors: {},
  isSubmitted: false,
});

export const getInitialState = () => ({
  state: '',
  error: null,
  alert: null,

  payments: [],
  users: [],
  campaigns: [],
  applications: [],

  payment: getInitialPaymentForm(),

  paging: {
    page: 0,
    pageSize: 30,
    count: 0,
    total: 0,
  },

  filters: {
    userId: '',
    approvedBy: '',
  },

  paymentForm: getInitialPaymentFormState(),
});

const filtersReducer = (state = {}, action) => {
  if (action.type === actionTypes.FILTER_RESET) {
    return {
      userId: '',
      approvedBy: '',
    };
  }

  if (action.type !== actionTypes.FILTER_CHANGED) {
    return state;
  }

  const { filter, value } = action.payload;

  return {
    ...state,
    [filter]: value,
  };
};

const paymentsDataReducer = asyncActionReducer(paymentActionTypes.FETCH_MANY, {
  [asyncStates.pending]: (state) => ({
    ...state,
    state: 'loading',
    error: null,
  }),
  [asyncStates.success]: (state, { payload }) => ({
    ...state,
    state: 'loaded',
    error: null,
    payments: payload.data,
    paging: getPagingFromHeaders(payload.headers),
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    state: 'failure',
    error,
  }),
});

const usersDataReducer = asyncActionReducer(userActionTypes.FETCH_MANY, {
  [asyncStates.pending]: (state) => ({
    ...state,
    state: 'loading',
    error: null,
  }),
  [asyncStates.success]: (state, { payload }) => ({
    ...state,
    state: 'loaded',
    error: null,
    users: payload.data,
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    state: 'failure',
    error,
  }),
});

const campaignsDataReducer = asyncActionReducer(
  campaignActionTypes.FETCH_MANY,
  {
    [asyncStates.pending]: (state) => ({
      ...state,
      state: 'loading',
      error: null,
    }),
    [asyncStates.success]: (state, { payload }) => ({
      ...state,
      state: 'loaded',
      error: null,
      campaigns: payload.data,
    }),
    [asyncStates.failure]: (state, { payload: error }) => ({
      ...state,
      state: 'failure',
      error,
    }),
  },
);

const applicationsDataReducer = asyncActionReducer(
  applicationActionTypes.FETCH_MANY,
  {
    [asyncStates.pending]: (state) => ({
      ...state,
      state: 'loading',
      error: null,
    }),
    [asyncStates.success]: (state, { payload }) => ({
      ...state,
      state: 'loaded',
      error: null,
      applications: payload.data,
    }),
    [asyncStates.failure]: (state, { payload: error }) => ({
      ...state,
      state: 'failure',
      error,
    }),
  },
);

const paymentCreateReducer = asyncActionReducer(
  paymentActionTypes.CREATE_MANUAL,
  {
    [asyncStates.pending]: (state) => ({
      ...state,
      state: 'loading',
      error: null,
    }),
    [asyncStates.success]: (state) => ({
      ...state,
      state: 'loaded',
      error: null,
      alert: {
        type: 'success',
        message: 'Payment was successfully created.',
      },
    }),
    [asyncStates.failure]: (state, { payload: error }) => ({
      ...state,
      state: 'failure',
      error,
      alert: {
        type: 'danger',
        message: `Sorry, your payment was not created. ${error.message}`,
      },
    }),
  },
);

const paymentUpdateReducer = (state, { type, payload }) => {
  if (type !== actionTypes.PAYMENT_UPDATE) {
    return state;
  }

  return {
    ...state,
    form: payload,
  };
};

const paymentValidationReducer = (state, action) => {
  if (action.type !== actionTypes.PAYMENT_VALIDATE) {
    return state;
  }

  const { errors, isSubmitted } = action.payload;

  return {
    ...state,
    errors,
    isSubmitted,
  };
};

const paymentSubmitReducer = (state, action) => {
  if (action.type !== actionTypes.PAYMENT_SUBMIT) {
    return state;
  }

  const {
    paymentForm: { form },
  } = state;

  return {
    ...state,
    payment: form,
  };
};

const paymentEditReducer = (state, action) => {
  if (action.type !== actionTypes.PAYMENT_EDIT) {
    return state;
  }

  const { payment } = state;
  const paymentForm = { ...state.paymentForm };
  paymentForm.form = payment;
  return {
    ...state,
    alert: null,
    payment: {},
    paymentForm,
  };
};

const paymentAlertReducer = (state, action) => {
  if (action.type !== actionTypes.PAYMENT_ALERT_CLOSE) {
    return state;
  }

  return {
    ...state,
    alert: null,
  };
};

const resetState = (state = {}, action) => {
  if (action.type !== actionTypes.RESET_SCENE) {
    return state;
  }

  return {
    ...state,
    state: '',
    error: null,
    payments: [],
    campaigns: [],
    users: [],
    applications: [],
    paymentForm: getInitialPaymentFormState(),
  };
};

const initialState = getInitialState();

export default combineReducersFlat(
  [
    namespacedReducer(namespace)(paymentsDataReducer),
    namespacedReducer(namespace)(paymentCreateReducer),
    namespacedReducer(namespace)(usersDataReducer),
    namespacedReducer(namespace)(campaignsDataReducer),
    namespacedReducer(namespace)(applicationsDataReducer),
    resetState,
    paymentSubmitReducer,
    paymentEditReducer,
    paymentAlertReducer,
    nestedReducer('filters')(filtersReducer),
    nestedReducer('paymentForm')(paymentUpdateReducer),
    nestedReducer('paymentForm')(paymentValidationReducer),
  ],
  initialState,
);
