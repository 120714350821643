import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';
import map from 'lodash/map';
import compact from 'lodash/compact';
import {
  withSubmissionValidation,
  withNextPanelAfterSubmission,
} from '../../enhancers';

const submit =
  ({ onUpdateCampaign }) =>
  (values, __, props) => {
    const {
      campaign: { id: campaignId },
    } = props;

    const patchCampaign = (payload) => onUpdateCampaign(campaignId, payload);

    return patchCampaign({
      productShipment: {
        ...values,
        productChoices: compact(map(values.productChoices, 'value')),
      },
    });
  };

export const withSubmissionHandler = compose(
  setPropTypes({
    campaign: PropTypes.shape({ id: PropTypes.string.isRequired }),
    onUpdateCampaign: PropTypes.func.isRequired,
  }),
  withHandlers({ onSubmit: submit }),
  withSubmissionValidation,
  withNextPanelAfterSubmission,
);
