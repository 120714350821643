import omit from 'lodash/omit';
import get from 'lodash/get';

import { createReducer } from 'source/utils';
import actionTypes from 'source/constants/actionTypes';
import {
  getAvailableFiltersTypes,
  normalizeLink,
} from 'source/selectors/links';
import {
  updateItem,
  findAndReplace,
  push,
  remove,
  nextItemId,
} from 'source/utils/imCollection';

const getInitialAddLinkFormState = () => ({
  form: {
    targetUrl: {
      value: '',
    },
    user: {
      value: '',
    },
    campaign: {
      value: '',
    },
  },
  errors: {},
});

const getInitialLinkDetailsState = () => ({
  stateLoaded: false,
  selectedLink: null,
  edit: false,
  form: {
    url: {
      value: '',
    },
    targetUrl: {
      value: '',
    },
    user: {
      value: '',
    },
    campaign: {
      value: '',
    },
    memoizedForm: null,
  },
  errors: [],
});

export const getInitialState = () => ({
  stateLoaded: false,
  alert: {},
  data: {
    links: [],
    users: [],
    campaigns: [],
  },
  filters: {
    types: [
      { value: 'targetUrl', text: 'Target URL' },
      { value: 'user', text: 'User' },
      { value: 'createdAt', text: 'Created At' },
      { value: 'campaign', text: 'Campaign Name' },
    ],
    /**
     * Array of defined filters
     * @type
     *   {
     *     id: Number,
     *     filter: String, // one value of the filter types
     *     value: String,
     *     options: [ { value: String, text: String } ],
     *     error: String,
     *     regex: RegExp
     *   }
     */
    filters: [],
  },
  table: {
    perPage: 20,
    page: 0,
  },
  linkDetails: getInitialLinkDetailsState(),
  addLinkForm: getInitialAddLinkFormState(),
});

const buildRegex = (filter) => {
  let regex;
  let error;

  try {
    regex = new RegExp(filter.value, 'i');
  } catch (err) {
    regex = /.*/;
    error = { message: `cannot build a regex from [${filter.value}]` };
  }

  return { regex, error };
};

const actionHandlers = {
  /* LinksFilter */
  [actionTypes.LINKS_FILTER_CHANGE]: (state, { filter, attribute, value }) => {
    let updatedFilter = updateItem(filter, attribute, value);

    if (attribute === 'value') {
      const result = buildRegex(updatedFilter);
      updatedFilter = {
        ...updatedFilter,
        ...result,
      };
    }

    return {
      ...state,
      filters: {
        ...state.filters,
        filters: findAndReplace(state.filters.filters, updatedFilter),
      },
    };
  },

  [actionTypes.LINKS_FILTER_ADD]: (state) => {
    const availableFilterTypes = getAvailableFiltersTypes(state);

    const filter = {
      id: nextItemId(state.filters.filters),
      value: '',
      filter: availableFilterTypes[0].value,
    };

    return {
      ...state,
      filters: {
        ...state.filters,
        filters: push(state.filters.filters, filter),
      },
    };
  },

  [actionTypes.LINKS_FILTER_REMOVE]: (state, { filter }) => ({
    ...state,
    filters: {
      ...state.filters,
      filters: remove(state.filters.filters, filter),
    },
  }),

  /* LinksTable */
  [actionTypes.LINKS_PAGE_SELECTED]: (state, { page }) => ({
    ...state,
    table: {
      ...state.table,
      page,
    },
  }),

  [actionTypes.LINKS_ALERT_CLOSE]: (state) => ({
    ...state,
    alert: {},
  }),

  /* AddLink */
  [actionTypes.LINKS_ADD_VALIDATION]: (state, { errors }) => ({
    ...state,
    addLinkForm: {
      ...state.addLinkForm,
      errors,
    },
  }),

  [actionTypes.LINKS_ADD_SUBMITTED]: (state, link) => ({
    ...state,
    addLinkForm: getInitialAddLinkFormState(),
    alert: {
      type: 'success',
      message: 'Added new link successfully!',
    },
    data: {
      ...state.data,
      links: [link, ...state.data.links],
    },
  }),

  [actionTypes.LINKS_ADD_SUBMITION_FAILED]: (state) => ({
    ...state,
    addLinkForm: getInitialAddLinkFormState(),
    alert: {
      type: 'danger',
      message: "Sorry, can't create the link. Please try again.",
    },
  }),

  [actionTypes.LINKS_ADD_UPDATE]: (state, { form, attribute, value }) => {
    const updatedForm = updateItem(form, attribute, value);

    return {
      ...state,
      addLinkForm: {
        ...state.addLinkForm,
        form: updatedForm,
      },
    };
  },

  [actionTypes.LINKS_ADD_CANCEL]: (state) => ({
    ...state,
    addLinkForm: getInitialAddLinkFormState(),
  }),

  /* LinksContainer */
  [actionTypes.LINK_STATE_LOADING]: (state) =>
    // stateLoaded flag is initially false, so on UI we will show the progress bar of loading data.
    // we don't reset it afterwards, so data is re-fetched from server each time Links view opened,
    // but without progress bar, allowing optimistic updates..

    ({
      ...state,
    }),

  [actionTypes.LINK_STATE_LOADED]: (state, { links, users, campaigns }) => ({
    ...state,
    stateLoaded: true,
    data: {
      ...state.data,
      links: links || state.data.links,
      users: users || state.data.users,
      campaigns: campaigns || state.data.campaigns,
    },
  }),

  [actionTypes.LINK_STATE_FAILED]: (state, { error }) => ({
    ...state,
    stateLoaded: true,
    alert: {
      type: 'danger',
      message: get(error, 'data.message'),
    },
  }),

  [actionTypes.LINK_DETAILS_LOADING]: (state) => ({
    ...state,
    linkDetails: {
      ...state.linkDetails,
      stateLoaded: false,
    },
  }),

  [actionTypes.LINK_DETAILS_LOADED]: (state, link) => ({
    ...state,
    linkDetails: {
      ...state.linkDetails,
      selectedLink: link,
      form: normalizeLink(link),
      stateLoaded: true,
    },
  }),

  /**/
  [actionTypes.LINK_DETAILS_EDIT]: (state) => ({
    ...state,
    linkDetails: {
      ...state.linkDetails,
      edit: true,
      memoizedForm: state.linkDetails.form,
    },
  }),

  [actionTypes.LINK_DETAILS_EDIT_FORM]: (state, { form, attribute, value }) => {
    const updatedForm = updateItem(form, attribute, value);
    const updatedErrors = omit(state.linkDetails.error, [attribute]);

    return {
      ...state,
      linkDetails: {
        ...state.linkDetails,
        form: updatedForm,
        errors: updatedErrors,
      },
    };
  },

  [actionTypes.LINK_DETAILS_EDIT_CANCEL]: (state) => ({
    ...state,
    linkDetails: {
      ...state.linkDetails,
      edit: false,
      form: state.linkDetails.memoizedForm,
      memoizedForm: null,
    },
  }),

  [actionTypes.LINK_DETAILS_FORM_VALIDATION]: (state, { errors }) => ({
    ...state,
    linkDetails: {
      ...state.linkDetails,
      errors,
    },
  }),

  [actionTypes.LINKS_DETAILS_SUBMITTED]: (state, link) => {
    const updatedLinks = findAndReplace(state.data.links, link);

    return {
      ...state,
      data: {
        ...state.data,
        links: updatedLinks,
      },
      linkDetails: {
        ...state.linkDetails,
        edit: false,
      },
    };
  },

  [actionTypes.LINK_DETAILS_RESET]: (state) => ({
    ...state,
    linkDetails: getInitialLinkDetailsState(),
  }),
};

export default createReducer(getInitialState(), actionHandlers);
