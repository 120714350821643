import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import sortBy from 'lodash/sortBy';

import config from 'config';
import { getReachOptionById } from 'source/utils/reachOptions';
import { idType } from 'source/utils/propTypes';
import { compactObject } from 'source/utils';
import DownloadButton from 'source/components/common/downloadButton';

const getContingentOptions = (contingents = []) =>
  sortBy(contingents, 'reach.min').map(({ id }) => (
    <option key={id} value={id}>
      {getReachOptionById(id).label}
    </option>
  ));

const formatValue = (filter, value) => {
  if (filter === 'contingentId' && value) {
    return parseInt(value, 10);
  }

  return value;
};

const getCsvPath = ({ accessToken, id, filters: { contingentId, status } }) => {
  const filters = [{ campaignId: id }];

  if (contingentId) {
    filters.push({ 'match.contingent.id': contingentId });
  }

  if (status) {
    filters.push({ status });
  }

  const query = `filters=${JSON.stringify(
    filters,
  )}&access_token=${accessToken}`;

  return `${config.api.url}/v2/reports/applications.xlsx?${query}`;
};

/* CampaignApplicationsFilter */
class CampaignApplicationsFilter extends React.Component {
  onFormChange = (e) => {
    const filter = e.target.name;
    const value = formatValue(filter, e.target.value);

    this.props.onFilterChange({ filter, value });
  };

  render() {
    const {
      campaign: { contingents, id },
      applications,
      filters,
      accessToken,
    } = this.props;

    return (
      <div className="row">
        <div className="col-md-9">
          <form className="form-inline" onChange={this.onFormChange}>
            <div className="form-group">
              <label htmlFor="status" className="mr-3">
                Status
              </label>
              <select
                name="status"
                className="form-control mr-3"
                value={filters.status}
              >
                <option value="">- No Filter -</option>
                <option value="new">New</option>
                <option value="review">Review</option>
                <option value="selected">Selected</option>
                <option value="rejected">Rejected</option>
                <option value="approved">Approved</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="contingentId" className="mr-3">
                Contingent
              </label>
              <select
                name="contingentId"
                className="form-control"
                value={filters.contingentId}
              >
                <option value="">- No Filter -</option>
                {getContingentOptions(contingents)}
              </select>
            </div>
          </form>
        </div>
        <div className="col-md-3 text-right">
          <span
            className={cx({ 'cursor-not-allowed': applications.length <= 0 })}
          >
            <DownloadButton
              href={getCsvPath({
                filters: compactObject(filters),
                id,
                accessToken,
              })}
              disabled={applications.length <= 0}
            >
              export XLSX
            </DownloadButton>
          </span>
        </div>
      </div>
    );
  }
}

CampaignApplicationsFilter.propTypes = {
  accessToken: PropTypes.string.isRequired,
  applications: PropTypes.array.isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    contingents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
  filters: PropTypes.shape({
    status: PropTypes.string.isRequired,
    contingentId: idType.isRequired,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default CampaignApplicationsFilter;
