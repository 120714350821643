import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';

import { requestAuthUrl } from 'source/actions/auth';
import { useAccessToken } from 'source/utils/auth';
import ErrorBoundary from 'source/utils/ErrorBoundary';

function SignIn({ onLogin }) {
  const isLoggedIn = !!useAccessToken();

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <ErrorBoundary>
      <div className="flex-center">
        <div className="flex-center-item">
          <form className="login-form" onSubmit={onLogin}>
            <h2 className="greetings">Welcome to Eqolot Control Center</h2>
            <div className="form-group">
              <p>
                Please use your Eqolot Google account to login Control Center.
              </p>
            </div>
            <div className="form-group">
              <button
                className="btn btn-lg btn-danger text-uppercase"
                type="submit"
              >
                Login with Google
              </button>
            </div>
          </form>
        </div>
      </div>
    </ErrorBoundary>
  );
}

SignIn.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = {
  onLogin: requestAuthUrl,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onLogin:
      ({ onLogin }) =>
      (e) => {
        e.preventDefault();
        return onLogin();
      },
  }),
);

export default enhance(SignIn);
