import { createSelector, createStructuredSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

import config from 'config';
import { objToUrlParams } from 'source/utils';
import { contingents } from 'source/utils/reachOptions';
import { getPages as getPaginationPages } from 'source/components/common/pagination';
import { getCampaignsById } from 'source/data/selectors';

const getCampaignIds = (state) => state.scenes.campaigns.campaignIds;
const getPerPage = (state) => state.scenes.campaigns.paging.pageSize;
const getPage = (state) => state.scenes.campaigns.paging.page;
const getPagingCount = (state) => state.scenes.campaigns.paging.count;
const getState = (state) => state.scenes.campaigns.state;
const getFilters = (state) => state.scenes.campaigns.filters;
const getAccessToken = (state) => state.account.accessToken;
const getPagingTotal = (state) => state.scenes.campaigns.paging.total;

const getCampaigns = createSelector(
  getCampaignsById,
  getCampaignIds,
  (campaignsById, campaignIds) =>
    campaignIds
      .map((campaignId) => campaignsById[campaignId])
      .filter((campaign) => Boolean(campaign)), // remove non-existing campaigns
);

/**
 * Polymorphic implementation for calculating application counts based on campaign type.
 */
const getApplicationCountsForWebsiteCampaign = (campaign) => {
  // NOTE: return array based on reachOptions to keep the order in which they are given (sorted by `reach.min`)
  const getWebsiteApplicationsCount = ({ summary }) => {
    const options = contingents.map(({ id, ignore }) => ({ id, ignore }));
    const reachCounters = get(summary, 'reach', []);
    const countersById = keyBy(reachCounters, 'id');

    return (key) =>
      options.map((option) => ({
        ...option,
        status: get(countersById, `${option.id}.${key}`, 0),
      }));
  };

  const getCountFor = getWebsiteApplicationsCount(campaign);

  return {
    missing: getCountFor('applications.missing'),
    review: getCountFor('applications.review'),
    mission: getCountFor('applications.mission'),
    live: getCountFor('applications.live'),
    total: getCountFor('numberOfPostings'),
  };
};

const getApplicationCountsForV2Campaign = (campaign) => campaign.counters;

const getApplicationBuilder = ({ type, pricing }) => {
  if (type === 'website' && pricing === 'bucket') {
    return getApplicationCountsForWebsiteCampaign;
  }

  return getApplicationCountsForV2Campaign;
};

const getApplications = (campaign) => getApplicationBuilder(campaign)(campaign);

const getCampaignTableElement = (campaign) => ({
  ...campaign,
  applications: getApplications(campaign),
});

const getCampaignTablePages = createSelector(
  getPerPage,
  getPagingCount,
  (perPage, count) => getPaginationPages(count, perPage),
);

const getCampaignTableElements = createSelector(getCampaigns, (campaigns) =>
  campaigns.map(getCampaignTableElement),
);

const getCounter = createSelector(
  getPagingCount,
  getPagingTotal,
  (count, total) => ({ count, total }),
);

const getAuthorizedUrl = (url) => (accessToken) => {
  const urlParams = objToUrlParams({
    access_token: accessToken,
  });

  return `${url}${urlParams}`;
};

const getTimelineReportUrl = createSelector(
  getAccessToken,
  getAuthorizedUrl(`${config.api.url}/v2/reports/timeline.xlsx`),
);

export const getCampaignsState = createStructuredSelector({
  state: getState,
  campaigns: getCampaignTableElements,
  campaignIds: getCampaignIds,
  page: getPage,
  filters: getFilters,
  pages: getCampaignTablePages,
  counter: getCounter,
  timelineReportUrl: getTimelineReportUrl,
});
