import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Octicon from 'react-octicon';
import cx from 'classnames';
import Clipboard from 'clipboard';
import noop from 'lodash/noop';

class CopyButton extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIcon: 'copy' };
  }

  componentDidMount() {
    const { value, getTarget } = this.props;

    const clipboardOptions = {};

    if (value) clipboardOptions.text = () => value;
    else if (getTarget) clipboardOptions.target = getTarget;

    this.clipboard = new Clipboard(this.trigger, clipboardOptions);

    this.clipboard.on('success', this.handleSuccess);
    this.clipboard.on('error', this.handleError);
  }

  componentWillUnmount() {
    this.clipboard.destroy();
  }

  setTrigger = (node) => {
    this.trigger = node;
  };

  handleSuccess = (e) => {
    const { transitionTimeout, hangTimeout, onSuccess } = this.props;

    // In `target` mode, clipboard.js copies content by selecting it
    // We don't want that selection to be visible after copying
    e.clearSelection();

    this.setState({ currentIcon: 'success' });

    setTimeout(this.resetState, transitionTimeout + hangTimeout);
    onSuccess(e);
  };

  handleError = (e) => {
    const { transitionTimeout, hangTimeout, onError } = this.props;

    this.setState({ currentIcon: 'error' });

    setTimeout(this.resetState, transitionTimeout + hangTimeout);
    onError(e);
  };

  resetState = () => {
    this.setState({ currentIcon: 'copy' });
  };

  render() {
    const {
      className,
      style,
      icon,
      successIcon,
      failureIcon,
      transitionTimeout,
      title,
      onClick,
    } = this.props;
    const { currentIcon } = this.state;

    const icons = {
      copy: { key: 'copy', name: icon },
      success: { key: 'success', name: successIcon },
      failure: { key: 'failure', name: failureIcon },
    };

    return (
      <button
        title={title}
        ref={this.setTrigger}
        className={cx('btn copy-btn', className)}
        style={style}
        onClick={onClick}
      >
        <CSSTransitionGroup
          transitionName="copy-btn-icon"
          transitionEnterTimeout={transitionTimeout}
          transitionLeaveTimeout={transitionTimeout}
        >
          <Octicon
            {...icons[currentIcon]}
            className={`copy-btn-icon copy-btn-icon-${currentIcon}`}
          />
        </CSSTransitionGroup>
      </button>
    );
  }
}

CopyButton.propTypes = {
  value: PropTypes.string,
  getTarget: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.string,
  title: PropTypes.string,
  successIcon: PropTypes.string,
  failureIcon: PropTypes.string,
  transitionTimeout: PropTypes.number,
  hangTimeout: PropTypes.number,
  onClick: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

CopyButton.defaultProps = {
  value: undefined,
  getTarget: undefined,
  className: '',
  style: undefined,
  onClick: undefined,
  icon: 'clippy',
  title: '',
  successIcon: 'check',
  failureIcon: 'alert',
  transitionTimeout: 200,
  hangTimeout: 800,
  onSuccess: noop,
  onError: noop,
};

export default CopyButton;
