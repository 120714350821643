import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * Redux-form compatible wrapper for `textArea` elements.
 *
 * @example
 *
 * <Field
 *   name="bio"
 *   component={Textarea}
 *   label="Biography:"
 *   placeholder="Placeholder inside the textarea"
 *   rows="15"
 * />
 */
function Textarea({
  // redux-form props
  input,
  input: { name },
  meta: { touched, error, form },

  // custom props
  className,
  label,
  ...inputProps
}) {
  return (
    <div
      className={cx('form-group', className, {
        'has-danger': touched && error,
      })}
    >
      {label && <label htmlFor={`${form}-${name}`}>{label}</label>}
      <textarea
        {...input}
        id={`${form}-${name}`}
        {...inputProps}
        className={cx('form-control', {
          'is-invalid': touched && error,
        })}
      />
      {touched && error && (
        <small className="form-control-feedback invalid-feedback">
          {error}
        </small>
      )}
    </div>
  );
}

Textarea.propTypes = {
  // redux-form props
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    form: PropTypes.string.isRequired,
  }).isRequired,

  // custom props
  className: PropTypes.string,
  label: PropTypes.string,
};

Textarea.defaultProps = {
  className: '',
  label: null,
};

export default Textarea;
