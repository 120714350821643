import React from 'react';
import PropTypes from 'prop-types';
import { compose, branch, renderNothing } from 'recompose';

const enhance = compose(
  // dont render the analysis if the status is not warning
  branch(
    ({ application: { insightsAnalysis } }) =>
      insightsAnalysis.status !== 'warning',
    renderNothing,
  ),
);

function InsightsAnalysis({ application }) {
  const { insightsAnalysis } = application;

  const warningIndicators = insightsAnalysis.indicators.filter(
    (indicator) => indicator.status === 'warning',
  );

  return (
    <div className="insights-analysis">
      <h5>Anomaly Indicator</h5>
      <p className="text-muted">An analysis report of the Insights metrics.</p>

      <table className="table table-hover">
        <thead>
          <tr>
            <th>Metric</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {warningIndicators.map((indicator) => (
            <tr key={indicator.key}>
              <td>{indicator.key}</td>
              <td>{indicator.message}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

InsightsAnalysis.propTypes = {
  application: PropTypes.shape({
    insightsAnalysis: PropTypes.shape({
      status: PropTypes.string.isRequired,
      indicators: PropTypes.arrayOf(
        PropTypes.shape({
          status: PropTypes.string.isRequired,
          message: PropTypes,
        }),
      ).isRequired,
    }),
  }).isRequired,
};

export default enhance(InsightsAnalysis);
