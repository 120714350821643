import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { FormattedNumber } from 'react-intl';

const toPercent = (value) =>
  typeof value === 'number' ? (
    <FormattedNumber
      {...{ style: 'percent' }}
      maximumFractionDigits={2}
      value={value}
    />
  ) : (
    ''
  );

const toNumber = (value) =>
  Number.isInteger(value) ? <FormattedNumber value={value} /> : '';

function KeyMetricsEntry({ className, label, children }) {
  return (
    <div className={cx('form-group', className)}>
      <span className="text-muted" htmlFor={label}>
        {label}
      </span>
      <div id={label} className="form-control">
        {children}
      </div>
    </div>
  );
}

KeyMetricsEntry.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
KeyMetricsEntry.defaultProps = {
  className: '',
};

function PinterestInsights({
  followers,
  pins,
  impressionMedian,
  engagementMedian,
  engagementRatio,
  pinClickMedian,
  pinClickRatio,
  saveMedian,
  saveRatio,
  updatedAt,
  source,
}) {
  return (
    <div className="container pinterest">
      <div className="row">
        <div className="col-lg-5 extra-padding">
          <div className="mb-3">
            {updatedAt ? (
              <span className="text-muted">
                Updated on {moment.utc(updatedAt).local().format('L HH:mm')} by{' '}
                {source?.includes('pinterest-api') ? 'system' : source}
              </span>
            ) : (
              <span className="text-muted">
                Collected date is not available
              </span>
            )}
          </div>
          <KeyMetricsEntry label="Followers">
            {toNumber(followers)}
          </KeyMetricsEntry>
          <KeyMetricsEntry label="Median Impressions for last 10 idea pins">
            {toNumber(impressionMedian)}
          </KeyMetricsEntry>
          <KeyMetricsEntry label="Pins">{toNumber(pins)}</KeyMetricsEntry>
        </div>
        <div className="col-lg-7">
          <div className="row">
            <div className="col-12">
              <p className="font-weight-bold">Insights for last 30 days</p>
            </div>
            <div className="col-7">
              <KeyMetricsEntry label="Engagement">
                {toNumber(engagementMedian)}
              </KeyMetricsEntry>
              <KeyMetricsEntry label="Pin clicks">
                {toNumber(pinClickMedian)}
              </KeyMetricsEntry>
              <KeyMetricsEntry label="Save">
                {toNumber(saveMedian)}
              </KeyMetricsEntry>
            </div>
            <div className="col-5">
              <KeyMetricsEntry label="Engagement rate">
                {toPercent(engagementRatio)}
              </KeyMetricsEntry>
              <KeyMetricsEntry label="Pin clicks rate">
                {toPercent(pinClickRatio)}
              </KeyMetricsEntry>
              <KeyMetricsEntry label="Save rate">
                {toPercent(saveRatio)}
              </KeyMetricsEntry>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PinterestInsights.propTypes = {
  pins: PropTypes.number,
  followers: PropTypes.number,
  impressionMedian: PropTypes.number,
  engagementMedian: PropTypes.number,
  engagementRatio: PropTypes.number,
  pinClickMedian: PropTypes.number,
  pinClickRatio: PropTypes.number,
  saveMedian: PropTypes.number,
  saveRatio: PropTypes.number,
  updatedAt: PropTypes.string,
  source: PropTypes.string,
};

PinterestInsights.defaultProps = {
  source: '',
};

export default PinterestInsights;
