import { createSelector, createStructuredSelector } from 'reselect';
import { isDirty } from 'redux-form';
import moment from 'moment';
import pick from 'lodash/pick';
import get from 'lodash/get';

const getCampaign = (state, props) => props.campaign;
const getFormDirty = (state, props) => isDirty(props.form)(state);
const getDefaultValues = () => ({
  previewDueDays: 2,
  publishingDates: [],
});

const getTimeline = createSelector(
  getDefaultValues,
  getCampaign,
  (defaultValues, campaign) => ({
    ...defaultValues,
    ...get(campaign, 'timeline', {}),
  }),
);

const getInitialValues = createSelector(getTimeline, (timeline) =>
  pick(timeline, ['applicationStart', 'previewDueDays', 'publishingDates']),
);

const getHeadline = createSelector(getFormDirty, (dirty) => {
  let headline = 'Timeline';
  if (dirty) {
    headline += '*';
  }

  return headline;
});

const getSubHeadline = createSelector(getCampaign, (campaign = {}) => {
  const timeline = get(campaign, 'timeline', {});

  let subHeadline = '';
  if (timeline.applicationStart) {
    subHeadline += moment(timeline.applicationStart).format('YYYY-MM-DD');
  }

  return subHeadline;
});

export default createStructuredSelector({
  initialValues: getInitialValues,
  headline: getHeadline,
  subHeadline: getSubHeadline,
});
