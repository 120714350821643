import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import { compose } from 'recompose';

import Alert from 'source/components/common/alert';

import selector from './selectors';
import validate from './validation';
import { withSubmissionHandler } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';

import CountriesTable from './components/countriesTable';

const enhance = compose(
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
  reduxForm({
    validate,
    touchOnBlur: false,
    touchOnChange: true,
    enableReinitialize: true,
  }),
);

function CountriesForm({ handleSubmit, invalid, submitting, error }) {
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Alert message={error.message} />
        <FieldArray name="countries" component={CountriesTable} />
        <div className="row justify-content-end pr-3">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={invalid || submitting}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

CountriesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

CountriesForm.defaultProps = {
  error: { message: undefined },
};

export default enhance(CountriesForm);
