import createCachedSelector from 're-reselect';
import { createStructuredSelector } from 'reselect';
import { isDirty } from 'redux-form';
import get from 'lodash/get';
import { TASK_TYPES } from 'source/scenes/campaignDetail/constants';

const getTaskList = (state, props) => props.list;
const getCampaign = (state, props) => props.campaign;
const getFormDirty = (state, props) => isDirty(props.form)(state);

const getInitialValues = createCachedSelector(
  getCampaign,
  getTaskList,
  (campaign, list) => {
    const tasks = get(campaign, 'tasks', []);

    return {
      tasks: list ? tasks.filter((t) => t.list === list) : tasks,
    };
  },
)(getTaskList);

const getHeadline = createCachedSelector(
  getFormDirty,
  getTaskList,
  (dirty, list) => {
    let headline = TASK_TYPES[list];
    if (dirty) {
      headline += '*';
    }

    return headline;
  },
)(getTaskList);

const getSubHeadline = createCachedSelector(
  getCampaign,
  getTaskList,
  (campaign = {}, list) => {
    const tasks = get(campaign, 'tasks', []).filter((t) => t.list === list);

    let subHeadline = '';
    subHeadline += `${tasks.length} task(s)`;

    return subHeadline;
  },
)(getTaskList);

export default createStructuredSelector({
  initialValues: getInitialValues,
  headline: getHeadline,
  subHeadline: getSubHeadline,
});
