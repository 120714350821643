import { connect } from 'react-redux';
import get from 'lodash/get';

import { backToDashboard } from './actions';
import { Root } from './components/Root';

const mapStateToProps = (state) => ({
  errorMessage: get(state.error, 'error.message', ''),
  errorStack: get(state.error, 'error.stack', ''),
  errorCode: get(state.error, 'error.code', ''),
});

const mapDispatchToProps = {
  onBackToDashboard: backToDashboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
