import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import {
  compose,
  setPropTypes,
  defaultProps,
  withPropsOnChange,
} from 'recompose';

const withChartOptions = defaultProps({
  tooltips: {
    enabled: true,
  },
  hover: {
    animationDuration: 0,
  },
});

const formatDailyDateForLineChart = (dailyReports) => ({
  labels: dailyReports.map(({ date }) => moment(date).format('DD-MM-YYYY')),
  datasets: [
    {
      label: 'Visits',
      data: dailyReports.map(({ totals }) => totals.visits),
    },
    {
      label: 'Unique Visitors',
      data: dailyReports.map(({ totals }) => totals.uniqueVisitors),
    },
    {
      label: 'Total Page Views',
      data: dailyReports.map(({ totals }) => totals.pageviews),
    },
  ],
});

const withData = compose(
  setPropTypes({
    byDate: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        totals: PropTypes.shape({
          visits: PropTypes.number.isRequired,
          uniqueVisitors: PropTypes.number.isRequired,
          pageviews: PropTypes.number.isRequired,
        }).isRequired,
      }),
    ).isRequired,
  }),
  withPropsOnChange(['byDate'], (props) => ({
    data: formatDailyDateForLineChart(props.byDate),
  })),
);

const enhance = compose(withChartOptions, withData);

export default enhance(Line);
