import values from 'lodash/values';
import flatten from 'lodash/flatten';

const managers = [
  { username: 'hella.tannhaeuser-erbsen', name: 'Hella Tannhäuser-Erbsen' },
  { username: 'maja.kuzmin' },
  { username: 'simon.staib' },
  { username: 'nils.denkhaus' },
  { username: 'caroline.albrecht' },
  { username: 'anja.schreiber' },
  { username: 'isabelle.sonderyd' },
  { username: 'melina.schmidt' },
  { username: 'viktoria.plonies' },
  { username: 'jacqueline.krueger' },
  { username: 'pascal.pfuetze' },
  { username: 'hanna.schnitzlein' },
  { username: 'jalda.rau' },
  { username: 'niklas.schubert' },
  { username: 'tramy.nguyen' },
];

const accessGroups = {
  developers: ['alexander.beletsky'],
  product: ['simon.staib'],
  marketing: ['simon.staib', 'emra.kolic'],
  operations: [
    'juliane.dobberow',
    'hella.tannhaeuser-erbsen',
    'anja.schreiber',
    'isabelle.sonderyd',
    'melina.schmidt',
    'viktoria.plonies',
    'nathalie.mursal',
    'jacqueline.krueger',
    'pascal.pfuetze',
    'hanna.schnitzlein',
    'jalda.rau',
    'niklas.schubert',
    'tramy.nguyen',
    'caroline.albrecht',
  ],
  management: [
    'simon.staib',
    'jan.homann',
    'sebastian.niemann',
    'nils.denkhaus',
  ],
  accounting: ['nils.tornow'],
  paymentApprovers: [
    'simon.staib',
    'hella.tannhaeuser-erbsen',
    'nicole.stoermann',
    'nils.tornow',
    'anja.schreiber',
    'isabelle.sonderyd',
    'caroline.albrecht',
    'maja.kuzmin',
    'nils.denkhaus',
    'pascal.pfuetze',
  ],
  campaignManagers: [
    'hella.tannhaeuser-erbsen',
    'nils.denkhaus',
    'anja.schreiber',
    'isabelle.sonderyd',
    'melina.schmidt',
    'viktoria.plonies',
    'jacqueline.krueger',
    'pascal.pfuetze',
    'hanna.schnitzlein',
    'jalda.rau',
    'niklas.schubert',
    'tramy.nguyen',
    'caroline.albrecht',
  ],
};

const allUsers = flatten(values(accessGroups));

const config = {
  allUsers,
  managers,
  accessGroups,
};

export default config;
