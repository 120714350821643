import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import cx from 'classnames';
import {
  branch,
  defaultProps,
  withHandlers,
  withStateHandlers,
  withPropsOnChange,
  renderComponent,
  compose,
} from 'recompose';

import Octicon from 'react-octicon';
import DropdownButton from 'source/components/common/dropdownButton';
import DragDropList from 'source/scenes/components/dragDropList';

// Helpers

const defaultFormId = '__form__';

// HoCs

/**
 * Implements view state for showing the Add Item Form
 */
const withItemFormViewState = withStateHandlers(
  { activeFormState: { type: '', id: '' } },
  {
    onSetAddForm:
      (state, { getNewItemInitialValues }) =>
      (formId) => ({
        activeFormState: {
          type: 'add',
          id: formId,
          initialValues: getNewItemInitialValues(formId),
        },
      }),

    onSetUpdateForm: () => (formId, itemId, index, values) => ({
      activeFormState: {
        type: 'update',
        id: formId,
        itemId,
        itemIndex: index,
        initialValues: values,
      },
    }),

    onCloseItemForm: () => () => ({
      activeFormState: { id: '', type: '' },
    }),
  },
);

const withItemFormSubmitHandler = withHandlers({
  onItemFormSubmitSuccess:
    ({
      activeFormState: { type, itemIndex },
      items,
      onAddItem,
      onUpdateItem,
      onCloseItemForm,
    }) =>
    (result) => {
      if (type === 'update') {
        onUpdateItem(itemIndex, result);
        // Only allow unique items in the collection
        // @sean TODO Show error somehow
      } else if (
        items.every(({ getId, data }) => getId(data) !== getId(result))
      ) {
        onAddItem(result);
      }
      onCloseItemForm();
    },
});

/**
 * Ensure input items are a unique set by id
 */
const withUniqueItems = withPropsOnChange(['items'], ({ items = [] }) => ({
  items: uniqBy(items, ({ getId, data }) => getId(data)),
}));

// Components

/**
 * Simple wrapper for Bootstrap list-group
 */
function ListGroup({ className, children }) {
  return <ul className={`list-group ${className}`}>{children}</ul>;
}

ListGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

ListGroup.defaultProps = {
  className: '',
  children: null,
};

function ListGroupItem({ className, children }) {
  return <li className={`list-group-item ${className}`}>{children}</li>;
}

ListGroupItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

ListGroupItem.defaultProps = {
  className: '',
  children: null,
};

ListGroup.Item = ListGroupItem;

/**
 * Default list item rendering when no other component is provided
 */
function DefaultItemComponent({ id, className }) {
  return <span className={className}>{id}</span>;
}

DefaultItemComponent.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const SingleAddButton = withHandlers({
  onClick:
    ({ onClick, children }) =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(children[0].props.id);
    },
})(({ className, btnLabel, disabled, onClick }) => (
  <button
    className={`btn btn-info ${className}`}
    disabled={disabled}
    onClick={onClick}
  >
    {btnLabel}
  </button>
));

function DropdownAddButton({
  className,
  btnLabel,
  disabled,
  onClick,
  children,
}) {
  return (
    <DropdownButton disabled={disabled} className={className} label={btnLabel}>
      {React.Children.map(children, (child) => (
        <DropdownButton.Item
          key={child.props.id}
          id={child.props.id}
          onClick={onClick}
        >
          {child}
        </DropdownButton.Item>
      ))}
    </DropdownButton>
  );
}

DropdownAddButton.propTypes = {
  className: PropTypes.string,
  btnLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any,
};

DropdownAddButton.defaultProps = {
  className: '',
  disabled: false,
  children: null,
};

/**
 * This component supports both the case where all items in the
 * collection have the same builder form, or if items can be
 * built with multiple form types
 */
const AddItemButton = branch(
  ({ children }) => children.length === 1,
  renderComponent(SingleAddButton),
  renderComponent(DropdownAddButton),
)(null);

/* eslint-disable react/prop-types */

function NoContent({ noContentMessage, listHeaderComponent: ListHeader }) {
  return (
    <div className="collection-builder__list text-muted">
      <ListHeader className="collection-builder__list__header" />
      <div>{noContentMessage}</div>
    </div>
  );
}

function ItemList({
  items,
  draggable,
  activeFormState,
  onReorderItem,
  onRemoveItem,
  onSetUpdateForm,
  listHeaderComponent: ListHeader,
  listItemComponent: ListItemContent,
}) {
  const List = draggable ? DragDropList : ListGroup;

  return (
    <div className="collection-builder__list">
      <ListHeader className="collection-builder__list__header" />
      <List
        className="collection-builder__list__content"
        onMoveItem={onReorderItem}
      >
        {items.map(({ disabled, formId = defaultFormId, data, getId }, i) => {
          const id = getId(data);
          const removeItemHandler = (e) => {
            e.preventDefault();
            onRemoveItem(i);
          };
          const editItemHandler = (e) => {
            e.preventDefault();
            onSetUpdateForm(formId, id, i, data);
          };
          const isEditing = id !== undefined && id === activeFormState.itemId;

          return (
            <List.Item
              className="collection-builder__list-item"
              id={id}
              key={id}
            >
              <ListItemContent
                className="collection-builder__list-item__content"
                id={id}
                item={data}
              />
              <div className="collection-builder__list-item__actions">
                <button
                  className="btn btn-sm btn-outline-secondary btn--edit"
                  disabled={disabled || isEditing}
                  onClick={editItemHandler}
                >
                  Edit
                </button>
                <button
                  className="btn btn-sm btn-outline-danger btn--remove"
                  disabled={disabled || isEditing}
                  onClick={removeItemHandler}
                >
                  <Octicon name="trashcan" />
                </button>
              </div>
            </List.Item>
          );
        })}
      </List>
    </div>
  );
}

/* eslint-enable react/prop-types */

const ItemListWithGuard = branch(
  ({ items }) => items.length === 0,
  renderComponent(NoContent),
  renderComponent(ItemList),
)(null);

/**
 * Generic interface for building and editing collections of data.
 * Allows data in a list to be added, edited, removed, reordered.
 * Behaves likes a controlled input. The `items` prop should be updated
 * when the appropriate callbacks are called
 */
function CollectionBuilder({
  className,
  items,
  itemForms,
  draggable,
  activeFormState,
  addBtnLabel,
  noContentMessage,
  onSetAddForm,
  onSetUpdateForm,
  onRemoveItem,
  onReorderItem,
  onCloseItemForm,
  onItemFormSubmitSuccess,
  listHeaderComponent,
  listItemComponent,
}) {
  if (!itemForms) {
    throw new Error('You must provide at least one itemForm');
  }

  // Normalize case where only a single itemForm is specified
  itemForms = !Array.isArray(itemForms)
    ? [{ id: defaultFormId, label: '', component: itemForms }]
    : itemForms;

  const itemForm = find(itemForms, { id: activeFormState.id });

  return (
    <div className={cx('collection-builder', className)}>
      <div className="row justify-content-end pr-3 pb-3">
        <AddItemButton
          className="collection-builder__add-btn"
          disabled={Boolean(itemForm)}
          btnLabel={addBtnLabel}
          onClick={onSetAddForm}
        >
          {itemForms.map(({ id, label }) => (
            <span id={id} key={id}>
              {label}
            </span>
          ))}
        </AddItemButton>
      </div>
      {itemForm ? (
        <div
          key={
            // Key is important to ensure these forms mount / unmount properly
            activeFormState.type === 'update'
              ? `collection-builder-update-${activeFormState.itemId}`
              : `collection-builder-add-${itemForm.id}`
          }
          className="collection-builder__item-form"
        >
          <itemForm.component
            initialValues={activeFormState.initialValues}
            onClose={onCloseItemForm}
            onSubmitSuccess={onItemFormSubmitSuccess}
            isUpdate={activeFormState.type === 'update'}
            destroyOnUnmount={
              // Should actually be able to destroy this, but there is
              // a bug in Redux Form which prevents it. And since this
              // is false, we also  need to set enableReinitialize
              // See https://github.com/erikras/redux-form/issues/2564
              false
            }
            enableReinitialize
          />
        </div>
      ) : null}
      {
        <ItemListWithGuard
          items={items}
          draggable={draggable}
          noContentMessage={noContentMessage}
          activeFormState={activeFormState}
          onSetUpdateForm={onSetUpdateForm}
          onRemoveItem={onRemoveItem}
          onReorderItem={onReorderItem}
          listItemComponent={listItemComponent}
          listHeaderComponent={listHeaderComponent}
        />
      }
    </div>
  );
}

export const propTypes = {
  className: PropTypes.string,
  draggable: PropTypes.bool,
  addBtnLabel: PropTypes.string,
  /**
   * List of items which this builder operates on
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Whether to disable editing or removing this specific item
       */
      disabled: PropTypes.bool,
      /**
       * Raw data for the item
       */
      data: PropTypes.any.isRequired,
      /**
       * Id of form to use when editing this item. Defaults to the first
       * form provided
       */
      formId: PropTypes.string,
      /**
       * Transform to resolve a unique id for the item
       */
      getId: PropTypes.func.isRequired,
    }),
  ).isRequired,
  /**
   * Forms which will be used to add or edit items in the collection.
   *
   * When specifying a list of forms there will be a dropdown to select the
   * correct form to use. Forms should be given as `{ id, label, component }`
   *
   * You can also specify a single form
   *
   * Forms will be provided an `onClose` callback to indicate
   * that they should be closed, as well as `initialValues`, and
   * an `isUpdate` boolean when it is an update of existing data
   */
  itemForms: PropTypes.oneOfType([
    PropTypes.any, // suppresses react warning
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        /**
         * Label which appears in the "Add Item" dropdown
         */
        label: PropTypes.string.isRequired,
        /**
         * Component used to render the form
         */
        component: PropTypes.any.isRequired, // suppresses react warning
      }),
    ),
  ]).isRequired,
  /**
   * Text or node to render when there are no items in the collection
   */
  noContentMessage: PropTypes.node,
  /**
   * Get initial values when adding a new item
   */
  // eslint-disable-next-line react/no-unused-prop-types
  getNewItemInitialValues: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  onAddItem: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  onUpdateItem: PropTypes.func,
  onReorderItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  /**
   * Component which is used to render items in the collection list
   *
   * Will be passed `{ id, item }` as props
   */
  listItemComponent: PropTypes.func,
  /**
   * Component which will be used to render the list header. By default,
   * no header is rendered
   */
  listHeaderComponent: PropTypes.func,

  // Internal only
  /** @ignore */
  onCloseItemForm: PropTypes.func.isRequired,
  /** @ignore */
  activeFormState: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    itemId: PropTypes.string,
    itemIndex: PropTypes.number,
    initialValues: PropTypes.any,
  }).isRequired,
  /** @ignore */
  onSetAddForm: PropTypes.func.isRequired,
  /** @ignore */
  onSetUpdateForm: PropTypes.func.isRequired,
  /** @ignore */
  onItemFormSubmitSuccess: PropTypes.func.isRequired,
};

CollectionBuilder.propTypes = propTypes;

export default compose(
  defaultProps({
    className: '',
    draggable: true,
    addBtnLabel: 'Add Item',
    noContentMessage: 'No items',
    getNewItemInitialValues: noop,
    onReorderItem: noop,
    onRemoveItem: noop,
    onAddItem: noop,
    onUpdateItem: noop,
    listItemComponent: DefaultItemComponent,
    listHeaderComponent: () => null,
  }),
  withItemFormViewState,
  withItemFormSubmitHandler,
  withUniqueItems,
)(CollectionBuilder);
