import {
  combineValidators,
  composeValidators,
  isRequired,
  isOneOf,
  isAlphaNumeric,
} from 'revalidate';

import { campaignManagers } from 'source/utils/acl';

const campaignManagerUsernames = campaignManagers.map(
  (manager) => manager.username,
);

export default combineValidators({
  managerName: composeValidators(
    isRequired,
    isOneOf(campaignManagerUsernames)({ message: 'Unknown campaign manager' }),
  )('Campaign manager'),
  currency: composeValidators(
    isRequired,
    isOneOf(['EUR', 'GBP'])({ message: 'Unsupported currency' }),
  )('Currency'),
  accountingNumber: composeValidators(isAlphaNumeric)('Accounting Number'),
});
