import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactCountryFlag from 'react-country-flag';
import moment from 'moment';
import get from 'lodash/get';
import { FormattedNumber } from 'react-intl';

import Icon from 'source/components/common/icon';
import ExternalLink from 'source/scenes/components/externalLink';
import ChannelStatus from 'source/components/common/channelStatus';
import ChannelAvatar from 'source/components/common/channelAvatar';

// Helpers
const toPercent = (value) =>
  typeof value === 'number' ? (
    <FormattedNumber
      {...{ style: 'percent' }}
      maximumFractionDigits={2}
      value={value}
    />
  ) : (
    ''
  );
const toNumber = (value) =>
  Number.isInteger(value) ? <FormattedNumber value={value} /> : '';

const getIQDataUrl = (username) =>
  `https://iqdata.social/audience-data?urlCopy=${username}&platform=instagram`;

const toPercentArray = (mapObj) =>
  Object.keys(mapObj).reduce(
    (result, objKey) => [...result, { key: objKey, value: mapObj[objKey] }],
    [],
  );

function CountryLabel({ countryCode }) {
  return (
    <div className="country-label ">
      <ReactCountryFlag className="m-r-1" countryCode={countryCode} />
      <span>{countryCode}</span>
    </div>
  );
}

CountryLabel.propTypes = {
  countryCode: PropTypes.string.isRequired,
};

function Capsule({ className, children, label }) {
  return (
    <div className={cx('capsule', className)}>
      <span className="capsule-value">{children}</span>
      {label && <span className="capsule-label">{label}</span>}
    </div>
  );
}

Capsule.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
};
Capsule.defaultProps = {
  label: '',
  className: '',
};

function KeyMetricsEntry({ className, label, bold, children }) {
  return (
    <div className={cx('key-metrics-entry', className)}>
      <span className="key-metrics-entry-label">{label}</span>
      <div
        className={cx('key-metrics-entry-value', {
          bold,
        })}
      >
        {children}
      </div>
    </div>
  );
}

KeyMetricsEntry.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  bold: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
KeyMetricsEntry.defaultProps = {
  className: '',
  bold: false,
};

function InstagramKeyMetrics({ className, channelName, keyMetrics }) {
  const updatedByAt = get(keyMetrics, 'updatedByAt');
  const updatedBy = get(keyMetrics, 'updatedBy');
  const metricsValidAt = get(keyMetrics, 'metricsValidAt');
  const followers = get(keyMetrics, 'followers');
  const engagementRate = get(keyMetrics, 'engagementRate');
  const followersHistory = get(keyMetrics, 'followersHistory', []);
  const genders = get(keyMetrics, 'audience.gender', {});
  const ageGroups = get(keyMetrics, 'audience.ageRange', {});
  const countries = get(keyMetrics, 'audience.countries', {});
  const postsImpressions = get(keyMetrics, 'postsImpressions', []);
  const storiesImpressions = get(keyMetrics, 'storiesImpressions', []);
  const postImpressionRatio = get(keyMetrics, 'postImpressionRatio');
  const storyImpressionRatio = get(keyMetrics, 'storyImpressionRatio');

  const lastManualUpdate = updatedByAt
    ? `Last manual update on ${moment(updatedByAt).format(
        'L HH:mm ZZ',
      )} by ${updatedBy}`
    : '';

  const lastAutomaticUpdate = metricsValidAt
    ? `Last automatic update on ${moment(metricsValidAt).format(
        'L HH:mm ZZ',
      )} by IQData`
    : '';

  return (
    <div className={cx('key-metrics-container', className)}>
      <KeyMetricsEntry label="Report Date" bold>
        {metricsValidAt ? moment(metricsValidAt).format('DD/MM/YYYY') : ''}
      </KeyMetricsEntry>
      <KeyMetricsEntry label="IQData API">
        <ExternalLink className="bold" href={getIQDataUrl(channelName)}>
          Full Report
        </ExternalLink>
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Followers" bold>
        {toNumber(followers)}
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Followers History">
        <div className="capsule-container">
          {followersHistory.map((historyEntry) => (
            <Capsule label={historyEntry.month}>
              {toNumber(historyEntry.followers)}
            </Capsule>
          ))}
        </div>
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Engagement Rate" bold>
        {toPercent(engagementRate)}
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Impression Ratio">
        <div className="capsule-container">
          <Capsule label="Stories">{toPercent(storyImpressionRatio)}</Capsule>
          <Capsule label="Posts">{toPercent(postImpressionRatio)}</Capsule>
        </div>
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Countries">
        <div className="capsule-container">
          {toPercentArray(countries).map((countryEntry) => (
            <Capsule label={<CountryLabel countryCode={countryEntry.key} />}>
              {toPercent(countryEntry.value)}
            </Capsule>
          ))}
        </div>
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Genders">
        <div className="capsule-container">
          <Capsule label="female">{toPercent(genders.female)}</Capsule>
          <Capsule label="male">{toPercent(genders.male)}</Capsule>
        </div>
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Age Groups">
        <div className="capsule-container">
          {toPercentArray(ageGroups).map((countryEntry) => (
            <Capsule label={countryEntry.key}>
              {toPercent(countryEntry.value)}
            </Capsule>
          ))}
        </div>
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Impressions for Stories from last 30 days">
        <div className="capsule-container">
          {storiesImpressions.map((value) => (
            <Capsule>{toNumber(value)}</Capsule>
          ))}
        </div>
      </KeyMetricsEntry>
      <KeyMetricsEntry label="Impressions for Posts from last 30 days">
        <div className="capsule-container">
          {postsImpressions.map((value) => (
            <Capsule>{toNumber(value)}</Capsule>
          ))}
        </div>
      </KeyMetricsEntry>
      <span className="last-updated-label">{lastManualUpdate}</span>
      <span className="last-updated-label">{lastAutomaticUpdate}</span>
    </div>
  );
}

InstagramKeyMetrics.propTypes = {
  keyMetrics: PropTypes.object,
  className: PropTypes.string,
  channelName: PropTypes.string.isRequired,
};
InstagramKeyMetrics.defaultProps = {
  keyMetrics: {},
  className: '',
};

function ChannelInfo({ className, channel }) {
  return (
    <div className={className}>
      <ChannelAvatar
        className="application-channel-avatar-instagram"
        url={channel.avatarUrl}
      />
      <div className="channel-info-entry">
        <span className="channel-info-entry-label">Internal ID</span>
        <div className="channel-info-entry-value">
          <ExternalLink
            className="truncate bold"
            href={`/channels/${channel.id}#metrics`}
          >
            {channel.id}
          </ExternalLink>
        </div>
      </div>
      <div className="channel-info-entry">
        <span className="channel-info-entry-label">Channel URL</span>
        <div className="channel-info-entry-value">
          <ExternalLink className="truncate bold" href={channel.data.url}>
            {channel.data.url}
          </ExternalLink>
        </div>
      </div>
      <div className="channel-info-entry">
        <span className="channel-info-entry-label">Channel Type</span>
        <div className="channel-info-entry-value">Instagram</div>
      </div>
      <div className="channel-info-entry">
        <span className="channel-info-entry-label">Status</span>
        <div className="channel-info-entry-value">
          <ChannelStatus
            ready={channel.ready}
            readyState={channel.readyState}
          />
        </div>
      </div>
      <div className="channel-info-entry">
        <span className="channel-info-entry-label">
          Internal Note
          <Icon name="note" className="small-icon ml-2" />
        </span>
        <div className="channel-info-entry-value">{channel.notes}</div>
      </div>
      <span className="last-updated-label">
        Last update on {moment(channel.updatedAt).format('L HH:mm ZZ')}
      </span>
    </div>
  );
}

ChannelInfo.propTypes = {
  className: PropTypes.string,
  channel: PropTypes.object.isRequired,
};
ChannelInfo.defaultProps = {
  className: '',
};

function ChannelDetails({ application }) {
  return (
    <div className="channel-details">
      <ChannelInfo
        className="channel-details-info"
        channel={application.channel}
      />
      <InstagramKeyMetrics
        className="channel-details-key-metrics"
        channelName={application.channel.name}
        keyMetrics={application.channel.keyMetrics}
      />
    </div>
  );
}

ChannelDetails.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    channel: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      data: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      notes: PropTypes.string,
      keyMetrics: PropTypes.object,
      kpi: PropTypes.object,
    }).isRequired,
  }).isRequired,
  insights: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.object,
    data: PropTypes.object,
  }).isRequired,
};

export default ChannelDetails;
