import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import moment from 'moment';
import omit from 'lodash/omit';
import kebabCase from 'lodash/kebabCase';
import noop from 'lodash/noop';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import cx from 'classnames';
import {
  setPropTypes,
  defaultProps,
  withPropsOnChange,
  withHandlers,
  withProps,
  withState,
  branch,
  compose,
  lifecycle,
} from 'recompose';
import {
  ToolTipController,
  Select as ToolTipWrapper,
} from 'react-tooltip-controller';
import ReactFlagsSelect from 'react-flags-select';
import Icon from 'source/components/common/icon';
import PanelList from 'source/scenes/components/panelList';
import ExpandingPanel from 'source/scenes/components/expandingPanel';
import Bar from 'source/scenes/components/bar';
import LabeledMetric from 'source/scenes/components/labeledMetric';
import ConfirmSplitButton from 'source/scenes/components/confirmSplitButton';
import { filterOutTechnicalGroups } from 'source/scenes/components/groups';
import ChannelToolTip from './channelToolTip';
import ClientReviewToolTip from './clientReviewToolTip';
import DeleteApplicationModal from './deleteApplicationModal';

// Helper Components

const maxLength = 512;
const lineBreakRegEx = /(\r\n|\n|\r)/gm;
const nonBreakingSpaces = '\u00A0 \u00A0 \u00A0';

const killEvent = (e) => {
  e.currentTarget.blur();
  e.preventDefault();
  e.stopPropagation();
};

function DefaultPanelHeading({
  application: {
    user: { firstname },
  },
}) {
  return <span>{firstname}</span>;
}

DefaultPanelHeading.propTypes = {
  application: PropTypes.shape({
    user: PropTypes.shape({
      firstname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

function DefaultPanelBody() {
  return <span />;
}

function EmptyPanel() {
  return <div className="text-muted p-3">No applications.</div>;
}

function UnformattedValue({ value }) {
  return <span>{value}</span>;
}

UnformattedValue.propTypes = { value: PropTypes.any.isRequired };

function FormattedDate({ value }) {
  return <span>{moment(value).format('YYYY-MM-DD')}</span>;
}

FormattedDate.propTypes = { value: PropTypes.any.isRequired };

function FormattedPercentage({ value }) {
  return typeof value === 'number' ? (
    <span className="inline-block">
      <FormattedNumber
        {...{ style: 'percent' }}
        maximumFractionDigits={2}
        value={value}
      />
    </span>
  ) : (
    <span className="inline-block mr-2">-</span>
  );
}

FormattedPercentage.propTypes = { value: PropTypes.any.isRequired };

function GroupsBand({ groups }) {
  return (
    <div className="groups-band">
      {groups.map(({ name, color }) => (
        <div
          className="groups-band__item"
          key={name}
          title={name}
          style={{
            backgroundColor: color,
          }}
        />
      ))}
    </div>
  );
}

GroupsBand.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
};

// Helpers
const renderMetric = (metric) => {
  let { formatting = {} } = metric;
  const { value, defaultValue, isFirst } = metric;

  if (formatting.style === 'date') {
    formatting = { component: FormattedDate };
  }
  if (formatting.style === 'text') {
    formatting = { component: UnformattedValue };
  }
  if (formatting.style === 'percent') {
    formatting = { component: FormattedPercentage };
  }

  const Label = formatting.component || FormattedNumber;
  const labelProps = omit(formatting, ['component']);
  const currentLabel = !isNil(value) ? (
    <Label {...labelProps} value={value} />
  ) : (
    defaultValue || null
  );

  return (
    <LabeledMetric className={cx({ 'ml-3': isFirst })} value={currentLabel} />
  );
};

function CounterOfferEvent({ event, formatting, className }) {
  const {
    issuer: { given_name: firstName, family_name: lastName },
  } = event;

  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;

  return (
    <div className={className}>
      Counteroffer on <FormattedDate value={event.createdAt} /> by {initials}:{' '}
      <FormattedNumber
        value={event.payload.counterOffer.counterOfferPrice}
        {...formatting}
      />
    </div>
  );
}

CounterOfferEvent.propTypes = {
  event: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    issuer: PropTypes.shape({
      given_name: PropTypes.string.isRequired,
      family_name: PropTypes.string.isRequired,
    }),
    payload: PropTypes.shape({
      counterOffer: PropTypes.shape({
        counterOfferPrice: PropTypes.number,
      }),
    }).isRequired,
  }),
  formatting: PropTypes.object.isRequired,
  className: PropTypes.string,
};

function CounterOfferToolTip({ value, formatting, counterOfferEvents }) {
  const formattedValue = <FormattedNumber value={value} {...formatting} />;

  return (
    <div className="counter-offer-tooltip">
      <div className="title mb-1">Counteroffer</div>
      {counterOfferEvents.map((event) => (
        <CounterOfferEvent
          key={event.id}
          className="title mb-1"
          event={event}
          formatting={formatting}
        />
      ))}
      <div>Standard price: {formattedValue}</div>
    </div>
  );
}

CounterOfferToolTip.propTypes = {
  value: PropTypes.number.isRequired,
  formatting: PropTypes.object.isRequired,
  counterOfferEvents: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
      issuer: PropTypes.shape({
        given_name: PropTypes.string.isRequired,
        family_name: PropTypes.string.isRequired,
      }),
      payload: PropTypes.shape({
        counterOffer: PropTypes.shape({
          counterOfferPrice: PropTypes.number,
        }),
      }).isRequired,
    }),
  ),
};

const renderCounterOffer = (metric, applicationEvents) => {
  const {
    value: { influencerTotalPrice, influencerOriginalPrice },
    formatting,
  } = metric;

  const tooltip = (
    <ToolTipController detect="hover" offsetY={6}>
      <ToolTipWrapper>
        <div>
          <FormattedNumber value={influencerTotalPrice} {...formatting} />
        </div>
      </ToolTipWrapper>
      <CounterOfferToolTip
        value={influencerOriginalPrice}
        formatting={formatting}
        counterOfferEvents={applicationEvents.filter(
          (event) => event.type === 'application_counter_offer_changed',
        )}
      />
    </ToolTipController>
  );

  return <LabeledMetric className="counter-offer" value={tooltip} />;
};

function NotesToolTip({ notes }) {
  const formattedNote = `${notes.replace(lineBreakRegEx, nonBreakingSpaces)}`;

  const truncateNote = (formattedNote) =>
    formattedNote.length > maxLength
      ? `${formattedNote.substring(0, maxLength)}...`
      : formattedNote;

  return (
    <div className="notes-tooltip">
      <p>{truncateNote(formattedNote)}</p>
    </div>
  );
}

NotesToolTip.propTypes = {
  notes: PropTypes.string.isRequired,
};

const renderChannel = (channel) => (
  <div className="d-flex align-items-center channel-icon ml-2">
    <Icon name={channel.platform} className="small-icon mr-2" />
    <span className="truncated-channel-name">{channel.name}</span>

    {channel.notes && (
      <ToolTipController id={channel.id} detect="hover" offsetY={6}>
        <ToolTipWrapper>
          <Icon name="note" className="small-icon mx-2" />
        </ToolTipWrapper>
        <NotesToolTip notes={channel.notes} />
      </ToolTipController>
    )}
  </div>
);

const renderChannels = (channels, application) => (
  <div className="d-flex flex-wrap align-items-center channel-icon mx-1">
    {channels.map((channel) => (
      <ToolTipController
        key={channel.id}
        id={channel.id}
        detect="hover"
        offsetY={6}
      >
        <ToolTipWrapper>
          <Icon
            name={channel.platform}
            className={cx('mr-1', {
              'small-icon': channels.length <= 6,
              'tiny-icon': channels.length > 6,
              'micro-icon': channels.length > 14,
            })}
          />
        </ToolTipWrapper>
        <ChannelToolTip
          channel={channel}
          priceFormatting={{
            style: 'currency',
            currency: get(application, 'match.payment.currency'),
          }}
        />
      </ToolTipController>
    ))}
  </div>
);

const renderTopCountry = (countries) => {
  const sortedCountries = Object.keys(countries).sort(
    (a, b) => countries[b] - countries[a],
  );

  const topCountry = sortedCountries[0];

  return topCountry ? (
    <span className="inline-block mr-2 d-flex align-items-center">
      <ReactFlagsSelect
        className="mr-1 disabled-flags"
        selectButtonClassName="mr-1 disabled-flags"
        selected={topCountry}
        fullWidth={false}
        disabled
        showSelectedLabel={false}
        showSecondarySelectedLabel={false}
        showOptionLabel={false}
      />
      <FormattedPercentage value={countries[topCountry]} />
    </span>
  ) : (
    <span className="inline-block w-100 text-center">-</span>
  );
};

const renderClientApplicationReview = (clientApplicationReview) => {
  if (
    isEmpty(clientApplicationReview) ||
    clientApplicationReview.status === 'pending'
  ) {
    return null;
  }

  return (
    <ToolTipController detect="hover" offsetY={6}>
      <ToolTipWrapper>
        <div className="d-flex justify-content-center w-100">
          {clientApplicationReview.status === 'accepted' && (
            <Icon
              className="small-icon mr-2"
              name="thumbsup"
              stroke="#4CAF50"
            />
          )}
          {clientApplicationReview.status === 'rejected' && (
            <Icon
              className="small-icon mr-2"
              name="thumbsdown"
              stroke="#F44336"
            />
          )}
          {clientApplicationReview.status === 'undecided' && (
            <Icon className="small-icon mr-2" name="minus" stroke="#00ACE7" />
          )}
          {clientApplicationReview.note && (
            <Icon name="note" className="small-icon" />
          )}
        </div>
      </ToolTipWrapper>
      <ClientReviewToolTip value={clientApplicationReview} />
    </ToolTipController>
  );
};

const renderPanelMetrics = ({ metrics, application }) =>
  metrics.map(
    (
      { type, value, defaultValue, label, appearance = {}, formatting = {} },
      index,
    ) => (
      <Bar.Item
        key={label}
        className={cx(
          'application-detail-header__metric',
          `application-detail-header__metric-${kebabCase(label)}`,
          'truncate',
          { 'align-left': appearance.alignment === 'left' },
          { 'align-right': appearance.alignment === 'right' },
          { 'align-center': appearance.alignment === 'center' },
          { bold: appearance.bold },
        )}
      >
        {type === 'metric' &&
          renderMetric({ value, defaultValue, formatting, isFirst: !index })}
        {type === 'counterOffer' &&
          renderCounterOffer({ value, formatting }, application.events)}
        {type === 'channel' && renderChannel(value)}
        {type === 'channels' && renderChannels(value, application)}
        {type === 'country' && renderTopCountry(value)}
        {type === 'review' && renderClientApplicationReview(value)}
      </Bar.Item>
    ),
  );

// render user groups
const renderGroups = ({ groups }) =>
  groups.length
    ? [
        <Bar.Item key="groups" className="application-detail-header__groups">
          <GroupsBand groups={groups} />
        </Bar.Item>,
      ]
    : null;

// HoCs
const withPanelState = withState('selectedPanel', 'onSelectPanel', '');

const withPanels = compose(
  withPropsOnChange(
    ['campaign', 'applications'],
    ({ applications, campaign, getPanelMetrics }) => ({
      panels: applications.map((application) => ({
        application,
        metrics: getPanelMetrics({
          application,
          campaign,
        }),
      })),
    }),
  ),
  /**
   * FEATURE
   *    allow one application panel to be opened through the url param
   */
  withPropsOnChange(['match'], ({ match, onSelectPanel }) => {
    if (match.params && match.params.applicationId) {
      onSelectPanel(match.params.applicationId);
    }
  }),
);

const withActionClickHandlers = compose(
  setPropTypes({
    application: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func,
    onReject: PropTypes.func,
    onDelete: PropTypes.func.isRequired,
    extraHandlers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        handler: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
      }),
    ).isRequired,
  }),
  branch(
    ({ onAccept }) => Boolean(onAccept),
    withHandlers({
      onAccept:
        ({ application, onAccept, onClose }) =>
        (e) => {
          e.stopPropagation();
          e.preventDefault();
          onClose();
          onAccept(application.id);
        },
    }),
  ),
  branch(
    ({ onReject }) => Boolean(onReject),
    withHandlers({
      onReject:
        ({ application, onReject, onClose }) =>
        (e) => {
          e.stopPropagation();
          e.preventDefault();
          onClose();
          onReject(application.id);
        },
    }),
  ),
  withHandlers({
    onDelete:
      ({ application, onDelete, onClose }) =>
      (e, notificationEmailPayload) => {
        e.stopPropagation();
        e.preventDefault();
        onClose();
        onDelete(application.id, notificationEmailPayload);
      },
  }),
  branch(
    ({ acceptBtnLabel }) => Boolean(acceptBtnLabel),
    withProps(({ extraHandlers, application, onClose }) => ({
      extraHandlers: extraHandlers.map((item) => ({
        ...item,
        handler: (e) => {
          e.stopPropagation();
          e.preventDefault();
          onClose();
          item.handler(application.id);
        },
      })),
    })),
  ),
);

/**
 * Handle panel click and sync with location
 */
const withPanelClickHandler = withHandlers({
  handlePanelSelect:
    ({
      application,
      campaign,
      expanded,
      onClick,
      syncApplicationWithLocation,
    }) =>
    () => {
      onClick(application.id);
      expanded
        ? syncApplicationWithLocation(campaign)
        : syncApplicationWithLocation(campaign, application);
    },
});

/**
 * This will be provided to the panel body so that it has the ability
 * to collapse the panel it is inside
 */
const withCloseHandler = withHandlers({
  onClose:
    ({ onSelectPanel }) =>
    () =>
      onSelectPanel(''),
});

const withUpdateOnExpandOnly = lifecycle({
  shouldComponentUpdate(nextProps) {
    return this.props.expanded || this.props.expanded !== nextProps.expanded;
  },
});

// Components

function DetailPanel({
  id,
  expanded,
  metrics,
  userGroups,
  acceptBtnLabel,
  warning,
  handlePanelSelect,
  onAccept,
  onReject,
  onDelete,
  extraHandlers,
  children,
  application,
}) {
  const [applicationStateMenuOpen, setApplicationStateMenuOpen] =
    React.useState();
  const [rejectMenuOpen, setRejectMenuOpen] = React.useState();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const handleRejectMenuClick = (e) => {
    killEvent(e);

    if (applicationStateMenuOpen) {
      setApplicationStateMenuOpen(false);
    }

    setRejectMenuOpen(!rejectMenuOpen);
  };

  const handleApplicationStateMenuClick = (e) => {
    killEvent(e);

    if (rejectMenuOpen) {
      setRejectMenuOpen(false);
    }

    setApplicationStateMenuOpen(!applicationStateMenuOpen);
  };

  return (
    <>
      <DeleteApplicationModal
        isOpen={deleteModalOpen}
        onConfirm={onDelete}
        onClose={() => setDeleteModalOpen(false)}
        application={application}
      />
      <ExpandingPanel
        id={id}
        expanded={expanded}
        className={cx('application-detail-panel', {
          'application-detail-panel--warning': warning,
        })}
      >
        <ExpandingPanel.ClickableHeader onClick={handlePanelSelect}>
          <Bar className="application-item__header">
            {renderGroups({ groups: userGroups })}
            {renderPanelMetrics({ metrics, application })}

            <Bar.Item className="application-detail-header__accept">
              {acceptBtnLabel ? (
                <ConfirmSplitButton
                  className="btn-sm btn-success"
                  isMenuOpen={applicationStateMenuOpen}
                  onMenuClick={handleApplicationStateMenuClick}
                  onMenuItemClick={() => setApplicationStateMenuOpen(false)}
                  isMenuOpenOverride={applicationStateMenuOpen}
                  onConfirm={onAccept}
                  menuItems={extraHandlers}
                  disabled={!onAccept}
                >
                  {acceptBtnLabel}
                </ConfirmSplitButton>
              ) : (
                <div className="fake-button" />
              )}
              {onReject ? (
                <ConfirmSplitButton
                  className="btn-sm btn-danger"
                  rootClassName="ml-1"
                  isMenuOpen={rejectMenuOpen}
                  onMenuClick={handleRejectMenuClick}
                  onMenuItemClick={() => setRejectMenuOpen(false)}
                  onConfirm={onReject}
                  menuOffsetX={-155}
                  menuItems={[
                    {
                      name: 'Reject for campaign',
                      handler: onReject,
                      icon: '🛑',
                    },
                    {
                      name: 'Delete application (allow to reapply)',
                      skipConfirmation: true,
                      handler: () => setDeleteModalOpen(true),
                      icon: '❌',
                    },
                  ]}
                >
                  Reject
                </ConfirmSplitButton>
              ) : (
                <ConfirmSplitButton
                  className="btn-sm btn-danger"
                  rootClassName="ml-1"
                  isMenuOpen={rejectMenuOpen}
                  onMenuClick={handleRejectMenuClick}
                  onMenuItemClick={() => setRejectMenuOpen(false)}
                  onConfirm={() => setDeleteModalOpen(true)}
                  menuOffsetX={-155}
                  menuItems={[
                    {
                      name: 'Delete application (allow to reapply)',
                      skipConfirmation: true,
                      handler: () => setDeleteModalOpen(true),
                      icon: '❌',
                    },
                    {
                      name: 'Reject for campaign',
                      handler: onReject,
                      disabled: true,
                      icon: '🛑',
                    },
                  ]}
                >
                  Delete
                </ConfirmSplitButton>
              )}
            </Bar.Item>
          </Bar>
        </ExpandingPanel.ClickableHeader>
        <ExpandingPanel.Body disableTransition>{children}</ExpandingPanel.Body>
      </ExpandingPanel>
    </>
  );
}

DetailPanel.propTypes = {
  id: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  metrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  warning: PropTypes.bool,
  userGroups: PropTypes.array.isRequired,
  acceptBtnLabel: PropTypes.string,
  handlePanelSelect: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
  extraHandlers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
    }),
  ).isRequired,
  children: PropTypes.any,
};

const DetailPanelEnhanced = compose(
  withActionClickHandlers,
  withPanelClickHandler,
  withCloseHandler,
  withUpdateOnExpandOnly,
)(DetailPanel);

function DetailPanelList({
  panels,
  campaign,
  acceptBtnLabel,
  selectedPanel,
  onSelectPanel,
  hasWarning,
  onClose,
  onAccept,
  onReject,
  onDelete,
  extraHandlers,
  label,
  panelHeading,
  panelBody: PanelBody,
  syncApplicationWithLocation,
}) {
  return (
    <PanelList
      disableTransition
      selectedPanel={selectedPanel}
      onSelectPanel={onSelectPanel}
    >
      {!panels.length ? <EmptyPanel /> : null}
      {panels.map(({ application, metrics }) => (
        <DetailPanelEnhanced
          id={application.id}
          key={application.id}
          application={application}
          groups={application.groups || []}
          userGroups={filterOutTechnicalGroups(application.groups || [])}
          campaign={campaign}
          metrics={metrics}
          warning={hasWarning({ campaign, application })}
          acceptBtnLabel={acceptBtnLabel}
          label={label}
          onClose={onClose}
          onAccept={onAccept}
          onReject={onReject}
          onDelete={onDelete}
          extraHandlers={extraHandlers}
          panelHeading={panelHeading}
          syncApplicationWithLocation={syncApplicationWithLocation}
        >
          <PanelBody
            campaign={campaign}
            application={application}
            onClose={onClose}
          />
        </DetailPanelEnhanced>
      ))}
    </PanelList>
  );
}

DetailPanelList.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  applications: PropTypes.arrayOf(PropTypes.object).isRequired,
  campaign: PropTypes.object.isRequired,
  /**
   * Function which yields a list of metrics to display in the panel
   */
  // eslint-disable-next-line react/no-unused-prop-types
  getPanelMetrics: PropTypes.func.isRequired,
  /**
   * Indicated whether to render a warning for the application
   */
  hasWarning: PropTypes.func,
  /**
   * Component used to render the left-most element of the panel. Receives
   * the campaign and application as parameters
   */
  panelHeading: PropTypes.func.isRequired,
  /**
   * Component used to render the body of the panel. Receives the
   * campaign and application as parameters
   */
  panelBody: PropTypes.func.isRequired,
  acceptBtnLabel: PropTypes.string,
  label: PropTypes.string,
  selectedPanel: PropTypes.string,
  onSelectPanel: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  extraHandlers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  syncApplicationWithLocation: PropTypes.func.isRequired,

  // Internal

  /** @ignore */
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      application: PropTypes.object.isRequired,
      metrics: PropTypes.array.isRequired,
    }),
  ),
  /** @ignore */
  onClose: PropTypes.func.isRequired,
};

export default compose(
  defaultProps({
    acceptBtnLabel: '',
    hasWarning: noop,
    panelHeading: DefaultPanelHeading,
    panelBody: DefaultPanelBody,
  }),
  withPanelState,
  withPanels,
  withCloseHandler,
)(DetailPanelList);
