import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';

import config from 'config';

import {
  withSubmissionValidation,
  withNextPanelAfterSubmission,
} from '../../enhancers';

const submitSettings =
  ({ onUpdateCampaign }) =>
  (values, __, props) => {
    !values.privateNetworkId && (values.privateNetworkId = null);
    !values.clientId && (values.clientId = null);

    return onUpdateCampaign(props.campaign.id, {
      settings: {
        ...values,
        managerName: `${values.managerName}@${config.primaryDomain}`,
      },
    });
  };

export const withSubmissionHandler = compose(
  setPropTypes({
    campaign: PropTypes.shape({ id: PropTypes.string.isRequired }),
    onUpdateCampaign: PropTypes.func.isRequired,
  }),
  withHandlers({ onSubmit: submitSettings }),
  withSubmissionValidation,
  withNextPanelAfterSubmission,
);
