import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import Octicon from 'react-octicon';
import { compose, setPropTypes } from 'recompose';

import Alert from 'source/components/common/alert';
import {
  ProgressBar,
  SearchBar,
  Dropdown,
  DownloadButton,
} from 'source/components/common';
import { Checkbox } from 'source/components/common/forms';
import { campaignManagers } from 'source/utils/acl';
import withResetScene from 'source/components/commonEnhancers/withResetScene';

import CampaignsTable from './table';
import actions from '../actions';
import { getCampaignsState } from '../selectors';

const enhance = compose(
  connect(getCampaignsState, actions),
  setPropTypes({
    campaignIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  withResetScene,
);

class Campaigns extends React.PureComponent {
  state = {
    displaySummaryReportNotAvailableAlert: false,
  };

  componentDidMount() {
    const { onMount } = this.props;

    return onMount();
  }

  handleFilterChangeAndReload = (filter, value) => {
    const { onFilterChange, onMount } = this.props;

    onFilterChange(filter, value);
    if (filter === 'campaignType') {
      // Clear the search query when the campaignType is changed.
      this.handleSearchChange('');
    }

    return onMount();
  };

  handleSearchChange = (query) => this.props.onFilterChange('term', query);

  handleSubmitSearch = () => this.props.onMount();

  handleFilterChangeFromEvent = (e) => {
    e.preventDefault();
    return this.handleFilterChangeAndReload(e.target.id, e.target.value);
  };

  handleSummaryReportClick = () =>
    this.setState({ displaySummaryReportNotAvailableAlert: true });

  handleAlertClose = () =>
    this.setState({ displaySummaryReportNotAvailableAlert: false });

  renderFilterAllowApplications({ className = '' } = {}) {
    const {
      filters: { allowApplications },
    } = this.props;

    return (
      <div className={cx('col-md-3', className)}>
        <form onChange={this.handleFilterChangeFromEvent}>
          <small className="form-text text-muted">Allow Applications:</small>
          <select
            id="allowApplications"
            className="form-control custom-select"
            defaultValue={allowApplications}
          >
            <option value="">Show All</option>
            <option value="open">Only Opened</option>
            <option value="closed">Only Closed</option>
          </select>
        </form>
      </div>
    );
  }

  renderFilterManager({ className = '' } = {}) {
    const {
      filters: { manager },
    } = this.props;

    return (
      <div className={cx('col-md-3', className)}>
        <form onChange={this.handleFilterChangeFromEvent}>
          <small className="form-text text-muted">Manager:</small>
          <select
            id="manager"
            className="form-control custom-select"
            defaultValue={manager}
          >
            <option value="">All Managers</option>
            {campaignManagers.map((manager) => (
              <option key={manager.username} value={manager.username}>
                {manager.name}
              </option>
            ))}
          </select>
        </form>
      </div>
    );
  }

  renderFilterCampaignType({ className = '' } = {}) {
    const {
      filters: { campaignType },
    } = this.props;

    return (
      <div className={cx('col-md-3', className)}>
        <form onChange={this.handleFilterChangeFromEvent}>
          <small className="form-text text-muted">Campaign Type:</small>
          <select
            id="campaignType"
            className="form-control custom-select"
            defaultValue={campaignType}
          >
            <option value="website">Website</option>
            <option value="instagram">Instagram</option>
            <option value="youtube">YouTube</option>
            <option value="tiktok">TikTok</option>
            <option value="pinterest">Pinterest</option>
          </select>
        </form>
      </div>
    );
  }

  renderShowArchivedCampaigns({ className = '' } = {}) {
    const {
      filters: { showArchived },
    } = this.props;

    return (
      <div className={cx('col-md-3 d-flex pt-3 align-self-center', className)}>
        <Checkbox
          id="showArchived"
          label="Show Archived and Done"
          checked={showArchived}
          onClick={this.handleFilterChangeAndReload}
          managed
        />
      </div>
    );
  }

  renderCampaignsTable() {
    const { campaigns, page, pages, counter, onSelectPage } = this.props;

    return (
      <div className="col">
        <h2>
          Campaigns
          <span>
            ({counter.count}/{counter.total})
          </span>
        </h2>
        <div className="row">
          <div className="col-lg-10 offset-lg-2 col-md-12">
            <div className="row justify-content-end pb-3">
              {this.renderFilterCampaignType()}
              {this.renderFilterAllowApplications({ className: 'pl-3' })}
              {this.renderFilterManager({ className: 'pl-3' })}
              {this.renderShowArchivedCampaigns({ className: 'pl-3' })}
            </div>
          </div>
        </div>
        <CampaignsTable
          campaigns={campaigns}
          page={page}
          pages={pages}
          onSelectPage={onSelectPage}
        />
      </div>
    );
  }

  renderNewCampaignButton() {
    const {
      filters: { campaignType },
    } = this.props;

    if (campaignType === 'instagram') {
      return (
        <Link
          to="/campaigns/instagram/new"
          className="btn btn-outline-primary btn-sub-nav"
        >
          Add Instagram Campaign
          <Octicon name="plus" />
        </Link>
      );
    }

    if (campaignType === 'youtube') {
      return (
        <Link
          to="/campaigns/youtube/new"
          className="btn btn-outline-primary btn-sub-nav"
        >
          Add YouTube Campaign
          <Octicon name="plus" />
        </Link>
      );
    }

    if (campaignType === 'tiktok') {
      return (
        <Link
          to="/campaigns/tiktok/new"
          className="btn btn-outline-primary btn-sub-nav"
        >
          Add TikTok Campaign
          <Octicon name="plus" />
        </Link>
      );
    }

    if (campaignType === 'pinterest') {
      return (
        <Link
          to="/campaigns/pinterest/new"
          className="btn btn-outline-primary btn-sub-nav"
        >
          Add Pinterest Campaign
          <Octicon name="plus" />
        </Link>
      );
    }

    return (
      <Dropdown
        label="Add"
        className="btn-sub-nav"
        btnClassName="btn-outline-primary"
        menuRight
      >
        <Dropdown.Item id="websiteBucket">
          <Link
            to="/campaigns/website-bucket/new"
            className="btn btn-outline-primary btn-sub-nav"
          >
            Website Bucket Campaign
          </Link>
        </Dropdown.Item>
        <Dropdown.Item id="websiteBudget">
          <Link
            to="/campaigns/website-budget/new"
            className="btn btn-outline-primary btn-sub-nav"
          >
            Website Budget Campaign
          </Link>
        </Dropdown.Item>
      </Dropdown>
    );
  }

  render() {
    const {
      state,
      filters: { term, campaignType },
      timelineReportUrl,
    } = this.props;

    const stateLoaded = state === 'loaded';

    return (
      <div className="container">
        <div className="row justify-content-end pr-3">
          <DownloadButton href={timelineReportUrl} iconName="calendar">
            Timeline Report
          </DownloadButton>
          <button
            onClick={this.handleSummaryReportClick}
            className={cx(
              'btn',
              'btn-outline-primary',
              'btn-download',
              'btn-sub-nav',
              {
                disabled: campaignType !== 'website',
              },
            )}
            disabled={campaignType !== 'website'}
          >
            Summary Report
            <Octicon name="cloud-download" />
          </button>
          {this.renderNewCampaignButton()}
        </div>

        {this.state.displaySummaryReportNotAvailableAlert && (
          <Alert
            message={
              <>
                Oh, hello
                <br />
                This report is no longer available.
                <br />
                If you miss it, please tell Gabriel.
                <br />
                Thanks!
              </>
            }
            type="warning"
            onClose={this.handleAlertClose}
          />
        )}

        <div className="row pt-3">
          <SearchBar
            className="col"
            query={term}
            placeholder="Search campaigns by title"
            onInputChange={this.handleSearchChange}
            onSubmit={this.handleSubmitSearch}
          />
        </div>

        <div className="row pt-5">
          {stateLoaded ? this.renderCampaignsTable() : <ProgressBar.Mega />}
        </div>
      </div>
    );
  }
}

Campaigns.propTypes = {
  state: PropTypes.string.isRequired,
  campaigns: PropTypes.array,
  page: PropTypes.any,
  pages: PropTypes.any,
  filters: PropTypes.shape({
    showArchived: PropTypes.bool.isRequired,
    term: PropTypes.string.isRequired,
    manager: PropTypes.string.isRequired,
    campaignType: PropTypes.string.isRequired,
    allowApplications: PropTypes.string.isRequired,
  }).isRequired,
  counter: PropTypes.shape({
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  timelineReportUrl: PropTypes.string.isRequired,
  onMount: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

Campaigns.defaultProps = {
  campaigns: [],
  page: null,
  pages: null,
};

export default enhance(Campaigns);
