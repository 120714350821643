import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import cx from 'classnames';
import get from 'lodash/get';
import merge from 'lodash/merge';

import Badge from 'source/components/common/badge';

class CollapsiblePanel extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      expanded: this.props.expanded,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { expanded } = this.props;

    // if `expanded` state comes through the props, we update our internal state
    if (expanded !== nextProps.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
  }

  toggleExpanded() {
    if (this.props.disableToggle) {
      return;
    }
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  handleClick = (e) => {
    const { disabled, onClick, disableToggle } = this.props;
    const { expanded } = this.state;

    e.preventDefault();

    if (disabled || disableToggle) {
      return;
    }

    this.toggleExpanded();
    // notify optional `onClick` handler
    if (onClick) {
      onClick(this.props.id, !expanded);
    }
  };

  renderBody() {
    const { disabled, bodyClassName, children } = this.props;
    const { expanded } = this.state;

    // don't render body if panel is disabled
    if (disabled) {
      return null;
    }

    const updatedChildren = Children.map(children, (child) =>
      cloneElement(child, { expanded }),
    );

    return (
      <div
        className={cx('card-body', bodyClassName, {
          collapse: !expanded,
          'collapse show': expanded,
        })}
        aria-expanded={expanded}
      >
        {updatedChildren}
      </div>
    );
  }

  render() {
    const {
      id,
      index,
      headline,
      subHeadline,
      disabled,
      panelIcon,
      className,
      headerClassName: headerClassNameProp,
    } = this.props;
    const { expanded } = this.state;

    const headerClassName = cx('card-header', headerClassNameProp, {
      'bg-primary text-white': expanded,
      'cursor-not-allowed': disabled,
      'cursor-pointer': !disabled,
    });

    return (
      <div className={cx('collapsible-panel', 'card', className)}>
        <div id={id} className={headerClassName} onClick={this.handleClick}>
          {panelIcon}
          <h5>
            {index && (
              <Badge
                type={expanded ? 'info' : 'secondary'}
                className="collapsible-index mr-3"
              >
                {index}
              </Badge>
            )}
            {headline}
            {subHeadline && (
              <small className={cx({ 'text-muted': !expanded })}>
                : {subHeadline}
              </small>
            )}
          </h5>
        </div>
        {this.renderBody()}
      </div>
    );
  }
}

CollapsiblePanel.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  headline: PropTypes.node,
  index: PropTypes.node,
  subHeadline: PropTypes.node,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  bodyClassName: PropTypes.string,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disableToggle: PropTypes.bool,
  panelIcon: PropTypes.node,
};

CollapsiblePanel.defaultProps = {
  headline: null,
  index: null,
  subHeadline: null,
  expanded: false,
  disabled: false,
  disableToggle: false,
  bodyClassName: undefined,
  className: undefined,
  headerClassName: '',
  onClick: undefined,
  children: null,
  panelIcon: null,
};

export default pure(CollapsiblePanel);

/* == redux helpers for the collapsible component == */

export const redux = {
  ActionTypes: {
    TOGGLE: '@@COLLAPSIBLE_PANEL/TOGGLE',
    COLLAPSE_ALL: '@@COLLAPSIBLE_PANEL/COLLAPSE_ALL',
  },

  /* == action creators */
  toggleActionCreator: (name) => (id, toggleFlag, router) => {
    // sync selected panel with location
    if (router) {
      const hash = toggleFlag ? `#${id}` : '';
      const nextLocation = `${router.location.pathname}${hash}`;
      router.history.replace(nextLocation);
    }

    return {
      type: redux.ActionTypes.TOGGLE,
      name,
      payload: { id },
    };
  },

  collapseAllActionCreator: (name) => (router) => {
    // sync selected panel with location
    if (router) {
      const nextLocation = router.location.pathname;
      router.history.replace(nextLocation);
    }

    return {
      type: redux.ActionTypes.COLLAPSE_ALL,
      name,
    };
  },

  /* == reducers == */
  reducers: {
    /**
     * Keeps track of multiple panels that can be toggle independently.
     */
    toggle:
      (name) =>
      (state = { expanded: {} }, action) => {
        if (action.name === name) {
          if (action.type === redux.ActionTypes.TOGGLE) {
            const { id } = action.payload;

            return merge({}, state, {
              expanded: {
                [id]: !get(state, ['expanded', id], false),
              },
            });
          }

          if (action.type === redux.ActionTypes.COLLAPSE_ALL) {
            return { expanded: {} };
          }

          return state;
        }
        return state;
      },

    /**
     * keeps track of one panel expansion status, which means only one item can
     * be open at the same time
     */
    distinctToggle:
      (name) =>
      (state = { expanded: null }, action) => {
        if (action.name === name) {
          if (action.type === redux.ActionTypes.TOGGLE) {
            return merge({}, state, {
              expanded:
                action.payload.id === state.expanded ? null : action.payload.id,
            });
          }

          if (action.type === redux.ActionTypes.COLLAPSE_ALL) {
            return { expanded: null };
          }

          return state;
        }
        return state;
      },
  },

  // selectors
  selectors: {
    getExpanded: (state) => state.expanded,
  },
};
