import {
  asyncActionCreator,
  keyedAsyncActionCreator,
} from '@blogfoster/redux-async-utils';
import compose from 'lodash/fp/compose';
import pick from 'lodash/pick';
import first from 'lodash/first';
import keyBy from 'lodash/keyBy';

import { actionAuthRequest } from 'source/utils/axios';

export const actionTypes = {
  FETCH_MANY: 'data/campaigns/FETCH_MANY',
  FETCH: 'data/campaigns/FETCH',
  UPDATE: 'data/campaigns/UPDATE',
  CREATE: 'data/campaigns/CREATE',
  DELETE: 'data/campaigns/DELETE',
  CLEAR: 'data/campaigns/CLEAR',
};

/**
 * wrapper for "campaign attach upload" to allow uploading multiple attachments with one call
 */
const attachWithMultiUploads =
  (attachFn) =>
  (campaignId, uploads = []) =>
  (dispatch) => {
    const promises = uploads.map((upload) =>
      dispatch(attachFn(campaignId, upload)),
    );

    return Promise.all(promises).then((results) =>
      // @see source/actions/campaignForm#L377 - pick the response with the most uploads
      first(
        results.sort((a, b) => b.data.uploads.length - a.data.uploads.length),
      ),
    );
  };

/**
 * wrapper for "campaign attach uploads" for better client-side data handling
 *
 * @param {Array<Object>} uploads - uploads that should be attached to the campaign
 */
const attachWithMergedInput =
  (attachFn) =>
  (campaignId, uploads = []) =>
  (dispatch) => {
    if (!uploads || !uploads.length) {
      return Promise.resolve([]);
    }

    const uploadsByUrl = keyBy(uploads, 'url');

    const attach = () => dispatch(attachFn(campaignId, uploads));
    const transform = (resp) => {
      if (!resp || resp.statusCode >= 400) {
        return resp;
      }

      return {
        ...resp,
        data: {
          ...resp.data,
          uploads: resp.data.uploads.map((upload) => ({
            ...uploadsByUrl[upload.url],
            ...upload,
          })),
        },
      };
    };

    return Promise.resolve().then(attach).then(transform);
  };

const attachEnhancer = compose(attachWithMergedInput, attachWithMultiUploads);

/**
 * GET /v2/campaigns/{campaignId}
 */
export const campaignFetch = (name) =>
  keyedAsyncActionCreator(
    actionTypes.FETCH,
    (campaignId, { fields = [] } = {}) =>
      (dispatch, getState) => {
        const params = {};

        if (fields && fields.length > 0) {
          params.fields = JSON.stringify(fields);
        }

        return actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
        }).get(`/v2/campaigns/${campaignId}`, { params });
      },
    { name },
  );

/**
 * GET /v2/campaigns
 */
export const campaignFetchMany = (name) =>
  asyncActionCreator(
    actionTypes.FETCH_MANY,
    ({ page = 0, fields = [], filters = [] }) =>
      (dispatch, getState) => {
        const params = { page: page + 1 };

        if (filters && filters.length > 0) {
          params.filters = JSON.stringify(filters);
        }

        if (fields && fields.length > 0) {
          params.fields = JSON.stringify(fields);
        }

        return (
          actionAuthRequest(dispatch, getState, { dispatchErrors: false })
            .get('/v2/campaigns', { params })
            // HACKY: to save the page correctly we inject a page header to the response
            .then((resp) => {
              resp.headers['x-page'] = page;
              return resp;
            })
        );
      },
    { name },
  );

/**
 * PATCH /v2/campaigns/{campaignId}
 */
export const campaignUpdate = (name) =>
  keyedAsyncActionCreator(
    actionTypes.UPDATE,
    (campaignId, payload = {}) =>
      (dispatch, getState) =>
        actionAuthRequest(dispatch, getState, { dispatchErrors: false }).patch(
          `/v2/campaigns/${campaignId}`,
          payload,
        ),
    { name },
  );

/**
 * POST /v2/campaigns
 */
export const campaignCreate = (name) =>
  asyncActionCreator(
    actionTypes.CREATE,
    (campaign) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false }).post(
        '/v2/campaigns',
        campaign,
      ),
    { name },
  );

/**
 * POST /v2/campaigns/{campaignId}/publish
 */
export const campaignPublish = (name) =>
  keyedAsyncActionCreator(
    actionTypes.UPDATE,
    (campaignId) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false }).post(
        `/v2/campaigns/${campaignId}/publish`,
      ),
    { name },
  );

/**
 * POST /v2/campaigns/{campaignId}/archive
 */
export const campaignArchive = (name) =>
  keyedAsyncActionCreator(
    actionTypes.UPDATE,
    (campaignId) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false }).post(
        `/v2/campaigns/${campaignId}/archive`,
      ),
    { name },
  );

/**
 * POST /v2/campaigns/{campaignId}/done
 */
export const campaignDone = (name) =>
  keyedAsyncActionCreator(
    actionTypes.UPDATE,
    (campaignId) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false }).post(
        `/v2/campaigns/${campaignId}/done`,
      ),
    { name },
  );

/**
 * POST /v2/campaigns/{campaignId}/duplicate
 */
export const campaignDuplicate = (name) =>
  keyedAsyncActionCreator(
    actionTypes.UPDATE,
    (campaignId, payload) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false }).post(
        `/v2/campaigns/${campaignId}/duplicate`,
        payload,
      ),
    { name },
  );

/**
 * DELETE /v2/campaigns/{campaignId}
 */
export const campaignDelete = (name) =>
  keyedAsyncActionCreator(
    actionTypes.DELETE,
    (campaignId) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false }).delete(
        `/v2/campaigns/${campaignId}`,
      ),
    { name },
  );

/**
 * attach the given uploads to the campaign
 *
 * POST /v2/campaigns/{campaignId}/uploads
 *
 * @param {String} campaignId
 * @param {Array<{ url: String, filename: String }>} uploads - uploads that should be attached to the campaign
 * @returns {Promise<Array<{ id: string, uploads }>>}
 */
export const campaignAttachUploads = (name) =>
  keyedAsyncActionCreator(
    actionTypes.UPDATE,
    attachEnhancer((campaignId, upload) => (dispatch, getState) => {
      const payload = pick(upload, ['url', 'filename']);

      return actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
      }).post(`/v2/campaigns/${campaignId}/uploads`, payload);
    }),
    { name },
  );

/**
 * clear some campaigns (by the given `campaigIds`)
 */
export const clearCampaigns = (campaignIds = []) => ({
  type: actionTypes.CLEAR,
  payload: campaignIds,
});
