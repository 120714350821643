import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { compose } from 'recompose';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';

import IndividualTasksForm from '../forms/individualTasks';
import SendToMissionForm from '../forms/sendToMission';
import IndividualAssignmentForm, {
  formSelector as individualAssignmentFormSelector,
} from '../forms/individualAssignment';

const isIndividualTask = (task) => task.individual;

/* eslint-disable react/prop-types */
function IndividualTasksSection({ application, onUpdateApplication }) {
  return (
    <section>
      <header>
        <p>
          <span className="h4">Individual Tasks</span>
        </p>
      </header>
      <IndividualTasksForm
        application={application}
        onUpdateApplication={onUpdateApplication}
      />
    </section>
  );
}
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
const counterOfferFormWrapper = compose(
  connect(
    (
      state,
      {
        application: {
          match: { payment },
          mission,
        },
        formatPrice,
      },
    ) => ({
      initialValues: {
        counterOfferPrice: payment.counterOffer
          ? formatPrice(payment.totals.price)
          : '',
      },
      isApplicationMissionAccepted: mission?.status === 'confirmed',
    }),
  ),
  reduxForm({
    form: 'applicationReview/applicationPanel/counterOffer',
    enableReinitialize: true,
    validate: ({ counterOfferPrice }) => {
      const value = Number(counterOfferPrice);

      if (!isNumber(value) || isNaN(value)) {
        return {
          _error: { counterOfferPrice: 'Please enter a number' },
        };
      }

      return {};
    },
  }),
);

const CounterOfferForm = counterOfferFormWrapper(
  ({
    application,
    handleSubmit,
    formatPrice,
    onUpdateApplication,
    onLoadApplication,
    isApplicationMissionAccepted,
    dirty,
    anyTouched,
    error,
  }) => {
    const {
      match: {
        payment: {
          counterOffer,
          price: influencerBasePrice,
          totals: { price: influencerTotalPrice },
        },
      },
    } = application;

    const onSubmit = ({ counterOfferPrice }) => {
      onUpdateApplication(application.id, {
        counterOffer: { counterOfferPrice },
      }).then((response) => {
        if (response.statusCode >= 400) {
          throw new SubmissionError({
            _error: `Error changing the influencer price: ${response.message}`,
          });
        }

        onLoadApplication(application.id, {
          fields: ['id', 'match', 'events'],
        });
      });
    };

    return (
      <div className="row">
        <div className="col-5 pl-5">
          <div className="ml-4">
            <div>Counteroffer pricing</div>
            <div className="small">
              Set a one-time net price for this application
            </div>
          </div>
        </div>
        <form className="col-7 form-row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-2">
            <label htmlFor="standard-price" className="text-muted small m-0">
              {counterOffer ? 'Base price' : 'Standard price'}
            </label>
            <input
              id="standard-price"
              className="form-control"
              value={formatPrice(
                counterOffer ? influencerBasePrice : influencerTotalPrice,
              )}
              readOnly
            />
          </div>
          <div className="col-2">
            <label htmlFor="counter-offer" className="text-muted small m-0">
              Counteroffer
            </label>
            <Field
              id="counter-offer"
              className={cx('form-control', {
                'counter-offer': counterOffer,
              })}
              name="counterOfferPrice"
              component="input"
              readOnly={isApplicationMissionAccepted}
            />
            {anyTouched && error?.counterOfferPrice ? (
              <div className="text-danger mt-1">{error.counterOfferPrice}</div>
            ) : null}
          </div>
          <div className="col-2 mt-4">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                !dirty ||
                error?.counterOfferPrice ||
                isApplicationMissionAccepted
              }
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  },
);
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
function VipApplicationSection({
  application,
  publishingDates,
  onUpdateApplication,
  onLoadApplication,
  onLoadPublishingDates,
}) {
  const {
    match: {
      payment: { currency },
    },
  } = application;

  const intl = useIntl();
  const formatPrice = (number) =>
    intl.formatNumber(number, { style: 'currency', currency });

  return (
    <section>
      <header>
        <h1>VIP Applications</h1>
        <CounterOfferForm
          application={application}
          onUpdateApplication={onUpdateApplication}
          onLoadApplication={onLoadApplication}
          formatPrice={formatPrice}
        />
        <hr />
      </header>
      <IndividualAssignmentForm
        {...publishingDates}
        application={application}
        onUpdateApplication={onUpdateApplication}
        onLoadPublishingDates={onLoadPublishingDates}
      />
    </section>
  );
}

/* eslint-enable react/prop-types */

function SendToMission({
  className,
  application,
  application: { status, tasks },
  publishingDates,
  readyForMission,
  onLoadPublishingDates,
  onUpdateApplication,
  onLoadApplication,
  onSendToMission,
}) {
  const isSelected = status === 'selected';
  const individualTasks = tasks.filter(isIndividualTask);
  const hasIndividualTasks = individualTasks.length > 0;

  return (
    <div className={`send-to-mission clearfix ${className}`}>
      {hasIndividualTasks && (
        <>
          <IndividualTasksSection
            application={application}
            onUpdateApplication={onUpdateApplication}
          />
          <hr />
        </>
      )}
      <VipApplicationSection
        application={application}
        publishingDates={publishingDates}
        onLoadPublishingDates={onLoadPublishingDates}
        onUpdateApplication={onUpdateApplication}
        onLoadApplication={onLoadApplication}
      />
      {isSelected && (
        <>
          <hr />
          <SendToMissionForm
            className="pull-md-right"
            applicationId={application.id}
            disabled={!readyForMission}
            onUpdateApplication={onUpdateApplication}
            onSubmit={onSendToMission}
          />
        </>
      )}
    </div>
  );
}

SendToMission.propTypes = {
  className: PropTypes.string,
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        individual: PropTypes.bool,
      }),
    ).isRequired,
    assignment: PropTypes.shape({
      publishingDateId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  readyForMission: PropTypes.bool.isRequired,
  publishingDates: PropTypes.object.isRequired,
  onSendToMission: PropTypes.func.isRequired,
  onUpdateApplication: PropTypes.func.isRequired,
  onLoadApplication: PropTypes.func.isRequired,
  onLoadPublishingDates: PropTypes.func.isRequired,
};

SendToMission.defaultProps = {
  className: '',
};

export default connect((state, { application }) => {
  const vipDateAssigned = !!application?.assignment?.publishingDateId;
  const additionalTermsAssigned = !!application?.assignment?.additionalTerms;

  const showVipDates = individualAssignmentFormSelector(state, 'showVipDates');
  const showAdditionalTerms = individualAssignmentFormSelector(
    state,
    'showAdditionalTerms',
  );

  const vipDatesReady = (showVipDates && vipDateAssigned) || !showVipDates;

  const additionalTermsReady =
    (showAdditionalTerms && additionalTermsAssigned) || !showAdditionalTerms;

  const readyForMission = vipDatesReady && additionalTermsReady;

  return {
    readyForMission,
  };
})(SendToMission);
