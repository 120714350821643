import { createSelector, createStructuredSelector } from 'reselect';
import get from 'lodash/get';

const getCampaign = (state, props) => props.campaign;
const getFormDirty = (state, props) => props.form.state === 'updated';

const getHeadline = createSelector(getFormDirty, (dirty) => {
  let headline = 'Clients and Reach';
  if (dirty) {
    headline += '*';
  }

  return headline;
});

const getSubHeadline = createSelector(
  getCampaign,
  (campaign = {}) => `${get(campaign, 'contingents', []).length} channels(s)`,
);

export default createStructuredSelector({
  headline: getHeadline,
  subHeadline: getSubHeadline,
});
