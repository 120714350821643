import React from 'react';
import { Link } from 'react-router-dom';

import { Feature } from 'source/components/common';

function Dashboard() {
  return (
    <div className="container">
      <div className="row row-spacing">
        <div className="col-sm-12">
          <h2>Welcome to the eqolot Control Center</h2>
        </div>
      </div>

      <div className="row row-spacing">
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h3>Influencers</h3>

              <Link to="/users" className="btn btn-primary btn-sm">
                Influencers
              </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h3>Channels</h3>

              <Link to="/channels" className="btn btn-primary btn-sm">
                Channels
              </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h3>Campaigns</h3>

              <Link to="/campaigns" className="btn btn-primary btn-sm">
                Campaigns
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row row-spacing">
        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h3>Links</h3>

              <Link to="/links" className="btn btn-primary btn-sm">
                Link Management
              </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h3>Groups</h3>

              <Link to="/groups" className="btn btn-primary btn-sm">
                Groups
              </Link>
            </div>
          </div>
        </div>
        <Feature feature="invoices">
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <h3>Invoices</h3>

                <Link to="/invoices" className="btn btn-primary btn-sm">
                  Invoices
                </Link>
              </div>
            </div>
          </div>
        </Feature>
        <Feature feature="payments">
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <h3>Payments</h3>

                <Link to="/payments" className="btn btn-primary btn-sm">
                  Payments
                </Link>
              </div>
            </div>
          </div>
        </Feature>
      </div>
    </div>
  );
}

export default Dashboard;
