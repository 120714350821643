import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';

import Markdown from 'source/components/common/markdown';

const createMarkup = (html) => ({ __html: html });

function Tab({ title, active, onClick }) {
  return (
    <li className="nav-item">
      <a className={cx('nav-link', { active })} href="#" onClick={onClick}>
        {title}
      </a>
    </li>
  );
}

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const EditStamp = React.memo(({ edited, editedBy, updatedAt }) => {
  if (!edited) {
    return '';
  }

  const msg = [
    'Edited',
    updatedAt ? ` on ${moment(updatedAt).format('YYYY-MM-DD HH:mm')}` : '',
    editedBy ? ` by ${editedBy}` : '',
  ].join('');

  return <div className="text-danger">{msg}</div>;
});

EditStamp.propTypes = {
  edited: PropTypes.string,
  editedBy: PropTypes.string,
  updatedAt: PropTypes.string,
};

function TabActions({
  className,
  editMode,
  isEdited,
  onEditClick,
  onRevertClick,
}) {
  return (
    <div className={className}>
      <div className="text-muted">
        The edited pitch is shown to clients in the Business Manager interface.
        It is not shown to the Influencer.
      </div>
      {!editMode && (
        <div className="d-flex">
          <a className="text-muted" href="#" onClick={onEditClick}>
            Edit pitch
          </a>
          {isEdited && (
            <a className="text-muted ml-3" href="#" onClick={onRevertClick}>
              Remove edits and revert to the original pitch
            </a>
          )}
        </div>
      )}
    </div>
  );
}

TabActions.propTypes = {
  className: PropTypes.string,
  editMode: PropTypes.bool.isRequired,
  isEdited: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onRevertClick: PropTypes.func.isRequired,
};

const Pitch = React.memo(
  ({ applicationId, pitch, pitchEdits, onUpdateApplication }) => {
    const { edited, diffHtml, updatedBy, updatedAt } = pitchEdits;
    const [activeTab, setActiveTab] = React.useState(edited ? 0 : 1);
    const [editMode, setEditMode] = React.useState(false);
    const [newPitch, setNewPitch] = React.useState(edited || pitch);

    const onChange = (e) => {
      setNewPitch(e.target.value);
    };

    const onTabClick = (index) => (e) => {
      e.preventDefault();
      setActiveTab(index);
    };

    const onEditClick = (e) => {
      e.preventDefault();
      setEditMode(true);
      setNewPitch(edited || pitch);
    };

    const onRevertClick = (e) => {
      e.preventDefault();
      onUpdateApplication(applicationId, { pitchEdits: null });
      setEditMode(false);
      setActiveTab(1);
    };

    const onUpdateClick = () => {
      onUpdateApplication(applicationId, { pitchEdits: { edited: newPitch } });
      setEditMode(false);
      setActiveTab(0);
    };

    const onCancelClick = () => {
      setEditMode(false);
      setActiveTab(1);
    };

    if (editMode) {
      return (
        <>
          <div className="d-flex">
            <TabActions
              className="flex-fill"
              editMode={editMode}
              isEdited={!!edited}
              onEditClick={onEditClick}
              onRevertClick={onRevertClick}
            />
            <div className="d-flex buttons">
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={onCancelClick}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger ml-2"
                onClick={onUpdateClick}
              >
                Save
              </button>
            </div>
          </div>
          <textarea
            rows="10"
            value={newPitch}
            onChange={onChange}
            className="form-control mt-3"
          />
        </>
      );
    }

    return (
      <>
        {edited && (
          <ul className="nav nav-tabs justify-content-end mb-3">
            <li className="flex-fill">
              <EditStamp
                edited={edited}
                editedBy={updatedBy}
                updatedAt={updatedAt}
              />
            </li>
            {['Edited', 'Original', 'Differences'].map((title, index) => (
              <Tab
                key={title}
                title={title}
                onClick={onTabClick(index)}
                active={activeTab === index}
              />
            ))}
          </ul>
        )}
        <>
          <TabActions
            className="mb-3"
            activeTab={activeTab}
            editMode={editMode}
            isEdited={!!edited}
            onEditClick={onEditClick}
            onRevertClick={onRevertClick}
          />
          <div onClick={onEditClick}>
            {activeTab === 0 && (
              <Markdown className="pitch p-1" markdown={edited} />
            )}
            {activeTab === 1 && (
              <Markdown className="pitch p-1" markdown={pitch} />
            )}
            {activeTab === 2 && (
              <div
                className="html-diff p-1"
                dangerouslySetInnerHTML={createMarkup(diffHtml)}
              />
            )}
          </div>
        </>
      </>
    );
  },
);

Pitch.propTypes = {
  applicationId: PropTypes.string.isRequired,
  onUpdateApplication: PropTypes.func.isRequired,
  pitch: PropTypes.string.isRequired,
  pitchEdits: PropTypes.shape({
    original: PropTypes.string,
    edited: PropTypes.string,
    diffHtml: PropTypes.string,
    updatedBy: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
};

Pitch.defaultProps = {
  pitchEdits: {},
};

export default Pitch;
