import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import get from 'lodash/get';
import truncate from 'lodash/truncate';

import Collapsible from 'source/components/common/collapsible';

const CAMPAIGN_NAME_CHARACTER_LIMIT = 75;

const getTitleWithCounter = (title, counter = 0) => `${title} (${counter})`;

const getPrefix = (campaignType) => `/campaigns/${campaignType}`;

const getRoute =
  ({ campaignType, ...props }) =>
  (path) =>
  () => {
    const prefix = getPrefix(campaignType);
    return path({ ...props, prefix });
  };

const withRoot =
  (str) =>
  ({ prefix }) =>
    `${prefix}${str}`;
const withId =
  (str) =>
  ({ prefix, campaignId }) =>
    `${prefix}/${campaignId}${str}`;

const combineRoutes = (obj) => (props) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [key]: getRoute(props)(obj[key]),
    }),
    {},
  );

const getRoutes = combineRoutes({
  setup: withRoot('/new'),
  edit: withId('/edit'),
  activation: withId('/activation'),
  todos: withId('/todos'),
  applications: withId('/applications'),
  missions: withId('/missions'),
  reporting: withId('/reporting'),
});

class Navigation extends React.PureComponent {
  renderLinks() {
    const { campaignId, counters, campaign, campaignType } = this.props;

    const routes = getRoutes({ campaignType, campaignId });

    // creating a new campaign only shows a setup link
    if (!campaignId) {
      return (
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to={routes.setup()}
              activeClassName="active"
            >
              Setup
            </NavLink>
          </li>
        </ul>
      );
    }

    const campaignReady = get(campaign, 'status') !== 'draft';
    const reportingEnabled =
      get(campaign, 'type') === 'website' ||
      get(campaign, 'type') === 'instagram' ||
      get(campaign, 'type') === 'tiktok';

    // we're on an existing campaign
    return (
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <NavLink
            className="nav-link"
            to={routes.edit()}
            activeClassName="active"
          >
            Edit
          </NavLink>
        </li>
        <li className={cx('nav-item', { disabled: true })}>
          <NavLink
            className="nav-link"
            to={routes.activation()}
            activeClassName="active"
          >
            Activation
          </NavLink>
        </li>
        <li
          className={cx('nav-item', {
            disabled: !campaignReady,
          })}
        >
          <NavLink
            className="nav-link"
            to={routes.todos()}
            activeClassName="active"
          >
            {getTitleWithCounter('Todos', counters.todos)}
          </NavLink>
        </li>
        <li
          className={cx('nav-item', {
            disabled: !campaignReady,
          })}
        >
          <NavLink
            className="nav-link"
            to={routes.applications()}
            activeClassName="active"
          >
            {getTitleWithCounter('Applications', counters.applications)}
          </NavLink>
        </li>
        <li
          className={cx('nav-item', {
            disabled: !campaignReady,
          })}
        >
          <NavLink
            className="nav-link"
            to={routes.missions()}
            activeClassName="active"
          >
            {getTitleWithCounter('On Mission', counters.missions)}
          </NavLink>
        </li>
        <li
          className={cx('nav-item', {
            disabled: !campaignReady || !reportingEnabled,
          })}
        >
          <NavLink
            className="nav-link"
            to={routes.reporting()}
            activeClassName="active"
          >
            Reporting
          </NavLink>
        </li>
      </ul>
    );
  }

  render() {
    const { campaign, campaignId } = this.props;
    const campaignName = !campaignId ? 'New Campaign' : get(campaign, 'name');

    return (
      <Collapsible>
        {({ onToggle, onKeyDown, show }) => (
          <nav
            className="navbar navbar-expand-sm navbar-light navbar-secondary fixed-top bg-light"
            onKeyDown={onKeyDown}
          >
            <div className="container">
              <button
                onClick={onToggle}
                className="navbar-toggler ml-auto"
                type="button"
                data-toggle="collapse"
                aria-expanded={show}
              >
                <span className="navbar-toggler-icon" />
              </button>

              <div className={cx('collapse', 'navbar-collapse', { show })}>
                <span title={campaignName} className="navbar-brand">
                  {truncate(campaignName, {
                    length: CAMPAIGN_NAME_CHARACTER_LIMIT,
                  })}
                </span>
                {this.renderLinks()}
              </div>
            </div>
          </nav>
        )}
      </Collapsible>
    );
  }
}

Navigation.propTypes = {
  campaignId: PropTypes.string,
  campaign: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
  counters: PropTypes.shape({
    applications: PropTypes.number.isRequired,
    missions: PropTypes.number.isRequired,
    todos: PropTypes.number.isRequired,
  }).isRequired,
  campaignType: PropTypes.string.isRequired,
};

Navigation.defaultProps = {
  campaignId: undefined,
  campaign: undefined,
};

export default Navigation;
