import React from 'react';
import PropTypes from 'prop-types';

import CopyButton from 'source/components/common/copyButton';

function EventEdits({ text }) {
  if (!text) {
    return null;
  }

  return (
    <div className="card-footer action-edits">
      <span className="f-right">
        <CopyButton title="copy message" value={text} />
      </span>
    </div>
  );
}

EventEdits.propTypes = {
  text: PropTypes.string,
};

EventEdits.defaultProps = {
  text: null,
};

export default EventEdits;
