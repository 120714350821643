import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'recompose';

import Alert from 'source/components/common/alert';
import { DateInput, Select } from 'source/scenes/components/reduxFormAdapters';
import { parseToInt, toStartOfDay } from 'source/utils/reduxForm';
import range from 'lodash/range';

import PublishingDates from './components/publishingDates';
import selector from './selectors';
import validate from './validation';
import { withSubmissionHandler } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';

const enhance = compose(
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
  reduxForm({
    validate,
    touchOnBlur: false,
    touchOnChange: true,
    enableReinitialize: true,
  }),
);

function TimelineForm({ handleSubmit, invalid, submitting, error }) {
  return (
    <form onSubmit={handleSubmit}>
      <Alert message={error.message} />
      <div className="row">
        <div className="col-md-6">
          <Field
            name="applicationStart"
            component={DateInput}
            label="Start of Application:*"
            parse={toStartOfDay}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <label htmlFor="previewDueDays">Article preview due:</label>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <Field
            id="previewDueDays"
            name="previewDueDays"
            component={Select}
            className="preview-due-selection-container"
            parse={parseToInt}
          >
            {range(0, 100).map((i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <PublishingDates name="publishingDates" />

      <div className="row justify-content-end pr-3">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={invalid || submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
}

TimelineForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

TimelineForm.defaultProps = {
  error: { message: undefined },
};

export default enhance(TimelineForm);
