import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers, lifecycle } from 'recompose';
import get from 'lodash/get';

/**
 * collection of high-order-components
 */

export const withCampaignFetch = compose(
  setPropTypes({
    onSetActiveCampaign: PropTypes.func.isRequired,
    onGetCampaign: PropTypes.func.isRequired,
  }),
  withHandlers({
    onGetCampaignAllFields:
      ({ onSetActiveCampaign, onGetCampaign }) =>
      (campaignId) => {
        // if there is no `campaignId`, we don't need to fire any async actions
        if (!campaignId) {
          onSetActiveCampaign(null);
          return;
        }

        // ok we should load a campaign
        // 1. update active campaign id in store
        // 2. load the campaign
        // 3. load the applications so we can render the counters
        onSetActiveCampaign(campaignId);
        onGetCampaign(campaignId, {
          fields: [
            'id',
            'allowApplications',
            'budget',
            'bonuses',
            'contingents',
            'categories',
            'countries',
            'contract',
            'productShipment',
            'afterPublicationUploadsRequired',
            'details',
            'language',
            'name',
            'pricing',
            'publishedAt',
            'settings',
            'socialMediaSharing',
            'status',
            'tasks',
            'timeline',
            'type',
            'uploads',
            'activation',
            'version',
            'summary',
            'clientInfluencerSelection',
          ],
        });
      },
  }),
);

export const withCampaignLoading = compose(
  setPropTypes({
    match: PropTypes.shape({
      params: PropTypes.shape({ id: PropTypes.string }).isRequired,
    }).isRequired,
    onGetCampaignAllFields: PropTypes.func.isRequired,
  }),
  lifecycle({
    componentDidMount() {
      const { onGetCampaignAllFields } = this.props;

      let campaignId = this.props.match.params.id;

      if (campaignId === 'new') {
        campaignId = null;
      }

      onGetCampaignAllFields(campaignId);
    },

    componentDidUpdate(prevProps) {
      const { onGetCampaignAllFields } = this.props;

      let campaignId = this.props.match.params.id;

      if (campaignId === 'new') {
        campaignId = null;
      }

      if (campaignId && prevProps.match.params.id !== campaignId) {
        onGetCampaignAllFields(campaignId);
      }
    },
  }),
);

export const withCampaignNavigationSummaryFetch = compose(
  setPropTypes({
    onGetCampaign: PropTypes.func.isRequired,
  }),
  withHandlers({
    onGetCampaignNavigationSummary:
      ({ onGetCampaign }) =>
      (campaignId) => {
        // nothing to reload of no campaignId given
        if (!campaignId) {
          return;
        }

        onGetCampaign(campaignId, {
          fields: ['summary'],
        });
      },
  }),
);

export const withCampaignNavigationSummaryReloading = compose(
  setPropTypes({
    onGetCampaignNavigationSummary: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { onGetCampaignNavigationSummary } = this.props;

      const prevPath = get(prevProps, 'location.pathname');
      const nextPath = get(this.props, 'location.pathname');
      let campaignId = get(this.props, 'match.params.id');

      if (campaignId === 'new') {
        campaignId = null;
      }

      // did the routing actually change and we have a campaignId in our path
      if (nextPath && prevPath !== nextPath && campaignId) {
        onGetCampaignNavigationSummary(campaignId);
      }
    },
  }),
);

export const withResetScene = compose(
  setPropTypes({
    campaign: PropTypes.object,
  }),
  lifecycle({
    componentWillUnmount() {
      const { campaign, onResetScene } = this.props;

      onResetScene(campaign && campaign.id);
    },
  }),
);
