import { createSelector, createStructuredSelector } from 'reselect';
import get from 'lodash/get';

import config from 'config';
import { objToUrlParams } from 'source/utils';
import { getPages as getPaginationPages } from 'source/components/common/pagination';
import formDataToFilters from './formDataToFilters';

const getState = (state) => state.scenes.invoices.state;
const getPerPage = (state) => state.scenes.invoices.paging.pageSize;
const getInvoices = (state) => state.scenes.invoices.invoices;
const getPage = (state) => state.scenes.invoices.paging.page;
const getPagingCount = (state) => state.scenes.invoices.paging.count;
const getFilters = (state) => state.scenes.invoices.filters;
const getAccessToken = (state) => state.account.accessToken;
const getPagingTotal = (state) => state.scenes.invoices.paging.total;
const getUser = (state) => state.app.user;

const getInvoiceName = (invoice) => {
  // invoices without address
  if (!invoice.address) {
    return invoice.userId;
  }

  const firstname = get(invoice, 'address.name.firstname');
  const lastname = get(invoice, 'address.name.lastname');

  // invoice with invalid address setup
  if (!firstname && !lastname) {
    return invoice.userId;
  }

  return `${firstname} ${lastname}`;
};

const typesMap = {
  invoice: 'Invoice',
  creditNote: 'Credit Note',
};

const getInvoiceTableElement = (invoice) => ({
  id: invoice.id,
  type: typesMap[invoice.type],
  period: invoice.period,
  totalAmount: invoice.totals.totalAmount / 100,
  netAmount: invoice.totals.netAmount / 100,
  vatAmount: invoice.totals.vatAmount / 100,
  currency: invoice.currency,
  name: getInvoiceName(invoice),
  userId: invoice.userId,
  country: get(invoice, 'address.address.country', 'n/a'),
  vatId: get(invoice, 'address.tax.vatId', 'n/a'),
});

const getInvoicesTableElements = createSelector(getInvoices, (invoices) =>
  invoices.map(getInvoiceTableElement),
);

const getReportUrl = (url) => (accessToken, formData) => {
  const params = {
    access_token: accessToken,
  };

  const filterObjects = formDataToFilters(formData);

  if (filterObjects.length > 0) {
    params.filters = JSON.stringify(filterObjects);
  }

  const urlParams = objToUrlParams(params);

  return `${url}${urlParams}`;
};

const getInvoicesSummaryReportUrl = createSelector(
  getAccessToken,
  getFilters,
  getReportUrl(`${config.api.url}/v2/reports/invoices.xlsx`),
);

const getInvoiceTablePages = createSelector(
  getPerPage,
  getPagingCount,
  (perPage, count) => getPaginationPages(count, perPage),
);

const getCounter = createSelector(
  getPagingCount,
  getPagingTotal,
  (count, total) => ({ count, total }),
);

export const getInvoicesState = createStructuredSelector({
  state: getState,
  invoices: getInvoicesTableElements,
  page: getPage,
  filters: getFilters,
  pages: getInvoiceTablePages,
  counter: getCounter,
  invoicesSummaryReportUrl: getInvoicesSummaryReportUrl,
  user: getUser,
});
