import get from 'lodash/get';
import merge from 'lodash/merge';
import find from 'lodash/find';

import actionTypes from 'source/constants/actionTypes';
import { createReducer } from 'source/utils';
import * as immutable from 'source/utils/imCollection';

import { mapTask } from './shared';

const getInitialFilters = () => ({ status: '', contingentId: '' });

export const getInitialState = () => ({
  /* applications filter */
  filters: getInitialFilters(),

  /* applications tab */
  insights: {},
  applications: { loading: false, loaded: false },
  formsById: {},
  expanded: {},
  publishingDates: {},
});

const mapApplicationToForm = (application) => {
  const tasks = get(application, 'tasks', []);

  return {
    status: application.status,
    tasks: tasks.map(mapTask),
    // NOTE: For an application where the user has aleady accepted a date
    //       there's currently no way of finding out if it was a VIP date or
    //       not directly from the application itself. We'd have to query for
    //       all publishing dates of the campaign and and find the date with
    //       the id found in the `mission` object.
    vip: false,
    publishingDateId: null,
  };
};

/* == Action Handlers == */
const actionHandlers = {
  [actionTypes.CAMPAIGN_APPLICATIONS_LOADED]: (state, applications) => ({
    ...state,
    applications: {
      loaded: true,
      loading: false,
      data: applications,
    },
    // update applications forms
    formsById: applications.reduce(
      (memo, application) => ({
        ...memo,
        [application.id]: {
          forms: mapApplicationToForm(application),
          saveState: '',
        },
      }),
      {},
    ),
  }),

  [actionTypes.CAMPAIGN_APPLICATIONS_LOADING]: (state) => ({
    ...state,
    applications: {
      loaded: false,
      loading: true,
    },
  }),

  [actionTypes.CAMPAIGN_APPLICATIONS_FAILED]: (state) => ({
    ...state,
    applications: {
      loaded: false,
      loading: false,
      error: true,
    },
  }),

  // listen for the campaign loaded event and create applications
  [actionTypes.CAMPAIGN_STATE_LOADED]: (state) => ({
    ...state,
    filters: getInitialFilters(),
  }),

  // top-level applications filter change
  [actionTypes.CAMPAIGN_APPLICATION_FILTER_CHANGE]: (
    state,
    { filter, value },
  ) => ({
    ...state,
    filters: {
      ...state.filters,
      [filter]: value,
    },
  }),

  // expand a application panel
  [actionTypes.CAMPAIGN_APPLICATION_EXPAND_APPLICATION]: (
    state,
    { id, expanded },
  ) => ({
    ...state,
    expanded: {
      ...state.expanded,
      [id]: expanded,
    },
  }),
  // form change on one application
  [actionTypes.CAMPAIGN_APPLICATION_FORM_CHANGED]: (
    state,
    { id, field, value },
  ) => ({
    ...state,
    formsById: {
      ...state.formsById,
      [id]: {
        ...state.formsById[id],
        forms: {
          ...state.formsById[id].forms,
          [field]: value,
        },
        saveState: null,
      },
    },
  }),

  // application async server update
  //    a. async process started
  [actionTypes.CAMPAIGN_APPLICATION_PATCHING]: (state, { id }) => ({
    ...state,
    formsById: {
      ...state.formsById,
      [id]: {
        ...state.formsById[id],
        saveState: 'saving',
      },
    },
  }),

  //    b. async process succeeded
  [actionTypes.CAMPAIGN_APPLICATION_PATCHED]: (state, { application }) => ({
    ...state,
    applications: {
      ...state.applications,
      data: immutable.findAndReplace(state.applications.data, application),
    },
    formsById: {
      ...state.formsById,
      [application.id]: {
        ...state.formsById[application.id],
        forms: mapApplicationToForm(application),
        saveState: 'saved',
      },
    },
  }),

  //    c. async process failed
  [actionTypes.CAMPAIGN_APPLICATION_PATCHING_FAILED]: (state, { id }) => ({
    ...state,
    formsById: {
      ...state.formsById,
      [id]: {
        ...get(state, ['formsById', id], {}),
        forms: {},
        saveState: 'failed',
      },
    },
  }),

  // application async insights loading
  //    a. async process started
  [actionTypes.CAMPAIGN_APPLICATION_INSIGHTS_LOADING]: (
    state,
    { application },
  ) => ({
    ...state,
    insights: {
      ...state.insights,
      [application.id]: {
        state: 'loading',
        error: null,
      },
    },
  }),

  //    b. async process succeeded
  [actionTypes.CAMPAIGN_APPLICATION_INSIGHTS_LOADED]: (
    state,
    { application, insights },
  ) => ({
    ...state,
    insights: {
      ...state.insights,
      [application.id]: {
        state: 'loaded',
        error: null,
        data: insights,
      },
    },
  }),

  //    c. async process failed
  [actionTypes.CAMPAIGN_APPLICATION_INSIGHTS_FAILED]: (
    state,
    { application, error },
  ) => ({
    ...state,
    insights: {
      ...state.insights,
      [application.id]: {
        state: 'error',
        error,
      },
    },
  }),

  // application async: updating multiple applications
  //    c. async process succeeded
  [actionTypes.CAMPAIGN_APPLICATIONS_UPDATED_SUCCESFULLY]: (
    state,
    applications,
  ) => {
    const reduceApplications = (memo, application) => ({
      ...memo,
      [application.id]: {
        forms: mapApplicationToForm(application),
        saveState: 'saved',
      },
    });

    return merge({}, state, {
      applications: {
        ...state.applications,
        data: state.applications.data.map((application) => {
          const applicationNext = find(applications, { id: application.id });
          return applicationNext || application;
        }),
      },
      // NOTE @alexspri
      //    Since we're using merge, the existing but non-updated applications
      //    stay the same.
      formsById: applications.reduce(reduceApplications, {}),
    });
  },

  [actionTypes.CAMPAIGN_APPLICATION_PUBLISHING_DATES_LOADING]: (
    state,
    { application },
  ) => ({
    ...state,
    publishingDates: {
      ...state.publishingDates,
      [application.id]: {
        state: 'loading',
        error: null,
      },
    },
  }),

  [actionTypes.CAMPAIGN_APPLICATION_PUBLISHING_DATES_LOADED]: (
    state,
    { application, publishingDates },
  ) => ({
    ...state,
    publishingDates: {
      ...state.publishingDates,
      [application.id]: {
        state: 'loaded',
        error: null,
        publishingDates,
      },
    },
  }),

  [actionTypes.CAMPAIGN_APPLICATION_PUBLISHING_DATES_FAILED]: (
    state,
    { application, error },
  ) => ({
    ...state,
    publishingDates: {
      ...state.publishingDates,
      [application.id]: {
        state: 'loaded',
        error,
      },
    },
  }),
};

export default createReducer(getInitialState(), actionHandlers);
