import React from 'react';
import PropTypes from 'prop-types';

import { EventDefault } from './index';
import EventContentCounterOffer from '../event/eventContentCounterOffer';

function EventCounterOffer({ application, content, ...props }) {
  const {
    match: {
      payment: {
        currency,
        totals: { price },
      },
    },
  } = application;

  return (
    <EventDefault {...props} content=" ">
      <EventContentCounterOffer price={price} currency={currency} />
    </EventDefault>
  );
}

EventCounterOffer.propTypes = {
  content: PropTypes.string,
  application: PropTypes.shape({
    match: PropTypes.shape({
      payment: PropTypes.shape({
        currency: PropTypes.oneOf(['EUR', 'GBP']).isRequired,
        counterOffer: PropTypes.bool.isRequired,
        totals: PropTypes.shape({
          price: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default EventCounterOffer;
