import React from 'react';
import PropTypes from 'prop-types';

import SearchInput from './searchInput';

class SearchBar extends React.PureComponent {
  renderSubHeading() {
    const { subHeading, subHeadingClassName } = this.props;

    if (!subHeading) {
      return null;
    }

    return <p className={subHeadingClassName}>{subHeading}</p>;
  }

  render() {
    const {
      className,
      query,
      inputClassName,
      formClassName,
      placeholder,
      onSubmit,
      onInputChange,
      disabled,
      children,
    } = this.props;

    return (
      <div className={className}>
        <div>{this.renderSubHeading()}</div>
        <div className="flex-grow">
          <SearchInput
            query={query}
            formClassName={formClassName}
            inputClassName={inputClassName}
            placeholder={placeholder}
            onInputChange={onInputChange}
            onSubmit={onSubmit}
            disabled={disabled}
          />
          {children}
        </div>
      </div>
    );
  }
}

SearchBar.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  formClassName: PropTypes.string,
  query: PropTypes.string,
  subHeading: PropTypes.string,
  subHeadingClassName: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.element,
};

SearchBar.defaultProps = {
  className: '',
  query: '',
  inputClassName: 'form-control',
  formClassName: 'flex-grow',
  subHeading: '',
  subHeadingClassName: 'text-muted',
  placeholder: '',
  disabled: false,
};

export default SearchBar;
