import isNil from 'lodash/isNil';
import get from 'lodash/get';

/**
 * Similar to combine reducers, except reducer slices are not nested under
 * their own keys, but are merged into a top-level state
 */
export const combineReducersFlat =
  (reducers, initialState = null) =>
  (state = initialState, action) =>
    reducers.reduce((state, reduce) => reduce(state, action), state);

/**
 * This implements a key-value store reducer where an
 * action's  `key` property determines the key at which to store
 * the payload. It accepts a "value reducer" as parameters
 */
export const keyValueStoreReducer =
  (reducer, { key = 'key' } = {}) =>
  (state = {}, action) => {
    const actionKey = get(action, key);

    // If this is not a keyed action, there is nothing to do
    if (!actionKey) {
      return state;
    }

    const value = state[actionKey];
    const valueNext = reducer(value, action);

    // If the wrapped reducer responds to the action, update
    // the state at 'key', otherwise do nothing
    // (we treat null and undefined the same when testing for equality)
    return (isNil(value) && isNil(valueNext)) || value === valueNext
      ? state
      : { ...state, [actionKey]: valueNext };
  };

/**
 * Wrap a reducer such that it only responds to actions with a certain namespace
 */
export const namespacedReducer = (name) => (reducer) => (state, action) =>
  // If state is not defined, we yield the initial state
  state === undefined || action.name === name ? reducer(state, action) : state;

export const resetableReducer = (resetAction) => (reducer) => (state, action) =>
  action.type === resetAction
    ? reducer(undefined, action)
    : reducer(state, action);

export const nestedReducer =
  (key) =>
  (reducer) =>
  (state = {}, action) => ({
    ...state,
    [key]: reducer(state[key], action),
  });

/**
 * reducer which just extracts from the action the given key
 */
export const extractReducer =
  (type, key = 'payload', initialState = null) =>
  (state = initialState, action) => {
    if (action.type !== type) {
      return state;
    }

    if (typeof key === 'function') {
      return key(action);
    }

    return get(action, key) || initialState;
  };
