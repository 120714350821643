import React from 'react';
import PropTypes from 'prop-types';

import ChannelsTableRow from './channelsTableRow';
import ChannelsTableSummary from './channelsTableSummary';

class ChannelsTable extends React.PureComponent {
  handleChange = (row, id, value) => {
    const { onRowChange } = this.props;

    return onRowChange(row, id, value);
  };

  render() {
    const {
      form,
      form: { channels, options },
      onRemoveChannel,
    } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-hover table-channels">
          <thead>
            <tr>
              <th className="col-bucket">Bucket</th>
              <th className="col-channel">Channel</th>
              <th className="col-reach">Reach / Month</th>
              <th className="col-postings">Postings</th>
              <th className="col-bonus">Bonus</th>
              <th className="col-costs">Influencer Price</th>
              <th className="col-details" />
            </tr>
          </thead>
          <tbody>
            {channels.map((channel) => (
              <ChannelsTableRow
                key={channel.id}
                row={channel}
                options={options}
                onChange={this.handleChange}
                onRemove={onRemoveChannel}
              />
            ))}
          </tbody>

          <tfoot>
            <ChannelsTableSummary form={form} />
          </tfoot>
        </table>
      </div>
    );
  }
}

ChannelsTable.propTypes = {
  form: PropTypes.shape({
    channels: PropTypes.array.isRequired,
    options: PropTypes.any,
  }).isRequired,
  onRowChange: PropTypes.func.isRequired,
  onRemoveChannel: PropTypes.func.isRequired,
};

export default ChannelsTable;
