import { combineReducers } from 'redux';
import { asyncActionReducer } from '@blogfoster/redux-async-utils';

import { combineReducersFlat } from 'source/utils/redux';

import { actionTypes } from './actions';
import { dataCreateHandler } from '../dataHandlers';

/* == selectors == */
export const getPaymentsById = (state) => state.byId;
export const getPayment = (state, { applicationId }) =>
  state.byId[applicationId];

const paymentCreateReducer = asyncActionReducer(
  actionTypes.CREATE,
  dataCreateHandler(),
);

const paymentsByIdReducer = combineReducersFlat([paymentCreateReducer], {});

export default combineReducers({
  byId: paymentsByIdReducer,
});
