import { combineReducers, compose } from 'redux';
import { createSelector } from 'reselect';
import { loadStateReducer } from '@blogfoster/redux-async-utils';

import { redux as collapsiblePanelRedux } from 'source/components/common/collapsiblePanel';
import { resetableReducer, extractReducer } from 'source/utils/redux';

import { actionTypes, namespace } from './actions';

// Selectors

const getPanels = (state) => state.panels;
const getError = (state) => state.error;
const getSelectedPanel = createSelector(getPanels, (panels) =>
  collapsiblePanelRedux.selectors.getExpanded(panels),
);
const getClients = (state) => state.clients;
const getPrivateNetworks = (state) => state.privateNetworks;

export const selectors = {
  getPanels,
  getError,
  getSelectedPanel,
  getClients,
  getPrivateNetworks,
};

// Reducers

const panelReducer = collapsiblePanelRedux.reducers.distinctToggle(namespace);
const errorReducer = extractReducer(actionTypes.SET_ERROR);
const clientsReducer = loadStateReducer(actionTypes.CLIENTS_FETCH_MANY);
const privateNetworksReducer = loadStateReducer(
  actionTypes.PRIVATE_NETWORKS_FETCH_MANY,
);

export default compose(resetableReducer(actionTypes.RESET_SCENE))(
  combineReducers({
    panels: panelReducer,
    error: errorReducer,
    clients: clientsReducer,
    privateNetworks: privateNetworksReducer,
  }),
);
