import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';

import {
  withSubmissionValidation,
  withNextPanelAfterSubmission,
} from '../../enhancers';

const getRedirectUrl = ({ type, pricing, id }) => {
  if (type === 'website' && pricing === 'budget') {
    return `/campaigns/website-budget/${id}/edit`;
  }

  if (type === 'website' && pricing === 'bucket') {
    return `/campaigns/website-bucket/${id}/edit`;
  }

  return `/campaigns/${type}/${id}/edit`;
};

const submitInit =
  ({ payloadConstants, onCreateCampaign, onUpdateCampaign, push }) =>
  (values, __, { campaignId, newCampaign }) => {
    const toPayload = (values) => {
      if (newCampaign) {
        return {
          ...payloadConstants,
          ...values,
        };
      }

      return { name: values.name };
    };

    const handler = newCampaign
      ? (payload) => onCreateCampaign(payload)
      : (payload) => onUpdateCampaign(campaignId, payload);

    const redirect = (resp) => {
      if (!newCampaign) {
        return Promise.resolve();
      }

      if (resp.status !== 201) {
        // something failed
        return Promise.reject(resp);
      }

      return push(getRedirectUrl(resp.data));
    };

    return Promise.resolve(values).then(toPayload).then(handler).then(redirect);
  };

export const withSubmissionHandler = compose(
  setPropTypes({
    payloadConstants: PropTypes.object.isRequired,
    onCreateCampaign: PropTypes.func.isRequired,
    onUpdateCampaign: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  withHandlers({ onSubmit: submitInit }),
  withSubmissionValidation,
  withNextPanelAfterSubmission,
);
