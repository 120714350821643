import React from 'react';
import { compose, setPropTypes, withProps, withHandlers } from 'recompose';
import find from 'lodash/find';

import TodoModel, { TodoShape } from '../../../todoModel';
import Action from './action';
import { withActionSubmit } from './enhancers';

/**
 * Action for the "confirmation:delay" Todo
 */
const ConfirmationDelayAction = compose(
  setPropTypes({ todo: TodoShape.isRequired }),
  withProps({
    title: 'User contacted?',
    label: 'Yes',
  }),
  withActionSubmit,
  withHandlers({
    onSubmit:
      ({ todo, onSubmit }) =>
      (e) => {
        e.preventDefault();

        const payload = {
          communication: { status: 'contacted', type: TodoModel.type(todo) },
        };

        onSubmit(payload);
      },
  }),
)(Action);

/**
 * Action for the "confirmation:refused" Todo
 */
const ConfirmationRefusedAction = compose(
  setPropTypes({ todo: TodoShape.isRequired }),
  withProps({
    title: 'User contacted?',
    label: 'Yes',
  }),
  withActionSubmit,
  withHandlers({
    onSubmit:
      ({ todo, onSubmit }) =>
      (e) => {
        e.preventDefault();

        const payload = {
          communication: { status: 'contacted', type: TodoModel.type(todo) },
        };

        onSubmit(payload);
      },
  }),
)(Action);

/**
 * Action for the "shipment:pending" Todo
 */
const ShipmentPendingAction = compose(
  setPropTypes({ todo: TodoShape.isRequired }),
  withProps({
    title: 'Delivery send?',
    label: 'Yes',
  }),
  withActionSubmit,
  withHandlers({
    onSubmit:
      ({ onSubmit }) =>
      (e) => {
        e.preventDefault();

        const payload = {
          productShipment: { status: 'sent' },
        };

        onSubmit(payload);
      },
  }),
)(Action);

const ActionsDefinition = [
  {
    test: TodoModel.typeOf.confirmationDelay,
    Component: ConfirmationDelayAction,
  },
  {
    test: TodoModel.typeOf.confirmationRefused,
    Component: ConfirmationRefusedAction,
  },
  {
    test: TodoModel.typeOf.shipmentPending,
    Component: ShipmentPendingAction,
  },
];

const getActions = (todo) => {
  const definition = find(ActionsDefinition, (def) => def.test(todo));

  return definition ? definition.Component : null;
};

function TodoActions({ todo, ...props }) {
  // if no actions defined we don't need to render anything
  const Actions = getActions(todo);
  if (!Actions) {
    return null;
  }

  return (
    <div className="row pt-4">
      <div className="col">
        <h6 className="text-muted">Actions:</h6>
        <div className="pl-3">
          <Actions {...props} todo={todo} />
        </div>
      </div>
    </div>
  );
}

TodoActions.propTypes = {
  todo: TodoShape.isRequired,
};

export default TodoActions;
