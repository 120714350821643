import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import sumBy from 'lodash/sumBy';
import capitalize from 'lodash/capitalize';
import {
  withProps,
  lifecycle,
  branch,
  renderComponent,
  compose,
  setPropTypes,
} from 'recompose';
import { connect } from 'react-redux';

import { formatReadingTime } from 'source/utils';

import ChannelAvatar from 'source/components/common/channelAvatar';
import ProgressBar from 'source/components/common/progressBar';

import InsightsAnalysis from './insightsAnalysis';
import ApplicationInsightsGa from './applicationInsightsGa';

// Helpers

function Error() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      Error loading insights.
    </div>
  );
}

function Loading() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ProgressBar />
    </div>
  );
}

/**
 * Render N/A if metric not available
 */
const renderData = (data, key, renderer) => {
  const value = get(data, key);

  if (isNil(value)) {
    return `${capitalize(key)} insights not available`;
  }

  return renderer(value);
};

/**
 * Renders table rows for the top 5 devices
 */
const renderTop5Rows =
  ({ intl, key }) =>
  (top5Devices) =>
    top5Devices.map((dev) => (
      <tr key={dev[key]}>
        <th scope="row" className="truncate" style={{ maxWidth: '12.5rem' }}>
          {dev[key]}:
        </th>
        <td className="text-right">
          {intl.formatNumber(dev.significance, {
            style: 'percent',
            maximumFractionDigits: 2,
          })}
        </td>
      </tr>
    ));

// HoCs

const withApplicationInsightsFetch = compose(
  setPropTypes({
    campaign: PropTypes.object.isRequired,
    onLoadInsights: PropTypes.func.isRequired,
  }),
  lifecycle({
    componentDidMount() {
      const { campaign, insights, application, onLoadInsights } = this.props;

      if (!insights.loaded) {
        onLoadInsights(application.id, campaign);
      }
    },
  }),
);

const withChannelMetrics = connect((state, props) => ({
  metrics: get(
    state,
    `scenes.campaignDetail.applications.applicationPanelsById[${props.application.id}].insights.data.channel.metrics`,
  ),
}));

const withErrorGuard = branch(
  ({ insights }) => insights.error,
  renderComponent(Error),
);

const withLoadingGuard = branch(
  ({ insights }) => !insights.loaded,
  renderComponent(Loading),
);

/**
 * Remove async information and calculate aggregate data on "top 5" metrics
 */
const withInsightsData = withProps(({ insights }) => ({
  insights: ['devices', 'countries', 'referrals'].reduce((acc, key) => {
    const data = acc[key];

    if (!data || !data.length) {
      return acc;
    }

    const totalVisits = sumBy(data, 'visits');

    return {
      ...acc,
      [key]: data
        .sort((a, b) => b.visits - a.visits)
        .slice(0, 5)
        .map((item) => ({
          ...item,
          significance: item.visits / totalVisits,
        })),
    };
  }, insights.data || {}),
}));

// Components

function ApplicationInsights({ intl, insights }) {
  if (insights.notFound) {
    return <div className="pt-3 pb-3">No Insights Data Available ...</div>;
  }

  const pairs = [
    { label: 'Page Views', attr: 'pageviews', formatter: intl.formatNumber },
    { label: 'Visits', attr: 'visits', formatter: intl.formatNumber },
    {
      label: 'Actions per Visit',
      attr: 'actionsPerVisit',
      formatter: (val) => intl.formatNumber(val, { maximumFractionDigits: 2 }),
    },
    {
      label: 'Unique Visitors',
      attr: 'uniqueVisits',
      formatter: intl.formatNumber,
    },
    {
      label: 'Visit Duration',
      attr: 'visitDuration',
      formatter: formatReadingTime,
    },
    {
      label: 'Bounce Rate',
      attr: 'bounceRate',
      formatter: (val) =>
        intl.formatNumber(val, {
          style: 'percent',
          maximumFractionDigits: 2,
        }),
    },
  ];

  return (
    <div className="pb-3 pt-3">
      <p className="text-muted">
        Insights data related to this application for the last 30 days
      </p>

      {/* common channel metrics */}
      <div className="row">
        <div className="col-md-6">
          <h5 className="pb-2">Channel Metrics</h5>
          <table className="table table-hover table-sm ml-2">
            <tbody>
              {pairs.map(({ label, attr, formatter }) => (
                <tr key={attr}>
                  <th scope="row">{label}:</th>
                  <td className="text-right">
                    {renderData(insights, attr, formatter)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* devices */}
        <div className="col-md-6">
          <h5 className="pb-2">Devices</h5>
          <table className="table table-hover table-sm ml-2">
            <tbody>
              {renderData(
                insights,
                'devices',
                renderTop5Rows({ intl, key: 'device' }),
              )}
            </tbody>
          </table>
        </div>

        {/* countries */}
        <div className="col-md-6">
          <h5 className="pb-2">Country Traffic</h5>
          <table className="table table-hover table-sm ml-2">
            <tbody>
              {renderData(
                insights,
                'countries',
                renderTop5Rows({ intl, key: 'country' }),
              )}
            </tbody>
          </table>
        </div>

        {/* referrals */}
        <div className="col-md-6">
          <h5 className="pb-2">Referrals</h5>
          <table className="table table-hover table-sm ml-2">
            <tbody>
              {renderData(
                insights,
                'referrals',
                renderTop5Rows({ intl, key: 'referrer' }),
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

ApplicationInsights.propTypes = {
  intl: PropTypes.object.isRequired,
  insights: PropTypes.object.isRequired,
};

const ApplicationInsightsEnhanced = compose(
  withApplicationInsightsFetch,
  withErrorGuard,
  withLoadingGuard,
  withInsightsData,
  injectIntl,
)(ApplicationInsights);

const ApplicationInsightsGaEnhanced = compose(
  withApplicationInsightsFetch,
  withErrorGuard,
  withLoadingGuard,
  withChannelMetrics,
)(ApplicationInsightsGa);

function InsightsTab(props) {
  const isGaChannel = props.application.channel.platform === 'ga';
  return (
    <div>
      <section>
        <ChannelAvatar
          url={props.application.channel.avatarUrl}
          className="application-channel-avatar-website"
        />
        {isGaChannel ? (
          <ApplicationInsightsGaEnhanced {...props} />
        ) : (
          <ApplicationInsightsEnhanced {...props} />
        )}
      </section>
      <section>
        <InsightsAnalysis application={props.application} />
      </section>
    </div>
  );
}

InsightsTab.propTypes = {
  application: PropTypes.shape({
    channel: PropTypes.shape({
      platform: PropTypes.string,
      avatarUrl: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default InsightsTab;
