import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  loadAddLinkState,
  submitNewLink,
  cancelNewLink,
  updateNewLink,
} from 'source/actions/links';
import { AddLink } from 'source/components/links';
import { Checkbox } from 'source/components/common/forms';
import {
  getNormalizedUsers,
  getNormalizedCampaigns,
} from 'source/selectors/links';

class AddLinkContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      withArchivedCampaigns: false,
    };
  }

  componentDidMount() {
    this.props.onMount(this.state);
  }

  handleFilterChangeAndReload = () => {
    this.setState(
      { withArchivedCampaigns: !this.state.withArchivedCampaigns },
      () => {
        this.props.onMount({ ...this.state, onlyCampaigns: true });
      },
    );
  };

  render() {
    const { withArchivedCampaigns } = this.state;

    return (
      <div className="container">
        <h2>Create New Link</h2>
        <p className="text-muted">
          To create a new link, you should specify the target URL (leading to a
          campaign site). The user and campaign fields are optional but
          specifying them helps us to collect better analytics.
        </p>
        <hr />
        <div className="row pt-3">
          <div className="col">
            <Checkbox
              id="showArchived"
              label="Show Archived Campaigns"
              checked={withArchivedCampaigns}
              onClick={this.handleFilterChangeAndReload}
            />
          </div>
        </div>

        <div className="row pt-3">
          <div className="col">
            <AddLink {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

AddLinkContainer.propTypes = {
  onMount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  stateLoaded: state.links.stateLoaded,
  form: state.links.addLinkForm.form,
  errors: state.links.addLinkForm.errors,
  users: getNormalizedUsers(state.links),
  campaigns: getNormalizedCampaigns(state.links),
});

const mapDispatchToProps = {
  onMount: loadAddLinkState,
  onUpdate: updateNewLink,
  onSubmit: submitNewLink,
  onCancel: cancelNewLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLinkContainer);
