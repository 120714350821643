import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import debounce from 'lodash/debounce';

import withResetScene from 'source/components/commonEnhancers/withResetScene';
import { InputText, InputSelect } from 'source/components/common/inputs';
import { getNewPaymentState } from '../selectors';
import actions from '../actions';

const enhance = compose(connect(getNewPaymentState, actions), withResetScene);

class NewPayment extends React.PureComponent {
  componentDidMount() {
    const {
      user,
      form,
      onLoadUsers,
      onLoadCampaigns,
      onLoadApplications,
      onUpdate,
    } = this.props;

    onLoadUsers();
    onLoadCampaigns();
    onLoadApplications();
    onUpdate(form, 'approvedBy', { value: user.email });
  }

  handleSubmit = (e) => {
    const { form, onSubmit } = this.props;
    e.preventDefault();

    return onSubmit(form);
  };

  handleChange = (id, value) => {
    const { form } = this.props;
    const {
      form: {
        userId: { value: userId },
        campaignId: { value: campaignId },
      },
      onUpdate,
      onLoadApplications,
    } = this.props;

    onUpdate(form, id, value);

    const isUserIdUpdated = id === 'userId' && campaignId && value.value;
    const isCampaignIdUpdated = id === 'campaignId' && userId && value.value;

    if (isUserIdUpdated || isCampaignIdUpdated) {
      onLoadApplications();
    }
  };

  handleOnBlur = () => {
    const { onBlur } = this.props;

    return onBlur();
  };

  handleCampaignsFetch = debounce((name) => {
    const { onLoadCampaigns } = this.props;
    onLoadCampaigns(name);
  }, 500);

  render() {
    const { state, users, applications, campaigns, form, errors } = this.props;

    const stateLoaded = state === 'loaded';
    const disableCampaigns = !form.userId.value;
    const disableApplications = !form.userId.value || !form.campaignId.value;

    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Create new payment</h2>
          </div>
        </div>
        <hr />
        <form>
          <InputText
            disabled
            name="Approved By:*"
            id="approvedBy"
            type="email"
            value={form.approvedBy.value}
            error={errors.approvedBy}
            onChange={this.handleChange}
            onBlur={this.handleOnBlur}
          />
          <InputSelect
            name="Beneficiary:*"
            id="userId"
            options={users}
            value={form.userId.value}
            error={errors.userId}
            onChange={this.handleChange}
            placeholder="Select user"
            onBlur={this.handleOnBlur}
          />

          <InputText
            name="Amount:*"
            id="amount"
            type="number"
            value={form.amount.value}
            error={errors.amount}
            onChange={this.handleChange}
            onBlur={this.handleOnBlur}
          />

          <InputSelect
            name="Currency:*"
            id="currency"
            options={[
              { id: 'EUR', value: 'EUR' },
              { id: 'GBP', value: 'GBP' },
            ]}
            value={form.currency.value}
            error={errors.currency}
            onChange={this.handleChange}
            onBlur={this.handleOnBlur}
          />

          <InputText
            name="Description:*"
            id="description"
            type="text"
            maxLength="250"
            value={form.description.value}
            error={errors.description}
            onChange={this.handleChange}
            onBlur={this.handleOnBlur}
          />

          <InputSelect
            name="Campaign:"
            id="campaignId"
            options={campaigns}
            value={form.campaignId.value}
            error={errors.campaign}
            onChange={this.handleChange}
            onBlur={this.handleOnBlur}
            onFetch={this.handleCampaignsFetch}
            disabled={disableCampaigns}
            placeholder="Select campaign"
          />

          <InputSelect
            name="Applications:"
            id="applicationId"
            options={applications}
            value={form.applicationId.value}
            error={errors.application}
            onChange={this.handleChange}
            onBlur={this.handleOnBlur}
            disabled={disableApplications}
            placeholder="Select application"
          />

          <div className="row justify-content-end pr-3">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={this.handleSubmit}
              disabled={!stateLoaded}
            >
              Create payment
            </button>
          </div>
        </form>
      </div>
    );
  }
}

NewPayment.propTypes = {
  state: PropTypes.string.isRequired,
  form: PropTypes.shape({
    approvedBy: PropTypes.object,
    userId: PropTypes.object,
    amount: PropTypes.object,
    currency: PropTypes.object,
    description: PropTypes.object,
    campaignId: PropTypes.object,
    applicationId: PropTypes.object,
  }).isRequired,
  errors: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onLoadUsers: PropTypes.func.isRequired,
  onLoadCampaigns: PropTypes.func.isRequired,
  onLoadApplications: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  campaigns: PropTypes.array.isRequired,
  applications: PropTypes.array.isRequired,
  user: PropTypes.object,
};

export default enhance(NewPayment);
