import validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import { push } from 'connected-react-router';

import { updateItem } from 'source/utils/imCollection';
import {
  paymentFetchMany,
  paymentCreateManual,
} from 'source/data/payments/actions';
import { userFetchMany } from 'source/data/users/actions';
import { campaignFetchMany } from 'source/data/campaigns/actions';
import { applicationFetchMany } from 'source/data/applications/actions';
import { isPaymentApprover } from 'source/utils/acl';

import {
  listDataToFilters,
  formDataToApplicationFilters,
} from './formDataToFilters';

export const namespace = 'scenes/payments';

export const actionTypes = {
  FILTER_CHANGED: `${namespace}/FILTER_CHANGED`,
  FILTER_RESET: `${namespace}/FILTER_RESET`,
  RESET_SCENE: `${namespace}/RESET_SCENE`,
  PAYMENT_UPDATE: `${namespace}/PAYMENT_UPDATE`,
  PAYMENT_VALIDATE: `${namespace}/PAYMENT_VALIDATE`,
  PAYMENT_SUBMIT: `${namespace}/PAYMENT_SUBMIT`,
  PAYMENT_EDIT: `${namespace}/PAYMENT_EDIT`,
  PAYMENT_ALERT_CLOSE: `${namespace}/PAYMENT_ALERT_CLOSE`,
};

const listPayments = paymentFetchMany(namespace);
const createPayment = paymentCreateManual(namespace);
const listUsers = userFetchMany(namespace);
const listCampaigns = campaignFetchMany(namespace);
const listApplications = applicationFetchMany(namespace);

const loadPayments =
  (page = 0) =>
  (dispatch, getState) => {
    const {
      scenes: {
        payments: { filters: formData = {} },
      },
    } = getState();

    const fields = [
      'id',
      'receiver.id',
      'amount',
      'currency',
      'description',
      'sender.id',
      'createdAt',
    ];

    return dispatch(
      listPayments({ page, fields, filters: listDataToFilters(formData) }),
    );
  };

const approvePayment = (payload) => (dispatch, getState) => {
  const amountInCents = Math.round(parseFloat(payload.amount) * 100);
  return Promise.resolve()
    .then(() => dispatch(createPayment({ ...payload, amount: amountInCents })))
    .then(() => {
      const {
        scenes: {
          payments: { error },
        },
      } = getState();
      if (!error) {
        dispatch(push('/payments'));
      }
    });
};

const loadUsers = () => (dispatch) => {
  const fields = ['id', 'email', 'firstname'];

  return dispatch(listUsers({ fields }));
};

const loadCampaigns = (name) => (dispatch) => {
  const filters = [];
  if (name) {
    filters.push({ name: { match: name } });
  }
  dispatch(listCampaigns({ filters }));
};

const loadApplications = () => (dispatch, getState) => {
  const {
    scenes: {
      payments: {
        paymentForm: { form },
      },
    },
  } = getState();
  const fields = ['id', 'user.firstname', 'channel.name'];
  const filters = formDataToApplicationFilters(form);

  if (filters.length === 0) {
    return false;
  }

  return dispatch(listApplications({ fields, filters }));
};

const changeFilter = (filter, value) => (dispatch) =>
  dispatch({
    type: actionTypes.FILTER_CHANGED,
    payload: { filter, value },
  });

const resetFilter = () => (dispatch) =>
  dispatch({
    type: actionTypes.FILTER_RESET,
  });

const resetScene = () => ({ type: actionTypes.RESET_SCENE });

const updatePayment = (form, attribute, value) => (dispatch) => {
  const updatedForm = updateItem(form, attribute, value);

  if (!updatedForm.userId.value) {
    updatedForm.campaignId = { value: '' };
    updatedForm.applicationId = { value: '' };
  }

  if (!updatedForm.campaignId.value) {
    updatedForm.applicationId = { value: '' };
  }

  if (attribute === 'userId' || attribute === 'campaignId') {
    updatedForm.applicationId = { value: '' };
  }

  dispatch({
    type: actionTypes.PAYMENT_UPDATE,
    payload: updatedForm,
  });
};

const validateForm = (form) => {
  const errors = [];
  if (!form.approvedBy.value || isEmpty(form.approvedBy.value.trim())) {
    errors.push({ id: 'approvedBy', message: 'Approving user is missing' });
  } else if (!isPaymentApprover(form.approvedBy.value)) {
    errors.push({
      id: 'approvedBy',
      message: 'You are not allowed to approve payments',
    });
  }

  if (!form.userId.value) {
    errors.push({ id: 'userId', message: 'Beneficiary user is missing' });
  }

  if (!validator.isDecimal(form.amount.value, { decimal_digits: 2 })) {
    errors.push({
      id: 'amount',
      message: 'Amount should be an integer',
    });
  } else if (
    !validator.isDecimal(form.amount.value, { lt: 0 }) &&
    !validator.isDecimal(form.amount.value, { gt: 0 })
  ) {
    errors.push({
      id: 'amount',
      message: 'Amount should not equal to 0',
    });
  }

  if (!form.description.value) {
    errors.push({
      id: 'description',
      message: 'Payment description is missing',
    });
  }

  return errors;
};

const validateNewPayment = (payment) => (dispatch, getState) => {
  const {
    scenes: {
      payments: {
        paymentForm: { form, isSubmitted },
      },
    },
  } = getState();

  if (!isSubmitted) {
    return false;
  }

  const errors = validateForm(payment || form);

  if (errors.length > 0) {
    return dispatch({
      type: actionTypes.PAYMENT_VALIDATE,
      payload: { errors: keyBy(errors, 'id'), isSubmitted },
    });
  }

  return false;
};

const submitNewPayment = (form) => (dispatch) => {
  const errors = validateForm(form);

  if (errors.length > 0) {
    return dispatch({
      type: actionTypes.PAYMENT_VALIDATE,
      payload: { errors: keyBy(errors, 'id'), isSubmitted: true },
    });
  }

  dispatch({ type: actionTypes.PAYMENT_SUBMIT });
  dispatch(push('/payments/new/review'));

  return false;
};

const editPayment = () => (dispatch) => {
  dispatch({ type: actionTypes.PAYMENT_EDIT });
  dispatch(push('/payments/new'));
};

const closeAlert = () => (dispatch) =>
  dispatch({ type: actionTypes.PAYMENT_ALERT_CLOSE });

const actions = {
  onMount: loadPayments,
  onSelectPage: loadPayments,
  onFilterChange: changeFilter,
  onFilterReset: resetFilter,
  onResetScene: resetScene,
  onLoadUsers: loadUsers,
  onLoadCampaigns: loadCampaigns,
  onLoadApplications: loadApplications,
  onUpdate: updatePayment,
  onSubmit: submitNewPayment,
  onEdit: editPayment,
  onBlur: validateNewPayment,
  onValidate: validateNewPayment,
  onApprove: approvePayment,
  onAlertClose: closeAlert,
};

export default actions;
