import { useSelector } from 'react-redux';

import { createStorage } from '.';

export const auth = {
  init(accessToken) {
    createStorage().save('accessToken', accessToken);
  },

  reset() {
    createStorage().remove('accessToken');
  },
};

export const useAccessToken = () =>
  useSelector((state) => state.account.accessToken);
