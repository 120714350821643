import config from 'config';
import { objToUrlParams } from 'source/utils';

import { createSelector, createStructuredSelector } from 'reselect';

import { getPages as getPaginationPages } from 'source/components/common/pagination';

const getState = (state) => state.scenes.groups.state;
const getAlert = (state) => state.scenes.groups.alert;
const getUsersState = (state) => state.scenes.groups.usersState;
const getGroups = (state) => state.scenes.groups.groups;
const getUsers = (state) => state.scenes.groups.users;
const getPage = (state) => state.scenes.groups.paging.page;
const getPerPage = (state) => state.scenes.groups.paging.pageSize;
const getPagingCount = (state) => state.scenes.groups.paging.count;
const getPagingTotal = (state) => state.scenes.groups.paging.total;
const getAddGroupForm = (state) => state.scenes.groups.addGroupForm;
const getAddUserForm = (state) => state.scenes.groups.addUserForm;
const getGroup = (state) => state.scenes.groups.group;
const getAccessToken = (state) => state.account.accessToken;

const getGroupsTableElement = (group) => group;

const getGroupsTableElements = createSelector(getGroups, (groups) =>
  groups.map(getGroupsTableElement),
);

const getGroupsTablePages = createSelector(
  getPerPage,
  getPagingCount,
  (perPage, count) => getPaginationPages(count, perPage),
);

const getCounter = createSelector(
  getPagingCount,
  getPagingTotal,
  (count, total) => ({ count, total }),
);

const getUserSelectOption = (user) => ({
  id: user.id,
  value: `[${user.id}] ${user.firstname} <${user.email}>`,
});

const getUserSelectOptions = createSelector(getUsers, (users) =>
  users.map(getUserSelectOption),
);

const getAuthorizedUrl = (accessToken, group) =>
  `${config.api.url}/v1/groups/${group.id}/users.xlsx${objToUrlParams({
    access_token: accessToken,
  })}`;

const getUsersReportUrl = createSelector(
  getAccessToken,
  getGroup,
  getAuthorizedUrl,
);

export const getGroupsState = createStructuredSelector({
  state: getState,
  alert: getAlert,
  groups: getGroupsTableElements,
  page: getPage,
  pages: getGroupsTablePages,
  counter: getCounter,
});

export const getGroupUsersState = createStructuredSelector({
  state: getState,
  group: getGroup,
  usersReportUrl: getUsersReportUrl,
});

export const getAddGroupState = createStructuredSelector({
  addGroupForm: getAddGroupForm,
  alert: getAlert,
});

export const getGroupDetailsState = createStructuredSelector({
  alert: getAlert,
  state: getState,
  group: getGroup,
});

export const getAddUserFormState = createStructuredSelector({
  alert: getAlert,
  state: getUsersState,
  users: getUserSelectOptions,
  form: getAddUserForm,
});
