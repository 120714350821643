import { asyncActionCreator } from '@blogfoster/redux-async-utils';

import { actionAuthRequest } from 'source/utils/axios';

export const actionTypes = {
  FETCH_ONE: 'data/users/FETCH_ONE',
  FETCH_MANY: 'data/users/FETCH_MANY',
  PATCH_ONE: 'data/users/PATCH_ONE',
  FETCH_USER_ACCESS_TOKEN: 'data/users/FETCH_USER_ACCESS_TOKEN',
};

/**
 * GET /v1/users
 */
export const userFetchManyV1 = (name) =>
  asyncActionCreator(
    actionTypes.FETCH_MANY,
    ({ page = 0, search }) =>
      (dispatch, getState) => {
        const params = { page: page + 1 };

        if (search) {
          params.search = encodeURIComponent(search);
        }

        return (
          actionAuthRequest(dispatch, getState, { dispatchErrors: false })
            .get('/v1/users', { params })
            // HACKY: to save the page correctly we inject a page header to the response
            .then((resp) => {
              resp.headers['x-page'] = page;
              return resp;
            })
        );
      },
    { name },
  );

/**
 * GET /v2/users
 */
export const userFetchMany = (name) =>
  asyncActionCreator(
    actionTypes.FETCH_MANY,
    ({ fields = [], filters = [] }) =>
      (dispatch, getState) => {
        const params = {};

        if (filters && filters.length > 0) {
          params.filters = JSON.stringify(filters);
        }

        if (fields && fields.length > 0) {
          params.fields = JSON.stringify(fields);
        }

        return actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
        }).get('/v2/users', { params });
      },
    { name },
  );

/**
 * GET /v2/users/:id
 */
export const userFetch = (name) =>
  asyncActionCreator(
    actionTypes.FETCH_ONE,
    (userId, { fields = [] } = {}) =>
      (dispatch, getState) => {
        const params = {};

        if (fields.length > 0) {
          params.fields = JSON.stringify(fields);
        }

        return actionAuthRequest(dispatch, getState).get(
          `/v2/users/${userId}`,
          {
            params,
          },
        );
      },
    { name },
  );

/**
 * POST /v1/users/:id/token
 */
export const userLogIn = (name) =>
  asyncActionCreator(
    actionTypes.FETCH_USER_ACCESS_TOKEN,
    (userId) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState)
        .post(`/v1/users/${userId}/token`, {})
        .then((resp) => {
          resp.data.userId = userId;
          return resp;
        }),
    { name },
  );

/**
 * PATCH /v1/users/:id
 */
export const userPatch = (name, extension = {}) =>
  asyncActionCreator(
    actionTypes.PATCH_ONE,
    (userId, payload) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState).patch(
        `/v1/users/${userId}`,
        payload,
      ),
    { name, ...extension },
  );
