import { combineReducers } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import isEmpty from 'lodash/isEmpty';

import PropTypes from 'prop-types';
import {
  compose,
  setPropTypes,
  lifecycle,
  branch,
  renderComponent,
} from 'recompose';
import { createStructuredSelector, createSelector } from 'reselect';
import { loadStateReducer } from '@blogfoster/redux-async-utils';

import ProgressBar from 'source/components/common/progressBar';
import { compactObject } from 'source/utils';
import { selectFromStatePath, withStatePath } from 'source/utils/selectors';
import withResetScene from 'source/components/commonEnhancers/withResetScene';
import { redux as collapsiblePanelRedux } from 'source/components/common/collapsiblePanel';
import { namespacedReducer, resetableReducer } from 'source/utils/redux';
import {
  applicationFetch,
  applicationUpdate,
  applicationUpdateContentPreviewMedia,
  applicationUpdateContentPreviewAccess,
  applicationUpdateContentPreviewReview,
  actionTypes as applicationActionTypes,
} from 'source/data/applications/actions';
import { getApplication, getCampaign } from 'source/data/selectors';

/* == actions == */
const namespace = 'scenes/campaignDetail/review';
const actionTypes = {
  RESET_SCENE: `${namespace}/RESET_SCENE`,
};

export const actions = {
  onLoadApplication: applicationFetch(namespace),
  onResetScene: () => ({ type: actionTypes.RESET_SCENE, name: namespace }),
  onCheckTask: (applicationId, tasksCheckList) =>
    applicationUpdate(namespace)(applicationId, { tasksCheckList }),
  onUpdateContentPreviewMedia: (applicationId, version, media) =>
    applicationUpdateContentPreviewMedia(namespace)(
      applicationId,
      version,
      media,
    ),
  onUpdateContentPreviewAccess:
    (applicationId, version, allowClient) => (dispatch, getState) =>
      applicationUpdateContentPreviewAccess(namespace)(applicationId, version, {
        allowClient,
      })(dispatch, getState).then(() =>
        // clientContentPreview is resolvable field
        // due to query-mapper limitations, resolvable fields are not present in PUT response,
        // so we make a following GET request
        applicationFetch(namespace)(applicationId, {
          fields: ['clientContentPreview'],
        })(dispatch, getState),
      ),
  onUpdateContentPreviewReview:
    (applicationId, version, payload) => (dispatch, getState) =>
      applicationUpdateContentPreviewReview(namespace)(applicationId, version, {
        // remove "empty string", "null" and "undefined" from the submitted response
        ...compactObject(payload),
      })(dispatch, getState),
  onInsightsReviewUpdate:
    (applicationId, fieldKey, payload) => (dispatch, getState) =>
      applicationUpdate(namespace)(applicationId, {
        // remove "empty string", "null" and "undefined" from the submitted response
        [fieldKey]: compactObject(payload),
      })(dispatch, getState),
  onPublicationAnalyticsUpdate:
    (applicationId, payload) => (dispatch, getState) => {
      applicationUpdate(namespace)(applicationId, payload)(dispatch, getState);
    },
  togglePanel: collapsiblePanelRedux.toggleActionCreator(namespace),
  collapseAllPanels: collapsiblePanelRedux.collapseAllActionCreator(namespace),
};

/* == reducer == */
export const reducer = namespacedReducer(namespace)(
  combineReducers({
    applicationAsync: resetableReducer(actionTypes.RESET_SCENE)(
      loadStateReducer(applicationActionTypes.FETCH, {}),
    ),
    panels: collapsiblePanelRedux.reducers.distinctToggle(namespace),
  }),
);

/* == selector == */
const getPanels = (state) => state.scenes.campaignDetail.review.panels;
const getSelectedPanel = createSelector(getPanels, (panels) =>
  collapsiblePanelRedux.selectors.getExpanded(panels),
);
const selector = createStructuredSelector({
  application: (state, props) =>
    getApplication(state, { applicationId: props.match.params.applicationId }),
  applicationAsync: selectFromStatePath('applicationAsync'),
  campaign: getCampaign,
  selectedContentPreview: (state, props) => {
    const { version, applicationId } = props.match.params;
    const application = getApplication(state, { applicationId });

    if (version && application) {
      const contentPreview =
        (application.contentPreviews || []).find(
          (cp) => cp.version === Number(version),
        ) || null;

      return contentPreview;
    }

    return null;
  },
  publicationAnalyticsFormValue: (state) => {
    const form = getFormValues('publicationAnalyticsForm')(state);
    return form?.publicationAnalytics || [];
  },
  selectedPanel: getSelectedPanel,
});

const withApplicationsFetchProgress = compose(
  setPropTypes({
    applicationAsync: PropTypes.shape({
      loaded: PropTypes.bool,
    }).isRequired,
  }),
  branch(
    ({ applicationAsync: { loaded, data } = {} }) => !loaded && isEmpty(data), // show progress only on initial load
    renderComponent(ProgressBar.Mega),
  ),
);

const createApplicationsFetchEnhancer = ({ fields = [] }) =>
  compose(
    setPropTypes({
      match: PropTypes.shape({
        params: PropTypes.shape({
          applicationId: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      onLoadApplication: PropTypes.func.isRequired,
    }),
    lifecycle({
      componentDidMount() {
        const {
          match: {
            params: { applicationId: id },
          },
          onLoadApplication,
        } = this.props;

        onLoadApplication(id, {
          fields,
        });
      },
    }),
  );

/* == container == */
const statePath = 'scenes.campaignDetail.review';
const createReviewContainerEnhancer = ({ applicationFields }) =>
  compose(
    connect(withStatePath(statePath)(selector), actions),
    withResetScene,
    createApplicationsFetchEnhancer({ fields: applicationFields }),
    withApplicationsFetchProgress,
  );

export default createReviewContainerEnhancer;
