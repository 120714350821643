import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';

import { formatCount } from 'source/utils';
import Alert from 'source/components/common/alert';

import CountriesTableRow from './countriesTableRow';

const DEFAULT_COUNTRIES = [
  { country: 'Germany' },
  { country: 'United Kingdom' },
];

class CountriesTable extends React.PureComponent {
  getAvailableCountries = () => {
    const { fields } = this.props;
    const countries = fields.getAll() || [];
    const usedCountries = countries.map((country) => country.country);
    return DEFAULT_COUNTRIES.filter(
      (country) => !usedCountries.includes(country.country),
    );
  };

  handleAddCountry = () => {
    const { fields } = this.props;
    const country = this.getAvailableCountries(fields)[0];
    fields.push({ ...country, visitsShare: 0 });
  };

  handleRemoveCountry = (index) => {
    const { fields } = this.props;
    fields.remove(index);
  };

  render() {
    const {
      fields,
      meta: { error },
    } = this.props;
    const availableCountries = this.getAvailableCountries();
    return (
      <div className="table-responsive">
        <table className="table table-hover table-channels countries-table">
          <thead>
            <tr>
              <th className="col-reach">Country</th>
              <th>Min. share of total visits (%)</th>
              <th className="col-details">
                <button
                  disabled={availableCountries.length <= 0}
                  type="button"
                  className="btn btn-primary pull-right"
                  onClick={this.handleAddCountry}
                >
                  Add
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {fields.map((country, index) => (
              <FormSection key={country} name={country}>
                <CountriesTableRow
                  index={index}
                  country={country}
                  handleRemove={this.handleRemoveCountry}
                  options={[fields.get(index)].concat(availableCountries)}
                />
              </FormSection>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="col-reach">
                <strong>
                  {formatCount('Country', 'Countries', fields.length)}
                </strong>
              </td>
              <td />
              <td className="col-details" />
            </tr>
          </tfoot>
        </table>
        <div className="form-group has-danger">
          <Alert type="warning" message={error} />
        </div>
      </div>
    );
  }
}

CountriesTable.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default CountriesTable;
