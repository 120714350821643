import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Badge from 'source/components/common/badge';

import { getCampaignEditPath } from './utils';

export function CampaignTodosStatusCell({ status, todos }) {
  let badgeType = 'secondary';

  if (['archived', 'done'].includes(status)) {
    badgeType = 'secondary';
  } else if (todos === 0) {
    badgeType = 'success';
  } else if (todos > 0 && todos <= 10) {
    badgeType = 'warning';
  } else if (todos > 10) {
    badgeType = 'danger';
  }

  return <Badge type={badgeType} pill className="status-container" />;
}

CampaignTodosStatusCell.propTypes = {
  todos: PropTypes.number,
  status: PropTypes.string.isRequired,
};

CampaignTodosStatusCell.defaultProps = {
  todos: null,
};

export function CampaignsStatusCell({ label }) {
  const badgeTypes = {
    draft: 'secondary',
    published: 'warning',
    onMission: 'success',
    archived: 'secondary',
    done: 'success',
  };

  return (
    <Badge type={badgeTypes[label]} className="fs-90 font-weight-normal">
      {label}
    </Badge>
  );
}

CampaignsStatusCell.propTypes = {
  label: PropTypes.string,
};

CampaignsStatusCell.defaultProps = {
  label: 'draft',
};

export function CampaignActions({ campaign }) {
  // hackish way so that the tables aligns with all columns
  const inlineStyle = { marginBottom: '0.5px' };

  return (
    <Link
      className="btn btn-outline-secondary btn-sm"
      style={inlineStyle}
      to={getCampaignEditPath(campaign)}
    >
      Edit
    </Link>
  );
}

CampaignActions.propTypes = {
  campaign: PropTypes.any.isRequired,
};
