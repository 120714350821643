import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import get from 'lodash/get';

import Alert from 'source/components/common/alert';

import TaskBuilderField from './components/taskBuilder';
import selector from './selectors';
import { withSubmissionHandler } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';

const enhance = compose(
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
  reduxForm({
    touchOnBlur: false,
    enableReinitialize: true,
  }),
);

function TasksForm({
  submitting,
  form,
  list,
  handleSubmit,
  pristine,
  valid,
  error,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Alert message={get(error, 'message')} />
      <TaskBuilderField
        tasksForm={`${form}/taskForm`}
        name="tasks"
        taskList={list}
      />
      <div className="row justify-content-end pr-3 pt-3">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={(pristine && valid) || submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
}

TasksForm.propTypes = {
  valid: PropTypes.bool,
  submitting: PropTypes.bool,
  list: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  error: PropTypes.object,
  form: PropTypes.string.isRequired,
};

TasksForm.defaultProps = {
  valid: true,
  submitting: false,
  error: undefined,
};

export default enhance(TasksForm);
