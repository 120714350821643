import React from 'react';
import PropTypes from 'prop-types';

import Badge from 'source/components/common/badge';

export const isWhiteColorRegExp = /^#fff$|^#ffffff$|^white$/i;

export const filterOutTechnicalGroups = (groups) =>
  groups.filter((group) => !isWhiteColorRegExp.test(group.color));

const technicalGroupStyles = (color) =>
  isWhiteColorRegExp.test(color)
    ? { backgroundColor: '#fff', color: '#777', border: '1px solid #777' }
    : {};

function Groups({ groups, privateNetworks }) {
  if (groups.length === 0 && privateNetworks.length === 0) {
    return <span>None</span>;
  }

  const inlineStyle = { paddingLeft: 0, listStyle: 'none' };

  return (
    <ul style={inlineStyle} className="d-flex flex-wrap">
      {groups.map(({ id, name, color }) => (
        <li key={id} className="mr-2">
          <Badge
            pill
            style={{
              backgroundColor: color,
              color: 'white',
              ...technicalGroupStyles(color),
            }}
          >
            {name}
          </Badge>
        </li>
      ))}
      {privateNetworks.map(({ id, name }) => (
        <li key={id} className="mr-2">
          <Badge pill style={technicalGroupStyles('#fff')}>
            {`PN: ${name}`}
          </Badge>
        </li>
      ))}
    </ul>
  );
}

Groups.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
  privateNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

Groups.defaultProps = {
  groups: [],
  privateNetworks: [],
};

export default Groups;
