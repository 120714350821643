import { push } from 'connected-react-router';

import {
  applicationFetchMany,
  applicationFetch,
  applicationUpdate,
  applicationDelete,
  clearApplications,
} from 'source/data/applications/actions';

import { checkboxActionCreator } from 'source/scenes/components/checkbox/redux';

export const namespace = 'scenes/applicationOverview';

export const actionTypes = {
  RESET_SCENE: `${namespace}/RESET_SCENE`,
};

export const listApplications = applicationFetchMany(namespace);
export const loadApplication = applicationFetch(namespace);
export const updateApplication = applicationUpdate(namespace);
export const deleteApplication = applicationDelete(namespace);

export const updateApplicationStatus = (applicationId, status) =>
  updateApplication(applicationId, { status });

export const changeOverviewFilter = (filterName, value) =>
  checkboxActionCreator(`${namespace}/${filterName}`)(value);

export const resetScene =
  ({ applicationIds }) =>
  (dispatch) => {
    dispatch(clearApplications(applicationIds));
    dispatch({ type: actionTypes.RESET_SCENE });
  };

export const syncApplicationWithLocation =
  (campaign, application = {}) =>
  (dispatch) => {
    const { id: campaignId, type, pricing } = campaign;
    const { id: applicationId = '' } = application;

    const campaignType = type === 'website' ? `${type}-${pricing}` : type;
    const url = `/campaigns/${campaignType}/${campaignId}/applications/${applicationId}`;

    dispatch(push(url));
  };
