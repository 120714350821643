import React from 'react';
import PropTypes from 'prop-types';

import CopyButton from 'source/components/common/copyButton';

function UploadsList({ uploads }) {
  return (
    <div>
      <h5 className="text-muted">
        List of all associated Uploads for this Campaign
      </h5>
      <table className="table table-hover mt-3">
        <thead>
          <tr>
            <th style={{ borderTop: 0 }}>filename</th>
            <th style={{ borderTop: 0 }}>URL</th>
            <th style={{ borderTop: 0 }} />
          </tr>
        </thead>
        <tbody>
          {uploads.map((upload) => (
            <tr key={upload.id}>
              <td>{upload.filename}</td>
              <td className="mw-20-5 truncate">{upload.url}</td>
              <td>
                <CopyButton title="copy URL" value={upload.url} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {uploads.length <= 0 && (
        <div className="row">
          <div className="col">
            <div className="alert alert-info">
              Seems like you did not upload any attachment yet!
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

UploadsList.propTypes = {
  uploads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired,
    }),
  ),
};

UploadsList.defaultProps = {
  uploads: [],
};

export default UploadsList;
