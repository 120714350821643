import React from 'react';
import PropTypes from 'prop-types';

import Badge from '../../../../components/common/badge';

const badgeTypeByTaskType = {
  text: 'secondary',
  link: 'warning',
  image: 'info',
  code: 'dark',
};

function TaskBadges({ task }) {
  return (
    <span style={{ margin: '0 8px' }}>
      <Badge type={badgeTypeByTaskType[task.type]}>{task.type}</Badge>
      {task.individual ? (
        <Badge type="info" className="ml-1">
          individual
        </Badge>
      ) : null}
    </span>
  );
}

TaskBadges.propTypes = {
  task: PropTypes.shape({
    type: PropTypes.oneOf(['text', 'link', 'image', 'code']),
    individual: PropTypes.bool,
  }).isRequired,
};

export default TaskBadges;
