import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Badge from 'source/components/common/badge';

const sumUpApplications = (applications) =>
  applications
    .filter((application) => !application.ignore)
    .map((application) => application.status)
    .reduce((total, next) => total + next, 0);

const getSplitApplications = (applications, { separator }) =>
  applications
    .filter((application) => !application.ignore)
    .map((application) => application.status)
    .join(separator);

function TableApplicationDisplay({
  applications,
  type,
  sumUp,
  noTag,
  separator,
  className,
}) {
  let value = sumUp
    ? sumUpApplications(applications)
    : getSplitApplications(applications, { separator });

  if (!applications || !applications.length) {
    value = '-';
  }

  if (noTag) {
    return <span>{value}</span>;
  }

  return (
    <Badge type={type} className={cx('fs-90', 'font-weight-normal', className)}>
      {value}
    </Badge>
  );
}

TableApplicationDisplay.propTypes = {
  className: PropTypes.string,
  applications: PropTypes.array.isRequired,
  noTag: PropTypes.bool,
  sumUp: PropTypes.bool,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger',
    'success',
    'info',
    'warning',
  ]),
  separator: PropTypes.string,
};

TableApplicationDisplay.defaultProps = {
  className: undefined,
  sumUp: false,
  noTag: false,
  type: 'primary',
  separator: ' | ',
};

export default TableApplicationDisplay;
