import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, branch, renderComponent } from 'recompose';

import InfoCard, { InfoCardContainer } from 'source/components/common/infoCard';
import { CollapsiblePanel } from 'source/components/common';

import TodoModel, { TodoShape } from '../todoModel';
import Todo from './todo';
import TodoHeadline from './todoHeadline';

/* No Todos Available */
function TodoListEmpty() {
  return (
    <InfoCardContainer>
      <InfoCard iconName="checklist" title="No open todos.">
        Once there are some open todos for this campaign, you will see them
        here.
      </InfoCard>
    </InfoCardContainer>
  );
}

const enhance = compose(
  setPropTypes({ todos: PropTypes.array }),
  /* with empty todos list */
  branch(
    ({ todos }) => !todos || !todos.length,
    renderComponent(TodoListEmpty),
  ),
);

/* Todos Available */
function TodoList({
  todos,
  todosActionAsync,
  expanded,
  onToggleTodo,
  ...props
}) {
  return (
    <div>
      {todos.map((todo, index) => (
        <CollapsiblePanel
          key={TodoModel.id(todo)}
          id={TodoModel.id(todo)}
          index={index + 1}
          headline={<TodoHeadline todo={todo} />}
          bodyClassName="todo-panel-body"
          expanded={expanded[TodoModel.id(todo)] || null}
          onClick={onToggleTodo}
          className="mt-3"
        >
          <Todo
            {...props}
            onToggleTodo={onToggleTodo}
            todo={todo}
            todoActionAsync={todosActionAsync[TodoModel.id(todo)] || {}}
          />
        </CollapsiblePanel>
      ))}
    </div>
  );
}

TodoList.propTypes = {
  todos: PropTypes.arrayOf(TodoShape).isRequired,
  todosActionAsync: PropTypes.object.isRequired,
  expanded: PropTypes.object.isRequired,
  onToggleTodo: PropTypes.func.isRequired,
};

export default enhance(TodoList);
