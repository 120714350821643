import { createSelector, createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

const getCampaign = (props) => props.campaign;

// predicates
const exists = (value) => Boolean(value);
const notEmpty = (value) => !isEmpty(value);

// filters
const isBucketCampaign = (campaign = {}) => campaign.pricing === 'bucket';
const isBudgetCampaign = (campaign = {}) => campaign.pricing === 'budget';

// key functions
const getAdvertiserPrice = (campaign = {}) => {
  if (campaign.type === 'website' && campaign.pricing === 'bucket') {
    return campaign.budget;
  }

  return get(campaign, 'contract.budget');
};

const progressFields = [
  // general
  { name: 'name', key: 'name', label: 'Name', predicate: exists },
  {
    name: 'language',
    key: 'language',
    label: 'Campaign Language',
    predicate: exists,
  },

  // settings
  {
    name: 'manager',
    key: 'settings.managerName',
    label: 'Manager Name',
    predicate: exists,
  },
  {
    name: 'currency',
    key: 'settings.currency',
    label: 'Campaign Currency',
    predicate: exists,
  },

  // budget
  {
    name: 'advertiserPrice',
    key: getAdvertiserPrice,
    label: 'Advertiser Budget',
    predicate: exists,
  },

  // [bucket only] contingents - at least one contingent should exist
  {
    name: 'contingents',
    key: 'contingents',
    label: 'one Reach Channel',
    predicate: notEmpty,
    filter: isBucketCampaign,
  },

  // [budget only] contract
  {
    name: 'reach',
    key: 'contract.reach',
    label: 'Reach',
    predicate: exists,
    filter: isBudgetCampaign,
  },
  {
    name: 'minPosts',
    key: 'contract.minPosts',
    label: 'Min. Posts',
    predicate: exists,
    filter: isBudgetCampaign,
  },

  // details
  {
    name: 'headline',
    key: 'details.headline',
    label: 'Campaign Public Name',
    predicate: exists,
  },
  {
    name: 'mission',
    key: 'details.mission',
    label: 'Mission',
    predicate: exists,
  },
  { name: 'goal', key: 'details.goal', label: 'Goal', predicate: exists },
  {
    name: 'companyLogo',
    key: 'details.companyLogoUrl',
    label: 'Company Logo',
    predicate: exists,
  },
  {
    name: 'previewImage',
    key: 'details.previewImageUrl',
    label: 'Preview Image',
    predicate: exists,
  },

  // timeline
  {
    name: 'applicationStart',
    key: 'timeline.applicationStart',
    label: 'Start of Applications',
    predicate: exists,
  },
  {
    name: 'publishingDates',
    key: 'timeline.publishingDates',
    label: 'Publishind Dates',
    predicate: notEmpty,
  },

  // tasks - at least one task should exist
  { name: 'tasks', key: 'tasks', label: 'Tasks', predicate: notEmpty },
];

// calculate the value of a given field
const getValue = ({ key }, campaign) => {
  if (typeof key === 'function') {
    return key(campaign);
  }

  return get(campaign, key);
};

/**
 * Returns all progress names in the correct order
 */
const getProgressFields = createSelector(getCampaign, (campaign) =>
  progressFields.filter(({ filter }) => (filter ? filter(campaign) : true)),
);

/**
 * Returns a list of progress names for which the currently active campaign
 * doesn't conform to the predicate function of the key.
 */
const getMissingFields = createSelector(
  getProgressFields,
  getCampaign,
  (fields, campaign) =>
    fields
      .map((field) => ({ ...field, value: getValue(field, campaign) }))
      .filter(({ predicate, value }) => !predicate(value)),
);

/**
 * Returns the progress level of the current campaign.
 */
const getProgress = createSelector(
  getProgressFields,
  getMissingFields,
  (allKeys, missingKeys) => 1 - missingKeys.length / allKeys.length,
);

export default createStructuredSelector({
  campaign: getCampaign,
  progress: getProgress,
  missingFields: getMissingFields,
});
