import React from 'react';
import PropTypes from 'prop-types';
import Octicon from 'react-octicon';
import cx from 'classnames';

class Alert extends React.PureComponent {
  renderClose() {
    const { onClose } = this.props;

    if (!onClose) {
      return null;
    }

    return (
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        onClick={onClose}
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    );
  }

  renderDownload() {
    const { blob, onClose } = this.props;

    if (!blob) {
      return null;
    }

    const href = window.URL.createObjectURL(blob);

    const styles = {};
    if (onClose) {
      styles.paddingRight = '0.5rem';
    }

    return (
      <a className="pull-md-right" href={href} download style={styles}>
        Download Error Report
        <Octicon name="cloud-download" style={{ paddingLeft: '0.2rem' }} />{' '}
      </a>
    );
  }

  render() {
    const { message, mega, type, className } = this.props;
    if (!message) {
      return null;
    }

    const content = (
      <div className={cx('alert', `alert-${type}`, className)} role="alert">
        {this.renderClose()}
        <span>{message}</span>
        {this.renderDownload()}
      </div>
    );

    if (!mega) {
      return content;
    }

    return (
      <div className="flex-center">
        <div className="flex-center-item">{content}</div>
      </div>
    );
  }
}

Alert.propTypes = {
  /**
   * the message to be rendered
   */
  message: PropTypes.node,
  mega: PropTypes.bool,
  /**
   * a downloadable error report blob
   */
  blob: PropTypes.instanceOf(Blob),
  type: PropTypes.oneOf([
    'secondary',
    'primary',
    'danger',
    'warning',
    'info',
    'success',
  ]),
  className: PropTypes.string,
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  message: undefined,
  mega: false,
  blob: undefined,
  type: 'danger',
  className: '',
  onClose: undefined,
};

export default Alert;
