import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';
import { SubmissionError } from 'redux-form';

import CollapsiblePanel from 'source/components/common/collapsiblePanel';
import { assertSuccessStatusCode } from 'source/utils/reduxAsyncUtils';

/**
 * wrapper for campaign update handlers
 */
const validateCampaignUpdateSubmission =
  (fn) =>
  (props) =>
  (...args) => {
    const { campaign, newCampaign } = props;

    if (!newCampaign && (!campaign || !campaign.id)) {
      throw new SubmissionError({
        _error: 'cannot update a non-existing campaign',
      });
    }

    return Promise.resolve()
      .then(() => fn(...args))
      .then((res) => {
        // handles "single" as well as "array of" responses
        if (Array.isArray(res)) {
          return res.map(assertSuccessStatusCode);
        }

        return assertSuccessStatusCode(res);
      })
      .catch((err) => {
        if (err instanceof SubmissionError) {
          return Promise.reject(err);
        }

        return Promise.reject(
          new SubmissionError({
            _error: err,
          }),
        );
      });
  };

export const withSubmissionValidation = withHandlers({
  onSubmit:
    ({ onSubmit, ...props }) =>
    (...args) =>
      validateCampaignUpdateSubmission(onSubmit)(props)(...args),
});

export const withNextPanelAfterSubmission = compose(
  setPropTypes({
    id: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onNextSection: PropTypes.func.isRequired,
    newCampaign: PropTypes.bool,
  }),
  withHandlers({
    onSubmit:
      ({ id, onSubmit, onNextSection, newCampaign }) =>
      (...args) =>
        Promise.resolve()
          .then(() => onSubmit(...args))
          /**
           * NOTE @alexspri
           *    Pass the `submitResult` back to the caller, as we're actually
           *    wrapping the "onSubmit" function and the "user" (me :P) expected
           *    the get a submission result back, but instead the next-panel
           *    action was returned.
           */
          .then((submitResult) => {
            if (newCampaign) {
              return Promise.resolve();
            }

            return Promise.resolve()
              .then(() => onNextSection(id))
              .then(() => submitResult);
          }),
  }),
);

export const withCollapsiblePanel = (Component) => {
  function Wrapped({ id, selectedPanel, onSelectPanel, ...props }) {
    return (
      <CollapsiblePanel
        {...props}
        id={id}
        expanded={selectedPanel === id}
        onClick={onSelectPanel}
      >
        <Component {...props} id={id} />
      </CollapsiblePanel>
    );
  }

  Wrapped.propTypes = {
    id: PropTypes.string.isRequired,
    selectedPanel: PropTypes.string,
    onSelectPanel: PropTypes.func.isRequired,
  };

  Wrapped.defaultProps = {
    selectedPanel: undefined,
  };

  return Wrapped;
};
