import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';

import {
  withSubmissionValidation,
  withNextPanelAfterSubmission,
} from '../../enhancers';

// Makins sure all visit shares are saved as floats
const mapCountries = (countries) =>
  countries.map((country) => ({
    ...country,
    visitsShare: country.visitsShare / 100,
  }));

const submitCountries =
  ({ onUpdateCampaign }) =>
  (values, __, props) =>
    onUpdateCampaign(props.campaign.id, {
      countries: mapCountries(values.countries),
    });

export const withSubmissionHandler = compose(
  setPropTypes({
    campaign: PropTypes.shape({ id: PropTypes.string.isRequired }),
    onUpdateCampaign: PropTypes.func.isRequired,
  }),
  withHandlers({ onSubmit: submitCountries }),
  withSubmissionValidation,
  withNextPanelAfterSubmission,
);
