import { asyncActionCreator } from '@blogfoster/redux-async-utils';
import isEmpty from 'lodash/isEmpty';

import { actionAuthRequest } from 'source/utils/axios';

export const actionTypes = {
  CATEGORIES_FETCH_MANY: 'data/channels/CATEGORIES_FETCH_MANY',
  FETCH_ONE: 'data/channels/FETCH_ONE',
  PATCH_ONE: 'data/channels/PATCH_ONE',
  DELETE_ONE: 'data/channels/DELETE_ONE',
  FETCH_MANY: 'data/channels/FETCH_MANY',
  SEARCH_MANY: 'data/channels/SEARCH_MANY',
  DELETE_WEBSITE: 'data/channels/DELETE_WEBSITE',
  UPDATE_KPI: 'data/channels/UPDATE_KPI',
  UPDATE_KEY_METRICS: 'data/channels/UPDATE_KEY_METRICS',
};

/**
 * GET /v2/channels/{channelId}
 */
export const channelFetch = (name) =>
  asyncActionCreator(
    actionTypes.FETCH_ONE,
    (channelId, { fields = [] } = {}) =>
      (dispatch, getState) => {
        const params = {};

        if (fields.length > 0) {
          params.fields = JSON.stringify(fields);
        }

        return actionAuthRequest(dispatch, getState).get(
          `/v2/channels/${channelId}`,
          { params },
        );
      },
    { name },
  );

/**
 * PATCH /v2/channels/{channelId}
 */
export const channelPatch = (name) =>
  asyncActionCreator(
    actionTypes.PATCH_ONE,
    (channelId, payload) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
      }).patch(`/v2/channels/${channelId}`, payload),
    { name },
  );

/**
 * PUT /v2/channels/{channelId}/kpi
 */
export const channelUpdateKpi = (name) =>
  asyncActionCreator(
    actionTypes.UPDATE_KPI,
    (channelId, payload) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
      }).put(`/v2/channels/${channelId}/kpi`, payload),
    { name },
  );

/**
 * PATCH /v2/channels/{channelId}/key-metrics
 */
export const channelUpdateKeyMetrics = (name) =>
  asyncActionCreator(
    actionTypes.UPDATE_KEY_METRICS,
    (channelId, payload) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
      }).patch(`/v2/channels/${channelId}/key-metrics`, payload),
    { name },
  );

/**
 * POST /v2/channels/{channelId}/refresh-key-metrics
 */
export const channelRefreshKeyMetrics = (channelId) => (dispatch, getState) =>
  actionAuthRequest(dispatch, getState, {
    dispatchErrors: false,
  }).post(`/v2/channels/${channelId}/refresh-key-metrics`);

/**
 * DELETE /v2/channels/{channelId}
 */
export const channelDelete = (name) =>
  asyncActionCreator(
    actionTypes.DELETE_ONE,
    (channelId, params) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
      }).delete(`/v2/channels/${channelId}`, { params }),
    { name },
  );

/**
 * GET /v2/channels
 */
export const channelsFetchMany = (name) =>
  asyncActionCreator(
    actionTypes.FETCH_MANY,
    ({ page = 0, fields = [], filters = [], sort }) =>
      (dispatch, getState) => {
        const params = { page: page + 1 };

        if (filters.length > 0) {
          params.filters = JSON.stringify(filters);
        }

        if (fields.length > 0) {
          params.fields = JSON.stringify(fields);
        }

        if (!isEmpty(sort)) {
          params.sort = JSON.stringify(sort);
        }

        return (
          actionAuthRequest(dispatch, getState, { dispatchErrors: false })
            .get('/v2/channels', { params })
            // HACKY: to save the page correctly we inject a page header to the response
            .then((resp) => {
              resp.headers['x-page'] = page;
              return resp;
            })
        );
      },
    { name },
  );

/**
 * GET /v2/channels/search
 */
export const channelsSearchMany = (name) =>
  asyncActionCreator(
    actionTypes.SEARCH_MANY,
    ({ page = 0, fields = [], filters = [], query, sort }) =>
      (dispatch, getState) => {
        const params = { page: page + 1 };

        if (filters.length > 0) {
          params.filters = JSON.stringify(filters);
        }

        if (fields.length > 0) {
          params.fields = JSON.stringify(fields);
        }

        if (!isEmpty(query)) {
          params.query = JSON.stringify(query);
        }

        if (!isEmpty(sort)) {
          params.sort = JSON.stringify(sort);
        }

        return (
          actionAuthRequest(dispatch, getState, { dispatchErrors: false })
            .get('/v2/channels/search', { params })
            // HACKY: to save the page correctly we inject a page header to the response
            .then((resp) => {
              resp.headers['x-page'] = page;
              return resp;
            })
        );
      },
    { name },
  );

/**
 * POST /v2/channels/rebuildSearch'
 */
export const rebuildChannelSearch = () => (dispatch, getState) =>
  actionAuthRequest(dispatch, getState, {
    dispatchErrors: false,
  }).post('/v2/channels/rebuildSearch');

/*
 * GET /v2/categories
 */

export const categoriesFetchMany = (name) =>
  asyncActionCreator(
    actionTypes.CATEGORIES_FETCH_MANY,
    () => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
      }).get('/v2/categories'),
    { name },
  );
