import React from 'react';
import PropTypes from 'prop-types';

import { EventDefault } from './index';
import EventContentCounterOffer from '../event/eventContentCounterOffer';

function EventApplicationCreated({ application, ...props }) {
  const {
    match: {
      payment: {
        currency,
        counterOffer,
        totals: { price },
      },
    },
  } = application;

  return (
    <EventDefault {...props}>
      {counterOffer && (
        <EventContentCounterOffer price={price} currency={currency} />
      )}
    </EventDefault>
  );
}

EventApplicationCreated.propTypes = {
  content: PropTypes.string,
  application: PropTypes.shape({
    match: PropTypes.shape({
      payment: PropTypes.shape({
        currency: PropTypes.oneOf(['EUR', 'GBP']).isRequired,
        counterOffer: PropTypes.bool.isRequired,
        totals: PropTypes.shape({
          price: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default EventApplicationCreated;
