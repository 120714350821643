import { createSelector, createStructuredSelector } from 'reselect';
import { isDirty } from 'redux-form';
import get from 'lodash/get';

import { compactObject } from 'source/utils';

const getCampaign = (state, props) => props.campaign;
const getFormDirty = (state, props) => isDirty(props.form)(state);

const getInitialValues = createSelector(getCampaign, (campaign) => {
  const details = get(campaign, 'details', {});

  return compactObject({
    name: details.headline,
    mission: details.mission,
    goal: details.goal,
    companyLogoUrl: details.companyLogoUrl,
    previewImageUrl: details.previewImageUrl,
  });
});

const getHeadline = createSelector(getFormDirty, (dirty) => {
  let headline = 'Briefing';
  if (dirty) {
    headline += '*';
  }

  return headline;
});

const getSubHeadline = createSelector(getCampaign, (campaign = {}) => {
  const details = get(campaign, 'details', {});

  let subHeadline = '';
  if (details.headline) {
    subHeadline += details.headline;
  }

  return subHeadline;
});

const getProductShipment = createSelector(getCampaign, (campaign = {}) =>
  get(campaign, 'productShipment'),
);

export default createStructuredSelector({
  initialValues: getInitialValues,
  headline: getHeadline,
  subHeadline: getSubHeadline,
  productShipment: getProductShipment,
});
