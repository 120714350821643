import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

import config from 'config';

const getFullNameFromUsername = (username) =>
  username.split('.').map(capitalize).join(' ');

const getEmailFromUsername = (username) =>
  `${username}@${config.primaryDomain}`;

const getUsernameFromEmail = (email) => {
  const [username] = email.split('@');

  return username;
};

export const formatUserData = (user) => {
  // when user is an object, for example: { username: 'paul.paulson', name: 'Paul Paulson' }
  if (isObject(user)) {
    return {
      ...user,
      name: user.name || getFullNameFromUsername(user.username),
      email: user.email || getEmailFromUsername(user.username),
    };
  }

  const isEmail = isString(user) && user.includes('@');

  // when user is an email, for example: 'paul.paulson@eqolot.com'
  if (isEmail) {
    const username = getUsernameFromEmail(user);

    return {
      username,
      name: getFullNameFromUsername(username),
      email: user,
    };
  }

  // when user is a username, for example: 'paul.paulson'
  return {
    username: user,
    name: getFullNameFromUsername(user),
    email: getEmailFromUsername(user),
  };
};

export const campaignManagers = config.managers.map(formatUserData);
export const allUsers = sortBy(
  uniqBy(config.allUsers.map(formatUserData), 'username'),
  'name',
);
export const paymentApprovers = config.paymentApprovers.map(formatUserData);

export const getCampaignManager = (manager) =>
  find(campaignManagers, { username: formatUserData(manager).username });
export const getCampaignManagerByEmail = (email) =>
  getCampaignManager({ username: formatUserData(email).username });
export const getUser = (user) =>
  find(allUsers, { username: formatUserData(user).username });
export const getUserByEmail = (email) =>
  getUser({ username: formatUserData(email).username });

export const isPaymentApprover = (user) =>
  Boolean(find(paymentApprovers, { username: formatUserData(user).username }));
