import { push } from 'connected-react-router';
import validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';

import actionTypes from 'source/constants/actionTypes';
import { requestFlow, actionAuthRequest } from 'source/utils/axios';

/* LinksFilter */
export const changeFilter = (filter, attribute, value) => ({
  type: actionTypes.LINKS_FILTER_CHANGE,
  payload: { filter, attribute, value },
});

export const addFilter = () => ({ type: actionTypes.LINKS_FILTER_ADD });

export const removeFilter = (filter) => ({
  type: actionTypes.LINKS_FILTER_REMOVE,
  payload: { filter },
});

/* LinksTable */
export const selectPage = (page) => ({
  type: actionTypes.LINKS_PAGE_SELECTED,
  payload: { page },
});

export const linkSelected = (linkId) => ({
  type: actionTypes.LINKS_LINK_SELECTED,
  payload: { linkId },
});

/* others */
export const closeAlert = () => ({ type: actionTypes.LINKS_ALERT_CLOSE });

/* AddLink */
export const updateNewLink = (form, attribute, value) => ({
  type: actionTypes.LINKS_ADD_UPDATE,
  payload: { form, attribute, value },
});

const getLinkTraits = (form) => {
  let traits = {};

  if (form.user && form.user.orig) {
    traits = { ...traits, userId: form.user.orig.id };
  }

  if (form.campaign && form.campaign.orig) {
    traits = {
      ...traits,
      campaignId: form.campaign.orig.id,
      campaignName: form.campaign.orig.name,
    };
  }

  return traits;
};

const createNewLink = (form) => (dispatch, getState) => {
  const payload = {
    targetUrl: form.targetUrl.value,
    traits: getLinkTraits(form),
  };

  const onBefore = () =>
    dispatch({
      type: actionTypes.LINKS_ADD_SUBMITTING,
      payload: { payload },
    });

  const onSuccess = (link) => {
    dispatch({ type: actionTypes.LINKS_ADD_SUBMITTED, payload: link });
    return dispatch(push('/links'));
  };

  const onError = () => {
    dispatch({ type: actionTypes.LINKS_ADD_SUBMITION_FAILED });
    return dispatch(push('/links'));
  };

  const request = () =>
    actionAuthRequest(dispatch, getState)
      .post('/v1/links', payload)
      .then((resp) => resp.data);

  return requestFlow(request, { onBefore, onSuccess, onError });
};

const updateExistingLink = (form) => (dispatch, getState) => {
  const payload = {
    targetUrl: form.targetUrl.value,
    traits: getLinkTraits(form),
  };

  const onBefore = () =>
    dispatch({
      type: actionTypes.LINKS_DETAILS_SUBMITTING,
      payload: { payload },
    });

  const onSuccess = (link) => {
    dispatch({ type: actionTypes.LINKS_DETAILS_SUBMITTED, payload: link });
    dispatch(push('/links'));
  };

  const onError = () => {
    dispatch({ type: actionTypes.LINKS_ADD_SUBMITION_FAILED });
    dispatch(push('/links'));
  };

  const request = () =>
    actionAuthRequest(dispatch, getState)
      .put(`/v1/links/${form.id}`, payload)
      .then((resp) => resp.data);

  return requestFlow(request, { onBefore, onSuccess, onError });
};

export const submitNewLink = (form) => (dispatch) => {
  // apply input validation
  const errors = [];
  const validatorUrlOptions = {
    protocols: ['http', 'https'],
    require_protocol: true,
  };

  if (!form.targetUrl.value || isEmpty(form.targetUrl.value.trim())) {
    errors.push({ id: 'targetUrl', message: 'missing target url' });
  } else if (!validator.isURL(form.targetUrl.value, validatorUrlOptions)) {
    errors.push({ id: 'targetUrl', message: 'target url is not a valid url' });
  }

  if (errors.length > 0) {
    return dispatch({
      type: actionTypes.LINKS_ADD_VALIDATION,
      payload: { errors: keyBy(errors, 'id') },
    });
  }

  return dispatch(createNewLink(form));
};

export const cancelNewLink = () => (dispatch) => {
  dispatch({ type: actionTypes.LINKS_ADD_CANCEL });
  dispatch(push('/links'));
};

/* LinksContainer */
export const loadLinksState = () => (dispatch, getState) => {
  const onBefore = () => {
    dispatch({ type: actionTypes.LINK_STATE_LOADING });
  };

  const onSuccess = (payload) => {
    dispatch({ type: actionTypes.LINK_STATE_LOADED, payload });
  };

  const onError = (error) => {
    dispatch({ type: actionTypes.LINK_STATE_FAILED, payload: { error } });
  };

  const request = () => {
    const fetchLinks = () =>
      actionAuthRequest(dispatch, getState)
        .get('/v1/links')
        .then((resp) => resp.data);

    return Promise.all([fetchLinks()]).then(([links]) => ({ links }));
  };

  return requestFlow(request, { onBefore, onSuccess, onError });
};

/* AddLinkContainer */
export const loadAddLinkState =
  ({ withArchivedCampaigns = false, onlyCampaigns = false } = {}) =>
  (dispatch, getState) => {
    const {
      links: { data },
    } = getState();

    const onBefore = () => {
      dispatch({ type: actionTypes.LINK_STATE_LOADING });
    };

    const onSuccess = (payload) => {
      dispatch({ type: actionTypes.LINK_STATE_LOADED, payload });
    };

    const onError = (error) => {
      dispatch({ type: actionTypes.LINK_STATE_FAILED, payload: { error } });
    };

    const request = () => {
      const loadUsers = () => {
        if (onlyCampaigns) {
          return Promise.resolve(data.users);
        }

        return actionAuthRequest(dispatch, getState)
          .get('/v1/users')
          .then((resp) => resp.data);
      };

      const loadCampaigns = () => {
        const params = {
          fields: JSON.stringify(['id', 'name']),
        };

        if (!withArchivedCampaigns) {
          params.filters = JSON.stringify([{ status: { not: 'archived' } }]);
        }

        return actionAuthRequest(dispatch, getState)
          .get('/v2/campaigns', { params })
          .then((resp) => resp.data);
      };

      return Promise.all([loadUsers(), loadCampaigns()]).then(
        ([users, campaigns]) => ({ users, campaigns }),
      );
    };

    return requestFlow(request, { onBefore, onSuccess, onError });
  };

/* LinkDetails Container */
export const loadLinkDetailsState = (linkId) => (dispatch, getState) => {
  const onBefore = () => {
    dispatch({ type: actionTypes.LINK_DETAILS_LOADING });
  };

  const onSuccess = ({ link, users, campaigns }) => {
    dispatch({
      type: actionTypes.LINK_STATE_LOADED,
      payload: { users, campaigns },
    });
    dispatch({ type: actionTypes.LINK_DETAILS_LOADED, payload: link });
  };

  const onError = (error) => {
    dispatch({ type: actionTypes.LINK_STATE_FAILED, payload: { error } });
  };

  const request = () => {
    const loadLink = () =>
      actionAuthRequest(dispatch, getState)
        .get(`/v1/links/${linkId}`)
        .then((resp) => resp.data);

    const loadUsers = () =>
      actionAuthRequest(dispatch, getState)
        .get('/v1/users')
        .then((resp) => resp.data);

    const loadCampaigns = () => {
      const params = {
        fields: JSON.stringify(['id', 'name']),
      };

      return actionAuthRequest(dispatch, getState)
        .get('/v2/campaigns', { params })
        .then((resp) => resp.data);
    };

    return Promise.all([loadLink(), loadUsers(), loadCampaigns()]).then(
      ([link, users, campaigns]) => ({
        link,
        users,
        campaigns,
      }),
    );
  };

  return requestFlow(request, { onBefore, onSuccess, onError });
};

/**/
export const editLink = () => ({ type: actionTypes.LINK_DETAILS_EDIT });

export const editLinkForm = (form, attribute, value) => ({
  type: actionTypes.LINK_DETAILS_EDIT_FORM,
  payload: { form, attribute, value },
});

export const submitEditedLink = (form) => (dispatch) => {
  // validation

  const errors = [];

  const validatorUrlOptions = {
    protocols: ['http', 'https'],
    require_protocol: true,
  };

  if (!form.targetUrl.value || isEmpty(form.targetUrl.value.trim())) {
    errors.push({ id: 'targetUrl', message: 'missing target url' });
  } else if (!validator.isURL(form.targetUrl.value, validatorUrlOptions)) {
    errors.push({ id: 'targetUrl', message: 'target url is not a valid url' });
  }

  if (errors.length > 0) {
    return dispatch({
      type: actionTypes.LINK_DETAILS_FORM_VALIDATION,
      payload: { errors: keyBy(errors, 'id') },
    });
  }

  return dispatch(updateExistingLink(form));
};

export const cancelEditLink = (form) => (dispatch) => {
  dispatch({ type: actionTypes.LINK_DETAILS_EDIT_CANCEL });
  return dispatch(push(`/links/${form.id}`));
};

/**/

export const resetLinkDetails = () => ({
  type: actionTypes.LINK_DETAILS_RESET,
});
