import moment from 'moment';
import { createSelector, createStructuredSelector } from 'reselect';

import { getPages as getPaginationPages } from 'source/components/common/pagination';

const getState = (state) => state.scenes.payments.state;
const getAlert = (state) => state.scenes.payments.alert;
const getPerPage = (state) => state.scenes.payments.paging.pageSize;
const getPayment = (state) => state.scenes.payments.payment;
const getPayments = (state) => state.scenes.payments.payments;
const getLoggedInUser = (state) => state.app.user;
const getUsers = (state) => state.scenes.payments.users;
const getCampaigns = (state) => state.scenes.payments.campaigns;
const getApplications = (state) => state.scenes.payments.applications;
const getPage = (state) => state.scenes.payments.paging.page;
const getPagingCount = (state) => state.scenes.payments.paging.count;
const getPagingTotal = (state) => state.scenes.payments.paging.total;
const getFilters = (state) => state.scenes.payments.filters;
const getPaymentForm = (state) => state.scenes.payments.paymentForm.form;
const getNewPaymentErrors = (state) => state.scenes.payments.paymentForm.errors;

const getPaymentPayload = (state) => {
  const {
    scenes: {
      payments: { payment },
    },
  } = state;
  return {
    approvedBy: payment.approvedBy.value,
    userId: payment.userId.id,
    amount: payment.amount.value,
    currency: payment.currency.value,
    description: payment.description.value,
    campaignId: payment.campaignId.id,
    applicationId: payment.applicationId.id,
  };
};

const getPaymentsTableElement = (payment) => ({
  id: payment.id,
  userId: payment.receiver.id,
  amount: payment.amount / 100,
  currency: payment.currency,
  description: payment.description,
  approvedBy: payment.sender.id,
  createdAt: moment(payment.createdAt).format('YYYY-MM-DD HH:mm'),
});

const getPaymentsTableElements = createSelector(getPayments, (payments) =>
  payments.map(getPaymentsTableElement),
);

const getUserSelectOption = (user) => ({
  id: user.id,
  value: `[${user.id}] ${user.firstname} <${user.email}>`,
});

const getUserSelectOptions = createSelector(getUsers, (users) =>
  users.map(getUserSelectOption),
);

const getCampaignSelectOption = (campaign) => ({
  id: campaign.id,
  value: campaign.name,
});

const getCampaignSelectOptions = createSelector(getCampaigns, (campaigns) =>
  campaigns.map(getCampaignSelectOption),
);

const getApplicationSelectOption = (application) => ({
  id: application.id,
  value: `${application.user.firstname} <${
    application.channel && application.channel.name
  }>`,
});

const getApplicationSelectOptions = createSelector(
  getApplications,
  (applications) => applications.map(getApplicationSelectOption),
);

const getPaymentsTablePages = createSelector(
  getPerPage,
  getPagingCount,
  (perPage, count) => getPaginationPages(count, perPage),
);

const getCounter = createSelector(
  getPagingCount,
  getPagingTotal,
  (count, total) => ({ count, total }),
);

export const getPaymentsState = createStructuredSelector({
  state: getState,
  alert: getAlert,
  payments: getPaymentsTableElements,
  page: getPage,
  pages: getPaymentsTablePages,
  counter: getCounter,
  filters: getFilters,
});

export const getNewPaymentState = createStructuredSelector({
  state: getState,
  user: getLoggedInUser,
  users: getUserSelectOptions,
  campaigns: getCampaignSelectOptions,
  applications: getApplicationSelectOptions,
  form: getPaymentForm,
  errors: getNewPaymentErrors,
});

export const getReviewPaymentState = createStructuredSelector({
  state: getState,
  alert: getAlert,
  payment: getPayment,
  paymentPayload: getPaymentPayload,
});
