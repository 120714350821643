import { asyncActionReducer, asyncStates } from '@blogfoster/redux-async-utils';

import { namespacedReducer, combineReducersFlat } from 'source/utils/redux';
import { actionTypes as groupsActionTypes } from 'source/data/groups/actions';
import { actionTypes as userActionTypes } from 'source/data/users/actions';

import { getPagingFromHeaders } from 'source/components/common/pagination';
import { namespace, actionTypes } from './actions';

const getAddGroupFormState = () => ({
  name: {
    value: '',
  },
  color: {
    value: '#24506B',
  },
  errors: {},
  submitted: false,
  submitting: false,
  error: null,
});

const getAddUserFormState = () => ({
  userId: {
    value: '',
  },
  errors: {},
  submitted: false,
  submitting: false,
  error: null,
});

const getPagingInitialState = () => ({
  page: 0,
  pageSize: 30,
  count: 0,
  total: 0,
});

const getInitialState = () => ({
  state: '',
  usersState: '',
  groups: [],
  users: [],
  clients: [],
  alert: null,
  group: {
    users: [],
  },
  addGroupForm: getAddGroupFormState(),
  addUserForm: getAddUserFormState(),
  paging: getPagingInitialState(),
});

const addGroupFormReducer = (state, { type, payload }) => {
  if (type !== actionTypes.ADD_GROUP_FORM_CHANGE) {
    return state;
  }

  return {
    ...state,
    addGroupForm: {
      ...payload,
      submitted: false,
    },
  };
};

const addUserFormReducer = (state, { type, payload }) => {
  if (type !== actionTypes.ADD_USER_FORM_CHANGE) {
    return state;
  }

  return {
    ...state,
    addUserForm: {
      ...payload,
      submitted: false,
    },
  };
};

const resetState = (state = {}, action) => {
  if (action.type !== actionTypes.RESET_SCENE) {
    return state;
  }

  return {
    ...state,
    ...getInitialState(),
  };
};

const groupsDataReducer = asyncActionReducer(groupsActionTypes.FETCH_MANY, {
  [asyncStates.pending]: (state) => ({
    ...state,
    state: 'loading',
  }),
  [asyncStates.success]: (state, { payload }) => ({
    ...state,
    state: 'loaded',
    groups: payload.data,
    paging: getPagingFromHeaders(payload.headers),
  }),
  [asyncStates.failure]: (state) => ({
    ...state,
    state: 'failure',
  }),
});

const usersDataReducer = asyncActionReducer(userActionTypes.FETCH_MANY, {
  [asyncStates.pending]: (state) => ({
    ...state,
    usersState: 'loading',
    error: null,
  }),
  [asyncStates.success]: (state, { payload }) => ({
    ...state,
    usersState: 'loaded',
    error: null,
    users: payload.data,
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    usersState: 'failure',
    error,
  }),
});

const groupDetailsReducer = asyncActionReducer(groupsActionTypes.FETCH_ONE, {
  [asyncStates.pending]: (state) => ({
    ...state,
    state: 'loading',
  }),
  [asyncStates.success]: (state, { payload: { data: group } }) => ({
    ...state,
    state: 'loaded',
    group,
  }),
  [asyncStates.failure]: (state) => ({
    ...state,
    state: 'failure',
  }),
});

const groupPostReducer = asyncActionReducer(groupsActionTypes.CREATE, {
  [asyncStates.pending]: (state) => ({
    ...state,
    addGroupForm: {
      ...state.addGroupForm,
      submitting: true,
      submitted: false,
      error: null,
    },
  }),
  [asyncStates.success]: (state) => ({
    ...state,
    addGroupForm: {
      ...state.addGroupForm,
      submitting: false,
      submitted: true,
      error: null,
    },
    alert: {
      type: 'success',
      message: 'Added new group successfully!',
    },
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    addGroupForm: {
      ...state.addGroupForm,
      submitting: false,
      submitted: false,
      error,
    },
    alert: {
      type: 'danger',
      message: `Sorry, can't create new group: ${error.message}.`,
    },
  }),
});

const groupPutUserReducer = asyncActionReducer(groupsActionTypes.PUT_USER, {
  [asyncStates.pending]: (state) => ({
    ...state,
    addUserForm: {
      ...state.addUserForm,
      submitting: true,
      submitted: false,
      error: null,
    },
  }),
  [asyncStates.success]: (state) => ({
    ...state,
    addUserForm: {
      ...state.addUserForm,
      submitting: false,
      submitted: true,
      error: null,
    },
    alert: {
      type: 'success',
      message: 'Successfully added the user to the group!',
    },
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    addUserForm: {
      ...state.addUserForm,
      submitting: false,
      submitted: false,
      error,
    },
    alert: {
      type: 'danger',
      message: "Sorry, can't add the user to the group. Please try again.",
    },
  }),
});

const groupRemoveUserReducer = asyncActionReducer(
  groupsActionTypes.DELETE_USER,
  {
    [asyncStates.pending]: (state) => ({
      ...state,
    }),
    [asyncStates.success]: (state, { payload: { userId } }) => ({
      ...state,
      group: {
        ...state.group,
        users: state.group.users.filter((user) => user.id !== userId),
      },
      alert: {
        type: 'success',
        message: `Removed user ${userId} from group.`,
      },
    }),
    [asyncStates.failure]: (state) => ({
      ...state,
      alert: {
        type: 'danger',
        message:
          "Sorry, can't remove the user from the group. Please try again.",
      },
    }),
  },
);

const groupUserStatusReducer = asyncActionReducer(
  groupsActionTypes.PATCH_USER_STATUS,
  {
    [asyncStates.pending]: (state) => ({
      ...state,
    }),
    [asyncStates.success]: (state, { payload: { data: user } }) => {
      const userIndex = state.group.users.findIndex((u) => u.id === user.id);
      const users = [...state.group.users];
      users[userIndex] = user;

      return {
        ...state,
        group: {
          ...state.group,
          users,
        },
        alert: {
          type: 'success',
          message: `Status of user ${user.firstname} successfully changed to ${user.status}.`,
        },
      };
    },
    [asyncStates.failure]: (state) => ({
      ...state,
      alert: {
        type: 'danger',
        message:
          "Sorry, can't change status of selected user. Please try again.",
      },
    }),
  },
);

const alertReducer = (state, action) => {
  if (action.type !== actionTypes.CLOSE_ALERT) {
    return state;
  }

  return {
    ...state,
    alert: null,
  };
};

const initialState = getInitialState();

export default combineReducersFlat(
  [
    namespacedReducer(namespace)(groupsDataReducer),
    namespacedReducer(namespace)(groupDetailsReducer),
    namespacedReducer(namespace)(groupPostReducer),
    namespacedReducer(namespace)(groupPutUserReducer),
    namespacedReducer(namespace)(groupRemoveUserReducer),
    namespacedReducer(namespace)(usersDataReducer),
    namespacedReducer(namespace)(groupUserStatusReducer),
    addGroupFormReducer,
    addUserFormReducer,
    alertReducer,
    resetState,
  ],
  initialState,
);
