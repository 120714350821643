import transform from 'lodash/transform';
import get from 'lodash/get';
import set from 'lodash/set';
import omitBy from 'lodash/omitBy';
import moment from 'moment';
import compact from 'lodash/compact';
import last from 'lodash/last';

export const createStorage = () => ({
  save(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },

  read(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  remove(key) {
    localStorage.removeItem(key);
  },
});

export const createConstants = (...constants) =>
  constants.reduce((acc, constant) => {
    acc[constant] = constant;
    return acc;
  }, {});

export const createReducer =
  (initialState, reducerMap) =>
  (state = initialState, action) => {
    const reducer = reducerMap[action.type];

    return reducer ? reducer(state, action.payload) : state;
  };

export const checkHttpStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;

  throw error;
};

export const parseJSON = (response) => response.json();

export const composeComponents = (component, wrappers = []) =>
  wrappers.reduce((c, wrapper) => wrapper(c), component);

export const nullIfEmpty = (value) => (value === '' ? null : value);

export const isEmpty = (value) =>
  value === undefined || value === null || value === '';

export const mapObject = (mapping, input, defaults = {}) =>
  transform(
    mapping,
    (output, toProp, fromProp) => {
      const value = get(input, fromProp) || get(defaults, toProp);
      if (value) {
        output = set(output, toProp, value);
      }
    },
    {},
  );

export const compactObject = (obj) => omitBy(obj, isEmpty);

export const createEventListener =
  (func, ...args) =>
  (e) =>
    func(...args, e);

export const bindArgs =
  (func, ...args1) =>
  (...args2) =>
    func(...args1, ...args2);

/**
 * trims new-line characters from multiline string
 *
 * @param {String} str - string to trim
 * @param {Boolean} options.compact - if set new lines will not be replaced to one space but no space
 */
export const trim = (str, { compact = false } = {}) =>
  str.replace(/\n\s*/g, compact ? '' : ' ');

export const dedent = (str, { compact = false, blockMode = false } = {}) => {
  if (!blockMode) {
    return str.replace(/\n\s*/g, compact ? '' : ' ');
  }

  const lines = str.split('\n');

  // is first line only a new line then we strip it;
  if (lines[0].match(/^\s*$/)) {
    lines.shift();
  }

  // is the last line only a new line then we strip it;
  if (lines[lines.length - 1].match(/^\s*$/)) {
    lines.pop();
  }

  // get the indention of the first line and only strip away this amount of
  // white spaces
  const firstline = lines[0];
  const indentionLevel = firstline.match(/^(\s*)/)[0].length;

  // strip of the indention level from all lines and join it back with \n
  return lines
    .map((line) => {
      let indention = line.match(/^(\s*)/);
      indention = indention[0].slice(0, indentionLevel);
      return line.replace(indention, '');
    })
    .join('\n');
};

/* options for state entities */
export const entitySkipUpdate = (requestedId, currentId, state, lastFetch) =>
  state === 'loading' ||
  (state === 'loaded' &&
    currentId === requestedId &&
    moment.utc().diff(lastFetch) < 1000 * 60 * 5);

export const entityCollectionSkipUpdate = (state, lastFetch) =>
  state === 'loading' ||
  (state === 'loaded' && moment.utc().diff(lastFetch) < 1000 * 60 * 5);

export const sum = (values) => values.reduce((sum, val) => sum + val, 0);

export const delay =
  (timeout) =>
  (...args) =>
    new Promise((resolve) => setTimeout(() => resolve(...args), timeout));

export const objToUrlParams = (obj) =>
  Object.keys(obj).reduce((str, key) => {
    const operator = !str ? '?' : '&';

    if (obj[key]) {
      return `${str}${operator}${key}=${encodeURIComponent(obj[key])}`;
    }

    return str;
  }, '');

// Maps a number from one range to another.
export const scale = (value, start1, stop1, start2, stop2) =>
  start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));

export const formatReadingTime = (readingTimeSec) => {
  const minutes = Math.floor(readingTimeSec / 60);
  const seconds = readingTimeSec % 60;
  const formattedSeconds = seconds > 9 ? seconds : `0${seconds}`;
  const formattedMinutes = minutes > 9 ? minutes : `0${minutes}`;
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const formatPercentage = (value, precision = 2) =>
  Number((value * 100).toFixed(precision));

// promise helpers
export const chainPromises = (functions) => {
  const wrapPromiseCreator =
    (fn) =>
    (resList = []) => {
      const nextPromise = fn();
      // nothing returned
      if (!nextPromise) {
        return resList;
      }

      // if immediate result returned
      if (!nextPromise.then) {
        return [...resList, nextPromise];
      }

      return nextPromise.then((res) => [...resList, res]);
    };

  // add all functions to the promise chain but wrap them so we can access all
  // results later
  return functions.reduce(
    (chain, fn) => chain.then(wrapPromiseCreator(fn)),
    Promise.resolve(),
  );
};

export const takeLastValid = (list) => last(compact(list));

export const formatCount = (singular, plural, count) =>
  count > 1 ? `${count} ${plural}` : `${count} ${singular}`;

export const trace =
  (tag) =>
  (fn) =>
  (...args) => {
    // eslint-disable-next-line no-console
    console.log(`==> ${tag}: receive args`, ...args);

    const res = fn(...args);

    // eslint-disable-next-line no-console
    console.log(`==> ${tag}: returns`, res);

    return res;
  };

// currency

const currencySymbols = { EUR: '€', GBP: '£' };
export const getCurrencySymbolForString = (currency) =>
  currencySymbols[currency];
