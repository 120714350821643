import React from 'react';
import PropTypes from 'prop-types';
import { Manager, Popper, Target } from 'react-popper';
import outsideClick from 'react-click-outside';
import cx from 'classnames';
import noop from 'lodash/noop';

/**
 * Renders a Dropdown Menu
 *
 * @usage
 *     import Dropdown form "source/components/common/dropdownButton";
 *
 *     <Dropdown label="click me" onClick={}>
 *       <Dropdown.Item id="item-1">item 1</Dropdown.Item>
 *       <Dropdown.Item id="item-2">item 2</Dropdown.Item>
 *       ...
 *     </Dropdown>
 */
class Dropdown extends React.PureComponent {
  constructor(...args) {
    super(...args);

    this.state = {
      show: false,
    };
  }

  // eslint-disable-next-line
  handleClickOutside = () => {
    this.setState({ show: false });
  };

  handleMenuToggle = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({ show: !prevState.show }));
  };

  handleMenuClick = () => {
    this.setState({ show: false });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Escape' && this.state.show === true) {
      this.setState({ show: false });
    }
  };

  render() {
    const { show } = this.state;
    const {
      className,
      btnClassName,
      label,
      disabled,
      menuRight,
      onClick,
      children,
    } = this.props;

    // if a `onClick` prop is given it will be passed down to all top-level
    // children inside the `dropdown-menu`
    let items = children;
    if (onClick) {
      items = React.Children.map(children, (child) =>
        React.cloneElement(child, { onClick }),
      );
    }

    let MenuComponent;
    const MenuProps = {};

    if (show) {
      MenuComponent = Popper;
      MenuProps.placement = `bottom-${menuRight ? 'end' : 'start'}`;
      MenuProps.component = 'div';
    } else {
      MenuComponent = 'div';
    }

    return (
      <Manager
        tag="div"
        className={cx('dropdown', className, { show })}
        onKeyDown={this.handleKeyDown}
      >
        <Target
          component="button"
          className={cx('btn', 'dropdown-toggle', btnClassName)}
          disabled={disabled}
          data-toggle="dropdown"
          type="button"
          onClick={this.handleMenuToggle}
          aria-expanded={show}
        >
          {label}
        </Target>
        <MenuComponent
          className={cx('dropdown-menu', {
            // NOTE @alexspri
            //    don't add `dropdown-menu-right` as it will conflict with
            //    popper.js
            show,
          })}
          role="menu"
          aria-hidden={!show}
          onClick={this.handleMenuClick}
          {...MenuProps}
        >
          {items}
        </MenuComponent>
      </Manager>
    );
  }
}

Dropdown.propTypes = {
  className: PropTypes.string,
  btnClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  menuRight: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Dropdown.defaultProps = {
  className: '',
  btnClassName: 'btn-info',
  disabled: false,
  menuRight: false,
  onClick: null,
  children: null,
};

class DropdownItem extends React.PureComponent {
  handleClick = (e) => {
    e.preventDefault();
    this.props.onClick(this.props.id, e);
  };

  render() {
    const { className, children } = this.props;

    return (
      <div
        className={cx('dropdown-item', className)}
        onClick={this.handleClick}
      >
        {children}
      </div>
    );
  }
}

DropdownItem.propTypes = {
  id: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

DropdownItem.defaultProps = {
  id: null,
  className: '',
  children: null,
  onClick: noop,
};

Dropdown.Item = DropdownItem;

export default outsideClick(Dropdown);
