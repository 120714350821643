import shared from './shared';

const config = {
  api: {
    url: 'https://aurum.eqolot.com',
    timeout: 90000,
  },

  app: {
    url: 'https://cc.eqolot.com',
  },

  userApp: {
    url: 'https://app.eqolot.com',
  },

  sentry: {
    dsn: 'https://2157df1890014a45a9cf2ae244301845@o30840.ingest.sentry.io/86667',
  },

  allowedSSODomains: ['blogfoster.com', 'eqolot.com'],
  primaryDomain: 'eqolot.com',

  managers: shared.managers,
  allUsers: shared.allUsers,
  paymentApprovers: shared.accessGroups.paymentApprovers,

  insights: {
    instagram: {
      insightsTtl: 30,
      insightsIntermediateTtl: 23,
    },
    tiktok: {
      insightsTtl: 30,
      insightsIntermediateTtl: 23,
    },
  },

  features: {
    // a.k.a. God mode
    loginAsInfluencer: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        ...shared.accessGroups.marketing,
        ...shared.accessGroups.operations,
        ...shared.accessGroups.management,
        ...shared.accessGroups.accounting,
        'nicole.stoermann',
        'melanie.lucas',
        'caroline.albrecht',
        'maja.kuzmin',
        'mathias.avalos-thiel',
        'anja.schreiber',
        'isabelle.sonderyd',
        'jacqueline.krueger',
        'pascal.pfuetze',
        'hanna.schnitzlein',
        'jalda.rau',
        'niklas.schubert',
        'tramy.nguyen',
      ],
    },

    showCreateGroup: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        'nils.tornow',
      ],
    },

    pricingMultiplier: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        ...shared.accessGroups.management,
        ...shared.accessGroups.campaignManagers,
        'maja.kuzmin',
        'caroline.albrecht',
        'nils.tornow',
      ],
    },

    invoices: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        ...shared.accessGroups.management,
        ...shared.accessGroups.accounting,
      ],
    },

    payments: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        ...shared.accessGroups.management,
        ...shared.accessGroups.accounting,
        ...shared.accessGroups.operations,
        ...shared.accessGroups.paymentApprovers,
        'nicole.stoermann',
        'melanie.lucas',
        'caroline.albrecht',
        'maja.kuzmin',
      ],
    },

    campaignBonuses: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        ...shared.accessGroups.management,
        ...shared.accessGroups.campaignManagers,
        'juliane.dobberow',
        'nicole.stoermann',
        'melanie.lucas',
        'christina.tinter',
        'maja.kuzmin',
        'caroline.albrecht',
        'nils.tornow',
        'mathias.avalos-thiel',
        'jacqueline.krueger',
        'pascal.pfuetze',
        'hanna.schnitzlein',
        'jalda.rau',
        'niklas.schubert',
        'tramy.nguyen',
      ],
    },

    allowCrediting: {
      allowed: [
        ...shared.accessGroups.product,
        ...shared.accessGroups.developers,
        ...shared.accessGroups.campaignManagers,
        ...shared.accessGroups.management,
        'caroline.albrecht',
        'nils.tornow',
        'pascal.pfuetze',
      ],
    },

    campaignSelectPrivateNetwork: {
      allowed: [
        ...shared.accessGroups.developers,
        ...shared.accessGroups.product,
        ...shared.accessGroups.campaignManagers,
        'melanie.lucas',
        'christina.tinter',
        'maja.kuzmin',
        'caroline.albrecht',
        'nils.tornow',
        'jacqueline.krueger',
        'pascal.pfuetze',
        'hanna.schnitzlein',
        'jalda.rau',
        'niklas.schubert',
        'tramy.nguyen',
      ],
    },
  },
};

export default config;
