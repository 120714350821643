import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setPropTypes,
  branch,
  renderComponent,
  lifecycle,
} from 'recompose';

import ProgressBar from 'source/components/common/progressBar';
import TodoList from './todoList';

/* enhancers */
const withProgress = compose(
  setPropTypes({
    campaignAsync: PropTypes.shape({
      loaded: PropTypes.bool,
    }).isRequired,
  }),
  branch(
    ({ campaignAsync }) => !campaignAsync.loaded,
    renderComponent(ProgressBar.Mega),
  ),
);

const withTodosLoading = compose(
  setPropTypes({
    campaign: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    onLoadCampaign: PropTypes.func.isRequired,
  }),
  lifecycle({
    componentDidMount() {
      const {
        campaign: { id },
        onLoadCampaign,
      } = this.props;

      onLoadCampaign(id, { fields: ['todos'] });
    },
  }),
);

const enhance = compose(withTodosLoading, withProgress);

/**
 * Controller Component for the `Todos` View.
 */
function TodosViews({ campaign, ...props }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h2>Open Todos for the campaign</h2>
          <p className="text-muted">
            Check what are the open todos for this campaign.
          </p>
        </div>
      </div>

      <hr />

      <TodoList todos={campaign.todos} campaign={campaign} {...props} />
    </div>
  );
}

TodosViews.propTypes = {
  campaign: PropTypes.shape({
    todos: PropTypes.array.isRequired,
  }).isRequired,
};

export default enhance(TodosViews);
