import applicationActionTypes from './applications';
import missionActionTypes from './missions';
import todoActionTypes from './todos';
import rootActionTypes from './root';
import timelineActionTypes from './timeline';

const actionTypes = {
  ...applicationActionTypes,
  ...missionActionTypes,
  ...todoActionTypes,
  ...rootActionTypes,
  ...timelineActionTypes,
};

export default actionTypes;
