import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import ChannelStatus from 'source/components/common/channelStatus';

const reachMap = {
  youtube: 'subscriberCount',
  instagram: 'followedBy',
  website: 'pageviews',
  ga: 'pageviews',
  pinterest: 'followers',
  twitter: 'followersCount',
};
const getReach = (channel) => {
  const reachKey = reachMap[channel.platform];
  const reach = channel.insights[reachKey];
  return reach >= 0 ? reach : 'n/a';
};

function ChannelToolTip({ channel, priceFormatting, ...props }) {
  return (
    <ul className="channel-tooltip" {...props}>
      <li className="bold">{channel.name}</li>
      <li>
        <ChannelStatus ready={channel.ready} readyState={channel.readyState} />
      </li>
      <li>
        Reach: <span className="bold">{getReach(channel)}</span>
      </li>
      <li>
        Base price:{' '}
        <span className="bold">
          <FormattedNumber
            value={channel.pricePerPostV2}
            {...priceFormatting}
          />
        </span>
      </li>
    </ul>
  );
}
ChannelToolTip.propTypes = {
  channel: PropTypes.object.isRequired,
  priceFormatting: PropTypes.object.isRequired,
};

export default ChannelToolTip;
