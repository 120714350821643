import PropTypes from 'prop-types';

const reduxFormInput = PropTypes.shape({
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
  }).isRequired,
});

const platform = PropTypes.oneOf([
  'website',
  'ga',
  'youtube',
  'instagram',
  'pinterest',
  'facebook',
  'blog',
  'tiktok',
]);

const screenshotName = PropTypes.oneOf([
  'storiesImpressions',
  'postsImpressions',
  'countries',
  'ageAndGender',
]);

const screenshot = PropTypes.shape({
  url: PropTypes.string,
  createdAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  updatedAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
});

const screenshots = PropTypes.shape({
  storiesImpressions: screenshot,
  postsImpressions: screenshot,
  countries: screenshot,
  ageAndGender: screenshot,
  oldestUpdatedAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  createdAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  updatedAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
});

const mergedPropTypes = {
  ...PropTypes,
  reduxFormInput,
  platform,
  screenshotName,
  screenshot,
  screenshots,
};

export default mergedPropTypes;
