import { asyncActionReducer, asyncStates } from '@blogfoster/redux-async-utils';

import { namespacedReducer, combineReducersFlat } from 'source/utils/redux';
import { actionTypes as usersActionTypes } from 'source/data/users/actions';
import { namespace, actionTypes } from 'source/scenes/users/actions';
import { getPagingFromHeaders } from 'source/components/common/pagination';
import { findAndReplace, without } from 'source/utils/imCollection';

const getUserInitialState = () => ({
  groups: [],
  settings: {},
});

const getPagingInitialState = () => ({
  page: 0,
  pageSize: 30,
  count: 0,
  total: 0,
});

const getUserDetailsFormInitialState = () => ({
  email: '',
  errors: {},
  submitting: false,
  submitted: false,
  submitFailed: false,
  pristine: true,
});

const getInitialState = () => ({
  data: {
    user: getUserInitialState(),
    users: [],
  },
  forms: {
    userDetails: getUserDetailsFormInitialState(),
  },
  paging: getPagingInitialState(),
  filters: {
    term: '',
  },
  listState: '',
  detailsState: '',

  error: null,
});

const userDataReducer = asyncActionReducer(usersActionTypes.FETCH_ONE, {
  [asyncStates.pending]: (state) => ({
    ...state,
    detailsState: 'loading',
  }),
  [asyncStates.success]: (state, { payload: { data: user } }) => ({
    ...state,
    detailsState: 'loaded',
    data: {
      ...state.data,
      user,
    },
  }),
  [asyncStates.failure]: (state) => ({
    ...state,
    detailsState: 'failure',
  }),
});

const usersDataReducer = asyncActionReducer(usersActionTypes.FETCH_MANY, {
  [asyncStates.pending]: (state) => ({
    ...state,
    listState: 'loading',
  }),
  [asyncStates.success]: (state, { payload: { headers, data: users } }) => ({
    ...state,
    listState: 'loaded',
    data: {
      ...state.data,
      users,
    },
    paging: getPagingFromHeaders(headers),
  }),
  [asyncStates.failure]: (state) => ({
    ...state,
    listState: 'failure',
  }),
});

const userAccessTokenReducer = asyncActionReducer(
  usersActionTypes.FETCH_USER_ACCESS_TOKEN,
  {
    [asyncStates.success]: (state, { payload }) => {
      const {
        data: { accessToken, userId },
      } = payload;

      const user = state.data.users.find((user) => user.id === userId);
      const userWithToken = { ...user, accessToken, id: userId };
      const updatedUsers = findAndReplace(state.data.users, userWithToken);

      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            accessToken,
          },
          users: updatedUsers,
        },
      };
    },
  },
);

const userDetailsReducer = asyncActionReducer(usersActionTypes.PATCH_ONE, {
  [asyncStates.pending]: (state, { form }) => ({
    ...state,
    forms: {
      [form]: {
        ...state.forms[form],

        submitting: true,
        submitted: false,
        submitFailed: false,
      },
    },
  }),
  [asyncStates.success]: (state, { form, payload: { data } }) => ({
    ...state,
    data: {
      ...state.data,
      user: {
        ...state.data.user,
        email: data.email,
      },
    },
    forms: {
      ...state.forms,
      [form]: {
        ...state.forms[form],
        email: data.email,
        submitting: false,
        submitted: true,
        pristine: true,
        submitFailed: false,
      },
    },
  }),
  [asyncStates.failure]: (state, { form }) => ({
    ...state,
    forms: {
      [form]: {
        ...state.forms[form],
        submitting: false,
        submitted: false,
        submitFailed: true,
      },
    },
  }),
});

const termFilterReducer = (state = {}, action) => {
  if (action.type !== actionTypes.USERS_LIST_FILTER_CHANGES) {
    return state;
  }

  return {
    ...state,
    filters: {
      ...state.filters,
      ...action.payload,
    },
  };
};

const userFormsChangeReducer = (state = {}, action) => {
  if (action.type !== actionTypes.FORM_CHANGES) {
    return state;
  }
  const { form, field, value } = action.payload;
  const { forms, data } = state;

  return {
    ...state,
    forms: {
      ...forms,
      [form]: {
        ...forms[form],
        [field]: value,
        pristine: data.user[field] === value,
        submitted: false,
        errors: without(forms[form].errors, [field]),
      },
    },
  };
};

const userFormsErrorReducer = (state, action) => {
  if (action.type !== actionTypes.FORM_ERRORS) {
    return state;
  }
  const { form, errors } = action.payload;
  const { forms } = state;

  return {
    ...state,
    forms: {
      ...forms,
      [form]: {
        ...forms[form],
        errors,
      },
    },
  };
};

const resetStateReducer = (state = {}, action) => {
  if (action.type === actionTypes.RESET_LIST_SCENE) {
    return { ...getInitialState(), detailsState: state.detailsState };
  }
  if (action.type === actionTypes.RESET_DETAILS_SCENE) {
    return { ...getInitialState(), listState: state.listState };
  }

  return state;
};

const initialState = getInitialState();

export default combineReducersFlat(
  [
    namespacedReducer(namespace)(userDataReducer),
    namespacedReducer(namespace)(usersDataReducer),
    namespacedReducer(namespace)(userAccessTokenReducer),
    namespacedReducer(namespace)(userDetailsReducer),
    userFormsChangeReducer,
    userFormsErrorReducer,
    termFilterReducer,
    resetStateReducer,
  ],
  initialState,
);
