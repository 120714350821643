/**
 * Global-aware selectors for data
 */

import * as fromCampaigns from './campaigns/reducer';
import * as fromApplications from './applications/reducer';
import * as fromPayments from './payments/reducer';
import * as fromReports from './reports/reducer';

export const getApplicationsById = (state) =>
  fromApplications.getApplicationsById(state.data.applications);

export const getApplication = (state, props) =>
  fromApplications.getApplication(state.data.applications, props);

export const getCampaignsById = (state) =>
  fromCampaigns.getCampaignsById(state.data.campaigns);

export const getCampaign = (state, props) =>
  fromCampaigns.getCampaign(state.data.campaigns, props);

export const getPaymentsById = (state) =>
  fromPayments.getPaymentsById(state.data.payments);

export const getCampaignReportsById = (state) =>
  fromReports.getCampaignReportsById(state.data.reports);

export const getCampaignReport = (state, props) =>
  fromReports.getCampaignReport(state.data.reports, props);
