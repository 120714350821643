import React from 'react';
import PropTypes from 'prop-types';
import { withHandlers, setPropTypes, compose } from 'recompose';
import { reduxForm, SubmissionError } from 'redux-form';
import noop from 'lodash/noop';

const withSubmitHandler = compose(
  setPropTypes({
    applicationId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onUpdateApplication: PropTypes.func.isRequired,
  }),
  withHandlers({
    onSubmit:
      ({ onSubmit, onUpdateApplication, applicationId }) =>
      (values) => {
        // We support a side-effect hook when submitting this form
        onSubmit(values);
        onUpdateApplication(applicationId, values).then((result) => {
          if (result.statusCode >= 400) {
            throw new SubmissionError({
              _error: `Error sending user to mission: ${result.data}`,
            });
          }
        });
      },
  }),
);

function SendToMissionForm({
  className,
  form,
  error,
  disabled,
  submitting,
  handleSubmit,
  onSubmit,
}) {
  return (
    <form className={className} name={form} onSubmit={handleSubmit(onSubmit)}>
      <div className="row justify-content-end pr-3">
        <button type="submit" className="btn btn-primary" disabled={disabled}>
          {!submitting ? 'Send to mission' : 'Processing...'}
        </button>
      </div>
      {error ? <div className="text-danger">{error}</div> : null}
    </form>
  );
}

SendToMissionForm.propTypes = {
  className: PropTypes.string,
  form: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  // Allow outside onSubmit handler for side-effects
  onSubmit: PropTypes.func,
};

SendToMissionForm.defaultProps = {
  className: '',
  disabled: false,
  error: '',
  onSubmit: noop,
};

export default compose(
  reduxForm({
    form: 'applicationReview/applicationPanel/sendToMission',
    initialValues: {
      status: 'approved',
    },
  }),
  withSubmitHandler,
)(SendToMissionForm);
