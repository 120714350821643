import React from 'react';
import PropTypes from 'prop-types';

import YouTubeVideo from 'source/scenes/components/youTubeVideo';

function EventVideoBody({ content: { media } }) {
  return (
    <div>
      {media.map(({ url }) => (
        <div key={url} className="card-body message">
          <YouTubeVideo url={url} />
        </div>
      ))}
    </div>
  );
}

EventVideoBody.propTypes = {
  content: PropTypes.shape({
    media: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default EventVideoBody;
