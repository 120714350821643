export const listDataToFilters = (formData) => {
  const filters = [];

  if (formData.userId && formData.userId.length > 0) {
    filters.push({ 'receiver.id': Number(formData.userId) });
  }

  if (formData.approvedBy && formData.approvedBy.length > 0) {
    filters.push({ 'sender.id': { match: formData.approvedBy } });
  }

  return filters;
};

export const formDataToApplicationFilters = (formData) => {
  const filters = [];
  const {
    campaignId: { id: campaignId },
    userId: { id: userId },
  } = formData;

  if (campaignId) {
    filters.push({ userId: Number(userId) }, { campaignId });
  }

  return filters;
};
