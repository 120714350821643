const formDataToFilters = (formData) => {
  const filters = [];

  if (formData.period && formData.period.length > 0) {
    filters.push({ period: { match: formData.period } });
  }

  if (formData.userId && formData.userId.length > 0) {
    filters.push({ userId: Number(formData.userId) });
  }

  if (formData.invoiceId && formData.invoiceId.length > 0) {
    filters.push({ id: { match: formData.invoiceId } });
  }

  return filters;
};

export default formDataToFilters;
