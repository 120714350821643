import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setPropTypes,
  withHandlers,
  withProps,
  defaultProps,
} from 'recompose';
import { reduxForm, Field } from 'redux-form';
import get from 'lodash/get';

import { Toggle } from 'source/scenes/components/reduxFormAdapters';

import { withSubmissionValidation } from '../enhancers';

function AllowApplicationsForm(props) {
  return (
    <form onSubmit={props.handleSubmit}>
      <div>
        <div id="allowApplications" className="pb-2">
          <b>Allow Applications:</b>
        </div>
        <Field
          name="allowApplications"
          id="allowApplications"
          component={Toggle}
          onChange={props.onAllowApplicationsChange}
          disabled={props.disabled}
        />
      </div>
    </form>
  );
}

AllowApplicationsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onAllowApplicationsChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

AllowApplicationsForm.defaultProps = {
  disabled: false,
};

const withInitialValues = withProps((props) => ({
  initialValues: {
    allowApplications: get(props, 'campaign.allowApplications', false),
  },
}));

const withInputHandlers = withHandlers({
  // Submits the form on every change, see
  // https://github.com/erikras/redux-form/issues/537
  onAllowApplicationsChange: (props) => () => {
    setTimeout(props.handleSubmit, 0);
  },
});

const withOnSubmitHandler = withHandlers({
  onSubmit:
    ({ campaign, form, onUpdateCampaign }) =>
    (values) =>
      onUpdateCampaign(campaign.id, values, form),
});

const withDisabledOnUnpublishedCampaigns = withProps((props) => ({
  disabled: get(props, 'campaign.status') !== 'published',
}));

export default compose(
  defaultProps({ form: 'campaignDetail/setup/allowApplications' }),
  setPropTypes({
    campaign: PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      allowApplications: PropTypes.bool,
    }),
    form: PropTypes.string.isRequired,
    onUpdateCampaign: PropTypes.func.isRequired,
  }),
  withInitialValues,
  withOnSubmitHandler,
  withSubmissionValidation,
  reduxForm({ enableReinitialize: true }),
  withInputHandlers,
  withDisabledOnUnpublishedCampaigns,
)(AllowApplicationsForm);
