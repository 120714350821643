import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { DateInput, Input } from 'source/scenes/components/reduxFormAdapters';
import { toStartOfDay, parseToInt } from 'source/utils/reduxForm';

function InstagramStoryInsightsFields({ name, onCancel }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <Field
            name={`${name}.data.publishedAt`}
            label="Publication date"
            isOutsideRange={() => false}
            component={DateInput}
            parse={toStartOfDay}
          />
        </div>
        <div className="col">
          <Field
            name={`${name}.data.reportedAt`}
            label="Reporting date"
            isOutsideRange={() => false}
            component={DateInput}
            parse={toStartOfDay}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Field
            inputClassName="no-spin-buttons"
            name={`${name}.data.interactions`}
            label="Interactions"
            component={Input}
            type="number"
            parse={parseToInt}
          />
        </div>
        <div className="col">
          <Field
            inputClassName="no-spin-buttons"
            name={`${name}.data.swipeUps`}
            label="Website Clicks"
            component={Input}
            type="number"
            parse={parseToInt}
          />
        </div>
        <div className="col">
          <Field
            inputClassName="no-spin-buttons"
            name={`${name}.data.linkClicks`}
            label="Link Clicks"
            component={Input}
            type="number"
            parse={parseToInt}
          />
        </div>
        <div className="col">
          <Field
            inputClassName="no-spin-buttons"
            name={`${name}.data.stickerTaps`}
            label="Sticker Taps"
            component={Input}
            type="number"
            parse={parseToInt}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <Field
            inputClassName="no-spin-buttons"
            name={`${name}.data.reach`}
            label="Reach"
            component={Input}
            type="number"
            parse={parseToInt}
          />
        </div>
        <div className="col-3">
          <Field
            inputClassName="no-spin-buttons"
            name={`${name}.data.impressions`}
            label="Impressions"
            component={Input}
            type="number"
            parse={parseToInt}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <button type="submit" className="btn btn-primary">
            Save
          </button>
          <button onClick={onCancel} className="btn btn-outline-secondary ml-2">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

InstagramStoryInsightsFields.propTypes = {
  name: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default InstagramStoryInsightsFields;
