import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';

/* == form helpers == */

const normalizeForm = (form) => {
  const normalizedForm = Object.keys(form).reduce((memo, key) => {
    const value = form[key];

    if (typeof value === 'string') {
      memo[key] = value.trim();
    } else {
      memo[key] = value;
    }

    return memo;
  }, {});

  return normalizedForm;
};

// TODO: we might need to handle events on a checkbox differently
const handleFormChange =
  ({ id, onChange, context }) =>
  (e) => {
    let value;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    return onChange(id, e.target.id, value, context);
  };

const handleFormSubmit =
  (
    { id, form, onSubmit = noop, onErrors = noop, validateOptions, context },
    validate,
  ) =>
  (e) => {
    /* -- blank -- */
    e.preventDefault();
    e.stopPropagation();

    /**
     * The validate function can either return a plain object for synchronous
     * validation, which will be used as an errors object or return a promise
     * for asynchronous validation, where the rejected value will be used as
     * an errors object.
     */
    const handleValidateResult = (validateResult) => {
      // wait for the promise and resolve with onSubmit and reject with onErrors
      if (typeof validateResult.then === 'function') {
        return validateResult
          .then(() => onSubmit(id, form, context))
          .catch((errors) => onErrors(id, errors, context));
      }

      // for sync response check
      if (!isEmpty(validateResult)) {
        return onErrors(id, validateResult, context);
      }

      return onSubmit(id, form, context);
    };

    const normalizedForm = normalizeForm(form);
    const validateResult = validate(normalizedForm, validateOptions);

    return handleValidateResult(validateResult);
  };

/**
 * @param {function} options.validateForm
 * @param {function} options.contextFn
 */
const forms =
  ({ validateForm, contextFn = noop } = {}) =>
  (Component) => {
    if (!validateForm) {
      validateForm = () => ({});
    }

    class FormsComponent extends React.Component {
      handleChange = (e) => {
        const { id, formName, onChange } = this.props;

        return handleFormChange({
          id: id || formName,
          onChange,
          context: contextFn(this.props),
        })(e);
      };

      handleSubmit = (e) => {
        const { id, formName, form, onSubmit, onErrors } = this.props;
        const validateOptions = { props: this.props }; // passed to the validate function as second argument;

        const submitOptions = {
          id: id || formName,
          form,
          onSubmit,
          onErrors,
          validateOptions,
          context: contextFn(this.props),
        };

        return handleFormSubmit(submitOptions, validateForm)(e);
      };

      render() {
        return (
          <Component
            {...this.props}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
          />
        );
      }
    }

    FormsComponent.propTypes = {
      id: PropTypes.string,
      formName: PropTypes.string,
      form: PropTypes.object.isRequired,
      onChange: PropTypes.func.isRequired,
      onSubmit: PropTypes.func,
      onErrors: PropTypes.func,
    };

    FormsComponent.defaultProps = {
      id: null,
      formName: null,
      onSubmit: noop,
      onErrors: noop,
    };

    return FormsComponent;
  };

export default forms;
