import { createSelector, createStructuredSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import keyBy from 'lodash/keyBy';

import config from 'config';
import { objToUrlParams } from 'source/utils';
import { getPagination } from 'source/components/common/pagination';

import { payloadToQuery } from './utils';

const getAccessToken = (state) => state.account.accessToken;
const getChannelAsync = (state) => state.scenes.channelsAdvanced.channelAsync;
const getCategoriesAsync = (state) =>
  state.scenes.channelsAdvanced.categoriesAsync;
const getChannels = (state) => state.scenes.channelsAdvanced.channelAsync.data;
const getChannelSorting = (state) =>
  state.scenes.channelsAdvanced.channelSorting;
const getChannelPaging = (state) =>
  getPagination(state.scenes.channelsAdvanced.channelPaging);
const getCategories = (state) => ({
  categories: state.scenes.channelsAdvanced.categoriesAsync.data || [],
  categoriesByCode: keyBy(
    state.scenes.channelsAdvanced.categoriesAsync.data,
    'code',
  ),
});
const getAuthorizedUrl = (url) => (accessToken, filterValues) => {
  const urlParams = objToUrlParams({
    query: JSON.stringify(payloadToQuery(filterValues)),
    access_token: accessToken,
  });

  return `${url}${urlParams}`;
};
const getChannelFilterValues = (state) =>
  getFormValues('channel_search')(state);
const getChannelSearchReportUrl = createSelector(
  getAccessToken,
  getChannelFilterValues,
  getAuthorizedUrl(`${config.api.url}/v2/channels/search.xlsx`),
);

const selector = createStructuredSelector({
  token: getAccessToken,
  channelAsync: getChannelAsync,
  categoriesAsync: getCategoriesAsync,
  channels: getChannels,
  categories: getCategories,
  channelFilterValues: getChannelFilterValues,
  channelSearchReportUrl: getChannelSearchReportUrl,
  channelPaging: getChannelPaging,
  channelSorting: getChannelSorting,
});

export default selector;
