import React from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import { CampaignMissions } from '../components';
import {
  EventDefault,
  EventPayment,
  EventApplicationCreated,
  EventLink,
  EventTaskEdit,
} from '../components/sharedEvents';
import EventContentPreviewWebsite from '../website/eventContentPreviewWebsite';
import ChannelLinkWebsite from '../website/channelLinkWebsite';
import { withApplications, withLoadingGuard, withErrorGuard } from '../common';
import { campaignMissions } from '../selectors';
import actions from '../actions';

const withConfig = withProps({
  eventComponents: {
    application_created: EventApplicationCreated,
    application_content_preview_submitted: EventContentPreviewWebsite,
    application_content_publication_changed: EventLink,
    application_payment_changed: EventPayment,
    application_task_overrides_changed: EventTaskEdit,
    campaign_tasks_changed: EventTaskEdit,
    default: EventDefault,
  },
  getHeading: (application) => application.user.firstname,
  getSubheading: (application) => (
    <>
      ({application.channel.name}, reach:{' '}
      <FormattedNumber value={application.match.reach} />, bucket:{' '}
      {application.match.contingent.id})
    </>
  ),
  channelLinkComponent: ChannelLinkWebsite,
});

const mapStateToProps = campaignMissions;
const mapDispatchToProps = actions;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApplications(),
  withConfig,
  withLoadingGuard,
  withErrorGuard,
)(CampaignMissions);
