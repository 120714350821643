import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedDate, FormattedRelativeTime } from 'react-intl';
import moment from 'moment';

import Groups from 'source/scenes/components/groups';
import countries from 'source/i18n/countries';
import languages from 'source/i18n/languages';

const salutations = {
  F: 'f',
  M: 'm',
  D: 'd',
};

const boolToString = (value) => (value ? 'YES' : 'NO');

const salutationToString = (value) => salutations[value];

const yearToAge = (value) => {
  if (!value) {
    return '';
  }

  return `${new Date().getFullYear() - value} yo`;
};

export const findCountry = (code) => {
  if (!code) {
    return '';
  }
  const upperCode = code.toUpperCase();
  const country = countries.find((c) => c.code === upperCode) || {};

  return country.name;
};

const findLanguage = (code) => {
  if (!code) {
    return '';
  }
  const language = languages.find((c) => c.code === code) || {};

  return language.name;
};

export function UserProfileHeadline({
  user,
  highlightLanguage = false,
  boldName = false,
  className = '',
}) {
  return (
    <div className={className}>
      <span className="badge badge-secondary">
        {salutationToString(user.salutation)}
      </span>
      <span className={cx('ml-2', { bold: boldName })}>{user.firstname}</span>
      <span className="ml-2">{yearToAge(user.yearOfBirth)}</span>
      {', '}
      <span
        className={cx('ml-2', { 'badge badge-warning': highlightLanguage })}
      >
        {findLanguage(user.language)}
      </span>
    </div>
  );
}

UserProfileHeadline.propTypes = {
  user: PropTypes.shape({
    salutation: PropTypes.string,
    firstname: PropTypes.string.isRequired,
    yearOfBirth: PropTypes.number,
    language: PropTypes.string.isRequired,
  }),
  highlightLanguage: PropTypes.bool,
  boldName: PropTypes.bool,
  className: PropTypes.string,
};

function UserProfile({ user, container, showHeadline, className }) {
  const hasGroups = user?.groups?.length > 0;
  const hasPrivateNetworks = user?.privateNetworks?.length > 0;
  const hasGroupsOrPrivateNetworks = hasGroups || hasPrivateNetworks;

  const now = moment();
  const lastLogin = moment(user.lastLogin);
  const lastLoginDays = Math.round(
    moment.duration(now.diff(lastLogin)).asDays(),
  );

  return (
    <div className={cx('user-profile', className, { container })}>
      <div className="row">
        {showHeadline && (
          <div className="col d-flex align-items-center">
            <UserProfileHeadline className="pl-2" user={user} boldName />
          </div>
        )}
      </div>
      <div className="row mt-2">
        <div className="col-4">
          <div className="text-muted small">Joined</div>
          <div className="">
            <FormattedDate value={user.createdAt} />
          </div>
        </div>
        <div className="col-4">
          <div className="text-muted small">Last login</div>
          <div className="">
            <FormattedRelativeTime
              value={-lastLoginDays}
              unit="day"
              numeric="auto"
            />
          </div>
        </div>
        <div className="col-4">
          <div className="text-muted small">Account</div>
          <div className="">
            <Link to={`/users/${user.id}`}>{user.id}</Link>
          </div>
        </div>
      </div>

      <div className="row mt-1">
        {hasGroupsOrPrivateNetworks ? (
          <div className="col">
            <Groups
              groups={user.groups}
              privateNetworks={user.privateNetworks}
            />
          </div>
        ) : (
          <>
            <div className="col-3">
              <span className="text-muted small">Groups</span>
            </div>
            <div className="col-9 pl-0">None</div>
          </>
        )}
      </div>

      <div className={cx('row', { 'mt-1': !hasGroupsOrPrivateNetworks })}>
        <div className="col-3">
          <span className="text-muted small">Email</span>
        </div>
        <div className="col-9 pl-0">
          <div className="truncate">
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </div>
          <div className="settings small">
            <span className="mr-3">
              promo {boolToString(user.settings.promotionalEmails)}
            </span>
            <span>
              campaigns {boolToString(user.settings.recommendedCampaignEmails)}
            </span>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3">
          <span className="text-muted small">Phone</span>
        </div>
        <div className="col-9 pl-0">
          <a href={`tel:${user.phone}`}>{user.phone}</a>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3">
          <span className="text-muted small">Home</span>
        </div>
        <div className="col-9 pl-0">
          <div className="full-name">
            {user.firstname} {user.lastname}
          </div>
          <div className="address-line-1">
            {user.streetName} {user.streetNumber}
          </div>
          <div className="address-line-2">
            {user.postalCode} {user.city}, {findCountry(user.country)}
          </div>
        </div>
      </div>
    </div>
  );
}

UserProfile.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    salutation: PropTypes.string,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string,
    yearOfBirth: PropTypes.number,
    lastLogin: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    streetName: PropTypes.string.isRequired,
    streetNumber: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    settings: PropTypes.shape({
      promotionalEmails: PropTypes.bool.isRequired,
      recommendedCampaignEmails: PropTypes.bool.isRequired,
    }).isRequired,
    groups: PropTypes.arrayOf(PropTypes.object),
    privateNetworks: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  className: PropTypes.string,
  container: PropTypes.bool,
  showHeadline: PropTypes.bool,
};

UserProfile.defaultProps = {
  className: '',
  container: true,
  showHeadline: true,
};

export default UserProfile;
