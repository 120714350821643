import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import ExternalLink from 'source/scenes/components/externalLink';
import CopyButton from 'source/components/common/copyButton';

function UserDetails({ className, user, website, applicationId }) {
  return (
    <div className={cx(className)}>
      <div className="row">
        <label htmlFor="name" className="col-sm-3 col-form-label">
          Name:
        </label>
        <div className="col-sm-9">
          <p className="form-control-plaintext break-word">
            <Link to={`/users/${user.id}`}>{user.firstname}</Link>
          </p>
        </div>
      </div>
      <div className="row">
        <label htmlFor="email" className="col-sm-3 col-form-label">
          Mail:
        </label>
        <div className="col-sm-9">
          <p className="form-control-plaintext break-word">{user.email}</p>
        </div>
      </div>
      <div className="row">
        <label htmlFor="url" className="col-sm-3 col-form-label">
          URL:
        </label>
        <div className="col-sm-9">
          <p className="form-control-plaintext">
            <ExternalLink className="break-word" href={website.url}>
              {website.url}
            </ExternalLink>
          </p>
        </div>
      </div>
      <div className="row">
        <label
          htmlFor="appId"
          className="col-sm-3 col-form-label"
          title="application id"
        >
          App. Id:
        </label>
        <div className="col-sm-7">
          <p className="form-control-plaintext truncate" title={applicationId}>
            {applicationId}
          </p>
        </div>
        <div className="col-sm-2">
          <CopyButton value={applicationId} title={applicationId} />
        </div>
      </div>
    </div>
  );
}

UserDetails.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  website: PropTypes.shape({
    url: PropTypes.string.isRequired,
    pageviews: PropTypes.number.isRequired,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
};

UserDetails.defaultProps = {
  className: undefined,
};

export default UserDetails;
