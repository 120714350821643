import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';

import Pagination from 'source/components/common/pagination';

function GroupTableRow({ group }) {
  return (
    <tr>
      <td>
        <Link to={`/groups/${group.id}`}>{group.name}</Link>
      </td>
      <td align="right">
        <FormattedNumber value={group.users} />
      </td>
    </tr>
  );
}

GroupTableRow.propTypes = {
  group: PropTypes.object.isRequired,
};

function GroupsTable({ groups, pages, page, onSelectPage }) {
  return (
    <>
      <div className="table-responsive-md">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="w-100">Name</th>
              <th>Users</th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group) => (
              <GroupTableRow key={group.id} group={group} />
            ))}
          </tbody>
        </table>
      </div>
      <div className="row justify-content-center pt-3">
        <Pagination pages={pages} activePage={page} onSelect={onSelectPage} />
      </div>
    </>
  );
}

GroupsTable.propTypes = {
  groups: PropTypes.array,
  pages: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  onSelectPage: PropTypes.any.isRequired,
};

GroupsTable.defaultProps = {
  groups: [],
};

export default GroupsTable;
