import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
import { pure } from 'recompose';

function Icon({ name, title, className, onClick, ...props }) {
  return (
    <svg
      className={cx('icon', `icon-${name}`, className)}
      onClick={onClick}
      {...props}
    >
      {title && <title>{title}</title>}
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  title: undefined,
  className: '',
  onClick: noop,
};

export default pure(Icon);
