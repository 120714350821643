import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import Dropzone from 'react-dropzone';
import Octicon from 'react-octicon';
import noop from 'lodash/noop';

import { idType } from '../../utils/propTypes';
import { stopPropagation } from '../../utils/eventHandlers';

import { FormControlFeedback } from './forms';

export class FileUrl extends Component {
  handleChange = (e) => {
    const { id, onChange } = this.props;

    e.stopPropagation();

    return onChange(id, e.target.value);
  };

  handleCancel = (e) => {
    const { id, disabled, onChange } = this.props;

    e.stopPropagation();

    if (disabled) {
      return null;
    }

    return onChange(id, '');
  };

  render() {
    const { url, disabled, error } = this.props;

    return (
      <fieldset
        className={cx('form-group', { 'has-danger': error })}
        disabled={disabled}
      >
        <span className="text-muted">... or provide an image url.</span>
        <div className="input-group">
          <input
            id="body"
            value={url}
            className="form-control"
            placeholder="image url..."
            onChange={this.handleChange}
          />
          <span className="input-group-btn">
            <button
              className={cx('btn', {
                'btn-secondary': !error,
                'btn-outline-danger': error,
              })}
              type="button"
              onClick={this.handleCancel}
            >
              <Octicon name="x" />
            </button>
          </span>
        </div>
        <FormControlFeedback message={error} />
      </fieldset>
    );
  }
}

FileUrl.propTypes = {
  id: idType.isRequired,
  url: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

FileUrl.defaultProps = {
  url: '',
  disabled: false,
  onChange: noop,
};

export function FilePreview({ src }) {
  if (!src) {
    return null;
  }

  return (
    <div className="card">
      <img src={src} className="card-img-top" alt="Card cap" />
    </div>
  );
}

FilePreview.propTypes = {
  src: PropTypes.string,
};

FilePreview.defaultProps = {
  src: null,
};

export default class FileDrop extends Component {
  handleChange = (acceptedFiles) => {
    const { id, onChange } = this.props;

    if (!acceptedFiles || !acceptedFiles[0]) {
      return null;
    }

    return onChange(id, acceptedFiles[0]);
  };

  handleCancel = (e) => {
    const { id, onChange } = this.props;

    e.stopPropagation();

    return onChange(id, {});
  };

  render() {
    const { id, file, label, accept, disabled, error, placeholder, className } =
      this.props;

    const filename = file.name || placeholder;
    const inputGroupClassName = cx('input-group', {
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
      'is-invalid': error,
    });

    const cancelButtonClassName = cx('btn', {
      'btn-outline-secondary': !error,
      'btn-outline-danger': error,
    });

    return (
      /* NOTE we need stop bubbling up the `change` event when a file was dropped */
      <fieldset
        className={cx('form-group', className)}
        disabled={disabled}
        onChange={stopPropagation}
      >
        {label && (
          <label htmlFor={id} className="text-muted">
            {label}
          </label>
        )}
        <Dropzone
          id={id}
          disableClick={disabled}
          className="file-drop"
          onDrop={this.handleChange}
          multiple={false}
          accept={accept}
        >
          <div className={inputGroupClassName} disabled={disabled}>
            <span className={cx('form-control', { 'disabled-bg': disabled })}>
              {filename}
            </span>
            <div className="input-group-append">
              <span className="input-group-text upload-file-btn">Browse</span>
              <button
                className={cancelButtonClassName}
                type="button"
                onClick={this.handleCancel}
              >
                <Octicon name="x" />
              </button>
            </div>
          </div>
          <FormControlFeedback message={error} style={{ display: 'inherit' }} />
        </Dropzone>
      </fieldset>
    );
  }
}

FileDrop.propTypes = {
  id: idType.isRequired,
  file: PropTypes.object,
  label: PropTypes.string,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

FileDrop.defaultProps = {
  file: {},
  label: 'Upload a file ...',
  accept: null,
  disabled: false,
  error: null,
  placeholder: 'Choose or drop a File ...',
  className: '',
  onChange: noop,
};
