import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import MarkdownTextAreaTabs from 'source/components/common/markdownTextAreaTabs';

/**
 * Redux-form compatible wrapper for `MarkdownTextAreaTabs`.
 *
 * @example
 *
 * import MarkdownTextAreaTabs from 'reduxFormAdapters/MarkdownTextAreaTabs';
 *
 * <Field
 *   name="body"
 *   component={MarkdownTextAreaTabs}
 *   placeholder="write some markdown ..."
 * />
 */
function WrappedMarkdownTextarea({
  // redux-form props
  input,
  input: { name },
  meta: { touched, error, form },

  // custom props
  className,
  label,
  ...inputProps
}) {
  return (
    <div
      className={cx('form-group', className, {
        'has-danger': touched && error,
      })}
    >
      {label && <label htmlFor={`${form}-${name}`}>{label}</label>}
      <MarkdownTextAreaTabs
        {...input}
        id={`${form}-${name}`}
        {...inputProps}
        className="form-control h-8-lines"
      />
      {touched && error && (
        <small className="form-control-feedback">{error}</small>
      )}
    </div>
  );
}

WrappedMarkdownTextarea.propTypes = {
  // redux-form props
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    form: PropTypes.string.isRequired,
  }).isRequired,

  // custom props
  className: PropTypes.string,
  label: PropTypes.string,
};

WrappedMarkdownTextarea.defaultProps = {
  className: '',
  label: '',
};

export default WrappedMarkdownTextarea;
