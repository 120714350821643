import React, { Children } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function Tabs({ activeTabId, children, className }) {
  const filteredChildren = Children.toArray(children).filter(
    (child) => child.props.tabId === activeTabId,
  );

  const foundChild = filteredChildren[0];
  if (!foundChild) {
    return null;
  }

  if (!foundChild.props.tabId) {
    console.error('Tabs cannot have a child without a `tabId`');
    return null;
  }

  return React.cloneElement(foundChild, {
    className: cx(foundChild.props.className, className),
    key: foundChild.props.tabId,
  });
}

Tabs.propTypes = {
  activeTabId: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

Tabs.defaultProps = {
  activeTabId: '',
  children: '',
  className: '',
};

function Pane({ children, ...props }) {
  const child = Children.only(children);

  if (!child) {
    return null;
  }

  return React.cloneElement(child, {
    ...props,
    className: cx(child.props.className, props.className),
  });
}

Pane.propTypes = {
  tabId: PropTypes.string.isRequired, // NOTE: only necessary for the upper `Tabs` Component to render the active tab
  className: PropTypes.string,
  children: PropTypes.node,
};

Pane.defaultProps = {
  children: '',
};

Tabs.Pane = Pane;

export default Tabs;
