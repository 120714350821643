import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withHandlers, withStateHandlers, compose } from 'recompose';
import Octicon from 'react-octicon';

const separatorStyles = {
  width: '1px',
  backgroundColor: '#3c6f53',
  height: '20px',
  zIndex: '2',
};

const killEvent = (e) => {
  e.currentTarget.blur();
  e.preventDefault();
  e.stopPropagation();
};

const noopPromise = () => Promise.resolve();

const States = {
  initial: 'initial',
  confirmation: 'confirmation',
  waiting: 'waiting',
  done: 'done',
};

const withConfirmationState = withStateHandlers(
  { buttonState: States.initial },
  { setBtnState: () => (buttonState) => ({ buttonState }) },
);

const withActiveButtonState = withStateHandlers(
  { activeButton: 'default' },
  { setActiveButtonState: () => (value) => ({ activeButton: value }) },
);

const withStateMachine = withHandlers({
  handleButtonClick:
    ({
      menuItems,
      buttonState,
      activeButton,
      withWaitingState = false,
      withDoneState = true,
      setBtnState,
      onConfirm = noopPromise,
    }) =>
    (e) => {
      killEvent(e);
      const menuItem =
        activeButton === 'default'
          ? menuItems[0]
          : menuItems.find((item) => item.name === activeButton);

      if (menuItem.skipConfirmation) {
        menuItem.handler(e);
        return;
      }

      if (buttonState === States.initial) {
        setBtnState(States.confirmation);
      } else if (buttonState === States.confirmation) {
        let confirmation;

        if (activeButton !== 'default') {
          confirmation = menuItem.handler(e);
        } else {
          confirmation = onConfirm(e);
        }

        const nextState = withDoneState ? States.done : States.initial;
        if (withWaitingState) {
          setBtnState(States.waiting);
          confirmation.then(() => setBtnState(nextState));
        } else {
          setBtnState(nextState);
        }
      }
    },
});

const withMenuHandlers = withHandlers({
  handleMenuItemClick:
    ({ menuItems, setBtnState, setActiveButtonState, onMenuItemClick }) =>
    (e) => {
      killEvent(e);

      const name = e.currentTarget.textContent;
      const menuItem = menuItems.find((item) => name.includes(item.name));

      if (menuItem.skipConfirmation) {
        menuItem.handler(e);
      } else {
        setActiveButtonState(menuItem.name);
        setBtnState(States.confirmation);
      }

      onMenuItemClick();
    },
});

/**
 * Button which has a confirmation phase before the click handler is fired
 */
function ConfirmSplitButton({
  className = '',
  initialClassName = '',
  rootClassName = '',
  buttonState,
  isMenuOpen,
  onMenuClick,
  disabled,
  confirm = 'Confirm',
  confirmClassName = '',
  waiting,
  waitingClassName = '',
  done = 'Success',
  doneClassName = '',
  handleButtonClick,
  children,
  menuItems = [],
  handleMenuItemClick,
  menuOffsetX,
}) {
  return (
    <div
      className={cx('btn-group align-items-center', rootClassName, {
        show: isMenuOpen,
      })}
    >
      <button
        className={cx(
          'confirm-button',
          'truncate',
          'btn',
          className,
          {
            [States.initial]: cx('confirm-button--initial', initialClassName),
            [States.confirmation]: cx(
              'confirm-button--confirmation',
              confirmClassName,
            ),
            [States.waiting]: cx('confirm-button--waiting', waitingClassName),
            [States.done]: cx('confirm-button--done', doneClassName),
          }[buttonState],
        )}
        disabled={
          (disabled && buttonState !== States.confirmation) ||
          buttonState === States.waiting ||
          buttonState === States.done
        }
        onClick={handleButtonClick}
      >
        {
          {
            [States.initial]: children,
            [States.confirmation]: confirm,
            [States.waiting]: waiting || children,
            [States.done]: done,
          }[buttonState]
        }
      </button>
      <div style={separatorStyles} />
      <button
        className={cx('btn dropdown-toggle dropdown-toggle-split', className)}
        onClick={onMenuClick}
      />
      <div
        className={cx('dropdown-menu', {
          show: isMenuOpen,
        })}
        style={menuOffsetX ? { left: menuOffsetX } : undefined}
      >
        {menuItems.map(({ name, disabled, icon }) => (
          <div key={name} onClick={killEvent}>
            <span
              style={disabled || icon ? { paddingLeft: '.4rem' } : {}}
              key={name}
              className={cx('dropdown-item', { disabled })}
              onClick={handleMenuItemClick}
            >
              {disabled && !icon && (
                <Octicon name="check" className="split-btn-icon-check" />
              )}
              {icon}
              {name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

ConfirmSplitButton.propTypes = {
  /**
   * The CSS class of the button which is always applied, no matter what state
   * it's in.
   */
  className: PropTypes.string,
  /**
   * The CSS class of the button which is only applied for the initial state.
   */
  initialClassName: PropTypes.string,

  rootClassName: PropTypes.string,
  disabled: PropTypes.bool,
  /**
   * Rendered when the button is in the confirmation state
   */
  confirm: PropTypes.node,
  /**
   * The CSS class of the button which is only applied for the confirm state.
   */
  confirmClassName: PropTypes.string,
  /**
   * Whether to transition to a waiting state between the confirmation
   * state and the end state. Assumes onConfirm yields a Promise
   *
   * Defaults to `false`
   */
  // eslint-disable-next-line react/no-unused-prop-types
  withWaitingState: PropTypes.bool,
  /**
   * Rendered when the button is in a waiting state
   */
  waiting: PropTypes.node,
  /**
   * The CSS class of the button which is only applied for the wait state.
   */
  waitingClassName: PropTypes.string,
  /**
   * Whether to transition to a done state after confirmation. If false,
   * the button will loop back to its initial state
   *
   * Defaults to `true`
   */
  // eslint-disable-next-line react/no-unused-prop-types
  withDoneState: PropTypes.bool,
  /**
   * Rendered when the button is in a done state
   */
  done: PropTypes.node,
  /**
   * The CSS class of the button which is only applied for the done state.
   */
  doneClassName: PropTypes.string,
  /**
   * Called only for the confirmation click
   */
  // eslint-disable-next-line react/no-unused-prop-types
  onConfirm: PropTypes.func,
  /**
   * Rendered when the button is in its initial state
   */
  children: PropTypes.any,

  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      icon: PropTypes.node,
    }),
  ).isRequired,

  isMenuOpen: PropTypes.bool,
  onMenuClick: PropTypes.func.isRequired,
  menuOffsetX: PropTypes.number,

  // Internal
  /** @ignore */
  buttonState: PropTypes.string.isRequired,
  /** @ignore */
  handleButtonClick: PropTypes.func.isRequired,
  /** @ignore */
  handleMenuItemClick: PropTypes.func.isRequired,
};

ConfirmSplitButton.defaultProps = {
  isMenuOpen: false,
};

export default compose(
  withConfirmationState,
  withActiveButtonState,
  withMenuHandlers,
  withStateMachine,
)(ConfirmSplitButton);
