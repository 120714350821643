import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * Redux-form compatible wrapper for `input` elements.
 *
 * @example
 *
 * <Field
 *   name="firstname"
 *   component={Input}
 *   label="Firstname:"
 *   placeholder="Your first name"
 * />
 */
function Input({
  // redux-form props
  input,
  input: { name },
  meta: { touched, error, form },

  // custom props
  className,
  label,
  helpText,
  onClear,
  labelClassName,
  inputClassName,
  feedbackClassName,
  ...inputProps
}) {
  return (
    <div className={cx('form-group', className)}>
      {label && (
        <label htmlFor={`${form}-${name}`} className={labelClassName}>
          {label}
        </label>
      )}
      <input
        {...input}
        id={`${form}-${name}`}
        {...inputProps}
        className={cx('form-control', inputClassName, {
          'is-invalid': touched && error,
        })}
      />
      {helpText && <small className="form-text text-muted">{helpText}</small>}
      {touched && error && (
        <small className={cx('invalid-feedback', feedbackClassName)}>
          {error}
        </small>
      )}
    </div>
  );
}

Input.propTypes = {
  // redux-form props
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    form: PropTypes.string.isRequired,
  }).isRequired,

  // custom props
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  feedbackClassName: PropTypes.string,
  label: PropTypes.string,
  helpText: PropTypes.string,
  onClear: PropTypes.func,
};

Input.defaultProps = {
  className: '',
  labelClassName: '',
  inputClassName: '',
  feedbackClassName: '',
  label: '',
  helpText: null,
  onClear: null,
};

export default Input;
