import React from 'react';
import PropTypes from 'prop-types';

import {
  EventVideoBody,
  EventBody,
} from 'source/scenes/campaignDetail/missions/components/event';

function YouTubePreview({ content }) {
  return (
    <div className="youtube-preview-container">
      <EventVideoBody content={content} />
      <EventBody content={content.caption} />
    </div>
  );
}

YouTubePreview.propTypes = {
  content: PropTypes.object.isRequired,
};

export default YouTubePreview;
