import React from 'react';
import PropTypes from 'prop-types';

import Points from 'source/scenes/components/points';

function PointsInput({ input: { value, onChange }, ...props }) {
  return <Points {...props} points={value} onSetPoint={onChange} />;
}

PointsInput.propTypes = {
  maxPoints: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  // Internal
  /** @ignore */
  input: PropTypes.shape({
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default PointsInput;
