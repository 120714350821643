import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import Rating from 'react-rating';
import { FormattedTime } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import forms from 'source/utils/forms';
import Icon from 'source/components/common/icon';

const influencerRatingFormOptions = {
  contextFn: ({ application: { id } }) => ({ missionId: id }),
  validateForm: () => ({}),
};

const ratingLevels = {
  null: '',
  undefined: '',
  1: 'Poor',
  2: 'Unsatisfactory',
  3: 'Ok',
  4: 'Good',
  5: 'Great',
};

const ratingLabels = {
  visualCreativity: 'Visual Creativity',
  copyWriting: 'Copy Writing',
  punctuality: 'Punctuality',
  professionalism: 'Professionalism',
};

const ratingProperties = Object.keys(ratingLabels);

const RatingDisk = React.memo(({ currentFormValue, onClick }) => {
  const name = isNull(currentFormValue) ? 'disk-fill' : 'disk';

  return (
    <Icon name={name} className="small-icon ml-3 mr-2" onClick={onClick} />
  );
});

RatingDisk.propTypes = {
  currentFormValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func.isRequired,
};

RatingDisk.defaultProps = {
  currentFormValue: '',
};

function PlaceholderStar({ currentFormValue }) {
  if (currentFormValue === '') {
    return <Icon name="star" className="small-icon mr-2" />;
  }

  return <Icon name="star-placeholder" className="small-icon mr-2" />;
}

PlaceholderStar.propTypes = {
  currentFormValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PlaceholderStar.defaultProps = {
  currentFormValue: '',
};

const StarRating = React.memo(
  ({ currentFormValue, ratingProperty, onChange }) => {
    const [currentRatingValue, setCurrentRatingValue] = React.useState(null);

    const handleChange = (ratingValue) => {
      onChange({
        target: { id: ratingProperty, value: ratingValue },
      });
    };

    const handleHover = React.useCallback(
      (newRatingValue) => {
        if (newRatingValue !== currentRatingValue) {
          setCurrentRatingValue(newRatingValue);
        }
      },
      [currentRatingValue],
    );

    const onDiskClick = React.useCallback(() => {
      const newValue = isNull(currentFormValue) ? '' : null;

      onChange({
        target: { id: ratingProperty, value: newValue },
      });
    }, [currentFormValue, ratingProperty, onChange]);

    const ratingLabel = ratingLabels[ratingProperty];

    return (
      <div className="row text-nowrap align-items-center">
        <label htmlFor={ratingLabel} className="col-md-4 col-form-label">
          {ratingLabel}
        </label>
        <div className="ml-2">
          <span data-tip="Not Applicable" data-for="disk-tooltip">
            <RatingDisk
              currentFormValue={currentFormValue}
              onClick={onDiskClick}
            />
          </span>
          <ReactTooltip id="disk-tooltip" type="info" />

          <span data-tip data-for="rating-tooltip">
            <Rating
              initialRating={currentFormValue}
              readonly={isNull(currentFormValue)}
              placeholderRating={5}
              placeholderSymbol={
                <PlaceholderStar currentFormValue={currentFormValue} />
              }
              emptySymbol={<Icon name="star" className="small-icon mr-2" />}
              fullSymbol={<Icon name="star-fill" className="small-icon mr-2" />}
              onChange={handleChange}
              onHover={handleHover}
            />
          </span>
          <ReactTooltip
            id="rating-tooltip"
            type="info"
            getContent={() => ratingLevels[currentRatingValue]}
          />
        </div>
      </div>
    );
  },
);

StarRating.propTypes = {
  currentFormValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ratingProperty: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

StarRating.defaultProps = {
  currentFormValue: '',
};

class InfluencerRating extends Component {
  renderTimestamp() {
    const {
      form: { updatedAt, updatedBy },
    } = this.props;

    const formattedDate = moment.utc(updatedAt).format('ddd DD/MM/YYYY');

    return (
      <div className="mt-3">
        {updatedAt && updatedBy ? (
          <p>
            Rated by {updatedBy} on {formattedDate} at{' '}
            <FormattedTime value={updatedAt} />
          </p>
        ) : (
          <p>Not rated yet!</p>
        )}
      </div>
    );
  }

  renderRateButton() {
    const {
      form: { state = 'pristine', errors },
    } = this.props;

    const buttonClassName = cx('btn', {
      'btn-primary': state === 'pristine' || state === 'dirty',
      'btn-outline-warning': state === 'submitting',
      'btn-outline-success': state === 'submitted',
    });

    const {
      form: { visualCreativity, copyWriting, punctuality, professionalism },
    } = this.props;

    const disabled =
      !isEmpty(errors) || // if there are errors
      state === 'submitting' || // while submission
      state === 'pristine' || // if there are no changes
      state === 'submitted' ||
      visualCreativity === undefined ||
      visualCreativity === '' ||
      copyWriting === undefined ||
      copyWriting === '' ||
      punctuality === undefined ||
      punctuality === '' ||
      professionalism === undefined ||
      professionalism === '';

    return (
      <div className="row justify-content-end pr-3 pt-1 pb-3">
        <button type="submit" className={buttonClassName} disabled={disabled}>
          Rate influencer
        </button>
      </div>
    );
  }

  render() {
    const { onSubmit, form, onChange, className } = this.props;

    return (
      <div className={cx('mt-2', className)}>
        <form onSubmit={onSubmit}>
          {this.props.showHeadline && <h2 className="mt-4">Rate Influencer</h2>}
          {ratingProperties.map((ratingProperty) => (
            <StarRating
              key={ratingProperty}
              currentFormValue={form[ratingProperty]}
              ratingProperty={ratingProperty}
              onChange={onChange}
            />
          ))}
          {this.renderTimestamp()}
          {this.renderRateButton()}
        </form>
      </div>
    );
  }
}

InfluencerRating.propTypes = {
  form: PropTypes.shape({
    visualCreativity: PropTypes.number,
    copyWriting: PropTypes.number,
    punctuality: PropTypes.number,
    professionalism: PropTypes.number,
    errors: PropTypes.object,
    state: PropTypes.oneOf(['pristine', 'dirty', 'submitting', 'submitted']),
    updatedAt: PropTypes.string,
    updatedBy: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  showHeadline: PropTypes.bool,
  className: PropTypes.string,
};
InfluencerRating.defaultProps = {
  showHeadline: true,
  className: '',
};

export default forms(influencerRatingFormOptions)(InfluencerRating);
