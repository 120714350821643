import React from 'react';
import PropTypes from 'prop-types';

function CountriesSelect({ input, options = [], className }) {
  return options.length > 1 ? (
    <select {...input} className={className}>
      {options.map(({ country }) => (
        <option key={country} value={country}>
          {country}
        </option>
      ))}
    </select>
  ) : (
    <span>{input.value}</span>
  );
}

CountriesSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.string.isRequired,
    }),
  ).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

export default CountriesSelect;
