import React from 'react';
import PropTypes from 'prop-types';

import Markdown from 'source/components/common/markdown';

function EventBody({ content }) {
  if (!content) {
    return null;
  }

  return (
    <div className="card-body message">
      <Markdown markdown={content} />
    </div>
  );
}

EventBody.propTypes = {
  content: PropTypes.string,
};

EventBody.defaultProps = {
  content: null,
};

export default EventBody;
