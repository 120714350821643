import React from 'react';
import PropTypes from 'prop-types';
import { defaultProps } from 'recompose';
import { ClipLoader } from 'react-spinners';
import cx from 'classnames';

function ProgressBar({ color, mega, className, style, size }) {
  // - add `className` / `style` to circle directly if it's not mega
  // - for mega we add those props to the container
  const circleClass = cx('progressbar-container', { [className]: !mega });
  const circleStyle = mega ? undefined : style;

  const innerCircle = (
    <div className={circleClass} style={circleStyle}>
      <ClipLoader color={color} loading size={size} sizeUnit="px" />
    </div>
  );

  // if this is not a "mega" progress it, we render where it is
  if (!mega) {
    return innerCircle;
  }

  // otherwise we wrap it into a flexbox
  return (
    <div className={cx('flex-center', 'w-100', className)} style={style}>
      <div className="flex-center-item">{innerCircle}</div>
    </div>
  );
}

ProgressBar.propTypes = {
  color: PropTypes.string,
  mega: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number,
};

ProgressBar.defaultProps = {
  color: '#24506B',
  mega: false,
  className: undefined,
  style: undefined,
  size: 100,
};

const MegaPropgressBar = defaultProps({ mega: true })(ProgressBar);
ProgressBar.Mega = MegaPropgressBar;

export default ProgressBar;
