import React from 'react';
import PropTypes from 'prop-types';

import Notes from 'source/scenes/campaignDetail/applications/components/common/profile/notes';

const ApplicationInfo = React.memo(
  ({
    application: { id },
    form: { kamNotes = {}, clientNotes = {} },
    id: formId,
    onChange,
    onSubmitKamNote,
  }) => {
    const onKamNotesChange = (e) =>
      onChange(
        formId,
        'kamNotes',
        {
          note: e.target.value,
        },
        { missionId: id },
      );

    const onKamNotesSubmit = () =>
      onSubmitKamNote(
        formId,
        {
          kamNotes: {
            note: kamNotes.note,
          },
        },
        { missionId: id },
      );

    return (
      <>
        {/* KAM Notes */}
        <Notes
          className="mt-2 mb-2"
          applicationId={id}
          label="KAM Note for Client"
          value={kamNotes?.note}
          updatedAt={kamNotes?.updatedAt}
          updatedBy={kamNotes?.updatedBy}
          onChange={onKamNotesChange}
          onSave={onKamNotesSubmit}
          buttonsPosition="bottom"
        />
        {/* Client Notes */}
        <Notes
          className="mt-2 mb-2"
          applicationId={id}
          label="Client Note for KAM"
          value={clientNotes?.note}
          updatedAt={clientNotes?.updatedAt}
          updatedBy={clientNotes?.updatedBy}
          buttonsPosition="bottom"
          editable={false}
        />
      </>
    );
  },
);

ApplicationInfo.propTypes = {
  id: PropTypes.string.isRequired,
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    kamNotes: PropTypes.shape({
      note: PropTypes.string,
      updatedBy: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
    clientNotes: PropTypes.shape({
      note: PropTypes.string,
      updatedBy: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
    channel: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  form: PropTypes.shape({
    kamNotes: PropTypes.shape({
      note: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.string,
    }),
    clientNotes: PropTypes.shape({
      note: PropTypes.string,
      updatedBy: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  }).isRequired,

  onChange: PropTypes.func.isRequired,
  onSubmitKamNote: PropTypes.func.isRequired,
};

export default ApplicationInfo;
