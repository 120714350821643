import React from 'react';
import PropTypes from 'prop-types';
import { Fields, reduxForm } from 'redux-form';
import moment from 'moment';
import cx from 'classnames';

import { DateRangePicker } from 'source/scenes/components/reduxFormAdapters';

const renderFields = (props) => (
  <DateRangePicker
    // pass on redux-form { input, meta } objects
    startDate={props.startDate}
    endDate={props.endDate}
    // props to pass on to the DateRangePicker component
    dateRangePickerProps={{
      // set the first day of the week to Monday
      firstDayOfWeek: 1,
      isOutsideRange: props.isOutsideRange,
      showDefaultInputIcon: true,
      numberOfMonths: 3,
    }}
  />
);

renderFields.propTypes = {
  startDate: PropTypes.shape({
    input: PropTypes.shape({
      // moment date object
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      onChange: PropTypes.func,
    }).isRequired,
  }).isRequired,
  endDate: PropTypes.shape({
    input: PropTypes.shape({
      // moment date object or empty string if the date is not set
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      onChange: PropTypes.func,
    }).isRequired,
  }).isRequired,
  isOutsideRange: PropTypes.func.isRequired,
};

class ReportingDateRangeForm extends React.Component {
  // make sure the first selectable date is the reporting start date
  isOutsideRange = (day) =>
    moment(day.format('YYYY-MM-DD')).isBefore(
      this.props.initialValues.startDate.format('YYYY-MM-DD'),
    );

  render() {
    const { submitDisabled, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Fields
          names={['startDate', 'endDate']}
          component={renderFields}
          isOutsideRange={this.isOutsideRange}
        />
        <button
          type="submit"
          className={cx('btn', 'ml-2', {
            'btn-primary': !submitDisabled,
            'btn-warning': submitDisabled,
          })}
          disabled={submitDisabled}
        >
          {submitDisabled ? 'Loading' : 'Load reporting data'}
        </button>
      </form>
    );
  }
}

ReportingDateRangeForm.propTypes = {
  initialValues: PropTypes.shape({
    // moment date objects
    startDate: PropTypes.object,
    endDate: PropTypes.object,
  }).isRequired,
  submitDisabled: PropTypes.bool,
  // injected by redux-form
  handleSubmit: PropTypes.func.isRequired,
};

ReportingDateRangeForm.defaultProps = {
  submitDisabled: false,
};

export default reduxForm()(ReportingDateRangeForm);
