import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { InputText, InputColor } from '../../../components/common/inputs';

class AddGroupForm extends React.PureComponent {
  handleChange = (id, value) => {
    const { form, onChange } = this.props;

    return onChange(form, id, value);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleSelectChange = (field, { id = '' }) => {
    const { form, onChange } = this.props;

    return onChange(form, field, { value: id });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form, onSubmit } = this.props;

    return onSubmit(form);
  };

  handleCancel = () => {
    const { onCancel } = this.props;

    return onCancel();
  };

  render() {
    const { form } = this.props;
    const { errors, name, color, submitting } = form;

    return (
      <form>
        <InputText
          name="Group name"
          id="name"
          value={name.value}
          error={errors.name}
          placeholder="Group name"
          onChange={this.handleChange}
        />

        <InputColor
          name="Color"
          id="color"
          value={color.value}
          error={errors.color}
          onChange={this.handleChange}
        />

        <div className="row justify-content-end pr-3">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.handleSubmit}
            disabled={!isEmpty(errors) || submitting}
          >
            Create New Group
          </button>
          <button
            type="button"
            className="btn btn-outline-danger ml-1"
            onClick={this.handleCancel}
            disabled={submitting}
          >
            Back to Groups
          </button>
        </div>
      </form>
    );
  }
}

AddGroupForm.propTypes = {
  form: PropTypes.shape({
    name: PropTypes.object.isRequired,
    color: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddGroupForm;
