import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import get from 'lodash/get';
import { reduxForm, Field } from 'redux-form';

import Alert from 'source/components/common/alert';
import { Toggle } from 'source/scenes/components/reduxFormAdapters';
import MarkdownTextarea from 'source/scenes/components/reduxFormAdapters/markdownTextarea';
import Checkbox from 'source/scenes/components/reduxFormAdapters/checkbox';
import selector from './selectors';
import { withSubmissionHandler } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';
import ProductsListField from './components/productsListField';
import validate from './validation';

const enhance = compose(
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
  reduxForm({
    validate,
    touchOnChange: true,
    touchOnBlur: false,
    enableReinitialize: true,
  }),
);

function ProductShipmentForm({
  submitting,
  handleSubmit,
  pristine,
  invalid,
  error,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Alert message={get(error, 'message')} />
      <div className="d-flex align-items-center">
        <Field id="enabled" name="enabled" component={Toggle} />
        <div className="ml-2 mb-2">
          <span>Product will be shipped to each influencer</span>
        </div>
      </div>

      <div className="mt-2">
        <Field
          id="description"
          name="description"
          component={MarkdownTextarea}
          placeholder="write some markdown ..."
        />
      </div>

      <div>
        <Field
          id="productChoices"
          name="productChoices"
          component={ProductsListField}
        />
      </div>

      <div>
        <Field
          id="allowOtherChoice"
          name="allowOtherChoice"
          component={Checkbox}
          label="Other (free text option)"
        />
      </div>

      <div className="row justify-content-end pr-3 pt-3">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={pristine || invalid || submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
}

ProductShipmentForm.propTypes = {
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

ProductShipmentForm.defaultProps = {
  invalid: false,
  submitting: false,
  error: undefined,
};

export default enhance(ProductShipmentForm);
