import { combineReducers } from 'redux';

import campaignDetail from './campaignDetail/reducer';
import campaigns from './campaigns/reducer';
import invoices from './invoices/reducer';
import payments from './payments/reducer';
import groups from './groups/reducer';
import users from './users/reducer';
import channels from './channels/reducer';
import channelsAdvanced from './channelsAdvanced/reducer';

export default combineReducers({
  campaignDetail,
  campaigns,
  invoices,
  payments,
  groups,
  users,
  channels,
  channelsAdvanced,
});
