export const getMissionRoute = ({ campaign, application }) => {
  let type;

  if (campaign.type === 'website' && campaign.pricing === 'budget') {
    type = 'website-budget';
  } else if (campaign.type === 'website' && campaign.pricing === 'bucket') {
    type = 'website-bucket';
  } else {
    type = campaign.type;
  }

  return `/campaigns/${type}/${campaign.id}/missions/${application.id}`;
};

export const getReviewSubHeading = ({ channel, match }) =>
  `${channel.name} reach: ${match.reach}`;
