import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  ToolTipController,
  Select as ToolTipWrapper,
} from 'react-tooltip-controller';

function LabeledMetric({ aligning, value, label, toolTip, className }) {
  let labelComponent = <div className="labeled-metric__label">{label}</div>;

  if (toolTip) {
    labelComponent = (
      <ToolTipController detect="hover" offsetY={6}>
        <ToolTipWrapper>{labelComponent}</ToolTipWrapper>
        <span>{toolTip}</span>
      </ToolTipController>
    );
  }

  return (
    <span className={cx('labeled-metric d-inline-block', className)}>
      <div
        className={cx('labeled-metric__metric truncate', {
          'align-right': aligning === 'right',
        })}
      >
        {value}
      </div>
      {labelComponent}
    </span>
  );
}

LabeledMetric.propTypes = {
  value: PropTypes.node,
  label: PropTypes.node,
  toolTip: PropTypes.string,
  className: PropTypes.string,
  aligning: PropTypes.string,
};

LabeledMetric.defaultProps = {
  value: '',
  label: '',
  className: '',
};

export default LabeledMetric;
