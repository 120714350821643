import { createConstants } from 'source/utils';

const actionTypes = createConstants(
  // click on the reaction navigation
  'CAMPAIGNS_MISSIONS_REACTION_NAVIGATION_CLICKED',
  // list form (applies to all missions) changes
  'CAMPAIGNS_MISSIONS_LIST_FORM_CHANGE',
  // mission specific form changes
  'CAMPAIGNS_MISSIONS_FORM_CHANGE',
  'CAMPAIGNS_MISSIONS_FORM_ERRORS',
  // mission form submit
  'CAMPAIGNS_MISSIONS_FORM_SUBMITTING',
  'CAMPAIGNS_MISSIONS_FORM_SUBMITTED',
  // application submit
  'CAMPAIGNS_MISSIONS_APPLICATION_SUBMITTED',
  // mission status submit
  'CAMPAIGNS_MISSIONS_STATUS_SUBMITTING',
  'CAMPAIGNS_MISSIONS_STATUS_SUBMITTED',
  // loading conversation data for one panel
  'CAMPAIGNS_MISSIONS_ACTIONS_LOADING',
  'CAMPAIGNS_MISSIONS_ACTIONS_LOADED',
  // keep track of expanded panels
  'CAMPAIGN_MISSIONS_EXPAND_MISSION',
);

export default actionTypes;
