import { asyncActionCreator } from '@blogfoster/redux-async-utils';

import { actionAuthRequest } from 'source/utils/axios';

export const actionTypes = {
  FETCH_MANY: 'data/invoices/FETCH_MANY',
};

export const invoicesFetch = (name) =>
  asyncActionCreator(
    actionTypes.FETCH_MANY,
    ({ page = 0, fields = [], filters = [] }) =>
      (dispatch, getState) => {
        const params = { page: page + 1 };

        if (filters && filters.length > 0) {
          params.filters = JSON.stringify(filters);
        }

        if (fields && fields.length > 0) {
          params.fields = JSON.stringify(fields);
        }

        return (
          actionAuthRequest(dispatch, getState, { dispatchErrors: false })
            .get('/v2/invoices', { params })
            // HACKY: to save the page correctly we inject a page header to the response
            .then((resp) => {
              resp.headers['x-page'] = page;
              return resp;
            })
        );
      },
    { name },
  );
