import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * Redux-form compatible wrapper for `select` elements.
 *
 * @example
 *
 * <Field
 *   name="fruit"
 *   component={Select}
 *   label="Your favourite fruit:"
 * >
 *   <option value="apples">Apples</option>
 *   <option value="oranges">Oranges</option>
 * </Field>
 */
function Select({
  // redux-form props
  input,
  input: { name },
  meta: { touched, error, form },

  // custom props
  label,
  className,
  children,
  ...inputProps
}) {
  return (
    <div className={cx('form-group', className)}>
      {label && <label htmlFor={`${form}-${name}`}>{label}</label>}
      <select
        {...input}
        id={`${form}-${name}`}
        {...inputProps}
        className={cx('form-control custom-select', {
          'is-invalid': touched && error,
        })}
      >
        {children}
      </select>
      {touched && error && <small className="invalid-feedback">{error}</small>}
    </div>
  );
}

Select.propTypes = {
  // redux-form props
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    form: PropTypes.string.isRequired,
  }).isRequired,

  // custom props
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
};

Select.defaultProps = {
  className: '',
  label: null,
  children: null,
};

export default Select;
