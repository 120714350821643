import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const oneSecond = 1000;

const killEvent = (e) => {
  e.currentTarget.blur();
  e.preventDefault();
  e.stopPropagation();
};

const states = {
  initial: 'initial',
  confirm: 'confirm',
  warning: 'warning',
  danger: 'danger',
  done: 'done',
};

function WarningButton({
  className,
  confirm,
  warning,
  danger,
  done,
  disabled,
  withConfirm,
  withDone,
  children,
  error,
  timeout,
  onClick,
  onForceClick,
  initialClassName,
  warningClassName,
  confirmClassName,
  dangerClassName,
  doneClassName,
}) {
  const [buttonState, setButtonState] = React.useState(states.initial);
  const [counter, setCounter] = React.useState(timeout);
  const [isTimeout, setIsTimeout] = React.useState();
  const [isAborted, setIsAborted] = React.useState();
  const timeoutRef = React.useRef();
  const intervalRef = React.useRef();

  const handleOnClick = (e) => {
    killEvent(e);

    if (buttonState === states.initial && withConfirm) {
      setButtonState(states.confirm);
    } else {
      const nextState = withDone ? states.done : states.initial;
      const buttonClick =
        buttonState === states.danger ? onForceClick() : onClick();

      buttonClick.then((error) => {
        if (!error) {
          setButtonState(nextState);
        } else if (error.abort) {
          setIsAborted(true);
          setButtonState(states.initial);
          clearTimeout(timeoutRef.current);
          clearInterval(intervalRef.current);
        }
      });
    }
  };

  React.useEffect(() => {
    if (isAborted) {
      return;
    }
    const expectedStates = withConfirm
      ? [states.initial, states.warning]
      : [states.warning];

    if (error && !expectedStates.includes(buttonState)) {
      const expectedState = withConfirm ? states.confirm : states.initial;

      if (buttonState === expectedState) {
        setButtonState(states.warning);
        setIsTimeout(true);
      }
    } else if (buttonState === states.danger) {
      setButtonState(states.initial);
      setCounter(timeout);
    }
  }, [error, buttonState, setButtonState, states, isAborted]);

  React.useEffect(() => {
    if (isTimeout) {
      timeoutRef.current = setTimeout(() => {
        setButtonState(states.danger);
        setIsTimeout(false);
      }, timeout * oneSecond);

      intervalRef.current = setInterval(() => {
        setCounter((counter) => counter - 1);
      }, oneSecond);
    }

    return () => {
      clearTimeout(timeoutRef.current);
      clearInterval(intervalRef.current);
    };
  }, [
    isTimeout,
    setButtonState,
    setCounter,
    timeoutRef,
    intervalRef,
    clearTimeout,
    clearInterval,
  ]);

  return (
    <button
      className={cx(
        'btn',
        className,
        { [initialClassName]: buttonState === states.initial },
        { [confirmClassName]: buttonState === states.confirm },
        { [warningClassName]: buttonState === states.warning },
        { [dangerClassName]: buttonState === states.danger },
        { [doneClassName]: buttonState === states.done },
      )}
      onClick={handleOnClick}
      disabled={disabled || buttonState === states.warning}
    >
      {buttonState === states.initial && children}
      {buttonState === states.confirm && confirm}
      {buttonState === states.warning && `${warning} (${counter || ''})`}
      {buttonState === states.danger && danger}
      {buttonState === states.done && done}
    </button>
  );
}

WarningButton.propTypes = {
  timeout: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  onForceClick: PropTypes.func.isRequired,
  withConfirm: PropTypes.bool,
  withDone: PropTypes.bool,
  error: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  confirm: PropTypes.node,
  warning: PropTypes.node,
  danger: PropTypes.node,
  done: PropTypes.node,
  initialClassName: PropTypes.string,
  confirmClassName: PropTypes.string,
  warningClassName: PropTypes.string,
  dangerClassName: PropTypes.string,
  doneClassName: PropTypes.string,
};

WarningButton.defaultProps = {
  timeout: 4, // in seconds
  confirm: 'Confirm',
  warning: 'Warning',
  danger: 'Danger',
  done: 'Success',
  disabled: false,
  withConfirm: false,
  withDone: false,
  className: '',
  initialClassName: 'btn-success',
  confirmClassName: 'btn-warning',
  warningClassName: 'btn-warning',
  dangerClassName: 'btn-danger',
  doneClassName: 'btn-success',
};

export default WarningButton;
