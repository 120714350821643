import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import CategoriesSelectList from './categoriesSelectList';

const toNumber = (value) =>
  isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);

function CategoriesFilter({ fields, categories }) {
  return (
    <div>
      <CategoriesSelectList
        categories={categories}
        addCategories={(categories) =>
          categories.forEach((category) => {
            fields.push({ ...category, weight: 1 });
          })
        }
      />
      {fields.map((member, index) => (
        <div key={member}>
          <div className="category-chip p-2 mt-2 d-flex rounded">
            <span className="category-weight m-2 pr-2 align-self-center text-muted">
              <button
                type="button"
                className="close category-filter-delete"
                onClick={() => fields.remove(index)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </span>
            <span className="category-name-block d-flex flex-column">
              <span className="category-name">{fields.get(index).name}</span>
              <span className="category-code text-muted font-italic">
                {fields.get(index).code}
              </span>
            </span>
            <Field
              className="form-control category-filter-weight"
              name={`${member}.weight`}
              type="number"
              min="0"
              max="13"
              component="input"
              placeholder="Weight"
              normalize={toNumber}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

CategoriesFilter.propTypes = {
  fields: PropTypes.any.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default CategoriesFilter;
