import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import Octicon from 'react-octicon';
import _isEmpty from 'lodash/isEmpty';

import Nav from 'source/scenes/components/navigation';
import Tabs from 'source/scenes/components/tabs';

import InsightBarChart from './insightBarChart';
import InternalNote from '../common/internalNote';

function FormattedPercent({ value }) {
  return (
    <FormattedNumber
      value={value / 100}
      /* eslint-disable react/style-prop-object */
      style="percent"
      minimumFractionDigits={2}
      maximumFractionDigits={2}
    />
  );
}

FormattedPercent.propTypes = {
  value: PropTypes.number.isRequired,
};

class ApplicationInsightsGa extends Component {
  state = {
    activeTabLeft: 'pageviews',
    activeTabRight: 'countries',
  };

  render() {
    if (_isEmpty(this.props.insights.data)) {
      return null;
    }

    const { url } = this.props.application.channel.data;
    const { notes } = this.props.application.channel;

    return (
      <>
        <InternalNote notes={notes} />

        <h5 style={{ marginBottom: '15px' }}>
          Insights for last 30 days before application
        </h5>
        <div className="row">
          <div className="col-sm-7">
            <Nav
              onClick={(activeTabLeft) => this.setState({ activeTabLeft })}
              className="nav nav-tabs"
              activeNavId={this.state.activeTabLeft}
            >
              <Nav.Item navId="pageviews">
                <Nav.Link className="text-center">
                  <Octicon name="eye" /> Page Views
                  <br />
                  <span className="font-weight-bold">
                    <FormattedNumber
                      value={this.props.insights.data.pageviews}
                    />
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item navId="users">
                <Nav.Link className="text-center">
                  <Octicon name="organization" /> Unique Visitors
                  <br />
                  <span className="font-weight-bold">
                    <FormattedNumber value={this.props.insights.data.users} />
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tabs activeTabId={this.state.activeTabLeft}>
              <Tabs.Pane tabId="pageviews">
                {this.props.metrics && (
                  <InsightBarChart
                    data={this.props.metrics.byDate.map(
                      ({ date, pageviews }) => ({
                        date,
                        value: pageviews,
                      }),
                    )}
                    label="page views"
                  />
                )}
              </Tabs.Pane>
              <Tabs.Pane tabId="users">
                {this.props.metrics && (
                  <InsightBarChart
                    data={this.props.metrics.byDate.map(({ date, users }) => ({
                      date,
                      value: users,
                    }))}
                    label="unique visitors"
                  />
                )}
              </Tabs.Pane>
            </Tabs>
          </div>
          <div className="col-sm-5">
            <Nav
              onClick={(activeTabRight) => this.setState({ activeTabRight })}
              className="nav nav-tabs"
              activeNavId={this.state.activeTabRight}
            >
              <Nav.Item navId="countries">
                <Nav.Link className="text-center">
                  <Octicon name="globe" />{' '}
                  {this.props.insights.data.countries[0].country}
                  <br />
                  <span className="font-weight-bold">
                    <FormattedPercent
                      value={this.props.insights.data.countries[0].percentage}
                    />
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item navId="referrals">
                <Nav.Link className="text-center">
                  <Octicon name="milestone" />{' '}
                  {this.props.insights.data.referrals[0].referral}
                  <br />
                  <span className="font-weight-bold">
                    <FormattedPercent
                      value={this.props.insights.data.referrals[0].percentage}
                    />
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item navId="deviceCategories">
                <Nav.Link className="text-center">
                  <Octicon name="device-mobile" />{' '}
                  {this.props.insights.data.deviceCategories[0].deviceCategory}
                  <br />
                  <span className="font-weight-bold">
                    <FormattedPercent
                      value={
                        this.props.insights.data.deviceCategories[0].percentage
                      }
                    />
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item navId="pages">
                <Nav.Link className="text-center">
                  <Octicon name="file" /> Pages
                  <br />
                  {this.props.insights.data.pages[0] && (
                    <span className="font-weight-bold">
                      <FormattedPercent
                        value={this.props.insights.data.pages[0].percentage}
                      />
                    </span>
                  )}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="text-right">
              <Octicon name="eye" /> Page Views
            </div>
            <Tabs activeTabId={this.state.activeTabRight}>
              <Tabs.Pane tabId="countries">
                <table className="table table-sm table-hover">
                  <tbody>
                    {this.props.insights.data.countries.map(
                      ({ country, visits, percentage }, index) => (
                        <tr key={country}>
                          <td>{index + 1}.</td>
                          <td>{country}</td>
                          <td className="text-right font-weight-bold">
                            <FormattedNumber value={visits} />
                          </td>
                          <td className="text-right">
                            (<FormattedPercent value={percentage} />)
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </Tabs.Pane>
              <Tabs.Pane tabId="referrals">
                <table className="table table-sm table-hover">
                  <tbody>
                    {this.props.insights.data.referrals.map(
                      ({ referral, visits, percentage }, index) => (
                        <tr key={referral}>
                          <td>{index + 1}.</td>
                          <td
                            className="text-truncate"
                            style={{ maxWidth: '270px' }}
                          >
                            <a
                              href={`http://${referral}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {referral}
                            </a>
                          </td>
                          <td className="text-right font-weight-bold">
                            <FormattedNumber value={visits} />
                          </td>
                          <td className="text-right">
                            (<FormattedPercent value={percentage} />)
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </Tabs.Pane>
              <Tabs.Pane tabId="deviceCategories">
                <table className="table table-sm table-hover">
                  <tbody>
                    {this.props.insights.data.deviceCategories.map(
                      ({ deviceCategory, visits, percentage }, index) => (
                        <tr key={deviceCategory}>
                          <td>{index + 1}.</td>
                          <td>{deviceCategory}</td>
                          <td className="text-right font-weight-bold">
                            <FormattedNumber value={visits} />
                          </td>
                          <td className="text-right">
                            (<FormattedPercent value={percentage} />)
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </Tabs.Pane>
              <Tabs.Pane tabId="pages">
                <div style={{ overflowY: 'auto', maxHeight: '320px' }}>
                  <table className="table table-sm table-hover">
                    <tbody>
                      {this.props.insights.data.pages.map(
                        ({ pagePath, visits, percentage }, index) => (
                          <tr key={pagePath}>
                            <td>{index + 1}.</td>
                            <td
                              className="text-truncate"
                              style={{ maxWidth: '270px' }}
                            >
                              <a
                                href={url + pagePath}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {pagePath}
                              </a>
                            </td>
                            <td className="text-right font-weight-bold">
                              <FormattedNumber value={visits} />
                            </td>
                            <td className="text-right">
                              (<FormattedPercent value={percentage} />)
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </div>
              </Tabs.Pane>
            </Tabs>
          </div>
        </div>
      </>
    );
  }
}

ApplicationInsightsGa.propTypes = {
  insights: PropTypes.shape({
    data: PropTypes.shape({
      users: PropTypes.number.isRequired,
      pageviews: PropTypes.number.isRequired,
      countries: PropTypes.arrayOf(
        PropTypes.shape({
          country: PropTypes.string.isRequired,
          percentage: PropTypes.number.isRequired,
        }),
      ).isRequired,
      referrals: PropTypes.arrayOf(
        PropTypes.shape({
          referral: PropTypes.string.isRequired,
          percentage: PropTypes.number.isRequired,
        }),
      ).isRequired,
      deviceCategories: PropTypes.arrayOf(
        PropTypes.shape({
          deviceCategory: PropTypes.string.isRequired,
          percentage: PropTypes.number.isRequired,
        }),
      ).isRequired,
      pages: PropTypes.arrayOf(
        PropTypes.shape({
          pagePath: PropTypes.string.isRequired,
          percentage: PropTypes.number.isRequired,
          visits: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }),
  }).isRequired,
  metrics: PropTypes.shape({
    byDate: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        pageviews: PropTypes.number.isRequired,
        users: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }),
  application: PropTypes.shape({
    channel: PropTypes.shape({
      data: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      notes: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ApplicationInsightsGa;
