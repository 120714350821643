import React from 'react';
import PropTypes from 'prop-types';

/**
 * Fairly generic input component
 * Could potentially be expanded to handle logic for incrementally firing
 * search queries as the user types
 */
class SearchInput extends React.PureComponent {
  handleInputChange = (e) => {
    const {
      target: { value: query },
    } = e;
    const { onInputChange } = this.props;

    onInputChange(query);
  };

  handleSubmit = (e) => {
    const { onSubmit, query } = this.props;

    e.preventDefault();

    onSubmit(query);
  };

  render() {
    const { placeholder, formClassName, inputClassName, query, disabled } =
      this.props;

    return (
      <form className={formClassName} onSubmit={this.handleSubmit}>
        <input
          className={inputClassName}
          type="text"
          placeholder={placeholder}
          value={query}
          onChange={this.handleInputChange}
          disabled={disabled}
        />
      </form>
    );
  }
}

SearchInput.propTypes = {
  formClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  query: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  formClassName: '',
  inputClassName: '',
  placeholder: '',
  query: '',
  disabled: false,
};

export default SearchInput;
