import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { lifecycle, branch, renderComponent, compose } from 'recompose';
import moment from 'moment';
import get from 'lodash/get';
import { FormattedNumber } from 'react-intl';
import ReactFlagsSelect from 'react-flags-select';
import ExternalLink from 'source/scenes/components/externalLink';

import ChannelAvatar from 'source/components/common/channelAvatar';
import ProgressBar from 'source/components/common/progressBar';
import socialbladeLogo from 'source/images/logo-socialblade.png';
import tiktokLogo from 'source/images/logo-tiktok.svg';

import InternalNote from '../common/internalNote';

// Helpers

const toDate = (value) => (value ? moment(value).format('YYYY-MM-DD') : '');

const toPercent = (value) =>
  typeof value === 'number' ? (
    <FormattedNumber
      {...{ style: 'percent' }}
      maximumFractionDigits={2}
      value={value}
    />
  ) : (
    ''
  );
const toNumber = (value) =>
  Number.isInteger(value) ? <FormattedNumber value={value} /> : '';

const mapToSpans = (map) =>
  Object.keys(map)
    .sort()
    .map((key) => (
      <span className="mr-2">
        {toPercent(map[key])}
        <small className="ml-1 text-muted">{key}</small>
      </span>
    ));

const mapToCountries = (map) =>
  Object.keys(map).map((key) => (
    <span
      key={key}
      className="inline-block mr-2 d-inline-flex align-items-center"
    >
      {toPercent(map[key])}
      <ReactFlagsSelect
        className="disabled-flags"
        selected={key}
        disabled
        showSelectedLabel={false}
        showSecondarySelectedLabel={false}
        showOptionLabel={false}
      />
      <span className="text-muted">{key}</span>
    </span>
  ));

const arrayToTags = (array) =>
  array.map((item) => (
    <span className="badge badge-secondary mr-1">{item}</span>
  ));

const toLastUpdate = (updatedBy, updatedByAt) =>
  (updatedBy &&
    updatedByAt &&
    `Last Control Center update on ${moment(updatedByAt).format(
      'YYYY-MM-DD HH:mm:ss',
    )} by ${updatedBy}`) ||
  '';

function Error() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      Error loading insights.
    </div>
  );
}

function Loading() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ProgressBar />
    </div>
  );
}

// HoCs

const withApplicationInsightsFetch = lifecycle({
  componentDidMount() {
    const { campaign, insights, application, onLoadInsights } = this.props;

    if (!insights.loaded) {
      onLoadInsights(application.id, campaign);
    }
  },
});

const withErrorGuard = branch(
  ({ insights }) => insights.error,
  renderComponent(Error),
);

const withLoadingGuard = branch(
  ({ insights }) => !insights.loaded,
  renderComponent(Loading),
);

const getSocialbladeUrl = (username) =>
  `https://socialblade.com/tiktok/user/${username}`;

// Components

function TikTokInsights({
  insights: { date, followers, following, likes },
  kpi = {},
  channelId,
  channelUrl,
  channelName,
  channelAvatarUrl,
}) {
  const statisticDate = get(kpi, 'statisticDate');
  const followersKpi = get(kpi, 'followers');
  const viewsTotal = get(kpi, 'viewsTotal');
  const countries = get(kpi, 'geoStatistic.countries', {});
  const viewsPerVideo = get(kpi, 'viewsPerVideo', []);
  const genders = get(kpi, 'audienceStatistic.genders', {});
  const updatedBy = get(kpi, 'updatedBy');
  const updatedByAt = get(kpi, 'updatedByAt');

  return (
    <div className="insight insight-kpi position-relative">
      <img
        className="insight-logo position-absolute mr-4"
        style={{ right: 0 }}
        src={tiktokLogo}
        alt="TikTok"
      />
      <h3>TikTok Account Data</h3>
      <div className="row">
        <div className="col-md-5">
          <ChannelAvatar
            url={channelAvatarUrl}
            className="application-channel-avatar-tiktok"
          />
          <table className="table table-sm">
            <tbody>
              <tr>
                <th>Date</th>
                <td>{moment(date).format('YYYY-MM-DD')}</td>
              </tr>
              <tr>
                <th>Channel URL</th>
                <td>
                  <ExternalLink href={channelUrl}>{channelUrl}</ExternalLink>
                </td>
              </tr>
              <tr>
                <th>Channel Name</th>
                <td>
                  <Link to={`/channels/${channelId}#metrics`}>
                    {channelName}
                  </Link>
                </td>
              </tr>
              <tr>
                <th>Followers</th>
                <td>{toNumber(followers)}</td>
              </tr>
              <tr>
                <th>Following</th>
                <td>{toNumber(following)}</td>
              </tr>
              <tr>
                <th>Likes</th>
                <td>{toNumber(likes)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-7">
          <table className="table table-sm">
            <tbody>
              <tr>
                <th>Data as of</th>
                <td>{toDate(statisticDate)}</td>
              </tr>
              <tr>
                <th>Views from last 28 days</th>
                <td>{toNumber(viewsTotal)}</td>
              </tr>
              <tr>
                <th>Followers</th>
                <td>{toNumber(followersKpi)}</td>
              </tr>
              <tr>
                <th width="25%">Views for video posts from last 7 days</th>
                <td>{arrayToTags(viewsPerVideo)}</td>
              </tr>
              <tr>
                <th width="25%">Countries</th>
                <td>{mapToCountries(countries)}</td>
              </tr>
              <tr>
                <th width="25%">Genders</th>
                <td>{mapToSpans(genders)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <span className="text-muted">
          {toLastUpdate(updatedBy, updatedByAt)}
        </span>
      </div>
    </div>
  );
}

TikTokInsights.propTypes = {
  channelId: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  channelUrl: PropTypes.string.isRequired,
  channelAvatarUrl: PropTypes.string,
  kpi: PropTypes.object,
  insights: PropTypes.shape({
    date: PropTypes.string,
    followers: PropTypes.number,
    following: PropTypes.number,
    likes: PropTypes.number,
  }).isRequired,
};

function SocialbladeInsights({ channelName }) {
  return (
    <div className="insight insight-socialblade">
      <img
        className="insight-logo-wide pull-right"
        src={socialbladeLogo}
        alt="Instagram"
      />
      <h3>Follower count increase &amp; decrease</h3>
      <ExternalLink href={getSocialbladeUrl(channelName)}>
        {getSocialbladeUrl(channelName)}
      </ExternalLink>
    </div>
  );
}

SocialbladeInsights.propTypes = {
  channelName: PropTypes.string.isRequired,
};

function ApplicationInsights({
  insights: { data },
  application: {
    channel: {
      id: channelId,
      name: channelName,
      avatarUrl: channelAvatarUrl,
      data: { url: channelUrl },
      notes,
    },
  },
}) {
  return (
    <div>
      <InternalNote notes={notes} />

      <TikTokInsights
        kpi={data.kpi}
        channelId={channelId}
        channelUrl={channelUrl}
        channelName={channelName}
        insights={data}
        channelAvatarUrl={channelAvatarUrl}
      />
      <SocialbladeInsights channelName={channelName} />
    </div>
  );
}

ApplicationInsights.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    channel: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      data: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      notes: PropTypes.string,
      avatarUrl: PropTypes.string,
    }).isRequired,
  }).isRequired,
  insights: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.object,
    data: PropTypes.object,
  }).isRequired,
};

export default compose(
  withApplicationInsightsFetch,
  withErrorGuard,
  withLoadingGuard,
)(ApplicationInsights);
