import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import noop from 'lodash/noop';

import Markdown from './markdown';

class MarkdownTextAreaTabs extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      preview: false,
      textareaFocused: false,
    };
  }

  togglePreviewOn = (e) => {
    e.preventDefault();
    this.setState({ preview: true });
  };

  togglePreviewOff = (e) => {
    e.preventDefault();
    this.setState({ preview: false });
  };

  handleTextareaBlur = (e) => {
    this.setState({ textareaFocused: false });
    return this.props.onBlur(e);
  };

  handleTextareaFocus = (e) => {
    this.setState({ textareaFocused: true });
    return this.props.onFocus(e);
  };

  renderBody() {
    const { preview } = this.state;
    const { className, value, ...props } = this.props;

    // in "preview" mode we render the markdown preview
    if (preview) {
      return (
        <Markdown
          className={cx('form-control', 'preview b-t-0', className)}
          markdown={value}
        />
      );
    }

    // in "!preview" (aka. edit) mode we render a textarea
    return (
      <textarea
        {...props}
        value={value}
        className={cx(className, 'b-t-0')}
        onBlur={this.handleTextareaBlur}
        onFocus={this.handleTextareaFocus}
      />
    );
  }

  render() {
    const { preview, textareaFocused } = this.state;

    return (
      <div className="markdown-textarea">
        <ul className="nav nav-tabs no-b-b">
          <li className="nav-item">
            <a
              className={cx('nav-link', {
                active: !preview,
                focused: textareaFocused,
              })}
              onClick={this.togglePreviewOff}
              href="#"
            >
              edit
            </a>
          </li>
          <li className="nav-item">
            <a
              className={cx('nav-link', { active: preview })}
              onClick={this.togglePreviewOn}
              href="#"
            >
              preview
            </a>
          </li>
        </ul>
        {this.renderBody()}
      </div>
    );
  }
}

MarkdownTextAreaTabs.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MarkdownTextAreaTabs.defaultProps = {
  value: '',
  className: '',
  onBlur: noop,
  onFocus: noop,
  rows: 8,
};

export default MarkdownTextAreaTabs;
