import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import data from 'source/data/reducer';
import scenes from 'source/scenes/reducer';

import account, { getInitialState as accountInitialState } from './account';
import app, { getInitialState as appInitialState } from './app';
import error, { getInitialState as errorInitialState } from './error';
import links, { getInitialState as linksInitialState } from './links';
import iabCategories, {
  getInitialState as iabCategoriesInitialState,
} from './iabCategories';
import campaign, { getInitialState as campaignInitialState } from './campaign';

const createRootReducer = (history) =>
  combineReducers({
    // external
    router: connectRouter(history),

    // internal
    account,
    app,
    error,
    links,
    iabCategories,
    campaign,
    data,
    // redux-form
    form: formReducer,
    scenes,
  });

export const getInitialState = (accessToken) => ({
  account: { ...accountInitialState(accessToken) },
  app: { ...appInitialState() },
  error: { ...errorInitialState() },
  links: { ...linksInitialState() },
  iabCategories: { ...iabCategoriesInitialState() },
  campaign: { ...campaignInitialState() },
});

export default createRootReducer;
