import PropTypes from 'prop-types';
import React from 'react';

import { Alert, ProgressBar } from 'source/components/common';

const pageDescriptions = {
  groupUsers: 'Add or remove users to this group',
  addUser: 'Add a user to this group',
};

function GroupDetailWrapper(props) {
  if (props.state !== 'loaded') {
    return <ProgressBar.Mega />;
  }

  const child = React.Children.only(props.children);

  return (
    <div className="container">
      <Alert {...props.alert} onClose={props.onAlertClose} />

      <div className="row">
        <div className="col">
          <h2>User Group</h2>
          <h3 className="pt-3">
            <div
              className="group-color-block"
              style={{ backgroundColor: props.group.color }}
            />
            {props.group.name}
          </h3>
          <p className="text-muted">{pageDescriptions[props.groupName]}</p>
        </div>
      </div>

      {React.cloneElement(child, { ...props })}
    </div>
  );
}

GroupDetailWrapper.propTypes = {
  onAlertClose: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  alert: PropTypes.object,
  group: PropTypes.object,
  groupName: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default GroupDetailWrapper;
