import { loadStateReducer, asyncStates } from '@blogfoster/redux-async-utils';
import { combineReducers } from 'redux';

import { namespacedReducer, resetableReducer } from 'source/utils/redux';
import { actionTypes as channelActionTypes } from 'source/data/channels/actions';
import { getPagingFromHeaders } from 'source/components/common/pagination';

import { namespace, actionTypes } from './actions';

const reducer = namespacedReducer(namespace)(
  resetableReducer(actionTypes.RESET_SCENE)(
    combineReducers({
      channelAsync: loadStateReducer(channelActionTypes.SEARCH_MANY),
      categoriesAsync: loadStateReducer(
        channelActionTypes.CATEGORIES_FETCH_MANY,
      ),
      channelPaging: (state, action) => {
        if (state === undefined) {
          return getPagingFromHeaders();
        }

        if (
          action.type === channelActionTypes.SEARCH_MANY &&
          action.async === asyncStates.success &&
          action.payload
        ) {
          return getPagingFromHeaders(action.payload.headers);
        }

        return state;
      },
      channelSorting: (state, action) => {
        // initial state
        if (state === undefined) {
          return { reach: -1 };
        }

        if (action.type === actionTypes.UPDATE_SORT) {
          return action.payload;
        }

        return state;
      },
    }),
  ),
);

export default reducer;
