import React from 'react';
import { branch, lifecycle, renderComponent } from 'recompose';

import { Alert, ProgressBar } from 'source/components/common';

export const withApplications = ({
  defaultFields = [
    'campaignId',
    'productShipment',
    'kamNotes',
    'clientNotes',
    'id',
    'type',
    'match',
    'mission',
    'payment',
    'publication',
    'status',
    'contentReview',
    'contentPreview',
    'contentPreviews',
    'clientContentReview',
    'clientContentPreview',
    'clientApplicationReview',
    'collaborationRatings',
    'channel',
    'tasks',
    'user.id',
    'user.email',
    'user.salutation',
    'user.firstname',
    'user.lastname',
    'user.yearOfBirth',
    'user.language',
    'user.createdAt',
    'user.lastLogin',
    'user.phone',
    'user.streetName',
    'user.streetNumber',
    'user.city',
    'user.country',
    'user.postalCode',
    'user.phone',
    'user.settings.promotionalEmails',
    'user.settings.recommendedCampaignEmails',
    'user.groups',
    'user.privateNetworks',
  ],
  additionalFields = [],
} = {}) =>
  lifecycle({
    componentDidMount() {
      const { onLoadApplications, campaign } = this.props;

      const dynamicFields = [];

      if (campaign.afterPublicationUploadsRequired) {
        dynamicFields.push('afterPublicationUploadsVerification');
      }

      if (campaign.socialMediaSharing) {
        dynamicFields.push('socialMediaSharingVerification');
      }

      const filters = [{ campaignId: campaign.id }];
      const fields = [
        ...defaultFields,
        ...dynamicFields,
        // campaign type specific fields
        ...additionalFields,
      ];

      onLoadApplications({
        filters,
        fields,
      });
    },

    componentWillUnmount() {
      this.props.onResetScene();
    },
  });

export function Loading() {
  return <ProgressBar mega />;
}

function Error() {
  return (
    <Alert
      type="danger"
      message="Error loading applications. Please try to refresh the page."
      mega
    />
  );
}

export const withLoadingGuard = branch(
  (props) => !props.applicationsRequestState.loaded,
  renderComponent(Loading),
);

export const withErrorGuard = branch(
  (props) => props.applicationsRequestState.error,
  renderComponent(Error),
);
