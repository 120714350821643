import { combineReducers } from 'redux';
import { asyncActionReducer } from '@blogfoster/redux-async-utils';
import identity from 'lodash/identity';

import { actionTypes } from './actions';
import { dataReplaceManyHandler } from '../dataHandlers';

const getData = identity;

const filesCreateReducer = asyncActionReducer(
  actionTypes.CREATE,
  dataReplaceManyHandler({ key: 'key', getData }),
);

export default combineReducers({
  byId: filesCreateReducer,
});
