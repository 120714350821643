import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import forms from 'source/utils/forms';
import SettingsBar from 'source/components/common/settingsBar';
import { Checkbox } from 'source/components/common/forms';

const enhanceOptionsForm = compose(forms({}));

function OptionsForm({ form: { showRefused = true }, onChange }) {
  return (
    <form>
      <SettingsBar className="pb-3">
        <Checkbox
          id="showRefused"
          checked={showRefused}
          label="Show Refused Missions"
          onClick={onChange}
        />
      </SettingsBar>
    </form>
  );
}

OptionsForm.propTypes = {
  form: PropTypes.shape({
    showRefused: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

OptionsForm = enhanceOptionsForm(OptionsForm);

function MissionOptions({ forms: { missionsOptions = {} }, ...props }) {
  return (
    <div className="row">
      <div className="col">
        <OptionsForm {...props} id="missionsOptions" form={missionsOptions} />
      </div>
    </div>
  );
}

MissionOptions.propTypes = {
  forms: PropTypes.shape({
    missionsOptions: PropTypes.object,
  }).isRequired,
};

export default MissionOptions;
