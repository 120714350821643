import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { InputError } from 'source/components/common';
import { bindArgs } from 'source/utils';

class LinksFilterSelect extends React.PureComponent {
  handleChange = (e) => {
    e.preventDefault();
    return this.props.onChange(e.target.value);
  };

  render() {
    const { options, value } = this.props;

    /* eslint-disable react/no-array-index-key */
    return (
      <select
        className="form-control"
        value={value}
        onChange={this.handleChange}
      >
        {options.map((option, index) => (
          <option
            key={index}
            value={option.value}
            selected={value === option.value}
          >
            {option.text}
          </option>
        ))}
      </select>
    );
    /* eslint-enable react/no-array-index-key */
  }
}

LinksFilterSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired, // value that is send with the onChange method
    }),
  ).isRequired,
  value: PropTypes.any, // one value of the options
  onChange: PropTypes.func.isRequired,
};

function LinksFilter({ options, filter, value, error, onChange, onRemove }) {
  const handleRemove = () => onRemove();
  const handleChangeFilter = (value) => onChange('filter', value);
  const handleChangeValue = (e) => onChange('value', e.target.value);

  return (
    <div className="form-group row">
      <div className="col-md-2">
        <LinksFilterSelect
          options={options}
          value={filter}
          onChange={handleChangeFilter}
        />
      </div>
      <div className="col-md-9">
        <input
          type="text"
          className={cx('form-control', { 'is-invalid': error })}
          placeholder="search"
          value={value}
          onChange={handleChangeValue}
        />
        <InputError error={error} />
      </div>
      <div className="col-md-1">
        <button className="btn btn-outline-danger" onClick={handleRemove}>
          -
        </button>
      </div>
    </div>
  );
}

LinksFilter.propTypes = {
  options: PropTypes.any.isRequired,
  filter: PropTypes.any.isRequired,
  value: PropTypes.string,
  error: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

function LinksFilters({
  filters,
  disableAdd = false,
  onChange,
  onAdd,
  onRemove,
}) {
  const handleAdd = (e) => {
    e.preventDefault();
    return onAdd();
  };

  return (
    <div>
      <form>
        {filters.map((filter) => (
          <LinksFilter
            key={filter.id}
            {...filter}
            onChange={bindArgs(onChange, filter)}
            onRemove={bindArgs(onRemove, filter)}
          />
        ))}
        <div className="row justify-content-end pr-3">
          <button
            className="btn btn-outline-primary"
            onClick={handleAdd}
            disabled={disableAdd}
          >
            +
          </button>
        </div>
      </form>
    </div>
  );
}

LinksFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  disableAdd: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default LinksFilters;
