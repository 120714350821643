import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';

export function FormControlFeedback({ message, className, style }) {
  return message ? (
    <small className={cx('invalid-feedback', className)} style={style}>
      {message}
    </small>
  ) : null;
}

FormControlFeedback.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

FormControlFeedback.defaultProps = {
  message: '',
  className: undefined,
  style: undefined,
};

export class Checkbox extends Component {
  handleClick = (e) => {
    const { id, onClick } = this.props;

    e.stopPropagation();

    return onClick && onClick(id, e.target.checked);
  };

  render() {
    const {
      id,
      checked,
      label,
      disabled,
      className,
      inputClassName,
      style,
      managed,
      onClick,
    } = this.props;

    const inputProps = {};
    if (onClick && !managed) {
      inputProps.onClick = onClick;
    }
    if (managed) {
      inputProps.onClick = this.handleClick;
    }

    return (
      <div className={cx('form-check', className)} style={style}>
        <input
          id={id}
          className={cx('form-check-input', inputClassName)}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={noop}
          {...inputProps}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  style: PropTypes.object,
  managed: PropTypes.bool,
  onClick: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  label: '',
  className: undefined,
  inputClassName: undefined,
  style: undefined,
  managed: false,
  onClick: undefined,
};

export class Form extends Component {
  handleChange = (e) => {
    const { id, onChange } = this.props;

    e.stopPropagation();

    let value;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    return onChange(id, e.target.id, value);
  };

  handleSubmit = (e) => {
    const { id, data, onSubmit } = this.props;

    e.preventDefault();
    e.stopPropagation();

    return onSubmit(id, data);
  };

  render() {
    const { id, className, children } = this.props;

    return (
      <form
        id={id}
        className={className}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
      >
        {children}
      </form>
    );
  }
}

Form.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.any,
  children: PropTypes.any,
};

Form.defaultProps = {
  data: undefined,
  className: '',
  children: undefined,
};
