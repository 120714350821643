import React from 'react';
import PropTypes from 'prop-types';

import {
  EventMediaBody,
  EventBody,
} from 'source/scenes/campaignDetail/missions/components/event';

import HTMLPreview from './htmlPreview';
import YouTubePreview from './youtubePreview';

function MediaPreview({ type, content, mediaRefs }) {
  return (
    <div className={`${type}-preview-container`}>
      <EventMediaBody content={content} mediaRefs={mediaRefs} />
      <EventBody content={content.caption} />
    </div>
  );
}

MediaPreview.propTypes = {
  type: PropTypes.oneOf(['instagram', 'tiktok', 'pinterest']),
  content: PropTypes.object.isRequired,
  mediaRefs: PropTypes.objectOf(
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLElement) }),
  ).isRequired,
};

function ContentPreview({
  showCode,
  setRenderedHTMLRef,
  type,
  content,
  mediaRefs,
}) {
  if (type === 'website') {
    return (
      <HTMLPreview
        setRenderedHTMLRef={setRenderedHTMLRef}
        showCode={showCode}
        content={content}
      />
    );
  }

  if (type === 'youtube') {
    return <YouTubePreview content={content} />;
  }

  if (['instagram', 'tiktok', 'pinterest'].includes(type)) {
    return <MediaPreview type={type} content={content} mediaRefs={mediaRefs} />;
  }

  return (
    <div className="d-flex justify-content-center align-items-center no-preview">
      <div className="alert alert-danger" role="alert">
        Preview for the content of type <strong>{type}</strong> not supported.
      </div>
    </div>
  );
}

ContentPreview.propTypes = {
  showCode: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  setRenderedHTMLRef: PropTypes.func.isRequired,
  mediaRefs: PropTypes.objectOf(
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLElement) }),
  ).isRequired,
};

export default ContentPreview;
