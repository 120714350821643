import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import withResetScene from 'source/components/commonEnhancers/withResetScene';
import { InputSelect } from 'source/components/common/inputs';
import ProgressBar from 'source/components/common/progressBar';
import actions from '../actions';

import { getAddUserFormState } from '../selectors';

const enhance = compose(connect(getAddUserFormState, actions), withResetScene);

class GroupAddUser extends React.PureComponent {
  componentDidMount() {
    this.props.onLoadUsers();
  }

  handleChange = (id, value) => {
    const { form, onAddUserFormChange } = this.props;

    onAddUserFormChange(form, id, value);
  };

  handleSubmit = (e) => {
    const {
      onAddUserFormSubmit,
      match: {
        params: { id },
      },
    } = this.props;
    e.preventDefault();

    onAddUserFormSubmit(id);
  };

  handleCancel = () => {
    const {
      onAddUserFormCancel,
      match: {
        params: { id },
      },
    } = this.props;

    onAddUserFormCancel(id);
  };

  render() {
    const { state, users, form } = this.props;

    if (state !== 'loaded') {
      return <ProgressBar />;
    }

    return (
      <>
        <hr />
        <div className="row">
          <div className="col">
            <form>
              <InputSelect
                name="User"
                id="userId"
                options={users}
                value={form.userId.value}
                error={form.errors.userId}
                onChange={this.handleChange}
                placeholder="Select user"
              />

              <div className="row justify-content-end pr-3">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.handleSubmit}
                >
                  Add User
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ml-1"
                  onClick={this.handleCancel}
                  disabled={form.submitting}
                >
                  Back to Group
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

GroupAddUser.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onLoadUsers: PropTypes.func.isRequired,
  onAddUserFormChange: PropTypes.func.isRequired,
  onAddUserFormSubmit: PropTypes.func.isRequired,
  onAddUserFormCancel: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  form: PropTypes.shape({
    userId: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.object,
  }).isRequired,
};

export default enhance(GroupAddUser);
