import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import forms from 'source/utils/forms';
import CopyButton from 'source/components/common/copyButton';

const preventDefault = (e) => e?.preventDefault();

const missionStatusFormOptions = {
  contextFn: ({ application: { id } }) => ({ missionId: id }),
  validateForm(form) {
    const errors = {};

    if (form.missionStatus !== 'pending') {
      errors.missionStatus = 'invalid mission status';
    }

    return errors;
  },
};

function MissionDates({ mission }) {
  const { previewDate, publishDate } = mission;

  if (!previewDate || !publishDate) {
    return null;
  }
  const previewDateFormatted = moment.utc(previewDate).format('DD/MM/YYYY');
  const publishDateFormatted = moment.utc(publishDate).format('DD/MM/YYYY');

  return (
    <div className="row">
      <div className="col-md-6">
        <label htmlFor="previewDate" className="text-muted small mb-0">
          Preview
        </label>
        <div id="previewDate">{previewDateFormatted}</div>
      </div>
      <div className="col-md-6">
        <label htmlFor="publishDate" className="text-muted small mb-0">
          Publish
        </label>
        <div id="publishDate">{publishDateFormatted}</div>
      </div>
    </div>
  );
}

MissionDates.propTypes = {
  mission: PropTypes.shape({
    status: PropTypes.string.isRequired,
    previewDate: PropTypes.string,
    publishDate: PropTypes.string,
  }).isRequired,
};

const MissionStatus = React.memo(
  ({
    application: { id, mission },
    campaign: { type: platform, id: campaignId },
    form: { state = 'pristine', missionStatus = '', errors },
    onChange,
    onSubmit,
    className,
  }) => {
    const buttonClassName = cx('btn', {
      'btn-primary': state === 'pristine' || state === 'dirty',
      'btn-outline-warning': state === 'submitting',
      'btn-outline-success': state === 'submitted',
    });

    const disabled =
      state === 'submitting' || // while submission
      !isEmpty(errors) || // if there are errors
      state === 'pristine' || // if there are no changes
      state === 'submitted';

    return (
      <form className={className} onSubmit={onSubmit}>
        {/* Application ID */}
        <div>
          <label className="text-muted small mb-0" htmlFor="applicationUrl">
            Application ID
          </label>
          <div className="d-flex align-item-center justify-content-between">
            <Link
              id="applicationUrl"
              to={`/campaigns/${platform}/${campaignId}/applications/${id}`}
            >
              {id}
            </Link>
            <CopyButton value={id} title={id} onClick={preventDefault} />
          </div>
        </div>

        {/* Mission dates */}
        <MissionDates mission={mission} />

        {/* Mission status */}
        <div className="mb-2">
          <label className="text-muted small mb-0" htmlFor="missionStatus">
            Mission status:
          </label>
          <select
            id="missionStatus"
            className={cx('custom-select', {
              'is-invalid': errors.missionStatus,
            })}
            value={missionStatus}
            onChange={onChange}
          >
            <option value="pending">Pending</option>
            <option value="refused" disabled>
              Refused
            </option>
            <option value="confirmed" disabled>
              Confirmed
            </option>
          </select>
          {errors.missionStatus && (
            <small className="invalid-feedback">{errors.missionStatus}</small>
          )}
        </div>
        <div className="d-flex justify-content-end">
          <button type="submit" className={buttonClassName} disabled={disabled}>
            Save
          </button>
        </div>
      </form>
    );
  },
);

MissionStatus.propTypes = {
  id: PropTypes.string.isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    mission: PropTypes.shape({
      status: PropTypes.string.isRequired,
      previewDate: PropTypes.string,
      publishDate: PropTypes.string,
    }).isRequired,
  }).isRequired,

  className: PropTypes.string,

  form: PropTypes.shape({
    missionStatus: PropTypes.string.isRequired,
    state: PropTypes.oneOf(['pristine', 'dirty', 'submitting', 'submitted']),
    errors: PropTypes.object,
  }).isRequired,

  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default forms(missionStatusFormOptions)(MissionStatus);
