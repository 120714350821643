import React from 'react';
import PropTypes from 'prop-types';
import Octicon from 'react-octicon';

import { nanoid } from 'nanoid';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

import DragDropList from 'source/scenes/components/dragDropList';

const mapProducts = (productsWithoutId) =>
  productsWithoutId.map((product) => {
    if (isObject(product) && product.id) {
      return product;
    }

    return {
      id: nanoid(),
      value: product,
    };
  });

function ProductsListField({ input }) {
  const { value, onChange } = input;

  const [products, setProducts] = React.useState(() => mapProducts(value));

  const handleAddProduct = () => {
    setProducts([
      ...products,
      {
        id: nanoid(),
        value: '',
      },
    ]);
  };

  const handleMoveProduct = (indexA, indexB) => {
    const productsCopy = [...products];

    const productA = products[indexA];
    const productB = products[indexB];

    productsCopy[indexB] = productA;
    productsCopy[indexA] = productB;

    setProducts(productsCopy);
  };

  const createEditHandler = (productId) => (event) => {
    const productsCopy = [...products];
    const productIndex = productsCopy.findIndex(
      (product) => product.id === productId,
    );
    productsCopy[productIndex].value = event.target.value;

    setProducts(productsCopy);
  };

  const createRemoveHandler = (productId) => () => {
    const newProducts = products.filter((product) => product.id !== productId);

    setProducts(newProducts);
  };

  React.useEffect(() => {
    if (!isEmpty(value) && isString(value[0])) {
      setProducts(mapProducts(value));
    }
  }, [value]);

  React.useEffect(() => {
    onChange(products);
  }, [products]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className="products-list" onKeyDown={handleKeyDown}>
      <span className="label">Product choices:</span>
      {!isEmpty(value) && (
        <DragDropList
          onMoveItem={handleMoveProduct}
          className="products-container"
        >
          {value.map(({ id: productId, value: name }) => (
            <DragDropList.Item key={productId} id={productId}>
              <input
                className="form-control"
                value={name}
                onChange={createEditHandler(productId)}
              />
              <button
                type="button"
                className="btn btn-outline-danger btn--remove"
                onClick={createRemoveHandler(productId)}
              >
                <Octicon name="trashcan" />
              </button>
            </DragDropList.Item>
          ))}
        </DragDropList>
      )}
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleAddProduct}
      >
        + Add product
      </button>
    </div>
  );
}

ProductsListField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
  }),
};
ProductsListField.defaultProps = {
  input: {
    value: [],
    onChange: () => {},
  },
};

export default ProductsListField;
