import { createConstants } from 'source/utils';

const actionTypes = createConstants(
  // list applications
  'CAMPAIGN_APPLICATIONS_LOADING',
  'CAMPAIGN_APPLICATIONS_LOADED',
  'CAMPAIGN_APPLICATIONS_FAILED',
  // application to bar filter change
  'CAMPAIGN_APPLICATION_FILTER_CHANGE',
  // application panel changes
  'CAMPAIGN_APPLICATION_EXPAND_APPLICATION',
  // application form changes
  'CAMPAIGN_APPLICATION_FORM_CHANGED',
  // application submit
  'CAMPAIGN_APPLICATION_PATCHING',
  'CAMPAIGN_APPLICATION_PATCHED',
  'CAMPAIGN_APPLICATION_PATCHING_FAILED',
  // insights
  'CAMPAIGN_APPLICATION_INSIGHTS_LOADING',
  'CAMPAIGN_APPLICATION_INSIGHTS_LOADED',
  'CAMPAIGN_APPLICATION_INSIGHTS_FAILED',
  // updating multiple applications at the same time
  'CAMPAIGN_APPLICATIONS_UPDATING',
  'CAMPAIGN_APPLICATIONS_UPDATED_SUCCESFULLY',
  'CAMPAIGN_APPLICATIONS_UPDATING_FAILED',
  // publishing dates
  'CAMPAIGN_APPLICATION_PUBLISHING_DATES_LOADING',
  'CAMPAIGN_APPLICATION_PUBLISHING_DATES_LOADED',
  'CAMPAIGN_APPLICATION_PUBLISHING_DATES_FAILED',
);

export default actionTypes;
