import React from 'react';
import PropTypes from 'prop-types';

import DownloadButton from 'source/components/common/downloadButton';
import ProgressBar from 'source/components/common/progressBar';
import Alert from 'source/components/common/alert';

import WebsiteReportingTable from './websiteReportingTable';
import WebsiteLinksTable from './websiteLinksTable';
import WebsiteReportingLineChart from './websiteReportingLineChart';
import ReportingDateRangeForm from './reportingDateRangeForm';

const REPORTING_DATE_RANGE_FORM_NAME = 'reportingDateRangeForm';

class CampaignReporting extends React.Component {
  onSubmit = (values) => {
    this.props.onLoadCampaignReporting(this.props.campaign.id, values);
  };

  renderReport() {
    const {
      report: { totals, byPost, byDate, byLinks },
      campaign,
      reportingUrls,
    } = this.props;

    // We assume that whenever we receive '0'/null as value for an article, the article report is invalid
    const hasInvalidReportByPost = byPost.some(
      (report) => report.totals.visits === null,
    );

    return (
      <>
        {hasInvalidReportByPost && (
          <div className="row">
            <div className="col">
              <Alert
                type="warning"
                message="Performance data could not be loaded for one or more articles."
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  Performance data for all submitted articles
                </h4>
                <WebsiteReportingTable
                  reports={byPost}
                  totals={totals}
                  campaign={campaign}
                />
              </div>
              <div className="card-footer">
                <div className="text-sm-right">
                  <DownloadButton size="sm" href={reportingUrls.byPost}>
                    Export XLSX
                  </DownloadButton>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  GA post metrics traffic data for the selected period
                </h4>
              </div>
              <div className="card-footer">
                <div className="text-sm-right">
                  <DownloadButton size="sm" href={reportingUrls.byPostMetrics}>
                    Export XLSX
                  </DownloadButton>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  Visits, Unique Visitors and Total Page Views
                </h4>
                <div className="mt-3">
                  <WebsiteReportingLineChart byDate={byDate} />
                </div>
              </div>
              <div className="card-footer">
                <div className="text-sm-right">
                  <DownloadButton size="sm" href={reportingUrls.byDate}>
                    Export XLSX
                  </DownloadButton>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Links</h4>
                <WebsiteLinksTable reports={byLinks} totals={totals} />
              </div>
              <div className="card-footer">
                <div className="text-sm-right">
                  <DownloadButton size="sm" href={reportingUrls.byLink}>
                    Export XLSX
                  </DownloadButton>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </>
    );
  }

  render() {
    const {
      reportAsync: { loaded },
      initialReportingDateRange,
    } = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Reporting</h2>
            <p className="text-muted">
              Performance data for the published articles of the campaign
            </p>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col">
              <ReportingDateRangeForm
                form={REPORTING_DATE_RANGE_FORM_NAME}
                initialValues={initialReportingDateRange}
                submitDisabled={!loaded}
                onSubmit={this.onSubmit}
              />
            </div>
          </div>
          <br />
        </div>
        {loaded ? this.renderReport() : <ProgressBar.Mega />}
      </div>
    );
  }
}

CampaignReporting.propTypes = {
  reportAsync: PropTypes.shape({
    loaded: PropTypes.bool.isRequired,
  }).isRequired,
  report: PropTypes.shape({
    byPost: PropTypes.any,
    totals: PropTypes.any,
    byDate: PropTypes.any,
    byLinks: PropTypes.array,
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  campaign: PropTypes.object.isRequired,
  initialReportingDateRange: PropTypes.shape({
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
  }).isRequired,
  reportingUrls: PropTypes.shape({
    byPost: PropTypes.string.isRequired,
    byDate: PropTypes.string.isRequired,
    byLink: PropTypes.string.isRequired,
    byPostMetrics: PropTypes.string.isRequired,
  }).isRequired,
  onLoadCampaignReporting: PropTypes.func.isRequired,
};

export default CampaignReporting;
