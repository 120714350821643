import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import get from 'lodash/get';
import difference from 'lodash/difference';
import { reduxForm, Field, propTypes } from 'redux-form';
import { DateInput } from 'source/scenes/components/reduxFormAdapters';
import ExternalLink from 'source/scenes/components/externalLink';
import Countries from 'source/utils/countries';

import {
  toISOString,
  percentToFloatStr,
  floatStrToPercent,
  normalizePercent,
  parseToInt,
} from 'source/utils/reduxForm';

import CountryInput from './countryInput';
import TagsInput from './tagsInput';
import ScreenshotPanel from './screenshotPanel';
import { SCREENSHOTS_NAMES, SCREENSHOTS_PLACEHOLDERS } from '../constants';

const ageGroups = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'];
const defaultCountries = ['DE', 'AT', 'CH', 'US'];
const countryCodes = Object.keys(Countries).sort();

class InstagramInsights extends React.PureComponent {
  constructor(props) {
    super(props);
    const countries = Object.keys(
      get(props.channel, 'keyMetrics.audience.countries', {}),
    );
    const diff = difference(defaultCountries, countries);
    this.selectedCountries = [...countries, ...diff].slice(0, 4);
  }

  state = {
    isRefreshing: false,
  };

  getRefreshLabel = () => {
    if (this.state.isRefreshing) {
      return `Refresh requested on ${moment().local().format('L HH:mm')} by ${
        this.props.user.email
      }`;
    }

    const { channel } = this.props;

    if (channel?.keyMetricsCache?.cacheUpdatedAt) {
      return `Refreshed on ${moment
        .utc(channel?.keyMetricsCache?.cacheUpdatedAt)
        .local()
        .format('L HH:mm')} from ${channel?.keyMetrics?.source}`;
    }

    return 'No full report available yet';
  };

  handleScreenshotDelete = (name) => this.props.onDeleteScreenshot(name);

  updateSelectedCountries = (oldCountry, newCountry) => {
    const index = this.selectedCountries.indexOf(oldCountry);
    this.selectedCountries[index] = newCountry;
  };

  handleCountryChange = (oldCountry, newCountry, value) => {
    this.props.change(`audience.countries.${oldCountry}`, '');

    if (value) {
      this.props.change(
        `audience.countries.${newCountry}`,
        floatStrToPercent(value),
      );
    }

    this.updateSelectedCountries(oldCountry, newCountry);
  };

  handleRefreshMetrics = (event) => {
    event.preventDefault();

    this.setState((prevState) => {
      if (prevState.isRefreshing) {
        return prevState;
      }

      return {
        ...prevState,
        isRefreshing: true,
      };
    });

    this.props.onRefreshMetrics();
  };

  renderCountries = () => {
    const countriesWithoutSelected = difference(
      countryCodes,
      this.selectedCountries,
    );

    return (
      <div className="d-flex mt-2">
        {this.selectedCountries.map((country, index) => (
          <CountryInput
            key={country}
            objectKey="audience.countries"
            className={cx({
              'mr-3': index !== this.selectedCountries.length - 1,
            })}
            country={country}
            countries={countriesWithoutSelected}
            onSelectFlag={this.handleCountryChange}
          />
        ))}
      </div>
    );
  };

  renderScreenshots = () => {
    const { channel } = this.props;
    const names = get(SCREENSHOTS_NAMES, channel.platform, []);
    return names.map((screenshotName) => (
      <ScreenshotPanel
        key={screenshotName}
        name={screenshotName}
        placeholderImg={get(
          SCREENSHOTS_PLACEHOLDERS,
          `${channel.platform}.${screenshotName}`,
        )}
        screenshot={get(channel, `screenshots.${screenshotName}`)}
        platform={channel.platform}
        onDelete={this.handleScreenshotDelete}
      />
    ));
  };

  renderField = ({
    className,
    showPercentage,
    input,
    label,
    smallLabel,
    meta: { error },
    ...rest
  }) => {
    const labelElement = smallLabel ? 'small' : 'label';
    const unsetProps =
      rest.readOnly && input.value === undefined ? { value: '' } : {};

    return (
      <>
        {React.createElement(
          labelElement,
          { htmlFor: input.name, className: 'text-muted truncate' },
          label,
        )}
        <div
          className={cx('position-relative', {
            percentage: showPercentage && input.value >= 0,
          })}
        >
          <input
            id={input.name}
            className={cx(className, { 'is-invalid': error })}
            {...input}
            {...unsetProps}
            {...rest}
          />
          <small className="invalid-feedback">{error}</small>
        </div>
      </>
    );
  };

  render() {
    const { pristine, requestInsightsState, handleSubmit, channel } =
      this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="panels-grid form-group mb-3">
                {this.renderScreenshots()}
              </div>
            </div>

            {/* Metrics */}
            <div className="col-xl-6 col-md-12">
              {channel?.keyMetrics?.updatedByAt && (
                <div className="mb-3">
                  <span className="text-muted">
                    Updated on{' '}
                    {moment
                      .utc(channel?.keyMetrics?.updatedByAt)
                      .local()
                      .format('L HH:mm')}{' '}
                    by {channel?.keyMetrics?.updatedBy}
                  </span>
                </div>
              )}

              {/* Stories impressions */}
              <div className="form-group">
                <label className="text-muted" htmlFor="storiesImpressions">
                  Impressions for Stories from last 30 days
                </label>
                <TagsInput id="storiesImpressions" name="storiesImpressions" />
              </div>

              {/* Posts impressions */}
              <div className="form-group">
                <label className="text-muted" htmlFor="postsImpressions">
                  Impressions for Posts from last 30 days
                </label>
                <TagsInput id="postsImpressions" name="postsImpressions" />
              </div>

              <div className="mb-3">
                <span className="font-weight-bold mr-3">IQData API</span>
                <ExternalLink
                  href={`https://iqdata.social/audience-data?urlCopy=${channel.name}&platform=instagram`}
                >
                  Full report
                </ExternalLink>
              </div>

              <div className="row">
                <div className="col-5">
                  <label htmlFor="metricsValidAt" className="text-muted">
                    Report date
                  </label>
                  <Field
                    id="metricsValidAt"
                    name="metricsValidAt"
                    component={DateInput}
                    parse={toISOString}
                    isOutsideRange={() => false}
                  />
                </div>
                <div className="col-7 key-metrics-manual-refresh">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleRefreshMetrics}
                    disabled={this.state.isRefreshing}
                  >
                    {this.state.isRefreshing ? 'Refreshing…' : 'Refresh'}
                  </button>
                  <span className="text-muted">{this.getRefreshLabel()}</span>
                </div>
              </div>

              {/* Followers & Followers History */}
              <div className="row form-group">
                <div className="col-4">
                  <Field
                    className="form-control no-spin-buttons"
                    name="followers"
                    type="number"
                    label="Followers"
                    parse={parseToInt}
                    component={this.renderField}
                  />
                </div>
                <div className="col-8 d-flex justify-content-between flex-column">
                  <label className="text-muted" htmlFor="followersHistory">
                    Followers History
                  </label>
                  <TagsInput
                    id="followersHistory"
                    name="followersHistory"
                    wrap={false}
                    readOnly
                    renderTag={({ input }) => (
                      <div className="tag badge-disabled">
                        <span className="mr-1">{input.value.followers}</span>
                        <small className="last-updated-label mt-1">
                          {moment(input.value.month).format('DD/MM/YYYY')}
                        </small>
                      </div>
                    )}
                  />
                </div>
              </div>

              {/* Engagement Rate & Impression Rates */}
              <div className="row form-group">
                <div className="col-4">
                  <Field
                    className="form-control no-spin-buttons"
                    name="engagementRate"
                    type="number"
                    label="Engagement Rate"
                    showPercentage
                    component={this.renderField}
                    parse={floatStrToPercent}
                    normalize={normalizePercent}
                    format={percentToFloatStr}
                  />
                </div>
                <div className="col-4">
                  <Field
                    className="form-control no-spin-buttons"
                    name="storyImpressionRatio"
                    type="number"
                    label="Impression Rate Stories"
                    showPercentage
                    component={this.renderField}
                    parse={floatStrToPercent}
                    normalize={normalizePercent}
                    format={percentToFloatStr}
                    readOnly
                  />
                </div>
                <div className="col-4">
                  <Field
                    className="form-control no-spin-buttons"
                    name="postImpressionRatio"
                    type="number"
                    label="Impression Rate Posts"
                    showPercentage
                    component={this.renderField}
                    parse={floatStrToPercent}
                    normalize={normalizePercent}
                    format={percentToFloatStr}
                    readOnly
                  />
                </div>
              </div>

              {/* Countries */}
              <span className="text-muted">Countries</span>
              {this.renderCountries()}

              {/* Genders */}
              <span className="text-muted">Genders</span>
              <div className="row form-group">
                <div className="col-2">
                  <Field
                    className="form-control no-spin-buttons"
                    name="audience.gender.female"
                    type="number"
                    label="Female"
                    smallLabel
                    showPercentage
                    component={this.renderField}
                    parse={floatStrToPercent}
                    normalize={normalizePercent}
                    format={percentToFloatStr}
                  />
                </div>
                <div className="col-2">
                  <Field
                    className="form-control no-spin-buttons"
                    name="audience.gender.male"
                    type="number"
                    label="Male"
                    smallLabel
                    showPercentage
                    component={this.renderField}
                    parse={floatStrToPercent}
                    normalize={normalizePercent}
                    format={percentToFloatStr}
                  />
                </div>
              </div>

              {/* Age */}
              <span className="text-muted">Age</span>
              <div className="row form-group with-padding">
                {ageGroups.map((group, index) => (
                  <div
                    key={group}
                    className={cx('col no-padding', {
                      'mr-4': index !== ageGroups.length - 1,
                    })}
                  >
                    <Field
                      className="form-control no-spin-buttons"
                      name={`audience.ageRange.${group}`}
                      smallLabel
                      type="number"
                      showPercentage
                      label={group}
                      component={this.renderField}
                      parse={floatStrToPercent}
                      normalize={normalizePercent}
                      format={percentToFloatStr}
                    />
                  </div>
                ))}
              </div>

              <div className="d-flex align-items-center">
                <input
                  type="submit"
                  className={cx('btn btn-primary', {
                    'btn-primary': !requestInsightsState.insightsSubmitted,
                    'btn-success':
                      requestInsightsState.insightsSubmitted && pristine,
                  })}
                  value="Save"
                  disabled={pristine || requestInsightsState.insightsSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

InstagramInsights.propTypes = {
  ...propTypes,
  requestInsightsState: PropTypes.shape({
    insightsSubmitting: PropTypes.bool,
    insightsSubmitted: PropTypes.bool,
  }),
  channel: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDeleteScreenshot: PropTypes.func.isRequired,
  onRefreshMetrics: PropTypes.func.isRequired,
};

export default reduxForm({
  enableReinitialize: true,
  form: 'channels/InstagramInsights',
})(InstagramInsights);
