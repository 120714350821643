import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import noop from 'lodash/noop';

import TodoDetail from '../detail';

function ActionSubmitButton({
  todoActionAsync: { loaded, loading },
  children,
  onClick,
  className,
}) {
  const buttonClass = cx('btn', 'btn-primary', className, {
    'bg-warning': loading,
    'bg-success': loaded,
  });

  return (
    <button
      type="button"
      className={buttonClass}
      onClick={onClick}
      disabled={loading}
    >
      {children}
    </button>
  );
}

ActionSubmitButton.propTypes = {
  todoActionAsync: PropTypes.shape({
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
  }),
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

ActionSubmitButton.defaultProps = {
  todoActionAsync: {},
  className: '',
  onClick: noop,
  children: undefined,
};

function Action({ title, label, onSubmit, todoActionAsync }) {
  return (
    <TodoDetail title={title} noColon>
      <ActionSubmitButton onClick={onSubmit} todoActionAsync={todoActionAsync}>
        {label}
      </ActionSubmitButton>
    </TodoDetail>
  );
}

Action.propTypes = {
  title: PropTypes.any,
  label: PropTypes.node.isRequired,
  todoActionAsync: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
};

Action.defaultProps = {
  title: undefined,
  todoActionAsync: undefined,
};

export default Action;
