import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import UserStatusPopup from 'source/scenes/groups/components/userStatus/userStatusPopup';
import { STATUS_LABELS } from 'source/scenes/groups/constants';

class UserStatus extends React.PureComponent {
  state = {
    isPopupOpen: false,
  };

  getStatusLabel = (status) => get(STATUS_LABELS, `${status}`, 'N/A');

  togglePopup = (e) => {
    e && e.preventDefault();
    const { isPopupOpen } = this.state;

    this.setState({ isPopupOpen: !isPopupOpen });
  };

  handleUpdateStatus = (status) => {
    const { onChangeGroupUserStatus, group, user } = this.props;
    onChangeGroupUserStatus(group.id, user.id, status);
    this.togglePopup();
  };

  render() {
    const { user } = this.props;

    return (
      <div className="select-group-status">
        <a
          href="#"
          className="group-list-status-btn"
          onClick={this.togglePopup}
        >
          <div className="d-flex align-items-center">
            <span
              className={`group-status-indicator ${user.status}`}
              style={{ marginRight: 5 }}
            />
            <span>{this.getStatusLabel(user.status)}</span>
          </div>
          <div className="arrows d-flex flex-column ml-1">
            <span className="arrow-up">▾</span>
            <span className="arrow-down">▾</span>
          </div>
        </a>
        {this.state.isPopupOpen && (
          <UserStatusPopup
            onClose={this.togglePopup}
            onStatusSelected={this.handleUpdateStatus}
            currentStatus={user.status}
            user={user}
          />
        )}
      </div>
    );
  }
}

UserStatus.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string,
  }).isRequired,
  group: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onChangeGroupUserStatus: PropTypes.func.isRequired,
};

export default UserStatus;
