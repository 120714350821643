import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import without from 'lodash/without';
import { combineReducers, compose } from 'redux';
import { asyncActionReducer, asyncStates } from '@blogfoster/redux-async-utils';
import {
  namespacedReducer,
  resetableReducer,
  nestedReducer,
  keyValueStoreReducer,
  combineReducersFlat,
} from 'source/utils/redux';
import * as Sentry from '@sentry/browser';

import { actionTypes as applicationActionTypes } from 'source/data/applications/actions';
import { checkboxReducer } from 'source/scenes/components/checkbox/redux';
import applicationPanelReducer from '../applicationDetail/reducer';
import { namespace, actionTypes } from './actions';

export const selectors = {
  getApplicationsSceneState: (state) => state,
  getApplicationIds: (state) => state.applicationIds,
  getApplicationPanel: (state, props) =>
    state.applicationPanelsById[props.applicationId] || {},
};

const notMigratedChannel = (channel) => !channel.hidden;
const notSameChannels = (channel1, channel2) => channel1.id !== channel2.id;

const applicationsFetchReducer = asyncActionReducer(
  applicationActionTypes.FETCH_MANY,
  {
    [asyncStates.success]: (state, { payload }) => {
      const {
        overviewFilter: { showRejected },
        loadedRejected,
      } = state;

      const newApplications = [...payload.data];

      // filter away migrated and equal channels from other user's channels
      newApplications.forEach((application) => {
        application.channels = application.channels.filter(
          (channel) =>
            notMigratedChannel(channel) &&
            notSameChannels(channel, application.channel),
        );
      });

      const applications = [...state.data, ...newApplications];

      Sentry.addBreadcrumb({
        category: 'reducer',
        message: applicationActionTypes.FETCH_MANY,
        level: Sentry.Severity.Debug,
      });

      if (!isEmpty(state.data) && !isEmpty(newApplications) && !showRejected) {
        Sentry.withScope((scope) => {
          scope.setExtra(
            'existingCampaignIds',
            keys(keyBy(state.data, 'campaignId')),
          );
          scope.setExtra(
            'newCampaignIds',
            keys(keyBy(newApplications, 'campaignId')),
          );
          Sentry.captureMessage(
            `Encountered existing applications while merging new applications to state on applicationsOverview!`,
            'debug',
          );
        });
      }

      // create map to avoid application repetitions
      const applicationsById = {
        ...keyBy(applications, 'id'),
      };

      return {
        ...state,
        data: Object.values(applicationsById),
        applicationIds: Object.keys(applicationsById),
        loading: false,
        loaded: true,
        loadedRejected: loadedRejected || showRejected,
      };
    },
    [asyncStates.pending]: (state) => ({
      ...state,
      loading: true,
    }),
  },
);

const applicationLoadReducer = asyncActionReducer(
  applicationActionTypes.FETCH,
  {
    [asyncStates.success]: (state, { payload }) => {
      const newApplication = payload.data;

      const newApplicationData = state.data.map((application) => {
        if (application.id === newApplication.id) {
          return {
            ...application,
            ...newApplication,
          };
        }
        return application;
      });

      return {
        ...state,
        data: newApplicationData,
        loading: false,
        loaded: true,
      };
    },
    [asyncStates.pending]: (state) => ({
      ...state,
      loading: true,
    }),
  },
);

// Since we store all application panel states separately, we need to
// "instantiate" the panel view state when the applications are loaded
const applicationPanelInitReducer = namespacedReducer(namespace)(
  asyncActionReducer(
    applicationActionTypes.FETCH_MANY,
    {
      [asyncStates.success]: (state, { payload }) =>
        payload.data.reduce(
          (acc, { id }) => {
            acc[id] = applicationPanelReducer(undefined, { type: '@@INIT' });
            return acc;
          },
          { ...state },
        ),
    },
    {},
  ),
);

const applicationPanelsByIdReducer = combineReducersFlat(
  [applicationPanelInitReducer, keyValueStoreReducer(applicationPanelReducer)],
  {},
);

const applicationUpdateReducer = asyncActionReducer(
  applicationActionTypes.UPDATE,
  {
    [asyncStates.success]: (state, { payload }) => {
      const newApplication = payload.data;

      const applications = state.data.map((application) =>
        application.id === newApplication.id
          ? {
              ...application,
              tasks: newApplication.tasks,
              status: newApplication.status,
              pitchEdits: newApplication.pitchEdits,
              assignment: newApplication.assignment,
              productShipment: newApplication.productShipment,
              kamNotes: newApplication.kamNotes,
            }
          : application,
      );

      return {
        ...state,
        data: applications,
      };
    },
  },
);

const applicationDeleteReducer = asyncActionReducer(
  applicationActionTypes.DELETE,
  {
    [asyncStates.success]: (state, { key: deletedApplicationId }) => ({
      ...state,
      applicationIds: without(state.applicationIds, deletedApplicationId),
      applicationPanelsById: omit(state.applicationPanelsById, [
        deletedApplicationId,
      ]),
      data: state.data.filter(
        (application) => application.id !== deletedApplicationId,
      ),
    }),
  },
);

const overviewFilterReducer = combineReducers({
  showRejected: checkboxReducer(`${namespace}/showRejected`),
  showApproved: checkboxReducer(`${namespace}/showApproved`),
});

const getInitialState = () => ({
  data: [],
  loading: false,
  loaded: false,
  loadedRejected: false,
  applicationIds: [],
  applicationPanelsById: {},
  overviewFilter: { showRejected: false },
});

export default compose(resetableReducer(actionTypes.RESET_SCENE))(
  combineReducersFlat(
    [
      namespacedReducer(namespace)(applicationsFetchReducer),
      namespacedReducer(namespace)(applicationLoadReducer),
      namespacedReducer(namespace)(applicationUpdateReducer),
      namespacedReducer(namespace)(applicationDeleteReducer),
      nestedReducer('applicationPanelsById')(applicationPanelsByIdReducer),
      nestedReducer('overviewFilter')(overviewFilterReducer),
    ],
    getInitialState(),
  ),
);
