import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import Octicon from 'react-octicon';

import withResetScene from 'source/components/commonEnhancers/withResetScene';
import { ProgressBar, SearchBar, Alert } from 'source/components/common';

import ChannelsTable from './channelsTable';
import actions from '../actions';

import { getChannelsState } from '../selectors';

const enhance = compose(connect(getChannelsState, actions), withResetScene);

class ChannelsList extends Component {
  componentDidMount() {
    const { onMount } = this.props;
    return onMount();
  }

  componentWillUnmount() {
    const { alert, onAlertClose } = this.props;
    if (alert) {
      onAlertClose();
    }
  }

  handleSearchChange = (term) => {
    const { onFilterChange } = this.props;
    return onFilterChange(term);
  };

  handleSearch = () => {
    const { onMount } = this.props;
    return onMount(0);
  };

  handleSelectPage = (page) => {
    this.props.onSelectPage(page);
  };

  renderChannelsTable() {
    const { channels, pages, page } = this.props;

    return (
      <div className="col">
        <ChannelsTable
          channels={channels}
          pages={pages}
          page={page}
          onSelectPage={this.handleSelectPage}
        />
      </div>
    );
  }

  render() {
    const { state, counter, filters, alert, onAlertClose } = this.props;
    const stateLoaded = state === 'loaded';

    return (
      <div className="container">
        <Alert {...alert} onClose={onAlertClose} />
        <div className="row">
          <div className="col">
            <h2 className="pb-2">
              Channels
              <span className="ml-2">
                ({counter.count}/{counter.total})
              </span>
            </h2>
          </div>
        </div>

        <div className="row pt-2">
          <SearchBar
            className="col d-flex"
            formClassName="flex-grow"
            inputClassName="form-control"
            query={filters.term}
            heading=""
            placeholder="Channel name, url or id"
            onInputChange={this.handleSearchChange}
            onSubmit={this.handleSearch}
          >
            <Link
              to="/channels/search"
              className="btn btn-outline-primary ml-4"
            >
              Advanced Filters
              <Octicon name="settings" className="ml-1" />
            </Link>
          </SearchBar>
        </div>

        <div className="row pt-4">
          {stateLoaded ? this.renderChannelsTable() : <ProgressBar.Mega />}
        </div>
      </div>
    );
  }
}

ChannelsList.propTypes = {
  alert: PropTypes.object,
  state: PropTypes.string.isRequired,
  channels: PropTypes.any.isRequired,
  pages: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  filters: PropTypes.shape({
    term: PropTypes.string.isRequired,
  }).isRequired,
  counter: PropTypes.shape({
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  onAlertClose: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
};

export default enhance(ChannelsList);
