import { asyncActionCreator } from '@blogfoster/redux-async-utils';

import { actionAuthRequest } from 'source/utils/axios';

export const actionTypes = {
  CREATE: 'data/payments/CREATE',
  CREATE_MANUAL: 'data/payments/CREATE_MANUAL',
  FETCH_MANY: 'data/payments/FETCH_MANY',
};

export const paymentCreate = (name) =>
  asyncActionCreator(
    actionTypes.CREATE,
    (applicationId) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false }).post(
        `/v2/payments/applications/${applicationId}`,
      ),
    { name },
  );

// Export generic versions of these action creators

export const createPayment = paymentCreate('default');

export const paymentCreateManual = (name) =>
  asyncActionCreator(
    actionTypes.CREATE_MANUAL,
    (payload) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false }).post(
        `/v2/payments`,
        payload,
      ),
    { name },
  );

export const paymentFetchMany = (name) =>
  asyncActionCreator(
    actionTypes.FETCH_MANY,
    ({ page = 0, fields = [], filters = [] }) =>
      (dispatch, getState) => {
        const params = { page: page + 1 };

        if (filters && filters.length > 0) {
          params.filters = JSON.stringify(filters);
        }

        if (fields && fields.length > 0) {
          params.fields = JSON.stringify(fields);
        }

        return (
          actionAuthRequest(dispatch, getState, { dispatchErrors: false })
            .get('/v2/payments', { params })
            // HACKY: to save the page correctly we inject a page header to the response
            .then((resp) => {
              resp.headers['x-page'] = page;
              return resp;
            })
        );
      },
    { name },
  );
