import { asyncActionReducer, asyncStates } from '@blogfoster/redux-async-utils';
import defaults from 'lodash/defaults';

import { createStorage } from 'source/utils';
import {
  namespacedReducer,
  nestedReducer,
  combineReducersFlat,
} from 'source/utils/redux';
import { actionTypes as campaignActionTypes } from 'source/data/campaigns/actions';
import { getPagingFromHeaders } from 'source/components/common/pagination';

import { namespace, actionTypes } from './actions';

export const getInitialState = () => {
  const filters = createStorage().read('campaigns/filters');

  return {
    state: '',
    error: null,

    // gives the shown campaigns an order
    campaignIds: [],

    paging: {
      page: 0,
      pageSize: 30,
      count: 0,
      total: 0,
    },

    filters: defaults(filters, {
      term: '',
      showArchived: false,
      manager: '',
      campaignType: 'website',
      allowApplications: '',
    }),
  };
};

const filtersReducer = (state = {}, action) => {
  if (action.type !== actionTypes.FILTER_CHANGED) {
    return state;
  }

  const { filter, value } = action.payload;

  return {
    ...state,
    [filter]: value,
  };
};

const campaignDataReducer = asyncActionReducer(campaignActionTypes.FETCH_MANY, {
  [asyncStates.pending]: (state) => ({
    ...state,
    state: 'loading',
    error: null,
  }),
  [asyncStates.success]: (state, { payload }) => ({
    ...state,
    state: 'loaded',
    error: null,
    campaignIds: payload.data.map((campaign) => campaign.id),
    paging: getPagingFromHeaders(payload.headers),
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    state: 'failure',
    error,
  }),
});

const resetState = (state = {}, action) => {
  if (action.type !== actionTypes.RESET_SCENE) {
    return state;
  }

  return {
    ...state,
    state: '',
    error: null,
    campaignIds: [],
  };
};

const initialState = getInitialState();

export default combineReducersFlat(
  [
    namespacedReducer(namespace)(campaignDataReducer),
    resetState,
    nestedReducer('filters')(filtersReducer),
  ],
  initialState,
);
