import PropTypes from 'prop-types';
import React from 'react';
import '../../scss/screen.scss';

function Root({ children }) {
  return <div className="fullscreen">{children}</div>;
}

Root.propTypes = {
  children: PropTypes.node,
};

export default Root;
