import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import { ProgressBar } from 'source/components/common';
import {
  EditableInputLink,
  StaticLink,
  EditableInputSelect,
  SaveOrCancelButton,
  EditRecordButton,
} from 'source/components/common/inputs';
import {
  getNormalizedUsers,
  getNormalizedCampaigns,
} from 'source/selectors/links';
import {
  loadLinkDetailsState,
  editLink,
  editLinkForm,
  submitEditedLink,
  cancelEditLink,
  resetLinkDetails,
} from 'source/actions/links';

class LinkDetails extends React.Component {
  componentDidMount() {
    this.props.onMount(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.onResetLinkDetails();
  }

  handleEdit = (e) => {
    e.preventDefault();

    this.props.onEdit();
  };

  handleSave = (e) => {
    e.preventDefault();

    this.props.onSubmit(this.props.form);
  };

  handleCancel = (e) => {
    const { form, onCancel } = this.props;

    e.preventDefault();
    return onCancel(form);
  };

  handleChange = (id, value) => this.props.onChange(this.props.form, id, value);

  renderForm() {
    const { edit, form, errors, users, campaigns } = this.props;

    return (
      <form>
        <StaticLink id="url" name="URL" value={form.url.value} />

        <EditableInputLink
          id="targetUrl"
          name="Target URL"
          type="url"
          error={errors.targetUrl}
          value={form.targetUrl.value}
          onChange={this.handleChange}
          edit={edit}
        />

        <EditableInputSelect
          id="user"
          name="User"
          options={users}
          value={form.user.value}
          error={errors.user}
          onChange={this.handleChange}
          edit={edit}
        />

        <EditableInputSelect
          id="campaign"
          name="Campaign"
          options={campaigns}
          value={form.campaign.value}
          error={errors.campaign}
          onChange={this.handleChange}
          edit={edit}
        />

        <div className="row justify-content-end pr-3">
          {edit ? (
            <SaveOrCancelButton
              onSave={this.handleSave}
              onCancel={this.handleCancel}
              saveBtnProps={{
                disabled: !isEmpty(errors),
                className: cx({ 'cursor-not-allowed': !isEmpty(errors) }),
              }}
            />
          ) : (
            <EditRecordButton onClick={this.handleEdit} />
          )}
        </div>
      </form>
    );
  }

  render() {
    const { stateLoaded } = this.props;

    return (
      <div className="container">
        <h2>Link Details</h2>
        <p className="text-muted">
          View and change details about the given link.
        </p>

        <hr />

        {stateLoaded ? this.renderForm() : <ProgressBar.Mega />}
      </div>
    );
  }
}

LinkDetails.propTypes = {
  stateLoaded: PropTypes.bool.isRequired,
  edit: PropTypes.any,
  form: PropTypes.any,
  errors: PropTypes.any,
  users: PropTypes.array.isRequired,
  campaigns: PropTypes.array.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onEdit: PropTypes.any,
  onChange: PropTypes.any,
  onSubmit: PropTypes.any,
  onCancel: PropTypes.any,
  onResetLinkDetails: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  stateLoaded: state.links.linkDetails.stateLoaded,
  edit: state.links.linkDetails.edit,
  form: state.links.linkDetails.form,
  errors: state.links.linkDetails.errors,
  users: getNormalizedUsers(state.links),
  campaigns: getNormalizedCampaigns(state.links),
});

const mapDispatchToProps = {
  onEdit: editLink,
  onChange: editLinkForm,
  onSubmit: submitEditedLink,
  onCancel: cancelEditLink,
  onMount: loadLinkDetailsState,
  onResetLinkDetails: resetLinkDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkDetails);
