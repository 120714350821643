import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { compose, withPropsOnChange, setPropTypes, lifecycle } from 'recompose';
import { scroller, Element } from 'react-scroll';

import { CollapsiblePanel } from 'source/components/common';
import InfoCard, { InfoCardContainer } from 'source/components/common/infoCard';

import MissionHeadline from './missionHeadline';
import Mission from './mission';
import MissionOptions from './missionOptions';

/* CampaignMissionsList */
function MissionsListEmpty() {
  return (
    <InfoCardContainer>
      <InfoCard iconName="info" title="No missions so far.">
        Once there are any missions for this campaign, you will see them here.
      </InfoCard>
    </InfoCardContainer>
  );
}

const enhanceMissionsList = compose(
  setPropTypes({
    match: PropTypes.shape({
      params: PropTypes.shape({
        applicationId: PropTypes.string,
      }).isRequired,
    }).isRequired,
    onExpandMission: PropTypes.func.isRequired,
  }),
  /**
   * FEATURE
   *    allow one mission panel to be opened through the url param
   */
  withPropsOnChange(['match'], ({ match, onExpandMission }) => {
    if (match.params && match.params.applicationId) {
      onExpandMission(match.params.applicationId);
    }
  }),
  /**
   * FEATURE
   *    once the component is mounted, if we have an `application` id via
   *    router `params` set, we should scroll to it
   */
  lifecycle({
    componentDidMount() {
      if (this.props.match.params.applicationId) {
        scroller.scrollTo(`mission-${this.props.match.params.applicationId}`, {
          duration: 0,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      }
    },
  }),
);

function _MissionsList({
  applications,
  campaign,
  missions,
  expanded,
  onExpandMission,
  getHeading,
  getSubheading,
  ...props
}) {
  return (
    <div>
      {applications.map((application, index) => (
        <Element key={application.id} name={`mission-${application.id}`}>
          <CollapsiblePanel
            index={index + 1}
            id={`mission-${application.id}`}
            headline={
              <MissionHeadline
                application={application}
                campaign={campaign}
                heading={getHeading(application)}
                subHeading={getSubheading(application)}
              />
            }
            bodyClassName="mission-panel-body"
            onClick={onExpandMission}
            expanded={expanded[application.id] || false}
            className="mb-3"
          >
            <Mission
              {...props}
              {...(missions[application.id] || {})}
              application={application}
              campaign={campaign}
            />
          </CollapsiblePanel>
        </Element>
      ))}
    </div>
  );
}

_MissionsList.propTypes = {
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  campaign: PropTypes.shape({
    type: PropTypes.string.isRequired,
    pricing: PropTypes.string.isRequired,
  }).isRequired,
  missions: PropTypes.object.isRequired,
  expanded: PropTypes.object.isRequired,
  onExpandMission: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      applicationId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  getHeading: PropTypes.func.isRequired,
  getSubheading: PropTypes.func.isRequired,
};

const MissionsList = enhanceMissionsList(_MissionsList);

function CampaignMissionsList({
  applications,
  campaign,
  counter,
  onListChange,
  listForms,
  ...props
}) {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h2>
            Influencers on Mission{' '}
            <span>
              ({counter.count}/{counter.total})
            </span>
          </h2>
        </div>
      </div>

      <MissionOptions {...props} onChange={onListChange} forms={listForms} />

      <div className="row mb-5">
        <div className="col">
          {isEmpty(applications) ? (
            <MissionsListEmpty />
          ) : (
            <MissionsList
              {...props}
              applications={applications}
              campaign={campaign}
            />
          )}
        </div>
      </div>
    </div>
  );
}

CampaignMissionsList.propTypes = {
  applications: PropTypes.array.isRequired,
  campaign: PropTypes.shape({
    type: PropTypes.string.isRequired,
    pricing: PropTypes.string.isRequired,
  }).isRequired,
  counter: PropTypes.shape({
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  onListChange: PropTypes.func.isRequired,
  listForms: PropTypes.object.isRequired,
};

export default CampaignMissionsList;
