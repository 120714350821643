import React from 'react';
import { connect } from 'react-redux';
import { withProps, branch, mapProps, compose } from 'recompose';

import CategoriesForm from '../../categorySelection';
import selector from './selectors';
import { withSubmissionHandler } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';

// HoCs

const withCategoriesGuard = branch(
  ({ iabCategories }) => iabCategories.state !== 'loaded',
  () =>
    function () {
      return <div>Loading categories...</div>;
    },
);

const withCategoriesData = withProps(({ iabCategories }) => ({
  iabCategories: iabCategories.data,
}));

const withPropsMapping = mapProps(({ iabCategories, ...props }) => ({
  ...props,
  categories: iabCategories,
}));

const enhance = compose(
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
  withCategoriesGuard,
  withCategoriesData,
  withPropsMapping,
);

// Components

export default enhance(CategoriesForm);
