import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Event, toColor } from '../components';
import { getContentPreviewReviewUrl } from '../utils';

const { Avatar, ContentContainer, EventHeadline, EventEdits, EventBodyButton } =
  Event;

function EventContentPreviewWebsite({
  createdAt,
  issuer,
  title,
  content,
  initials,
  campaign,
  references,
}) {
  return (
    <div className={cx('row', `${issuer.type}-action`)}>
      {/* Image */}
      <Avatar
        name={initials}
        color={issuer.type === 'user' ? toColor(initials) : null}
      />

      {/* Message */}
      <ContentContainer>
        <EventHeadline title={title} createdAt={createdAt} />
        <EventBodyButton
          caption="Review Article"
          url={getContentPreviewReviewUrl({
            campaign,
            references,
            version: content.version,
          })}
        />
        <EventEdits text={issuer.type === 'user' ? content.article : null} />
      </ContentContainer>
    </div>
  );
}

EventContentPreviewWebsite.propTypes = {
  createdAt: PropTypes.instanceOf(Date).isRequired,
  issuer: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.shape({
    version: PropTypes.number.isRequired,
    article: PropTypes.string.isRequired,
  }).isRequired,
  initials: PropTypes.string.isRequired,
  campaign: PropTypes.object.isRequired,
  references: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
    applicationId: PropTypes.string.isRequired,
  }).isRequired,
};

export default EventContentPreviewWebsite;
