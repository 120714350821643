import * as campaignDetailImport from './campaignDetail/containers';
import * as campaignsImport from './campaigns/containers';
import * as invoicesImport from './invoices/containers';
import * as paymentsImport from './payments/containers';
import * as channelSearchImport from './channelsAdvanced/containers';
import * as channelsImport from './channels/containers';
import * as usersImport from './users/containers';
import * as groupsImport from './groups/containers';
import * as rootImport from './root';
import * as ErrorPageImport from './errorPage';

export const campaignDetail = campaignDetailImport;
export const campaigns = campaignsImport;
export const channels = channelsImport;
export const users = usersImport;
export const groups = groupsImport;
export const invoices = invoicesImport;
export const payments = paymentsImport;
export const channelSearch = channelSearchImport;
export const ErrorPage = ErrorPageImport.default;
export const Root = rootImport.default;
