import React from 'react';
import PropTypes from 'prop-types';
import set from 'lodash/set';
import unset from 'lodash/unset';
import moment from 'moment';
import cx from 'classnames';
import { withProps, compose } from 'recompose';
import { connect } from 'react-redux';
import {
  reduxForm,
  Field,
  SubmissionError,
  propTypes,
  formValueSelector,
} from 'redux-form';

import { LabeledSelect } from 'source/scenes/components/labeledInputs';
import { Textarea, Toggle } from 'source/scenes/components/reduxFormAdapters';

const formName = 'applicationReview/applicationPanel/individualAssignment';

export const formSelector = formValueSelector(formName);

const withRedux = connect((state, { data: publishingDates, application }) => {
  const { publishingDateId } = application.assignment;
  const hasAssignedDate = !!publishingDateId;
  const hasAssignedTerms = !!application.assignment.additionalTerms;

  return {
    hasAssignedDate,
    hasAssignedTerms,
    showVipDates: formSelector(state, 'showVipDates'),
    showAdditionalTerms: formSelector(state, 'showAdditionalTerms'),
    isApplicationMissionAccepted: application.mission.status === 'confirmed',
    // Filter out all publishing dates which are assigned to other applications
    publishingDates: (publishingDates || []).filter(
      (publishDate) =>
        !publishDate.assigned || publishDate.id === publishingDateId,
    ),
    initialValues: {
      showVipDates: hasAssignedDate,
      showAdditionalTerms: hasAssignedTerms,
      assignment: {
        publishingDateId,
        additionalTerms: application.assignment.additionalTerms,
      },
    },
  };
});

const withForm = reduxForm({
  form: formName,
  touchOnBlur: true,
  touchOnChange: true,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate: ({ assignment = {} }) => {
    const error = {};

    if (!assignment.publishingDateId) {
      set(
        error,
        'assignment.publishingDateId',
        'Please select a publishing date',
      );
    }
    if (!assignment.additionalTerms) {
      set(error, 'assignment.additionalTerms', 'Add additional terms');
    }
    return error;
  },
});

const withSubmit = withProps(
  ({
    onUpdateApplication,
    application,
    showVipDates,
    showAdditionalTerms,
  }) => ({
    onSubmit: ({ assignment }) => {
      !showVipDates && unset(assignment, 'publishingDateId');
      !showAdditionalTerms && unset(assignment, 'additionalTerms');

      return onUpdateApplication(application.id, { assignment }).then(
        (result) => {
          if (result.statusCode >= 400) {
            throw new SubmissionError({
              _error: `Error assigning publish date: ${result.message}`,
            });
          }
        },
      );
    },
  }),
);

function IndividualAssignmentForm({
  error,
  pristine,
  submitting,
  submitSucceeded,
  handleSubmit,
  onSubmit,
  loaded,
  application,
  publishingDates,
  onLoadPublishingDates,
  showVipDates,
  showAdditionalTerms,
  hasAssignedDate,
  isApplicationMissionAccepted,
}) {
  React.useEffect(() => {
    if (showVipDates) {
      onLoadPublishingDates(application.id, application.campaignId);
    }
  }, [showVipDates, application.id]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-5 d-flex align-items-center">
          <Field
            name="showVipDates"
            component={Toggle}
            disabled={hasAssignedDate || isApplicationMissionAccepted}
          />
          <div className="ml-2">
            <div>VIP publishing date</div>
            <div className="small">
              Assign a specific date to this influencer as their only option for
              publication
            </div>
          </div>
        </div>
        <div className="col-7 form-row">
          <div className="col-4">
            {showVipDates && (
              <Field
                className="m-0"
                name="assignment.publishingDateId"
                component={LabeledSelect}
              >
                <option value="">---</option>
                {publishingDates.map(({ id, date }) => (
                  <option key={id} value={id}>
                    {moment.utc(date).format('ddd DD/MM/YYYY')}
                  </option>
                ))}
              </Field>
            )}
          </div>
          <div className="col-8">
            <div className="text-danger mb-3">
              {showVipDates &&
                loaded &&
                publishingDates.length === 0 &&
                'No available VIP publishing dates found. Please make sure adequate VIP dates are configured for the campaign.'}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-5 d-flex align-items-start">
          <Field
            name="showAdditionalTerms"
            component={Toggle}
            disabled={isApplicationMissionAccepted}
          />
          <div className="ml-2">
            <div>Additional terms</div>
            <div className="small">
              Define additional legal terms just for this application
            </div>
          </div>
        </div>
        <div className="col-7 form-row">
          <div className="col-12">
            {showAdditionalTerms && (
              <Field
                name="assignment.additionalTerms"
                component={Textarea}
                rows="5"
                readOnly={isApplicationMissionAccepted}
              />
            )}
          </div>
        </div>
      </div>

      {error && (
        <div className="row mt-2">
          <div className="col form-row ">
            <div className="alert alert-danger w-100">{error}</div>
          </div>
        </div>
      )}

      {(showVipDates || showAdditionalTerms) && (
        <div className="row mt-2">
          <div className="col form-row ">
            <div className="col d-flex justify-content-end">
              <button
                type="submit"
                disabled={submitting || pristine}
                className={cx('btn', 'btn-primary', {
                  'btn-success': submitSucceeded && !submitting && pristine,
                })}
              >
                {
                  // eslint-disable-next-line no-nested-ternary
                  submitting
                    ? 'Saving'
                    : submitSucceeded && pristine
                    ? 'Saved'
                    : 'Save'
                }
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

IndividualAssignmentForm.propTypes = {
  ...propTypes,
  loaded: PropTypes.bool.isRequired,
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    campaignId: PropTypes.string.isRequired,
    assignment: PropTypes.shape({
      publishingDateId: PropTypes.string,
      additionalTerms: PropTypes.string,
    }),
  }).isRequired,
  onLoadPublishingDates: PropTypes.func.isRequired,
  // from connect
  hasAssignedDate: PropTypes.bool.isRequired,
  showVipDates: PropTypes.bool,
  showAdditionalTerms: PropTypes.bool,
  isApplicationMissionAccepted: PropTypes.bool.isRequired,
  publishingDates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

IndividualAssignmentForm.defaultProps = {
  showVipDates: false,
  showAdditionalTerms: false,
};

export default compose(
  withRedux,
  withForm,
  withSubmit,
)(IndividualAssignmentForm);
