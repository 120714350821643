import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { loadAppState } from 'source/actions/app';
import { Navigation } from 'source/components/app';
import { ProgressBar } from 'source/components/common';
import ExternalLink from 'source/scenes/components/externalLink';
import { useAccessToken } from 'source/utils/auth';

const useAppState = () =>
  useSelector((state) => ({
    stateLoaded: state.app.stateLoaded,
    user: state.app.user,
  }));

function AppContainer({ children }) {
  const { stateLoaded, user } = useAppState();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = !!useAccessToken();

  const inlineStyle = { paddingTop: '5rem' };

  React.useEffect(() => {
    if (isLoggedIn) {
      dispatch(loadAppState());
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return children;
  }

  return (
    <div className="app">
      <header>
        {stateLoaded && <Navigation user={user} pathname={location.pathname} />}
      </header>

      <div style={inlineStyle} className="pb-5">
        {stateLoaded ? children : <ProgressBar.Mega />}
      </div>

      <footer className="footer">
        <div className="d-flex container">
          <span className="text-muted">
            © blogfoster.com {new Date().getFullYear()}
          </span>
          <span className="ml-auto">
            <ExternalLink
              href={`https://github.com/blogfoster/control-center/commit/${process.env.COMMITHASH}`}
            >
              {process.env.COMMITHASH}
            </ExternalLink>
            -v{process.env.VERSION}
          </span>
        </div>
      </footer>
    </div>
  );
}

AppContainer.propTypes = {
  children: PropTypes.node,
};

AppContainer.defaultProps = {
  children: null,
};

export default AppContainer;
