import React from 'react';
import PropTypes from 'prop-types';
import { ErrorText } from './ErrorText';

export function Root({
  errorMessage,
  errorStack,
  errorCode,
  onBackToDashboard,
}) {
  return (
    <div className="container flex-center">
      <div className="flex-center-item col-md-6">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Sorry, we're having problems</h4>
            <h6 className="card-subtitle text-muted">
              Unfortunately we couldn't resolve your request.
            </h6>
          </div>
          <img
            src="images/error-superman.jpg"
            alt="Card we-re-having-problems"
          />
          <ErrorText
            message={errorMessage}
            stack={errorStack}
            code={errorCode}
          />
          <div className="card-body">
            <div className="row justify-content-end pr-3">
              <button
                className="card-link btn btn-primary"
                onClick={onBackToDashboard}
              >
                Back to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Root.propTypes = {
  errorMessage: PropTypes.node,
  errorStack: PropTypes.node,
  errorCode: PropTypes.node,
  onBackToDashboard: PropTypes.func.isRequired,
};
