import React from 'react';
import PropTypes from 'prop-types';

import { ProgressBar } from 'source/components/common';

import ListFilter from './listFilter';
import List from './list';

/**
 * campaign/applications controller
 */
class CampaignApplications extends React.PureComponent {
  componentDidMount() {
    const {
      match: {
        params: { applicationId },
      },
      campaign: { id: campaignId },
      onExpandApplication,
      onLoadApplications,
    } = this.props;

    if (applicationId) {
      onExpandApplication({ id: applicationId, expanded: true });
    }

    onLoadApplications(campaignId);
  }

  /**
   * NOTE @alexspri - change of /applications[/:applicationId] URL
   *
   *   If the url changed internally (change of /applications[/:applicationId])
   *   we also need to re-evaluate the expanded panels. There are two cases:
   *
   *     1. the `nextId` is undefined (url moved back to /applications)
   *     2. the `nextId` is a different id nows
   *
   *   Caveats:
   *
   *     - If we're on a detailed applications page /applications/123123 the
   *       corresponding panel will be expanded. If you then manually close the
   *       panel the url will still stay the same even if you then open another
   *       panel. This might look quite confusing.
   */
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { applicationId: currentId },
      },
      onExpandApplication,
    } = this.props;
    const {
      match: {
        params: { applicationId: nextId },
      },
    } = nextProps;

    if (currentId !== nextId) {
      if (!nextId) {
        onExpandApplication({ id: currentId, expanded: false });
      } else {
        onExpandApplication({ id: nextId, expanded: true });
      }
    }
  }

  render() {
    const {
      props: {
        counter,
        applications: { loaded, error, data },
        ...props
      },
    } = this;

    if (error) {
      return <div>Error loading applications</div>;
    }

    return (
      <div className="container">
        <div className="row">
          {loaded ? (
            <div className="col">
              <ListFilter {...props} applications={data} />
              <List {...props} applications={data} />
            </div>
          ) : (
            <ProgressBar.Mega />
          )}
        </div>
      </div>
    );
  }
}

CampaignApplications.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  applications: PropTypes.shape({
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.any,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.any,
  }).isRequired,
  // route params
  match: PropTypes.shape({
    params: PropTypes.shape({
      applicationId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  counter: PropTypes.shape({
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  onLoadApplications: PropTypes.func.isRequired,
  // update expanded panel
  onExpandApplication: PropTypes.func.isRequired,
};

export default CampaignApplications;
