import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import difference from 'lodash/difference';
import { reduxForm, Field, propTypes } from 'redux-form';
import { DateInput } from 'source/scenes/components/reduxFormAdapters';
import Countries from 'source/utils/countries';

import {
  toISOString,
  percentToFloatStr,
  floatStrToPercent,
  normalizePercent,
  parseToInt,
} from 'source/utils/reduxForm';

import CountryInput from './countryInput';
import TagsInput from './tagsInput';
import ScreenshotPanel from './screenshotPanel';
import { SCREENSHOTS_NAMES, SCREENSHOTS_PLACEHOLDERS } from '../constants';

const defaultCountries = ['DE', 'AT', 'CH', 'US'];
const countryCodes = Object.keys(Countries).sort();

class TikTokInsights extends React.PureComponent {
  constructor(props) {
    super(props);
    const countries = Object.keys(
      get(props.channel, 'kpi.geoStatistic.countries', {}),
    );
    const diff = difference(defaultCountries, countries);
    this.selectedCountries = [...countries, ...diff].slice(0, 4);
  }

  handleScreenshotDelete = (name) => this.props.onDeleteScreenshot(name);

  updateSelectedCountries = (oldCountry, newCountry) => {
    const index = this.selectedCountries.indexOf(oldCountry);
    this.selectedCountries[index] = newCountry;
  };

  handleCountryChange = (oldCountry, newCountry, value) => {
    this.props.change(`geoStatistic.countries.${oldCountry}`, '');

    if (value) {
      this.props.change(
        `geoStatistic.countries.${newCountry}`,
        floatStrToPercent(value),
      );
    }

    this.updateSelectedCountries(oldCountry, newCountry);
  };

  renderCountries = () => {
    const countriesWithoutSelected = difference(
      countryCodes,
      this.selectedCountries,
    );

    return (
      <div className="d-flex mt-2">
        {this.selectedCountries.map((country, index) => (
          <CountryInput
            key={country}
            objectKey="geoStatistic.countries"
            className={cx({
              'mr-4': index !== this.selectedCountries.length - 1,
            })}
            country={country}
            countries={countriesWithoutSelected}
            onSelectFlag={this.handleCountryChange}
          />
        ))}
      </div>
    );
  };

  renderScreenshots = () => {
    const { channel } = this.props;
    const names = get(SCREENSHOTS_NAMES, channel.platform, []);
    return names.map((screenshotName) => (
      <ScreenshotPanel
        key={screenshotName}
        name={screenshotName}
        placeholderImg={get(
          SCREENSHOTS_PLACEHOLDERS,
          `${channel.platform}.${screenshotName}`,
        )}
        screenshot={get(channel, `screenshots.${screenshotName}`)}
        platform={channel.platform}
        onDelete={this.handleScreenshotDelete}
      />
    ));
  };

  renderField = ({
    className,
    showPercentage,
    input,
    label,
    smallLabel,
    meta: { error },
    ...rest
  }) => {
    const labelElement = smallLabel ? 'small' : 'label';

    return (
      <>
        {React.createElement(
          labelElement,
          { htmlFor: input.name, className: 'text-muted truncate' },
          label,
        )}
        <div
          className={cx('position-relative', {
            percentage: showPercentage && input.value >= 0,
          })}
        >
          <input
            id={input.name}
            className={cx(className, { 'is-invalid': error })}
            {...input}
            {...rest}
          />
          <small className="invalid-feedback">{error}</small>
        </div>
      </>
    );
  };

  render() {
    const { pristine, requestInsightsState, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="panels-grid form-group mb-3">
                {this.renderScreenshots()}
              </div>
            </div>

            {/* KPI */}

            <div className="col-xl-6 col-md-12">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="statisticDate" className="text-muted">
                    Date as of
                  </label>
                  <Field
                    id="statisticDate"
                    name="statisticDate"
                    component={DateInput}
                    parse={toISOString}
                    isOutsideRange={() => false}
                  />
                </div>
              </div>

              {/* Followers and total views */}

              <div className="row form-group">
                <div className="col-6">
                  <Field
                    className="form-control no-spin-buttons"
                    name="viewsTotal"
                    type="number"
                    label="Views from last 28 days"
                    parse={parseToInt}
                    component={this.renderField}
                  />
                </div>
                <div className="col-6">
                  <Field
                    className="form-control no-spin-buttons"
                    name="followers"
                    type="number"
                    label="Followers"
                    parse={parseToInt}
                    component={this.renderField}
                  />
                </div>
              </div>

              {/* Views per video */}

              <div className="form-group">
                <label className="text-muted" htmlFor="viewsPerVideo">
                  Views for video posts from last 7 days
                </label>
                <TagsInput id="viewsPerVideo" name="viewsPerVideo" />
              </div>

              {/* Countries */}

              <span className="text-muted">Countries</span>
              {this.renderCountries()}

              {/* Genders */}

              <span className="text-muted">Genders</span>
              <div className="row form-group">
                <div className="col-2">
                  <Field
                    className="form-control no-spin-buttons"
                    name="audienceStatistic.genders.female"
                    type="number"
                    label="Female"
                    smallLabel
                    showPercentage
                    component={this.renderField}
                    parse={floatStrToPercent}
                    normalize={normalizePercent}
                    format={percentToFloatStr}
                  />
                </div>
                <div className="col-2">
                  <Field
                    className="form-control no-spin-buttons"
                    name="audienceStatistic.genders.male"
                    type="number"
                    label="Male"
                    smallLabel
                    showPercentage
                    component={this.renderField}
                    parse={floatStrToPercent}
                    normalize={normalizePercent}
                    format={percentToFloatStr}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Submit section */}

          <div className="row mt-3">
            <div className="col">
              <div className="d-flex justify-content-end align-items-center">
                <input
                  type="submit"
                  className={cx('btn btn-primary', {
                    'btn-primary': !requestInsightsState.insightsSubmitted,
                    'btn-success':
                      requestInsightsState.insightsSubmitted && pristine,
                  })}
                  value="Save"
                  disabled={pristine || requestInsightsState.insightsSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

TikTokInsights.propTypes = {
  ...propTypes,
  requestInsightsState: PropTypes.shape({
    insightsSubmitting: PropTypes.bool,
    insightsSubmitted: PropTypes.bool,
  }),
  channel: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDeleteScreenshot: PropTypes.func.isRequired,
};

export default reduxForm({
  enableReinitialize: true,
  form: 'channels/TikTokInsights',
})(TikTokInsights);
