import React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { compose } from 'recompose';
import pick from 'lodash/pick';
import noop from 'lodash/noop';
import cx from 'classnames';

const listItemDragType = 'dragDropList/listItem';

/**
 * Determines whether to update the "preview" position
 * when dragging an item over other items
 */
const maybeUpdatePreview = (props, monitor, component) => {
  const dragIndex = monitor.getItem().index;
  const hoverIndex = props.index;

  // Don't replace items with themselves
  if (dragIndex === hoverIndex) {
    return;
  }

  // Determine rectangle on screen
  // eslint-disable-next-line react/no-find-dom-node
  const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  // Determine mouse position
  const clientOffset = monitor.getClientOffset();
  // Get pixels to the top
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;

  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%

  // Dragging downwards
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return;
  }

  // Dragging upwards
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return;
  }

  props.onMoveItem(dragIndex, hoverIndex);

  // Note: we're mutating the monitor item here!
  // Generally it's better to avoid mutations,
  // but it's good here for the sake of performance
  // to avoid expensive index searches.
  monitor.getItem().index = hoverIndex;
};

const DragDropListItem = ({
  className,
  dragIcon,
  isDragging,
  asDragSource,
  asDragPreview,
  asDropTarget,
  children,
}) =>
  asDragPreview(
    asDropTarget(
      <li
        className={cx(
          'list-group-item',
          'draggable-list__item',
          { 'drag-active': isDragging },
          className,
        )}
      >
        <span className="mr-3">{asDragSource(dragIcon)}</span>
        {children}
      </li>,
    ),
  );

DragDropListItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  dragIcon: PropTypes.node.isRequired,
  isDragging: PropTypes.bool.isRequired,
  asDragSource: PropTypes.func.isRequired,
  asDragPreview: PropTypes.func.isRequired,
  asDropTarget: PropTypes.func.isRequired,
  children: PropTypes.any,
};

DragDropListItem.defaultProps = {
  className: '',
  dragIcon: (
    <span
      className="draggable-list__item__handle"
      style={{
        // The following increases the clickable area without effecting
        // the layout flow
        padding: '0.5rem',
        margin: '-0.5rem',
      }}
    >
      ::
    </span>
  ),
  children: null,
};

const withDropTarget = DropTarget(
  listItemDragType,
  { hover: maybeUpdatePreview },
  (connect) => ({
    asDropTarget: connect.dropTarget(),
  }),
);

const withDragSource = DragSource(
  listItemDragType,
  // Used to uniquely identify list items
  { beginDrag: (props) => pick(props, ['id', 'index']) },
  (connect, monitor) => ({
    asDragSource: connect.dragSource(),
    asDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
);

// List item extended with React DnD logic providers
const DragDropListItemDND = compose(
  withDropTarget,
  withDragSource,
)(DragDropListItem);

function DragDropList({ className, dragIcon, onMoveItem, children }) {
  return (
    <ul className={cx('list-group', 'draggable-list', className)}>
      {React.Children.map(children, (child, i) =>
        React.cloneElement(child, {
          index: i,
          dragIcon,
          onMoveItem,
        }),
      )}
    </ul>
  );
}

DragDropList.propTypes = {
  className: PropTypes.string,
  dragIcon: PropTypes.node,
  onMoveItem: PropTypes.func,
  children: PropTypes.any,
};

DragDropList.defaultProps = {
  className: '',
  dragIcon: undefined,
  onMoveItem: noop,
  children: null,
};

DragDropList.Item = DragDropListItemDND;

export default compose(DragDropContext(HTML5Backend))(DragDropList);
