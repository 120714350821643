import React from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  FieldArray,
  Field,
  fieldArrayFieldsPropTypes,
} from 'redux-form';
import cx from 'classnames';

import TaskBadges from './taskBadges';

const renderTasks = (props) => (
  <ul className="list-group">
    {props.fields.map((taskFormName, index) => {
      const task = props.fields.get(index);

      // Note: we don't want to render pre or post tasks, but the backend expects all tasks
      if (task.list !== 'tasks') return null;

      return (
        <li
          className={cx('list-group-item', {
            'list-group-item--disabled': task.completed,
          })}
          key={task.id}
        >
          <div className="form-check">
            <Field
              id={`task-${task.id}`}
              name={`${taskFormName}.completed`}
              component="input"
              type="checkbox"
              className="form-check-input"
            />
            <label className="form-check-label" htmlFor={`task-${task.id}`}>
              <div>
                {task.headline}
                <TaskBadges task={task} />
              </div>
              {task.body && !task.completed && (
                <div>
                  <p className="text-muted">{task.body}</p>
                </div>
              )}
            </label>
          </div>
        </li>
      );
    })}
  </ul>
);

renderTasks.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
};

function TaskListForm() {
  return (
    <form>
      <div className="form-group">
        <FieldArray
          name="tasksCheckList"
          rerenderOnEveryChange
          component={renderTasks}
        />
      </div>
    </form>
  );
}

export default reduxForm()(TaskListForm);
