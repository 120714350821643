import sortBy from 'lodash/sortBy';

// Make IAB categories easier to sort by transforming the codes into numbers
// We multiply the first number with 10000 and add the second number
// IAB3     -> 30000
// IAB3-1   -> 30001
// IAB3-22  -> 30022
// IAB3-X   -> 30099
// IAB11-1  -> 11001
const sortIabCategories = (categories) =>
  sortBy(categories, (category) => {
    const parts = category.code.slice(3).split('-');
    const pre = parts[0];
    let post = parts[1];
    if (!post) post = 0;
    if (post === 'X') post = 99;
    return Number(pre) * 10000 + Number(post);
  });

export default sortIabCategories;
