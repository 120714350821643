import React from 'react';
import PropTypes from 'prop-types';

import ExternalLink from 'source/scenes/components/externalLink';

const stripProtocol = (url) => url.replace(/https?:\/\//, '');

function WebsiteLinksTable({ reports }) {
  return (
    <div className="table-responsive-lg">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Channel</th>
            <th>Target URL</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          <tr className="font-weight-bold">
            <td>totals</td>
            <td>-</td>
            <td>-</td>
          </tr>
          {reports.map((report) => (
            <tr key={`${report.channelName}-${report.url}`}>
              <td style={{ maxWidth: '10rem' }} className="truncate">
                <ExternalLink
                  href={report.channelName}
                  title={report.channelName}
                >
                  {stripProtocol(report.channelName)}
                </ExternalLink>
              </td>
              <td style={{ maxWidth: '10rem' }} className="truncate">
                <ExternalLink href={report.targetUrl} title={report.targetUrl}>
                  {report.targetUrl}
                </ExternalLink>
              </td>
              <td style={{ maxWidth: '10rem' }} className="truncate">
                {report.url}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

WebsiteLinksTable.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      channelName: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      targetUrl: PropTypes.string.isRequired,
      clicks: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default WebsiteLinksTable;
