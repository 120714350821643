import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';
import moment from 'moment';
import noop from 'lodash/noop';

import 'react-dates/lib/css/_datepicker.css';

/**
 * The DateRangePicker component from "react-dates" is by default not stateful
 * and requires state to work. This component wraps the DateRangePicker with
 * a default state but also allows all props to be controlled (best of both
 * worlds).
 */
class DateRangePickerState extends React.Component {
  state = {
    startDate: this.props.startDate,
    startDateId: this.props.startDateId,
    endDate: this.props.endDate,
    endDateId: this.props.endDateId,
    focusedInput: this.props.focusedInput,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const controlProps = Object.keys(this.state);
    const shouldStateChange = controlProps.some(
      (prop) => nextProps[prop] != null && nextProps[prop] !== this.state[prop],
    );

    if (shouldStateChange) {
      const nextState = controlProps.reduce(
        (nextState, prop) => ({
          ...nextState,
          [prop]: nextProps[prop] || this.state[prop],
        }),
        {},
      );
      this.setState(nextState);
    }
  }

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate }, () =>
      this.props.onDatesChange({ startDate, endDate }),
    );
  };

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput }, () =>
      this.props.onFocusChange(focusedInput),
    );
  };

  render() {
    return (
      <DateRangePicker
        {...this.props}
        {...this.state}
        onDatesChange={this.onDatesChange}
        onFocusChange={this.onFocusChange}
      />
    );
  }
}

DateRangePickerState.propTypes = {
  ...DateRangePicker.propTypes,
  // Since we wrap the DateRangePicker with defaults the following props are not
  // required anymore
  startDate: PropTypes.object,
  startDateId: PropTypes.string,
  endDate: PropTypes.object,
  endDateId: PropTypes.string,
  focusedInput: PropTypes.oneOf([START_DATE, END_DATE]),
  onDatesChange: PropTypes.func,
  onFocusChange: PropTypes.func,
};

DateRangePickerState.defaultProps = {
  startDate: moment(),
  startDateId: 'defaultStartDateId',
  endDate: moment().add(7, 'days'),
  endDateId: 'defaultEndDateId',
  focusedInput: null,
  onDatesChange: noop,
  onFocusChange: noop,
};

export default DateRangePickerState;
