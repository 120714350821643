import React from 'react';
import { Link } from 'react-router-dom';

import TodoModel, { TodoShape, CampaignShape } from '../../todoModel';
import TodoDetail from './detail';
import TodoActions from './actions';

function Todo({ todo, campaign, ...props }) {
  return (
    <div>
      {/* description */}
      <div className="row">
        <div className="col">
          <h6 className="text-muted">Description:</h6>
          <div className="pl-3">{TodoModel.description(todo)}</div>
        </div>
      </div>

      {/* details */}
      <div className="row pt-4">
        <div className="col">
          <h6 className="text-muted">Details:</h6>
          <div className="pl-3">
            <TodoDetail title="username">{TodoModel.username(todo)}</TodoDetail>
            <TodoDetail title="email">{TodoModel.userEmail(todo)}</TodoDetail>
            <TodoDetail title="channel">
              {TodoModel.channelName(todo)}
            </TodoDetail>
            <TodoDetail title="application">
              <Link to={TodoModel.applicationUrl(todo, campaign)}>
                Link to application
              </Link>
            </TodoDetail>
            <TodoDetail title="mission">
              <Link to={TodoModel.missionUrl(todo, campaign)}>
                Link to mission
              </Link>
            </TodoDetail>
          </div>
        </div>
      </div>

      {/* actions */}
      <TodoActions {...props} todo={todo} />
    </div>
  );
}

Todo.propTypes = {
  todo: TodoShape.isRequired,
  campaign: CampaignShape.isRequired,
};

export default Todo;
