import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, Fields } from 'redux-form';
import { compose } from 'recompose';
import get from 'lodash/get';

import { Alert, Feature } from 'source/components/common';
import {
  Input,
  Checkbox,
  MarkdownTextareaSide,
} from 'source/scenes/components/reduxFormAdapters';
import { LabeledNumberInput } from 'source/scenes/components/labeledInputs';

import { parseToInt } from 'source/utils/reduxForm';

import selector from './selectors';
import withValidate from './validation';
import { withSubmissionHandler } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';

const enhance = compose(
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
  withValidate,
  reduxForm({
    touchOnBlur: false,
    touchOnChange: true,
    enableReinitialize: true,
  }),
);

function PricingMultiplierFields({
  pricingMultiplier: { active, coefficient },
}) {
  return (
    <div>
      <Checkbox {...active} label="Apply pricing multiplier to campaign" />

      {active.input.value ? (
        <div className="pl-4 pt-3">
          <p className="alert alert-warning">
            The pricing multiplier affects both the payment to the influencer
            and the cost to the advertiser. All payments and costs will be
            multiplied by this value. It must be in the range 0 to 2.
          </p>
          <LabeledNumberInput
            {...coefficient}
            disabled={!active.input.value}
            min={0}
            max={2}
            step={0.1}
            label="Pricing multiplier"
          />
        </div>
      ) : null}
    </div>
  );
}

PricingMultiplierFields.propTypes = {
  pricingMultiplier: PropTypes.shape({
    /**
     * The following are Redux Form input props
     * @see https://redux-form.com/7.1.2/docs/api/field.md/#input-props
     */
    active: PropTypes.object.isRequired,
    coefficient: PropTypes.object.isRequired,
  }).isRequired,
};

function ContractForm({
  handleSubmit,
  invalid,
  submitting,
  error,
  reachKPI = 'reach',
  unitOfParticipation = 'posts',
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Alert message={get(error, 'message')} />
      <Field
        name="targetInfluencerProfile"
        label="Target Influencer profile (shows in Business Manager):"
        placeholder="write some markdown..."
        component={MarkdownTextareaSide}
        rows="5"
      />
      <Field
        name="budget"
        component={Input}
        type="number"
        parse={parseToInt}
        min={1}
        label="Advertiser budget:"
        className="row"
        labelClassName="col-3 col-form-label"
        inputClassName="col-3"
        feedbackClassName="d-flex col-3 pl-3 align-items-center"
      />

      <Field
        name="reach"
        component={Input}
        type="number"
        parse={parseToInt}
        min={1}
        label={`Total ${reachKPI} target:`}
        className="row"
        labelClassName="col-3 col-form-label"
        inputClassName="col-3"
        feedbackClassName="d-flex col-3 pl-3 align-items-center"
      />

      <Field
        name="minPosts"
        component={Input}
        type="number"
        parse={parseToInt}
        min={1}
        label={`Min. number of ${unitOfParticipation}:`}
        className="row"
        labelClassName="col-3 col-form-label"
        inputClassName="col-3"
        feedbackClassName="d-flex col-3 pl-3 align-items-center"
      />

      <Field
        name="maxPosts"
        component={Input}
        type="number"
        parse={parseToInt}
        min={1}
        label={`Max. number of ${unitOfParticipation}:`}
        className="row"
        labelClassName="col-3 col-form-label"
        inputClassName="col-3"
        feedbackClassName="d-flex col-3 pl-3 align-items-center"
      />

      <Feature feature="pricingMultiplier">
        <Fields
          names={['pricingMultiplier.active', 'pricingMultiplier.coefficient']}
          component={PricingMultiplierFields}
        />
      </Feature>

      <div className="row justify-content-end pr-3">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={invalid || submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
}

ContractForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reachKPI: PropTypes.string,
  unitOfParticipation: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default enhance(ContractForm);
