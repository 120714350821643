/**
 * helpers for the redux-async-utils library
 */

import get from 'lodash/get';

export const assertSuccessStatusCode = (res) => {
  const statusCode = get(res, 'statusCode');
  const data = get(res, 'data') || get(res, 'message');

  if (statusCode >= 400) {
    throw new Error(`Failed to update Campaign: ${data}`);
  }

  return res;
};
