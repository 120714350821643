import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { CopyButton } from 'source/components/common';
import Pagination from 'source/components/common/pagination';
import { getCampaignEditPath } from 'source/scenes/campaigns/components';

/* eslint-disable react/prop-types */
const getUserValue = ({ id, value }) =>
  value ? <Link to={`/users/${id}`}>{value}</Link> : '-';

const getCampaignValue = (campaign) => {
  if (!campaign.value) {
    return '-';
  }

  const {
    id,
    value,
    orig: { type, pricing },
  } = campaign;

  return <Link to={getCampaignEditPath({ id, type, pricing })}>{value}</Link>;
};
/* eslint-enable react/prop-types  */

function LinksTableElement({ id, url, targetUrl, user, campaign, createdAt }) {
  return (
    <tr>
      <td>{id}</td>
      <td className="col-wrap">
        <Link to={`/links/${id}`}>{targetUrl.value}</Link>
      </td>
      <td className="col-wrap">{getUserValue(user)}</td>
      <td className="col-wrap">{getCampaignValue(campaign)}</td>
      <td>{createdAt.value}</td>
      <td>
        <CopyButton title="Copy link" value={url.value} />
      </td>
    </tr>
  );
}

const NormalizedValue = PropTypes.shape({
  value: PropTypes.string.isRequired,
});

LinksTableElement.propTypes = {
  id: PropTypes.string.isRequired,
  url: NormalizedValue.isRequired,
  targetUrl: NormalizedValue.isRequired,
  user: NormalizedValue.isRequired,
  campaign: NormalizedValue.isRequired,
  createdAt: NormalizedValue.isRequired,
};

function LinksTable({ links, pages, page, onSelectPage }) {
  return (
    <div>
      <div className="table-responsive">
        <table className="table table-striped table-links">
          <thead>
            <tr>
              <th className="col-id">ID</th>
              <th>Target URL</th>
              <th>User</th>
              <th>Campaign</th>
              <th className="min-width-8-5">Created At</th>
              <th className="col-actions">{/* Dummy header */}</th>
            </tr>
          </thead>
          <tbody>
            {links.map((link) => (
              <LinksTableElement key={link.id} {...link} />
            ))}
          </tbody>
        </table>
      </div>
      <div className="row justify-content-center pt-3">
        <Pagination pages={pages} activePage={page} onSelect={onSelectPage} />
      </div>
    </div>
  );
}

LinksTable.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pages: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  onSelectPage: PropTypes.any.isRequired,
};

export default LinksTable;
