import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import { CampaignMissions } from '../components';
import {
  EventDefault,
  EventPayment,
  EventApplicationCreated,
  EventLink,
  EventTaskEdit,
} from '../components/sharedEvents';
import EventContentPreviewYouTube from './eventContentPreviewYouTube';
import ChannelLink from '../components/channelLink';
import { withApplications, withLoadingGuard, withErrorGuard } from '../common';
import { campaignMissions } from '../selectors';
import actions from '../actions';

const withConfig = withProps({
  eventComponents: {
    application_created: EventApplicationCreated,
    application_content_preview_submitted: EventContentPreviewYouTube,
    application_content_publication_changed: EventLink,
    application_payment_changed: EventPayment,
    application_task_overrides_changed: EventTaskEdit,
    campaign_tasks_changed: EventTaskEdit,
    default: EventDefault,
  },
  getHeading: (application) => application.user.firstname,
  getSubheading: (application) =>
    `(${application.channel.name}, subscribers: ${application.match.reach})`,
  channelLinkComponent: ChannelLink,
});

export default compose(
  connect(campaignMissions, actions),
  withConfig,
  withApplications(),
  withLoadingGuard,
  withErrorGuard,
)(CampaignMissions);
