import find from 'lodash/find';
import get from 'lodash/get';
import omit from 'lodash/omit';

import actionTypes from 'source/constants/actionTypes';
import { requestFlow } from 'source/utils/axios';
import { chainPromises, takeLastValid } from 'source/utils';
import { serverErrorRequestErrorHappened } from 'source/actions/error';

import api from './apiV2';

/**
 * transform an application task
 */
const transformTask = (task) => {
  const omittedFields = ['computedId', 'existing', 'file'];
  if (!task.existing || task.computedId) {
    omittedFields.push('id');
  }

  if (task.type === 'text' && !task.body) {
    omittedFields.push('body');
  }

  return omit(task, omittedFields);
};

/**
 * NOTE @alexspri
 *    - We're re-using this action in the campaignMission view.
 *    - also todos view
 */
export const updateApplication = (campaignId, application) => ({
  type: actionTypes.CAMPAIGN_APPLICATION_PATCHED,
  payload: { campaignId, application },
});

/* == sync actions == */

export const applicationFilterChange = (payload) => ({
  type: actionTypes.CAMPAIGN_APPLICATION_FILTER_CHANGE,
  payload,
});

export const applicationFormChange = (id, field, value) => ({
  type: actionTypes.CAMPAIGN_APPLICATION_FORM_CHANGED,
  payload: { id, field, value },
});

export const expandApplication = (payload) => ({
  type: actionTypes.CAMPAIGN_APPLICATION_EXPAND_APPLICATION,
  payload,
});

/* == async actions == */

// load publishing dates for the application/campaign
export const loadPublishingDates = (application) => (dispatch) => {
  const onBefore = () =>
    dispatch({
      type: actionTypes.CAMPAIGN_APPLICATION_PUBLISHING_DATES_LOADING,
      payload: { application },
    });

  const onSuccess = (payload) =>
    dispatch({
      type: actionTypes.CAMPAIGN_APPLICATION_PUBLISHING_DATES_LOADED,
      payload: { application, publishingDates: payload },
    });

  const onError = (error) =>
    dispatch({
      type: actionTypes.CAMPAIGN_APPLICATION_PUBLISHING_DATES_FAILED,
      payload: { application, error },
    });

  const request = () =>
    dispatch(api.getPublishingDates(application, { dispatchErrors: false }));

  return requestFlow(request, { onBefore, onSuccess, onError });
};

// submit an appliaction
export const applicationSubmit =
  (campaignId, applicationId) => (dispatch, getState) => {
    const {
      campaign: {
        applications: { applications, formsById },
      },
    } = getState();

    const applicationForm = formsById[applicationId].forms;
    const application = find(applications.data, { id: applicationId });

    const updateTasks = () => {
      const modifiedTaskIndices = Object.keys(
        get(applicationForm, 'modifiedTasks', {}),
      ).map(Number); // NOTE: Object.keys is converting numbers to string so we need to re-convert the task index

      // update tasks if there is a modified task
      if (!modifiedTaskIndices.length) {
        return null;
      }

      const tasks = applicationForm.tasks
        .map(transformTask)
        .map((task, index) => {
          if (modifiedTaskIndices.indexOf(index) !== -1) {
            task.updated = true;
          }

          return task;
        });

      const payload = {
        tasks,
      };

      return dispatch(api.patchApplication({ applicationId, payload }));
    };

    const updateStatus = () => {
      // no need to update the application if it wasnt updated
      if (application.status === applicationForm.status) {
        return Promise.resolve();
      }

      const payload = {
        status: applicationForm.status,
      };

      return dispatch(api.patchApplication({ applicationId, payload }));
    };

    const updateAssigment = () => {
      const { vip, publishingDateId } = applicationForm;

      if (vip && publishingDateId) {
        // Only patch the application with the assigment object if both the VIP checkbox and a date was selected
        const payload = {
          assignment: { publishingDateId },
        };

        const patchApplication = () =>
          dispatch(
            api.patchApplication(
              { applicationId, payload },
              { dispatchErrors: false },
            ),
          );

        const reloadPublishingDates = (applicationResult) =>
          dispatch(loadPublishingDates(application)).then(
            () => applicationResult,
          );

        return Promise.resolve()
          .then(patchApplication)
          .then(reloadPublishingDates);
      }

      return Promise.resolve();
    };

    const onBefore = () =>
      dispatch({
        type: actionTypes.CAMPAIGN_APPLICATION_PATCHING,
        payload: { id: applicationId },
      });
    const onSuccess = (updatedApplication) =>
      // NOTE: Sometimes we skip patching the application if nothing was changed.
      //       In that case `updatedApplication` is undefined and we dispatch the
      //       success action with the old application.
      dispatch(
        updateApplication(campaignId, updatedApplication || application),
      );
    const onError = (error) =>
      dispatch({
        type: actionTypes.CAMPAIGN_APPLICATION_PATCHING_FAILED,
        payload: { id: applicationId, error },
      });

    const request = () =>
      chainPromises([updateTasks, updateStatus, updateAssigment]).then(
        takeLastValid,
      );

    return requestFlow(request, { onBefore, onSuccess, onError });
  };

// Load insights data for one application.
export const loadApplicationInsights = (application) => (dispatch) => {
  const onBefore = () =>
    dispatch({
      type: actionTypes.CAMPAIGN_APPLICATION_INSIGHTS_LOADING,
      payload: { application },
    });

  const onSuccess = (payload) =>
    dispatch({
      type: actionTypes.CAMPAIGN_APPLICATION_INSIGHTS_LOADED,
      payload: { application, insights: payload },
    });

  const onError = (err) => {
    if (err.statusCode === 404) {
      // not found - no insights data found
      return dispatch({
        type: actionTypes.CAMPAIGN_APPLICATION_INSIGHTS_LOADED,
        payload: { application, insights: { notFound: true } },
      });
    }

    if (err.statusCode === 502) {
      // bad gateway - an error with the insights call
      return dispatch({
        type: actionTypes.CAMPAIGN_APPLICATION_INSIGHTS_FAILED,
        payload: { application, error: err.data.message },
      });
    }

    return dispatch(serverErrorRequestErrorHappened(err));
  };

  const request = () =>
    dispatch(
      api.getApplicationInsightsAggregation(
        { applicationId: application.id },
        { dispatchErrors: false },
      ),
    );

  return requestFlow(request, { onBefore, onSuccess, onError });
};

// update multiple applications
export const updateApplications =
  (campaignId, applications = []) =>
  (dispatch) => {
    const onBefore = () =>
      dispatch({
        type: actionTypes.CAMPAIGN_APPLICATIONS_UPDATING,
      });

    const onSuccess = (applications) =>
      dispatch({
        type: actionTypes.CAMPAIGN_APPLICATIONS_UPDATED_SUCCESFULLY,
        payload: applications,
      });

    const onError = (error) =>
      dispatch({
        type: actionTypes.CAMPAIGN_APPLICATIONS_UPDATING_FAILED,
        payload: { error },
      });

    const request = () => {
      const promises = applications.map((application) => {
        const payload = omit(application, 'id');

        return dispatch(
          api.patchApplication({ applicationId: application.id, payload }),
        );
      });

      return Promise.all(promises);
    };

    return requestFlow(request, { onBefore, onSuccess, onError });
  };

export const loadApplications = (campaignId) => (dispatch) => {
  const onBefore = () =>
    dispatch({
      type: actionTypes.CAMPAIGN_APPLICATIONS_LOADING,
    });

  const onSuccess = (applications) =>
    dispatch({
      type: actionTypes.CAMPAIGN_APPLICATIONS_LOADED,
      payload: applications,
    });

  const onError = (error) =>
    dispatch({
      type: actionTypes.CAMPAIGN_APPLICATIONS_FAILED,
      payload: { error },
    });

  const request = () => dispatch(api.listApplications(campaignId));

  return requestFlow(request, { onBefore, onSuccess, onError });
};
