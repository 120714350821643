import React from 'react';
import PropTypes from 'prop-types';
import Octicon from 'react-octicon';
import cx from 'classnames';

/**
 * The {InfoCard} displays is github-ish message card (like in the
 * no notifications available page).
 *
 * @props iconName {String} if given the `icon` prop will be ignored and instead an octicon icon with this name is shown.
 * @props icon {Node} is rendered as the message card icon. If neither `iconName` nor `icon` is given, the iconName defaults to "info".
 * @props title {Node} the title that will be rendered in the middle as a headline.
 * @props description {Node} the description.
 */
function InfoCard({ iconName, icon, title, size, className, children }) {
  // iconName overrides icon
  if (iconName) {
    icon = <Octicon mega name={iconName} />;
  }

  // unset size if it does not fit the allowed props
  if (!['small'].includes(size)) {
    size = null;
  }

  return (
    <div className={cx('card', 'info-card', className)}>
      <div className={cx('card-body', `info-card--${size}`)}>
        {icon && <div className="icon">{icon}</div>}

        {title && <h4 className="title">{title}</h4>}

        <div className="description p-3">{children}</div>
      </div>
    </div>
  );
}

InfoCard.propTypes = {
  iconName: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.node,
  size: PropTypes.oneOf([null, 'small']),
  className: PropTypes.string,
  children: PropTypes.node,
};

InfoCard.defaultProps = {
  iconName: null,
  icon: null,
  title: null,
  size: null,
  className: '',
  children: null,
};

export default InfoCard;

/**
 * The {InfoCardContainer} should be used to wrap the {InfoCard} to
 * apply proper sizing.
 *
 * @props width {Integer} Should be
 */
export function InfoCardContainer({ children, className, innerClassName }) {
  const col = 6;
  const offset = 3;

  const colClassName = cx(
    `col-md-${col}`,
    `offset-md-${offset}`,
    innerClassName,
  );

  return (
    <div className={cx('row', className)}>
      <div className={colClassName}>{children}</div>
    </div>
  );
}

InfoCardContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
};
