import React from 'react';
import { Fields } from 'redux-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import PropTypes from 'source/scenes/utils/propTypes';
import { FileDrop, Input } from 'source/scenes/components/reduxFormAdapters';
import { FilePreview } from 'source/components/common/fileDrop';

function ImageTaskContent({ file, body }) {
  const fileDisabled = Boolean(body.input.value);
  const fileUrlDisabled = !isEmpty(file.input.value);
  const fileSrc = get(file, 'input.value.preview') || get(body, 'input.value');

  return (
    <div>
      <FileDrop
        {...file}
        label="Upload a file ..."
        placeholder="Choose or drop a file..."
        accept="image/*"
        disabled={fileDisabled}
      />

      <Input
        {...body}
        label="... or provide an image url."
        placeholder="image url..."
        type="url"
        disabled={fileUrlDisabled}
      />

      <FilePreview src={fileSrc} />
    </div>
  );
}

ImageTaskContent.propTypes = {
  file: PropTypes.reduxFormInput.isRequired,
  body: PropTypes.reduxFormInput.isRequired,
};

function ImageTask() {
  return <Fields names={['file', 'body']} component={ImageTaskContent} />;
}

export default ImageTask;
