import { actionRequest, requestFlow } from 'source/utils/axios';

import config from 'config';
import actionTypes from 'source/constants/actionTypes';

import { createStorage } from 'source/utils';

export const requestAuthUrl = () => (dispatch) => {
  const onBefore = () =>
    dispatch({ type: actionTypes.ACCOUNT_AUTH_URL_REQUESTING });

  const onSuccess = (resp) => {
    dispatch({
      type: actionTypes.ACCOUNT_AUTH_URL_REQUESTED,
      payload: resp.data,
    });

    // redirect user to google auth page
    window.location.replace(resp.data.redirectUrl);
  };

  const onError = (resp) =>
    dispatch({
      type: actionTypes.ACCOUNT_AUTH_URL_REQUEST_FAILED,
      payload: resp,
    });

  const request = () =>
    actionRequest(dispatch).get('/v1/auth/google', {
      params: { redirect_url: `${config.app.url}/access` },
    });

  return requestFlow(request, { onBefore, onSuccess, onError });
};

export const accessTokenFetched = (accessToken) => (dispatch) => {
  createStorage().save('accessToken', accessToken);

  dispatch({
    type: actionTypes.ACCOUNT_ACCESS_TOKEN_FETCHED,
    payload: accessToken,
  });
};
