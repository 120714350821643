import { combineValidators, composeValidators, isRequired } from 'revalidate';

import {
  createArrayValidator,
  isGreaterThan,
  isLessThan,
} from 'source/utils/validators';

export default combineValidators({
  countries: createArrayValidator(
    // array validation
    composeValidators(isRequired)('Countries'),
    // item validation
    combineValidators({
      // redux-form removes the value of a checkbox Field if it is not checked.
      // vip: isRequired('VIP'),
      visitsShare: composeValidators(
        isRequired,
        isGreaterThan(-1),
        isLessThan(101),
      )('Minimum share'),
    }),
  ),
});
