import React from 'react';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/browser';

import SvgComponents from 'source/components/common/svgComponents';
import * as Containers from 'source/containers';
import * as scenes from 'source/scenes/containers';
import routes from 'source/routes';
import config from 'config';

import createApplicationStore, { history } from './store';

const store = createApplicationStore();

if (!config.sentry.disabled) {
  Sentry.init({
    dsn: config.sentry.dsn,
  });
}

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <IntlProvider locale="en" defaultLocale="en">
          <scenes.Root>
            <Containers.AppContainer>
              <Switch>{routes}</Switch>
            </Containers.AppContainer>
          </scenes.Root>
        </IntlProvider>
      </ConnectedRouter>
      <SvgComponents />
    </Provider>
  );
}

export default hot(App);

if (module.hot) {
  module.hot.accept();
}
