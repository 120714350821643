import React from 'react';
import { FormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';
import {
  compose,
  setPropTypes,
  withHandlers,
  withPropsOnChange,
} from 'recompose';
import { Link } from 'react-router-dom';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';
import cx from 'classnames';
import Octicon from 'react-octicon';

import InfoCard, { InfoCardContainer } from 'source/components/common/infoCard';
import Pagination from 'source/components/common/pagination';
import ExternalLink from 'source/scenes/components/externalLink';
import Icon from 'source/components/common/icon';

function ChannelSearchNoSearch() {
  return (
    <InfoCardContainer className="h-100" innerClassName="d-flex">
      <InfoCard
        className="align-self-center"
        iconName="info"
        title="NO FILTERS APPLIED"
      >
        Please adjust the filter selection on the left.
      </InfoCard>
    </InfoCardContainer>
  );
}

function ChannelSearchNoResults() {
  return (
    <InfoCardContainer className="h-100" innerClassName="d-flex">
      <InfoCard
        className="align-self-center"
        iconName="info"
        title="NO MATCHING RESULTS"
      >
        Please adjust the filter selection on the left.
      </InfoCard>
    </InfoCardContainer>
  );
}

const cleanLinkName = (url) =>
  url
    .replace(/^https?:\/\//, '')
    .replace(/\/$/, '')
    .replace(/^www\./, '');

function CategoryChip({ name, className }) {
  return (
    <div
      className={cx(
        'category-chip category-chip-compact d-flex rounded',
        className,
      )}
    >
      <span className="category-name-block d-flex flex-column">
        <span className="category-name text-truncate">{name}</span>
      </span>
    </div>
  );
}

CategoryChip.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

function _ChannelSearchColumn({ id, label, className, sorted, onClick }) {
  className = cx('text-nowrap cursor-pointer', className, {
    'text-muted': !sorted,
  });

  return (
    <th id={id} className={className} onClick={onClick}>
      {sorted === 1 ? <Octicon name="triangle-up" /> : null}
      {sorted === -1 ? <Octicon name="triangle-down" /> : null}
      <span>{label}</span>
    </th>
  );
}

_ChannelSearchColumn.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  sorted: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

const ChannelSearchColumn = compose(
  setPropTypes({
    id: PropTypes.string.isRequired,
    sorting: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  withHandlers({
    onClick:
      ({ id, sorting, onClick }) =>
      (e) => {
        const nextSorting = {};

        // if we already sort by this id, we toggle it now
        if (sorting[id]) {
          nextSorting[id] = sorting[id] * -1;
        } else {
          // if we didn't sort by this property yet, we set this to the only
          // sorted field (descending by default)
          nextSorting[id] = -1;
        }

        return onClick(nextSorting, e);
      },
  }),
  // add "sorted" property from the given "sorting" object mapping
  withPropsOnChange(['sorting'], ({ id, sorting }) => ({
    sorted: sorting[id],
  })),
)(_ChannelSearchColumn);

function ChannelSearchWithResults({
  results,
  categories,
  paging,
  sorting,
  onSelectPage,
  onChangeSort,
}) {
  return (
    <div>
      <div className="table-responsive-lg">
        <table className="table table-striped channel-results">
          <thead>
            <tr>
              <th className="text-muted">UserID</th>
              <th className="text-muted">Name</th>
              <th className="text-muted">Lang</th>
              <th className="text-muted">Country</th>
              <th className="channel-col text-muted">Channel</th>
              <th className="text-muted">Channel ID</th>
              <ChannelSearchColumn
                id="reach"
                label="Reach"
                sorting={sorting}
                onClick={onChangeSort}
              />
              <th className="text-muted">Categories</th>
              <th className="text-muted">other Channels</th>
            </tr>
          </thead>
          <tbody>
            {results.map((channel) => (
              <tr key={channel.id}>
                <td>
                  <Link to={`/users/${channel.user.id}`} target="_blank">
                    {channel.user.id}
                  </Link>
                </td>
                <td
                  className="name-col text-truncate"
                  title={channel.user.firstname}
                >
                  {channel.user.firstname}
                </td>
                <td>{channel.user.language}</td>
                <td>{toUpper(channel.user.country)}</td>
                <td
                  className="channel-col text-truncate"
                  title={cleanLinkName(channel.name)}
                >
                  <div className="d-flex align-items-center channel-icon truncate">
                    <Icon name={channel.platform} className="small-icon" />
                    <span className="ml-2 truncate mw-12">
                      <ExternalLink href={channel.data.url}>
                        {cleanLinkName(channel.name)}
                      </ExternalLink>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="truncate mw-5" title={channel.id}>
                    <Link to={`/channels/${channel.id}`} target="_blank">
                      {channel.id}
                    </Link>
                  </div>
                </td>
                <td className="reach-col">
                  <FormattedNumber value={channel.reach} />
                </td>
                <td>
                  <div className="row nmt-2 flex-nowrap">
                    {channel.categories &&
                      channel.categories.map((category) => (
                        <CategoryChip
                          key={category.code}
                          className="ml-2 mt-2"
                          name={categories[category.code].name}
                        />
                      ))}
                  </div>
                </td>
                <td>
                  <div className="row nmt-2">
                    {channel.user.channels
                      .filter(
                        (otherChannel) =>
                          otherChannel.data.url !== channel.data.url,
                      )
                      .map((otherChannel) => (
                        <div key={otherChannel.id} className="channel-icon">
                          <span className="ml-2 mt-2">
                            <ExternalLink href={otherChannel.data.url}>
                              <Icon
                                name={otherChannel.platform}
                                className="small-icon"
                              />
                            </ExternalLink>
                          </span>
                        </div>
                      ))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row justify-content-center pt-3">
        <Pagination {...paging} onSelect={onSelectPage} />
      </div>
    </div>
  );
}

ChannelSearchWithResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          weight: PropTypes.number,
        }),
      ),
      data: PropTypes.shape({ url: PropTypes.string }),
      name: PropTypes.string,
      platform: PropTypes.string,
      reach: PropTypes.number,
      user: PropTypes.shape({
        id: PropTypes.number,
        channels: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            platform: PropTypes.string,
            data: PropTypes.shape({ url: PropTypes.string }),
          }),
        ),
        country: PropTypes.string,
        email: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
      }),
    }),
  ).isRequired,
  categories: PropTypes.object.isRequired,
  paging: PropTypes.any.isRequired,
  /**
   * NOTE @alexspri
   *    `sorting` is an object, which key is the property to sort by and its
   *    value is either 1 (ascending) or -1 (descending). the `sorting`
   *    property follow directly the api requirements for the `sort` parameter.
   */
  sorting: PropTypes.object.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
};

/* just a small 3 branch wrapper based on the results and set filters */
function ChannelSearchResults(props) {
  // if results is nil (null or undefined), it means we didn't not even try to
  // load data yet
  if (isNil(props.results)) {
    return <ChannelSearchNoSearch {...props} />;
  }

  // if results list is empty, it means the server could not find any matching
  // channel
  if (isEmpty(props.results)) {
    return <ChannelSearchNoResults {...props} />;
  }

  // otherwise we have data
  return <ChannelSearchWithResults {...props} />;
}

ChannelSearchResults.propTypes = {
  results: PropTypes.array,
};

export default ChannelSearchResults;
