export const SCREENSHOTS_NAMES = {
  instagram: [
    'storiesImpressions',
    'postsImpressions',
    'countries',
    'ageAndGender',
  ],
  tiktok: ['overview', 'content', 'followers'],
};

export const SCREENSHOTS_PLACEHOLDERS = {
  instagram: {
    storiesImpressions: '/images/insights/instagram/stories-impressions.png',
    postsImpressions: '/images/insights/instagram/posts-impressions.png',
    countries: '/images/insights/instagram/countries.png',
    ageAndGender: '/images/insights/instagram/age-and-gender.png',
  },
  tiktok: {
    overview: '/images/insights/tiktok/overview.png',
    content: '/images/insights/tiktok/content.png',
    followers: '/images/insights/tiktok/followers.png',
  },
};

export const SCREENSHOTS_DESCRIPTIONS = {
  instagram: {
    storiesImpressions: 'Impressions for Stories from last 30 days',
    postsImpressions: 'Impressions for Posts from last 30 days',
    countries: 'Countries, in percentage',
    ageAndGender: 'Age and Gender distribution, in percentage',
  },
  tiktok: {
    overview: 'Analytics: Overview with 28 days selected',
    content: 'Analytics: Content',
    followers: 'Analytics: Followers including gender and top countries charts',
  },
};
