import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import noop from 'lodash/noop';
import ReactFlagsSelect from 'react-flags-select';
import Countries from 'source/utils/countries';

import { Field } from 'redux-form';
import {
  percentToFloatStr,
  floatStrToPercent,
  normalizePercent,
} from 'source/utils/reduxForm';

const countryCodes = Object.keys(Countries).sort();
const countryLabels = countryCodes.reduce((memo, country) => {
  memo[country] = country;
  return memo;
}, {});

class CountryInput extends React.PureComponent {
  state = { country: this.props.country };

  onSelectFlag = (newCountry) => {
    const { onSelectFlag } = this.props;
    const { country } = this.state;

    this.setState({ country: newCountry });
    onSelectFlag(country, newCountry, this.value);
  };

  renderInput = ({ className, input, ...rest }) => {
    const { onChange } = this.props;
    const { country } = this.state;

    this.value = input.value;
    onChange(this.value, `${this.props.objectKey}.${country}`);

    return (
      <div
        className={cx('position-relative', {
          percentage: this.value >= 0,
        })}
      >
        <input className={cx(className)} {...input} {...rest} />
      </div>
    );
  };

  render() {
    const { className } = this.props;
    const { country } = this.state;

    return (
      <div className={cx('form-group d-flex flag-input', className)}>
        <Field
          className="form-control no-spin-buttons"
          name={`${this.props.objectKey}.${country}`}
          type="number"
          parse={floatStrToPercent}
          normalize={normalizePercent}
          format={percentToFloatStr}
          component={this.renderInput}
        />
        <ReactFlagsSelect
          className="menu-flags"
          selectButtonClassName="menu-flags-button"
          placeholder="Country"
          selected={country}
          customLabels={countryLabels}
          onSelect={this.onSelectFlag}
          searchable
        />
      </div>
    );
  }
}

CountryInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  country: PropTypes.string,
  onSelectFlag: PropTypes.func,
  onChange: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.string),
  objectKey: PropTypes.string.isRequired,
};

CountryInput.defaultProps = {
  countries: countryCodes,
  onSelectFlag: noop,
  onChange: noop,
};

export default CountryInput;
