import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import ConfirmationAlert from './confirmationAlert';

function ConfirmationAlertWrapper({
  children,
  closePortal,
  onConfirm,
  yesButtonLabel,
  noButtonLabel,
}) {
  return (
    <div>
      <ConfirmationAlert
        closePortal={closePortal}
        onConfirm={onConfirm}
        yesButtonLabel={yesButtonLabel}
        noButtonLabel={noButtonLabel}
      >
        {children}
      </ConfirmationAlert>
      <div
        className="confirmation-overlay"
        tabIndex={-3}
        style={{ opacity: 1, display: 'block' }}
      />
    </div>
  );
}

ConfirmationAlertWrapper.propTypes = {
  onConfirm: PropTypes.func,
  closePortal: PropTypes.func,
  children: PropTypes.node.isRequired,
  yesButtonLabel: PropTypes.string,
  noButtonLabel: PropTypes.string,
};

ConfirmationAlertWrapper.defaultProps = {
  onConfirm: noop,
  closePortal: noop,
};

export default ConfirmationAlertWrapper;
