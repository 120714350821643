import React from 'react';

import TodoModel, { TodoShape } from '../todoModel';

function TodoHeadline({ todo }) {
  return (
    <span className="todo-headline">
      <span className="label">{TodoModel.label(todo)}</span>
      <small className="pl-4">
        {TodoModel.username(todo)}, {TodoModel.channelName(todo)}
      </small>
    </span>
  );
}

TodoHeadline.propTypes = {
  todo: TodoShape.isRequired,
};

export default TodoHeadline;
