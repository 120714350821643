import React from 'react';
import PropTypes from 'prop-types';
import Octicon from 'react-octicon';
import get from 'lodash/get';
import { compose, pure, setPropTypes, withHandlers } from 'recompose';

import { Checkbox } from 'source/components/common/forms';
import Badge from 'source/components/common/badge';
import { FormattedNumber } from 'react-intl';

function ApplicationStatusBadge({ status }) {
  const statusToBadgeType = {
    new: 'warning',
    review: 'info',
    selected: 'info',
    rejected: 'danger',
    approved: 'success',
  };

  const badgeType = statusToBadgeType[status] || 'secondary';

  return (
    <Badge type={badgeType} pill className="ml-3 font-weight-normal">
      {status}
    </Badge>
  );
}

ApplicationStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

function GroupList({ groups }) {
  return (
    <ul className="group-list">
      {groups.map(({ id, name, color: backgroundColor }) => (
        <li
          key={id}
          className="group-list-item group-tag"
          style={{ backgroundColor }}
        >
          {name}
        </li>
      ))}
    </ul>
  );
}

GroupList.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
};

GroupList.defaultProps = {
  groups: [],
};

function AttentionIndicator({ insightsAnalysis }) {
  // dont show attention indicator if analysis status is not warning
  if (insightsAnalysis && insightsAnalysis.status !== 'warning') {
    return null;
  }

  // - show a grayed attention symbol if the data could not be loaded
  // - otherwise we use the yellow version
  const badgeType = !insightsAnalysis ? 'secondary' : 'warning';
  const color = !insightsAnalysis ? 'black' : 'white';
  return (
    <Badge
      type={badgeType}
      pill
      className="mr-3"
      style={{ float: 'right', fontSize: 'inherit', color }}
    >
      <Octicon name="alert" />
    </Badge>
  );
}

AttentionIndicator.propTypes = {
  insightsAnalysis: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }),
};

AttentionIndicator.defaultProps = {
  insightsAnalysis: undefined,
};

const enhanceApplicationHeadline = compose(
  pure,
  setPropTypes({
    application: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    onSelect: PropTypes.func.isRequired,
  }),
  withHandlers({
    onSelect:
      ({ onSelect, application: { id } }) =>
      (e) => {
        e.stopPropagation();
        onSelect(id);
      },
  }),
);

function ApplicationHeadline(props) {
  const {
    application: { id, status, match, channel, user, groups, insightsAnalysis },
    onSelect,
    selected,
  } = props;

  const url = channel.name;
  const reach = get(match, 'reach', -1);
  const contingent = get(match, 'contingent.id', -1);

  const overview = (
    <>
      ({url}, reach: <FormattedNumber value={reach} />, bucket: {contingent})
    </>
  );

  return (
    <span className="application-headline">
      <Checkbox
        id={`application-checkbox-${id}`}
        className="float-left"
        onClick={onSelect}
        checked={selected}
      />
      <ApplicationStatusBadge status={status} />
      <span className="ml-3">
        <span>{user.firstname}</span>
        <span className="ml-3">
          <small>{overview}</small>
        </span>
        <GroupList groups={groups} />
        <AttentionIndicator insightsAnalysis={insightsAnalysis} />
      </span>
    </span>
  );
}

ApplicationHeadline.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    match: PropTypes.shape({
      reach: PropTypes.number.isRequired,
      contingent: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
    }),
    channel: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      firstname: PropTypes.string.isRequired,
    }).isRequired,
    groups: PropTypes.any,
    insightsAnalysis: PropTypes.any,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default enhanceApplicationHeadline(ApplicationHeadline);
