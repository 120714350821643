export const getContentPreviewReviewUrl = ({
  version,
  references: { applicationId },
  campaign: { id: campaignId, type, pricing },
}) => {
  let platform = type;

  if (type === 'website' && pricing === 'bucket') {
    platform = 'website-bucket';
  }
  if (type === 'website' && pricing === 'budget') {
    platform = 'website-budget';
  }

  return `/campaigns/${platform}/${campaignId}/preview/${applicationId}/version/${version}`;
};

export const getAfterPublicationUploadsReviewUrl = ({
  references: { applicationId },
  campaign: { id: campaignId, type: platform },
}) => `/campaigns/${platform}/${campaignId}/insights/${applicationId}`;
