import get from 'lodash/get';

import config from 'config';

import { formatUserData } from './acl';
import { trim } from '.';

export const isFeatureAvailable = (feature) => (user) => {
  if (!user) {
    throw new Error(
      trim(`missing user, when checking for feature! did you forget to
            pass the signed in user to the "isFeatureAvailable" function`),
    );
  }

  const allowed = get(config, ['features', feature, 'allowed'], []).map(
    formatUserData,
  );

  if (!allowed.length) {
    return true;
  }

  const [username, domain] = user.email.split('@');

  return (
    config.allowedSSODomains.includes(domain) &&
    Boolean(allowed.find((allowedUser) => allowedUser.username === username))
  );
};
