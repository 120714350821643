import { applicationStatuses, missionStatuses } from 'source/constants';

export const missionsFilter =
  ({ showRefused = false } = {}) =>
  (application) => {
    const isApproved = application.status === applicationStatuses.approved;
    const isNotRefused = application.mission.status !== missionStatuses.refused;

    return isApproved && (showRefused || isNotRefused);
  };
