import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function Badge({ children, type, pill, className, style }) {
  const typeClass = type ? `badge-${type}` : null;

  return (
    <span
      className={cx('badge', className, typeClass, {
        'badge-pill': pill,
      })}
      style={style}
    >
      {children}
    </span>
  );
}

Badge.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger',
    'success',
    'info',
    'warning',
  ]),
  pill: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

Badge.defaultProps = {
  children: undefined,
  type: 'primary',
  pill: false,
  className: undefined,
  style: undefined,
};

export default Badge;
