import find from 'lodash/find';

import { requestFlow } from 'source/utils/axios';
import { genericRequestErrorHappened } from 'source/actions/error';
import {
  applicationFetch,
  applicationFetchMany,
  applicationUpdate,
} from 'source/data/applications/actions';
import { paymentCreate } from 'source/data/payments/actions';
import { redux as collapsiblePanelRedux } from 'source/components/common/collapsiblePanel';

import { campaignMissionEvents, getReactionPayload } from './selectors';

export const namespace = 'campaignDetail/missions';

const getApplication = applicationFetch(namespace);
const listApplications = applicationFetchMany(namespace);
const patchApplication = applicationUpdate(namespace);
const createPayment = paymentCreate(namespace);

export const actionTypes = {
  REACTION_NAVIGATION_CLICKED: `${namespace}/REACTION_NAVIGATION_CLICKED`,
  LIST_FORM_CHANGE: `${namespace}/LIST_FORM_CHANGE`,
  FORM_CHANGE: `${namespace}/FORM_CHANGE`,
  FORM_ERRORS: `${namespace}/FORM_ERRORS`,
  FORM_SUBMITTING: `${namespace}/FORM_SUBMITTING`,
  FORM_SUBMITTED: `${namespace}/FORM_SUBMITTED`,
  APPLICATION_SUBMITTED: `${namespace}/APPLICATION_SUBMITTED`,
  RESET_SCENE: `${namespace}/RESET_SCENE`,
};

// click on the reaction navigation bar
const clickActionNavigation = (id, reaction) => ({
  type: actionTypes.REACTION_NAVIGATION_CLICKED,
  payload: { id, reaction },
});

// Form changes for all missions options bar
const listFormChange = (formId, field, value) => ({
  type: actionTypes.LIST_FORM_CHANGE,
  payload: { formId, field, value },
});

// Any form changes on one of the mission tabs (currently we're using one
// form for the conversation UI and one form for the mission status).
const formChange = (formId, field, value, { missionId } = {}) => ({
  type: actionTypes.FORM_CHANGE,
  payload: { formId, field, value, id: missionId },
});

const formErrors = (formId, errors, { missionId } = {}) => ({
  type: actionTypes.FORM_ERRORS,
  payload: { formId, errors, id: missionId },
});

// keep track of expanded panels
const expandMission = collapsiblePanelRedux.toggleActionCreator(namespace);

// submitting any form on one mission tab
const submitForm = (formId, applicationId) => ({
  type: actionTypes.FORM_SUBMITTING,
  payload: { id: applicationId, formId },
});

const submittedForm = (formId, applicationId) => ({
  type: actionTypes.FORM_SUBMITTED,
  payload: { id: applicationId, formId },
});

/* == Async Helpers == */

// update one application with the given payload
const updateApplication = (applicationId, payload, formId) => (dispatch) => {
  const onBefore = () => dispatch(submitForm(formId, applicationId));

  const onSuccess = ({ data: application }) => {
    // update form state
    dispatch(submittedForm(formId, applicationId));

    // update the mission state
    dispatch({
      type: actionTypes.APPLICATION_SUBMITTED,
      payload: { id: applicationId, application },
    });
  };

  const onError = (error) => dispatch(genericRequestErrorHappened(error));

  const request = () => dispatch(patchApplication(applicationId, payload));

  return requestFlow(request, { onBefore, onSuccess, onError });
};

const resetScene = () => ({ type: actionTypes.RESET_SCENE, name: namespace });

// Submit data provided from the conversation.
export const conversationSubmit =
  (formId, form = {}, { missionId, campaignId }) =>
  (dispatch, getState) => {
    const {
      missions: {
        [missionId]: { selectedReaction, lastEvent },
      },
    } = campaignMissionEvents(getState(), { campaignId });

    const reaction = find(lastEvent.reactions, {
      reaction: selectedReaction,
    });

    if (!reaction) {
      return dispatch(
        genericRequestErrorHappened(
          new Error(`could not find reaction ${selectedReaction}`),
        ),
      );
    }

    const updateHandler = {
      patch_application: updateApplication,
      post_payment: createPayment,
    };

    const handler = updateHandler[reaction.method];
    if (!handler) {
      return dispatch(
        genericRequestErrorHappened(
          new Error(`could not find handler for reaction ${selectedReaction}`),
        ),
      );
    }

    const payload = getReactionPayload(reaction, { form });

    return Promise.resolve()
      .then(() => dispatch(handler(missionId, payload, formId)))
      .then(() =>
        // NOTE: We also want to load additional fields here because there are
        // parts which potentially have changes and the UI needs to know about
        // them, like `mission.status` or `payment.status`.
        dispatch(
          getApplication(missionId, {
            fields: ['events', 'mission', 'payment'],
          }),
        ),
      );
  };

/* == Status Async Actions == */

// Submit data provided from the mission status sidebar.
const statusSubmit = (formId, form, { missionId }) => {
  const payload = {
    mission: { status: form.missionStatus },
  };

  return updateApplication(missionId, payload, formId);
};

const kamNotesSubmit = (formId, form, { missionId }) => {
  const payload = {
    kamNotes: form.kamNotes,
  };

  return updateApplication(missionId, payload, formId);
};

const productShipmentSubmit = (formId, form, { missionId }) => {
  const payload = {
    productShipment: { product: form.product },
  };

  return updateApplication(missionId, payload, formId);
};

const ratingSubmit = (formId, form, { missionId }) => {
  const payload = {
    collaborationRatings: {
      visualCreativity: form.visualCreativity,
      copyWriting: form.copyWriting,
      punctuality: form.punctuality,
      professionalism: form.professionalism,
    },
  };

  return updateApplication(missionId, payload, formId);
};

const actions = {
  onLoadApplications: listApplications,
  onLoadApplication: getApplication,
  onResetScene: resetScene,
  onClickActionNavigation: clickActionNavigation,
  onListChange: listFormChange,
  onChange: formChange,
  onErrors: formErrors,
  onSubmitConversation: conversationSubmit,
  onSubmitStatus: statusSubmit,
  onSubmitKamNote: kamNotesSubmit,
  onSubmitProductShipment: productShipmentSubmit,
  onSubmitRating: ratingSubmit,
  onExpandMission: expandMission,
};

export default actions;
