import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withState, withHandlers } from 'recompose';
import { Link, withRouter } from 'react-router-dom';
import { getFormValues } from 'redux-form';

import { compactObject } from 'source/utils';
import ConfirmButton from 'source/scenes/components/confirmButton';

import FeedbackForm from '../components/feedbackForm';
import { getMissionRoute } from '../helpers';
import InstagramInsightsForm from './instagram/form';
import TikTokInsightsForm from './tiktok/form';

const FEEDBACK_FORM_NAME = 'feedbackForm';
const INSIGHTS_FORM_NAME = 'publicationAnalyticsForm';

const enhance = compose(
  withRouter,
  withState('verificationStatusChanged', 'setVerificationStatusChanged'),
  withHandlers({
    onVerificationStatusChanged: ({ setVerificationStatusChanged }) =>
      setVerificationStatusChanged,
  }),
  connect((state) => ({
    feedbackFormValues: getFormValues(FEEDBACK_FORM_NAME)(state),
  })),
);

function Screenshot({ url, filename }) {
  return (
    <div className="screenshot-container">
      <span className="screenshot-title">{filename}</span>
      <img
        className="screenshot-img"
        src={url}
        alt="Post Insights Screenshot"
      />
    </div>
  );
}

Screenshot.propTypes = {
  url: PropTypes.string,
  filename: PropTypes.string,
};

class InsightsReviewManager extends React.Component {
  componentDidMount() {
    const { togglePanel, location } = this.props;
    const [, panelId] = location.hash.split('#');

    if (panelId) {
      togglePanel(Number(panelId), true);
    }
  }

  onPublicationAnalyticsSubmit = (data) => {
    const {
      application,
      onPublicationAnalyticsUpdate,
      collapseAllPanels,
      location,
      history,
    } = this.props;

    const publicationAnalytics = data.publicationAnalytics.map((item) => ({
      ...item,
      data: compactObject(item.data),
    }));
    const payload = { publicationAnalytics };
    onPublicationAnalyticsUpdate(application.id, payload);
    collapseAllPanels({
      location,
      history,
    });
  };

  setVerificationStatus = (status) => {
    const { feedbackFormValues, onVerificationStatusChanged } = this.props;

    onVerificationStatusChanged('pending');
    this.updateVerification({
      ...feedbackFormValues,
      status,
    }).then((response) => {
      if (response.status && response.status === 200) {
        onVerificationStatusChanged('success');
      } else {
        onVerificationStatusChanged('error');
      }
    });
  };

  reject = () => this.setVerificationStatus('rejected');

  accept = () => this.setVerificationStatus('accepted');

  updateVerification = (payload) => {
    const applicationId = this.props.application.id;
    return this.props.onInsightsReviewUpdate(
      applicationId,
      'afterPublicationUploadsVerification',
      payload,
    );
  };

  render() {
    const {
      application,
      campaign,
      verificationStatusChanged,
      selectedPanel,
      togglePanel,
      collapseAllPanels,
      publicationAnalyticsFormValue,
    } = this.props;
    const { screenshots } = application.afterPublicationUploads;
    const { publishDate } = application.mission;
    const { url: contentUrl } = application.contentPublication;

    return (
      <div className="row pt-3 pb-3 content-preview">
        <div className="col-md-6 insights-review pt-3">
          <div className="insights-review-images-container">
            {screenshots.map(({ url, filename }) => (
              <Screenshot key={url} url={url} filename={filename} />
            ))}
          </div>
        </div>
        <div className="col-md-6 pt-3 pb-3 management">
          <div className="form-group controls">
            <div className="float-right">
              <Link
                className="btn btn-outline-secondary"
                to={getMissionRoute({ campaign, application })}
                role="button"
              >
                Close Review
              </Link>
            </div>
          </div>

          {campaign.type === 'instagram' && (
            <InstagramInsightsForm
              title="Step 1: Collect data"
              form={INSIGHTS_FORM_NAME}
              onSubmit={this.onPublicationAnalyticsSubmit}
              publishDate={publishDate}
              initialValues={{
                publicationAnalytics: application.publicationAnalytics,
              }}
              togglePanel={togglePanel}
              selectedPanel={selectedPanel}
              collapseAllPanels={collapseAllPanels}
              publicationAnalyticsFormValue={publicationAnalyticsFormValue}
              contentUrl={contentUrl}
            />
          )}

          {campaign.type === 'tiktok' && (
            <TikTokInsightsForm
              title="Step 1: Collect data"
              form={INSIGHTS_FORM_NAME}
              onSubmit={this.onPublicationAnalyticsSubmit}
              publishDate={publishDate}
              initialValues={{
                publicationAnalytics: application.publicationAnalytics,
              }}
              togglePanel={togglePanel}
              selectedPanel={selectedPanel}
              collapseAllPanels={collapseAllPanels}
              publicationAnalyticsFormValue={publicationAnalyticsFormValue}
              contentUrl={contentUrl}
            />
          )}

          <FeedbackForm
            title="Step 2: Accept or Reject"
            description="Review notes are shown to the influencer in the User App after rejection."
            initialValues={{
              ...application.verification,
              feedback: '', // @TODO: We will change the draft to be saved on localStorage
            }}
            form={FEEDBACK_FORM_NAME}
          />

          <div className="form-group">
            <div className="clearfix">
              <ConfirmButton
                className="btn float-right"
                onConfirm={this.reject}
                disabled={
                  application.afterPublicationUploadsVerification.status !==
                    'submitted' || !!verificationStatusChanged
                }
                initialClassName="btn-danger"
                confirmClassName="btn-warning"
                doneClassName="btn-success"
              >
                Reject Insights
              </ConfirmButton>
              <ConfirmButton
                className="btn float-right mr-3"
                onConfirm={this.accept}
                disabled={
                  application.afterPublicationUploadsVerification.status !==
                    'submitted' || !!verificationStatusChanged
                }
                initialClassName="btn-success"
                confirmClassName="btn-warning"
                doneClassName="btn-success"
              >
                Accept Insights
              </ConfirmButton>
            </div>

            {verificationStatusChanged === 'error' && (
              <p className="pt-3 text-danger text-right">
                ✖ Something went wrong
              </p>
            )}
          </div>

          <div className="form-group">
            <Link
              className="btn btn-outline-secondary float-right"
              to={getMissionRoute({ campaign, application })}
              role="button"
            >
              Close Review
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

InsightsReviewManager.propTypes = {
  // props
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    afterPublicationUploadsVerification: PropTypes.object.isRequired,
    afterPublicationUploads: PropTypes.shape({
      screenshots: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
    mission: PropTypes.shape({
      publishDate: PropTypes.string,
    }).isRequired,
    contentPublication: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    verification: PropTypes.object.isRequired,
    publicationAnalytics: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    pricing: PropTypes.string.isRequired,
  }).isRequired,
  feedbackFormValues: PropTypes.shape({
    feedback: PropTypes.string.isRequired,
  }),
  selectedPanel: PropTypes.number,
  togglePanel: PropTypes.func.isRequired,
  collapseAllPanels: PropTypes.func.isRequired,
  publicationAnalyticsFormValue: PropTypes.arrayOf(PropTypes.object),
  // state
  verificationStatusChanged: PropTypes.string,
  // handlers
  onInsightsReviewUpdate: PropTypes.func.isRequired,
  onPublicationAnalyticsUpdate: PropTypes.func.isRequired,
  onVerificationStatusChanged: PropTypes.func.isRequired,
  // router
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

InsightsReviewManager.defaultProps = {
  feedbackFormValues: { feedback: '' },
  verificationStatusChanged: undefined,
};

export default enhance(InsightsReviewManager);
