import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import DateRangePicker from 'source/scenes/components/dateRangePicker';

class ReduxFormDateRangePicker extends React.Component {
  // map react-dates onDatesChange to redux-form's input.onChange so we track
  // date changes in the redux state
  onDatesChange = ({ startDate, endDate }) => {
    const currentStartDate = this.props.startDate.input.value;
    const currentEndDate = this.props.endDate.input.value;

    if (startDate !== currentStartDate) {
      this.props.startDate.input.onChange(startDate);
    }

    if (endDate !== currentEndDate) {
      this.props.endDate.input.onChange(endDate);
    }

    this.props.onDatesChange({ startDate, endDate });
  };

  render() {
    const currentStartDate = this.props.startDate.input.value || null;
    const currentEndDate = this.props.endDate.input.value || null;

    return (
      <DateRangePicker
        {...this.props.dateRangePickerProps}
        // overwrite startDate and endDate with the input moment object values
        startDate={currentStartDate}
        endDate={currentEndDate}
        // intercept the onDatesChange handler
        onDatesChange={this.onDatesChange}
      />
    );
  }
}

ReduxFormDateRangePicker.propTypes = {
  startDate: PropTypes.shape({
    input: PropTypes.shape({
      // moment date object or empty string if not set
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      onChange: PropTypes.func,
    }).isRequired,
  }).isRequired,
  endDate: PropTypes.shape({
    input: PropTypes.shape({
      // moment date object or empty string if not set
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      onChange: PropTypes.func,
    }).isRequired,
  }).isRequired,
  onDatesChange: PropTypes.func,
  // props to pass through to the DateRangePicker component to allow
  // customization
  dateRangePickerProps: PropTypes.object,
};

ReduxFormDateRangePicker.defaultProps = {
  dateRangePickerProps: {},
};

ReduxFormDateRangePicker.defaultProps = {
  onDatesChange: noop,
};

export default ReduxFormDateRangePicker;
