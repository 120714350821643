import moment from 'moment';

import { keyedAsyncActionCreator } from '@blogfoster/redux-async-utils';

import { checkboxActionCreator } from 'source/scenes/components/checkbox/redux';
import { actionAuthRequest } from 'source/utils/axios';

export const namespace = 'scenes/applicationsOverview/applicationDetail';
export const actionTypes = {
  INSIGHTS_FETCH: `${namespace}/INSIGHTS_FETCH`,
  PUBLISHING_DATES_FETCH: `${namespace}/PUBLISHING_DATES_FETCH`,
};

export const updateToggleState = (applicationId, name, value) =>
  checkboxActionCreator(`${namespace}/${name}`, { key: applicationId })(value);

const kpiChannels = ['tiktok'];
const keyMetricsChannels = ['instagram'];
const getDefaultFields = (campaignType) => {
  const fields = ['insights'];

  if (campaignType === 'website') {
    fields.push('channel.performance');
    fields.push('channel.metrics');
  } else if (kpiChannels.includes(campaignType)) {
    fields.push('channel.kpi');
  } else if (keyMetricsChannels.includes(campaignType)) {
    fields.push('channel.keyMetrics');
    fields.push('channel.updatedAt');
    fields.push('channel.ready');
    fields.push('channel.readyState');
  }

  return fields;
};

export const loadApplicationInsights = keyedAsyncActionCreator(
  actionTypes.INSIGHTS_FETCH,
  (applicationId, campaign, fields) => (dispatch, getState) => {
    const params = {
      fields: JSON.stringify(fields || getDefaultFields(campaign.type)),
    };

    return actionAuthRequest(dispatch, getState, {
      dispatchErrors: false,
    }).get(`/v2/applications/${applicationId}`, { params });
  },
);

export const loadApplicationPublishingDates = keyedAsyncActionCreator(
  actionTypes.PUBLISHING_DATES_FETCH,
  (applicationId, campaignId) => (dispatch, getState) => {
    const params = {
      filters: JSON.stringify([
        { campaignId },
        // NOTE: We need to also get the already assigned publishing dates
        //       because otherwise we aren't able to display the date an
        //       application was assigned to.
        // { assigned: false },
        { vip: true },
        {
          date: {
            greater: moment().startOf('day').toISOString(),
          },
        },
      ]),
    };

    return actionAuthRequest(dispatch, getState, {
      dispatchError: false,
    }).get('/v2/publishing-dates', { params });
  },
);
