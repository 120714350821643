import { createStructuredSelector } from 'reselect';
import get from 'lodash/get';

const getInitialValues = (state, props) => {
  const productShipment = get(props, 'campaign.productShipment', {});
  const productChoices = get(productShipment, 'productChoices', []);

  return {
    ...productShipment,
    productChoices,
  };
};

const getHeadline = () => 'Product Shipment';

const getSubHeadline = (state, props) => {
  const formState = state.form[props.form];

  if (formState?.values?.enabled) {
    return 'Product will be shipped to each influencer';
  }

  return '';
};

export default createStructuredSelector({
  initialValues: getInitialValues,
  headline: getHeadline,
  subHeadline: getSubHeadline,
});
