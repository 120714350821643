import { combineReducers } from 'redux';

import root from './root/reducer';
import setup from './setup/reducer';
import missions from './missions/reducer';
import applications from './applications/reducer';
import { reducer as reporting } from './reporting/websiteBucket';
import { reducer as todos } from './todos';
import { reducer as review } from './review/data';

export default combineReducers({
  root,
  setup,
  missions,
  applications,
  reporting,
  todos,
  review,
});
