import { createConstants } from 'source/utils';

const actionTypes = createConstants(
  // loading a given campaign by id
  'CAMPAIGN_STATE_LOADING',
  'CAMPAIGN_STATE_LOADED',
  // preparing the context for a new campaign
  'CAMPAIGN_STATE_EMPTY_LOADED',
);

export default actionTypes;
