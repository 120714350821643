import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import withResetScene from 'source/components/commonEnhancers/withResetScene';
import { Alert } from 'source/components/common';

import AddGroupForm from './addGroupForm';
import actions from '../actions';
import { getAddGroupState } from '../selectors';

const enhance = compose(connect(getAddGroupState, actions), withResetScene);

function AddGroup(props) {
  return (
    <div className="container">
      <Alert {...props.alert} onClose={props.onAlertClose} />
      <h2>Create New Group</h2>
      <p className="text-muted">
        To create a new group, choose a name and specify a color using the color
        picker. Set the color to white #FFFFFF to hide the group from the list
        of applications.
      </p>
      <hr />
      <div className="row">
        <div className="col">
          <AddGroupForm
            form={props.addGroupForm}
            onChange={props.onAddGroupFormChange}
            onSubmit={props.onAddGroupFormSubmit}
            onCancel={props.onAddGroupFormCancel}
          />
        </div>
      </div>
    </div>
  );
}

AddGroup.propTypes = {
  addGroupForm: PropTypes.shape({
    name: PropTypes.object.isRequired,
    color: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  }).isRequired,
  onAddGroupFormChange: PropTypes.func.isRequired,
  onAddGroupFormSubmit: PropTypes.func.isRequired,
  onAddGroupFormCancel: PropTypes.func.isRequired,
  alert: PropTypes.object,
  onAlertClose: PropTypes.func.isRequired,
};

export default enhance(AddGroup);
