import PropTypes from 'prop-types';
import get from 'lodash/get';

import { dedent } from 'source/utils';

const typeToDescription = {
  'application:review': dedent(`
      A user send their application for this campaign, which now needs
      to be reviewed.`),
  'confirmation:delay': dedent(`
      A user was approved, but they didn't confirm their mission for a
      while. We should contact them.`),
  'confirmation:refused': dedent(`
      An approved user refused the missions. We should contact them now.`),
  'delivery:pending': dedent(`
      The user provided their address, now we need to send them the
      package`), // @deprecated
  'shipment:pending': dedent(`
      The user provided their address, now we need to send them the
      package`),
  'client-content-review:review': dedent(`
    The client has reviewed the draft. Now we need to approve or reject the mission.`),
  'article:review': dedent(`
      The user submitted their new article. Now we need to review it.`),
  'article-url:review': dedent(`
      The user submitted their article url. Now we need to check the
      article behind the url.`),
  'social-media-sharing:review': dedent(`
      The user submitted their social media urls. Please verify the URLs are
      correct and, if marked as not available, that the user really has no
      account on the platform.`),
  'after-publication-uploads:review': dedent(`
      The user submitted their Instagram post insights. Please verify that they are correct.`),
  'application-rating-by-manager:pending': dedent(`
      The mission has been completed. Now is the best time to rate the Influencer's performance. `),
};

const typeToLabel = {
  'application:review': 'Review the application',
  'confirmation:delay': 'Check mission confirmation',
  'confirmation:refused': 'Check the reason of mission refuse',
  'delivery:pending': 'Send the package', // @deprecated
  'shipment:pending': 'Send the package',
  'client-content-review:review':
    'Update the mission status after the client review',
  'article:review': 'Review the article draft',
  'article-url:review': 'Review the posted article',
  'social-media-sharing:review': 'Review the social media urls',
  'after-publication-uploads:review': 'Review the post insights',
  'application-rating-by-manager:pending': 'Rate Influencer',
};

const getBaseUrl = ({ id, type, pricing }) => {
  const campaignId = id;
  let campaignType = '';
  if (type === 'website' && pricing === 'bucket') {
    campaignType = 'website-bucket';
  } else if (type === 'website' && pricing === 'budget') {
    campaignType = 'website-budget';
  } else if (type === 'instagram') {
    campaignType = 'instagram';
  } else if (type === 'youtube') {
    campaignType = 'youtube';
  } else if (type === 'tiktok') {
    campaignType = 'tiktok';
  } else if (type === 'pinterest') {
    campaignType = 'pinterest';
  } else {
    throw new Error(`cannot define campaign type for [${type}|${pricing}]`);
  }

  return `/campaigns/${campaignType}/${campaignId}`;
};

// Todo Model Helpers
// - used in todo actions to specifiy the correct action handler based on the `todo.code`
const TodoModel = {
  typeOf: {
    confirmationDelay: (todo) => todo.type === 'confirmation:delay',
    confirmationRefused: (todo) => todo.type === 'confirmation:refused',
    deliveryPending: (todo) => todo.type === 'delivery:pending', // @deprecated
    shipmentPending: (todo) => todo.type === 'shipment:pending',
  },
  id: (todo) => todo.id,
  applicationId: (todo) => todo.applicationId,
  campaignId: (todo) => todo.campaignId,
  type: (todo) => todo.type,
  username: (todo) => get(todo, 'application.user.firstname'),
  userEmail: (todo) => get(todo, 'application.user.email'),
  channelName: (todo) => get(todo, 'application.channel.name'),
  description: (todo) => typeToDescription[todo.type] || 'n/a',
  applicationUrl: (todo, campaign) =>
    `${getBaseUrl(campaign)}/applications/${todo.application.id}`,
  missionUrl: (todo, campaign) =>
    `${getBaseUrl(campaign)}/missions/${todo.application.id}`,
  label: (todo) => typeToLabel[todo.type] || 'n/a',
};

export const TodoShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    user: PropTypes.shape({
      firstname: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
    channel: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
});

export const CampaignShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  pricing: PropTypes.string.isRequired,
});

export default TodoModel;
