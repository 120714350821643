import React from 'react';
import PropTypes from 'prop-types';
import { Combobox } from 'react-input-enhancements';
import cx from 'classnames';
import noop from 'lodash/noop';

import { idType } from 'source/utils/propTypes';
import ExternalLink from 'source/scenes/components/externalLink';

export function InputError({ error }) {
  return error ? (
    <small className="invalid-feedback">{error.message}</small>
  ) : null;
}

InputError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
};

export function StaticText({ id, name, value }) {
  return (
    <div className="form-group row">
      <label htmlFor={id} className="col-md-2">
        {name}
      </label>
      <div className="col-md-10">
        <input
          readOnly
          className="form-control-plaintext"
          defaultValue={value}
        />
      </div>
    </div>
  );
}

StaticText.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export function StaticLink({ id, name, value }) {
  return (
    <div className="form-group row">
      <label htmlFor={id} className="col-md-2">
        {name}
      </label>
      <div className="col-md-10">
        <ExternalLink href={value}>{value}</ExternalLink>
      </div>
    </div>
  );
}

StaticLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export class InputText extends React.Component {
  handleChange = (e) => {
    e.preventDefault();

    return this.props.onChange(e.target.id, { value: e.target.value });
  };

  render() {
    const { name, error, id, onChange, ...inputProps } = this.props;

    return (
      <div className="form-group row">
        <label htmlFor={id} className="col-md-2">
          {name}
        </label>
        <div className="col-md-10">
          <input
            id={id}
            className={cx('form-control', { 'is-invalid': error })}
            onChange={this.handleChange}
            {...inputProps}
          />
          <InputError error={error} />
        </div>
      </div>
    );
  }
}

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export class InputColor extends React.Component {
  handleChange = (e) => {
    e.preventDefault();

    return this.props.onChange(e.target.id, { value: e.target.value });
  };

  render() {
    const { name, id, value, error } = this.props;

    return (
      <div className="form-group row">
        <label htmlFor={id} className="col-md-2">
          {name}
        </label>
        <div className="col-md-10">
          <input
            type="color"
            id={id}
            value={value}
            className={cx({ 'is-invalid': error })}
            onChange={this.handleChange}
          />
          <InputError error={error} />
        </div>
      </div>
    );
  }
}

InputColor.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export function EditableInputText(props) {
  return props.edit ? <InputText {...props} /> : <StaticText {...props} />;
}

EditableInputText.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  edit: PropTypes.bool,
};

export function EditableInputLink(props) {
  return props.edit ? <InputText {...props} /> : <StaticLink {...props} />;
}

EditableInputLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  edit: PropTypes.bool,
};

export class InputSelect extends React.PureComponent {
  handleValueChange = (selectedItem) => {
    this.props.onChange(this.props.id, selectedItem || { value: '' });
    this.props.onBlur();
  };

  handleInputChange = (onChange) => (e) => {
    onChange(e);
    this.props.onFetch(e.target.value);
  };

  handleKeyDown = (e) => {
    const keyMap = {
      Enter(e) {
        // prevents submit of create link form
        e.preventDefault();
      },
    };

    if (keyMap[e.key]) {
      keyMap[e.key](e);
    }
  };

  render() {
    const { id, name, options, value, error, placeholder, disabled } =
      this.props;

    const mappedOptions = options.map((o) => ({ text: o.value, value: o }));

    return (
      <div className="form-group row">
        <label htmlFor={id} className="col-md-2">
          {name}
        </label>
        <div className="col-md-10">
          <Combobox
            value={value}
            options={mappedOptions}
            dropdownProps={{ style: { width: '100%' } }}
            onSelect={this.handleValueChange}
            onKeyDown={this.handleKeyDown}
          >
            {(inputProps) => (
              <div>
                <input
                  {...inputProps}
                  disabled={disabled}
                  type="text"
                  onChange={this.handleInputChange(inputProps.onChange)}
                  placeholder={placeholder || name}
                  className={cx(inputProps.className, 'form-control', {
                    'is-invalid': error,
                  })}
                />
                <InputError error={error} />
              </div>
            )}
          </Combobox>
        </div>
      </div>
    );
  }
}

InputSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: idType.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string,
  error: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFetch: PropTypes.func,
};

InputSelect.defaultProps = {
  onBlur: noop,
  onFetch: noop,
  disabled: false,
};

export function EditableInputSelect(props) {
  return props.edit ? <InputSelect {...props} /> : <StaticText {...props} />;
}

EditableInputSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: idType.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string,
  error: PropTypes.any,
  edit: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export function EditRecordButton({ onClick }) {
  return (
    <button className="btn btn-danger" onClick={onClick}>
      Modify
    </button>
  );
}

EditRecordButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export function SaveOrCancelButton({ onSave, onCancel }) {
  return (
    <div>
      <button type="submit" className="btn btn-primary" onClick={onSave}>
        Save
      </button>

      <button
        type="button"
        className="btn btn-outline-danger ml-1"
        onClick={onCancel}
      >
        Cancel
      </button>
    </div>
  );
}

SaveOrCancelButton.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
