import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import moment from 'moment';
import cx from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

import ButtonWithConfirmation from 'source/components/common/buttonWithConfirmation';
import ConfirmButton from 'source/scenes/components/confirmButton';
import ConfirmInputButton from 'source/scenes/components/confirmInputButton';
import { campaignStatuses } from 'source/constants';

import AllowApplicationsForm from '../allowApplications';
import {
  withOnCloseCampaign,
  withProgressActions,
  withPropsSelectorApplied,
} from './enhancers';

const enhance = compose(
  withOnCloseCampaign,
  withProgressActions,
  withPropsSelectorApplied,
);

class CampaignProgress extends React.PureComponent {
  renderHeading() {
    const { campaign } = this.props;

    if (campaign && campaign.status === campaignStatuses.published) {
      const date = moment(campaign.publishedAt).format('DD/MM/YYYY');

      return (
        <h5 className="card-title">
          Published <small>({date})</small>
        </h5>
      );
    }

    if (campaign && campaign.status === campaignStatuses.done) {
      const date = moment(campaign.doneAt).format('DD/MM/YYYY');

      return (
        <h5 className="card-title">
          Done <small>({date})</small>
        </h5>
      );
    }

    return <h5 className="card-title">Progress</h5>;
  }

  renderMissing() {
    const { missingFields } = this.props;
    const label = get(missingFields, '[0].label');

    if (!label) {
      return null;
    }

    return (
      <div className="card-body">
        <div className="pb-2">
          <b>Still missing:</b>
        </div>
        <span>{label}</span>
      </div>
    );
  }

  renderAllowApplications() {
    const { campaign, allowApplicationsForm, onUpdateCampaign } = this.props;

    // dont render "allow applications" if campaign was not yet created
    if (!campaign) {
      return null;
    }

    return (
      <div className="card-body">
        <AllowApplicationsForm
          form={allowApplicationsForm}
          campaign={campaign}
          onUpdateCampaign={onUpdateCampaign}
        />
      </div>
    );
  }

  renderPublishButton() {
    const { campaign, progress, onPublishCampaign } = this.props;

    // can only publish draft campaigns
    if (!campaign || campaign.status !== campaignStatuses.draft) {
      return null;
    }

    // can only publish campaign progress is 100%
    const disabled = progress < 1;

    return (
      <button
        type="submit"
        className="btn btn-success btn-block"
        disabled={disabled}
        onClick={onPublishCampaign}
      >
        Publish
      </button>
    );
  }

  renderDeleteButton() {
    const { campaign, onDeleteCampaign } = this.props;

    // can only delete draft campaigns
    if (!campaign || campaign.status !== campaignStatuses.draft) {
      return null;
    }

    return (
      <ButtonWithConfirmation
        onConfirm={onDeleteCampaign}
        button={<button className="btn btn-danger btn-block">Delete</button>}
      >
        <h4>Are you sure you want to delete?</h4>
      </ButtonWithConfirmation>
    );
  }

  renderArchiveButton() {
    const { campaign, onArchiveCampaign } = this.props;
    const { id, type, status, summary = {} } = campaign || {};

    // can only archive published campaigns
    if (!campaign || status !== campaignStatuses.published) {
      return null;
    }

    const { total: numberOfTodos } = summary;

    if (numberOfTodos > 0) {
      const todosUrl = `/campaigns/${type}/${id}/todos`;

      return (
        <ButtonWithConfirmation
          button={<button className="btn btn-block btn-danger">Archive</button>}
          onConfirm={onArchiveCampaign}
          yesButtonLabel="Archive"
          noButtonLabel="Don't archive"
        >
          <p>
            There {numberOfTodos > 1 ? 'are' : 'is'} still {numberOfTodos}{' '}
            <Link to={todosUrl}>Todo{numberOfTodos > 1 ? 's' : ''}</Link> open
            for this campaign.
          </p>
        </ButtonWithConfirmation>
      );
    }

    return (
      <ConfirmButton
        className="btn btn-block"
        initialClassName="btn-danger"
        confirmClassName="btn-warning"
        doneClassName="btn-success"
        onConfirm={onArchiveCampaign}
      >
        Archive
      </ConfirmButton>
    );
  }

  renderDuplicateButton() {
    const { campaign, onDuplicateCampaign } = this.props;

    if (!campaign || !campaign.name) {
      return null;
    }

    const campaignDuplicateName = `${campaign.name} Copy`;
    const handleDuplicateCampaign = (name) => onDuplicateCampaign({ name });

    return (
      <ConfirmInputButton
        className="btn btn-block"
        initialClassName="btn-primary"
        confirmClassName="btn-warning"
        initialInputValue={campaignDuplicateName}
        doneClassName="btn-success"
        confirm="Duplicate"
        onConfirm={handleDuplicateCampaign}
      >
        New campaign from this...
      </ConfirmInputButton>
    );
  }

  renderDoneButton() {
    const { campaign, onDoneCampaign } = this.props;

    if (!campaign || campaign.status !== campaignStatuses.archived) {
      return null;
    }

    return (
      <ConfirmButton
        className="btn btn-block"
        initialClassName="btn-primary"
        confirmClassName="btn-warning"
        doneClassName="btn-success"
        onConfirm={onDoneCampaign}
      >
        Set as Done
      </ConfirmButton>
    );
  }

  render() {
    const { progress, onCloseCampaign } = this.props;

    const progressPercent = Number(progress * 100);
    const progressFulfilled = progressPercent === 100;

    const progressBarClassNames = cx('progress', {
      'progress-success': progressFulfilled,
      'progress-striped': !progressFulfilled,
    });

    return (
      <div className="card progress-card">
        <div className="card-body">
          {this.renderHeading()}
          <progress
            className={progressBarClassNames}
            value={progressPercent}
            max="100"
          />
        </div>

        {this.renderMissing()}
        {this.renderAllowApplications()}

        <div className="card-body">
          {this.renderDuplicateButton()}
          {this.renderDoneButton()}
          {this.renderArchiveButton()}
          {this.renderPublishButton()}
          {this.renderDeleteButton()}
          <button
            type="submit"
            className="btn btn-outline-secondary btn-block"
            onClick={onCloseCampaign}
          >
            ← Back to Campaigns
          </button>
        </div>
      </div>
    );
  }
}

CampaignProgress.propTypes = {
  progress: PropTypes.number.isRequired, // number between 0 and 1
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    publishedAt: PropTypes.string,
    doneAt: PropTypes.string,
    type: PropTypes.string.isRequired,
    summary: PropTypes.shape({
      todos: PropTypes.shape({
        total: PropTypes.number.isRequired,
      }).isRequired,
    }),
  }),
  allowApplicationsForm: PropTypes.string,
  missingFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
  ),
  onPublishCampaign: PropTypes.func.isRequired,
  onArchiveCampaign: PropTypes.func.isRequired,
  onDoneCampaign: PropTypes.func.isRequired,
  onDeleteCampaign: PropTypes.func.isRequired,
  onCloseCampaign: PropTypes.func.isRequired,
  onUpdateCampaign: PropTypes.func.isRequired,
  onDuplicateCampaign: PropTypes.func.isRequired,
};

CampaignProgress.defaultProps = {
  campaign: undefined,
  allowApplicationsForm: undefined,
  missingFields: [],
};

export default enhance(CampaignProgress);
