import React from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import { useLocation } from 'react-router-dom';

import { accessTokenFetched } from 'source/actions/auth';
import ErrorBoundary from 'source/utils/ErrorBoundary';

function Access() {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(useLocation().search);
  const accessToken = queryParams.get('access_token');

  const delayedRedirect = (to) => {
    setTimeout(() => dispatch(replace(to)), 1500);
  };

  React.useEffect(() => {
    if (accessToken) {
      dispatch(accessTokenFetched(accessToken));
      delayedRedirect('/');
    }
    delayedRedirect('/signin');
  }, []);

  return (
    <ErrorBoundary>
      <div className="flex-center">
        <div className="flex-center-item">
          <h3>Redirecting back to Control Center...</h3>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Access;
