import { asyncActionCreator } from '@blogfoster/redux-async-utils';

import { actionAuthRequest } from 'source/utils/axios';

export const actionTypes = {
  FETCH_MANY: 'data/groups/FETCH_MANY',
  FETCH_ONE: 'data/groups/FETCH_ONE',
  CREATE: 'data/groups/CREATE',
  PUT_USER: 'data/groups/PUT_USER',
  DELETE_USER: 'data/groups/DELETE_USER',
  PATCH_USER_STATUS: 'data/groups/PATCH_USER_STATUS',
};

/**
 * GET /v1/groups
 */
export const groupFetchMany = (name) =>
  asyncActionCreator(
    actionTypes.FETCH_MANY,
    ({ page = 0, fields = [], filters = [] }) =>
      (dispatch, getState) => {
        const params = { page: page + 1 };

        if (filters && filters.length > 0) {
          params.filters = JSON.stringify(filters);
        }

        if (fields && fields.length > 0) {
          params.fields = JSON.stringify(fields);
        }

        return (
          actionAuthRequest(dispatch, getState, { dispatchErrors: true })
            .get('/v1/groups', { params })
            // HACKY: to save the page correctly we inject a page header to the response
            .then((resp) => {
              resp.headers['x-page'] = page;
              return resp;
            })
        );
      },
    { name },
  );

/**
 * POST /v1/groups
 */
export const groupCreate = (name) =>
  asyncActionCreator(
    actionTypes.CREATE,
    (payload) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false }).post(
        '/v1/groups',
        payload,
      ),
    { name },
  );

/**
 * GET /v1/groups/:id
 */
export const groupFetch = (name) =>
  asyncActionCreator(
    actionTypes.FETCH_ONE,
    (groupId) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: true }).get(
        `/v1/groups/${groupId}`,
      ),
    { name },
  );

/**
 * PUT /v1/groups/:gid/users/:uid
 */
export const groupPutUser = (name) =>
  asyncActionCreator(
    actionTypes.PUT_USER,
    ({ groupId, userId }) =>
      (dispatch, getState) =>
        actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
        }).put(`/v1/groups/${groupId}/user/${userId}`, { groupId, userId }),
    { name },
  );

/**
 * DELETE /v1/groups/:gid/users/:uid
 */
export const groupDeleteUser = (name) =>
  asyncActionCreator(
    actionTypes.DELETE_USER,
    (groupId, userId) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false })
        .delete(`/v1/groups/${groupId}/user/${userId}`)
        .then(() => ({ userId })),
    { name },
  );

/**
 * PATCH /v1/groups/:gid/users/:uid
 */
export const groupUserPatchStatus = (name) =>
  asyncActionCreator(
    actionTypes.PATCH_USER_STATUS,
    (groupId, userId, payload) => (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false }).patch(
        `/v1/groups/${groupId}/user/${userId}`,
        payload,
      ),
    { name },
  );
