import { asyncActionReducer, asyncStates } from '@blogfoster/redux-async-utils';
import pick from 'lodash/pick';
import {
  namespacedReducer,
  nestedReducer,
  combineReducersFlat,
} from 'source/utils/redux';

import { actionTypes as channelActionTypes } from 'source/data/channels/actions';
import { getPagingFromHeaders } from 'source/components/common/pagination';
import { redux as collapsiblePanelRedux } from 'source/components/common/collapsiblePanel';

import { namespace, actionTypes } from './actions';

const getInitialChannelState = () => ({
  platform: '',
  data: {},
  user: {},
  readyState: {},
  ready: false,
  screenshots: {},
});

const getInitialState = () => ({
  state: '',
  submitting: false,
  submitted: false,
  insightsSubmitting: false,
  insightsSubmitted: false,
  error: null,

  channelFormInitialValues: {},
  channel: getInitialChannelState(),
  channels: [],

  paging: {
    page: 0,
    pageSize: 30,
    count: 0,
    total: 0,
  },

  filters: {
    term: '',
  },
});

const metricsFieldsByPlatform = {
  instagram: [
    'keyMetrics.followers',
    'keyMetrics.followersHistory',
    'keyMetrics.engagementRate',
    'keyMetrics.audience',
    'keyMetrics.storiesImpressions',
    'keyMetrics.postsImpressions',
    'keyMetrics.storyImpressionRatio',
    'keyMetrics.postImpressionRatio',
    'keyMetrics.metricsValidAt',
  ],
  tiktok: [
    'kpi.followers',
    'kpi.viewsTotal',
    'kpi.viewsPerVideo',
    'kpi.geoStatistic',
    'kpi.audienceStatistic',
  ],
};

const getChannelInitialValues = (channel) => {
  const metricsFields = metricsFieldsByPlatform[channel.platform] || [];

  const channelFormInitialValues = pick(channel, [
    'data.url',
    'notes',
    'kpi.statisticDate',
    ...metricsFields,
  ]);

  // inverse qualityReview to correct toggle display
  if (typeof channel.qualityReview === 'boolean') {
    channelFormInitialValues.qualityReview = !channel.qualityReview;
  }

  channelFormInitialValues.timeStamp = new Date();

  return channelFormInitialValues;
};

const channelDataReducer = asyncActionReducer(channelActionTypes.FETCH_ONE, {
  [asyncStates.pending]: (state) => ({
    ...state,
    state: 'loading',
    error: null,
  }),
  [asyncStates.success]: (state, { payload: { data: channel } }) => ({
    ...state,
    state: 'loaded',
    error: null,
    channel,
    channelFormInitialValues: getChannelInitialValues(channel),
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    state: 'failure',
    error,
  }),
});

const channelDeleteReducer = asyncActionReducer(channelActionTypes.DELETE_ONE, {
  [asyncStates.pending]: (state) => ({
    ...state,
    state: 'loading',
    error: null,
  }),
  [asyncStates.success]: (state) => ({
    ...state,
    state: 'loaded',
    error: null,
    alert: {
      type: 'success',
      message: `Channel was successfully deleted.`,
    },
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    error,
    state: 'failure',
    alert: {
      type: 'danger',
      message: `Sorry, channel was not deleted. ${error.message}`,
    },
  }),
});

const channelPatchReducer = asyncActionReducer(channelActionTypes.PATCH_ONE, {
  [asyncStates.pending]: (state) => ({
    ...state,
    submitting: true,
    submitted: false,
    error: null,
  }),
  [asyncStates.success]: (state, { payload: { data: channel } }) => ({
    ...state,
    submitting: false,
    submitted: true,
    channel: { ...state.channel, ...channel },
    channelFormInitialValues: getChannelInitialValues(channel),
    alert: null,
    error: null,
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    submitting: false,
    submitted: false,
    error,
    alert: {
      type: 'danger',
      message: `Sorry, channel was not updated. ${error.message}`,
    },
  }),
});

const channelUpdateKpiReducer = asyncActionReducer(
  channelActionTypes.UPDATE_KPI,
  {
    [asyncStates.pending]: (state) => ({
      ...state,
      insightsSubmitting: true,
      insightsSubmitted: false,
      error: null,
    }),
    [asyncStates.success]: (state, { payload: { data: channel } }) => ({
      ...state,
      insightsSubmitting: false,
      insightsSubmitted: true,
      channel: { ...state.channel, ...channel },
      channelFormInitialValues: getChannelInitialValues(channel),
      alert: null,
      error: null,
    }),
    [asyncStates.failure]: (state, { payload: error }) => ({
      ...state,
      insightsSubmitting: false,
      insightsSubmitted: false,
      error,
      alert: {
        type: 'danger',
        message: `Sorry, channel was not updated. ${error.message}`,
      },
    }),
  },
);

const channelUpdateKeyMetricsReducer = asyncActionReducer(
  channelActionTypes.UPDATE_KEY_METRICS,
  {
    [asyncStates.pending]: (state) => ({
      ...state,
      insightsSubmitting: true,
      insightsSubmitted: false,
      error: null,
    }),
    [asyncStates.success]: (state, { payload: { data: channel } }) => ({
      ...state,
      insightsSubmitting: false,
      insightsSubmitted: true,
      channel: { ...state.channel, ...channel },
      channelFormInitialValues: getChannelInitialValues(channel),
      alert: null,
      error: null,
    }),
    [asyncStates.failure]: (state, { payload: error }) => ({
      ...state,
      insightsSubmitting: false,
      insightsSubmitted: false,
      error,
      alert: {
        type: 'danger',
        message: `Sorry, channel was not updated. ${error.message}`,
      },
    }),
  },
);

const channelsDataReducer = asyncActionReducer(channelActionTypes.FETCH_MANY, {
  [asyncStates.pending]: (state) => ({
    ...state,
    state: 'loading',
    error: null,
  }),
  [asyncStates.success]: (state, { payload }) => ({
    ...state,
    state: 'loaded',
    error: null,
    channels: payload.data,
    paging: getPagingFromHeaders(payload.headers),
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    state: 'failure',
    error,
  }),
});

const filtersReducer = (state = {}, action) => {
  if (action.type === actionTypes.FILTER_RESET) {
    return {
      term: '',
    };
  }

  if (action.type !== actionTypes.FILTER_CHANGED) {
    return state;
  }

  const { filter, value } = action.payload;

  return {
    ...state,
    [filter]: value,
  };
};

const resetState = (state = {}, action) => {
  if (action.type !== actionTypes.RESET_SCENE) {
    return state;
  }
  return {
    ...state,
    state: '',
    submitting: false,
    submitted: false,
    insightsSubmitting: false,
    insightsSubmitted: false,
    error: null,
    channel: getInitialChannelState(),
    channels: [],
    panels: {
      expanded: null,
    },
  };
};

const alertReducer = (state, { type, alert }) => {
  if (type !== actionTypes.CHANNEL_ALERT_CHANGED) {
    return state;
  }

  return {
    ...state,
    alert,
  };
};

const panelReducer = collapsiblePanelRedux.reducers.distinctToggle(namespace);

const initialState = getInitialState();

export default combineReducersFlat(
  [
    namespacedReducer(namespace)(channelsDataReducer),
    namespacedReducer(namespace)(channelDataReducer),
    namespacedReducer(namespace)(channelPatchReducer),
    namespacedReducer(namespace)(channelUpdateKpiReducer),
    namespacedReducer(namespace)(channelUpdateKeyMetricsReducer),
    namespacedReducer(namespace)(channelDeleteReducer),
    nestedReducer('filters')(filtersReducer),
    nestedReducer('panels')(panelReducer),
    alertReducer,
    resetState,
  ],
  initialState,
);
