import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withHandlers, withStateHandlers, compose } from 'recompose';

const killEvent = (e) => {
  e.currentTarget.blur();
  e.preventDefault();
  e.stopPropagation();
};

const noopPromise = () => Promise.resolve();

const States = {
  initial: 'initial',
  confirmation: 'confirmation',
  waiting: 'waiting',
  done: 'done',
};

const withConfirmationState = withStateHandlers(
  { state: States.initial },
  { setBtnState: () => (state) => ({ state }) },
);

const withStateMachine = withHandlers({
  handleClick:
    ({
      state,
      withWaitingState = false,
      withDoneState = true,
      setBtnState,
      onConfirm = noopPromise,
    }) =>
    (e) => {
      killEvent(e);
      if (state === States.initial) {
        setBtnState(States.confirmation);
      } else if (state === States.confirmation) {
        const confirmation = onConfirm(e);
        const nextState = withDoneState ? States.done : States.initial;
        if (withWaitingState) {
          setBtnState(States.waiting);
          confirmation.then(() => setBtnState(nextState));
        } else {
          setBtnState(nextState);
        }
      }
    },
});

/**
 * Button which has a confirmation phase before the click handler is fired
 */
function ConfirmButton({
  className = '',
  initialClassName = '',
  state,
  disabled,
  confirm = 'Confirm',
  confirmClassName = '',
  waiting,
  waitingClassName = '',
  done = 'Success',
  doneClassName = '',
  handleClick,
  children,
}) {
  return (
    <button
      className={cx(
        'confirm-button',
        'btn',
        className,
        {
          [States.initial]: cx('confirm-button--initial', initialClassName),
          [States.confirmation]: cx(
            'confirm-button--confirmation',
            confirmClassName,
          ),
          [States.waiting]: cx('confirm-button--waiting', waitingClassName),
          [States.done]: cx('confirm-button--done', doneClassName),
        }[state],
      )}
      disabled={disabled || state === States.waiting || state === States.done}
      onClick={handleClick}
    >
      {
        {
          [States.initial]: children,
          [States.confirmation]: confirm,
          [States.waiting]: waiting || children,
          [States.done]: done,
        }[state]
      }
    </button>
  );
}

ConfirmButton.propTypes = {
  /**
   * The CSS class of the button which is always applied, no matter what state
   * it's in.
   */
  className: PropTypes.string,
  /**
   * The CSS class of the button which is only applied for the initial state.
   */
  initialClassName: PropTypes.string,
  disabled: PropTypes.bool,
  /**
   * Rendered when the button is in the confirmation state
   */
  confirm: PropTypes.node,
  /**
   * The CSS class of the button which is only applied for the confirm state.
   */
  confirmClassName: PropTypes.string,
  /**
   * Whether to transition to a waiting state between the confirmation
   * state and the end state. Assumes onConfirm yields a Promise
   *
   * Defaults to `false`
   */
  // eslint-disable-next-line react/no-unused-prop-types
  withWaitingState: PropTypes.bool,
  /**
   * Rendered when the button is in a waiting state
   */
  waiting: PropTypes.node,
  /**
   * The CSS class of the button which is only applied for the wait state.
   */
  waitingClassName: PropTypes.string,
  /**
   * Whether to transition to a done state after confirmation. If false,
   * the button will loop back to its initial state
   *
   * Defaults to `true`
   */
  // eslint-disable-next-line react/no-unused-prop-types
  withDoneState: PropTypes.bool,
  /**
   * Rendered when the button is in a done state
   */
  done: PropTypes.node,
  /**
   * The CSS class of the button which is only applied for the done state.
   */
  doneClassName: PropTypes.string,
  /**
   * Called only for the confirmation click
   */
  // eslint-disable-next-line react/no-unused-prop-types
  onConfirm: PropTypes.func,
  /**
   * Rendered when the button is in its initial state
   */
  children: PropTypes.any,

  // Internal

  /** @ignore */
  state: PropTypes.string.isRequired,
  /** @ignore */
  handleClick: PropTypes.func.isRequired,
};

export default compose(withConfirmationState, withStateMachine)(ConfirmButton);
