import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import forms from 'source/utils/forms';
import { FormControlFeedback } from 'source/components/common/forms';
import MarkdownTextAreaTabs from 'source/components/common/markdownTextAreaTabs';
import Feature from 'source/components/common/feature';

import { upperCaseFirst } from './helpers';

/*  == Reaction Form == */

/**
 * Generic Action Form that renders a textarea and submit button.
 */
const enhanceReactionForm = compose(
  forms({
    validateForm({ feedback }, { props: { action } }) {
      const errors = {};

      if (action === 'reject' && !feedback) {
        errors.feedback = 'feedback cannot be empty';
      }

      return errors;
    },

    contextFn: ({ application: { id, campaignId } }) => ({
      missionId: id,
      campaignId,
    }),
  }),
  pure,
);

function ReactionForm(props) {
  const {
    reaction,
    form: { errors = {}, feedback = '', state },
    onChange,
    onSubmit,
  } = props;

  const btnClass =
    reaction === 'accept' || reaction === 'yes' ? 'btn-success' : 'btn-danger';
  const submitDisabled = state === 'submitting' || !isEmpty(errors);

  return (
    <form>
      <div className="row">
        <div className="col">
          <div className={cx('form-group', { 'has-danger': errors.feedback })}>
            <MarkdownTextAreaTabs
              id="feedback"
              className={cx('form-control', 'mh-8', {
                'form-control-danger': errors.feedback,
              })}
              rows="5"
              value={feedback}
              onChange={onChange}
            />
            <FormControlFeedback message={errors.feedback} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button
            type="submit"
            className={cx('btn', btnClass, 'f-right')}
            onClick={onSubmit}
            disabled={submitDisabled}
          >
            {upperCaseFirst(reaction)}
          </button>
        </div>
      </div>
    </form>
  );
}

ReactionForm.propTypes = {
  reaction: PropTypes.oneOf(['accept', 'reject', 'yes']).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    feedback: PropTypes.string,
    state: PropTypes.oneOf(['pristine', 'dirty', 'submitting', 'submitted']),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ReactionForm = enhanceReactionForm(ReactionForm);

/* == Navigation (of actions) == */

/**
 * UI Nav-Bar that shows all possible actions.
 */
class Navigation extends React.Component {
  handleClick = (e) => {
    const {
      application: { id },
      onClick,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    return onClick(id, e.target.id); // actionId
  };

  render() {
    const { selectedReaction, possibleReactions } = this.props;

    return (
      <nav className="nav">
        {possibleReactions.map((reaction) => {
          // TODO: Move logic like this to the containers.
          const isPaymentReaction =
            reaction.id === 'create_application_payment';

          return (
            <Feature
              key={reaction.id}
              feature="allowCrediting"
              disabled={!isPaymentReaction}
            >
              <a
                id={reaction.label}
                className={cx('nav-link', reaction.label, {
                  active: selectedReaction === reaction.label,
                })}
                onClick={this.handleClick}
                href={`#${reaction.label}`}
              >
                {upperCaseFirst(reaction.label)}
              </a>
            </Feature>
          );
        })}
      </nav>
    );
  }
}

Navigation.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  selectedReaction: PropTypes.string,
  possibleReactions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

Navigation.defaultProps = {
  selectedReaction: null,
};

/**
 * Wraps `Navigation` and `ReactionForm` together, which is the entire
 * reaction UI for the user.
 */
class Reaction extends React.Component {
  renderReactionForm() {
    const {
      selectedReaction,
      forms: { reaction = {} },
    } = this.props;

    if (!selectedReaction) {
      return null;
    }

    return (
      <div className="row reaction-form">
        <div className="col">
          <ReactionForm
            {...this.props}
            id="reaction"
            form={reaction}
            reaction={selectedReaction}
          />
        </div>
      </div>
    );
  }

  render() {
    const { possibleReactions, onClickActionNavigation } = this.props;

    if (!possibleReactions || !possibleReactions.length) {
      return null;
    }

    return (
      <div className="conversation-reaction">
        <div className="row reaction-navigation">
          <div className="col">
            <Navigation {...this.props} onClick={onClickActionNavigation} />
          </div>
        </div>
        {this.renderReactionForm()}
      </div>
    );
  }
}

Reaction.propTypes = {
  selectedReaction: PropTypes.string,
  possibleReactions: PropTypes.array.isRequired,
  forms: PropTypes.shape({
    reaction: PropTypes.object,
  }).isRequired,
  onClickActionNavigation: PropTypes.func.isRequired,
};

Reaction.defaultProps = {
  selectedReaction: null,
};

export default Reaction;
