import React from 'react';
import { FormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';

function Counter({ counter, name }) {
  return (
    <div className="counter mx-3">
      <strong>
        <FormattedNumber value={counter.filtered} /> {name}
      </strong>
      <br />
      <span className="text-muted counter-total">
        <FormattedNumber value={counter.total} /> total
      </span>
    </div>
  );
}

Counter.propTypes = {
  counter: PropTypes.shape({
    total: PropTypes.number.isRequired,
    filtered: PropTypes.number.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
};

export default Counter;
