import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const statusToTitle = {
  accepted: 'Client Accepted',
  rejected: 'Client Rejected',
  undecided: 'Client Undecided',
};

function ClientReviewToolTip({ value: clientApplicationReview, ...props }) {
  const title = statusToTitle[clientApplicationReview.status];

  const subtitle = `Reviewed on ${moment(
    clientApplicationReview.updatedAt,
  ).format('DD.MM.YYYY, HH:mm')} by ${clientApplicationReview.updatedBy}`;

  return (
    <div className="client-review-tooltip" {...props}>
      <h2 className={`title title-${clientApplicationReview.status}`}>
        {title}
      </h2>
      <h5 className="subtitle">{subtitle}</h5>
      {clientApplicationReview.note && (
        <div className="note">{clientApplicationReview.note}</div>
      )}
    </div>
  );
}

ClientReviewToolTip.propTypes = {
  value: PropTypes.object.isRequired,
};

export default ClientReviewToolTip;
