import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';

function EventHeadline({ title, createdAt }) {
  return (
    <div className="card-header message-card-header">
      <span>{title}</span>
      <span className="text-muted message-date">
        <FormattedDate value={createdAt} /> <FormattedTime value={createdAt} />
      </span>
    </div>
  );
}

EventHeadline.propTypes = {
  title: PropTypes.string,
  createdAt: PropTypes.instanceOf(Date).isRequired,
};

EventHeadline.defaultProps = {
  title: 'n/a',
};

export default EventHeadline;
