import React from 'react';
import PropTypes from 'prop-types';

import ProductShipment from 'source/scenes/campaignDetail/applications/components/common/profile/productShipment';

const ApplicationInfo = React.memo(
  ({
    application: { id, productShipment: applicationProductShipment = {} },
    campaign: { productShipment: campaignProductShipment = {} },
    form: { product = '' },
    id: formId,
    onChange,
    onSubmit,
  }) => {
    const onProductShipmentChange = (e) =>
      onChange(formId, 'product', e.target.value, { missionId: id });
    const onProductShipmentSubmit = () =>
      onSubmit(formId, { product }, { missionId: id });

    const productShipment = {
      ...applicationProductShipment,
      ...campaignProductShipment,
    };

    return (
      <ProductShipment
        productShipment={productShipment}
        onChange={onProductShipmentChange}
        onSave={onProductShipmentSubmit}
        defaultMode="preview"
        title="Product choice"
        buttonsPosition="bottom"
        className="mt-2"
      />
    );
  },
);

ApplicationInfo.propTypes = {
  id: PropTypes.string.isRequired,
  campaign: PropTypes.shape({
    productShipment: PropTypes.object,
  }).isRequired,
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    productShipment: PropTypes.object,
    channel: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  form: PropTypes.shape({
    product: PropTypes.string,
  }).isRequired,

  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ApplicationInfo;
