import {
  campaignFetchMany,
  clearCampaigns,
} from 'source/data/campaigns/actions';
import { createStorage } from 'source/utils';

export const namespace = 'scenes/campaigns';

export const actionTypes = {
  FILTER_CHANGED: `${namespace}/FILTER_CHANGED`,
  RESET_SCENE: `${namespace}/RESET_SCENE`,
};

const listCampaigns = campaignFetchMany(namespace);

/* load the campaign state */
const load =
  (page = 0) =>
  (dispatch, getState) => {
    // @sean TODO Use selector. Really though, it would be preferable to pass the
    //       filter information into the action creator as argument
    const {
      scenes: {
        campaigns: { filters: campaignFilters = {} },
      },
    } = getState();

    const fields = ['id', 'name', 'type', 'status', 'summary', 'pricing'];

    const filters = [];

    if (!campaignFilters.showArchived) {
      filters.push({ status: { not: 'archived' } });
      filters.push({ status: { not: 'done' } });
    }

    if (campaignFilters.manager) {
      filters.push({
        'settings.managerName': { match: campaignFilters.manager },
      });
    }

    if (campaignFilters.campaignType) {
      filters.push({ type: campaignFilters.campaignType });
    }

    if (campaignFilters.allowApplications === 'open') {
      filters.push({ allowApplications: true });
    } else if (campaignFilters.allowApplications === 'closed') {
      filters.push({ allowApplications: false });
    }

    if (campaignFilters.term) {
      const encodedTerm = encodeURIComponent(campaignFilters.term);
      filters.push({ or: { name: { match: encodedTerm } } });
      filters.push({ or: { 'details.headline': { match: encodedTerm } } });
    }

    return dispatch(listCampaigns({ page, filters, fields }));
  };

// change filter and reload data
const changeFilter = (filter, value) => (dispatch, getState) => {
  // update the state
  dispatch({
    type: actionTypes.FILTER_CHANGED,
    payload: { filter, value },
  });

  // get the filters state
  const { filters } = getState().scenes.campaigns;

  // save manager filter to the localStorage
  createStorage().save('campaigns/filters', filters);
};

const resetScene =
  ({ campaignIds }) =>
  (dispatch) => {
    dispatch(clearCampaigns(campaignIds));
    dispatch({ type: actionTypes.RESET_SCENE });
  };

const actions = {
  onMount: load,
  onSelectPage: load,
  onFilterChange: changeFilter,
  onResetScene: resetScene,
};

export default actions;
