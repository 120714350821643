import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

/**
 * taken from aurum
 *
 * @see https://github.com/blogfoster/aurum/blob/develop/source/api/v2/channels/models/pricing/websiteBucket.js#L12
 */
export const contingents = sortBy(
  [
    {
      id: 0,
      label: '1 - 10K',
      reach: { min: 1, max: 10000 },
      price: 100,
      advertiserPrice: 200,
      bonus: 0,
      ignore: true,
    },
    {
      id: 1,
      label: '10K - 25K',
      reach: { min: 10000, max: 25000 },
      price: 200,
      advertiserPrice: 400,
      bonus: 0,
    },
    {
      id: 2,
      label: '25K - 75K',
      reach: { min: 25000, max: 75000 },
      price: 300,
      advertiserPrice: 600,
      bonus: 0,
    },
    {
      id: 3,
      label: '75K - 135K',
      reach: { min: 75000, max: 135000 },
      price: 500,
      advertiserPrice: 1000,
      bonus: 0,
    },
    {
      id: 4,
      label: '+135K',
      reach: { min: 135000 },
      price: 650,
      advertiserPrice: 1300,
      bonus: 0,
    },
    {
      id: 5,
      label: '1 - 5K',
      reach: { min: 1, max: 5000 },
      price: 30,
      advertiserPrice: 60,
      bonus: 0,
      ignore: true,
    },
    {
      id: 6,
      label: '5 - 10K',
      reach: { min: 5000, max: 10000 },
      price: 100,
      advertiserPrice: 200,
      bonus: 0,
    },
  ],
  'reach.min',
);

export const getReachOptionByReach = (reach) => find(contingents, { reach });
export const getReachOptionById = (id) => find(contingents, { id });
