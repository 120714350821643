import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import withResetScene from 'source/components/commonEnhancers/withResetScene';
import ButtonWithConfirmation from 'source/components/common/buttonWithConfirmation';
import { DownloadButton } from 'source/components/common';

import actions from '../actions';
import UserStatus from './userStatus/userStatus';

import { getGroupUsersState } from '../selectors';

const enhance = compose(connect(getGroupUsersState, actions), withResetScene);

class UserRow extends React.PureComponent {
  onConfirmRemoveUser = () => {
    const {
      onRemoveUser,
      group: { id: groupId },
      user: { id: userId },
    } = this.props;
    onRemoveUser(groupId, userId);
  };

  render() {
    const { group, user, onChangeGroupUserStatus } = this.props;

    return (
      <tr>
        <td>{user.firstname}</td>
        <td>
          <Link to={`/users/${user.id}`}>[{user.id}]</Link>
        </td>
        <td>{moment(user.createdAt).format('YYYY-MM-DD HH:mm')}</td>
        <td>{moment(user.updatedAt).format('YYYY-MM-DD HH:mm')}</td>
        <td>
          <div title={user.pitch} className="d-block truncate mw-20">
            {user.pitch}
          </div>
        </td>
        <td>
          <ButtonWithConfirmation
            button={<button className="btn btn-danger btn-sm">Remove</button>}
            onConfirm={this.onConfirmRemoveUser}
          >
            <p>
              Are you sure you want to remove user
              {user.firstname} from <em>{group.name}</em>?
            </p>
          </ButtonWithConfirmation>
        </td>
        <td>
          <UserStatus
            user={user}
            group={group}
            onChangeGroupUserStatus={onChangeGroupUserStatus}
          />
        </td>
      </tr>
    );
  }
}

UserRow.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstname: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    pitch: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  onChangeGroupUserStatus: PropTypes.func.isRequired,
};

class GroupUsers extends React.PureComponent {
  render() {
    const {
      group,
      onRemoveUserFromGroup,
      onChangeGroupUserStatus,
      usersReportUrl,
    } = this.props;

    return (
      <div>
        <div className="row justify-content-end pr-3">
          <DownloadButton href={usersReportUrl} iconName="cloud-download">
            Download as XLSX
          </DownloadButton>

          <Link
            to={`/groups/${group.id}/users/new`}
            className="btn btn-primary ml-2"
          >
            Add user
          </Link>
        </div>

        <div className="row pt-3">
          <div className="col">
            <table className="table table-striped table-group-users">
              <thead>
                <tr>
                  <th>User</th>
                  <th nowrap="true">User ID</th>
                  <th nowrap="true">Added At</th>
                  <th nowrap="true">Updated At</th>
                  <th>Notes</th>
                  <th>Remove</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {group.users.map((user) => (
                  <UserRow
                    key={user.id}
                    group={group}
                    user={user}
                    onRemoveUser={onRemoveUserFromGroup}
                    onChangeGroupUserStatus={onChangeGroupUserStatus}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

GroupUsers.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        firstname: PropTypes.string,
      }),
    ).isRequired,
  }),
  usersReportUrl: PropTypes.string.isRequired,
  onRemoveUserFromGroup: PropTypes.func.isRequired,
  onChangeGroupUserStatus: PropTypes.func.isRequired,
};

export default enhance(GroupUsers);
