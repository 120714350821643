import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { push } from 'connected-react-router';

import {
  groupFetchMany,
  groupCreate,
  groupFetch,
  groupPutUser,
  groupDeleteUser,
  groupUserPatchStatus,
} from 'source/data/groups/actions';
import { userFetchMany } from 'source/data/users/actions';

import { updateItem } from 'source/utils/imCollection';
import { isColorCode } from 'source/utils/validators';

export const namespace = 'scenes/groups';

export const actionTypes = {
  RESET_SCENE: `${namespace}/RESET_SCENE`,
  ADD_GROUP_FORM_CHANGE: `${namespace}/GRPOUP_FORM_CHANGE`,
  ADD_USER_FORM_CHANGE: `${namespace}/USER_FORM_CHANGE`,
  CLOSE_ALERT: `${namespace}/CLOSE_ALERT`,
};

const fetchGroups = groupFetchMany(namespace);
const fetchGroup = groupFetch(namespace);
const createGroup = groupCreate(namespace);
const fetchUsers = userFetchMany(namespace);
const addUserToGroup = groupPutUser(namespace);
const deleteUserFromGroup = groupDeleteUser(namespace);
const patchGroupUserStatus = groupUserPatchStatus(namespace);

const loadGroups =
  (page = 0) =>
  (dispatch) =>
    dispatch(fetchGroups({ page }));

const loadGroupDetails = (channelId) => (dispatch) =>
  dispatch(fetchGroup(channelId));

const validateAddGroupForm = (form) => {
  const errors = [];

  if (!form.name.value) {
    errors.push({
      id: 'name',
      message: 'Group name is required',
    });
  }

  if (!form.color.value || isEmpty(form.color.value.trim())) {
    // color validation for browsers that don't support the color picker
    errors.push({ id: 'color', message: 'Color is missing' });
  } else if (!isColorCode(form.color.value)) {
    errors.push({ id: 'color', message: 'Invalid RGB color code' });
  }

  return errors;
};

const validateAddUserForm = (form) => {
  const errors = [];

  if (!form.userId.value) {
    errors.push({
      id: 'userId',
      message: 'User is required',
    });
  }

  return errors;
};

const updateAddGroupForm = (form, attribute, value) => (dispatch) => {
  const updatedForm = updateItem(form, attribute, value);
  const errors = validateAddGroupForm(updatedForm);

  updatedForm.errors = keyBy(errors, 'id');

  dispatch({
    type: actionTypes.ADD_GROUP_FORM_CHANGE,
    payload: updatedForm,
  });
};

const updateAddUserForm = (form, attribute, value) => (dispatch) => {
  const updatedForm = updateItem(form, attribute, value);
  const errors = validateAddUserForm(updatedForm);

  updatedForm.errors = keyBy(errors, 'id');

  dispatch({
    type: actionTypes.ADD_USER_FORM_CHANGE,
    payload: updatedForm,
  });
};

const submitAddGroupForm = () => (dispatch, getState) => {
  const {
    scenes: {
      groups: { addGroupForm: form },
    },
  } = getState();

  const errors = validateAddGroupForm(form);

  if (errors.length > 0) {
    form.errors = keyBy(errors, 'id');

    return dispatch({
      type: actionTypes.ADD_GROUP_FORM_CHANGE,
      payload: form,
    });
  }

  const payload = {
    name: form.name.value,
    color: form.color.value,
  };

  return Promise.resolve()
    .then(() => dispatch(createGroup(payload)))
    .then(() => {
      const {
        scenes: {
          groups: {
            addGroupForm: { error },
          },
        },
      } = getState();
      if (!error) {
        dispatch(push(`/groups`));
      }
    });
};

const submitAddUserForm = (groupId) => (dispatch, getState) => {
  const {
    scenes: {
      groups: { addUserForm: form },
    },
  } = getState();

  const errors = validateAddUserForm(form);

  if (errors.length > 0) {
    form.errors = keyBy(errors, 'id');

    return dispatch({
      type: actionTypes.ADD_USER_FORM_CHANGE,
      payload: form,
    });
  }

  const {
    userId: { id: userId },
  } = form;
  const payload = { userId, groupId };

  return Promise.resolve()
    .then(() => dispatch(addUserToGroup(payload)))
    .then(() => {
      const {
        scenes: {
          groups: {
            addUserForm: { error },
          },
        },
      } = getState();
      if (!error) {
        dispatch(push(`/groups/${groupId}`));
      }
    });
};

const cancelAddGroupForm = () => (dispatch) => {
  dispatch(push('/groups'));
};

const loadUsers = () => (dispatch) => {
  const fields = ['id', 'email', 'firstname'];

  return dispatch(fetchUsers({ fields }));
};

export const cancelAddUserForm = (groupId) => (dispatch) => {
  dispatch(push(`/groups/${groupId}`));
};

export const removeUserFromGroup = (groupId, userId) => (dispatch) =>
  dispatch(deleteUserFromGroup(groupId, userId));

export const changeGroupUserStatus = (groupId, userId, status) => (dispatch) =>
  dispatch(patchGroupUserStatus(groupId, userId, { status: status || null }));

const resetScene = () => ({ type: actionTypes.RESET_SCENE });

const closeAlert = () => (dispatch) =>
  dispatch({ type: actionTypes.CLOSE_ALERT });

const actions = {
  onMount: loadGroups,
  onSelectPage: loadGroups,
  onLoadGroup: loadGroupDetails,
  onResetScene: resetScene,
  onLoadUsers: loadUsers,
  onAddGroupFormChange: updateAddGroupForm,
  onAddGroupFormSubmit: submitAddGroupForm,
  onAddGroupFormCancel: cancelAddGroupForm,
  onAddUserFormChange: updateAddUserForm,
  onAddUserFormSubmit: submitAddUserForm,
  onAddUserFormCancel: cancelAddUserForm,
  onRemoveUserFromGroup: removeUserFromGroup,
  onChangeGroupUserStatus: changeGroupUserStatus,
  onAlertClose: closeAlert,
};

export default actions;
