import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';

import {
  withSubmissionValidation,
  withNextPanelAfterSubmission,
} from '../../enhancers';

const submitContract =
  ({ onUpdateCampaign }) =>
  (values, __, props) =>
    onUpdateCampaign(props.campaign.id, { contract: values });

export const withSubmissionHandler = compose(
  setPropTypes({
    campaign: PropTypes.shape({ id: PropTypes.string.isRequired }),
    onUpdateCampaign: PropTypes.func.isRequired,
  }),
  withHandlers({ onSubmit: submitContract }),
  withSubmissionValidation,
  withNextPanelAfterSubmission,
);
