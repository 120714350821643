import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import every from 'lodash/every';
import noop from 'lodash/noop';
import invoke from 'lodash/invoke';
import { pure, withStateHandlers, withHandlers } from 'recompose';

import { Form, Alert } from 'source/components/common';
import ApplicationInsightsGa from 'source/scenes/campaignDetail/applications/components/websiteBudget/applicationInsightsGa';

import IndividualTask from './individualTask';
import UserDetails from './userDetails';
import ApplicationInsightsPiwik from './applicationInsightsPiwik';
import InsightsAnalysis from './insightsAnalysis';
import PublishingDates from './publishingDates';

// HoCs

const withTabState = withStateHandlers(
  ({ form }) => ({
    selectedTab:
      get(form, 'forms.status') === 'approved' ? 'dates' : 'insights',
  }),
  {
    onSelectTab: () => (tabName) => ({ selectedTab: tabName }),
  },
);

// Components

const ApplicationDetailsTabs = withHandlers({
  onSelectTab:
    ({ onSelectTab }) =>
    (e) => {
      e.preventDefault();
      onSelectTab(e.currentTarget.getAttribute('data-tab'));
    },
})(({ onSelectTab, selectedTab, showDates }) => (
  <ul className="nav nav-tabs mt-3 mb-3" role="tablist">
    <li className="nav-item cursor-pointer">
      <a
        data-tab="insights"
        className={cx('nav-link', { active: selectedTab === 'insights' })}
        onClick={onSelectTab}
        href="#"
      >
        Insights
      </a>
    </li>
    <li className="nav-item cursor-pointer">
      <a
        data-tab="dates"
        className={cx('nav-link', {
          active: selectedTab === 'dates',
          disabled: !showDates,
          'cursor-not-allowed': !showDates,
        })}
        onClick={showDates ? onSelectTab : noop}
        href="#"
      >
        Dates
      </a>
    </li>
  </ul>
));

ApplicationDetailsTabs.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  onSelectTab: PropTypes.func.isRequired,
  showDates: PropTypes.bool.isRequired,
};

class Application extends React.PureComponent {
  componentDidMount() {
    const {
      application,
      expanded,
      loadApplicationInsights,
      loadPublishingDates,
    } = this.props;

    // if `expanded` is true we need to load data
    if (expanded) {
      loadApplicationInsights(application);
      loadPublishingDates(application);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      application,
      expanded,
      loadApplicationInsights,
      loadPublishingDates,
    } = this.props;

    // if `expanded` is true and it changed from the previous state
    if (nextProps.expanded && expanded !== nextProps.expanded) {
      loadApplicationInsights(application);
      loadPublishingDates(application);
    }
  }

  onSubmit = (applicationId) => {
    const { onSubmit, campaignId } = this.props;
    onSubmit(campaignId, applicationId);
  };

  renderApplicationStatuses() {
    const { form } = this.props;

    const status = get(form, 'forms.status', '');
    const tasks = get(form, 'forms.tasks', []).filter(
      (task) => task.individual,
    );

    const allUpdated = every(tasks, { updated: true });

    return (
      <div>
        <label className="pr-3" htmlFor="status">
          Change status:
        </label>
        <select id="status" className="custom-select" value={status}>
          <option value="new" disabled>
            New
          </option>
          <option value="review">Review</option>
          <option value="selected">Selected</option>
          <option value="rejected">Rejected</option>
          <option value="approved" disabled={!allUpdated}>
            Approved
          </option>
        </select>
      </div>
    );
  }

  renderApplicationData() {
    const {
      application: { pitch },
    } = this.props;

    return (
      <div className="form-group">
        <label htmlFor="pitch">Pitch:</label>
        <textarea
          id="pitch"
          className="form-control"
          rows="8"
          defaultValue={pitch}
          readOnly
        />
      </div>
    );
  }

  renderIndividualTasks() {
    const { form, ...props } = this.props;

    const tasks = get(form, 'forms.tasks', [])
      .map((task, index) => ({ ...task, taskIndex: index }))
      .filter((task) => task.individual);

    if (tasks.length <= 0) {
      return null;
    }

    return (
      <div className="individual-tasks">
        <h5>Individual Tasks</h5>
        <table className="table table-sm individual-tasks-table">
          <thead>
            <tr>
              <th className="task-status">status</th>
              <th className="task-type">type</th>
              <th className="task-headline">headline</th>
              <th className="task-body">body</th>
              <th className="task-controls" />
            </tr>
          </thead>
          <tbody>
            {tasks.map(({ taskIndex, ...task }) => (
              <IndividualTask
                {...props}
                key={taskIndex}
                task={task}
                taskIndex={taskIndex}
                formData={form.forms || {}}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderSaveBar() {
    const {
      form: {
        saveState,
        forms: { editingTask, vip, publishingDateId },
      },
    } = this.props;

    const buttonClassName = cx('btn', {
      'btn-primary': !saveState,
      'btn-outline-warning': saveState === 'saving',
      'btn-outline-success': saveState === 'saved',
      'btn-danger': saveState === 'failed',
    });

    const ifEditing = Boolean(editingTask);
    const ifVipWithoutPublishingDate = vip && !publishingDateId;
    const ifPublishingDateWithoutVip = publishingDateId && !vip;

    const disable =
      ifEditing || ifVipWithoutPublishingDate || ifPublishingDateWithoutVip;

    return (
      <div className="row justify-content-end pr-3">
        <button type="submit" disabled={disable} className={buttonClassName}>
          Save
        </button>
      </div>
    );
  }

  render() {
    const {
      form,
      application,
      application: { id, user, website, channel },
      selectedTab,
      onSelectTab,
      insights,
      publishingDates,
      onChange,
    } = this.props;

    const websiteData = channel && channel.data ? channel.data : website;
    const isApproved = get(form, 'forms.status') === 'approved';
    const getTab = (selectedTab) => {
      const tabs = {
        insights: () => (
          <div role="tabpanel" className="pt-3 pb-3 pl-3">
            {channel.platform === 'ga' ? (
              insights && (
                <ApplicationInsightsGa
                  insights={{ data: insights.insights }}
                  metrics={insights.metrics}
                  application={application}
                />
              )
            ) : (
              <ApplicationInsightsPiwik insights={insights} />
            )}
          </div>
        ),
        dates: () => (
          <div role="tabpanel" className="pt-3 pb-3 pl-3">
            <PublishingDates
              application={application}
              form={form}
              publishingDates={publishingDates}
              onChange={onChange}
            />
          </div>
        ),
      };

      return invoke(tabs, selectedTab);
    };

    return (
      <Form id={id} onChange={onChange} onSubmit={this.onSubmit}>
        {user.confirmed ? null : (
          <Alert
            message="This user did not confirm their email"
            type="warning"
          />
        )}
        <div className="row">
          <div className="col-lg-2 pt-1">
            {this.renderApplicationStatuses()}
          </div>
          <div className="col-lg-6 pt-1">{this.renderApplicationData()}</div>
          <div className="col-lg-4 pt-lg-1">
            <UserDetails
              className="pt-lg-4"
              user={user}
              website={websiteData}
              applicationId={id}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ApplicationDetailsTabs
              showDates={isApproved}
              selectedTab={selectedTab}
              onSelectTab={onSelectTab}
            />
            {getTab(selectedTab)}
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <InsightsAnalysis application={application} />
          </div>
        </div>
        <div className="row">
          <div className="col">{this.renderIndividualTasks()}</div>
        </div>
        <div className="row">
          <div className="col">{this.renderSaveBar()}</div>
        </div>
      </Form>
    );
  }
}

Application.propTypes = {
  form: PropTypes.shape({
    forms: PropTypes.shape({
      status: PropTypes.string,
      tasks: PropTypes.arrayOf(
        PropTypes.shape({
          individual: PropTypes.bool,
          updated: PropTypes.bool,
        }),
      ),
      editingTask: PropTypes.number,
      vip: PropTypes.bool,
      publishingDateId: PropTypes.string,
    }),
    saveState: PropTypes.string,
  }).isRequired,
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    pitch: PropTypes.string.isRequired,
    // @deprecated
    website: PropTypes.shape({
      url: PropTypes.string.isRequired,
      pageviews: PropTypes.number.isRequired,
    }),
    channel: PropTypes.shape({
      platform: PropTypes.string.isRequired,
      data: PropTypes.shape({
        url: PropTypes.string.isRequired,
        pageviews: PropTypes.number.isRequired,
      }),
    }),
    user: PropTypes.shape({
      firstname: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      confirmed: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  campaignId: PropTypes.string.isRequired,
  insights: PropTypes.object,
  expanded: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loadApplicationInsights: PropTypes.func.isRequired,
  publishingDates: PropTypes.object,
  loadPublishingDates: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
  onSelectTab: PropTypes.func.isRequired,
};

Application.defaultProps = {
  insights: null,
  expanded: false,
  publishingDates: null,
};

export default pure(withTabState(Application));
