import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

const handleChange = (id, onChange) => (e) => {
  e.preventDefault();

  onChange(id, e.target.id, e.target.value);
};

const handleSubmit = (id, form, onErrors, onSubmit) => (e) => {
  e.preventDefault();

  const errors = {};

  // validation of email
  if (!form.email || isEmpty(form.email)) {
    errors.email = 'email address could not be empty.';
  } else if (!validator.isEmail(form.email)) {
    errors.email = 'email address is not valid.';
  }

  return isEmpty(errors) ? onSubmit(id) : onErrors(id, errors);
};

function UserDetailsForm({ id, form, onChange, onErrors, onSubmit }) {
  return (
    <form
      id={id}
      onChange={handleChange(id, onChange)}
      onSubmit={handleSubmit(id, form, onErrors, onSubmit)}
    >
      <div className="form-group">
        <label htmlFor="email">email address:</label>
        <input
          id="email"
          type="email"
          className={cx('form-control', { 'is-invalid': form.errors.email })}
          value={form.email}
          onChange={noop}
        />
        {form.errors.email && (
          <small className="invalid-feedback">{form.errors.email}</small>
        )}
      </div>

      <div className="row justify-content-end pr-3">
        <button
          type="submit"
          className={cx('btn', {
            'btn-error': form.submitFailed,
            'btn-success': form.submitted,
            'btn-primary': !form.submitted && !form.submitFailed,
          })}
          disabled={form.pristine || form.submitting || !isEmpty(form.errors)}
        >
          Save
        </button>
      </div>
    </form>
  );
}

UserDetailsForm.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.shape({
    submitting: PropTypes.bool.isRequired,
    submitted: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    errors: PropTypes.object,
    email: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onErrors: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UserDetailsForm;
