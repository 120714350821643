import {
  channelsSearchMany,
  categoriesFetchMany,
  rebuildChannelSearch,
} from 'source/data/channels/actions';
import { payloadToQuery } from './utils';

export const namespace = 'scenes/channelsAdvanced';
const loadChannels = channelsSearchMany(namespace);
const loadCategories = categoriesFetchMany(namespace);

export const actionTypes = {
  RESET_SCENE: `${namespace}/RESET_SCENE`,
  UPDATE_SORT: `${namespace}/UPDATE_SORT`,
};

const actions = {
  onLoadChannels: (payload, page, sort) =>
    loadChannels({
      fields: ['id', 'user', 'name', 'platform', 'reach', 'categories', 'data'],
      query: payloadToQuery(payload),
      page,
      sort,
    }),
  onLoadCategories: loadCategories,
  // TODO @anton.syrovatka
  // We may implement hidden button to be able
  // to rebuild channelSearch collection in one click
  onRebuildChannelSearch: rebuildChannelSearch,
  onResetScene: () => ({ name: namespace, type: actionTypes.RESET_SCENE }),
  onUpdateSorting: (sort) => ({
    name: namespace,
    type: actionTypes.UPDATE_SORT,
    payload: sort,
  }),
};

export default actions;
