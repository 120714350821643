import React from 'react';
import PropTypes from 'prop-types';

import { UserProfile } from 'source/scenes/widgets';
import ProductShipment from './productShipment';
import Notes from './notes';
import Pitch from './pitch';

function Profile({
  applicationId,
  productShipment,
  kamNotes,
  pitch,
  pitchEdits,
  user,
  onUpdateApplication,
}) {
  const onKamNoteSave = (value) =>
    onUpdateApplication(applicationId, {
      kamNotes: {
        note: value,
      },
    });

  const onProductShipmentSave = (value) =>
    onUpdateApplication(applicationId, {
      productShipment: {
        product: value,
      },
    });

  return (
    <div className="row">
      <div className="col-md-8">
        <Pitch
          pitch={pitch}
          pitchEdits={pitchEdits}
          applicationId={applicationId}
          onUpdateApplication={onUpdateApplication}
        />
        <div className="mt-4 row">
          <div className="col-md-6">
            <Notes
              label="KAM Note for Client"
              value={kamNotes?.note}
              applicationId={applicationId}
              onSave={onKamNoteSave}
              updatedAt={kamNotes?.updatedAt}
              updatedBy={kamNotes?.updatedBy}
            />
          </div>
          <div className="col-md-6">
            <ProductShipment
              productShipment={productShipment}
              onSave={onProductShipmentSave}
              defaultMode="preview"
              title="Product"
            />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <UserProfile user={user} />
      </div>
    </div>
  );
}

Profile.propTypes = {
  applicationId: PropTypes.string.isRequired,
  pitch: PropTypes.string.isRequired,
  pitchEdits: PropTypes.object,
  productShipment: PropTypes.object,
  kamNotes: PropTypes.shape({
    note: PropTypes.string,
    updatedBy: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  user: PropTypes.object.isRequired,
  onUpdateApplication: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  pitchEdits: {},
  kamNotes: {},
};

export default Profile;
