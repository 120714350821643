import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import keys from 'lodash/keys';
import last from 'lodash/last';

// The final status of each state name, state names are ordered chronologically
const finalStatuses = {
  mission: 'confirmed',
  contentReview: 'accepted',
  publication: 'accepted',
  afterPublicationUploadsVerification: 'accepted',
  socialMediaSharingVerification: 'accepted',
  payment: 'credited',
};

// Get the first non-final state name, it will be the current state of the mission
const getCurrentStateName = (application) => {
  // This covers the case of a campaign that has follow-up tasks but no `afterPublicationUploads`
  // and no `socialMediaSharing`, after `publication` goes to `accepted`, the `payment` state will only
  // be patched after the follow-up tasks have been completed, so we need to hardcode this case to return
  // 'publication' as the current state, as the default state would otherwise be `payment`
  // You can find a ticket to improve this on API level here: https://app.clubhouse.io/eqolot/story/1916/include-current-application-state-in-the-application
  if (
    application.publication &&
    application.publication.status === 'accepted' &&
    !application.afterPublicationUploadsVerification &&
    !application.socialMediaSharingVerification &&
    !application.payment
  ) {
    return 'publication';
  }
  const orderedStateNames = keys(finalStatuses);

  return (
    find(orderedStateNames, (stateName) => {
      const currentStatus = get(application, `${stateName}.status`);
      const finalStatus = get(finalStatuses, stateName);

      return currentStatus && currentStatus !== finalStatus;
    }) || last(orderedStateNames)
  );
};

const badges = {
  mission: {
    pending: {
      color: 'neutral',
      label: 'mission pending',
    },
    refused: {
      color: 'danger',
      label: 'mission refused',
    },
  },
  contentReview: {
    pending: {
      color: 'secondary',
      label: '⏱ content',
    },
    submitted: {
      color: 'warning',
      label: 'content preview submitted',
    },
    waitingForClient: {
      color: 'client',
      label: '⏱ client',
    },
    reviewedByClient: {
      color: 'warning',
      label: 'reviewed by client',
    },
    rejected: {
      color: 'danger',
      label: 'content preview rejected',
    },
  },
  publication: {
    pending: {
      color: 'secondary',
      label: '⏱ article URL',
    },
    submitted: {
      color: 'warning',
      label: 'published',
    },
    accepted: {
      color: 'warning',
      label: 'publication accepted',
    },
    rejected: {
      color: 'danger',
      label: 'publication rejected',
    },
  },
  afterPublicationUploadsVerification: {
    pending: {
      color: 'secondary',
      label: '⏱ insights',
    },
    submitted: {
      color: 'warning',
      label: 'check insights',
    },
    rejected: {
      color: 'secondary',
      label: '⏱ new insights',
    },
  },
  socialMediaSharingVerification: {
    submitted: {
      color: 'warning',
      label: 'sharing verification submitted',
    },
    rejected: {
      color: 'danger',
      label: 'sharing verification rejected',
    },
  },
  payment: {
    ready: {
      color: 'info',
      label: '⏱ credit',
    },
    credited: {
      color: 'success',
      label: 'credited',
    },
  },
};

const getContentReviewStepBadge = (application) => {
  const {
    contentReview,
    contentPreview,
    clientContentReview,
    clientContentPreview,
  } = application;

  if (clientContentPreview) {
    // Skip client review if accepted/rejected by manager
    if (['accepted', 'rejected'].includes(contentReview.status)) {
      return get(badges, `contentReview.${contentReview.status}`);
    }

    // Content submitted and reviewed by client
    // or waiting to be reviewed by client (unless new content is submitted)
    if (
      contentReview.status === 'submitted' &&
      contentPreview.version === clientContentPreview.version
    ) {
      return clientContentReview.status === 'pending'
        ? get(badges, 'contentReview.waitingForClient')
        : get(badges, 'contentReview.reviewedByClient');
    }
  }

  return get(badges, `contentReview.${contentReview.status}`);
};

const getMissionBadge = (application) => {
  const currentStateName = getCurrentStateName(application);
  const currentStatus = get(application, `${currentStateName}.status`);
  const isContentReviewStep = currentStateName === 'contentReview';

  const badge = isContentReviewStep
    ? getContentReviewStepBadge(application)
    : get(badges, `${currentStateName}.${currentStatus}`, {});

  return {
    color: badge.color || 'secondary',
    label: badge.label || `${currentStateName}:${currentStatus}`,
  };
};

function MissionStatusBadge({ application }) {
  const badge = getMissionBadge(application);

  return (
    <span
      className={`badge badge-pill font-weight-normal mission-badge-${badge.color}`}
    >
      {badge.label}
    </span>
  );
}

const StatusShape = PropTypes.shape({
  status: PropTypes.string.isRequired,
});

MissionStatusBadge.propTypes = {
  application: PropTypes.shape({
    mission: StatusShape.isRequired,
    contentReview: StatusShape.isRequired,
    publication: StatusShape.isRequired,
    afterPublicationUploadsVerification: StatusShape,
    payment: StatusShape,
  }).isRequired,
};

export default MissionStatusBadge;
