import React from 'react';
import PropTypes from 'prop-types';
import { animateScroll as scroll } from 'react-scroll';

import { toInitials } from './helpers';

function Event({ eventComponents, campaign, type, issuer, ...props }) {
  const EventComponent = eventComponents[type] || eventComponents.default;

  const initials = toInitials(issuer);

  return (
    <EventComponent
      type={type}
      issuer={issuer}
      initials={initials}
      campaign={campaign}
      {...props}
    />
  );
}

Event.propTypes = {
  campaign: PropTypes.shape({
    type: PropTypes.string.isRequired,
    pricing: PropTypes.string.isRequired,
  }),
  eventComponents: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  issuer: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

Event.defaultProps = {
  campaign: undefined,
};

/**
 * Renders all Events within a wrapping `div` and scrolls down
 * to the latest Message.
 */
class Events extends React.Component {
  // NOTE @alexspri
  //    Scroll down to the bottom of the conversation on initial mount.
  componentDidMount() {
    this.updateScroll();
  }

  // NOTE @alexspri
  //    Always scroll to the bottom of the conversation for any updates
  //    (e.g. a new message pops up).
  componentDidUpdate() {
    this.updateScroll();
  }

  updateScroll() {
    scroll.scrollToBottom({
      containerId: 'conversation-actions',
      duration: 0,
    });
  }

  render() {
    const { eventComponents, events, campaign, application } = this.props;

    return (
      <div id="conversation-actions" className="row conversation-actions">
        <div className="col">
          {events.map((event) => (
            <Event
              key={event.id}
              {...event}
              campaign={campaign}
              application={application}
              eventComponents={eventComponents}
            />
          ))}
        </div>
      </div>
    );
  }
}

Events.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  campaign: PropTypes.object,
  application: PropTypes.object.isRequired,
  eventComponents: PropTypes.object.isRequired,
};

Events.defaultProps = {
  campaign: undefined,
};

export default Events;
