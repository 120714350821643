import createReviewContainerEnhancer from './data';
import ContentPreviewReviewView from './preview';
import InsightsReviewView from './insights';

const commonApplicationFields = [
  'channel',
  'id',
  'match',
  'mission',
  'publication',
  'type',
  'user',
  'afterPublicationUploadsVerification',
  'socialMediaSharingVerification',
  'payment',
];

const enhanceInsightsReviewContainer = createReviewContainerEnhancer({
  applicationFields: [
    ...commonApplicationFields,
    'afterPublicationUploads',
    'publicationAnalytics',
    'contentPublication',
  ],
});

const enhanceContentPreviewReviewContainer = createReviewContainerEnhancer({
  applicationFields: [
    ...commonApplicationFields,
    'tasks',
    'tasksCheckList',
    'contentReview',
    'contentPreview',
    'contentPreviews',
    'clientContentReview',
    'clientContentPreview',
    'clientApplicationReview',
  ],
});

export const ContentPreview = enhanceContentPreviewReviewContainer(
  ContentPreviewReviewView,
);
export const InsightsReview =
  enhanceInsightsReviewContainer(InsightsReviewView);
