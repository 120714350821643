import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { VelocityTransitionGroup } from 'velocity-react';

export const getHeaderId = (id) => `expanding-panel__${id}--header`;

export const getBodyId = (id) => `expanding-panel__${id}--body`;

/**
 * NOTE: Before using this component, make sure you components work as expecting
 * even when they get mounted and unmounted a lot. `ExpandingPanel` unmounts its
 * content when the panel is not expanded. If you use `redux-form` you would
 * have to set `destroyOnUnmount` to `false` and destroy it manually in order to
 * preserve form state when the Panel decollapses.
 */

function Header({ className, id, expanded, children }) {
  return (
    <header
      id={getHeaderId(id)}
      className={cx('expanding-panel__header', 'card-header', className, {
        expanded,
      })}
      role="tab"
    >
      {children}
    </header>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  expanded: PropTypes.bool,
};

Header.defaultProps = {
  className: '',
  id: '',
  children: null,
  expanded: false,
};

// Helper for expanding panel headers which are clickable everywhere
class ClickableHeader extends React.Component {
  handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { id, onClick } = this.props;

    onClick(id);
  };

  render() {
    const { className, id, expanded, children } = this.props;

    return (
      <Header
        id={id}
        className={`expanding-panel__header--clickable ${className}`}
        expanded={expanded}
      >
        <div onClick={this.handleClick} aria-controls={getBodyId(id)}>
          {children}
        </div>
      </Header>
    );
  }
}

ClickableHeader.propTypes = {
  ...Header.propTypes,
  expanded: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ClickableHeader.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  className: '',
  expanded: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  children: null,
};

function Body({
  className,
  id,
  expanded,
  transitionDuration,
  disableTransition,
  children,
}) {
  const body = expanded ? (
    <div
      id={getBodyId(id)}
      className={`expanding-panel__body card-body ${className}`}
      aria-labelledby={getHeaderId(id)}
      role="tabpanel"
    >
      {children}
    </div>
  ) : null;

  return disableTransition ? (
    body
  ) : (
    <VelocityTransitionGroup
      enter={{
        animation: 'slideDown',
        duration: transitionDuration,
      }}
      leave={{
        animation: 'slideUp',
        duration: transitionDuration,
      }}
    >
      {children}
    </VelocityTransitionGroup>
  );
}

Body.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  expanded: PropTypes.bool,
  disableTransition: PropTypes.bool,
  transitionDuration: PropTypes.number,
  children: PropTypes.node,
};

Body.defaultProps = {
  className: '',
  id: '',
  expanded: false,
  disableTransition: false,
  transitionDuration: 300,
  children: null,
};

/**
 * Handles rendering logic for conditionally showing just a panel card header or
 * the entire panel card depending on input.
 *
 * On collapse, the body of the panel will be unmounted. On expand, re-mounted
 */
function ExpandingPanel({ className, id, expanded, children }) {
  return (
    <section
      id={id}
      className={cx('expanding-panel', 'card', className, { active: expanded })}
    >
      {
        // We need to clone the children with the parent id and
        // expanded state to hook up ARIA attributes
        React.Children.map(children, (child) =>
          React.cloneElement(child, { id, expanded }),
        )
      }
    </section>
  );
}

ExpandingPanel.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

ExpandingPanel.defaultProps = {
  className: '',
  children: null,
};

ExpandingPanel.Header = Header;
ExpandingPanel.ClickableHeader = ClickableHeader;
ExpandingPanel.Body = Body;

export default ExpandingPanel;
