import PropTypes from 'prop-types';
import React from 'react';

import { InputText, InputSelect } from '../common/inputs';

class AddLink extends React.Component {
  handleChange = (id, value) => {
    const { form, onUpdate } = this.props;

    return onUpdate(form, id, value);
  };

  handleSubmit = (e) => {
    const { form, onSubmit } = this.props;

    e.preventDefault();

    return onSubmit(form);
  };

  handleCancel = (e) => {
    const { onCancel } = this.props;

    e.preventDefault();

    return onCancel();
  };

  render() {
    const { stateLoaded, users, campaigns, form, errors } = this.props;

    return (
      <div>
        <form>
          <InputText
            name="Target URL"
            id="targetUrl"
            type="url"
            item={form.targetUrl.value}
            error={errors.targetUrl}
            placeholder="http://example.com/article"
            onChange={this.handleChange}
          />

          <InputSelect
            name="User"
            id="user"
            options={users}
            value={form.user.value}
            error={errors.user}
            onChange={this.handleChange}
          />

          <InputSelect
            name="Campaign"
            id="campaign"
            options={campaigns}
            value={form.campaign.value}
            error={errors.campaign}
            onChange={this.handleChange}
          />

          <div className="row justify-content-end pr-3">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={this.handleSubmit}
              disabled={!stateLoaded}
            >
              Create New Link
            </button>
            <button
              type="button"
              className="btn btn-outline-danger ml-1"
              onClick={this.handleCancel}
              disabled={!stateLoaded}
            >
              Back to Links
            </button>
          </div>
        </form>
      </div>
    );
  }
}

AddLink.propTypes = {
  stateLoaded: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  form: PropTypes.shape({
    targetUrl: PropTypes.object,
    publisher: PropTypes.object,
    user: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    campaign: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
  }).isRequired,
  errors: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddLink;
