/* eslint-disable react/style-prop-object */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import moment from 'moment';

import { formatReadingTime, getCurrencySymbolForString } from 'source/utils';
import ExternalLink from 'source/scenes/components/externalLink';
import Icon from 'source/components/common/icon';

const stripProtocol = (url) => url.replace(/https?:\/\//, '');

function SocialMediaList({ items }) {
  return (
    <div className="row nmt-2">
      {items.map(({ type, url }) =>
        url ? (
          <span key={url} className="ml-2 mt-2 channel-icon">
            <ExternalLink href={url}>
              <Icon name={type} className="small-icon" />
            </ExternalLink>
          </span>
        ) : null,
      )}
    </div>
  );
}

SocialMediaList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ).isRequired,
};

function FormattedNumberOrDash(props) {
  return props.value === null ? '-' : <FormattedNumber {...props} />;
}

FormattedNumberOrDash.propTypes = {
  value: PropTypes.number,
};

const formatReadingTimeOrDash = (readingTimeSec) =>
  readingTimeSec === null ? '-' : formatReadingTime(readingTimeSec);

function WebsiteReportingTable({ reports, totals, campaign }) {
  return (
    <div className="table-responsive-lg">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Article URL</th>
            <th style={{ minWidth: '7rem' }}>Submitted on</th>
            <th>Unique Visitors</th>
            <th>Total Page Views</th>
            <th>
              CPR ({getCurrencySymbolForString(campaign.settings.currency)})
            </th>
            <th>Avg. Time on Page</th>
            <th style={{ minWidth: '8rem' }}>Social Media</th>
          </tr>
        </thead>
        <tbody>
          <tr className="font-weight-bold">
            <td>totals</td>
            <td>-</td>
            <td>
              <FormattedNumberOrDash value={totals.uniqueVisitors} />
            </td>
            <td>
              <FormattedNumberOrDash value={totals.pageviews} />
            </td>
            <td>
              <FormattedNumberOrDash
                value={totals.cpr}
                style="currency"
                currency={campaign.settings.currency}
              />
            </td>
            <td>{formatReadingTimeOrDash(totals.duration)}</td>
            <td>-</td>
          </tr>
          {reports.map((report) => (
            <tr key={report.url}>
              <td className="truncate">
                <div className="d-flex align-items-center channel-icon">
                  <Icon name={report.platform} className="small-icon" />
                  <ExternalLink
                    className="truncate mw-12"
                    href={report.url}
                    title={report.url}
                    style={{ marginLeft: '0.5rem' }}
                  >
                    {stripProtocol(report.url)}
                  </ExternalLink>
                </div>
              </td>
              <td>{moment(report.publishedAt).format('YYYY-MM-DD')}</td>
              <td>
                <FormattedNumberOrDash value={report.totals.uniqueVisitors} />
              </td>
              <td>
                <FormattedNumberOrDash value={report.totals.pageviews} />
              </td>
              <td>
                <FormattedNumberOrDash
                  value={report.totals.cpr}
                  style="currency"
                  currency={campaign.settings.currency}
                />
              </td>
              <td>{formatReadingTimeOrDash(report.totals.duration)}</td>
              <td>
                <SocialMediaList items={report.socialMediaSharing} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

WebsiteReportingTable.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      publishedAt: PropTypes.string.isRequired,
      totals: PropTypes.shape({
        visits: PropTypes.number,
        uniqueVisitors: PropTypes.number,
        pageviews: PropTypes.number,
        cpr: PropTypes.number,
        duration: PropTypes.number,
        bounceRate: PropTypes.number,
      }).isRequired,
      socialMediaSharing: PropTypes.any,
    }),
  ).isRequired,
  totals: PropTypes.shape({
    visits: PropTypes.number,
    uniqueVisitors: PropTypes.number,
    pageviews: PropTypes.number,
    cpr: PropTypes.number,
    duration: PropTypes.number,
    bounceRate: PropTypes.number,
  }).isRequired,
  campaign: PropTypes.shape({
    settings: PropTypes.shape({
      currency: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default WebsiteReportingTable;
