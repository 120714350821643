/**
 * NOTE @alexspri
 *     We need to add a some id to the tasks so we can identify them
 *     within the tasks list. We need to do so, because we can edit
 *     existing tasks and are copying them to a `newTasks` property.
 *     When saving we need to copy the `newTasks` updates back to
 *     the original task within the tasks list. And to tell the action
 *     to not send the id back to the server we add another flag here.
 */
export const mapTask = (task, index) => {
  if (!task.id) {
    task = {
      ...task,
      id: index,
      computedId: true,
      existing: true,
    };
  }

  return { ...task, existing: true };
};

export const socialMediaSharingPlatforms = [
  { platform: 'instagram', label: 'Instagram' },
  { platform: 'facebook', label: 'Facebook' },
  { platform: 'twitter', label: 'Twitter' },
  { platform: 'pinterest', label: 'Pinterest' },
  { platform: 'youtube', label: 'YouTube' },
];
