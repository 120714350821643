import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import omit from 'lodash/omit';
import kebabCase from 'lodash/kebabCase';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import cx from 'classnames';
import {
  setPropTypes,
  withPropsOnChange,
  withHandlers,
  compose,
} from 'recompose';
import {
  ToolTipController,
  Select as ToolTipWrapper,
} from 'react-tooltip-controller';

import ProgressBar from 'source/components/common/progressBar';
import PanelList from 'source/scenes/components/panelList';
import ExpandingPanel from 'source/scenes/components/expandingPanel';
import Bar from 'source/scenes/components/bar';
import LabeledMetric from 'source/scenes/components/labeledMetric';
import { calculateBookedValuesDifference } from 'source/utils/campaign';

import ListApplicationsReportButton from '../listApplicationsReportButton';
import ClientInfluencerSelectionToolTip from './clientInfluencerSelectionToolTip';

function ClientReviewBadge({ campaign }) {
  const { clientInfluencerSelection } = campaign;

  if (
    !clientInfluencerSelection ||
    clientInfluencerSelection.status === 'pending'
  ) {
    return null;
  }

  const titleByStatus = {
    confirmed: 'selection confirmed',
  };

  const {
    status,
    selectionType,
    campaignBudget,
    confirmedBudget,
    campaignReach,
    confirmedReach,
  } = clientInfluencerSelection;

  const title = titleByStatus[status];
  const bookedValuesDifference = calculateBookedValuesDifference({
    booked: selectionType === 'reach' ? campaignReach : campaignBudget,
    confirmed: selectionType === 'reach' ? confirmedReach : confirmedBudget,
  });
  const showWarning = bookedValuesDifference * 100 >= 5;

  return (
    <ToolTipController detect="hover" offsetY={6}>
      <ToolTipWrapper>
        <div className="badge-container">
          <span
            className={cx({
              success: !showWarning,
              warning: showWarning,
            })}
          >
            {title} {showWarning ? '⚠️' : ''}
          </span>
        </div>
      </ToolTipWrapper>
      <ClientInfluencerSelectionToolTip
        value={clientInfluencerSelection}
        showDifferenceWarning={showWarning}
      />
    </ToolTipController>
  );
}

ClientReviewBadge.propTypes = {
  campaign: PropTypes.shape({
    clientInfluencerSelection: PropTypes.shape({
      status: PropTypes.oneOf(['confirmed', 'pending']).isRequired,
      selectionType: PropTypes.oneOf(['budget', 'reach']),
      campaignBudget: PropTypes.number,
      confirmedBudget: PropTypes.number,
      campaignReach: PropTypes.number,
      confirmedReach: PropTypes.number,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.string,
    }),
  }).isRequired,
};

// Helpers

const byStatusShape = PropTypes.shape({
  new: PropTypes.any.isRequired,
  shortlisted: PropTypes.any.isRequired,
  review: PropTypes.any.isRequired,
  selected: PropTypes.any.isRequired,
  approved: PropTypes.any.isRequired,
  rejected: PropTypes.any.isRequired,
});

const visible = (panel) => !panel.hide;

const statuses = [
  'rejected',
  'new',
  'shortlisted',
  'review',
  'selected',
  'approved',
];

const labelsByStatus = {
  rejected: 'Rejected',
  new: 'Inbox',
  shortlisted: 'Shortlisted',
  review: 'Client Review',
  selected: 'Ready For Mission',
  approved: 'Sent On Mission',
};

const acceptBtnLabelsByStatus = {
  rejected: 'To Inbox',
  new: 'To Shortlisted',
  shortlisted: 'To Review',
  review: 'To Ready For Mission',
  selected: 'Move to ...',
};

const acceptHandlersByStatus = {
  rejected: 'markNew',
  new: 'markShortlisted',
  shortlisted: 'markReview',
  review: 'markSelected',
};

const badgeByStatus = {
  review: ClientReviewBadge,
};

const extraHandlersMap = {
  rejected: [
    { name: 'Rejected', status: 'markRejected', disabled: true },
    { name: 'Inbox', status: 'markNew' },
    { name: 'Shortlisted', status: 'markShortlisted' },
    { name: 'Client Review', status: 'markReview' },
    { name: 'Ready For Mission', status: 'markSelected' },
  ],
  new: [
    { name: 'Inbox', status: 'markNew', disabled: true },
    { name: 'Shortlisted', status: 'markShortlisted' },
    { name: 'Client Review', status: 'markReview' },
    { name: 'Ready For Mission', status: 'markSelected' },
  ],
  shortlisted: [
    { name: 'Inbox', status: 'markNew' },
    { name: 'Shortlisted', status: 'markShortlisted', disabled: true },
    { name: 'Client Review', status: 'markReview' },
    { name: 'Ready For Mission', status: 'markSelected' },
  ],
  review: [
    { name: 'Inbox', status: 'markNew' },
    { name: 'Shortlisted', status: 'markShortlisted' },
    { name: 'Client Review', status: 'markReview', disabled: true },
    { name: 'Ready For Mission', status: 'markSelected' },
  ],
  selected: [
    { name: 'Inbox', status: 'markNew' },
    { name: 'Shortlisted', status: 'markShortlisted' },
    { name: 'Client Review', status: 'markReview' },
    { name: 'Ready For Mission', status: 'markSelected', disabled: true },
  ],
};

const allowRejectByStatus = {
  rejected: false,
  new: true,
  shortlisted: true,
  review: true,
  selected: true,
  approved: true,
};

function UnformattedValue({ value }) {
  return <span>{value}</span>;
}

UnformattedValue.propTypes = { value: PropTypes.any.isRequired };

const panelHeaderRenderer =
  ({ goals }) =>
  () =>
    goals.map(({ label, value, empty, toolTip, formatting = {} }) => {
      const Label = formatting.component || FormattedNumber;
      const labelProps = omit(formatting, ['component']);
      const currentLabel = !isNil(value) ? (
        <Label {...labelProps} value={value} />
      ) : null;

      return (
        <Bar.Item
          key={label}
          className={cx(
            'applications-header__metrics',
            `applications-header__metrics__${kebabCase(label)}`,
            {
              'no-border': empty,
            },
          )}
        >
          <LabeledMetric
            className={cx('align-right', {
              'mt-4': isNil(value),
            })}
            value={currentLabel}
            label={!empty ? label : ''}
            toolTip={toolTip}
          />
        </Bar.Item>
      );
    });

const withPanels = compose(
  setPropTypes({
    campaign: PropTypes.object.isRequired,
    applicationsByStatus: byStatusShape.isRequired,
    metricsByStatus: byStatusShape.isRequired,
    getPanelGoals: PropTypes.func.isRequired,
  }),
  withPropsOnChange(
    ['campaign', 'applicationsByStatus', 'metricsByStatus', 'showRejected'],
    ({
      applicationsByStatus,
      metricsByStatus,
      campaign,
      getPanelGoals,
      showRejected,
      ...props
    }) => ({
      panels: statuses.map((status) => {
        const metrics = metricsByStatus[status];
        const applications = applicationsByStatus[status];
        const label = labelsByStatus[status];
        const acceptBtnLabel = acceptBtnLabelsByStatus[status];
        const allowReject = allowRejectByStatus[status];
        const acceptHandler = acceptHandlersByStatus[status];
        const BadgeComponent = badgeByStatus[status];

        const renderPanelHeader = panelHeaderRenderer({
          status,
          goals: getPanelGoals({
            campaign,
            metrics,
          }),
        });
        const extraHandlers = extraHandlersMap[status] || [];

        return {
          id: status,
          status,
          label,
          applications,
          count: applications.length,
          acceptBtnLabel,
          handleAccept: props[acceptHandler],
          handleReject: allowReject ? props.markRejected : null,
          handleDelete: props.markDeleted,
          badge: BadgeComponent ? <BadgeComponent campaign={campaign} /> : null,
          extraHandlers: extraHandlers.map((item) => ({
            ...item,
            handler: props[item.status],
          })),
          hide: status === 'rejected' && !showRejected,
          renderPanelHeader,
        };
      }),
    }),
  ),
  /**
   * FEATURE
   *    allow one application panel to be opened through the url param
   */
  withPropsOnChange(['match'], ({ match, applications, setSelectedPanel }) => {
    if (match.params && match.params.applicationId) {
      const targetApplication = find(applications, {
        id: match.params.applicationId,
      });
      if (targetApplication) {
        setSelectedPanel(targetApplication.status);
      }
    }
  }),
);

const stopPropagation = (e) => e.stopPropagation();

// Components

function OverviewPanel({
  id,
  label,
  status,
  campaign,
  accessToken,
  applications,
  badge,
  expanded,
  onClick,
  renderPanelHeader,
  children,
  loading,
}) {
  return (
    <ExpandingPanel
      className="application-review__status-panel pt-2"
      id={id}
      expanded={expanded}
    >
      <ExpandingPanel.ClickableHeader onClick={onClick}>
        <Bar className="applications-header">
          <Bar.Item
            className={cx('applications-header__name no-border', {
              'applications-header__name--with-badge': Boolean(badge),
            })}
          >
            <div className="applications-header__label__name ml-3">{label}</div>
            {badge}
          </Bar.Item>
          <Bar.Item className="applications-header__count">
            {status === 'rejected' && loading ? (
              <ProgressBar
                style={{ width: '25px', height: '25px' }}
                size={25}
              />
            ) : (
              <div className="applications-header__label__count">
                {applications.length}
              </div>
            )}
          </Bar.Item>
          {renderPanelHeader()}
          <Bar.Item className="applications-header__accept">
            <ListApplicationsReportButton
              size="sm"
              className="mr-3"
              campaignId={campaign.id}
              accessToken={accessToken}
              applicationStatus={status}
              title={`download report for "${status}" applications`}
              onClick={stopPropagation}
            />
          </Bar.Item>
        </Bar>
      </ExpandingPanel.ClickableHeader>
      <ExpandingPanel.Body disableTransition>{children}</ExpandingPanel.Body>
    </ExpandingPanel>
  );
}

OverviewPanel.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  accessToken: PropTypes.string.isRequired,
  applications: PropTypes.array.isRequired,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  renderPanelHeader: PropTypes.func.isRequired,
  badge: PropTypes.node,
  children: PropTypes.any,
};

OverviewPanel.defaultProps = {
  expanded: false,
  onClick: () => {},
  children: null,
  badge: null,
};

/**
 * Handle panel click and sync with location
 */
const withPanelClickHandler = withHandlers({
  handlePanelSelect:
    ({ campaign, setSelectedPanel, syncApplicationWithLocation }) =>
    (name) => {
      setSelectedPanel(name);
      syncApplicationWithLocation(campaign);
    },
});

function OverviewPanelList({
  panels,
  loading,
  campaign,
  accessToken,
  panelBody: PanelBody,
  selectedPanel,
  syncApplicationWithLocation,
  handlePanelSelect,
  match,
}) {
  return (
    <PanelList
      className="application-review__status-panels"
      selectedPanel={selectedPanel}
      onSelectPanel={handlePanelSelect}
    >
      {panels
        .filter(visible)
        .map(
          ({
            id,
            status,
            label,
            applications,
            metrics,
            acceptBtnLabel,
            badge,
            handleAccept,
            handleReject,
            handleDelete,
            extraHandlers,
            renderPanelHeader,
          }) => (
            <OverviewPanel
              key={id}
              id={id}
              status={status}
              label={label}
              loading={loading}
              metrics={metrics}
              applications={applications}
              badge={badge}
              campaign={campaign}
              accessToken={accessToken}
              renderPanelHeader={renderPanelHeader}
            >
              <PanelBody
                label={label}
                match={match}
                campaign={campaign}
                applications={applications}
                acceptBtnLabel={acceptBtnLabel}
                onAccept={handleAccept}
                onReject={handleReject}
                onDelete={handleDelete}
                extraHandlers={extraHandlers}
                syncApplicationWithLocation={syncApplicationWithLocation}
              />
            </OverviewPanel>
          ),
        )}
    </PanelList>
  );
}

OverviewPanelList.propTypes = {
  campaign: PropTypes.object.isRequired,
  accessToken: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  syncApplicationWithLocation: PropTypes.func.isRequired,
  /**
   * Metrics used to calculate the goal values
   */
  // eslint-disable-next-line react/no-unused-prop-types
  metricsByStatus: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  applicationsByStatus: PropTypes.object.isRequired,
  /**
   * Component used to render the body of the panel
   */
  panelBody: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  getPanelGoals: PropTypes.func.isRequired,
  /**
   * Params used to open the application panel
   */
  match: PropTypes.shape({
    params: PropTypes.shape({
      applicationId: PropTypes.string,
    }).isRequired,
  }).isRequired,

  // Internal

  /** @ignore */
  panels: PropTypes.array.isRequired,
  /** @ignore */
  selectedPanel: PropTypes.string.isRequired,
  /** @ignore */
  // eslint-disable-next-line react/no-unused-prop-types
  setSelectedPanel: PropTypes.func.isRequired,
  handlePanelSelect: PropTypes.func.isRequired,
};

export default compose(withPanels, withPanelClickHandler)(OverviewPanelList);
