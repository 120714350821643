import { invoicesFetch } from 'source/data/invoices/actions';
import formDataToFilters from './formDataToFilters';

export const namespace = 'scenes/invoices';

export const actionTypes = {
  FILTER_CHANGED: `${namespace}/FILTER_CHANGED`,
  FILTER_RESET: `${namespace}/FILTER_RESET`,
  RESET_SCENE: `${namespace}/RESET_SCENE`,
};

const listInvoices = invoicesFetch(namespace);

const load =
  (page = 0) =>
  (dispatch, getState) => {
    const {
      scenes: {
        invoices: { filters: formData = {} },
      },
    } = getState();

    const fields = [
      'id',
      'period',
      'userId',
      'currency',
      'address',
      'totals',
      'paid',
      'type',
    ];

    return dispatch(
      listInvoices({ page, fields, filters: formDataToFilters(formData) }),
    );
  };

const changeFilter = (filter, value) => (dispatch) =>
  // update the state
  dispatch({
    type: actionTypes.FILTER_CHANGED,
    payload: { filter, value },
  });

const resetFilter = () => (dispatch) =>
  // update the state
  dispatch({
    type: actionTypes.FILTER_RESET,
  });

const resetScene = () => ({ type: actionTypes.RESET_SCENE });

const actions = {
  onMount: load,
  onSelectPage: load,
  onFilterChange: changeFilter,
  onFilterReset: resetFilter,
  onResetScene: resetScene,
};

export default actions;
