import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import {
  FieldArray,
  arrayPush,
  submit,
  reduxForm,
  propTypes,
} from 'redux-form';
import { CollapsiblePanel } from 'source/components/common';

import PostInsightsFields from './postInsightsFields';
import StoryInsightsFields from './storyInsightsFields';
import InsightsHeadline from './headline';

const InstagramInsights = ({
  fields,
  onRemove,
  onCancel,
  togglePanel,
  selectedPanel,
}) => {
  const history = useHistory();
  const location = useLocation();

  const cancelForm = (e) => {
    e.preventDefault();
    onCancel();
  };

  const removeItem = (index) => () => {
    fields.remove(index);
    // this helps finish fields update before onRemove is called
    setTimeout(() => onRemove());
  };

  const onPanelClick = (id, toggleFlag) => {
    togglePanel(id, toggleFlag, {
      location,
      history,
    });
  };

  const analytics = fields.map((name, index) => {
    const item = fields.get(index);
    const headline = (
      <InsightsHeadline item={item} onRemove={removeItem(index)} />
    );

    const form =
      item.type === 'post' ? (
        <PostInsightsFields name={name} onCancel={cancelForm} />
      ) : (
        <StoryInsightsFields name={name} onCancel={cancelForm} />
      );

    return (
      <CollapsiblePanel
        id={index}
        className="mb-1"
        headline={headline}
        expanded={selectedPanel === index}
        onClick={onPanelClick}
      >
        {form}
      </CollapsiblePanel>
    );
  });

  return analytics;
};

InstagramInsights.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  togglePanel: PropTypes.func.isRequired,
  selectedPanel: PropTypes.number,
};

const getDefaultData = (publishDate, postUrl) => {
  const defaultData = {
    publishedAt: publishDate,
    reportedAt: new Date().toISOString(),
  };

  if (postUrl) {
    defaultData.postUrl = postUrl;
  }

  return defaultData;
};

function InstagramInsightsForm({
  title,
  form,
  reset,
  dispatch,
  handleSubmit,
  publishDate,
  togglePanel,
  selectedPanel,
  collapseAllPanels,
  publicationAnalyticsFormValue,
  contentUrl,
}) {
  const history = useHistory();
  const location = useLocation();
  const router = {
    history,
    location,
  };

  const addNewPost = () => {
    dispatch(
      arrayPush(form, 'publicationAnalytics', {
        type: 'post',
        data: getDefaultData(publishDate, contentUrl),
      }),
    );
    togglePanel(publicationAnalyticsFormValue.length, router);
  };

  const addNewStory = () => {
    dispatch(
      arrayPush(form, 'publicationAnalytics', {
        type: 'story',
        data: getDefaultData(publishDate),
      }),
    );
    togglePanel(publicationAnalyticsFormValue.length, router);
  };

  const submitForm = () => {
    dispatch(submit(form));
    collapseAllPanels(router);
  };

  const resetForm = () => {
    reset();
    collapseAllPanels(router);
  };

  return (
    <div className="insights-form">
      <div className="form-group write-review">
        <h3 className="mt-5">{title}</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <FieldArray
          name="publicationAnalytics"
          component={InstagramInsights}
          onRemove={submitForm}
          onCancel={resetForm}
          togglePanel={togglePanel}
          selectedPanel={selectedPanel}
        />
      </form>
      {selectedPanel === null && (
        <div className="d-flex -justify-content-end align-items-center mt-3">
          <button className="btn btn-primary" onClick={addNewPost}>
            + Post
          </button>
          <button className="btn btn-primary ml-2" onClick={addNewStory}>
            + Story
          </button>
        </div>
      )}
    </div>
  );
}

InstagramInsightsForm.propTypes = {
  ...propTypes,
  selectedPanel: PropTypes.number,
  publishDate: PropTypes.string.isRequired,
  togglePanel: PropTypes.func.isRequired,
  collapseAllPanels: PropTypes.func.isRequired,
  publicationAnalyticsFormValue: PropTypes.arrayOf(PropTypes.object).isRequired,
  contentUrl: PropTypes.string.isRequired,
};

export default reduxForm({
  enableReinitialize: true,
})(InstagramInsightsForm);
