import {
  asyncActionReducer,
  asyncStates,
  loadStateReducer,
} from '@blogfoster/redux-async-utils';
import { compose } from 'redux';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import get from 'lodash/get';

import { createReducer } from 'source/utils';
import { actionTypes as applicationActionTypes } from 'source/data/applications/actions';
import {
  combineReducersFlat,
  namespacedReducer,
  resetableReducer,
  nestedReducer,
} from 'source/utils/redux';
import { redux as collapsiblePanelRedux } from 'source/components/common/collapsiblePanel';

import { actionTypes, namespace } from './actions';

export const getInitialState = () => ({
  missions: {}, // per mission data
  listForms: {}, // global (mission view) forms data
  expanded: {}, // keep track of the expanded mission panels

  applicationIds: [],

  applicationsRequestState: {
    loaded: false,
    error: null,
  },

  eventsRequestState: {
    loaded: false,
    error: null,
  },
});

const mapMissionToForms = (application, state) => ({
  ...state,
  notes: {
    kamNotes: application.kamNotes,
    clientNotes: application.clientNotes,
    state: 'pristine',
  },
  productShipment: {
    product: application.productShipment?.product,
    state: 'pristine',
  },
  status: {
    ...state.status,
    missionStatus: application.mission.status,
    errors: {},
    state: 'pristine',
  },
  collaborationRatings: {
    ...state.collaborationRatings,
    visualCreativity: get(application, 'collaborationRatings.visualCreativity'),
    copyWriting: get(application, 'collaborationRatings.copyWriting'),
    punctuality: get(application, 'collaborationRatings.punctuality'),
    professionalism: get(application, 'collaborationRatings.professionalism'),
    updatedBy: get(application, 'collaborationRatings.updatedBy'),
    updatedAt: get(application, 'collaborationRatings.updatedAt'),
    errors: {},
    state: 'pristine',
  },
});

const manageApplicationIds = namespacedReducer(namespace)(
  asyncActionReducer(applicationActionTypes.FETCH_MANY, {
    [asyncStates.success]: (state, { payload }) => ({
      ...state,
      applicationIds: payload.data.map((application) => application.id),
    }),
  }),
);

const applicationsRequestStateReducer = compose(
  namespacedReducer(namespace),
  nestedReducer('applicationsRequestState'),
)(loadStateReducer(applicationActionTypes.FETCH_MANY));

const eventsRequestStateReducer = compose(
  namespacedReducer(namespace),
  nestedReducer('eventsRequestState'),
)(loadStateReducer(applicationActionTypes.FETCH));

// listen for the applications loaded event and create missions from that
const missionsReducer = namespacedReducer(namespace)(
  asyncActionReducer(applicationActionTypes.FETCH_MANY, {
    [asyncStates.success]: (state, { payload }) =>
      merge({}, state, {
        missions: payload.data.reduce(
          (missions, application) => ({
            ...missions,
            [application.id]: {
              actionIds: [],
              forms: mapMissionToForms(application, {}),
            },
          }),
          state.missions,
        ),
      }),
  }),
);

const expandedStateReducer = collapsiblePanelRedux.reducers.toggle(namespace);

/**
 * NOTE @alexspri
 *    The missions state is actually a map of mission status keyed by the application id.
 *    This means most of the actions only need to work on their specific mission state.
 *    Therefore this function gives us a bit of syntactic sugar.
 */
const missionAction =
  (fn) =>
  (state, { id, ...payload }) => ({
    ...state,
    missions: {
      ...state.missions,
      [id]: fn(state.missions[id] || {}, { id, ...payload }),
    },
  });

/* == Action Handlers == */
const actionHandlers = {
  // click on the reaction navigation
  [actionTypes.REACTION_NAVIGATION_CLICKED]: missionAction(
    (state, { reaction }) => {
      const nextState = merge({}, state, {
        selectedReaction: state.selectedReaction !== reaction ? reaction : null, // collapse form, if clicked twice
      });

      // remove `reaction` form errors when the action changes
      return omit(nextState, 'forms.reaction.errors');
    },
  ),

  // global form changes
  [actionTypes.LIST_FORM_CHANGE]: (state, { formId, field, value }) =>
    merge({}, state, {
      listForms: {
        [formId]: {
          [field]: value,
          state: 'dirty',
        },
      },
    }),

  // form changes
  [actionTypes.FORM_CHANGE]: missionAction(
    (state, { formId, field, value }) => {
      const nextState = merge({}, state, {
        forms: {
          [formId]: {
            [field]: value,
            state: 'dirty',
          },
        },
      });

      return omit(nextState, [['forms', formId, 'errors', field]]);
    },
  ),

  [actionTypes.FORM_ERRORS]: missionAction((state, { formId, errors }) =>
    merge({}, state, {
      forms: {
        [formId]: {
          errors,
        },
      },
    }),
  ),

  // the form is submitting
  [actionTypes.FORM_SUBMITTING]: missionAction((state, { formId }) => {
    const nextState = merge({}, state, {
      forms: {
        [formId]: {
          state: 'submitting',
        },
      },
    });

    // When updating the `reaction` form we want to de-collapse the input form.
    if (formId === 'reaction') {
      nextState.selectedReaction = null;
    }

    return nextState;
  }),

  // a form has been submitted
  [actionTypes.FORM_SUBMITTED]: missionAction((state, { formId }) => {
    const nextState = merge({}, state, {
      forms: {
        [formId]: {
          state: 'submitted',
        },
      },
    });

    // When submitted the `reaction` form we want to reset the input form.
    if (formId === 'reaction') {
      nextState.forms[formId].feedback = '';
    }

    return nextState;
  }),

  // we updated the application successfully
  [actionTypes.APPLICATION_SUBMITTED]: missionAction(
    (state, { application }) => ({
      ...state,
      forms: {
        ...mapMissionToForms(application, state.forms),
      },
    }),
  ),
};

const rootReducer = createReducer(getInitialState(), actionHandlers);

export default resetableReducer(actionTypes.RESET_SCENE)(
  combineReducersFlat(
    [
      rootReducer,
      manageApplicationIds,
      missionsReducer,
      applicationsRequestStateReducer,
      eventsRequestStateReducer,
      expandedStateReducer,
    ],
    getInitialState(),
  ),
);
