import React from 'react';
import PropTypes from 'prop-types';

import ProgressBar from 'source/components/common/progressBar';
import { DownloadButton } from 'source/components/common';
import sortIabCategories from 'source/scenes/utils/iabCategories';

import ChannelSearchResults from './channelSearchResults';
import Counter from './counter';
import ChannelSearchForm from './channelSearchForm';

function ChannelSearchMain({
  results,
  counters,
  handleSubmit,
  loading,
  categories,
  categoriesByCode,
  channelSearchReportUrl,
  channelPaging,
  channelSorting,
  onSelectPage,
  onChangeSort,
  onReportDownload,
  onChannelFilterReset,
}) {
  return (
    <div
      className="container container-full-height"
      style={{ maxWidth: '1500px' }}
    >
      <div className="row">
        {/* left part of the channel view */}
        <div className="col-md-3 pt-4 pb-5 channel-filter">
          <h2>Channel Filter</h2>
          <p className="text-muted">Data gets updated every night</p>
          <ChannelSearchForm
            onSubmit={handleSubmit}
            onReset={onChannelFilterReset}
            categories={sortIabCategories(categories)}
          />
        </div>

        {/* right part of the channel view */}
        <div className="col-md-9 pt-4 pb-5">
          {loading ? (
            <div>
              <ProgressBar.Mega />
            </div>
          ) : (
            <>
              <div className="row align-items-center mt-3 mb-3">
                <div className="mx-3">
                  <DownloadButton
                    href={channelSearchReportUrl}
                    disabled={!(results && results.length > 0)}
                    onClick={onReportDownload}
                  >
                    Download result as XLSX
                  </DownloadButton>
                </div>
                {counters ? (
                  <>
                    <Counter counter={counters.channels} name="Channels" />
                    <Counter counter={counters.users} name="Users" />
                    <Counter counter={counters.reach} name="Reach" />
                  </>
                ) : null}
              </div>
              <div className="row pt-3 h-100">
                <div className="col">
                  <ChannelSearchResults
                    results={results}
                    categories={categoriesByCode}
                    paging={channelPaging}
                    sorting={channelSorting}
                    onSelectPage={onSelectPage}
                    onChangeSort={onChangeSort}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const totalsAndFilteredPropType = {
  total: PropTypes.number.isRequired,
  filtered: PropTypes.number.isRequired,
};

ChannelSearchMain.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  results: PropTypes.array,
  categories: PropTypes.array,
  categoriesByCode: PropTypes.object,
  loading: PropTypes.bool,
  counters: PropTypes.shape({
    channels: PropTypes.shape(totalsAndFilteredPropType).isRequired,
    users: PropTypes.shape(totalsAndFilteredPropType).isRequired,
    reach: PropTypes.shape(totalsAndFilteredPropType).isRequired,
  }),
  channelSearchReportUrl: PropTypes.string,
  channelPaging: PropTypes.any,
  channelSorting: PropTypes.object.isRequired,
  onSelectPage: PropTypes.any,
  onChangeSort: PropTypes.any,
  onReportDownload: PropTypes.func.isRequired,
  onChannelFilterReset: PropTypes.func.isRequired,
};

ChannelSearchMain.defaultProps = {
  loading: false,
  categories: [],
  categoriesByCode: {},
};

export default ChannelSearchMain;
