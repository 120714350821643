import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import serializeError from 'serialize-error';

import { createStorage } from 'source/utils';
import createRootReducer, { getInitialState } from 'source/reducers';

const isProduction = process.env.NODE_ENV === 'production';

export const history = createBrowserHistory();

export default function createApplicationStore() {
  const middlewares = [thunk, routerMiddleware(history)];

  const composeEnhancers = isProduction
    ? compose
    : composeWithDevTools({
        serialize: {
          replacer: (key, value) => {
            if (value instanceof Error) {
              return serializeError(value);
            }
            return value;
          },
        },
      });

  const rootReducer = createRootReducer(history);
  const accessToken = createStorage().read('accessToken');
  const store = createStore(
    rootReducer,
    getInitialState(accessToken),
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  if (!isProduction && module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}
