import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';
import pMapSeries from 'p-map-series';
import find from 'lodash/find';
import get from 'lodash/get';

import { assertSuccessStatusCode } from 'source/utils/reduxAsyncUtils';
import { compactObject } from 'source/utils';
import {
  withSubmissionValidation,
  withNextPanelAfterSubmission,
} from '../../enhancers';

const findUploadUrl = (uploads, key, defaultValue) =>
  get(find(uploads, { key }), 'url', defaultValue);

const submitBriefing =
  ({ onUploadFiles, onAttachFilesToCampaign, onUpdateCampaign }) =>
  (values, __, props) => {
    const {
      campaign: { id: campaignId },
    } = props;

    const extractFiles = () =>
      ['previewImage', 'companyLogo']
        .map((key) => ({ key, file: values[key] }))
        .filter((file) => file.file instanceof File);

    const submitFiles = onUploadFiles;
    const attachFiles = (uploads) =>
      onAttachFilesToCampaign(campaignId, uploads);
    const toUploads = (resp) => get(resp, 'data.uploads');
    const toPayloads = (uploads) => [
      {
        details: compactObject({
          headline: values.name,
          mission: values.mission,
          goal: values.goal,
          previewImageUrl: findUploadUrl(
            uploads,
            'previewImage',
            values.previewImageUrl,
          ),
          companyLogoUrl: findUploadUrl(
            uploads,
            'companyLogo',
            values.companyLogoUrl,
          ),
        }),
      },
    ];

    const patchCampaign = (payloads) =>
      pMapSeries(payloads, (payload) => onUpdateCampaign(campaignId, payload));

    return Promise.resolve()
      .then(extractFiles)
      .then(submitFiles)
      .then(attachFiles)
      .then(assertSuccessStatusCode)
      .then(toUploads)
      .then(toPayloads)
      .then(patchCampaign);
  };

export const withSubmissionHandler = compose(
  setPropTypes({
    campaign: PropTypes.shape({ id: PropTypes.string.isRequired }),
    onUploadFiles: PropTypes.func.isRequired,
    onAttachFilesToCampaign: PropTypes.func.isRequired,
    onUpdateCampaign: PropTypes.func.isRequired,
  }),
  withHandlers({ onSubmit: submitBriefing }),
  withSubmissionValidation,
  withNextPanelAfterSubmission,
);
