import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function ChannelAvatar({ className, url }) {
  if (!url) {
    return null;
  }

  return (
    <img
      className={cx(className, 'channel-avatar')}
      src={url}
      alt="Channel Avatar"
    />
  );
}

ChannelAvatar.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
};
ChannelAvatar.defaultProps = {
  url: '',
  className: '',
};

export default ChannelAvatar;
