import React from 'react';
import { Route as BaseRoute, Redirect } from 'react-router-dom';

import * as Containers from 'source/containers';
import * as scenes from 'source/scenes/containers';
import { Route } from 'source/utils/routing';

export default [
  // Dashboard
  <Route key="dashboard" path="/" exact component={Containers.Dashboard} />,

  // Links
  <Route key="links" path="/links" exact component={Containers.Links} />,
  <Route key="addLink" path="/links/add" component={Containers.AddLink} />,
  <Route
    key="linkDetails"
    path="/links/:id"
    component={Containers.LinkDetails}
  />,

  // Users/Influencers
  <Route key="users" path="/users" exact component={scenes.users.UsersList} />,
  <Route
    key="userDetails"
    path="/users/:id"
    component={scenes.users.UserDetails}
  />,

  // Channels
  <Route
    key="channels"
    path="/channels"
    exact
    component={scenes.channels.ChannelsList}
  />,
  <Route
    key="channelSearch"
    path="/channels/search"
    component={scenes.channelSearch.ChannelSearch}
  />,
  <Route
    key="channelDetails"
    path="/channels/:id"
    component={scenes.channels.ChannelDetails}
  />,

  // Campaigns Overview
  <Route
    key="campaigns"
    path="/campaigns"
    exact
    component={scenes.campaigns.Overview}
  />,

  // Instagram Campaigns
  <Redirect
    key="campaignsInstagramRedirect"
    exact
    from="/campaigns/instagram"
    to="/campaigns/instagram/new"
  />,
  <Route key="campaignsInstagram" path="/campaigns/instagram/:id">
    {({ match, location }) => (
      <scenes.campaignDetail.Root match={match} location={location}>
        <Route
          path="/campaigns/instagram/new"
          component={scenes.campaignDetail.Setup.Instagram}
        />
        <Route
          path={`${match.url}/edit`}
          component={scenes.campaignDetail.Setup.Instagram}
        />
        <Route
          path={`${match.url}/todos`}
          component={scenes.campaignDetail.Todos}
        />
        <Route
          path={`${match.url}/applications/:applicationId?`}
          component={scenes.campaignDetail.InstagramApplicationsOverview}
        />
        <Route
          path={`${match.url}/missions/:applicationId?`}
          component={scenes.campaignDetail.MissionsContainerInstagram}
        />
        <Route
          path={`${match.url}/insights/:applicationId?`}
          component={scenes.campaignDetail.InsightsReview}
        />
        <Route
          path={`${match.url}/preview/:applicationId/version/:version?`}
          component={scenes.campaignDetail.ContentPreview}
        />
        <Route
          path={`${match.url}/reporting`}
          component={scenes.campaignDetail.SocialReporting}
        />
      </scenes.campaignDetail.Root>
    )}
  </Route>,

  // TikTok Campaigns
  <Redirect
    key="campaignsTikTokRedirect"
    exact
    from="/campaigns/tiktok"
    to="/campaigns/tiktok/new"
  />,
  <Route key="campaignsTikTok" path="/campaigns/tiktok/:id">
    {({ match, location }) => (
      <scenes.campaignDetail.Root match={match} location={location}>
        <Route
          path="/campaigns/tiktok/new"
          component={scenes.campaignDetail.Setup.TikTok}
        />
        <Route
          path={`${match.url}/edit`}
          component={scenes.campaignDetail.Setup.TikTok}
        />
        <Route
          path={`${match.url}/todos`}
          component={scenes.campaignDetail.Todos}
        />
        <Route
          path={`${match.url}/applications/:applicationId?`}
          component={scenes.campaignDetail.TikTokApplicationsOverview}
        />
        <Route
          path={`${match.url}/missions/:applicationId?`}
          component={scenes.campaignDetail.MissionsContainerTikTok}
        />
        <Route
          path={`${match.url}/insights/:applicationId?`}
          component={scenes.campaignDetail.InsightsReview}
        />
        <Route
          path={`${match.url}/preview/:applicationId/version/:version?`}
          component={scenes.campaignDetail.ContentPreview}
        />
        <Route
          path={`${match.url}/reporting`}
          component={scenes.campaignDetail.SocialReporting}
        />
      </scenes.campaignDetail.Root>
    )}
  </Route>,

  // Pinterest Campaigns
  <Redirect
    key="campaignsPinterestRedirect"
    exact
    from="/campaigns/pinterest"
    to="/campaigns/pinterest/new"
  />,
  <Route key="campaignsPinterest" path="/campaigns/pinterest/:id">
    {({ match, location }) => (
      <scenes.campaignDetail.Root match={match} location={location}>
        <Route
          path="/campaigns/pinterest/new"
          component={scenes.campaignDetail.Setup.Pinterest}
        />
        <Route
          path={`${match.url}/edit`}
          component={scenes.campaignDetail.Setup.Pinterest}
        />
        <Route
          path={`${match.url}/todos`}
          component={scenes.campaignDetail.Todos}
        />
        <Route
          path={`${match.url}/applications/:applicationId?`}
          component={scenes.campaignDetail.PinterestApplicationsOverview}
        />
        <Route
          path={`${match.url}/missions/:applicationId?`}
          component={scenes.campaignDetail.MissionsContainerPinterest}
        />
        <Route
          path={`${match.url}/preview/:applicationId/version/:version?`}
          component={scenes.campaignDetail.ContentPreview}
        />
      </scenes.campaignDetail.Root>
    )}
  </Route>,

  // Youtube Campaigns
  <Redirect
    key="campaignsYoutubeRedirect"
    exact
    from="/campaigns/youtube"
    to="/campaigns/youtube/new"
  />,
  <Route key="campaignsYoutube" path="/campaigns/youtube/:id">
    {({ match, location }) => (
      <scenes.campaignDetail.Root match={match} location={location}>
        <Route
          path="/campaigns/youtube/new"
          component={scenes.campaignDetail.Setup.YouTube}
        />
        <Route
          path={`${match.url}/edit`}
          component={scenes.campaignDetail.Setup.YouTube}
        />
        <Route
          path={`${match.url}/todos`}
          component={scenes.campaignDetail.Todos}
        />
        <Route
          path={`${match.url}/applications/:applicationId?`}
          component={scenes.campaignDetail.YouTubeApplicationsOverview}
        />
        <Route
          path={`${match.url}/missions/:applicationId?`}
          component={scenes.campaignDetail.MissionsContainerYouTube}
        />
        <Route
          path={`${match.url}/preview/:applicationId/version/:version?`}
          component={scenes.campaignDetail.ContentPreview}
        />
      </scenes.campaignDetail.Root>
    )}
  </Route>,

  // Website-Budget Campaigns
  <Redirect
    key="campaignsWebsiteBudgetRedirect"
    exact
    from="/campaigns/website-budget"
    to="/campaigns/website-budget/new"
  />,
  <Route key="campaignsWebsiteBudget" path="/campaigns/website-budget/:id">
    {({ match, location }) => (
      <scenes.campaignDetail.Root match={match} location={location}>
        <Route
          path="/campaigns/website-budget/new"
          component={scenes.campaignDetail.Setup.WebsiteBudget}
        />
        <Route
          path={`${match.url}/edit`}
          component={scenes.campaignDetail.Setup.WebsiteBudget}
        />
        <Route
          path={`${match.url}/todos`}
          component={scenes.campaignDetail.Todos}
        />
        <Route
          path={`${match.url}/applications/:applicationId?`}
          component={scenes.campaignDetail.WebsiteBudgetApplicationsOverview}
        />
        <Route
          path={`${match.url}/missions/:applicationId?`}
          component={scenes.campaignDetail.MissionsContainerWebsiteBudget}
        />
        <Route
          path={`${match.url}/reporting`}
          component={scenes.campaignDetail.WebsiteReporting}
        />
        <Route
          path={`${match.url}/preview/:applicationId/version/:version?`}
          component={scenes.campaignDetail.ContentPreview}
        />
      </scenes.campaignDetail.Root>
    )}
  </Route>,

  // Website-Bucket Campaigns
  <Redirect
    key="campaignsWebsiteBucketRedirect"
    exact
    from="/campaigns/website-bucket"
    to="/campaigns/website-bucket/new"
  />,
  <Route key="campaignsWebsiteBucket" path="/campaigns/website-bucket/:id">
    {({ match, location }) => (
      <scenes.campaignDetail.Root match={match} location={location}>
        <Route
          path="/campaigns/website-bucket/new"
          component={scenes.campaignDetail.Setup.WebsiteBucket}
        />
        <Route
          path={`${match.url}/edit`}
          component={scenes.campaignDetail.Setup.WebsiteBucket}
        />
        <Route
          path={`${match.url}/todos`}
          component={scenes.campaignDetail.Todos}
        />
        <Route
          path={`${match.url}/applications/:applicationId?`}
          component={scenes.campaignDetail.WebsiteBucketApplicationsOverview}
        />
        <Route
          path={`${match.url}/missions/:applicationId?`}
          component={scenes.campaignDetail.MissionsContainerWebsiteBucket}
        />
        <Route
          path={`${match.url}/reporting`}
          component={scenes.campaignDetail.WebsiteReporting}
        />
        <Route
          path={`${match.url}/preview/:applicationId/version/:version?`}
          component={scenes.campaignDetail.ContentPreview}
        />
      </scenes.campaignDetail.Root>
    )}
  </Route>,

  // Groups
  <Route
    key="groups"
    path="/groups"
    exact
    component={scenes.groups.GroupsList}
  />,
  <Route
    key="addGroup"
    path="/groups/new"
    component={scenes.groups.AddGroup}
  />,
  <Route key="groupDetails" path="/groups/:id" exact>
    {({ match, location }) => (
      <scenes.groups.GroupDetailsRoot
        match={match}
        location={location}
        groupName="groupUsers"
      >
        <scenes.groups.GroupUsers />
      </scenes.groups.GroupDetailsRoot>
    )}
  </Route>,
  <Route key="groupDetailsAddUser" path="/groups/:id/users/new">
    {({ match, location }) => (
      <scenes.groups.GroupDetailsRoot
        match={match}
        location={location}
        groupName="addUser"
      >
        <scenes.groups.GroupAddUser match={match} />
      </scenes.groups.GroupDetailsRoot>
    )}
  </Route>,

  // Invoices
  <Route
    key="invoices"
    path="/invoices"
    component={scenes.invoices.Overview}
  />,

  // Payments
  <Route
    key="payments"
    path="/payments"
    exact
    component={scenes.payments.Overview}
  />,
  <Route
    key="addPayment"
    path="/payments/new"
    exact
    component={scenes.payments.NewPayment}
  />,
  <Route
    key="reviewPayment"
    path="/payments/new/review"
    component={scenes.payments.ReviewPayment}
  />,

  // Routes that don't require auth
  <BaseRoute
    key="errorPage"
    path="/we-are-having-problems"
    component={scenes.ErrorPage}
    status={500}
  />,
  <BaseRoute key="signin" path="/signin" component={Containers.SignIn} />,
  <BaseRoute key="access" path="/access" component={Containers.Access} />,
  <BaseRoute
    key="missing"
    path="*"
    component={scenes.ErrorPage}
    status={404}
  />,
];
