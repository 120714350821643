import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import ExternalLink from 'source/scenes/components/externalLink';

import config from '../../../config';

function LoginButton({ accessToken, onLoginClick, name, className }) {
  if (accessToken) {
    return (
      <ExternalLink
        href={`${config.userApp.url}/access?access_token=${accessToken}`}
        className={cx('btn', 'btn-sm', 'btn-primary', className)}
      >
        Open App
      </ExternalLink>
    );
  }

  return (
    <button
      className={cx('btn', 'btn-sm', 'btn-success', className)}
      onClick={onLoginClick}
    >
      Login{name && ` as ${name}`}
    </button>
  );
}

LoginButton.propTypes = {
  name: PropTypes.string,
  accessToken: PropTypes.string,
  className: PropTypes.string,
  onLoginClick: PropTypes.func.isRequired,
};

export default LoginButton;
