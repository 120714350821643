import React from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';
import cx from 'classnames';

import { removeAtIndex } from 'source/utils/imCollection';

const includesCategory = (categories, category) =>
  categories.find((c) => c.code === category.code) !== undefined;

function CategoriesSelectList({
  categories,
  addCategories,
  toggleCategory,
  resetSelected,
  selectedCategories,
  toggleParentCategory,
}) {
  return (
    <div>
      <div className="form-control category-select-list mb-2">
        {categories.map((category) => {
          const isSelected = includesCategory(selectedCategories, category);
          const hasParent = category.parent !== undefined;
          return (
            <div
              className={cx('category-select-list-item', {
                selected: isSelected,
                parent: !hasParent,
              })}
              key={category.code}
              value={category.code}
              onClick={() => {
                if (hasParent) {
                  toggleCategory(category);
                } else {
                  toggleParentCategory(category);
                }
              }}
            >
              {category.code} - {category.name}
            </div>
          );
        })}
      </div>
      <button
        className="btn btn-outline-primary btn-block mb-3"
        type="button"
        disabled={selectedCategories.length === 0}
        onClick={() => {
          addCategories(selectedCategories);
          resetSelected();
        }}
      >
        Add to filter
      </button>
    </div>
  );
}

CategoriesSelectList.propTypes = {
  categories: PropTypes.array.isRequired,
  addCategories: PropTypes.func.isRequired,
  toggleCategory: PropTypes.func.isRequired,
  toggleParentCategory: PropTypes.func.isRequired,
  resetSelected: PropTypes.func.isRequired,
  selectedCategories: PropTypes.array.isRequired,
};

const enhance = compose(
  withStateHandlers(
    { selectedCategories: [] },
    {
      toggleCategory:
        ({ selectedCategories }) =>
        (category) => {
          const index = selectedCategories.findIndex(
            (c) => c.code === category.code,
          );
          if (index !== -1) {
            return {
              selectedCategories: removeAtIndex(selectedCategories, index),
            };
          }
          return {
            selectedCategories: [...selectedCategories, category],
          };
        },
      toggleParentCategory:
        ({ selectedCategories }, { categories }) =>
        (category) => {
          const hasSelectedParentCategories = !!selectedCategories.find(
            (c) => c.parent === category.code,
          );
          if (hasSelectedParentCategories) {
            return {
              selectedCategories: selectedCategories.filter(
                (c) => c.parent !== category.code,
              ),
            };
          }
          const parentCategories = categories.filter(
            (c) => c.parent === category.code,
          );
          return {
            selectedCategories: [...selectedCategories, ...parentCategories],
          };
        },
      resetSelected: () => () => ({
        selectedCategories: [],
      }),
    },
  ),
);

export default enhance(CategoriesSelectList);
