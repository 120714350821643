import isURL from 'validator/lib/isURL';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { createValidator } from 'revalidate';

const validatorUrlOptions = {
  protocols: ['http', 'https'],
  require_protocol: true,
};

export const taskValidator = (task) => {
  const { type, body, headline, file } = task;
  const errors = {};

  if (isEmpty(headline)) {
    errors.headline = 'Missing task headline';
  }

  // body must be set
  if (isEmpty(body)) {
    // - unless it is a text task
    // - unless it is an image task and a file is given instead
    if (!(type === 'text') && !(type === 'image' && !isEmpty(file))) {
      errors.body = 'Missing task details';
    }
  }

  // body must be a url if set
  if (type === 'image' && body && !isURL(body, validatorUrlOptions)) {
    errors.body = 'Image Url is not valid';
  }

  if (type === 'link' && body && !isURL(body, validatorUrlOptions)) {
    errors.body = 'URL is not valid';
  }

  return errors;
};

// see: http://stackoverflow.com/questions/8027423/how-to-check-if-a-string-is-a-valid-hex-color-representation
export const isColorCode = (colorCode) =>
  /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(colorCode);

export const isValidContentDisposition = (filename) =>
  // eslint-disable-next-line no-control-regex
  /^([!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*(?:$|;)/.test(filename);

export default {
  taskValidator,
  isColorCode,
};

/**
 * Validators compatible with "redux-form" and "revalidate"
 */

export const isValidURL = createValidator(
  (message) => (value) =>
    value &&
    !isURL(value, { protocols: ['http', 'https'], require_protocol: true })
      ? message
      : undefined,
  (field) => `${field} is not a valid URL`,
);

export const isNotEmpty = createValidator(
  (message) => (value) => isEmpty(value) ? message : undefined,
  (field) => `${field} must not be empty`,
);

// TODO's:
// - Write a general numeric comparison validator
// - Use that in `isGreaterThan` and `isLessThan`
// - Create additional functions like `isGreaterThanOrEquals`, `isLessThanOrEquals`
// - Check if we can contribute to 'revalidate'
export const isGreaterThan = (other) =>
  createValidator(
    (message) => (value, allValues) => {
      let otherValue;
      if (typeof other === 'number') {
        // compare value against the given number
        otherValue = other;
      }
      if (typeof other === 'string') {
        // compare value against the parsed value of the field with name `other`
        otherValue = allValues[other];
      }
      if (isNil(otherValue)) {
        return undefined;
      }
      if (parseFloat(value) <= otherValue) {
        return message;
      }
      return undefined;
    },
    (field) => `${field} must be greater than ${other}`,
  );

export const isLessThan = (other) =>
  createValidator(
    (message) => (value, allValues) => {
      let otherValue;
      if (typeof other === 'number') {
        // compare value against the given number
        otherValue = other;
      }
      if (typeof other === 'string') {
        // compare value against the parsed value of the field with name `other`
        otherValue = allValues[other];
      }
      if (isNil(otherValue)) {
        return undefined;
      }
      if (parseFloat(value) >= otherValue) {
        return message;
      }
      return undefined;
    },
    (field) => `${field} must be less than ${other}`,
  );

export const createValidatorByProp = (prop, validators) => (value) => {
  if (!prop) {
    console.error(`can not validate with property ${prop}`);
    return undefined;
  }

  if (!value || !value[prop]) {
    // object to validate is not initialized or does not have the property `prop`
    return undefined;
  }

  const propValue = value[prop];
  const validator = validators[propValue];
  if (isEmpty(validators) || !validator) {
    // validator for value of `prop` is missing in the `validators` mapping
    console.error(
      `missing validator for property '${prop}' with value '${propValue}'`,
    );
    return undefined;
  }

  return validator(value);
};

export const createArrayValidator =
  (arrayValidate, itemValidate) =>
  (array = []) => {
    if (!arrayValidate && !itemValidate) {
      console.error('no validators for array', array);
      return undefined;
    }

    let errors;

    if (arrayValidate) {
      const error = arrayValidate(array);
      if (error) {
        errors = { _error: error };
      }
    }

    if (itemValidate) {
      const itemErrors = [];

      array.forEach((item, index) => {
        const error = itemValidate(item);
        if (error) {
          itemErrors[index] = error;
        }
      });

      if (itemErrors.length > 0) {
        errors = itemErrors;
      }
    }

    return errors;
  };

export const isRequiredIfOtherMissing = (other) =>
  createValidator(
    (message) => (value, allValues) =>
      !value && !allValues[other] ? message : undefined,

    (field) => `${field} is required if ${other} is missing`,
  );

export const isRequiredIfOtherPresent = (other) =>
  createValidator(
    (message) => (value, allValues) =>
      !value && allValues[other] ? message : undefined,

    (field) => `${field} is required`,
  );
