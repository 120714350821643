import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import withResetScene from 'source/components/commonEnhancers/withResetScene';
import { ProgressBar, DownloadButton } from 'source/components/common';

import actions from '../actions';
import { getInvoicesState } from '../selectors';
import InvoiceTable from './table';

const enhance = compose(connect(getInvoicesState, actions), withResetScene);

class Invoices extends React.PureComponent {
  componentDidMount() {
    const { onMount } = this.props;

    return onMount();
  }

  handleSearchPeriodChange = (e) =>
    this.props.onFilterChange('period', e.target.value);

  handleSearchUserIdChange = (e) =>
    this.props.onFilterChange('userId', e.target.value);

  handleSearchInvoiceIdChange = (e) =>
    this.props.onFilterChange('invoiceId', e.target.value);

  handleSubmitSearch = (e) => {
    e.preventDefault();
    this.props.onMount();
  };

  handleFilterReset = () => {
    this.props.onFilterReset();
    this.props.onMount();
  };

  renderInvoiceTable() {
    const { invoices, page, pages, counter, onSelectPage } = this.props;

    return (
      <div className="col">
        <h3 className="pb-3">
          Invoices
          <span>
            ({counter.count}/{counter.total})
          </span>
        </h3>
        <InvoiceTable
          invoices={invoices}
          page={page}
          pages={pages}
          onSelectPage={onSelectPage}
        />
      </div>
    );
  }

  render() {
    const {
      state,
      filters: { period, userId, invoiceId },
      invoicesSummaryReportUrl,
    } = this.props;

    const stateLoaded = state === 'loaded';
    const filterApplied = period !== '' || userId !== '' || invoiceId !== '';

    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Invoices Management</h2>
            <p className="text-muted">
              This view shows you all issued invoices.
            </p>
          </div>
        </div>

        <div className="row justify-content-end pr-3">
          <DownloadButton
            href={invoicesSummaryReportUrl}
            disabled={!filterApplied}
          >
            Export Invoices
          </DownloadButton>
        </div>

        <hr />
        <h3>Search</h3>
        <form className="col" onSubmit={this.handleSubmitSearch}>
          <div className="row pt-3">
            <p className="text-muted">
              Filter by invoice period (mandatory for Export Invoices)
            </p>
            <input
              className="form-control"
              type="text"
              placeholder="Invoice period"
              value={period}
              onChange={this.handleSearchPeriodChange}
            />
          </div>
          <div className="row pt-3">
            <p className="text-muted">Filter by user id</p>
            <input
              className="form-control"
              type="number"
              placeholder="User Id"
              value={userId}
              onChange={this.handleSearchUserIdChange}
            />
          </div>
          <div className="row pt-3">
            <p className="text-muted">Filter by invoice id</p>
            <input
              className="form-control"
              type="text"
              placeholder="Invoice Id"
              value={invoiceId}
              onChange={this.handleSearchInvoiceIdChange}
            />
          </div>
          <div className="row justify-content-end pt-3">
            <button className="btn btn-primary" type="submit">
              Search
            </button>
            <button
              className="btn btn-secondary ml-1"
              type="button"
              onClick={this.handleFilterReset}
            >
              Reset
            </button>
          </div>
        </form>

        <div className="row pt-5">
          {stateLoaded ? this.renderInvoiceTable() : <ProgressBar.Mega />}
        </div>
      </div>
    );
  }
}

Invoices.propTypes = {
  state: PropTypes.string.isRequired,
  invoices: PropTypes.array.isRequired,
  page: PropTypes.any.isRequired,
  pages: PropTypes.any.isRequired,
  filters: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    invoiceId: PropTypes.string.isRequired,
  }).isRequired,
  counter: PropTypes.shape({
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  invoicesSummaryReportUrl: PropTypes.string.isRequired,
  onMount: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
};

export default enhance(Invoices);
