import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route as ReactRouterRoute } from 'react-router-dom';

import { useAccessToken } from 'source/utils/auth';
import ErrorBoundary from 'source/utils/ErrorBoundary';

export function Route({
  authRequired,
  component: Component,
  render,
  ...otherProps
}) {
  const accessToken = useAccessToken();

  if (authRequired && !accessToken) {
    return <Redirect to="/signin" />;
  }

  let renderFn;

  if (Component) {
    renderFn = function (routeProps) {
      return (
        <ErrorBoundary>
          <Component {...routeProps} {...otherProps} />
        </ErrorBoundary>
      );
    };
  }

  if (render) {
    renderFn = function (routeProps) {
      return (
        <ErrorBoundary>
          {render({ ...routeProps, ...otherProps })}
        </ErrorBoundary>
      );
    };
  }

  return <ReactRouterRoute {...otherProps} render={renderFn} />;
}

Route.propTypes = {
  component: PropTypes.elementType,
  render: PropTypes.func,
  authRequired: PropTypes.bool,
};

Route.defaultProps = {
  authRequired: true,
  render: null,
};
