import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { LinksFilters, LinksTable } from 'source/components/links';
import {
  loadLinksState,
  changeFilter,
  addFilter,
  removeFilter,
  selectPage,
  closeAlert,
} from 'source/actions/links';
import { Alert, ProgressBar } from 'source/components/common';
import {
  getDisableAdd,
  getFiltersWithOptions,
  getFilteredAndSlicedLinks,
  getPages,
} from 'source/selectors/links';

export class LinksController extends Component {
  componentDidMount() {
    this.props.onMount();
  }

  renderLinksTable() {
    const { links, pages, page, onSelectPage } = this.props;

    return (
      <div className="col">
        <h3 className="pb-3">Links</h3>
        <LinksTable
          links={links}
          pages={pages}
          page={page}
          onSelectPage={onSelectPage}
        />
      </div>
    );
  }

  render() {
    const {
      filters,
      disableAdd,
      onChangeFilter,
      onAddFilter,
      onRemoveFilter,
      stateLoaded,
      alert,
      onAlertClose,
    } = this.props;

    return (
      <div className="container">
        <Alert {...alert} onClose={onAlertClose} />

        <div className="row justify-content-end pr-3">
          <Link
            to="/links/add"
            className="btn btn-outline-primary pull-md-right"
            disabled={!stateLoaded}
          >
            Add Link
          </Link>
        </div>

        <hr />

        <div className="row pt-3">
          <div className="col">
            <h3>Search</h3>
            <p className="text-muted">
              Click a plus button on the right, to add search criteria. Search
              results came up immediately.
            </p>
            <LinksFilters
              disabled={!stateLoaded}
              filters={filters}
              disableAdd={disableAdd}
              onChange={onChangeFilter}
              onAdd={onAddFilter}
              onRemove={onRemoveFilter}
            />
          </div>
        </div>

        <div className="row pt-3">
          {stateLoaded ? this.renderLinksTable() : <ProgressBar.Mega />}
        </div>
      </div>
    );
  }
}

LinksController.propTypes = {
  stateLoaded: PropTypes.bool.isRequired,
  onMount: PropTypes.func.isRequired,
  /* Props of LinksFilters */
  filters: PropTypes.any.isRequired,
  disableAdd: PropTypes.any.isRequired,
  onChangeFilter: PropTypes.any.isRequired,
  onAddFilter: PropTypes.any.isRequired,
  onRemoveFilter: PropTypes.any.isRequired,

  /* Props of LinksTable */
  links: PropTypes.any.isRequired,
  pages: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  onSelectPage: PropTypes.any.isRequired,

  /* others */
  alert: PropTypes.object,
  onAlertClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  stateLoaded: state.links.stateLoaded,

  /* LinksFilters */
  filters: getFiltersWithOptions(state.links),
  disableAdd: getDisableAdd(state.links),

  /* LinksTable */
  links: getFilteredAndSlicedLinks(state.links),
  pages: getPages(state.links),
  page: state.links.table.page,

  /* other */
  alert: state.links.alert,
});

const mapDispatchToProps = {
  /* State */
  onMount: loadLinksState,

  /* LinksFilters */
  onChangeFilter: changeFilter,
  onAddFilter: addFilter,
  onRemoveFilter: removeFilter,

  /* LinksTable */
  onSelectPage: selectPage,

  /* other */
  onAlertClose: closeAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinksController);
