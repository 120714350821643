import React from 'react';
import PropTypes from 'prop-types';

import HTMLRenderer from 'source/scenes/components/htmlRenderer';

function HTMLPreview({ showCode, content, setRenderedHTMLRef }) {
  return (
    <div className="html-preview-container">
      <HTMLRenderer
        setRenderedHTMLRef={setRenderedHTMLRef}
        showCode={showCode}
        html={content.article}
        safeHtml={content.articleSanitized}
      />
    </div>
  );
}

HTMLPreview.propTypes = {
  showCode: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    article: PropTypes.string.isRequired,
    articleSanitized: PropTypes.string.isRequired,
  }).isRequired,
  setRenderedHTMLRef: PropTypes.func.isRequired,
};

export default HTMLPreview;
