import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import cx from 'classnames';
import get from 'lodash/get';

import Alert from 'source/components/common/alert';

import selector from './selectors';
import { withSubmissionHandler, withManagedChannels } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';
import ChannelsTable from './components/channelsTable';

/**
 * NOTE @alexspri
 *    We just migrated the `ChannelsForm` from the old setup (v1) and did not
 *    re-implement it with redux-form just to not waste time for old forms.
 *    When adding a new form we should stick to `redux-form` of course.
 */
const enhance = compose(
  withManagedChannels,
  connect(selector),
  withCollapsiblePanel,
  withSubmissionHandler,
);

class ChannelsForm extends React.PureComponent {
  handleAddChannel = (e) => {
    const { onAddChannel } = this.props;

    e.preventDefault();

    return onAddChannel();
  };

  handleSubmit = (e) => {
    const {
      form,
      dispatch,
      onSubmit,
      onResetContingents,
      onSubmissionError,
      ...props
    } = this.props;

    e.preventDefault();

    return Promise.resolve()
      .then(() => onSubmit(form, dispatch, props))
      .then((res) => onResetContingents(res.data))
      .catch(onSubmissionError);
  };

  renderTable() {
    const { form, formName, onRowChange, onRemoveChannel } = this.props;

    return (
      <ChannelsTable
        form={form}
        formName={formName}
        onRowChange={onRowChange}
        onRemoveChannel={onRemoveChannel}
      />
    );
  }

  render() {
    const {
      form: { options, channels },
      formName,
      error,
    } = this.props;

    return (
      <form id={formName} onSubmit={this.handleSubmit}>
        <Alert message={get(error, 'message')} />

        <div className="row justify-content-end pr-3">
          <button
            className="btn btn-info"
            onClick={this.handleAddChannel}
            disabled={options.length <= 0}
          >
            Add Channel
          </button>
        </div>

        <div className="pt-3">
          {channels.length > 0 ? (
            this.renderTable()
          ) : (
            <div className="alert alert-info">
              Seems like you did not add any channels just yet!
            </div>
          )}
        </div>

        <div className="row justify-content-end pr-3">
          <button
            type="submit"
            className={cx('btn', {
              'btn-primary': !get(error, 'message'),
              'btn-danger': !!get(error, 'message'),
            })}
          >
            Save
          </button>
        </div>
      </form>
    );
  }
}

ChannelsForm.propTypes = {
  form: PropTypes.shape({
    channels: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
  }).isRequired,
  formName: PropTypes.string.isRequired,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onResetContingents: PropTypes.func.isRequired,
  onSubmissionError: PropTypes.func.isRequired,
  onAddChannel: PropTypes.func.isRequired,
  onRemoveChannel: PropTypes.func.isRequired,
  onRowChange: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

ChannelsForm.defaultProps = {
  error: undefined,
};

export default enhance(ChannelsForm);
