import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';
import find from 'lodash/find';
import filter from 'lodash/filter';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { assertSuccessStatusCode } from 'source/utils/reduxAsyncUtils';
import { compactObject } from 'source/utils';
import {
  withSubmissionValidation,
  withNextPanelAfterSubmission,
} from '../../enhancers';

const getUploads = (resp) => get(resp, 'data.uploads');

const submitTasks =
  ({ list, onUploadFiles, onAttachFilesToCampaign, onUpdateCampaign }) =>
  (values, __, props) => {
    const {
      campaign,
      campaign: { id: campaignId },
    } = props;

    if (!['preTasks', 'tasks', 'postTasks'].includes(list)) {
      throw new Error(`unkown list to submit: ${list}`);
    }

    const existingTasks = campaign.tasks;
    const updatedTasks = values.tasks;

    // extract files from "image" tasks
    const extractFiles = () =>
      updatedTasks
        .map((task, index) => {
          let file;
          let filename;

          // NOTE   Only upload "file" for `image` tasks. We might have left-over
          //        attributes, when choosing an image, but then switching the
          //        task type.
          if (task.file && ['image'].indexOf(task.type) !== -1) {
            file = task.file;
            filename = task.file.name;
          }

          // NOTE   `key` must be casted to String here as FormData otherwise
          //        can cause troubles.
          return { key: String(index), file, filename };
        })
        .filter((file) => file.file instanceof File);

    // uploads files if any exists
    const submitFiles = onUploadFiles;
    const attachFiles = (uploads) =>
      onAttachFilesToCampaign(campaignId, uploads);
    const toUploads = getUploads;

    // use the uploads "URL" as body for the corresponding task updatedTask)
    const associateUploadsWithTasks = (uploads) =>
      updatedTasks.map((task, index) => {
        // only override the tasks body if it contained an image that was uploaded
        if (!['image'].includes(task.type) || !task.file) {
          return task;
        }

        const nextTask = { ...task };
        const upload = find(uploads, { key: String(index) });

        if (!upload) {
          // ... should not happen, a file was specified but we didn't upload it ?
          throw new Error(
            `a file was specified in task ${task.headline} but it wasn't uploaded`,
          );
        } else {
          nextTask.body = upload.url;
        }

        return nextTask;
      });

    const mergeTasks = (updatedTasks) => {
      const tasks = {
        preTasks: filter(existingTasks, { list: 'preTasks' }),
        tasks: filter(existingTasks, { list: 'tasks' }),
        postTasks: filter(existingTasks, { list: 'postTasks' }),
      };

      tasks[list] = updatedTasks;

      return [...tasks.preTasks, ...tasks.tasks, ...tasks.postTasks];
    };

    // prepare tasks so that they can be PATCH'ed
    const prepareTasks = (tasks) =>
      tasks.map((task) => {
        const omittedFields = ['file'];

        // Strip temp ids
        if (/^temp/.test(task.id)) {
          omittedFields.push('id');
        }

        return compactObject(omit(task, omittedFields));
      });

    const toUpdatePayload = (tasks) => ({ tasks });
    const patchCampaign = (payload) => onUpdateCampaign(campaignId, payload);

    return Promise.resolve()
      .then(extractFiles)
      .then(submitFiles)
      .then(attachFiles)
      .then(assertSuccessStatusCode)
      .then(toUploads)
      .then(associateUploadsWithTasks)
      .then(mergeTasks)
      .then(prepareTasks)
      .then(toUpdatePayload)
      .then(patchCampaign);
  };

export const withSubmissionHandler = compose(
  setPropTypes({
    campaign: PropTypes.shape({ id: PropTypes.string.isRequired }),
    list: PropTypes.string.isRequired,
    onUploadFiles: PropTypes.func.isRequired,
    onAttachFilesToCampaign: PropTypes.func.isRequired,
    onUpdateCampaign: PropTypes.func.isRequired,
  }),
  withHandlers({ onSubmit: submitTasks }),
  withSubmissionValidation,
  withNextPanelAfterSubmission,
);
