import { combineReducers } from 'redux';
import { asyncActionReducer } from '@blogfoster/redux-async-utils';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { combineReducersFlat } from 'source/utils/redux';

import { actionTypes } from './actions';
import { dataCreateHandler } from '../dataHandlers';

/* == selectors == */
export const getCampaignReportsById = (state) =>
  get(state, 'campaignReportById', {});

export const getCampaignReport = (state, { campaignId }) =>
  state.campaignReportById[campaignId];

/* == default reducers == */

const campaignReportFetchReducer = asyncActionReducer(
  actionTypes.FETCH,
  dataCreateHandler({ key: 'campaignId' }),
  {},
);

const campaignReportingClearReducer = (state, action) =>
  action.type === actionTypes.CLEAR ? omit(state, action.payload) : state;

const campaignReportByIdReducer = combineReducersFlat(
  [campaignReportingClearReducer, campaignReportFetchReducer],
  {},
);

export default combineReducers({
  campaignReportById: campaignReportByIdReducer,
});
