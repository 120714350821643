import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { compose } from 'recompose';
import get from 'lodash/get';

import {
  Input,
  FileDrop,
  MarkdownTextareaSide,
} from 'source/scenes/components/reduxFormAdapters';
import Alert from 'source/components/common/alert';
import { FilePreview } from 'source/components/common/fileDrop';

import validate from './validation';
import selector from './selectors';
import { withSubmissionHandler } from './enhancers';
import { withCollapsiblePanel } from '../../enhancers';

const enhance = compose(
  connect((state, ownProps) => ({
    companyLogo: formValueSelector(ownProps.form)(state, 'companyLogo'),
    companyLogoUrl: formValueSelector(ownProps.form)(state, 'companyLogoUrl'),
    previewImage: formValueSelector(ownProps.form)(state, 'previewImage'),
    previewImageUrl: formValueSelector(ownProps.form)(state, 'previewImageUrl'),
    ...selector(state, ownProps),
  })),
  withCollapsiblePanel,
  withSubmissionHandler,
  reduxForm({
    validate,
    touchOnBlur: false,
    touchOnChange: true,
    enableReinitialize: true,
  }),
);

function BriefingForm({
  handleSubmit,
  invalid,
  submitting,
  companyLogo,
  companyLogoUrl,
  previewImage,
  previewImageUrl,
  error,
}) {
  return (
    <form onSubmit={handleSubmit} className="form briefing-form">
      <Alert message={get(error, 'message')} />
      <Field
        name="name"
        component={Input}
        label="Campaign public name*:"
        placeholder="Public name"
      />

      {/* Mission Statement */}
      <div className="row">
        <div className="col">
          <Field
            name="mission"
            component={MarkdownTextareaSide}
            label="Mission*:"
            placeholder="Mission Statement of the campaign"
            rows="15"
          />
        </div>
      </div>

      {/* Goal Statement */}
      <div className="row">
        <div className="col">
          <Field
            name="goal"
            component={MarkdownTextareaSide}
            label="Goal*:"
            placeholder="Goal Statement of the campaign"
            rows="15"
          />
        </div>
      </div>

      <div className="row cursor-auto">
        {/* Left column */}
        <div className="col-md-6">
          <div className="row">
            <div className="col">
              {/* Company logo input */}
              <Field
                name="companyLogo"
                component={FileDrop}
                label="Company Logo (200x100)*:"
                placeholder="Choose or drop a file..."
                accept="image/*"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              {/* Company logo preview */}
              <FilePreview
                src={get(companyLogo, 'preview') || companyLogoUrl}
              />
            </div>
          </div>
        </div>
        {/* Right column */}
        <div className="col-md-6">
          <div className="row">
            <div className="col">
              {/* Preview image input */}
              <Field
                name="previewImage"
                component={FileDrop}
                label="Preview Image (800x300)*:"
                placeholder="Choose or drop a file..."
                accept="image/*"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              {/* Preview image preview */}
              <FilePreview
                src={get(previewImage, 'preview') || previewImageUrl}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-end pr-3">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={invalid || submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
}

BriefingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  companyLogo: PropTypes.object,
  companyLogoUrl: PropTypes.string,
  previewImage: PropTypes.object,
  previewImageUrl: PropTypes.string,
  productShipment: PropTypes.shape({
    enabled: PropTypes.bool,
  }),
  error: PropTypes.object,
};

BriefingForm.defaultProps = {
  companyLogo: undefined,
  companyLogoUrl: '',
  previewImage: undefined,
  previewImageUrl: '',
  error: undefined,
};

export default enhance(BriefingForm);
