import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'source/components/common/icon';
import ExternalLink from 'source/scenes/components/externalLink';

function ChannelLinkWebsite({ channel: { name, platform } }) {
  return (
    <div className="d-flex align-items-center channel-icon">
      <Icon name={platform} className="small-icon mr-2" />
      <p className="form-control-plaintext truncate">
        <ExternalLink href={name}>{name}</ExternalLink>
      </p>
    </div>
  );
}

ChannelLinkWebsite.propTypes = {
  channel: PropTypes.shape({
    platform: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ChannelLinkWebsite;
