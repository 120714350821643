import { keyedAsyncActionCreator } from '@blogfoster/redux-async-utils';

import {
  campaignCreate,
  campaignUpdate,
  campaignAttachUploads,
  campaignPublish,
  campaignArchive,
  campaignDelete,
  campaignDone,
  campaignDuplicate,
} from 'source/data/campaigns/actions';

import { actionAuthRequest } from 'source/utils/axios';
import { filesUpload } from 'source/data/cave/actions';
import { redux as collapsiblePanelRedux } from 'source/components/common/collapsiblePanel';

// Helpers

const extractErrorMessage = (error) => {
  if (typeof error === 'string') {
    return error;
  }
  if (error && error.message) {
    return error.message;
  }

  return String(error);
};

// Actions

export const namespace = 'scenes/campaignDetail/setup';

export const actionTypes = {
  RESET_SCENE: 'campaignDetail/setup/container/RESET_SCENE',
  SET_ERROR: 'campaignDetail/setup/container/SET_ERROR',
  CLIENTS_FETCH_MANY: 'campaignDetail/setup/container/CLIENTS_FETCH_MANY',
  PRIVATE_NETWORKS_FETCH_MANY:
    'campaignDetail/setup/container/PRIVATE_NETWORKS_FETCH_MANY',
};

export const createCampaign = campaignCreate(namespace);

export const updateCampaign = campaignUpdate(namespace);

export const uploadFiles = filesUpload(namespace);

export const attachFilesToCampaign = campaignAttachUploads(namespace);

export const publishCampaign = campaignPublish(namespace);

export const archiveCampaign = campaignArchive(namespace);

export const doneCampaign = campaignDone(namespace);

export const duplicateCampaign = campaignDuplicate(namespace);

export const deleteCampaign = campaignDelete(namespace);

export const selectPanel = collapsiblePanelRedux.toggleActionCreator(namespace);

export const resetScene = () => ({ type: actionTypes.RESET_SCENE });

export const setError = (error) => ({
  type: actionTypes.SET_ERROR,
  payload: extractErrorMessage(error),
});

export const fetchClients = keyedAsyncActionCreator(
  actionTypes.CLIENTS_FETCH_MANY,
  () => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState, {
      dispatchErrors: false,
    }).get('/v2/clients'),
);

export const fetchPrivateNetworks = keyedAsyncActionCreator(
  actionTypes.PRIVATE_NETWORKS_FETCH_MANY,
  () => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState, {
      dispatchErrors: false,
    }).get('/v2/private-networks'),
);
