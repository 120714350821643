import { createSelector } from 'reselect';

import filter from 'lodash/filter';
import get from 'lodash/get';

import { compactObject } from 'source/utils';

const getAccessToken = (state) => get(state, 'account.accessToken');
const getFilters = (state) => get(state, 'campaign.applications.filters');
const getForms = (state) => get(state, 'campaign.applications.formsById');
const getExpanded = (state) => get(state, 'campaign.applications.expanded');
const getInsights = (state) => get(state, 'campaign.applications.insights');
const getApplications = (state) =>
  get(state, 'campaign.applications.applications');
const getPublishingDates = (state) =>
  get(state, 'campaign.applications.publishingDates');

const insightsSelector = createSelector(
  [getInsights],
  (applicationInsights) => {
    const transformedInsights = Object.keys(applicationInsights).reduce(
      (memo, key) => {
        const value = applicationInsights[key];

        const insights = get(value, 'data.insights', {});
        const performance = get(value, 'data.channel.performance', {});
        const metrics = get(value, 'data.channel.metrics', {});

        memo[key] = {
          insights: { ...insights, ...performance },
          metrics,
        };

        return memo;
      },
      {},
    );

    return transformedInsights;
  },
);

export const campaignApplications = createSelector(
  [
    getApplications,
    getAccessToken,
    getFilters,
    getForms,
    getExpanded,
    insightsSelector,
    getPublishingDates,
  ],
  (
    applications,
    accessToken,
    filters,
    forms,
    expanded,
    insights,
    publishingDates,
  ) => {
    const counter = { total: 0, count: 0 };

    if (applications && applications.loaded) {
      // TODO: apply applications filter - should be its own function
      counter.total = applications.data.length;
      const filtersToApply = compactObject(filters);
      applications = {
        ...applications,
        data: filter(applications.data, filtersToApply),
      };
      counter.count = applications.data.length;
    }

    return {
      applications,
      accessToken,
      filters,
      forms,
      expanded,
      insights,
      counter,
      publishingDates,
    };
  },
);
