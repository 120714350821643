import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import ExternalLink from 'source/scenes/components/externalLink';
import Pagination from 'source/components/common/pagination';
import ChannelStatus from 'source/components/common/channelStatus';
import Icon from 'source/components/common/icon';

function ChannelRowBuilder(channel) {
  return (
    <tr key={channel.id}>
      <td>
        <div className="d-block truncate mw-5" title={channel.id}>
          {channel.id}
        </div>
        {channel.platform === 'website' && channel.websiteId && (
          <div>{channel.websiteId}</div>
        )}
      </td>
      <td>
        <div className="d-flex align-items-center channel-icon truncate">
          <Icon name={channel.platform} className="small-icon" />
          <Link to={`/channels/${channel.id}`} className="ml-2 truncate mw-12">
            {channel.name}
          </Link>
        </div>
      </td>
      <td nowrap="true">
        <ChannelStatus ready={channel.ready} readyState={channel.readyState} />
      </td>
      <td>
        {channel.qualityReview && '⚠️'}
        <ExternalLink href={channel.url} className="ml-1">
          {channel.url}
        </ExternalLink>
      </td>
      <td>
        <Link to={`/users/${channel.user.id}`} className="cell-link">
          {channel.user.firstname}
        </Link>
      </td>
      <td>{channel.createdAt}</td>
    </tr>
  );
}

function ChannelsTable({ channels, pages, page, onSelectPage }) {
  return (
    <div>
      <div className="table-responsive-lg">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Status</th>
              <th>URL</th>
              <th>User</th>
              <th className="min-width-8-5">Created At</th>
            </tr>
          </thead>
          <tbody>{channels.map(ChannelRowBuilder)}</tbody>
        </table>
      </div>
      <div className="row justify-content-center pt-3">
        <Pagination pages={pages} activePage={page} onSelect={onSelectPage} />
      </div>
    </div>
  );
}

ChannelsTable.propTypes = {
  channels: PropTypes.array,
  pages: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  onSelectPage: PropTypes.any.isRequired,
};

ChannelsTable.defaultProps = {
  channels: [],
};

export default ChannelsTable;
