export const campaignStatuses = {
  draft: 'draft',
  published: 'published',
  archived: 'archived',
  done: 'done',
};

export const applicationStatuses = {
  new: 'new',
  review: 'review',
  selected: 'selected',
  approved: 'approved',
  rejected: 'rejected',
};

export const missionStatuses = {
  pending: 'pending',
  confirmed: 'confirmed',
  refused: 'refused',
};

export const verificationStatuses = {
  pending: 'pending',
  submitted: 'submitted',
  accepted: 'accepted',
  rejected: 'rejected',
};

export const publicationStatuses = {
  pending: 'pending',
  submitted: 'submitted',
  accepted: 'accepted',
  rejected: 'rejected',
};
