import { reduxForm } from 'redux-form';

import ChannelFilter from './channelFilter';

const ChannelSearchForm = reduxForm({
  // validate,
  initialValues: {
    platform: 'instagram',
    reach: 0,
    categories: [],
    categoriesFilterType: 'and',
  },
  form: 'channel_search',
  touchOnBlur: false,
  touchOnChange: true,
  enableReinitialize: true,
})(ChannelFilter);

export default ChannelSearchForm;
