import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, branch, renderComponent } from 'recompose';
import isEmpty from 'lodash/isEmpty';
import InfoCard, { InfoCardContainer } from 'source/components/common/infoCard';
import config from 'config';

function EmptyReporting() {
  return (
    <InfoCardContainer>
      <InfoCard iconName="info" title="No Insights available so far." />
    </InfoCardContainer>
  );
}

const withEmptyReporting = branch(
  (props) => isEmpty(props.applications),
  renderComponent(EmptyReporting),
);

const enhance = compose(pure, withEmptyReporting);

const platformLabel = {
  tiktok: 'TikTok',
  instagram: 'Instagram',
};

function SocialReporting({ campaign, user }) {
  const mailToHref = React.useMemo(() => {
    const to = 'support.internal@eqolot.com';
    const subject = `Data export request for ${campaign.id}`;
    const campaignLink = `${config.app.url}/campaigns/${campaign.type}/${campaign.id}/edit`;
    const body = `Hello BI team,\n\nPlease export and send the report data for:\n\n${campaign.name}\n${campaignLink}\n${campaign.id}\n\nSent by ${user.email}`;

    return `mailto:${to}?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`;
  }, [campaign, user]);

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h2>Reporting</h2>
          <p className="mt-3">
            {platformLabel[campaign.type]} insights are uploaded and collected
            in the mission workflow.
            <br />
            When all missions are completed, the collected insights can be
            exported in a table.
          </p>
          <a className="btn btn-primary" href={mailToHref}>
            Request insights export
          </a>
        </div>
      </div>
    </div>
  );
}

SocialReporting.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['tiktok', 'instagram']),
  }).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  applications: PropTypes.arrayOf(PropTypes.object),
};

SocialReporting.defaultProps = {
  applications: [],
};

export default enhance(SocialReporting);
