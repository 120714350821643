import moment from 'moment';

import { createReducer } from 'source/utils';
import actionTypes from 'source/constants/actionTypes';

export const getInitialState = () => ({
  state: '',

  data: [],

  error: null,

  lastFetch: null,
});

const actionHandlers = {
  [actionTypes.IAB_CATEGORIES_LOADING]: (state) => ({
    ...state,
    state: 'loading',
  }),

  [actionTypes.IAB_CATEGORIES_LOADED]: (state, data) => ({
    ...state,
    state: 'loaded',
    lastFetch: moment.utc(),
    data,
  }),

  [actionTypes.IAB_CATEGORIES_LOAD_FAILED]: (state, error) => ({
    ...state,
    state: 'error',
    error,
  }),
};

export default createReducer(getInitialState(), actionHandlers);
