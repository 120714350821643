import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Markdown from './markdown';

function MarkdownTextareaSide({ value, className, ...props }) {
  return (
    <div className="row">
      <div className="col-md-6">
        <textarea
          type="text"
          className={cx('form-control', 'h-100', className)}
          value={value}
          rows="15"
          {...props}
        />
      </div>
      <div className="col-md-6">
        <Markdown type="text" className="form-control h-100" markdown={value} />
      </div>
    </div>
  );
}

MarkdownTextareaSide.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
};

MarkdownTextareaSide.defaultProps = {
  value: '',
  className: '',
};

export default MarkdownTextareaSide;
