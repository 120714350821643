import flat from 'flat';

export const payloadToQuery = (payload = {}) => {
  let query = {};

  if (payload.platform === 'blogs') {
    query = { ...query, platforms: ['ga', 'website'] };
  } else {
    query = { ...query, platforms: [payload.platform] };
  }

  if (payload.reachMin) {
    query = { ...query, reach: { ...query.reach, min: payload.reachMin } };
  }

  if (payload.reachMax) {
    query = { ...query, reach: { ...query.reach, max: payload.reachMax } };
  }

  if (payload.user && payload.user.country) {
    query = {
      ...query,
      user: { ...query.user, countries: [payload.user.country] },
    };
  }

  if (payload.user && payload.user.language) {
    query = {
      ...query,
      user: { ...query.user, languages: [payload.user.language] },
    };
  }

  if (payload.categories) {
    query = {
      ...query,
      categories: {
        expression: payload.categoriesFilterType,
        values: payload.categories.map(({ code, weight }) => ({
          code,
          weight,
        })),
      },
    };
  }

  return query;
};

export const payloadToEventProperties = (payload) => flat(payload);
