/* eslint-disable react/style-prop-object */

import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';

import Pagination from 'source/components/common/pagination';

const invoiceRowBuilder = (invoice) => (
  <tr key={invoice.id}>
    <td>{invoice.id}</td>
    <td>{invoice.period}</td>
    <td>{invoice.type}</td>
    <td>
      <FormattedNumber
        value={invoice.totalAmount}
        style="currency"
        currency={invoice.currency}
      />
    </td>
    <td>
      <FormattedNumber
        value={invoice.netAmount}
        style="currency"
        currency={invoice.currency}
      />
    </td>
    <td>
      <FormattedNumber
        value={invoice.vatAmount}
        style="currency"
        currency={invoice.currency}
      />
    </td>
    <td>{invoice.currency}</td>
    <td>{invoice.userId}</td>
    <td>
      <Link to={`/users/${invoice.userId}`}>{invoice.name}</Link>
    </td>
  </tr>
);

function InvoicesTable({ invoices, pages, page, onSelectPage }) {
  return (
    <div>
      <div className="table-responsive-md">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Invoice Id</th>
              <th>Period</th>
              <th>Type</th>
              <th>Total Amount</th>
              <th>Net Amount</th>
              <th>VAT Amount</th>
              <th>Currency</th>
              <th>User Id</th>
              <th>User</th>
            </tr>
          </thead>
          <tbody>{invoices.map(invoiceRowBuilder)}</tbody>
        </table>
      </div>
      <div className="row justify-content-center pt-3">
        <Pagination pages={pages} activePage={page} onSelect={onSelectPage} />
      </div>
    </div>
  );
}

InvoicesTable.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      period: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      totalAmount: PropTypes.number.isRequired,
      netAmount: PropTypes.number.isRequired,
      vatAmount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      userId: PropTypes.number.isRequired,
    }),
  ),
  pages: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  onSelectPage: PropTypes.any.isRequired,
};

InvoicesTable.defaultProps = {
  invoices: [],
};

export default pure(InvoicesTable);
