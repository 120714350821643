import React from 'react';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  renderComponent,
  withPropsOnChange,
  renderNothing,
} from 'recompose';

import Alert from 'source/components/common/alert';
import ProgressBar from 'source/components/common/progressBar';

import Events from './events';
import Reaction from './reaction';

/**
 * Conversation component that wraps `Events` and `Reaction` together.
 */
function Conversation(props) {
  return (
    <div className="row conversation">
      <div className="col">
        <Events {...props} />
        <Reaction {...props} />
      </div>
    </div>
  );
}

const withEvents = withPropsOnChange(
  ['expanded'],
  ({ expanded, application: { id }, onLoadApplication }) => {
    if (expanded) {
      onLoadApplication(id, { fields: ['events'] });
    }
  },
);

function Error() {
  return (
    <Alert
      type="danger"
      message="Error loading messages. Please try to refresh the page."
      mega
    />
  );
}

// NOTE: We don't want to mount the component and trigger loading the events
// if it's not necessary.
const withNotExpandedGuard = branch((props) => !props.expanded, renderNothing);

const withLoadingGuard = branch(
  (props) => !props.eventsRequestState.loaded,
  renderComponent(ProgressBar.Mega),
);

const withErrorGuard = branch(
  (props) => props.eventsRequestState.error,
  renderComponent(Error),
);

Conversation.propTypes = {
  expanded: PropTypes.bool.isRequired,
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  withNotExpandedGuard,
  withEvents,
  withLoadingGuard,
  withErrorGuard,
)(Conversation);
