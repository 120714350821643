import PropTypes from 'prop-types';
import { compose, setPropTypes, lifecycle } from 'recompose';

/**
 * Calls the component's `onResetScene` function when the component
 * unmounts.
 */
export default compose(
  setPropTypes({
    onResetScene: PropTypes.func,
  }),
  lifecycle({
    componentWillUnmount() {
      if (this.props.onResetScene) {
        this.props.onResetScene(this.props);
      }
    },
  }),
);
